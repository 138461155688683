import { orderBy, uniq } from "lodash";
import { allDatasToDisplay } from "./CustomXylemAnalysisWithResponses";
import {
  AddShoppingCart,
  AttachMoney,
  PriceCheck,
  CurrencyExchange,
  CreditScore,
  FactCheck,
  ShoppingCartCheckout,
  SecurityUpdateGood,
  LocationOff,
  Dangerous,
  ThumbUp,
  Shield,
  HourglassBottom,
  Abc,
  BookmarkAdded,
  Cancel,
  CompareArrows,
  PermPhoneMsg,
  TagFaces,
  VolunteerActivism,
  AddReaction,
  AlarmOn,
  Mood,
  SentimentVeryDissatisfied
} from "@mui/icons-material";

export const allRegions = ["Europe"];
export const allSubRegions = ["South Europe", "North Europe"];
export const allCountries = ["FR", "UK", "DE", "IT"];
export const allMarkets = ["***", "CBS", "IND", "MUN"];

//TODO: use remote datas!
export const allCompanyGroupNames = orderBy(
  uniq(
    allDatasToDisplay
      .map((d) => d.integration.CustomerCompanyGroupName)
      .filter(Boolean)
  ),
  (x) => x.toLowerCase()
);
export const allCompanyNames = orderBy(
  uniq(
    allDatasToDisplay
      .map((d) => d.integration.CustomerCompanyName)
      .filter(Boolean)
  ),
  (x) => x.toLowerCase()
);

export const allTouchpoints = [
  "Commissionning_Installation",
  "Customer Visit",
  "Delivery",
  "Product return",
  "Quotation",
  "Rental",
  "Repairing",
  "Warrantly claim",
  "Delivery date",
];

export const iconsMapping = {
  promo: AddShoppingCart,
  prix: AttachMoney,
  "rapp.q.p": PriceCheck,
  "prix.var": CurrencyExchange,
  abattement: CreditScore,
  choix: FactCheck,
  dispo: ShoppingCartCheckout,
  garantie: SecurityUpdateGood,
  "manque.produit": LocationOff,
  abime: Dangerous,
  fiabilite: ThumbUp,
  qualite: Shield,
  delai: HourglassBottom,
  signature: Abc,
  service: BookmarkAdded,
  refus: Cancel,
  "geste.co": CompareArrows,
  joignabilite: PermPhoneMsg,
  accueil: TagFaces,
  ecoute: VolunteerActivism,
  competence: AddReaction,
  efficacite: AlarmOn,
  ressenti: Mood,
  "ressenti.neg": SentimentVeryDissatisfied
};

export const XYLEM_BLUE = "#2685a6";