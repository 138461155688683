import * as jose from 'jose';
import { useContext, useEffect, useState } from 'react';
import { Chart } from '../Chart';
import './Dashboard.css';

import { Box, Grid, Paper } from '@mui/material';
import { groupBy, map } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pie, PieChart, Tooltip } from 'recharts';
import { DataContext } from '../DataContext';
import { TouchpointMultipleSelectChip } from '../TouchpointChip';
import { DashboardFilter, DashboardFilters } from '../components/DashboardFilter';

const getToken = async () => {
  const secret = new TextEncoder().encode(
    'L9nzTOrjviYVvZMax2y3UcsSwW8A9LZq5A8MuzJ1k0v8eUm79LICFOhu2f90Q3jB',
  );
  const alg = 'HS256';

  const jwt = await new jose.SignJWT({ 'urn:example:claim': true })
    .setProtectedHeader({ alg })
    .setIssuedAt(Math.floor(Date.now() / 1000) - 60)
    .setIssuer('xylem-dashboards')
    .setExpirationTime('12h')
    .sign(secret);

  return jwt;
};

const getInitialValueFromUrlOrLocalstorage = (
  touchPointGetParam: string | null,
) => {
  return (touchPointGetParam || localStorage.getItem('touchpoints') || '')
    .split(',')
    .filter(Boolean);
};

export const DashboardByTouchpoint = () => {
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const search = location.search;

  const params = new URLSearchParams(search);

  const [touchpoints, setTouchpoints] = useState(
    getInitialValueFromUrlOrLocalstorage(params.get('touchpoints')),
  );

  useEffect(() => {
    getToken().then((token) => setToken(token));
  }, []);

  useEffect(() => {
    if (!touchpoints.length) {
      navigate('/touchpoint');
      return;
    }
    const joinedTouchpoints = touchpoints.join(',');
    const encodedTouchpoints = encodeURIComponent(joinedTouchpoints);

    localStorage.setItem('touchpoints', joinedTouchpoints);

    navigate('/touchpoint?touchpoints=' + encodedTouchpoints);
  }, [touchpoints]);

  const { xylemResponses } = useContext(DataContext);

  const [filterState, setFilterState] = useState<DashboardFilter>({
    regions: [] as string[],
    subRegions: [] as string[],
    countries: [] as string[],
    markets: [] as string[],
    companyNames: [] as string[],
    companyGroupNames: [] as string[],
    range: [null, null]
  });
  function onFilterChange(state: DashboardFilter) {
    setFilterState(state)
  }

  if (!token) {
    return null;
  }

  const height = '250px';
  const semiHeight = '125px';

  const touchpointsToDisplay = [
    { name: 'All touchpoints', filter: null },
    ...touchpoints.map((t) => ({ name: t, filter: { touchpoint: t } })),
  ];

  return (
    <Box sx={{ p: 6 }}>
      <Grid container>
        <Grid item xs={10}>
          <TouchpointMultipleSelectChip
            touchpoints={touchpoints}
            onNewTouchpoints={(values: any) => setTouchpoints(values)}
          />
          <Grid container spacing={3} columnSpacing={3}>
            {touchpointsToDisplay.map((aTouchpoint) => {
              let rowsForTouchpoint = xylemResponses.filter(
                (r) => !aTouchpoint.filter || r.touchpoint === aTouchpoint.name,
              );

              const filterToApply: any = aTouchpoint.filter || {};
              if(filterState.regions.length > 0) {
                filterToApply["integrationCalculated.region"] = {$in: filterState.regions};
                rowsForTouchpoint = rowsForTouchpoint.filter((r) => filterState.regions.includes(r.integrationCalculated.region));
              }
              if(filterState.subRegions.length > 0) {
                filterToApply["integrationCalculated.subRegion"] =  {$in:filterState.subRegions };
                rowsForTouchpoint = rowsForTouchpoint.filter((r) => filterState.subRegions.includes(r.integrationCalculated.subRegion));
              }
              if(filterState.countries.length > 0) {
                filterToApply["integration.XylemCountry"] =  {$in:filterState.countries };
                rowsForTouchpoint = rowsForTouchpoint.filter((r) => filterState.countries.includes(r.integration.XylemCountry));
              }
              if(filterState.markets.length > 0) {
                filterToApply["integration.XylemMarket"] =  {$in:filterState.markets };
                rowsForTouchpoint = rowsForTouchpoint.filter((r) => filterState.markets.includes(r.integration.XylemMarket));
              }
              if(filterState.companyNames.length > 0) {
                filterToApply["integration.CustomerCompanyName"] =  {$in:filterState.companyNames };
                rowsForTouchpoint = rowsForTouchpoint.filter((r) => filterState.companyNames.includes(r.integration.CustomerCompanyName));
              }
              if(filterState.companyGroupNames.length > 0) {
                filterToApply["integration.CustomerCompanyGroupName"] =  {$in:filterState.companyGroupNames };
                rowsForTouchpoint = rowsForTouchpoint.filter((r) => filterState.companyGroupNames.includes(r.integration.CustomerCompanyGroupName));
              }
              if(filterState.range[0] || filterState.range[1]) {
                filterToApply["date"] =  {};
                const startDate = filterState.range[0] || null;
                const endDate = filterState.range[1] || null;
                if(startDate) {
                  filterToApply["date"]["$gte"] = startDate;
                  rowsForTouchpoint = rowsForTouchpoint.filter((r) => new Date(r.date) >= startDate);
                }
                if(endDate) {
                  filterToApply["date"]["$lte"] = endDate;
                  rowsForTouchpoint = rowsForTouchpoint.filter((r) => new Date(r.date) <= endDate);
                }
              }

              const grouped = groupBy(
                rowsForTouchpoint,
                (x) => x.answersCalculated.generalRating,
              );

              const mapping = {
                '5': '#2D8651',
                '4': '#73E0A1',
                '3': '#F0D175',
                '2': '#FFFFFF',
                '1': '#CB2917',
              };
              const data01 = map(grouped, (someRows, key) => ({
                name: 'Note ' + key,
                value: someRows.length,
                percent: Math.floor(
                  100 * (someRows.length / rowsForTouchpoint.length),
                ),
                fill: mapping[key as '1'] || mapping['1'],
              }));

              return (
                <>
                  <Grid item xs={12}>
                    {aTouchpoint.filter ? (
                      <h2>Touchpoint {aTouchpoint.name}</h2>
                    ) : (
                      <h2>{aTouchpoint.name}</h2>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Chart
                      height={semiHeight}
                      filter={filterToApply}
                      chartId={'63807918-886c-4957-87ab-47b77e7828df'}
                      token={token}
                    />
                    <Chart
                      filter={filterToApply}
                      height={semiHeight}
                      chartId={'6380be35-86af-4e56-8078-c00c49fc0f47'}
                      theme="light"
                      token={token}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Chart
                      height={height}
                      filter={filterToApply}
                      chartId={'63807995-91cd-4a9c-8ebb-63c4ba657fc4'}
                      token={token}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Chart
                      filter={filterToApply}
                      height={height + ''}
                      chartId={'6380bd91-7b26-4c88-81ca-222c66b98aeb'}
                      token={token}
                    />
                  </Grid>

                  {/*<Grid item xs={2}>
                <Chart
                  filter={aTouchpoint.filter}
                  height={height}
                  chartId={'6384d27e-7d00-4752-8306-3f5c0c81235c'}
                  token={token}
                />
                </Grid>*/}

                  <Grid item xs={2}>
                    <PieChart width={300} height={250}>
                      <Pie
                        data={data01}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label={({ payload }) => {
                          return payload.payload.name.replace('Note ', '');
                        }}
                      ></Pie>
                      <Tooltip
                        formatter={(value, name, item) => {
                          return `${item.payload.payload.percent}% (${value} occurences)`;
                        }}
                      />
                    </PieChart>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Paper sx={{ position: "sticky", top: "40px" }}>
            <Box sx={{ padding: "20px" }}>
              <h4>Filters</h4>
              <DashboardFilters onChange={onFilterChange} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
