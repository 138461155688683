import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const selectedStyle = {
  fontWeight: '700',
  textDecoration: 'underline',
  textUnderlineOffset: '3px',
};
const notSelectedStyle = { fontWeight: '400' };

export function AppBarCustom({ menu, onNewMenu }: any) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button
            color="inherit"
            onClick={() => onNewMenu('country')}
            style={menu === 'country' ? selectedStyle : notSelectedStyle}
          >
            By country stats
          </Button>
          <Button
            color="inherit"
            onClick={() => onNewMenu('touchpoint')}
            style={menu === 'touchpoint' ? selectedStyle : notSelectedStyle}
          >
            By touchpoint stats
          </Button>
          <Button
            color="inherit"
            onClick={() => onNewMenu('mood')}
            style={menu === 'mood' ? selectedStyle : notSelectedStyle}
          >
            By mood stats
          </Button>
          <Box sx={{ flexGrow: 1 }}>
            <Button
              color="inherit"
              onClick={() => onNewMenu('follow-up')}
              style={menu === 'follow-up' ? selectedStyle : notSelectedStyle}
            >
              My follow up
            </Button>
          </Box>
          {/* This button should be at far right */}

          <Button
            color="inherit"
            onClick={() => {
              localStorage.removeItem('user');
              window.location.reload();
            }}
          >
            Exit <ExitToAppIcon />
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
