import React, { useState, useEffect, useCallback } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { DashboardByCountry } from './pages/DashboardByCountry';
import { DashboardByTouchpoint } from './pages/DashboardByTouchpoint';
import { AppBarCustom } from './AppBar';
import { DashboardByMood } from './pages/DashboardByMood';
import { MyFollowUp } from './pages/MyFollowUp';
import { Login } from './pages/Login';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  CustomXylemAnalysisWithResponse,
  DataContext,
  mapARowToCustomXylemAnalysisWithResponse,
} from './DataContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContext, User } from './AuthContext';
import { XYLEM_BLUE } from './constants';

const theme = createTheme({
  palette: {
    primary: {
      main: XYLEM_BLUE,
    },
    success: {
      main: '#1EA67D',
    },
    error: {
      main: '#F27350',
    },
  },
});

const WrapComponentWithAppBar = ({ children }: any) => {
  const location = useLocation();

  const navigate = useNavigate();

  const [xylemResponses, setXylemResponses] = useState(
    [] as CustomXylemAnalysisWithResponse[]
  );
  const refreshXylemResponses = useCallback(async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + '/getAllDatas'
    );
    const data = await response.json();
    const mappedAndFilteredDatas = data.payload
      .filter((x: any) => x.extendedResponse)
      .map((x: any) => mapARowToCustomXylemAnalysisWithResponse(x));
    setXylemResponses(mappedAndFilteredDatas);
  }, [setXylemResponses]);

  useEffect(() => {
    refreshXylemResponses();
  }, [refreshXylemResponses]);

  const { user } = React.useContext(AuthContext);
  if (process.env.REACT_APP_NOLOGIN !== 'true' && !user) {
    return <Navigate to='/login' />;
  }

  return (
    <ThemeProvider theme={theme}>
      <DataContext.Provider
        value={{ xylemResponses: xylemResponses, refreshXylemResponses }}
      >
        <AppBarCustom
          menu={location.pathname.replace(/^\//, '')}
          onNewMenu={(newMenu: any) => {
            navigate('/' + newMenu);
          }}
        />
        {children}
      </DataContext.Provider>
    </ThemeProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate replace to='/country' />,
  },
  {
    path: '/login',
    element: (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_CLIENT_KEY || ''}
      >
        <Login />
      </GoogleReCaptchaProvider>
    ),
  },
  {
    path: '/country',
    element: (
      <WrapComponentWithAppBar>
        <DashboardByCountry />
      </WrapComponentWithAppBar>
    ),
  },
  {
    path: '/touchpoint',
    element: (
      <WrapComponentWithAppBar>
        <DashboardByTouchpoint />
      </WrapComponentWithAppBar>
    ),
  },
  {
    path: '/mood',
    element: (
      <WrapComponentWithAppBar>
        <DashboardByMood />
      </WrapComponentWithAppBar>
    ),
  },
  {
    path: '/follow-up',
    element: (
      <WrapComponentWithAppBar>
        <MyFollowUp />
      </WrapComponentWithAppBar>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//TODO: we should type properly
const userInLocalStorageString = localStorage.getItem('user') || null;
let userInLocalStorage: User | null = null;
if (userInLocalStorageString) {
  userInLocalStorage = JSON.parse(userInLocalStorageString);
}

const AuthWrapper = ({ children }: any) => {
  const [user, setUser] = useState(userInLocalStorage);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        setUser: (aNewUser) => setUser(aNewUser),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <AuthWrapper>
        <RouterProvider router={router} />
      </AuthWrapper>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
