import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useState, useContext } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AuthContext } from '../AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { sleep } from '../utils/tools';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useSnackbar } from 'notistack';

//users database. Should be put in the backend instead!
const users = [
  //core team
  { email: 'j.chaaban.ext@oppycx.com' },
  { email: 'n.dubourg@oppycx.com' },
  { email: 'sebastien.zuckerman@xylem.com' },

  //general admin
  { email: 'geraud.destexupery@xylem.com' },

  //FR
  { email: 'magalie.veillet@xylem.com' },
  { email: 'aurelie.cheron@xylem.com' },
  { email: 'julien.moulin@xylem.com' },

  //UK
  { email: 'richard.phillips@xylem.com' },
  { email: 'dan.wright@xylem.com' },

  //DE
  { email: 'mirko.franz@xylem.com' },
  { email: 'robin.sternberg@xylem.com' },

  { email: 'ian.thompson@xylem.com' },
  { email: 'roxana.marin@xylem.com' },
  { email: 'Christine.Wall@xylem.com' },
  { email: 'giorgio.sabbatini@xylem.com' },

  { email: 'reynald.megret@xylem.com' },

  {email : 'vincent.idris@xylem.com'},
  {email : 'jeanfrancois.serrault@xylem.com'}
];

export function Login() {
  //TODO: remove
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  if (user) {
    return <Navigate to='/country' />;
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    //TODO: make url configurable
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + '/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login, password, mfaCode }),
      });
      const result = await res.json();
      console.log('resultlogin', result);
      if (!result || !result.user || result.error) {
        throw new Error('login error');
      }
      const userEmail = result.user.email;
      const foundUser = users.find((u) => u.email === userEmail);
      if (foundUser) {
        setUser(result.user);
        localStorage.setItem('user', JSON.stringify(result.user));
        //TODO: ugly!
        enqueueSnackbar('Login successful !', { variant: 'success' });
        await sleep(100);
        navigate('/country');
      }
    } catch (error) {
      enqueueSnackbar('Login failed, please check your data', {
        variant: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        bgcolor: 'background.default',
      }}
    >
      <Typography variant='h4' component='h1' gutterBottom>
        Xylem Dashboards by Omni
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Login'
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <TextField
          label='Password'
          type='password'
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label='MFA Code'
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={mfaCode}
          onChange={(e) => setMfaCode(e.target.value)}
        />
        <Grid
          container
          alignItems={'center'}
          justifyContent={'start'}
          direction={'row'}
          marginBottom={2}
          marginTop={2}
        >
          <Box>
            <Button
              sx={{ m: 0 }}
              type='submit'
              variant='contained'
              color='primary'
            >
              Login
            </Button>
          </Box>
          {loading && (
            <Box marginLeft={2}>
              <CircularProgress size={30} />
            </Box>
          )}
        </Grid>
      </form>
    </Box>
  );
}
