import { allDatasToDisplay } from './CustomXylemAnalysisWithResponses';
import { createContext } from 'react';

type BeforeTransformation = typeof allDatasToDisplay[number];

export const mapARowToCustomXylemAnalysisWithResponse = (
  x: BeforeTransformation,
) => {
  let country = "FR";
  if (x.integration.XylemCountry.match(/uk/i)) {
    country = "UK";
  }
  if (x.integration.XylemCountry.match(/de/i)) {
    country = "DE";
  }
  if (x.integration.XylemCountry.match(/it/i)) {
    country = "IT";
  }
  //TODO: normally x.extendedResponse is always defined because we filtered right before!
  return {
    id: x._id,
    parsedDate: x.date.slice(0, 10),
    contactKey: x.integration.ContactKey,
    country: country,
    customerCompanyName: x.integration.CustomerCompanyName,
    customerCompanyGroupName: x.integration.CustomerCompanyGroupName,
    shouldContactBecauseOfInsatisfaction:
      x.answersCalculated.generalRating < 5 &&
      x.answers.shouldContactBecauseOfInsatisfaction === 'yes'
        ? 'yes'
        : 'no',
    contacted: Boolean((x.extendedResponse?.agentAnalysis as any)?.insatReason)
      ? 'yes'
      : 'no',
    ...x,
  };
};

//TODO: this normally shouldn't be used anymore! Dynamic filters are required!
const getAllRows = () => {
  const result = allDatasToDisplay
    .filter((x) => x.extendedResponse)
    .map((x) => {
      return mapARowToCustomXylemAnalysisWithResponse(x);
    });

  return result;
};

const someRows = getAllRows();
export type CustomXylemAnalysisWithResponse = typeof someRows[number];

export const DataContext = createContext({
  xylemResponses: [] as CustomXylemAnalysisWithResponse[],
  refreshXylemResponses: async () => {}
});
