import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import './PerformanceHeader.css';

type Props = {
  type: 'success' | 'error';
  data: { label: string; tooltip: string; Icon: React.ComponentType<any> }[];
};

export function PerformanceHeader({ type, data }: Props) {
  if (!data || data.length === 0) {
    return <></>;
  }

  const label = `${type === 'success' ? 'Top' : 'Worst'} ${
    data.length > 1 ? `${data.length} categories ` : 'category'
  } `;
  return (
    <Grid item flex={1} pt={'0 !important'} className='performance-header-grid'>
      <Card>
        <CardContent className='performance-header-card-content'>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle2'>{label}</Typography>
          </Box>
          <Grid container justifyContent='center' spacing={4}>
            {data.map(({ label, tooltip, Icon }) => (
              <Grid key={tooltip} item xs={12} sm={6} md={4}>
                <div>
                  <Box sx={{ textAlign: 'center' }} className='icon-box'>
                    <Icon color={type} fontSize='inherit' />
                  </Box>
                  <Typography
                    sx={{ textAlign: 'center' }}
                    variant='body2'
                    color='textSecondary'
                  >
                    {tooltip}
                  </Typography>
                  <Typography
                    sx={{ textAlign: 'center', fontWeight: '700' }}
                    variant='body2'
                    color='textSecondary'
                  >
                    {label}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
