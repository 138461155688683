export const mapping = {
  'promo.all': 'Promotions',
  'prix.pos': 'Price (pos)',
  'prix.neg': 'Price (neg)',
  'prix.autre': 'Price (neu)',
  'rapp.q.p.pos': 'Value for money (pos)',
  'rapp.q.p.neg': 'Value for money (neg)',
  'prix.var.neg': 'Price increase (neg)',
  'abattement.neg': 'Return allowance (neg)',

  'choix.pos': 'Choice (pos)',
  'choix.neg': 'Choice (neg)',
  'dispo.pos': 'Availabity / Stock (pos)',
  'dispo.neg': 'Availability / Stock (neg)',
  'garantie.all': 'Guarantee',
  'manque.produit.neg': 'Missing / Lost product (neg)',
  'abime.neg': 'Damaged product (neg)',
  'fiabilite.pos': 'Reliability (pos)',
  'fiabilite.neg': 'Reliability (neg)',
  'qualite.pos': 'Quality (pos)',
  'qualite.neg': 'Quality (neg)',

  'delai.pos': 'Lead time (pos)',
  'delai.neg': 'Lead time (neg)',
  'delai.autre': 'Lead time (neu)',
  'signature.all': 'Signature (neu)',
  'service.pos': 'Quality of service / Follow-up (pos)',
  'service.neg': 'Quality of service / Follow-up (neg)',
  'refus.all': 'Refusal (neg)',
  'geste.co.all': 'Commercial trade off',
  'joignabilite.pos': 'Reachability (pos)',
  'joignabilite.neg': 'Reachability (neg)',

  'accueil.pos': 'Welcome / Kindness (pos)',
  'accueil.neg': 'Welcome / Kindness (neg)',
  'ecoute.pos': 'Attentiveness and availability (pos)',
  'ecoute.neg': 'Attentiveness and availability (neg)',
  'competence.pos': 'Skills (pos)',
  'competence.neg': 'Skills (neg)',
  'efficacite.pos': 'Efficiency / Reactivity (pos)',
  'efficacite.neg': 'Efficiency / Reactivity / Reminder (neg)',

  'ressenti.pos': 'Feeling (pos)',
  'ressenti.neg': 'Feeling (neg)',
  'ressenti.ras': 'No comment (neu)',
} as Record<string, string>;

export const fromTagToLabel = (tag: string) => {
  return mapping[tag] as unknown as string;
};

export const fromTagPrefixToLightLabel = (tag: string) => {
  return (
    Object.keys(mapping)
      .filter((k) => k.startsWith(tag))
      .map((k) => mapping[k].replaceAll(/\(.+?\)/g, '').trim())[0] || 'Unknown'
  );
};
