import { createContext } from 'react';

export interface User {
  email: string;
}

export const AuthContext = createContext({
  user: null as User | null,
  setUser(user: User | null) {},
});
