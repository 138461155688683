export const allDatasToDisplay = [
    {
        "_id": "637cb9ab98252a64d56ef128",
        "extendedResponse": {
            "_id": "6396d2e669253f0945a50da9",
            "submittedBody": {
                "id": "637cb9ab98252a64d56ef1280.14704021625580643",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2022-11-22T12:59:39+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0610330117"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ee6351ac-038e-4d01-8226-ef768b383c64",
            "responseId": "637cb9ab98252a64d56ef128",
            "receivedResponse": {
                "id": "637cb9ab98252a64d56ef1280.14704021625580643",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2022-11-22T12:59:39Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ],
            "agentAnalysis": {
                "comment": "name1234433"
            }
        },
        "date": "2022-11-22T11:59:39.786Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-22",
        "integration": {
            "ContactKey": "0610330117",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LAFOND Dimitri",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0610330117",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA UDEP DE FLAINE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3604372",
            "XylemReference": "188257",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637cb9ab98252a64d56ef140",
        "extendedResponse": {
            "_id": "6396d2e769253f0945a50daa",
            "submittedBody": {
                "id": "637cb9ab98252a64d56ef1400.04120129819363494",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Report sms",
                "creationDate": "2022-11-22T12:59:39+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0608711635"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "03bd34b3-3992-4a29-8cad-b29dc4508d88",
            "responseId": "637cb9ab98252a64d56ef140",
            "receivedResponse": {
                "id": "637cb9ab98252a64d56ef1400.04120129819363494",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Report sms",
                "creationDate": "2022-11-22T12:59:39Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-22T11:59:39.787Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-22",
        "integration": {
            "ContactKey": "0608711635",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LAMOTTE Philippe",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0608711635",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEIBO CENTRE ST QUENTIN",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CDF65748",
            "XylemReference": "187646",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Report sms",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637cb9ab98252a64d56ef168",
        "extendedResponse": {
            "_id": "6396d2e769253f0945a50dab",
            "submittedBody": {
                "id": "637cb9ab98252a64d56ef1680.5335807389072804",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne expérience avec sérieux, j’ai eu besoin d’aide et l’équipe d’Hydrolys m’a accompagné. Merci pour tout.",
                "creationDate": "2022-11-22T12:59:39+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0665248108"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0f6778a9-a27e-4a01-a510-3bffd5880437",
            "responseId": "637cb9ab98252a64d56ef168",
            "receivedResponse": {
                "id": "637cb9ab98252a64d56ef1680.5335807389072804",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne expérience avec sérieux, j ai eu besoin d aide et l équipe d Hydrolys m a accompagné. Merci pour tout.",
                "creationDate": "2022-11-22T12:59:39Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-22T11:59:39.789Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-22",
        "integration": {
            "ContactKey": "0665248108",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Joaquim SARO",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0665248108",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROLYS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "13344",
            "XylemReference": "188045",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne expérience avec sérieux, j’ai eu besoin d’aide et l’équipe d’Hydrolys m’a accompagné. Merci pour tout.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637cb9ab98252a64d56ef19d",
        "extendedResponse": {
            "_id": "6396d2e769253f0945a50dac",
            "submittedBody": {
                "id": "637cb9ab98252a64d56ef19d0.46108054561326095",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le prix ",
                "creationDate": "2022-11-22T12:59:39+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0686640867"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ac2cc739-286e-459e-819d-26d520ac0731",
            "responseId": "637cb9ab98252a64d56ef19d",
            "receivedResponse": {
                "id": "637cb9ab98252a64d56ef19d0.46108054561326095",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le prix ",
                "creationDate": "2022-11-22T12:59:39Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2022-11-22T11:59:39.793Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-22",
        "integration": {
            "ContactKey": "0686640867",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Olivier BARRET",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0686640867",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MAIRIE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 09/11/2022 - Devis 222079197",
            "XylemReference": "187916",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le prix ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Un prix plus compétitif "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637cb9ab98252a64d56ef205",
        "extendedResponse": {
            "_id": "6396d2f306fa6c05d30a098d",
            "submittedBody": {
                "id": "637cb9ab98252a64d56ef2050.02489491642673003",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison très rapide mais le colis est endommagé et le produit aussi. Je vais devoir le retourner !",
                "creationDate": "2022-11-22T12:59:39+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0672915998"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "19985b07-d19f-4bb6-9978-b4d38c423b2e",
            "responseId": "637cb9ab98252a64d56ef205",
            "receivedResponse": {
                "id": "637cb9ab98252a64d56ef2050.02489491642673003",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison très rapide mais le colis est endommagé et le produit aussi. Je vais devoir le retourner !",
                "creationDate": "2022-11-22T12:59:39Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "abime.neg",
                                    "label": "Damaged product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "abime.neg",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "abime.neg",
                    "label": "Damaged product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-22T11:59:39.804Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-22",
        "integration": {
            "ContactKey": "0672915998",
            "CustomerContactEmail": "ndubourg@oppycx.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Mlle Nathalie DUBOURG",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0672915998",
            "CustomerContactLandlinePhone": "0505050505",
            "CustomerContactRole": "Customer Success Manager",
            "CustomerCompanyName": "Oppy",
            "CustomerCompanyGroupName": "Opportunity",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jeanne",
            "XylemSalespeopleName": "D'Arc",
            "XylemContactedPersonFirstName": "Jacques",
            "XylemContactedPersonName": "Chirac",
            "XylemContactedPersonRole": "President",
            "CustomerReference": "124",
            "XylemReference": "1438",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison très rapide mais le colis est endommagé et le produit aussi. Je vais devoir le retourner !",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Dubourg\",\n  \"phone\": \"0672915998\",\n  \"mail\": \"Dubourgnathalie@yahoo.fr \"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637e2653865ad22403bb0555",
        "extendedResponse": {
            "_id": "6396d2f306fa6c05d30a098f",
            "submittedBody": {
                "id": "637e2653865ad22403bb05550.6603124050991265",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Sms pour livraison ",
                "creationDate": "2022-11-23T14:55:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0608711635"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "50fca4bc-fe94-4e3e-9dda-b9546b2913d3",
            "responseId": "637e2653865ad22403bb0555",
            "receivedResponse": {
                "id": "637e2653865ad22403bb05550.6603124050991265",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Sms pour livraison ",
                "creationDate": "2022-11-23T14:55:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-23T13:55:31.803Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-23",
        "integration": {
            "ContactKey": "0608711635",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LAMOTTE Philippe",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0608711635",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEIBO CENTRE ST QUENTIN",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CDF65909",
            "XylemReference": "188632",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Sms pour livraison ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637e2653865ad22403bb05a5",
        "extendedResponse": {
            "_id": "6396d2f406fa6c05d30a0990",
            "submittedBody": {
                "id": "637e2653865ad22403bb05a50.5552003853878544",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison à la bonne date, tout est ok mais le prix reste élevé ",
                "creationDate": "2022-11-23T14:55:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0672915998"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "b65cc952-7d6c-433e-b40f-ad2f1c638d09",
            "responseId": "637e2653865ad22403bb05a5",
            "receivedResponse": {
                "id": "637e2653865ad22403bb05a50.5552003853878544",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison à la bonne date, tout est ok mais le prix reste élevé ",
                "creationDate": "2022-11-23T14:55:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-11-23T13:55:31.806Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-23",
        "integration": {
            "ContactKey": "0672915998",
            "CustomerContactEmail": "ndubourg@oppycx.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Mlle Nathalie DUBOURG",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0672915998",
            "CustomerContactLandlinePhone": "0505050505",
            "CustomerContactRole": "Customer Success Manager",
            "CustomerCompanyName": "Oppy",
            "CustomerCompanyGroupName": "Opportunity",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jeanne",
            "XylemSalespeopleName": "D'Arc",
            "XylemContactedPersonFirstName": "Jacques",
            "XylemContactedPersonName": "Chirac",
            "XylemContactedPersonRole": "President",
            "CustomerReference": "124",
            "XylemReference": "1438",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison à la bonne date, tout est ok mais le prix reste élevé ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Faire un effort sur le prix "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e2337d",
        "extendedResponse": {
            "_id": "6396d5a71ad159cdbc39f730",
            "submittedBody": {
                "id": "637fb982865ad22403e2337d0.6926903164530769",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai de réponse très rapide, documentation technique de qualité sur les produits concernés par notre demande",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-paul.tabarly@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1a302272-07fa-46d6-97d3-f03485a94002",
            "responseId": "637fb982865ad22403e2337d",
            "receivedResponse": {
                "id": "637fb982865ad22403e2337d0.6926903164530769",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai de réponse très rapide, documentation technique de qualité sur les produits concernés par notre demande",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.836Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "jean-paul.tabarly@veolia.com",
            "CustomerContactEmail": "jean-paul.tabarly@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TABARLY",
            "CustomerContactFirstName": "JEAN-PAUL",
            "CustomerContactMobilePhone": "0611890252",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082955",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai de réponse très rapide, documentation technique de qualité sur les produits concernés par notre demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e2344f",
        "extendedResponse": {
            "_id": "6396d5a71ad159cdbc39f731",
            "submittedBody": {
                "id": "637fb982865ad22403e2344f0.11157817589117092",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Reaction ",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gregoire.lefebvre@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "01c67cff-9b52-475f-8e56-8c002f53e41b",
            "responseId": "637fb982865ad22403e2344f",
            "receivedResponse": {
                "id": "637fb982865ad22403e2344f0.11157817589117092",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Reaction ",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.842Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "gregoire.lefebvre@saur.com",
            "CustomerContactEmail": "gregoire.lefebvre@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEFEBVRE",
            "CustomerContactFirstName": "Grégoire",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082696",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Reaction ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e2345b",
        "extendedResponse": {
            "_id": "6396d5a81ad159cdbc39f732",
            "submittedBody": {
                "id": "637fb982865ad22403e2345b0.2472439494730727",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne communication, échange et conseil ",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christelle.morinet@ingerop.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "49274d3e-68a7-4426-ae2f-f5e6b3a40fad",
            "responseId": "637fb982865ad22403e2345b",
            "receivedResponse": {
                "id": "637fb982865ad22403e2345b0.2472439494730727",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne communication, échange et conseil ",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.843Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "christelle.morinet@ingerop.com",
            "CustomerContactEmail": "christelle.morinet@ingerop.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MORINET",
            "CustomerContactFirstName": "Christelle",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "INGEROP GRAND EST",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082706",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne communication, échange et conseil ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e2346f",
        "extendedResponse": {
            "_id": "6396d5a81ad159cdbc39f733",
            "submittedBody": {
                "id": "637fb982865ad22403e2346f0.905033560664978",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et professionnalisme \n",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aissam.bouhouche@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a4ea7dcd-28d9-4ed3-af5f-e6ca6e02deb6",
            "responseId": "637fb982865ad22403e2346f",
            "receivedResponse": {
                "id": "637fb982865ad22403e2346f0.905033560664978",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et professionnalisme \n",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.845Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "aissam.bouhouche@sade-cgth.fr",
            "CustomerContactEmail": "aissam.bouhouche@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUHOUCHE",
            "CustomerContactFirstName": "AISSAM",
            "CustomerContactMobilePhone": "0646016069",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222035319",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et professionnalisme \n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e2347c",
        "extendedResponse": {
            "_id": "6396d5a81ad159cdbc39f734",
            "submittedBody": {
                "id": "637fb982865ad22403e2347c0.1443843823164248",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les plus:\nLa rapidité de la réponse. Le conseil du commercial.\nLe moins:\nLes délais.",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "anthony.richard@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "557b94d5-e488-423d-897b-cf0ff32c8c10",
            "responseId": "637fb982865ad22403e2347c",
            "receivedResponse": {
                "id": "637fb982865ad22403e2347c0.1443843823164248",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les plus:\nLa rapidité de la réponse. Le conseil du commercial.\nLe moins:\nLes délais.",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.846Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "anthony.richard@suez.com",
            "CustomerContactEmail": "anthony.richard@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RICHARD",
            "CustomerContactFirstName": "Anthony",
            "CustomerContactMobilePhone": "0649732018",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222082744",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les plus:\nLa rapidité de la réponse. Le conseil du commercial.\nLe moins:\nLes délais.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Des délais de livraisons plus court et des \"goodies\" de fin d'année. 😉"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23483",
        "extendedResponse": {
            "_id": "6396d5a91ad159cdbc39f735",
            "submittedBody": {
                "id": "637fb982865ad22403e234830.199844710731641",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pierre-yves.gouillias@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "744ebce9-e60e-4a6c-a2b8-886f3c87bf49",
            "responseId": "637fb982865ad22403e23483",
            "receivedResponse": {
                "id": "637fb982865ad22403e234830.199844710731641",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.846Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "pierre-yves.gouillias@veolia.com",
            "CustomerContactEmail": "pierre-yves.gouillias@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GOUILLIAS",
            "CustomerContactFirstName": "Pierre-Yves",
            "CustomerContactMobilePhone": "0619916365",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "APG STEP LIVERY",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082758",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e2348c",
        "extendedResponse": {
            "_id": "6396d5a91ad159cdbc39f736",
            "submittedBody": {
                "id": "637fb982865ad22403e2348c0.3321594311113889",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité: Impeccable, dommage une erreur sur un devis: j'ai du faire annuler une commande et en refaire une autre. Cela peux arriver.",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "michael.segault@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "3ab3d68f-02aa-44d1-bd51-5efdf0d1d7b1",
            "responseId": "637fb982865ad22403e2348c",
            "receivedResponse": {
                "id": "637fb982865ad22403e2348c0.3321594311113889",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité: Impeccable, dommage une erreur sur un devis: j'ai du faire annuler une commande et en refaire une autre. Cela peux arriver.",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.847Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "michael.segault@suez.com",
            "CustomerContactEmail": "michael.segault@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SEGAULT",
            "CustomerContactFirstName": "Michaël",
            "CustomerContactMobilePhone": "0787752678",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Philippe",
            "XylemContactedPersonName": "CARRARA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222082772",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité: Impeccable, dommage une erreur sur un devis: j'ai du faire annuler une commande et en refaire une autre. Cela peux arriver.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Le bon devis du premier coup pour le PR St Amour"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e234a6",
        "extendedResponse": {
            "_id": "6396d5a91ad159cdbc39f737",
            "submittedBody": {
                "id": "637fb982865ad22403e234a60.5602502717190396",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "benoit.swaenepoel@groupe-sarp.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4da05d06-3095-4295-bd72-dab72403ef78",
            "responseId": "637fb982865ad22403e234a6",
            "receivedResponse": {
                "id": "637fb982865ad22403e234a60.5602502717190396",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.850Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "benoit.swaenepoel@groupe-sarp.com",
            "CustomerContactEmail": "benoit.swaenepoel@groupe-sarp.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SWAENEPOEL",
            "CustomerContactFirstName": "BENOIT",
            "CustomerContactMobilePhone": "0619820507",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BACHELET-BONNEFOND SAS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rachid",
            "XylemSalespeopleName": "ABDELKRIM",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082803",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e234c3",
        "extendedResponse": {
            "_id": "6396d5aa1ad159cdbc39f738",
            "submittedBody": {
                "id": "637fb982865ad22403e234c30.7168872868085756",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse rapide ",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pregnier@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "67aed422-a661-4c4c-a065-242fa69b5a6b",
            "responseId": "637fb982865ad22403e234c3",
            "receivedResponse": {
                "id": "637fb982865ad22403e234c30.7168872868085756",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide ",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.851Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "pregnier@lri-sodime.com",
            "CustomerContactEmail": "pregnier@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REGNIER",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082822",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e234d2",
        "extendedResponse": {
            "_id": "6396d5aa1ad159cdbc39f739",
            "submittedBody": {
                "id": "637fb982865ad22403e234d20.7894078432579952",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "offre détaillée et complète",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "elevieil@soc.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c9e49c05-a395-4de9-9706-bd385deefca0",
            "responseId": "637fb982865ad22403e234d2",
            "receivedResponse": {
                "id": "637fb982865ad22403e234d20.7894078432579952",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "offre détaillée et complète",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.852Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "elevieil@soc.fr",
            "CustomerContactEmail": "elevieil@soc.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "LEVIEIL",
            "CustomerContactFirstName": "EMILIE",
            "CustomerContactMobilePhone": "0645071345",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOC RHONE ALPES et GRANDS PROJETS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082832",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "offre détaillée et complète",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23503",
        "extendedResponse": {
            "_id": "6396d5aa1ad159cdbc39f73a",
            "submittedBody": {
                "id": "637fb982865ad22403e235030.20757869290641873",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "REACTIVITE DE LA REPONSE ET LA REPONSE ELLE MEME",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@first-robinetterie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8455b34a-79ee-4256-950f-c55fd46a6b27",
            "responseId": "637fb982865ad22403e23503",
            "receivedResponse": {
                "id": "637fb982865ad22403e235030.20757869290641873",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RÉACTIVITÉ DE LA RÉPONSE ET LA RÉPONSE ELLE MÊME",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.855Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "commercial@first-robinetterie.com",
            "CustomerContactEmail": "commercial@first-robinetterie.com",
            "CustomerContactSalutation": "Mademoiselle",
            "CustomerContactName": "TZIKUNIB",
            "CustomerContactFirstName": "Karine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FIRST ROBINETTERIE MARSEILLE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082868",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "REACTIVITE DE LA REPONSE ET LA REPONSE ELLE MEME",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23529",
        "extendedResponse": {
            "_id": "6396d5ab1ad159cdbc39f73b",
            "submittedBody": {
                "id": "637fb982865ad22403e235290.118804440536743",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Non respect de la demande ",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.montecot@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "a088f291-dd8b-48cd-9681-158a1510675b",
            "responseId": "637fb982865ad22403e23529",
            "receivedResponse": {
                "id": "637fb982865ad22403e235290.118804440536743",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Non respect de la demande ",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.neg",
                                    "label": "Welcome / Kindness (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "accueil.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.neg",
                    "label": "Welcome / Kindness (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.858Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "christophe.montecot@sofinther.fr",
            "CustomerContactEmail": "christophe.montecot@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONTECOT",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0662906952",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082872",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Non respect de la demande ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e235ef",
        "extendedResponse": {
            "_id": "6396d5ab1ad159cdbc39f73c",
            "submittedBody": {
                "id": "637fb982865ad22403e235ef0.24020541748195146",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité a répondre une demande prix ",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "andre.david@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "462afd14-3717-4902-88ca-f2c91f82a576",
            "responseId": "637fb982865ad22403e235ef",
            "receivedResponse": {
                "id": "637fb982865ad22403e235ef0.24020541748195146",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité a répondre une demande prix ",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.865Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "andre.david@veolia.com",
            "CustomerContactEmail": "andre.david@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DAVID",
            "CustomerContactFirstName": "ANDRE",
            "CustomerContactMobilePhone": "0646211297",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222082995",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité a répondre une demande prix ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23623",
        "extendedResponse": {
            "_id": "6396d5ab1ad159cdbc39f73d",
            "submittedBody": {
                "id": "637fb982865ad22403e236230.017684239692301595",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Service parfait et rapide \n",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "m.ipoutcha@agur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "001dd48d-f321-45a1-a536-68a661dfb2ca",
            "responseId": "637fb982865ad22403e23623",
            "receivedResponse": {
                "id": "637fb982865ad22403e236230.017684239692301595",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Service parfait et rapide \n",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.866Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "m.ipoutcha@agur.fr",
            "CustomerContactEmail": "m.ipoutcha@agur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "IPOUTCHA",
            "CustomerContactFirstName": "Michel",
            "CustomerContactMobilePhone": "0677834960",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AGUR",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083004",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Service parfait et rapide \n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23625",
        "extendedResponse": {
            "_id": "6396d5ac1ad159cdbc39f73e",
            "submittedBody": {
                "id": "637fb982865ad22403e236250.5484177719880132",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de la réponse.",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.gardet@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cb99a490-895f-449a-855c-cfc1fbc9421a",
            "responseId": "637fb982865ad22403e23625",
            "receivedResponse": {
                "id": "637fb982865ad22403e236250.5484177719880132",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de la réponse.",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.866Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "david.gardet@suez.com",
            "CustomerContactEmail": "david.gardet@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GARDET",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "0672880294",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083008",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de la réponse.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23631",
        "extendedResponse": {
            "_id": "6396d5ac1ad159cdbc39f73f",
            "submittedBody": {
                "id": "637fb982865ad22403e236310.7172997292425338",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Au-delà de la prise en compte des aspects techniques de nos demandes de chiffrage, ce qui compte pour nous c'est la prise en compte par vos équipes de nos contraintes de délais de réponse à nos consultations.\nActuellement vos équipes répondent parfaitement sur cet aspect et c'est important pour nous d'avoir confiance dans votre fiabilité sur cet aspect précis.",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.solier@alliance-env.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "8345bc11-065c-4533-b8cf-8b5c9a1bc400",
            "responseId": "637fb982865ad22403e23631",
            "receivedResponse": {
                "id": "637fb982865ad22403e236310.7172997292425338",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Au-delà de la prise en compte des aspects techniques de nos demandes de chiffrage, ce qui compte pour nous c'est la prise en compte par vos équipes de nos contraintes de délais de réponse à nos consultations.\nActuellement vos équipes répondent parfaitement sur cet aspect et c'est important pour nous d'avoir confiance dans votre fiabilité sur cet aspect précis.",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.867Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "s.solier@alliance-env.fr",
            "CustomerContactEmail": "s.solier@alliance-env.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SOLIER",
            "CustomerContactFirstName": "Samuel",
            "CustomerContactMobilePhone": "0772139247",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CANONGE & BIALLEZ (GROUPE ALLIANCE)",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222083003",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Au-delà de la prise en compte des aspects techniques de nos demandes de chiffrage, ce qui compte pour nous c'est la prise en compte par vos équipes de nos contraintes de délais de réponse à nos consultations.\nActuellement vos équipes répondent parfaitement sur cet aspect et c'est important pour nous d'avoir confiance dans votre fiabilité sur cet aspect précis.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Je n'ai pas mis 5/5 car sur certains dossiers des contraintes techniques d'étude n'ont pas été vue en prim abord par vos équipes. Egalement nous avons rencontré des problèmes dans la logistique sur la livraison de certains équipements (station de relevage fibre, pompes assainissement, ...)\nCes aspects ont bien entendu été remontés par nos soins à nos contacts commerciaux et la prise en compte des problèmes rencontrés a bien été gérée par vos équipes."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23640",
        "extendedResponse": {
            "_id": "6396d5ac1ad159cdbc39f740",
            "submittedBody": {
                "id": "637fb982865ad22403e236400.22756075929759478",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "-Rapidité d'envoi du devis suite à la demande par mail\n-Deux propositions sur le devis, à l'identique de ce qui est en place et une version plus moderne",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "victor.gervais@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "95859fd7-d58b-41b8-bce7-5da436aaab7a",
            "responseId": "637fb982865ad22403e23640",
            "receivedResponse": {
                "id": "637fb982865ad22403e236400.22756075929759478",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "-Rapidité d'envoi du devis suite à la demande par mail\n-Deux propositions sur le devis, à l'identique de ce qui est en place et une version plus moderne",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.868Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-24",
        "integration": {
            "ContactKey": "victor.gervais@veolia.com",
            "CustomerContactEmail": "victor.gervais@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GERVAIS",
            "CustomerContactFirstName": "Victor",
            "CustomerContactMobilePhone": "0615881084",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU CGE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN (L)",
            "XylemContactedPersonFirstName": "Nicolas",
            "XylemContactedPersonName": "BRUN (L)",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222083040",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "-Rapidité d'envoi du devis suite à la demande par mail\n-Deux propositions sur le devis, à l'identique de ce qui est en place et une version plus moderne",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb98298252a64d50fbc8f",
        "extendedResponse": {
            "_id": "6396d5ad1ad159cdbc39f741",
            "submittedBody": {
                "id": "637fb98298252a64d50fbc8f0.6045123446393508",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tous est parfait. Merci pour votre professionnalisme.",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0632976735"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "25fc909d-8f74-49bf-af1f-2a666c33d37a",
            "responseId": "637fb98298252a64d50fbc8f",
            "receivedResponse": {
                "id": "637fb98298252a64d50fbc8f0.6045123446393508",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tous est parfait. Merci pour votre professionnalisme.",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.874Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-24",
        "integration": {
            "ContactKey": "0632976735",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LHERNAULT KEN",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0632976735",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "IFB ENVIRONNEMENT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "2022,09,15",
            "XylemReference": "179823",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tous est parfait. Merci pour votre professionnalisme.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb98298252a64d50fbcac",
        "extendedResponse": {
            "_id": "6396d5ad1ad159cdbc39f742",
            "submittedBody": {
                "id": "637fb98298252a64d50fbcac0.8921358906144032",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Parfait ",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0661658384"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dd4d0749-799d-45fb-9878-fedffd92532e",
            "responseId": "637fb98298252a64d50fbcac",
            "receivedResponse": {
                "id": "637fb98298252a64d50fbcac0.8921358906144032",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Parfait ",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.875Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-24",
        "integration": {
            "ContactKey": "0661658384",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BRUNO MONICO",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0661658384",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BOUYGUES ENERGIES ET SERVICES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "HE14202439",
            "XylemReference": "189267",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Parfait ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb98298252a64d50fbcbb",
        "extendedResponse": {
            "_id": "6396d5ad1ad159cdbc39f743",
            "submittedBody": {
                "id": "637fb98298252a64d50fbcbb0.07535724989603709",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité  d'expédition  et le professionnalisme du transporteur.",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0616161858"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "96221650-1c32-49e1-a115-83e8c74b03c3",
            "responseId": "637fb98298252a64d50fbcbb",
            "receivedResponse": {
                "id": "637fb98298252a64d50fbcbb0.07535724989603709",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité d'expédition et le professionnalisme du transporteur.",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.875Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-24",
        "integration": {
            "ContactKey": "0616161858",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Philippe DEMON",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0616161858",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MOTRALEC",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF HER042110",
            "XylemReference": "189088",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité  d'expédition  et le professionnalisme du transporteur.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb98298252a64d50fbcc1",
        "extendedResponse": {
            "_id": "6396d5ae1ad159cdbc39f744",
            "submittedBody": {
                "id": "637fb98298252a64d50fbcc10.46179384042986804",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0634075056"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d2c89050-574d-4d17-9699-d58143b950b2",
            "responseId": "637fb98298252a64d50fbcc1",
            "receivedResponse": {
                "id": "637fb98298252a64d50fbcc10.46179384042986804",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.875Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-24",
        "integration": {
            "ContactKey": "0634075056",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "MARINO Thomas",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0634075056",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3608816",
            "XylemReference": "188932",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb98298252a64d50fbccb",
        "extendedResponse": {
            "_id": "6396d5ae1ad159cdbc39f745",
            "submittedBody": {
                "id": "637fb98298252a64d50fbccb0.3960173130396176",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai respecté, très rapide!! Rien à dire.",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0621594206"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b7b772b9-dcc6-4ddb-8d4a-93e2eaab0da1",
            "responseId": "637fb98298252a64d50fbccb",
            "receivedResponse": {
                "id": "637fb98298252a64d50fbccb0.3960173130396176",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai respecté, très rapide!! Rien à dire.",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.876Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-24",
        "integration": {
            "ContactKey": "0621594206",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LAFONTAINE GREGORY",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0621594206",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3609970",
            "XylemReference": "189083",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai respecté, très rapide!! Rien à dire.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e2373c",
        "extendedResponse": {
            "_id": "6396d5af1ad159cdbc39f747",
            "submittedBody": {
                "id": "637fb982865ad22403e2373c0.37421394146239817",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon contact avec vos collaborateurs. Produits intéressants.\nA voir démonstration sur site. (prévue courant décembre prochain)",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hlesaulnier@rme.saint-malo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3df6a17c-8756-48a0-a1cc-8631416ee1c1",
            "responseId": "637fb982865ad22403e2373c",
            "receivedResponse": {
                "id": "637fb982865ad22403e2373c0.37421394146239817",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon contact avec vos collaborateurs. Produits intéressants.\nA voir démonstration sur site. (prévue courant décembre prochain)",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.899Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-11-24",
        "integration": {
            "ContactKey": "hlesaulnier@rme.saint-malo.fr",
            "CustomerContactEmail": "hlesaulnier@rme.saint-malo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE SAULNIER",
            "CustomerContactFirstName": "Hubert",
            "CustomerContactMobilePhone": "0698524779",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "REGIE MALOUINE DE L'EAU (RME)",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Fabrice",
            "XylemContactedPersonName": "ROBIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon contact avec vos collaborateurs. Produits intéressants.\nA voir démonstration sur site. (prévue courant décembre prochain)",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "637fb982865ad22403e23746",
        "extendedResponse": {
            "_id": "6396d5af1ad159cdbc39f748",
            "submittedBody": {
                "id": "637fb982865ad22403e237460.6646714058633403",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "j'ai vu personne de chez vous depuis 1 an désolé !!!",
                "creationDate": "2022-11-24T19:35:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "josselin.rambaud@eau-vapeur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "2256c5e7-ffb9-4acb-9325-decbb6ec66b4",
            "responseId": "637fb982865ad22403e23746",
            "receivedResponse": {
                "id": "637fb982865ad22403e237460.6646714058633403",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "j'ai vu personne de chez vous depuis 1 an désolé !!!",
                "creationDate": "2022-11-24T19:35:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-24T18:35:46.899Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-11-24",
        "integration": {
            "ContactKey": "josselin.rambaud@eau-vapeur.fr",
            "CustomerContactEmail": "josselin.rambaud@eau-vapeur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RAMBAUD",
            "CustomerContactFirstName": "Josselin",
            "CustomerContactMobilePhone": "0607517796",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAU & VAPEUR",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Christian",
            "XylemContactedPersonName": "DODDI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "j'ai vu personne de chez vous depuis 1 an désolé !!!",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"phone\": \"0607517796\",\n  \"name\": \"JOSSELIN RAMBAUD\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da61a",
        "extendedResponse": {
            "_id": "6396d5b01ad159cdbc39f74a",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da61a0.7358594521349175",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité de vos équipes, la qualité des produits. ",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lucien.tomasi@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3112ce0f-8bb8-4c04-9534-71b2de3ed8fd",
            "responseId": "6380ea0f453b5c1c865da61a",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da61a0.7358594521349175",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité de vos équipes, la qualité des produits. ",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.363Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "lucien.tomasi@veolia.com",
            "CustomerContactEmail": "lucien.tomasi@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TOMASI",
            "CustomerContactFirstName": "Lucien",
            "CustomerContactMobilePhone": "0620752036",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KYRNOLIA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083146",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité de vos équipes, la qualité des produits. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da651",
        "extendedResponse": {
            "_id": "6396d5b01ad159cdbc39f74b",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da6510.7410333655937824",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "\n\n\nAmabilité, conseil et réactivité\n",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vvantrimpont@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c2a98e9d-674a-4b91-9bea-6abe504aca1d",
            "responseId": "6380ea0f453b5c1c865da651",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da6510.7410333655937824",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "\n\n\nAmabilité, conseil et réactivité\n",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.366Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "vvantrimpont@gressier.net",
            "CustomerContactEmail": "vvantrimpont@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANTRIMPONT",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0626329823",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER ET FILS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Corinne",
            "XylemContactedPersonName": "LAQUAINE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222083055",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "\n\n\nAmabilité, conseil et réactivité\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da663",
        "extendedResponse": {
            "_id": "6396d5b01ad159cdbc39f74c",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da6630.8709195699220893",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Il est difficile d'avoir des réponses rapides (-24h) comparé à avant . On est obligé de remuer ciel et terre pour avoir une réponse concrète pour un dossier et de faire appel au commercial. Par rapport à d'habitude, je suis déçu, beaucoup de perte de temps inutiles.",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emiliebranjonneau@mei-services.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "99f30d3d-c856-4779-883c-a0521c6433ac",
            "responseId": "6380ea0f453b5c1c865da663",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da6630.8709195699220893",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Il est difficile d'avoir des réponses rapides (-24h) comparé à avant . On est obligé de remuer ciel et terre pour avoir une réponse concrète pour un dossier et de faire appel au commercial. Par rapport à d'habitude, je suis déçu, beaucoup de perte de temps inutiles.",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.367Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "emiliebranjonneau@mei-services.fr",
            "CustomerContactEmail": "emiliebranjonneau@mei-services.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "BRANJONNEAU",
            "CustomerContactFirstName": "Emilie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MEI SERVICES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Corinne",
            "XylemContactedPersonName": "LAQUAINE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222083079",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Il est difficile d'avoir des réponses rapides (-24h) comparé à avant . On est obligé de remuer ciel et terre pour avoir une réponse concrète pour un dossier et de faire appel au commercial. Par rapport à d'habitude, je suis déçu, beaucoup de perte de temps inutiles.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da687",
        "extendedResponse": {
            "_id": "6396d5b11ad159cdbc39f74d",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da6870.18495897022058028",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité ",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aubin.salvan@tereva.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "459ed6fc-64d3-448f-9626-9f79f14f0462",
            "responseId": "6380ea0f453b5c1c865da687",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da6870.18495897022058028",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité ",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.369Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "aubin.salvan@tereva.fr",
            "CustomerContactEmail": "aubin.salvan@tereva.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SALVAN",
            "CustomerContactFirstName": "Aubin",
            "CustomerContactMobilePhone": "0555866137",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREVA BRIVE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222083093",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da6ba",
        "extendedResponse": {
            "_id": "6396d5b11ad159cdbc39f74e",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da6ba0.4439967274744345",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fperret@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "66d52a93-6be9-49df-9f7b-63fbc9efe01c",
            "responseId": "6380ea0f453b5c1c865da6ba",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da6ba0.4439967274744345",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.372Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "fperret@lri-sodime.com",
            "CustomerContactEmail": "fperret@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PERRET",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0764059103",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LRI - LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222083162",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da6c6",
        "extendedResponse": {
            "_id": "6396d5b11ad159cdbc39f74f",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da6c60.7411960229794035",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Positif: réactivité sur envoi des offres et délais",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arnaud.silhouette@herve-thermique.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a332d879-4d9f-4442-9d98-c62882e440fb",
            "responseId": "6380ea0f453b5c1c865da6c6",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da6c60.7411960229794035",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Positif: réactivité sur envoi des offres et délais",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "delai.autre",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.374Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "arnaud.silhouette@herve-thermique.com",
            "CustomerContactEmail": "arnaud.silhouette@herve-thermique.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SILHOUETTE",
            "CustomerContactFirstName": "Arnaud",
            "CustomerContactMobilePhone": "0682805745",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HERVE THERMIQUE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083178",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Positif: réactivité sur envoi des offres et délais",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Optimisation des prix"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da713",
        "extendedResponse": {
            "_id": "6396d5b21ad159cdbc39f751",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da7130.3560986156422743",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Fabien toujours disponible et à l’écoute de ses clients, rapidité d’exécution de l’offre et de l’envoi et prix correct ",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien@coran-fils.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6ee3d87d-6d06-4007-a639-f0ad5838d4a7",
            "responseId": "6380ea0f453b5c1c865da713",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da7130.3560986156422743",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Fabien toujours disponible et à l écoute de ses clients, rapidité d exécution de l offre et de l envoi et prix correct ",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.pos",
                                    "label": "Price (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.pos",
                "dispo.pos",
                "delai.pos",
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.pos",
                    "label": "Price (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.377Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "sebastien@coran-fils.fr",
            "CustomerContactEmail": "sebastien@coran-fils.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CORAN",
            "CustomerContactFirstName": "SEBASTIEN",
            "CustomerContactMobilePhone": "0683632777",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CORAN ET FILS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083282",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Fabien toujours disponible et à l’écoute de ses clients, rapidité d’exécution de l’offre et de l’envoi et prix correct ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da706",
        "extendedResponse": {
            "_id": "6396d5b21ad159cdbc39f750",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da7060.33100850538607896",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Excellent contact avec le commercial de notre secteur (Nicolas Charin), très professionnel et réactif.\nEn revanche beaucoup de difficultés de compréhension rencontrées avec les consultations centralisées via l'outil Veolia Pumps... Des offres souvent à \"côté de la plaque\" avec obligation d'échanges téléphoniques (de mon initiative) et réouverture de dossiers pour présentation d'une nouvelle offre adaptée.",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "camille.gamberi@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "36898a82-4b01-49dc-bad8-67cbd1a523ea",
            "responseId": "6380ea0f453b5c1c865da706",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da7060.33100850538607896",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Excellent contact avec le commercial de notre secteur (Nicolas Charin), très professionnel et réactif.\nEn revanche beaucoup de difficultés de compréhension rencontrées avec les consultations centralisées via l'outil Veolia Pumps... Des offres souvent à \"côté de la plaque\" avec obligation d'échanges téléphoniques (de mon initiative) et réouverture de dossiers pour présentation d'une nouvelle offre adaptée.",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "accueil.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.377Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "camille.gamberi@veolia.com",
            "CustomerContactEmail": "camille.gamberi@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GAMBERI",
            "CustomerContactFirstName": "Camille",
            "CustomerContactMobilePhone": "0612395993",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (STEP ANTIBES)",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222083264",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Excellent contact avec le commercial de notre secteur (Nicolas Charin), très professionnel et réactif.\nEn revanche beaucoup de difficultés de compréhension rencontrées avec les consultations centralisées via l'outil Veolia Pumps... Des offres souvent à \"côté de la plaque\" avec obligation d'échanges téléphoniques (de mon initiative) et réouverture de dossiers pour présentation d'une nouvelle offre adaptée.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Ne pas hésiter à me contacter avant de répondre à un dossier Veolia Pumps pour bien comprendre le besoin et éviter les incohérences ou propositions inadaptées."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da761",
        "extendedResponse": {
            "_id": "6396d5b31ad159cdbc39f753",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da7610.43668422151582065",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité  très bonne",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "n.bougherara@fern-energies.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ee9bddfd-8ca6-4dbd-b037-7c29e4513fe0",
            "responseId": "6380ea0f453b5c1c865da761",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da7610.43668422151582065",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité très bonne",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.394Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "n.bougherara@fern-energies.fr",
            "CustomerContactEmail": "n.bougherara@fern-energies.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUGHERARA",
            "CustomerContactFirstName": "Nadir",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FERN ENERGIES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083262",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité  très bonne",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da75d",
        "extendedResponse": {
            "_id": "6396d5b21ad159cdbc39f752",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da75d0.9605848763555576",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La clareté du devis + réactivité",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "v.biagetti@materiauxtp.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "139c88ee-12e9-4d1c-a92e-fde77a6bae56",
            "responseId": "6380ea0f453b5c1c865da75d",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da75d0.9605848763555576",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La clarté du devis + réactivité",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.394Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "v.biagetti@materiauxtp.fr",
            "CustomerContactEmail": "v.biagetti@materiauxtp.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "BIAGETTI",
            "CustomerContactFirstName": "VICTORIA",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MTP 65",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "SEBASTIEN",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083275",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La clareté du devis + réactivité",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Que la réactivité perdure sur nos prochaines demandes."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da76d",
        "extendedResponse": {
            "_id": "6396d5b31ad159cdbc39f754",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da76d0.1436429912717898",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "je n'ai pas eu de discussion avec celui ou celle qui m'a fait le devis. \nil n'y a pas eu d'entretien avec votre service commercial de notre région occitanie concernant ma demande qui a été traité avec plus de un mois de retard.",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cedric.hollender@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "c0241f9c-dc97-442d-899f-ff6e3a4e6dc9",
            "responseId": "6380ea0f453b5c1c865da76d",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da76d0.1436429912717898",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "je n'ai pas eu de discussion avec celui ou celle qui m'a fait le devis. \nil n'y a pas eu d'entretien avec votre service commercial de notre région occitanie concernant ma demande qui a été traité avec plus de un mois de retard.",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.395Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "cedric.hollender@suez.com",
            "CustomerContactEmail": "cedric.hollender@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HOLLENDER",
            "CustomerContactFirstName": "Cedric",
            "CustomerContactMobilePhone": "0633151228",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083354",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "je n'ai pas eu de discussion avec celui ou celle qui m'a fait le devis. \nil n'y a pas eu d'entretien avec votre service commercial de notre région occitanie concernant ma demande qui a été traité avec plus de un mois de retard.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f453b5c1c865da776",
        "extendedResponse": {
            "_id": "6396d5b31ad159cdbc39f755",
            "submittedBody": {
                "id": "6380ea0f453b5c1c865da7760.4076969788033096",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Plus : la qualité des produits , le support technique \n\nMoins : les délais de réponse qq fois un peu long , les délais de livraison  parfois très long  même si on sait que c'est compliqué en ce moment , et on aimerait être tenu  au courant des reports sans devoir relancer à chaque fois .",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cacan.philippe@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "237a7b53-89db-449f-b20a-f7c130f161e4",
            "responseId": "6380ea0f453b5c1c865da776",
            "receivedResponse": {
                "id": "6380ea0f453b5c1c865da7760.4076969788033096",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Plus : la qualité des produits , le support technique \n\nMoins : les délais de réponse quelques fois un peu long , les délais de livraison parfois très long même si on sait que c'est compliqué en ce moment , et on aimerait être tenu au courant des reports sans devoir relancer à chaque fois .",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.395Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-11-25",
        "integration": {
            "ContactKey": "cacan.philippe@sade-cgth.fr",
            "CustomerContactEmail": "cacan.philippe@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CACAN",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0615103461",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE - CENTRE DES TRAVAUX D'ARTOIS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Reynald",
            "XylemContactedPersonName": "MEGRET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083367",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Plus : la qualité des produits , le support technique \n\nMoins : les délais de réponse qq fois un peu long , les délais de livraison  parfois très long  même si on sait que c'est compliqué en ce moment , et on aimerait être tenu  au courant des reports sans devoir relancer à chaque fois .",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "voir question 1 "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f98252a64d5b0e7ba",
        "extendedResponse": {
            "_id": "6396d5b41ad159cdbc39f757",
            "submittedBody": {
                "id": "6380ea0f98252a64d5b0e7ba0.8301653887963383",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien de bon ",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.roy@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "862942f5-ae15-4274-92e6-961fae6f847d",
            "responseId": "6380ea0f98252a64d5b0e7ba",
            "receivedResponse": {
                "id": "6380ea0f98252a64d5b0e7ba0.8301653887963383",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien de bon ",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.822Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2022-11-25",
        "integration": {
            "ContactKey": "stephane.roy@veolia.com",
            "CustomerContactEmail": "stephane.roy@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROY",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "0609270592",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOMES ASSAINISSEMENT (SECTEUR LANGUEDOC ROUSSILLON)",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "BORSALI",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "BC 43 1575",
            "XylemReference": "2RF046106",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien de bon ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f865ad22403a9ee9f",
        "extendedResponse": {
            "_id": "6396d5b51ad159cdbc39f759",
            "submittedBody": {
                "id": "6380ea0f865ad22403a9ee9f0.8293415143274001",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "1 semaine pour livrer un circulateur \nInadmissible ",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0645164490"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "e1282e26-c36f-4ccc-b6b2-f3e1a21d1fb7",
            "responseId": "6380ea0f865ad22403a9ee9f",
            "receivedResponse": {
                "id": "6380ea0f865ad22403a9ee9f0.8293415143274001",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "1 semaine pour livrer un circulateur \nInadmissible ",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.852Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-25",
        "integration": {
            "ContactKey": "0645164490",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "PORTRAT Fabien",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0645164490",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MCP SA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 18/11/22 - Devis 222080618",
            "XylemReference": "189011",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "1 semaine pour livrer un circulateur \nInadmissible ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f865ad22403a9ee9a",
        "extendedResponse": {
            "_id": "6396d5b41ad159cdbc39f758",
            "submittedBody": {
                "id": "6380ea0f865ad22403a9ee9a0.8609952561814986",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délais court ",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0629915852"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "aebebf85-95d6-4cf7-9385-317dee9ffc28",
            "responseId": "6380ea0f865ad22403a9ee9a",
            "receivedResponse": {
                "id": "6380ea0f865ad22403a9ee9a0.8609952561814986",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délais court ",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.852Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-25",
        "integration": {
            "ContactKey": "0629915852",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "NADAL David",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0629915852",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3605660",
            "XylemReference": "189148",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délais court ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6380ea0f865ad22403a9eed3",
        "extendedResponse": {
            "_id": "6396d5b51ad159cdbc39f75a",
            "submittedBody": {
                "id": "6380ea0f865ad22403a9eed30.21487739642843806",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité du technico-commercial a été très appréciable",
                "creationDate": "2022-11-25T17:15:11+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0646445175"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "95e9f7a6-061f-4757-8c62-d7c8bfbc2012",
            "responseId": "6380ea0f865ad22403a9eed3",
            "receivedResponse": {
                "id": "6380ea0f865ad22403a9eed30.21487739642843806",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité du technico-commercial a été très appréciable",
                "creationDate": "2022-11-25T17:15:11Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-25T16:15:11.854Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-11-25",
        "integration": {
            "ContactKey": "0646445175",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "FRANTZ CHAPPAT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0646445175",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NALCO",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "4504580422",
            "XylemReference": "189259",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité du technico-commercial a été très appréciable",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac8509609ff9ca7254d2",
        "extendedResponse": {
            "_id": "6396d5b61ad159cdbc39f75c",
            "submittedBody": {
                "id": "6384ac8509609ff9ca7254d20.9426609109608919",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Super moment passer avec votre  commercial personne très professionnel et réactif sur nos besoin d'approvisionnement.\n\nMr Behlouli \n",
                "creationDate": "2022-11-28T13:41:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fouzi.behlouli@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "295f1ad4-3b0e-49eb-8cc9-1c470c54be33",
            "responseId": "6384ac8509609ff9ca7254d2",
            "receivedResponse": {
                "id": "6384ac8509609ff9ca7254d20.9426609109608919",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Super moment passer avec votre commercial personne très professionnel et réactif sur nos besoin d'approvisionnement.\n\nMr Behlouli \n",
                "creationDate": "2022-11-28T13:41:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:41:41.729Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221128",
        "integration": {
            "ContactKey": "fouzi.behlouli@dalkia.fr",
            "CustomerContactEmail": "fouzi.behlouli@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FOUZI",
            "CustomerContactFirstName": "BEHLOULI",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA ARCEUIL",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Super moment passer avec votre  commercial personne très professionnel et réactif sur nos besoin d'approvisionnement.\n\nMr Behlouli \n",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac8509609ff9ca7254d3",
        "extendedResponse": {
            "_id": "6396d5b61ad159cdbc39f75d",
            "submittedBody": {
                "id": "6384ac8509609ff9ca7254d30.7268600868781239",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "genial",
                "creationDate": "2022-11-28T13:41:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "franck.guillaud@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "371315d9-4abd-4d8b-82ea-76c8e0fe9658",
            "responseId": "6384ac8509609ff9ca7254d3",
            "receivedResponse": {
                "id": "6384ac8509609ff9ca7254d30.7268600868781239",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "génial",
                "creationDate": "2022-11-28T13:41:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-28T12:41:41.729Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221128",
        "integration": {
            "ContactKey": "franck.guillaud@dalkia.fr",
            "CustomerContactEmail": "franck.guillaud@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUILLAUD",
            "CustomerContactFirstName": "FRANCK",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA ARCEUIL",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "genial",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac8509609ff9ca7254d6",
        "extendedResponse": {
            "_id": "6396d5b61ad159cdbc39f75e",
            "submittedBody": {
                "id": "6384ac8509609ff9ca7254d60.40184964873699003",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Découverte de équipements XYLEM.",
                "creationDate": "2022-11-28T13:41:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ldumitrache@saga-tertiaire.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fe3c8207-b5fc-4cef-b0f1-aec1f1b70f63",
            "responseId": "6384ac8509609ff9ca7254d6",
            "receivedResponse": {
                "id": "6384ac8509609ff9ca7254d60.40184964873699003",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Découverte de équipements XYLEM.",
                "creationDate": "2022-11-28T13:41:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-28T12:41:41.729Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221128",
        "integration": {
            "ContactKey": "ldumitrache@saga-tertiaire.fr",
            "CustomerContactEmail": "ldumitrache@saga-tertiaire.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DUMITRACHE",
            "CustomerContactFirstName": "Luciana",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAGA TERTIAIRE",
            "CustomerCompanyGroupName": "SAGA TERTIAIRE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "NOIROT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Découverte de équipements XYLEM.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac8509609ff9ca7254de",
        "extendedResponse": {
            "_id": "6396d5b71ad159cdbc39f75f",
            "submittedBody": {
                "id": "6384ac8509609ff9ca7254de0.3952561290424368",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Spontanéité, réponse technique de qualité et disponibilité",
                "creationDate": "2022-11-28T13:41:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aissa-kadri@delacommune-dumont.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "28a36648-71cc-4167-9bbf-d8263f31c034",
            "responseId": "6384ac8509609ff9ca7254de",
            "receivedResponse": {
                "id": "6384ac8509609ff9ca7254de0.3952561290424368",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Spontanéité, réponse technique de qualité et disponibilité",
                "creationDate": "2022-11-28T13:41:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:41:41.729Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221128",
        "integration": {
            "ContactKey": "aissa-kadri@delacommune-dumont.fr",
            "CustomerContactEmail": "aissa-kadri@delacommune-dumont.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "KADRI",
            "CustomerContactFirstName": "AISSA",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DELACOMMUNE ET DUMONT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "NOIROT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Spontanéité, réponse technique de qualité et disponibilité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac8509609ff9ca7254e3",
        "extendedResponse": {
            "_id": "6396d5b71ad159cdbc39f760",
            "submittedBody": {
                "id": "6384ac8509609ff9ca7254e30.8636998976206871",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "- La démarche de venir se présenter à ses nouveaux clients à été très appréciable\n- De bons échanges et la découverte de produits proposés par Xylem dont nous n'avions pas connaissance et pour lesquels nous ne vous consultions pas\n- Bonne connaissance des produits de la gamme\n- Grande réactivité sur les problématiques soulevées",
                "creationDate": "2022-11-28T13:41:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "f.ngassu@bouygues-es.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f0ef7c7b-2a64-4acc-9263-504e982e7884",
            "responseId": "6384ac8509609ff9ca7254e3",
            "receivedResponse": {
                "id": "6384ac8509609ff9ca7254e30.8636998976206871",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "- La démarche de venir se présenter à ses nouveaux clients à été très appréciable\n- De bons échanges et la découverte de produits proposés par Xylem dont nous n'avions pas connaissance et pour lesquels nous ne vous consultions pas\n- Bonne connaissance des produits de la gamme\n- Grande réactivité sur les problématiques soulevées",
                "creationDate": "2022-11-28T13:41:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:41:41.730Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221128",
        "integration": {
            "ContactKey": "f.ngassu@bouygues-es.com",
            "CustomerContactEmail": "f.ngassu@bouygues-es.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "NGASSU",
            "CustomerContactFirstName": "FRANCK",
            "CustomerContactMobilePhone": "764793055",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BOUYGUES ENERGIES ET SERVICES",
            "CustomerCompanyGroupName": "BOUYGUES BATIMENT IDF S28X",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "NOIROT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "- La démarche de venir se présenter à ses nouveaux clients à été très appréciable\n- De bons échanges et la découverte de produits proposés par Xylem dont nous n'avions pas connaissance et pour lesquels nous ne vous consultions pas\n- Bonne connaissance des produits de la gamme\n- Grande réactivité sur les problématiques soulevées",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e54c6",
        "extendedResponse": {
            "_id": "6396d5b71ad159cdbc39f761",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e54c60.08107610650374197",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse à ma demande.",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jdartigues@emma40.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "071ac4f6-3bb8-4027-8ca7-a573c5b00130",
            "responseId": "6384ac9d474566e19b0e54c6",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e54c60.08107610650374197",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse à ma demande.",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.491Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "jdartigues@emma40.fr",
            "CustomerContactEmail": "jdartigues@emma40.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DARTIGUES",
            "CustomerContactFirstName": "JACQUES",
            "CustomerContactMobilePhone": "630492191",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EMMA",
            "CustomerCompanyGroupName": "EMMA EAUX MARENSIN MARENNE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083575",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse à ma demande.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e54f3",
        "extendedResponse": {
            "_id": "6396d5b81ad159cdbc39f762",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e54f30.35348362645808007",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "xx",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mathieu.eisenberg@vinci-construction.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a41b15ff-f0b2-4df9-849c-9a2e65d4125d",
            "responseId": "6384ac9d474566e19b0e54f3",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e54f30.35348362645808007",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "xx",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.495Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "mathieu.eisenberg@vinci-construction.fr",
            "CustomerContactEmail": "mathieu.eisenberg@vinci-construction.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "EISENBERG",
            "CustomerContactFirstName": "Mathieu",
            "CustomerContactMobilePhone": "613104537",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEA EST BTP",
            "CustomerCompanyGroupName": "VINCI CF (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083437",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "xx",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e5513",
        "extendedResponse": {
            "_id": "6396d5b81ad159cdbc39f763",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55130.13377433107905223",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien à dire !",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "entreprise@hydrelec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "20431949-81b3-4cc7-a6ba-7a6ae43a622b",
            "responseId": "6384ac9d474566e19b0e5513",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55130.13377433107905223",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien à dire !",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.497Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "entreprise@hydrelec.fr",
            "CustomerContactEmail": "entreprise@hydrelec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PIERINELLI",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRELEC",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Joachim",
            "XylemContactedPersonName": "GAUDON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083467",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien à dire !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e5555",
        "extendedResponse": {
            "_id": "6396d5b81ad159cdbc39f764",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55550.5381491116839938",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La diligence ",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "axel.corneroute@sapian.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c156abf6-f5b6-4b13-b173-127d57bceeaa",
            "responseId": "6384ac9d474566e19b0e5555",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55550.5381491116839938",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La diligence ",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.502Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "axel.corneroute@sapian.fr",
            "CustomerContactEmail": "axel.corneroute@sapian.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CORNEROUTE",
            "CustomerContactFirstName": "Axel",
            "CustomerContactMobilePhone": "684551324",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAPIAN",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Cédric",
            "XylemContactedPersonName": "DELBOS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222083569",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La diligence ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e5570",
        "extendedResponse": {
            "_id": "6396d5b91ad159cdbc39f765",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55700.6966416955335917",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivté ",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "valmir.adeline@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b941a433-f4a1-4673-a063-7050592ff2ce",
            "responseId": "6384ac9d474566e19b0e5570",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55700.6966416955335917",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivté ",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.505Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "valmir.adeline@sofinther.fr",
            "CustomerContactEmail": "valmir.adeline@sofinther.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "VALMIR",
            "CustomerContactFirstName": "ADELINE",
            "CustomerContactMobilePhone": "688843783",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083596",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivté ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e5572",
        "extendedResponse": {
            "_id": "6396d5b91ad159cdbc39f766",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55720.22336757310719824",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse à la demande très rapide",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gerald.sergent@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7a21f4d1-7686-408e-8535-29ecb3970e80",
            "responseId": "6384ac9d474566e19b0e5572",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55720.22336757310719824",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse à la demande très rapide",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.505Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "gerald.sergent@veolia.com",
            "CustomerContactEmail": "gerald.sergent@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SERGENT",
            "CustomerContactFirstName": "GERALD",
            "CustomerContactMobilePhone": "622853712",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SVR",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Michel",
            "XylemSalespeopleName": "ALBRECHT",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083609",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse à la demande très rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e5581",
        "extendedResponse": {
            "_id": "6396d5b91ad159cdbc39f767",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55810.2941271961647942",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très réactifs \noffre claire\navec documents .",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "psimoens@sefitransmission.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1c56d0c4-3f2e-424a-9a07-32e3e649b962",
            "responseId": "6384ac9d474566e19b0e5581",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55810.2941271961647942",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très réactifs \noffre claire\navec documents .",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.506Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "psimoens@sefitransmission.fr",
            "CustomerContactEmail": "psimoens@sefitransmission.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SIMOENS",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEFITRANSMISSION",
            "CustomerCompanyGroupName": "BOUYGUES E&S 921X",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083620",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très réactifs \noffre claire\navec documents .",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e5599",
        "extendedResponse": {
            "_id": "6396d5ba1ad159cdbc39f768",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55990.18660406657730344",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Toujours très précis par rapport à la demande cependant un délai de réponse trop long ",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.matrassou@materiauxtp.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "14832c26-0be8-4d2a-9efa-5bc0525e85ef",
            "responseId": "6384ac9d474566e19b0e5599",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55990.18660406657730344",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Toujours très précis par rapport à la demande cependant un délai de réponse trop long ",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.508Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "emmanuel.matrassou@materiauxtp.fr",
            "CustomerContactEmail": "emmanuel.matrassou@materiauxtp.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MATRASSOU",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "682655658",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MTP PAU",
            "CustomerCompanyGroupName": "MTP",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083650",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Toujours très précis par rapport à la demande cependant un délai de réponse trop long ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "répondre plus vite "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e55a8",
        "extendedResponse": {
            "_id": "6396d5ba1ad159cdbc39f76a",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55a80.060986106503256865",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Chiffrage avec documentation parfait. Délais de réponse un peu long. Merci.",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julien.piccoli@fic-pro.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "8a2033f0-2033-4ab2-b725-e13001a83d53",
            "responseId": "6384ac9d474566e19b0e55a8",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55a80.060986106503256865",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Chiffrage avec documentation parfait. Délais de réponse un peu long. Merci.",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.509Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "julien.piccoli@fic-pro.fr",
            "CustomerContactEmail": "julien.piccoli@fic-pro.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PICCOLI",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FIC",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083672",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Chiffrage avec documentation parfait. Délais de réponse un peu long. Merci.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Prise en charge de la demande plus rapide."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e55a1",
        "extendedResponse": {
            "_id": "6396d5ba1ad159cdbc39f769",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55a10.6645706662381834",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'offre était complète, avec des fiches techniques, et parvenue dans le temps souhaité.",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sabine.vaillant@vinci-construction.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7258330f-e28e-45f0-b6d6-b91edcd9501e",
            "responseId": "6384ac9d474566e19b0e55a1",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55a10.6645706662381834",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'offre était complète, avec des fiches techniques, et parvenue dans le temps souhaité.",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.509Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "sabine.vaillant@vinci-construction.fr",
            "CustomerContactEmail": "sabine.vaillant@vinci-construction.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "VAILLANT",
            "CustomerContactFirstName": "SABINE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEA SUD HYDRAULIQUE - PROCESS",
            "CustomerCompanyGroupName": "VINCI CF (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222083656",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'offre était complète, avec des fiches techniques, et parvenue dans le temps souhaité.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e55c8",
        "extendedResponse": {
            "_id": "6396d5bb1ad159cdbc39f76b",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55c80.5418629865350655",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité, le conseil et les explications de Mme. Cheron.",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cedric.dolige@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c4c7332e-e744-4e39-ae6c-af008673bb12",
            "responseId": "6384ac9d474566e19b0e55c8",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55c80.5418629865350655",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité, le conseil et les explications de Madame. Cheron.",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.512Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "cedric.dolige@suez.com",
            "CustomerContactEmail": "cedric.dolige@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DOLIGE",
            "CustomerContactFirstName": "CÉDRIC",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083718",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité, le conseil et les explications de Mme. Cheron.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6384ac9d474566e19b0e55cb",
        "extendedResponse": {
            "_id": "6396d5bb1ad159cdbc39f76c",
            "submittedBody": {
                "id": "6384ac9d474566e19b0e55cb0.21631797509984518",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Personnels réactifs, SAV ou aide en ligne très coopératifs et professionnels",
                "creationDate": "2022-11-28T13:42:05+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jccura@terideal.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d60c6c51-e628-48d5-aecb-44992cab1484",
            "responseId": "6384ac9d474566e19b0e55cb",
            "receivedResponse": {
                "id": "6384ac9d474566e19b0e55cb0.21631797509984518",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Personnels réactifs, SAV ou aide en ligne très coopératifs et professionnels",
                "creationDate": "2022-11-28T13:42:05Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-28T12:42:05.512Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221128",
        "integration": {
            "ContactKey": "jccura@terideal.fr",
            "CustomerContactEmail": "jccura@terideal.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CURA",
            "CustomerContactFirstName": "Jean-Christophe",
            "CustomerContactMobilePhone": "668351834",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TERIDEAL SEGEX ENERGIES",
            "CustomerCompanyGroupName": "TERIDEAL SEGEX ENERGIES",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Franck",
            "XylemContactedPersonName": "PENVERN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222083723",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Personnels réactifs, SAV ou aide en ligne très coopératifs et professionnels",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871ced474566e19b3e0e3c",
        "extendedResponse": {
            "_id": "6396d5bb1ad159cdbc39f76d",
            "submittedBody": {
                "id": "63871ced474566e19b3e0e3c0.32758235095232635",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Une réponse ou une proposition toujours rapide + un contact très cordiale et sympathique + accompagnement sur projets = Efficacité...",
                "creationDate": "2022-11-30T10:05:49+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-luc.zieba@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6389fa63-c596-4b4c-9431-327a45591240",
            "responseId": "63871ced474566e19b3e0e3c",
            "receivedResponse": {
                "id": "63871ced474566e19b3e0e3c0.32758235095232635",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Une réponse ou une proposition toujours rapide + un contact très cordiale et sympathique + accompagnement sur projets = Efficacité...",
                "creationDate": "2022-11-30T10:05:49Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "accueil.pos",
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:05:49.964Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221130",
        "integration": {
            "ContactKey": "jean-luc.zieba@veolia.com",
            "CustomerContactEmail": "jean-luc.zieba@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ZIEBA",
            "CustomerContactFirstName": "Jean-Luc",
            "CustomerContactMobilePhone": "601337091",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Flavio",
            "XylemContactedPersonName": "MEDARDONI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Une réponse ou une proposition toujours rapide + un contact très cordiale et sympathique + accompagnement sur projets = Efficacité...",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871ced474566e19b3e0e45",
        "extendedResponse": {
            "_id": "6396d5bc1ad159cdbc39f76e",
            "submittedBody": {
                "id": "63871ced474566e19b3e0e450.9313818862788983",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Personne très professionnelle, réactive et bienveillante.",
                "creationDate": "2022-11-30T10:05:49+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fbitaud@noria-hydro.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "54cfb612-069f-41e5-899b-d9593be9b72e",
            "responseId": "63871ced474566e19b3e0e45",
            "receivedResponse": {
                "id": "63871ced474566e19b3e0e450.9313818862788983",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Personne très professionnelle, réactive et bienveillante.",
                "creationDate": "2022-11-30T10:05:49Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:05:49.965Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221130",
        "integration": {
            "ContactKey": "fbitaud@noria-hydro.com",
            "CustomerContactEmail": "fbitaud@noria-hydro.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BITAUD",
            "CustomerContactFirstName": "François",
            "CustomerContactMobilePhone": "687600140",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NORIA CONCEPTION HYDRAULIQUE",
            "CustomerCompanyGroupName": "NORIA CONCEPTION HYDRAULIQUE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Pierrick",
            "XylemContactedPersonName": "FRAPPAS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Personne très professionnelle, réactive et bienveillante.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871ced474566e19b3e0e4a",
        "extendedResponse": {
            "_id": "6396d5bc1ad159cdbc39f76f",
            "submittedBody": {
                "id": "63871ced474566e19b3e0e4a0.13477706288678326",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je n'ai pas rencontré de commercial récemment. Mais pour avoir rencontré M Smej à quelques reprises, je note tout de même. ",
                "creationDate": "2022-11-30T10:05:49+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolas.thomas@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "98899a03-ea3c-4977-b5ae-17adc031f6c3",
            "responseId": "63871ced474566e19b3e0e4a",
            "receivedResponse": {
                "id": "63871ced474566e19b3e0e4a0.13477706288678326",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je n'ai pas rencontré de commercial récemment. Mais pour avoir rencontré M Smej à quelques reprises, je note tout de même. ",
                "creationDate": "2022-11-30T10:05:49Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-30T09:05:49.966Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221130",
        "integration": {
            "ContactKey": "nicolas.thomas@veolia.com",
            "CustomerContactEmail": "nicolas.thomas@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA INDUSTRIES GLOBAL SOLUTIONS",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "Gauthier",
            "XylemContactedPersonName": "DUFOUR",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je n'ai pas rencontré de commercial récemment. Mais pour avoir rencontré M Smej à quelques reprises, je note tout de même. ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Ne pas se tromper de destinataire pour cette enquête satisfaction "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b9849516b",
        "extendedResponse": {
            "_id": "6396d5bc1ad159cdbc39f770",
            "submittedBody": {
                "id": "63871d001b5d5e2b9849516b0.8237068708790722",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse technique efficace ",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.fisson@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cc61e82a-0bad-4629-a2b8-b9fa3109a1fc",
            "responseId": "63871d001b5d5e2b9849516b",
            "receivedResponse": {
                "id": "63871d001b5d5e2b9849516b0.8237068708790722",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse technique efficace ",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.777Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "thomas.fisson@saur.com",
            "CustomerContactEmail": "thomas.fisson@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FISSON",
            "CustomerContactFirstName": "Thomas",
            "CustomerContactMobilePhone": "660068824",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR FRANCE EXPLOITATION SAINT LAMBERT",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222084198",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse technique efficace ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b98495172",
        "extendedResponse": {
            "_id": "6396d5bd1ad159cdbc39f771",
            "submittedBody": {
                "id": "63871d001b5d5e2b984951720.10998188540081477",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité des réponse ",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francis.faivre@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a2ca391e-fc03-4ab7-a336-7e8020c958b2",
            "responseId": "63871d001b5d5e2b98495172",
            "receivedResponse": {
                "id": "63871d001b5d5e2b984951720.10998188540081477",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité des réponse ",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.777Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "francis.faivre@veolia.com",
            "CustomerContactEmail": "francis.faivre@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FAIVRE",
            "CustomerContactFirstName": "Francis",
            "CustomerContactMobilePhone": "778514167",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084223",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité des réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b9849518a",
        "extendedResponse": {
            "_id": "6396d5bd1ad159cdbc39f772",
            "submittedBody": {
                "id": "63871d001b5d5e2b9849518a0.7619623010798524",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse dans un délai très acceptable, offre complète tarif et technique.\nSuivi du commercial Samuel HARZAU toujours aussi bon.",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sconde@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a23ff044-0234-4f11-9aa3-b726f5f68960",
            "responseId": "63871d001b5d5e2b9849518a",
            "receivedResponse": {
                "id": "63871d001b5d5e2b9849518a0.7619623010798524",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse dans un délai très acceptable, offre complète tarif et technique.\nSuivi du commercial Samuel HARZAU toujours aussi bon.",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "choix.pos",
                "delai.pos",
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.780Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "sconde@hydralians.com",
            "CustomerContactEmail": "sconde@hydralians.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CONDE",
            "CustomerContactFirstName": "STEPHANE",
            "CustomerContactMobilePhone": "682312489",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRALIANS - SOMAIR GERVAT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084344",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse dans un délai très acceptable, offre complète tarif et technique.\nSuivi du commercial Samuel HARZAU toujours aussi bon.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b984951a6",
        "extendedResponse": {
            "_id": "6396d5bd1ad159cdbc39f773",
            "submittedBody": {
                "id": "63871d001b5d5e2b984951a60.23583875757993855",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis ne correspondant pas à ma demande .",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.vigneron@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "a6eab9f9-2a57-4d87-a3c8-607ebe906ebf",
            "responseId": "63871d001b5d5e2b984951a6",
            "receivedResponse": {
                "id": "63871d001b5d5e2b984951a60.23583875757993855",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis ne correspondant pas à ma demande .",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "fiabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.782Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "frederic.vigneron@veolia.com",
            "CustomerContactEmail": "frederic.vigneron@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VIGNERON",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "614634076",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEGA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084073",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis ne correspondant pas à ma demande .",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b984951af",
        "extendedResponse": {
            "_id": "6396d5be1ad159cdbc39f774",
            "submittedBody": {
                "id": "63871d001b5d5e2b984951af0.8670057219650447",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDE ET EFFICACE",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.durand@motelec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1dfa56a2-e98f-41aa-84e4-af9a9cf275ea",
            "responseId": "63871d001b5d5e2b984951af",
            "receivedResponse": {
                "id": "63871d001b5d5e2b984951af0.8670057219650447",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAPIDE ET EFFICACE",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.783Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "s.durand@motelec.fr",
            "CustomerContactEmail": "s.durand@motelec.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DURAND",
            "CustomerContactFirstName": "Stéphanie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MOTELEC INDUSTRIE",
            "CustomerCompanyGroupName": "MOTELEC INDUSTRIE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084138",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDE ET EFFICACE",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b984951ee",
        "extendedResponse": {
            "_id": "6396d5bf1ad159cdbc39f776",
            "submittedBody": {
                "id": "63871d001b5d5e2b984951ee0.4768884770139352",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et gentillesse",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexandre.fritz@groupe-fb.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ec0be798-d28a-470c-b21f-3e796af940c5",
            "responseId": "63871d001b5d5e2b984951ee",
            "receivedResponse": {
                "id": "63871d001b5d5e2b984951ee0.4768884770139352",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et gentillesse",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.788Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "alexandre.fritz@groupe-fb.com",
            "CustomerContactEmail": "alexandre.fritz@groupe-fb.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FRITZ",
            "CustomerContactFirstName": "Alexandre",
            "CustomerContactMobilePhone": "626162619",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (167)",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084239",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et gentillesse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b984951ec",
        "extendedResponse": {
            "_id": "6396d5be1ad159cdbc39f775",
            "submittedBody": {
                "id": "63871d001b5d5e2b984951ec0.8503390811462812",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le délai de réponse pour nos chiffrage est vraiment bien et très réactif.\nje n'ai pas de remarque négatif ",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arthurwattier@cgr-robinetterie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "7b6776e2-30d1-489c-b2f1-286a43a1f6ac",
            "responseId": "63871d001b5d5e2b984951ec",
            "receivedResponse": {
                "id": "63871d001b5d5e2b984951ec0.8503390811462812",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le délai de réponse pour nos chiffrage est vraiment bien et très réactif.\nje n'ai pas de remarque négatif ",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.neg",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.788Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "arthurwattier@cgr-robinetterie.fr",
            "CustomerContactEmail": "arthurwattier@cgr-robinetterie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "WATTIER",
            "CustomerContactFirstName": "Arthur",
            "CustomerContactMobilePhone": "326790715",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CGR Sud-Ouest",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084233",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le délai de réponse pour nos chiffrage est vraiment bien et très réactif.\nje n'ai pas de remarque négatif ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "J'ai du mal à trouver les doc techniques en fr sur le site internet "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b9849521c",
        "extendedResponse": {
            "_id": "6396d5bf1ad159cdbc39f777",
            "submittedBody": {
                "id": "63871d001b5d5e2b9849521c0.771536159434131",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Dommage que le devis ne soit pas complet, manque des éléments!",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rcamiade@sogedo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "a08f3030-9a18-4954-89d6-3b0e3b78f509",
            "responseId": "63871d001b5d5e2b9849521c",
            "receivedResponse": {
                "id": "63871d001b5d5e2b9849521c0.771536159434131",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Dommage que le devis ne soit pas complet, manque des éléments!",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "manque.produit.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.792Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "rcamiade@sogedo.fr",
            "CustomerContactEmail": "rcamiade@sogedo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAMIADE",
            "CustomerContactFirstName": "RICHARD",
            "CustomerContactMobilePhone": "671634326",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEDO",
            "CustomerCompanyGroupName": "SOGEDO",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084301",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Dommage que le devis ne soit pas complet, manque des éléments!",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Répondre entièrement a ma demande"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b98495230",
        "extendedResponse": {
            "_id": "6396d5bf1ad159cdbc39f778",
            "submittedBody": {
                "id": "63871d001b5d5e2b984952300.9103522704003764",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "TOUT EST OK.",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dominique.pindon@proservice-sema.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c7de2868-27c6-457d-bda5-1265f0c8a25c",
            "responseId": "63871d001b5d5e2b98495230",
            "receivedResponse": {
                "id": "63871d001b5d5e2b984952300.9103522704003764",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "TOUT EST OK.",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.794Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "dominique.pindon@proservice-sema.fr",
            "CustomerContactEmail": "dominique.pindon@proservice-sema.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PINDON",
            "CustomerContactFirstName": "DOMINIQUE",
            "CustomerContactMobilePhone": "611277195",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PROSERVICE - SEMA",
            "CustomerCompanyGroupName": "PROSERVICE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084278",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "TOUT EST OK.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b98495244",
        "extendedResponse": {
            "_id": "6396d5c01ad159cdbc39f779",
            "submittedBody": {
                "id": "63871d001b5d5e2b984952440.2517234594549276",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Vous êtes très réactif lors de la demande de prix .",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-claude.fath@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9b1d8530-af3d-4bdd-8b7f-1fed24003f92",
            "responseId": "63871d001b5d5e2b98495244",
            "receivedResponse": {
                "id": "63871d001b5d5e2b984952440.2517234594549276",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Vous êtes très réactif lors de la demande de prix .",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.795Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "jean-claude.fath@veolia.com",
            "CustomerContactEmail": "jean-claude.fath@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FATH",
            "CustomerContactFirstName": "Jean-Claude",
            "CustomerContactMobilePhone": "617946383",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084359",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Vous êtes très réactif lors de la demande de prix .",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b9849524f",
        "extendedResponse": {
            "_id": "6396d5c01ad159cdbc39f77a",
            "submittedBody": {
                "id": "63871d001b5d5e2b9849524f0.3978186606747105",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité, le fait de joindre des fiches de sélection est un réel plus ",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bryan.morieux@equans.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "715e7915-79c9-4910-9e59-006868a488d6",
            "responseId": "63871d001b5d5e2b9849524f",
            "receivedResponse": {
                "id": "63871d001b5d5e2b9849524f0.3978186606747105",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité, le fait de joindre des fiches de sélection est un réel plus ",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.pos",
                                    "label": "Reachability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "joignabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "joignabilite.pos",
                    "label": "Reachability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.796Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "bryan.morieux@equans.com",
            "CustomerContactEmail": "bryan.morieux@equans.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORIEUX",
            "CustomerContactFirstName": "Bryan",
            "CustomerContactMobilePhone": "783682208",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EQUANS AXIMA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "Gaetan",
            "XylemContactedPersonName": "BRETEAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084341",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité, le fait de joindre des fiches de sélection est un réel plus ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d001b5d5e2b9849525a",
        "extendedResponse": {
            "_id": "6396d5c01ad159cdbc39f77b",
            "submittedBody": {
                "id": "63871d001b5d5e2b9849525a0.47178332385620925",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonne réactivité ",
                "creationDate": "2022-11-30T10:06:08+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jfm@etsmaria.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6210f4c8-5cdd-4b31-9772-ed14b487de20",
            "responseId": "63871d001b5d5e2b9849525a",
            "receivedResponse": {
                "id": "63871d001b5d5e2b9849525a0.47178332385620925",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonne réactivité ",
                "creationDate": "2022-11-30T10:06:08Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:08.797Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221130",
        "integration": {
            "ContactKey": "jfm@etsmaria.fr",
            "CustomerContactEmail": "jfm@etsmaria.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARTINEZ",
            "CustomerContactFirstName": "Jean-François",
            "CustomerContactMobilePhone": "628826867",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MARIA ETS",
            "CustomerCompanyGroupName": "MARIA ETS SAS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Franck",
            "XylemContactedPersonName": "PENVERN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222084402",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonne réactivité ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "mentionner un délais au plus proche de la réalité"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d0e09609ff9caab8711",
        "extendedResponse": {
            "_id": "6396d5c11ad159cdbc39f77c",
            "submittedBody": {
                "id": "63871d0e09609ff9caab87110.9777616147750938",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la rapidité  de réponse ",
                "creationDate": "2022-11-30T10:06:22+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "750958782"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f31228d7-cc6f-4a6f-9285-4fc60fa73047",
            "responseId": "63871d0e09609ff9caab8711",
            "receivedResponse": {
                "id": "63871d0e09609ff9caab87110.9777616147750938",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la rapidité de réponse ",
                "creationDate": "2022-11-30T10:06:22Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:06:22.849Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_221130",
        "integration": {
            "ContactKey": "750958782",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Jean Claude FERRET",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "750958782",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ARCELORMITTAL MEDITERRANEE - FOS SUR MER",
            "CustomerCompanyGroupName": "ARCELOR (Groupe National)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "David",
            "XylemSalespeopleName": "FOUNAU JACQUARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "4002820396 - Modifié",
            "XylemReference": "183365",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la rapidité  de réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63871d3b1b5d5e2b984a4f0f",
        "extendedResponse": {
            "_id": "6396d5c11ad159cdbc39f77d",
            "submittedBody": {
                "id": "63871d3b1b5d5e2b984a4f0f0.6272308985247312",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les plus  : Reactivité  commerciale , au  prix du marché , équipes de  montage très professionnelles , respect des regles de securité, port des EPI  ,  aucun d'incident enregistré  .  Félicitations .\nLes moins  : Coffret electrique  fatigué (fendu )   suspendu  ,non fixé à son support et pas adapté au  chaleurs de cet été (Déclenchement du thermique ) .\n",
                "creationDate": "2022-11-30T10:07:07+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dominique.junet@trimet.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "eba0335a-0de0-4b37-a091-54304218263d",
            "responseId": "63871d3b1b5d5e2b984a4f0f",
            "receivedResponse": {
                "id": "63871d3b1b5d5e2b984a4f0f0.6272308985247312",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les plus : Réactivité commerciale , au prix du marché , équipes de montage très professionnelles , respect des règles de sécurité, port des EPI , aucun d'incident enregistré . Félicitations .\nLes moins : Coffret électrique fatigué (fendu ) suspendu ,non fixé à son support et pas adapté au chaleurs de cet été (Déclenchement du thermique ) .\n",
                "creationDate": "2022-11-30T10:07:07Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-11-30T09:07:07.869Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_221130",
        "integration": {
            "ContactKey": "dominique.junet@trimet.fr",
            "CustomerContactEmail": "dominique.junet@trimet.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JUNET",
            "CustomerContactFirstName": "Dominique",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TRIMET FRANCE",
            "CustomerCompanyGroupName": "TRIMET FRANCE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Guillaume",
            "XylemContactedPersonName": "RISSELIN",
            "XylemContactedPersonRole": "Resp. Service & Location",
            "CustomerReference": "45111476240",
            "XylemReference": "724961",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les plus  : Reactivité  commerciale , au  prix du marché , équipes de  montage très professionnelles , respect des regles de securité, port des EPI  ,  aucun d'incident enregistré  .  Félicitations .\nLes moins  : Coffret electrique  fatigué (fendu )   suspendu  ,non fixé à son support et pas adapté au  chaleurs de cet été (Déclenchement du thermique ) .\n",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Un coffret electrique  neuf"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63875c0809609ff9cacd7c88",
        "extendedResponse": {
            "_id": "6396d5c11ad159cdbc39f77e",
            "submittedBody": {
                "id": "63875c0809609ff9cacd7c880.9902145451092075",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Vshdbdndn",
                "creationDate": "2022-11-30T14:35:04+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "609110094"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "fde85c85-9a77-4a6f-955a-089a1c282a37",
            "responseId": "63875c0809609ff9cacd7c88",
            "receivedResponse": {
                "id": "63875c0809609ff9cacd7c880.9902145451092075",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Vshdbdndn",
                "creationDate": "2022-11-30T14:35:04Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-11-30T13:35:04.703Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_221130Test",
        "integration": {
            "ContactKey": "609110094",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Sébastien Zuckerman",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "609110094",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "XYLEM",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Anne",
            "XylemContactedPersonName": "VANNEREAU",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "DD220553",
            "XylemReference": "2RF045209",
            "XylemCountry": "FR"
        },
        "answers": {
            "generalRating": "4",
            "pageNo": "0",
            "initialVerbatim": "Vshdbdndn"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f53b474566e19ba144a4",
        "extendedResponse": {
            "_id": "6396d5c21ad159cdbc39f77f",
            "submittedBody": {
                "id": "6389f53b474566e19ba144a40.14047020585248915",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ponctualité, professionnalisme, disponibilité. Et surtout solide connaissance technique. ",
                "creationDate": "2022-12-02T13:53:15+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gerald.moretto@general-technic.lu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fc644a3c-ab98-483f-ac07-ab7a49b67853",
            "responseId": "6389f53b474566e19ba144a4",
            "receivedResponse": {
                "id": "6389f53b474566e19ba144a40.14047020585248915",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ponctualité, professionnalisme, disponibilité. Et surtout solide connaissance technique. ",
                "creationDate": "2022-12-02T13:53:15Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:15.610Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221201",
        "integration": {
            "ContactKey": "gerald.moretto@general-technic.lu",
            "CustomerContactEmail": "gerald.moretto@general-technic.lu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORETTO",
            "CustomerContactFirstName": "GERALD",
            "CustomerContactMobilePhone": "3,52495E+11",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "General Technic",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Fabien",
            "XylemContactedPersonName": "CANNIZZARO",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ponctualité, professionnalisme, disponibilité. Et surtout solide connaissance technique. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a7ca",
        "extendedResponse": {
            "_id": "6396d5c21ad159cdbc39f780",
            "submittedBody": {
                "id": "6389f548474566e19ba1a7ca0.5690430771351798",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tres bon contact  avec  Francois Lantigny ",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "d.brunet@becot-sas.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "02f591df-7d02-4cbf-96c1-d2eb3aafd380",
            "responseId": "6389f548474566e19ba1a7ca",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a7ca0.5690430771351798",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon contact avec Francois Lantigny ",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.237Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "d.brunet@becot-sas.fr",
            "CustomerContactEmail": "d.brunet@becot-sas.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BRUNET",
            "CustomerContactFirstName": "Didier",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BECOT SAS",
            "CustomerCompanyGroupName": "BECOT SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222084587",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tres bon contact  avec  Francois Lantigny ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a7d4",
        "extendedResponse": {
            "_id": "6396d5c21ad159cdbc39f781",
            "submittedBody": {
                "id": "6389f548474566e19ba1a7d40.09369977081581826",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bonne réactivité et de bon conseils ",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romain.savatte@carlac.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f8d46a69-16b6-4c5d-9af4-9f051f12173f",
            "responseId": "6389f548474566e19ba1a7d4",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a7d40.09369977081581826",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bonne réactivité et de bon conseils ",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.238Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "romain.savatte@carlac.fr",
            "CustomerContactEmail": "romain.savatte@carlac.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SAVATTE",
            "CustomerContactFirstName": "ROMAIN",
            "CustomerContactMobilePhone": "662077254",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CARRIERES DES LACS",
            "CustomerCompanyGroupName": "CARRIERES DES LACS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084650",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bonne réactivité et de bon conseils ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a7fa",
        "extendedResponse": {
            "_id": "6396d5c31ad159cdbc39f782",
            "submittedBody": {
                "id": "6389f548474566e19ba1a7fa0.5729847052118147",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+ Offre rapide, concise, complète.\n- j'ai toujours une deuxième demande en attente...",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gilles.vernier@sopom.pf"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a3a0b4e8-14db-4fb2-920f-404831953307",
            "responseId": "6389f548474566e19ba1a7fa",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a7fa0.5729847052118147",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+ Offre rapide, concise, complète.\n- j'ai toujours une deuxième demande en attente...",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.241Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "gilles.vernier@sopom.pf",
            "CustomerContactEmail": "gilles.vernier@sopom.pf",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VERNIER",
            "CustomerContactFirstName": "Gilles",
            "CustomerContactMobilePhone": "687228400",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOPOM",
            "CustomerCompanyGroupName": "SIN TUNG HING MARINE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084782",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+ Offre rapide, concise, complète.\n- j'ai toujours une deuxième demande en attente...",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Me donner une réponse justifiant l'attente de retour à mon deuxième questionnement."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a814",
        "extendedResponse": {
            "_id": "6396d5c31ad159cdbc39f783",
            "submittedBody": {
                "id": "6389f548474566e19ba1a8140.9647241177371984",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Excellant service avant vente",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.menguy@semeru.fayat.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c6fbab5c-914e-4bc8-adb0-2c99e7246441",
            "responseId": "6389f548474566e19ba1a814",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a8140.9647241177371984",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "excellent service avant vente",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.243Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "j.menguy@semeru.fayat.com",
            "CustomerContactEmail": "j.menguy@semeru.fayat.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MENGUY",
            "CustomerContactFirstName": "Jean",
            "CustomerContactMobilePhone": "676489791",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEMERU",
            "CustomerCompanyGroupName": "SEMERU",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084488",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Excellant service avant vente",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a857",
        "extendedResponse": {
            "_id": "6396d5c31ad159cdbc39f784",
            "submittedBody": {
                "id": "6389f548474566e19ba1a8570.4841259865653802",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les offres et documents techniques sont très détaillées, \nLe délai de réponse est rapide, \n",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c.defert@tdefrance.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "133e9775-6136-469f-8b03-c850c4d3a63e",
            "responseId": "6389f548474566e19ba1a857",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a8570.4841259865653802",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les offres et documents techniques sont très détaillées, \nLe délai de réponse est rapide, \n",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.248Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "c.defert@tdefrance.fr",
            "CustomerContactEmail": "c.defert@tdefrance.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEFERT",
            "CustomerContactFirstName": "Charlie",
            "CustomerContactMobilePhone": "676757556",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PREST'ASSAINISSEMENT",
            "CustomerCompanyGroupName": "TECHNICIENS DES EAUX",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Roberto",
            "XylemContactedPersonName": "FERNANDES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084616",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les offres et documents techniques sont très détaillées, \nLe délai de réponse est rapide, \n",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a858",
        "extendedResponse": {
            "_id": "6396d5c41ad159cdbc39f785",
            "submittedBody": {
                "id": "6389f548474566e19ba1a8580.09548647208076044",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse claire, très rapide et précisément ce que je voulais",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "saet.distribution2@luvica.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "67e4e963-c057-4ef9-9482-2a77213ec61e",
            "responseId": "6389f548474566e19ba1a858",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a8580.09548647208076044",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse claire, très rapide et précisément ce que je voulais",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.248Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "saet.distribution2@luvica.fr",
            "CustomerContactEmail": "saet.distribution2@luvica.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONNOURY",
            "CustomerContactFirstName": "Denis",
            "CustomerContactMobilePhone": "970823903",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAET",
            "CustomerCompanyGroupName": "Groupe LUVICA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084608",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse claire, très rapide et précisément ce que je voulais",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a887",
        "extendedResponse": {
            "_id": "6396d5c41ad159cdbc39f786",
            "submittedBody": {
                "id": "6389f548474566e19ba1a8870.5564883863123029",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J'apprécie la qualité de votre matériels et de nos relations.",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lucien.tomasi@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7eb97d02-ff0f-497c-975e-abb2533e765d",
            "responseId": "6389f548474566e19ba1a887",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a8870.5564883863123029",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J'apprécie la qualité de votre matériels et de nos relations.",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.252Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "lucien.tomasi@veolia.com",
            "CustomerContactEmail": "lucien.tomasi@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TOMASI",
            "CustomerContactFirstName": "Lucien",
            "CustomerContactMobilePhone": "620752036",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KYRNOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084677",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J'apprécie la qualité de votre matériels et de nos relations.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a891",
        "extendedResponse": {
            "_id": "6396d5c41ad159cdbc39f787",
            "submittedBody": {
                "id": "6389f548474566e19ba1a8910.23177607977810433",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité, accueil, réponse adaptée",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@acelia.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "73ea86e3-3b91-4590-bd27-c90cd0db8aef",
            "responseId": "6389f548474566e19ba1a891",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a8910.23177607977810433",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité, accueil, réponse adaptée",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.253Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "contact@acelia.net",
            "CustomerContactEmail": "contact@acelia.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MAGANE",
            "CustomerContactFirstName": "FARID",
            "CustomerContactMobilePhone": "651664520",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ACELIA",
            "CustomerCompanyGroupName": "ACELIA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084685",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité, accueil, réponse adaptée",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f548474566e19ba1a8fa",
        "extendedResponse": {
            "_id": "6396d5c51ad159cdbc39f788",
            "submittedBody": {
                "id": "6389f548474566e19ba1a8fa0.38637123619772984",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "plu : Réponse à ma question\nDéplu : rien",
                "creationDate": "2022-12-02T13:53:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francis.popu@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b250d67b-b2d1-469e-a8bc-d2f0651c25b2",
            "responseId": "6389f548474566e19ba1a8fa",
            "receivedResponse": {
                "id": "6389f548474566e19ba1a8fa0.38637123619772984",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "plu : Réponse à ma question\nDéplu : rien",
                "creationDate": "2022-12-02T13:53:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-02T12:53:28.261Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221201",
        "integration": {
            "ContactKey": "francis.popu@suez.com",
            "CustomerContactEmail": "francis.popu@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POPU",
            "CustomerContactFirstName": "Francis",
            "CustomerContactMobilePhone": "677380692",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Société des Eaux de l’Agglomération du Bassin de Brive - Suez",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084830",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "plu : Réponse à ma question\nDéplu : rien",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f54e09609ff9ca3c984c",
        "extendedResponse": {
            "_id": "6396d5c51ad159cdbc39f789",
            "submittedBody": {
                "id": "6389f54e09609ff9ca3c984c0.23940222196289773",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "R à s ",
                "creationDate": "2022-12-02T13:53:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "763553565"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b9c3ae0f-5f1d-4e8d-ae5c-9c5488635825",
            "responseId": "6389f54e09609ff9ca3c984c",
            "receivedResponse": {
                "id": "6389f54e09609ff9ca3c984c0.23940222196289773",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "R à s ",
                "creationDate": "2022-12-02T13:53:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-02T12:53:34.474Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_221201",
        "integration": {
            "ContactKey": "763553565",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "COSTE Jean Paul",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "763553565",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ASTEO",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "ATTPLA122002715/0",
            "XylemReference": "186702",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "R à s ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f55b6d10aade713ae226",
        "extendedResponse": {
            "_id": "6396d5c51ad159cdbc39f78a",
            "submittedBody": {
                "id": "6389f55b6d10aade713ae2260.9132254973106186",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité et réactivité du technico-commercial",
                "creationDate": "2022-12-02T13:53:47+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolas.huck@tereos.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "36a86fd4-9fa2-4a71-b414-5ee62f88c0a9",
            "responseId": "6389f55b6d10aade713ae226",
            "receivedResponse": {
                "id": "6389f55b6d10aade713ae2260.9132254973106186",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité et réactivité du technico-commercial",
                "creationDate": "2022-12-02T13:53:47Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:47.577Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221202",
        "integration": {
            "ContactKey": "nicolas.huck@tereos.com",
            "CustomerContactEmail": "nicolas.huck@tereos.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HUCK",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "624395842",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREOS SYRAL",
            "CustomerCompanyGroupName": "TEREOS STARCH & SWEETENERS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Claude",
            "XylemContactedPersonName": "GUILLERMET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité et réactivité du technico-commercial",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f55b6d10aade713ae227",
        "extendedResponse": {
            "_id": "6396d5c61ad159cdbc39f78b",
            "submittedBody": {
                "id": "6389f55b6d10aade713ae2270.06606492605641501",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Technico-commercial très réactif et compétent",
                "creationDate": "2022-12-02T13:53:47+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.locherer@tereos.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1ee0a1c4-1a4d-487d-aa04-561027e58794",
            "responseId": "6389f55b6d10aade713ae227",
            "receivedResponse": {
                "id": "6389f55b6d10aade713ae2270.06606492605641501",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Technico-commercial très réactif et compétent",
                "creationDate": "2022-12-02T13:53:47Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:47.577Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221202",
        "integration": {
            "ContactKey": "christophe.locherer@tereos.com",
            "CustomerContactEmail": "christophe.locherer@tereos.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LOCHERER",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "672837037",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREOS SYRAL",
            "CustomerCompanyGroupName": "TEREOS STARCH & SWEETENERS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Claude",
            "XylemContactedPersonName": "GUILLERMET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Technico-commercial très réactif et compétent",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f55b6d10aade713ae230",
        "extendedResponse": {
            "_id": "6396d5c61ad159cdbc39f78c",
            "submittedBody": {
                "id": "6389f55b6d10aade713ae2300.06381689452738915",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "1ere impression : Mr Garcia semble être un bon commercial à l'écoute.\nCependant, nous craignons un manque de réactivité suite à la 'complexe' structure organisationnelle de Xylem. \nLes échanges avec les grands comptes OEM ne semblent pas très structurés, ce qui peut laisser craindre un manque de réactivité, en contradiction avec notre croissance.",
                "creationDate": "2022-12-02T13:53:47+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "noemicamilleri@tranetechnologies.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "97e73ec9-2076-4df6-a886-2d94b848f335",
            "responseId": "6389f55b6d10aade713ae230",
            "receivedResponse": {
                "id": "6389f55b6d10aade713ae2300.06381689452738915",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "1ere impression : Mr Garcia semble être un bon commercial à l'écoute.\nCependant, nous craignons un manque de réactivité suite à la 'complexe' structure organisationnelle de Xylem. \nLes échanges avec les grands comptes OEM ne semblent pas très structurés, ce qui peut laisser craindre un manque de réactivité, en contradiction avec notre croissance.",
                "creationDate": "2022-12-02T13:53:47Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-02T12:53:47.578Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_221202",
        "integration": {
            "ContactKey": "noemicamilleri@tranetechnologies.com",
            "CustomerContactEmail": "noemicamilleri@tranetechnologies.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "CAMILLERI",
            "CustomerContactFirstName": "Noémi",
            "CustomerContactMobilePhone": "632858249",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TRANE TECHNOLOGIE",
            "CustomerCompanyGroupName": "TRANE TECHNOLOGIES INTERN. LTD",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "GARCIA",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "GARCIA",
            "XylemContactedPersonRole": "OEM Business Development Manager France",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "1ere impression : Mr Garcia semble être un bon commercial à l'écoute.\nCependant, nous craignons un manque de réactivité suite à la 'complexe' structure organisationnelle de Xylem. \nLes échanges avec les grands comptes OEM ne semblent pas très structurés, ce qui peut laisser craindre un manque de réactivité, en contradiction avec notre croissance.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "De l'efficacité/ réactivité dans les échanges. "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba2180c",
        "extendedResponse": {
            "_id": "6396d5c71ad159cdbc39f78e",
            "submittedBody": {
                "id": "6389f561474566e19ba2180c0.2877397861717794",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "délai pour l'offre trop long et surtout, le prix est incohérent par rapport à la concurrence.",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sheintz@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "3efd4a6d-dec2-4fc9-a329-09eb018b8759",
            "responseId": "6389f561474566e19ba2180c",
            "receivedResponse": {
                "id": "6389f561474566e19ba2180c0.2877397861717794",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délai pour l'offre trop long et surtout, le prix est incohérent par rapport à la concurrence.",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.692Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "sheintz@hydralians.com",
            "CustomerContactEmail": "sheintz@hydralians.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HEINTZ",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PROLIANS",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084981",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "délai pour l'offre trop long et surtout, le prix est incohérent par rapport à la concurrence.",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba2180b",
        "extendedResponse": {
            "_id": "6396d5c61ad159cdbc39f78d",
            "submittedBody": {
                "id": "6389f561474566e19ba2180b0.11062289631471045",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité efficacité ",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hlacan@innov-elevage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "13f5caeb-be30-45fd-8b04-2a1b2cd5117b",
            "responseId": "6389f561474566e19ba2180b",
            "receivedResponse": {
                "id": "6389f561474566e19ba2180b0.11062289631471045",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité efficacité ",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.692Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "hlacan@innov-elevage.fr",
            "CustomerContactEmail": "hlacan@innov-elevage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACAN",
            "CustomerContactFirstName": "HERVE",
            "CustomerContactMobilePhone": "632631162",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DAFFINI SARL",
            "CustomerCompanyGroupName": "DAFFINI SARL (INNOV'ELEVAGE)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222080362",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité efficacité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba21822",
        "extendedResponse": {
            "_id": "6396d5c71ad159cdbc39f790",
            "submittedBody": {
                "id": "6389f561474566e19ba218220.2974176772699577",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réponse rapide",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier@dossot.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7baa766d-39ba-4e8f-9c76-d6d79917f568",
            "responseId": "6389f561474566e19ba21822",
            "receivedResponse": {
                "id": "6389f561474566e19ba218220.2974176772699577",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réponse rapide",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.693Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "olivier@dossot.fr",
            "CustomerContactEmail": "olivier@dossot.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PONCIN",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DOSSOT",
            "CustomerCompanyGroupName": "ETS DOSSOT ET CIE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085087",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réponse rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba21815",
        "extendedResponse": {
            "_id": "6396d5c71ad159cdbc39f78f",
            "submittedBody": {
                "id": "6389f561474566e19ba218150.293380274848988",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour \nbon accueil et service rapide et professionnel.",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yann.bozec@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c8b00303-932e-4291-9d9a-96276ea274d7",
            "responseId": "6389f561474566e19ba21815",
            "receivedResponse": {
                "id": "6389f561474566e19ba218150.293380274848988",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour \nbon accueil et service rapide et professionnel.",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "accueil.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.693Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "yann.bozec@suez.com",
            "CustomerContactEmail": "yann.bozec@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOZEC",
            "CustomerContactFirstName": "Yann",
            "CustomerContactMobilePhone": "674528103",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222085015",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour \nbon accueil et service rapide et professionnel.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba218ac",
        "extendedResponse": {
            "_id": "6396d5c81ad159cdbc39f791",
            "submittedBody": {
                "id": "6389f561474566e19ba218ac0.9476872634309839",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bon rapport ",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "guillaume.lor@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a7122a04-826c-463b-91e3-a3cafa9dde39",
            "responseId": "6389f561474566e19ba218ac",
            "receivedResponse": {
                "id": "6389f561474566e19ba218ac0.9476872634309839",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bon rapport ",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.709Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "guillaume.lor@veolia.com",
            "CustomerContactEmail": "guillaume.lor@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LOR",
            "CustomerContactFirstName": "Guillaume",
            "CustomerContactMobilePhone": "33613936756",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE LENS)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085050",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bon rapport ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba218b2",
        "extendedResponse": {
            "_id": "6396d5c81ad159cdbc39f792",
            "submittedBody": {
                "id": "6389f561474566e19ba218b20.6549861547923834",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réponse, le détail du matériel proposé",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francois.leroy@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "5f968192-9395-4f70-b9bc-d4dc7b43579c",
            "responseId": "6389f561474566e19ba218b2",
            "receivedResponse": {
                "id": "6389f561474566e19ba218b20.6549861547923834",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réponse, le détail du matériel proposé",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.710Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "francois.leroy@veolia.com",
            "CustomerContactEmail": "francois.leroy@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEROY",
            "CustomerContactFirstName": "François",
            "CustomerContactMobilePhone": "609395338",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CFSP",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Christophe",
            "XylemSalespeopleName": "DOUAY",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085062",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réponse, le détail du matériel proposé",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba218ba",
        "extendedResponse": {
            "_id": "6396d5c81ad159cdbc39f793",
            "submittedBody": {
                "id": "6389f561474566e19ba218ba0.1616716963871423",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "j'ai aimé la rapidité de réponse, mais le contenu et conseil manque",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vincent.idris@xyleminc.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "38f30461-054a-4d1a-ac3c-8d01d690cbe4",
            "responseId": "6389f561474566e19ba218ba",
            "receivedResponse": {
                "id": "6389f561474566e19ba218ba0.1616716963871423",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "j'ai aimé la rapidité de réponse, mais le contenu et conseil manque",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.710Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "vincent.idris@xyleminc.com",
            "CustomerContactEmail": "vincent.idris@xyleminc.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "IDRIS",
            "CustomerContactFirstName": "VINCENT",
            "CustomerContactMobilePhone": "682819791",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VINCENT IDRIS",
            "CustomerCompanyGroupName": "VINCENT IDRIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085066",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "j'ai aimé la rapidité de réponse, mais le contenu et conseil manque",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "du détail de réponse, du conseil"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba218cb",
        "extendedResponse": {
            "_id": "6396d5c91ad159cdbc39f794",
            "submittedBody": {
                "id": "6389f561474566e19ba218cb0.4452255960576845",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse ",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "beauvironnois.herve@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "aca6cd32-ab5a-4856-9501-e8981ef8b625",
            "responseId": "6389f561474566e19ba218cb",
            "receivedResponse": {
                "id": "6389f561474566e19ba218cb0.4452255960576845",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse ",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.712Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "beauvironnois.herve@sade-cgth.fr",
            "CustomerContactEmail": "beauvironnois.herve@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BEAUVIRONNOIS",
            "CustomerContactFirstName": "Herve",
            "CustomerContactMobilePhone": "609615856",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085090",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba218d1",
        "extendedResponse": {
            "_id": "6396d5c91ad159cdbc39f795",
            "submittedBody": {
                "id": "6389f561474566e19ba218d10.053255836121237854",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Toujours professionnel",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.leclercq@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0f0acc91-9c28-4b76-a2f9-517e96c2249c",
            "responseId": "6389f561474566e19ba218d1",
            "receivedResponse": {
                "id": "6389f561474566e19ba218d10.053255836121237854",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Toujours professionnel",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.712Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "laurent.leclercq@dalkia.fr",
            "CustomerContactEmail": "laurent.leclercq@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LECLERCQ",
            "CustomerContactFirstName": "LAURENT",
            "CustomerContactMobilePhone": "642780721",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Dalkia - DTGP",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085097",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Toujours professionnel",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba218ef",
        "extendedResponse": {
            "_id": "6396d5ca1ad159cdbc39f797",
            "submittedBody": {
                "id": "6389f561474566e19ba218ef0.7773755156382012",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité ",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cni@scirpe.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "83911aed-fe67-49d9-a32d-149fdbe4f7f7",
            "responseId": "6389f561474566e19ba218ef",
            "receivedResponse": {
                "id": "6389f561474566e19ba218ef0.7773755156382012",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité ",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.715Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "cni@scirpe.fr",
            "CustomerContactEmail": "cni@scirpe.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "NICOD",
            "CustomerContactFirstName": "CASSANDRE",
            "CustomerContactMobilePhone": "643821571",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SCIRPE",
            "CustomerCompanyGroupName": "SCIRPE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085122",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba218ee",
        "extendedResponse": {
            "_id": "6396d5c91ad159cdbc39f796",
            "submittedBody": {
                "id": "6389f561474566e19ba218ee0.6975126037294848",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et documents techniques joints.",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.dussart@lhotellier.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7c079240-b8cb-451f-a3c3-76e8da0b0e0d",
            "responseId": "6389f561474566e19ba218ee",
            "receivedResponse": {
                "id": "6389f561474566e19ba218ee0.6975126037294848",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et documents techniques joints.",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.pos",
                                    "label": "Reachability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "joignabilite.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "joignabilite.pos",
                    "label": "Reachability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.715Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "olivier.dussart@lhotellier.fr",
            "CustomerContactEmail": "olivier.dussart@lhotellier.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUSSART",
            "CustomerContactFirstName": "OLIVIER",
            "CustomerContactMobilePhone": "760780723",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRA",
            "CustomerCompanyGroupName": "ATURA TP",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Christophe",
            "XylemSalespeopleName": "DOUAY",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084204",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et documents techniques joints.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba2191d",
        "extendedResponse": {
            "_id": "6396d5ca1ad159cdbc39f799",
            "submittedBody": {
                "id": "6389f561474566e19ba2191d0.27845759510063783",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "les horaires de réponse mail ",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jaouhar.haddad@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "64d5299a-c7c7-433d-a2cc-aab6bcac1735",
            "responseId": "6389f561474566e19ba2191d",
            "receivedResponse": {
                "id": "6389f561474566e19ba2191d0.27845759510063783",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "les horaires de réponse mail ",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.718Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "jaouhar.haddad@saur.com",
            "CustomerContactEmail": "jaouhar.haddad@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HADDAD",
            "CustomerContactFirstName": "JAOUHAR",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085196",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "les horaires de réponse mail ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f561474566e19ba21919",
        "extendedResponse": {
            "_id": "6396d5ca1ad159cdbc39f798",
            "submittedBody": {
                "id": "6389f561474566e19ba219190.11098282503984969",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "REPONSE RAPIDE",
                "creationDate": "2022-12-02T13:53:53+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pregnier@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4a0d7fc7-11a9-40ce-bbf0-7bd15b99f83d",
            "responseId": "6389f561474566e19ba21919",
            "receivedResponse": {
                "id": "6389f561474566e19ba219190.11098282503984969",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RÉPONSE RAPIDE",
                "creationDate": "2022-12-02T13:53:53Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-02T12:53:53.718Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_221202",
        "integration": {
            "ContactKey": "pregnier@lri-sodime.com",
            "CustomerContactEmail": "pregnier@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REGNIER",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222085185",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "REPONSE RAPIDE",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6389f56709609ff9ca3d76a3",
        "extendedResponse": {
            "_id": "6396d5cb1ad159cdbc39f79a",
            "submittedBody": {
                "id": "6389f56709609ff9ca3d76a30.24451534716537981",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Notre commercial Guillaume ",
                "creationDate": "2022-12-02T13:53:59+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "698329245"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "428fceb9-7adb-4c2e-8cce-a9018ba71b90",
            "responseId": "6389f56709609ff9ca3d76a3",
            "receivedResponse": {
                "id": "6389f56709609ff9ca3d76a30.24451534716537981",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Notre commercial Guillaume ",
                "creationDate": "2022-12-02T13:53:59Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-02T12:53:59.170Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_221202",
        "integration": {
            "ContactKey": "698329245",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Jean Charles THIERY",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "698329245",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "1574302",
            "XylemReference": "189288",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Notre commercial Guillaume ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a0cba625e6dd3e1c720",
        "extendedResponse": {
            "_id": "6396d5cb1ad159cdbc39f79c",
            "submittedBody": {
                "id": "63905a0cba625e6dd3e1c7200.8772130402796563",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité  de la réponse à un besoin urgent",
                "creationDate": "2022-12-07T10:17:00+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gaetan.massoubeyre@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d20f89bb-6240-45ff-bffd-851a822027f4",
            "responseId": "63905a0cba625e6dd3e1c720",
            "receivedResponse": {
                "id": "63905a0cba625e6dd3e1c7200.8772130402796563",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse à un besoin urgent",
                "creationDate": "2022-12-07T10:17:00Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:00.677Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "gaetan.massoubeyre@suez.com",
            "CustomerContactEmail": "gaetan.massoubeyre@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MASSOUBEYRE",
            "CustomerContactFirstName": "GAETAN",
            "CustomerContactMobilePhone": "0670206718",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086328",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité  de la réponse à un besoin urgent",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "@"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a0cba625e6dd3e1c70e",
        "extendedResponse": {
            "_id": "6396d5cb1ad159cdbc39f79b",
            "submittedBody": {
                "id": "63905a0cba625e6dd3e1c70e0.5541040841663898",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+ LA RAPIDITEE DE TRAITEMENT \n\n",
                "creationDate": "2022-12-07T10:17:00+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thierry.bohn@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6916ba52-f520-44e2-82a4-3409c643bb6e",
            "responseId": "63905a0cba625e6dd3e1c70e",
            "receivedResponse": {
                "id": "63905a0cba625e6dd3e1c70e0.5541040841663898",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+ LA rapidité DE TRAITEMENT \n\n",
                "creationDate": "2022-12-07T10:17:00Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:00.677Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "thierry.bohn@suez.com",
            "CustomerContactEmail": "thierry.bohn@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOHN",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "0677047233",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ Environnement",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086218",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+ LA RAPIDITEE DE TRAITEMENT \n\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a0cba625e6dd3e1c75f",
        "extendedResponse": {
            "_id": "6396d5cc1ad159cdbc39f79d",
            "submittedBody": {
                "id": "63905a0cba625e6dd3e1c75f0.05557982784107951",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délais de réponse rapide\nClarté du devis\nApplication de nos conditions tarifaires particulières ",
                "creationDate": "2022-12-07T10:17:00+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "2nrt@pompesenvironnement.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "94e1d386-c158-4c95-8002-ea3917f807eb",
            "responseId": "63905a0cba625e6dd3e1c75f",
            "receivedResponse": {
                "id": "63905a0cba625e6dd3e1c75f0.05557982784107951",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délais de réponse rapide\nClarté du devis\nApplication de nos conditions tarifaires particulières ",
                "creationDate": "2022-12-07T10:17:00Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:00.680Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "2nrt@pompesenvironnement.fr",
            "CustomerContactEmail": "2nrt@pompesenvironnement.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "HEUZE",
            "CustomerContactFirstName": "CHARLOTTE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "2NRT",
            "CustomerCompanyGroupName": "2NRT - POMPES ENVIRONNEMENT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "SEBASTIEN",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086221",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délais de réponse rapide\nClarté du devis\nApplication de nos conditions tarifaires particulières ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a0cba625e6dd3e1c7e3",
        "extendedResponse": {
            "_id": "6396d5cc1ad159cdbc39f79e",
            "submittedBody": {
                "id": "63905a0cba625e6dd3e1c7e30.7241113595452588",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Service claire et rapide.",
                "creationDate": "2022-12-07T10:17:00+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hguery@vdingenierie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c7c38ba1-6ea7-4abd-9802-cab477a5a489",
            "responseId": "63905a0cba625e6dd3e1c7e3",
            "receivedResponse": {
                "id": "63905a0cba625e6dd3e1c7e30.7241113595452588",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Service claire et rapide.",
                "creationDate": "2022-12-07T10:17:00Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:00.689Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "hguery@vdingenierie.fr",
            "CustomerContactEmail": "hguery@vdingenierie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUERY",
            "CustomerContactFirstName": "Hugo",
            "CustomerContactMobilePhone": "0763974040",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "V.D.I Agence Est Rhône-Alpes",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222086398",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Service claire et rapide.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a0cba625e6dd3e1c7e8",
        "extendedResponse": {
            "_id": "6396d5cc1ad159cdbc39f79f",
            "submittedBody": {
                "id": "63905a0cba625e6dd3e1c7e80.48897108914440235",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le relationnel ",
                "creationDate": "2022-12-07T10:17:00+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "solutions-techniques@outlook.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "c9795cdb-af6e-4f69-b836-ca3933c58ad5",
            "responseId": "63905a0cba625e6dd3e1c7e8",
            "receivedResponse": {
                "id": "63905a0cba625e6dd3e1c7e80.48897108914440235",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le relationnel ",
                "creationDate": "2022-12-07T10:17:00Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:00.703Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "solutions-techniques@outlook.fr",
            "CustomerContactEmail": "solutions-techniques@outlook.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RELMY",
            "CustomerContactFirstName": "Patrick",
            "CustomerContactMobilePhone": "0619255625",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOLUTIONS TECHNIQUES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "NOIROT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "222086407",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le relationnel ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Encore une remise "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a1a3c093bd019c05f1b",
        "extendedResponse": {
            "_id": "6396d5cd1ad159cdbc39f7a0",
            "submittedBody": {
                "id": "63905a1a3c093bd019c05f1b0.2874562208975249",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Expédition rapide et très bon emballage ",
                "creationDate": "2022-12-07T10:17:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0634112900"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "97957adf-93d8-403e-9bce-3521c1590daa",
            "responseId": "63905a1a3c093bd019c05f1b",
            "receivedResponse": {
                "id": "63905a1a3c093bd019c05f1b0.2874562208975249",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Expédition rapide et très bon emballage ",
                "creationDate": "2022-12-07T10:17:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:14.058Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_",
        "integration": {
            "ContactKey": "0634112900",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BERTHEL Benjamin",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0634112900",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SB-AQUAELEC",
            "CustomerCompanyGroupName": "SB AQUAELEC",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF-220128",
            "XylemReference": "191434",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Expédition rapide et très bon emballage ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a3b3f45642f74f581cf",
        "extendedResponse": {
            "_id": "6396d5cd1ad159cdbc39f7a1",
            "submittedBody": {
                "id": "63905a3b3f45642f74f581cf0.7831733211744394",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le professionnalisme des interlocuteurs de Nanterre, qu'ils soient chef d'atelier ou commercial.\nles tests en grandeur nature dans le bassin de Nanterre mettent du temps à arriver.",
                "creationDate": "2022-12-07T10:17:47+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "didier.clavel@siaap.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d601c4c2-1266-41ec-ac65-8558b6f8c36f",
            "responseId": "63905a3b3f45642f74f581cf",
            "receivedResponse": {
                "id": "63905a3b3f45642f74f581cf0.7831733211744394",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le professionnalisme des interlocuteurs de Nanterre, qu'ils soient chef d'atelier ou commercial.\nles tests en grandeur nature dans le bassin de Nanterre mettent du temps à arriver.",
                "creationDate": "2022-12-07T10:17:47Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:47.412Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_",
        "integration": {
            "ContactKey": "didier.clavel@siaap.fr",
            "CustomerContactEmail": "didier.clavel@siaap.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CLAVEL",
            "CustomerContactFirstName": "DIDIER",
            "CustomerContactMobilePhone": "0664664992",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIAAP-SITE SAM-POSTE SESAME-SEINE AMONT-VALENTON",
            "CustomerCompanyGroupName": "SIAAP MULTISITES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE",
            "XylemContactedPersonFirstName": "Tchandra",
            "XylemContactedPersonName": "AMBROISE",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "2201838",
            "XylemReference": "2RF067251",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le professionnalisme des interlocuteurs de Nanterre, qu'ils soient chef d'atelier ou commercial.\nles tests en grandeur nature dans le bassin de Nanterre mettent du temps à arriver.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "je me répète, la réalisation des tests d'achèvement de travaux en condition réèlle."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63905a463c093bd019c0a9b2",
        "extendedResponse": {
            "_id": "6396d5cd1ad159cdbc39f7a2",
            "submittedBody": {
                "id": "63905a463c093bd019c0a9b20.21524187501000314",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité , le sérieux et le professionnalisme de Mr Loison.\nla rigueur lors des échanges par mail,envoi et réception du matériel      ",
                "creationDate": "2022-12-07T10:17:58+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.pointin@tereos.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "b2272274-0e7a-473d-af79-8279050b4523",
            "responseId": "63905a463c093bd019c0a9b2",
            "receivedResponse": {
                "id": "63905a463c093bd019c0a9b20.21524187501000314",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité , le sérieux et le professionnalisme de Mr Loison.\nla rigueur lors des échanges par mail,envoi et réception du matériel ",
                "creationDate": "2022-12-07T10:17:58Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-07T09:17:58.821Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_",
        "integration": {
            "ContactKey": "olivier.pointin@tereos.com",
            "CustomerContactEmail": "olivier.pointin@tereos.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POINTIN",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREOS",
            "CustomerCompanyGroupName": "TEREOS NUTRITION ANIMAL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Mickael",
            "XylemContactedPersonName": "AUBRY",
            "XylemContactedPersonRole": "TCS Location",
            "CustomerReference": "45042",
            "XylemReference": "745325",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité , le sérieux et le professionnalisme de Mr Loison.\nla rigueur lors des échanges par mail,envoi et réception du matériel      ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Durée de la location corrélée à la livraison d'une pompe neuve.\nUne livraison d'une pompe neuve aurait été souhaitable.    "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391950b3f45642f743c92ad",
        "extendedResponse": {
            "_id": "6396d5ce1ad159cdbc39f7a3",
            "submittedBody": {
                "id": "6391950b3f45642f743c92ad0.34015389179728905",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "AVENANT",
                "creationDate": "2022-12-08T08:40:59+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "maxime.sauvage@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d2fbfec2-8ac0-4a95-bc94-362ba31e0e97",
            "responseId": "6391950b3f45642f743c92ad",
            "receivedResponse": {
                "id": "6391950b3f45642f743c92ad0.34015389179728905",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "AVENANT",
                "creationDate": "2022-12-08T08:40:59Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:40:59.918Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_",
        "integration": {
            "ContactKey": "maxime.sauvage@saur.com",
            "CustomerContactEmail": "maxime.sauvage@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SAUVAGE",
            "CustomerContactFirstName": "Maxime",
            "CustomerContactMobilePhone": "0760603880",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR (Direction Régionale Picardie Artois Flandres)",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Flavio",
            "XylemContactedPersonName": "MEDARDONI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "AVENANT",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63919518ba625e6dd31f77ab",
        "extendedResponse": {
            "_id": "6396d5ce1ad159cdbc39f7a4",
            "submittedBody": {
                "id": "63919518ba625e6dd31f77ab0.14435401790631364",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité",
                "creationDate": "2022-12-08T08:41:12+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.guillemont@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "136fbec3-58cd-4484-809e-cb664239963c",
            "responseId": "63919518ba625e6dd31f77ab",
            "receivedResponse": {
                "id": "63919518ba625e6dd31f77ab0.14435401790631364",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité",
                "creationDate": "2022-12-08T08:41:12Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:41:12.751Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "sebastien.guillemont@sofinther.fr",
            "CustomerContactEmail": "sebastien.guillemont@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUILLEMONT",
            "CustomerContactFirstName": "Sebastien",
            "CustomerContactMobilePhone": "0760732487",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222086152",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63919518ba625e6dd31f77c5",
        "extendedResponse": {
            "_id": "6396d5ce1ad159cdbc39f7a5",
            "submittedBody": {
                "id": "63919518ba625e6dd31f77c50.12687555868412304",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse rapide au demande.",
                "creationDate": "2022-12-08T08:41:12+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.pommereau@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "df1bbe6a-9b56-4e0b-ad5a-254f7374ec7c",
            "responseId": "63919518ba625e6dd31f77c5",
            "receivedResponse": {
                "id": "63919518ba625e6dd31f77c50.12687555868412304",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide au demande.",
                "creationDate": "2022-12-08T08:41:12Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:41:12.752Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "sebastien.pommereau@veolia.com",
            "CustomerContactEmail": "sebastien.pommereau@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POMMEREAU",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SVR",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Michel",
            "XylemSalespeopleName": "ALBRECHT",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086188",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse rapide au demande.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63919518ba625e6dd31f77f4",
        "extendedResponse": {
            "_id": "6396d5cf1ad159cdbc39f7a6",
            "submittedBody": {
                "id": "63919518ba625e6dd31f77f40.7420613437301502",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "impec",
                "creationDate": "2022-12-08T08:41:12+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tmichaud@prolians.eu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "021943af-6aed-428a-9bfc-351333241b51",
            "responseId": "63919518ba625e6dd31f77f4",
            "receivedResponse": {
                "id": "63919518ba625e6dd31f77f40.7420613437301502",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "impec",
                "creationDate": "2022-12-08T08:41:12Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:41:12.754Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "tmichaud@prolians.eu",
            "CustomerContactEmail": "tmichaud@prolians.eu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MICHAUD",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "0546006949",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VAMA - DOCK",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086260",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "impec",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63919518ba625e6dd31f780a",
        "extendedResponse": {
            "_id": "6396d5cf1ad159cdbc39f7a7",
            "submittedBody": {
                "id": "63919518ba625e6dd31f780a0.969641971269851",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité, le délai de réponse court à la demande\nLa documentation jointe",
                "creationDate": "2022-12-08T08:41:12+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.dobert@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "e507a409-c462-44f1-95ec-af68dc2a8779",
            "responseId": "63919518ba625e6dd31f780a",
            "receivedResponse": {
                "id": "63919518ba625e6dd31f780a0.969641971269851",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité, le délai de réponse court à la demande\nLa documentation jointe",
                "creationDate": "2022-12-08T08:41:12Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.neg",
                                    "label": "Skills (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.neg",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.neg",
                    "label": "Skills (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:41:12.754Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "stephane.dobert@veolia.com",
            "CustomerContactEmail": "stephane.dobert@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DOBERT",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "0646491332",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086290",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité, le délai de réponse court à la demande\nLa documentation jointe",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Je n'ai pas mis 5 étoiles car on peut toujours mieux faire ...."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63919518ba625e6dd31f7822",
        "extendedResponse": {
            "_id": "6396d5cf1ad159cdbc39f7a8",
            "submittedBody": {
                "id": "63919518ba625e6dd31f78220.9033795160031284",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide et devis conforme avec fiche produits correspondants.",
                "creationDate": "2022-12-08T08:41:12+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "flescieux@sefitransmission.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0aed676d-9443-43da-a691-33174ce0b4c0",
            "responseId": "63919518ba625e6dd31f7822",
            "receivedResponse": {
                "id": "63919518ba625e6dd31f78220.9033795160031284",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide et devis conforme avec fiche produits correspondants.",
                "creationDate": "2022-12-08T08:41:12Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "fiabilite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:41:12.755Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "flescieux@sefitransmission.fr",
            "CustomerContactEmail": "flescieux@sefitransmission.fr",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OREXAD LOIRET",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086319",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide et devis conforme avec fiche produits correspondants.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63919518ba625e6dd31f7824",
        "extendedResponse": {
            "_id": "6396d5d01ad159cdbc39f7a9",
            "submittedBody": {
                "id": "63919518ba625e6dd31f78240.5426065426178832",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et retour toujours précis",
                "creationDate": "2022-12-08T08:41:12+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.dubut@engie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "556c21e3-72d6-4bcf-ab6c-1344ff53c1d0",
            "responseId": "63919518ba625e6dd31f7824",
            "receivedResponse": {
                "id": "63919518ba625e6dd31f78240.5426065426178832",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et retour toujours précis",
                "creationDate": "2022-12-08T08:41:12Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:41:12.755Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "sebastien.dubut@engie.com",
            "CustomerContactEmail": "sebastien.dubut@engie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUBUT",
            "CustomerContactFirstName": "Sebastien",
            "CustomerContactMobilePhone": "0659844545",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ENGIE SOLUTIONS",
            "CustomerCompanyGroupName": "COFELY (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "François",
            "XylemSalespeopleName": "MARTIN",
            "XylemContactedPersonFirstName": "François",
            "XylemContactedPersonName": "MARTIN",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "222086320",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et retour toujours précis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63919597ba625e6dd3201bc7",
        "extendedResponse": {
            "_id": "6396d5d01ad159cdbc39f7aa",
            "submittedBody": {
                "id": "63919597ba625e6dd3201bc70.7201337618484671",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le professionalismr",
                "creationDate": "2022-12-08T08:43:19+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "t.lajoinie@missenard-quint.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4a13ad0b-7dfb-4482-811d-3a2ec083ba98",
            "responseId": "63919597ba625e6dd3201bc7",
            "receivedResponse": {
                "id": "63919597ba625e6dd3201bc70.7201337618484671",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le professionalismr",
                "creationDate": "2022-12-08T08:43:19Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-08T07:43:19.757Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_",
        "integration": {
            "ContactKey": "t.lajoinie@missenard-quint.com",
            "CustomerContactEmail": "t.lajoinie@missenard-quint.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAJOINIE",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MISSENARD CLIMATIQUE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le professionalismr",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd3202692",
        "extendedResponse": {
            "_id": "6396d5d11ad159cdbc39f7ad",
            "submittedBody": {
                "id": "639195a4ba625e6dd32026920.1790776627870252",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "tres bien",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hlacan@innov-elevage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "52d2da57-254e-48ba-9c95-d78c2f0c10e9",
            "responseId": "639195a4ba625e6dd3202692",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32026920.1790776627870252",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bien",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.422Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "hlacan@innov-elevage.fr",
            "CustomerContactEmail": "hlacan@innov-elevage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACAN",
            "CustomerContactFirstName": "HERVE",
            "CustomerContactMobilePhone": "0632631162",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DAFFINI SARL",
            "CustomerCompanyGroupName": "DAFFINI SARL (INNOV'ELEVAGE)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086735",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "tres bien",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd320268a",
        "extendedResponse": {
            "_id": "6396d5d11ad159cdbc39f7ac",
            "submittedBody": {
                "id": "639195a4ba625e6dd320268a0.6965130293029225",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité d'exécution suite à mon urgence",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@mei-services.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4f9a7fc1-a2c2-48bf-983b-46ccd5d16f3c",
            "responseId": "639195a4ba625e6dd320268a",
            "receivedResponse": {
                "id": "639195a4ba625e6dd320268a0.6965130293029225",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité d'exécution suite à mon urgence",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.422Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "contact@mei-services.fr",
            "CustomerContactEmail": "contact@mei-services.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "BRANJONNEAU",
            "CustomerContactFirstName": "Emilie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MEI SERVICES",
            "CustomerCompanyGroupName": "MEI SERVICES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Mehmet Akif",
            "XylemContactedPersonName": "TOKER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222086684",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité d'exécution suite à mon urgence",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd3202686",
        "extendedResponse": {
            "_id": "6396d5d01ad159cdbc39f7ab",
            "submittedBody": {
                "id": "639195a4ba625e6dd32026860.8089637745484455",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.fauche@pompes2s.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "200ec76c-8434-401e-a12a-879aceeb6ddd",
            "responseId": "639195a4ba625e6dd3202686",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32026860.8089637745484455",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.422Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "laurent.fauche@pompes2s.com",
            "CustomerContactEmail": "laurent.fauche@pompes2s.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FAUCHE",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0613254489",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "P2S",
            "CustomerCompanyGroupName": "POMPES SOLUTIONS SERVICES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086654",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd32026b0",
        "extendedResponse": {
            "_id": "6396d5d11ad159cdbc39f7ae",
            "submittedBody": {
                "id": "639195a4ba625e6dd32026b00.05894393497164985",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour. Depuis fort longtemps tout se passe bien .les demandes de devis sont traitées rapidement. Les commandes arrivent en temps et en heure. Les commerciaux sont toujours a votre écoute.",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "atelier@servicebobinage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ff099b8a-4ec0-4167-9951-5d150ab6f42d",
            "responseId": "639195a4ba625e6dd32026b0",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32026b00.05894393497164985",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour. Depuis fort longtemps tout se passe bien .les demandes de devis sont traitées rapidement. Les commandes arrivent en temps et en heure. Les commerciaux sont toujours a votre écoute.",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "ecoute.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.423Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "atelier@servicebobinage.com",
            "CustomerContactEmail": "atelier@servicebobinage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": ".",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERVICE BOBINAGE",
            "CustomerCompanyGroupName": "SCE BOB.ELEC.MECA.VAROIS SBEMV",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222086433",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour. Depuis fort longtemps tout se passe bien .les demandes de devis sont traitées rapidement. Les commandes arrivent en temps et en heure. Les commerciaux sont toujours a votre écoute.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "a mon avis c'est dommage que vous ne pouvez donner suite a certaines demandes concernant les pompes lowara.Nous avons beaucoup de soucis pour avoir certaines pièces de ces pompes que vous ne faites plus .il faut que l'on passe par d'autres fournisseurs qui ne sont pas aussi reactifs que vous ."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd3202703",
        "extendedResponse": {
            "_id": "6396d5d21ad159cdbc39f7af",
            "submittedBody": {
                "id": "639195a4ba625e6dd32027030.0018524934302863727",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+: Le service client et l'étude réalisée",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "guillaume.simons@edf.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "2089cae7-c54b-457b-8e4c-0aba084f2326",
            "responseId": "639195a4ba625e6dd3202703",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32027030.0018524934302863727",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+: Le service client et l'étude réalisée",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.426Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "guillaume.simons@edf.fr",
            "CustomerContactEmail": "guillaume.simons@edf.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SIMONS",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EDF",
            "CustomerCompanyGroupName": "EDF SA",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086501",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+: Le service client et l'étude réalisée",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd3202719",
        "extendedResponse": {
            "_id": "6396d5d21ad159cdbc39f7b0",
            "submittedBody": {
                "id": "639195a4ba625e6dd32027190.29470749168136434",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité de la réponse",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@first-robinetterie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1f093003-9761-4fdc-9e1d-360e57a4bbb6",
            "responseId": "639195a4ba625e6dd3202719",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32027190.29470749168136434",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité de la réponse",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.427Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "commercial@first-robinetterie.com",
            "CustomerContactEmail": "commercial@first-robinetterie.com",
            "CustomerContactSalutation": "Mademoiselle",
            "CustomerContactName": "TZIKUNIB",
            "CustomerContactFirstName": "Karine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FIRST ROBINETTERIE MARSEILLE",
            "CustomerCompanyGroupName": "FIRST ROBINETTERIE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086561",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd3202727",
        "extendedResponse": {
            "_id": "6396d5d21ad159cdbc39f7b1",
            "submittedBody": {
                "id": "639195a4ba625e6dd32027270.4445094143810553",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Retour des devis rapides, parfait.\nEn ce qui concerne la demande de tarif sur des pièces, il manque des références...kit joint mécanique =ok, (roulements / kits joints d'étanchéité = 0 ??)\nJe dois retourner sur le site pour trouver approximativement les prix pour réaliser mes offres..",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrick.proux@ip-france.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "980da89e-92dc-4bcc-bdaf-a74487d49c99",
            "responseId": "639195a4ba625e6dd3202727",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32027270.4445094143810553",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Retour des devis rapides, parfait.\nEn ce qui concerne la demande de tarif sur des pièces, il manque des références...kit joint mécanique =ok, (roulements / kits joints d'étanchéité = 0 ??)\nJe dois retourner sur le site pour trouver approximativement les prix pour réaliser mes offres..",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "prix.autre",
                "manque.produit.neg",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.427Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "patrick.proux@ip-france.net",
            "CustomerContactEmail": "patrick.proux@ip-france.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PROUX",
            "CustomerContactFirstName": "PATRICK",
            "CustomerContactMobilePhone": "0756909026",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "IP FRANCE",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086575",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Retour des devis rapides, parfait.\nEn ce qui concerne la demande de tarif sur des pièces, il manque des références...kit joint mécanique =ok, (roulements / kits joints d'étanchéité = 0 ??)\nJe dois retourner sur le site pour trouver approximativement les prix pour réaliser mes offres..",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "répondre aux demandes citées dans les mails."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd320272d",
        "extendedResponse": {
            "_id": "6396d5d31ad159cdbc39f7b2",
            "submittedBody": {
                "id": "639195a4ba625e6dd320272d0.8456551892566877",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ok",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yannick.roudiere@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "7f67cb68-41eb-4bf0-9586-e0953a6291da",
            "responseId": "639195a4ba625e6dd320272d",
            "receivedResponse": {
                "id": "639195a4ba625e6dd320272d0.8456551892566877",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ok",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.428Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "yannick.roudiere@veolia.com",
            "CustomerContactEmail": "yannick.roudiere@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROUDIERE",
            "CustomerContactFirstName": "Yannick",
            "CustomerContactMobilePhone": "0611033877",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Thierry",
            "XylemContactedPersonName": "HERMAN",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222086582",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ok",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd320272e",
        "extendedResponse": {
            "_id": "6396d5d31ad159cdbc39f7b3",
            "submittedBody": {
                "id": "639195a4ba625e6dd320272e0.3546653927706467",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "BON COMMERCIAL\nRAPIDITER CHIFFRAGE, QUALITE DE L'OFFRE AVEC FICHE TECHNIQUE, PRIX.\nBON PRODUIT",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "t.peyrard@sabatier-vitalys.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "97254fec-48a3-4abc-b78b-e23927e2c229",
            "responseId": "639195a4ba625e6dd320272e",
            "receivedResponse": {
                "id": "639195a4ba625e6dd320272e0.3546653927706467",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "BON COMMERCIAL\nrapidité CHIFFRAGE, qualité DE L'OFFRE AVEC FICHE TECHNIQUE, PRIX.\nBON PRODUIT",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "qualite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.428Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "t.peyrard@sabatier-vitalys.fr",
            "CustomerContactEmail": "t.peyrard@sabatier-vitalys.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PEYRARD",
            "CustomerContactFirstName": "Thomas",
            "CustomerContactMobilePhone": "0660967310",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SABATIER",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN (L)",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086557",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "BON COMMERCIAL\nRAPIDITER CHIFFRAGE, QUALITE DE L'OFFRE AVEC FICHE TECHNIQUE, PRIX.\nBON PRODUIT",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd3202734",
        "extendedResponse": {
            "_id": "6396d5d31ad159cdbc39f7b4",
            "submittedBody": {
                "id": "639195a4ba625e6dd32027340.8334017209382676",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "tous est correct chez xylem !",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@sj-industrie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "30e49004-45b6-4d36-9c6d-545ec29141d5",
            "responseId": "639195a4ba625e6dd3202734",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32027340.8334017209382676",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "tous est correct chez xylem !",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.428Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "commercial@sj-industrie.fr",
            "CustomerContactEmail": "commercial@sj-industrie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JORRÉ",
            "CustomerContactFirstName": "Swanny",
            "CustomerContactMobilePhone": "0783898624",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SJ INDUSTRIE",
            "CustomerCompanyGroupName": "SJ INDUSTRIE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Michael",
            "XylemContactedPersonName": "MOREAU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222063389",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "tous est correct chez xylem !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd320275e",
        "extendedResponse": {
            "_id": "6396d5d41ad159cdbc39f7b5",
            "submittedBody": {
                "id": "639195a4ba625e6dd320275e0.7503892553710056",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "ce qui ma plu ces votre réactivités à répondre au dossier demandé    note 8.5 sur 10",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "franck.stumpf@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "70db2243-4095-4588-aa57-b90cac97b1d6",
            "responseId": "639195a4ba625e6dd320275e",
            "receivedResponse": {
                "id": "639195a4ba625e6dd320275e0.7503892553710056",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "ce qui ma plu ces votre réactivité à répondre au dossier demandé note 8.5 sur 10",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.429Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "franck.stumpf@veolia.com",
            "CustomerContactEmail": "franck.stumpf@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "STUMPF",
            "CustomerContactFirstName": "FRANCK",
            "CustomerContactMobilePhone": "0778240388",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086651",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "ce qui ma plu ces votre réactivités à répondre au dossier demandé    note 8.5 sur 10",
            "pageNo": "0",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd320276b",
        "extendedResponse": {
            "_id": "6396d5d41ad159cdbc39f7b6",
            "submittedBody": {
                "id": "639195a4ba625e6dd320276b0.5202121215057183",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Service rapide et anticipé ",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vincent.tefau@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f6b3232a-7ace-4519-a50d-bfac78d6b589",
            "responseId": "639195a4ba625e6dd320276b",
            "receivedResponse": {
                "id": "639195a4ba625e6dd320276b0.5202121215057183",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Service rapide et anticipé ",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.430Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "vincent.tefau@suez.com",
            "CustomerContactEmail": "vincent.tefau@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TEFAU",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0764885985",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ STEP FEYSSINE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086675",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Service rapide et anticipé ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639195a4ba625e6dd32027af",
        "extendedResponse": {
            "_id": "6396d5d41ad159cdbc39f7b7",
            "submittedBody": {
                "id": "639195a4ba625e6dd32027af0.388166803131478",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité\nEtude technique de qualité",
                "creationDate": "2022-12-08T08:43:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.dixneuf@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "db2815f3-e6a5-4922-a2ec-b2be0ee75f04",
            "responseId": "639195a4ba625e6dd32027af",
            "receivedResponse": {
                "id": "639195a4ba625e6dd32027af0.388166803131478",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité\nétude technique de qualité",
                "creationDate": "2022-12-08T08:43:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T07:43:32.432Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_",
        "integration": {
            "ContactKey": "sebastien.dixneuf@veolia.com",
            "CustomerContactEmail": "sebastien.dixneuf@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DIXNEUF",
            "CustomerContactFirstName": "Sebastien",
            "CustomerContactMobilePhone": "0627455736",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222085999",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité\nEtude technique de qualité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd019333ecc",
        "extendedResponse": {
            "_id": "6396d5d51ad159cdbc39f7b8",
            "submittedBody": {
                "id": "6391f0823c093bd019333ecc0.4833962460067016",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Faire attention aux notices techniques et références des pompes sous emballage plastique qui sont glissées dans les cartons et quelques fois sont perdues dans les camions de transport. ",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0610282007"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "8eb3c6ac-7698-4132-8c04-69fa288f8da8",
            "responseId": "6391f0823c093bd019333ecc",
            "receivedResponse": {
                "id": "6391f0823c093bd019333ecc0.4833962460067016",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Faire attention aux notices techniques et références des pompes sous emballage plastique qui sont glissées dans les cartons et quelques fois sont perdues dans les camions de transport. ",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.312Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "0610282007",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Denis VIMONT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0610282007",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Florence GOULET",
            "XylemContactedPersonRole": "",
            "CustomerReference": "4 105 063 744",
            "XylemReference": "627809",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Faire attention aux notices techniques et références des pompes sous emballage plastique qui sont glissées dans les cartons et quelques fois sont perdues dans les camions de transport. ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Faire en sorte que les notices ne soient pas perdues. (Les attacher à la pompe par exemple)"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd019333ed9",
        "extendedResponse": {
            "_id": "6396d5d51ad159cdbc39f7b9",
            "submittedBody": {
                "id": "6391f0823c093bd019333ed90.12472176880138663",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidite",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0682570774"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "60d9d626-622a-43b0-b01e-388bdc69273d",
            "responseId": "6391f0823c093bd019333ed9",
            "receivedResponse": {
                "id": "6391f0823c093bd019333ed90.12472176880138663",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.312Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "0682570774",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Samuel ALLAGNAT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0682570774",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "STGS",
            "CustomerCompanyGroupName": "FEDERATION DES DISTRIBUTEURS D'EAU INDEPENDANTS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "52373",
            "XylemReference": "191642",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidite",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd019333f14",
        "extendedResponse": {
            "_id": "6396d5d51ad159cdbc39f7ba",
            "submittedBody": {
                "id": "6391f0823c093bd019333f140.9541048482389713",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L acces direct d un interlocuteur xylem\nDes retours de pro",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0622254542"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b2e416c5-3945-4f66-849c-f1c89ae27e01",
            "responseId": "6391f0823c093bd019333f14",
            "receivedResponse": {
                "id": "6391f0823c093bd019333f140.9541048482389713",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L accès direct d un interlocuteur xylem\nDes retours de professionnel",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.317Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "0622254542",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. David BEZANNIER HUSSON",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0622254542",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (700) LE MANS",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "348307",
            "XylemReference": "191520",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L acces direct d un interlocuteur xylem\nDes retours de pro",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd0193342ad",
        "extendedResponse": {
            "_id": "6396d5d61ad159cdbc39f7bb",
            "submittedBody": {
                "id": "6391f0823c093bd0193342ad0.8153399504219059",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "on a reçu rapidement notre devis",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pbediot@sogedo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ab0b223e-5e72-4ca7-b30f-f9740503c256",
            "responseId": "6391f0823c093bd0193342ad",
            "receivedResponse": {
                "id": "6391f0823c093bd0193342ad0.8153399504219059",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "on a reçu rapidement notre devis",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.676Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "pbediot@sogedo.fr",
            "CustomerContactEmail": "pbediot@sogedo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BEDIOT",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "0624380888",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEDO",
            "CustomerCompanyGroupName": "SOGEDO",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086856",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "on a reçu rapidement notre devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd0193342c8",
        "extendedResponse": {
            "_id": "6396d5d61ad159cdbc39f7bc",
            "submittedBody": {
                "id": "6391f0823c093bd0193342c80.9345777983440267",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité et adéquation de la réponse",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francois.laqueche@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dd1d52ea-c40c-4e56-b4d3-87e114cf9af2",
            "responseId": "6391f0823c093bd0193342c8",
            "receivedResponse": {
                "id": "6391f0823c093bd0193342c80.9345777983440267",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité et adéquation de la réponse",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.678Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "francois.laqueche@suez.com",
            "CustomerContactEmail": "francois.laqueche@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAQUECHE",
            "CustomerContactFirstName": "François",
            "CustomerContactMobilePhone": "0687942121",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ - HYDREA",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086799",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité et adéquation de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd019334300",
        "extendedResponse": {
            "_id": "6396d5d71ad159cdbc39f7be",
            "submittedBody": {
                "id": "6391f0823c093bd0193343000.42962713381605755",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité, professionnalisme qualité des échanges ",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "samuel.vanhaecke@seihe.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e3409df2-6ad3-4566-a0b6-32bd6da8bd90",
            "responseId": "6391f0823c093bd019334300",
            "receivedResponse": {
                "id": "6391f0823c093bd0193343000.42962713381605755",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité, professionnalisme qualité des échanges ",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.681Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "samuel.vanhaecke@seihe.fr",
            "CustomerContactEmail": "samuel.vanhaecke@seihe.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANHAECKE",
            "CustomerContactFirstName": "SAMUEL",
            "CustomerContactMobilePhone": "0757006878",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEIHE 32",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086652",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité, professionnalisme qualité des échanges ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd0193342f1",
        "extendedResponse": {
            "_id": "6396d5d61ad159cdbc39f7bd",
            "submittedBody": {
                "id": "6391f0823c093bd0193342f10.11778856900690737",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité, sérieux",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "elevieil@soc.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "36153296-63df-4163-adf3-b5cc7782f835",
            "responseId": "6391f0823c093bd0193342f1",
            "receivedResponse": {
                "id": "6391f0823c093bd0193342f10.11778856900690737",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité, sérieux",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.681Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "elevieil@soc.fr",
            "CustomerContactEmail": "elevieil@soc.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "LEVIEIL",
            "CustomerContactFirstName": "EMILIE",
            "CustomerContactMobilePhone": "0645071345",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOC RHONE ALPES et GRANDS PROJETS",
            "CustomerCompanyGroupName": "SOC",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222086801",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité, sérieux",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd019334306",
        "extendedResponse": {
            "_id": "6396d5d71ad159cdbc39f7bf",
            "submittedBody": {
                "id": "6391f0823c093bd0193343060.07652322961860336",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.chamard@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8cf85a50-b28f-4e11-acb5-ac57329b1fab",
            "responseId": "6391f0823c093bd019334306",
            "receivedResponse": {
                "id": "6391f0823c093bd0193343060.07652322961860336",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.682Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "xavier.chamard@eiffage.com",
            "CustomerContactEmail": "xavier.chamard@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHAMARD",
            "CustomerContactFirstName": "XAVIER",
            "CustomerContactMobilePhone": "0164812410",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIFFAGE TRAVAUX PUBLICS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086888",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd019334313",
        "extendedResponse": {
            "_id": "6396d5d71ad159cdbc39f7c0",
            "submittedBody": {
                "id": "6391f0823c093bd0193343130.40163196284704106",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Toujours à l'écoute et réponse rapide de nos demandes. Produits hors accords-cadres onéreux et difficulté pour nous d'être compétitif face à nos concurrents.",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nelson.pangkoui@polynesienne-des-eaux.pf"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "00584829-158a-4c3a-babb-6916be54d1d3",
            "responseId": "6391f0823c093bd019334313",
            "receivedResponse": {
                "id": "6391f0823c093bd0193343130.40163196284704106",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Toujours à l'écoute et réponse rapide de nos demandes. Produits hors accords-cadres onéreux et difficulté pour nous d'être compétitif face à nos concurrents.",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.pos",
                                    "label": "Price (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.pos",
                "prix.neg",
                "delai.pos",
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.pos",
                    "label": "Price (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.683Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "nelson.pangkoui@polynesienne-des-eaux.pf",
            "CustomerContactEmail": "nelson.pangkoui@polynesienne-des-eaux.pf",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PANG KOUI",
            "CustomerContactFirstName": "Nelson",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "POLYNESIENNE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086897",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Toujours à l'écoute et réponse rapide de nos demandes. Produits hors accords-cadres onéreux et difficulté pour nous d'être compétitif face à nos concurrents.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Pour avoir 5/5 la note parfaite, il serait bien que nos prix accords-cadres soient étendus su plus de produits. Nous sommes loin de tout et le prix rendu TAHITI est extremement important afin que nous soyons plus compétitifs que nos concurrents."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd019334329",
        "extendedResponse": {
            "_id": "6396d5d81ad159cdbc39f7c1",
            "submittedBody": {
                "id": "6391f0823c093bd0193343290.7664780250952472",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@eglinsdoerfer.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8db8c568-c1cf-4d3b-9093-52a5e68ac26c",
            "responseId": "6391f0823c093bd019334329",
            "receivedResponse": {
                "id": "6391f0823c093bd0193343290.7664780250952472",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.685Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "contact@eglinsdoerfer.fr",
            "CustomerContactEmail": "contact@eglinsdoerfer.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "KLINGER",
            "CustomerContactFirstName": "Delphine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EGLINSDOERFER PFOHL SA",
            "CustomerCompanyGroupName": "EGLINSDOERFER-PFOHL S.A.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Corinne",
            "XylemContactedPersonName": "LAQUAINE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222086922",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6391f0823c093bd01933436e",
        "extendedResponse": {
            "_id": "6396d5d81ad159cdbc39f7c2",
            "submittedBody": {
                "id": "6391f0823c093bd01933436e0.037807305946111924",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "ce qui m'a plus Réactivité de la réponse ",
                "creationDate": "2022-12-08T15:11:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@first-robinetterie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "da5acaec-d528-43b9-8323-f5ce5861bed6",
            "responseId": "6391f0823c093bd01933436e",
            "receivedResponse": {
                "id": "6391f0823c093bd01933436e0.037807305946111924",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "ce qui m'a plus Réactivité de la réponse ",
                "creationDate": "2022-12-08T15:11:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-08T14:11:14.690Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-08_15.10.58.csv",
        "integration": {
            "ContactKey": "commercial@first-robinetterie.com",
            "CustomerContactEmail": "commercial@first-robinetterie.com",
            "CustomerContactSalutation": "Mademoiselle",
            "CustomerContactName": "TZIKUNIB",
            "CustomerContactFirstName": "Karine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FIRST ROBINETTERIE MARSEILLE",
            "CustomerCompanyGroupName": "FIRST ROBINETTERIE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Cybélia",
            "XylemContactedPersonName": "CAMELOT",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087005",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "ce qui m'a plus Réactivité de la réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6392c46bba625e6dd3b3a58d",
        "extendedResponse": {
            "_id": "6396d5da1ad159cdbc39f7c8",
            "submittedBody": {
                "id": "6392c46bba625e6dd3b3a58d0.06521559327076454",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Mr bouquin est très professionnel  sur qui l on peut s'appuyer afin de faire de bon choix technique.",
                "creationDate": "2022-12-09T06:15:23+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cheriveau@ville-montlouis-loire.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5585843d-12e4-4485-a064-55fc14ada960",
            "responseId": "6392c46bba625e6dd3b3a58d",
            "receivedResponse": {
                "id": "6392c46bba625e6dd3b3a58d0.06521559327076454",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Mr bouquin est très professionnel sur qui l on peut s'appuyer afin de faire de bon choix technique.",
                "creationDate": "2022-12-09T06:15:23Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-09T05:15:23.967Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit__2022-12-09_06.15.07",
        "integration": {
            "ContactKey": "cheriveau@ville-montlouis-loire.fr",
            "CustomerContactEmail": "cheriveau@ville-montlouis-loire.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HERIVEAU",
            "CustomerContactFirstName": "Clément",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MAIRIE DE MONTLOUIS SERVICE EAU ET ASSAINISSEMENT",
            "CustomerCompanyGroupName": "VILLE DE MONTLOUIS/LOIRE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Rémi",
            "XylemContactedPersonName": "BOUQUIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Mr bouquin est très professionnel  sur qui l on peut s'appuyer afin de faire de bon choix technique.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6392c46bba625e6dd3b3a591",
        "extendedResponse": {
            "_id": "6396d5da1ad159cdbc39f7c9",
            "submittedBody": {
                "id": "6392c46bba625e6dd3b3a5910.3710125891936269",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Mr Bouquin est très  à l'écoute de mes demande,le temps de réponse est très correcte.\nil m'informe toujours des nouveautés et améliorations sur les produits de la marque.\nil se déplace sur site lors pour trouver une solution .\n ",
                "creationDate": "2022-12-09T06:15:23+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-pierre.canizares@lochessudtouraine.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6671180e-657a-41b1-823b-92e0c419c698",
            "responseId": "6392c46bba625e6dd3b3a591",
            "receivedResponse": {
                "id": "6392c46bba625e6dd3b3a5910.3710125891936269",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Mr Bouquin est très à l'écoute de mes demande,le temps de réponse est très correcte.\nil m'informe toujours des nouveautés et améliorations sur les produits de la marque.\nil se déplace sur site lors pour trouver une solution .\n ",
                "creationDate": "2022-12-09T06:15:23Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-09T05:15:23.968Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit__2022-12-09_06.15.07",
        "integration": {
            "ContactKey": "jean-pierre.canizares@lochessudtouraine.com",
            "CustomerContactEmail": "jean-pierre.canizares@lochessudtouraine.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CANIZARES",
            "CustomerContactFirstName": "jean pierre",
            "CustomerContactMobilePhone": "0608890531",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COM.COM LOCHES SUD TOURAINE",
            "CustomerCompanyGroupName": "CC LOCHES SUD TOURAINE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Rémi",
            "XylemContactedPersonName": "BOUQUIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Mr Bouquin est très  à l'écoute de mes demande,le temps de réponse est très correcte.\nil m'informe toujours des nouveautés et améliorations sur les produits de la marque.\nil se déplace sur site lors pour trouver une solution .\n ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6392c46bba625e6dd3b3a5a1",
        "extendedResponse": {
            "_id": "6396d5db1ad159cdbc39f7ca",
            "submittedBody": {
                "id": "6392c46bba625e6dd3b3a5a10.34300876851941986",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Il m’a donné tous les catalogues, et nous avons échangé sur notre MDD. \nPersonne pro et à l’écoute.",
                "creationDate": "2022-12-09T06:15:23+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "driss.zineeddine@tereva.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fc16aa80-6903-4e00-93ba-b28f2ee20168",
            "responseId": "6392c46bba625e6dd3b3a5a1",
            "receivedResponse": {
                "id": "6392c46bba625e6dd3b3a5a10.34300876851941986",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Il m a donné tous les catalogues, et nous avons échangé sur notre MDD. \nPersonne professionnel et à l écoute.",
                "creationDate": "2022-12-09T06:15:23Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-09T05:15:23.968Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit__2022-12-09_06.15.07",
        "integration": {
            "ContactKey": "driss.zineeddine@tereva.fr",
            "CustomerContactEmail": "driss.zineeddine@tereva.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ZINE DINE",
            "CustomerContactFirstName": "Driss",
            "CustomerContactMobilePhone": "0642871489",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREVA SAINT AMAND MONTROND",
            "CustomerCompanyGroupName": "MBE (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "François",
            "XylemSalespeopleName": "MARTIN",
            "XylemContactedPersonFirstName": "François",
            "XylemContactedPersonName": "MARTIN",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Il m’a donné tous les catalogues, et nous avons échangé sur notre MDD. \nPersonne pro et à l’écoute.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6392c46c3c093bd0198f72a4",
        "extendedResponse": {
            "_id": "6396d5db1ad159cdbc39f7cb",
            "submittedBody": {
                "id": "6392c46c3c093bd0198f72a40.5661750555830154",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2022-12-09T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.pourchel@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b7e80ca4-6112-46bd-b61f-6cedfa4df7c7",
            "responseId": "6392c46c3c093bd0198f72a4",
            "receivedResponse": {
                "id": "6392c46c3c093bd0198f72a40.5661750555830154",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2022-12-09T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-09T05:15:24.080Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-09_06.15.07",
        "integration": {
            "ContactKey": "xavier.pourchel@veolia.com",
            "CustomerContactEmail": "xavier.pourchel@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POURCHEL",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0603492814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE SAINT OMER (STEP)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087050",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6392c46c3c093bd0198f72b5",
        "extendedResponse": {
            "_id": "6396d5db1ad159cdbc39f7cc",
            "submittedBody": {
                "id": "6392c46c3c093bd0198f72b50.15787707983229904",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour ,   devis reçu rapidement et produit disponible  - je vous ai passer la commande de cette belle pompe de chauffage .",
                "creationDate": "2022-12-09T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c81249bf-5f0e-46a0-b5ce-c6920ec8a538",
            "responseId": "6392c46c3c093bd0198f72b5",
            "receivedResponse": {
                "id": "6392c46c3c093bd0198f72b50.15787707983229904",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour , devis reçu rapidement et produit disponible - je vous ai passer la commande de cette belle pompe de chauffage .",
                "creationDate": "2022-12-09T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "dispo.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-09T05:15:24.082Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-09_06.15.07",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222086971",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour ,   devis reçu rapidement et produit disponible  - je vous ai passer la commande de cette belle pompe de chauffage .",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6392c46c3c093bd0198f72c3",
        "extendedResponse": {
            "_id": "6396d5dc1ad159cdbc39f7cd",
            "submittedBody": {
                "id": "6392c46c3c093bd0198f72c30.9225074447228252",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Plu : Ces dernières semaines là réactivité, les prix , les produits . \nMoins plu : cette semaine l absence de réactivité peut être du à des réunions , des séminaires ou des vacances ... ",
                "creationDate": "2022-12-09T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julien.bernardreymond@ces-eau.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "264f4c43-add0-4c4b-ac33-ef5ce371a71f",
            "responseId": "6392c46c3c093bd0198f72c3",
            "receivedResponse": {
                "id": "6392c46c3c093bd0198f72c30.9225074447228252",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Plu : Ces dernières semaines là réactivité, les prix , les produits . \nMoins plu : cette semaine l absence de réactivité peut être du à des réunions , des séminaires ou des vacances ... ",
                "creationDate": "2022-12-09T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-09T05:15:24.084Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation__2022-12-09_06.15.07",
        "integration": {
            "ContactKey": "julien.bernardreymond@ces-eau.com",
            "CustomerContactEmail": "julien.bernardreymond@ces-eau.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BERNARD REYMOND",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "0608422184",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CES",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087040",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Plu : Ces dernières semaines là réactivité, les prix , les produits . \nMoins plu : cette semaine l absence de réactivité peut être du à des réunions , des séminaires ou des vacances ... ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Me répondre ;) "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639415f13c093bd0190d20cd",
        "extendedResponse": {
            "_id": "6396d5dc1ad159cdbc39f7ce",
            "submittedBody": {
                "id": "639415f13c093bd0190d20cd0.2845328508784948",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je n'ai pas reçu de livraison depuis plus d'un mois.. mes autres fournisseurs me donne un numéro de suivi dès le départ de leurs entrepôts.",
                "creationDate": "2022-12-10T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0620888587"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "74db0d7b-dbd2-40bd-85e4-f8d18e87756c",
            "responseId": "639415f13c093bd0190d20cd",
            "receivedResponse": {
                "id": "639415f13c093bd0190d20cd0.2845328508784948",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je n'ai pas reçu de livraison depuis plus d'un mois.. mes autres fournisseurs me donne un numéro de suivi dès le départ de leurs entrepôts.",
                "creationDate": "2022-12-10T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.neg",
                                    "label": "Quality of service / Follow-up (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "manque.produit.neg",
                "service.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "service.neg",
                    "label": "Quality of service / Follow-up (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-10T05:15:29.244Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-10_06.15.11",
        "integration": {
            "ContactKey": "0620888587",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "SAMPIERI Alain",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0620888587",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SDEC - SOCIETE DES EAUX DE CORSE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dimitri",
            "XylemSalespeopleName": "JEHU",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3550757",
            "XylemReference": "178931",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je n'ai pas reçu de livraison depuis plus d'un mois.. mes autres fournisseurs me donne un numéro de suivi dès le départ de leurs entrepôts.",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Lire plus haut"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639415f13c093bd0190d211c",
        "extendedResponse": {
            "_id": "6396d5dc1ad159cdbc39f7cf",
            "submittedBody": {
                "id": "639415f13c093bd0190d211c0.5604260170477",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Qualités des contacts, rapidité, sérieux ........... ",
                "creationDate": "2022-12-10T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pascal.baudry@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c2066976-291d-40a5-8564-a9d15bb674b6",
            "responseId": "639415f13c093bd0190d211c",
            "receivedResponse": {
                "id": "639415f13c093bd0190d211c0.5604260170477",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Qualités des contacts, rapidité, sérieux ........... ",
                "creationDate": "2022-12-10T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-10T05:15:29.248Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-10_06.15.11",
        "integration": {
            "ContactKey": "pascal.baudry@suez.com",
            "CustomerContactEmail": "pascal.baudry@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BAUDRY",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "+33680340627",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (EAU ET FORCE) (STEP WAVRECHAIN)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Maryse",
            "XylemSalespeopleName": "VANOVERBERGHE",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087231",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Qualités des contacts, rapidité, sérieux ........... ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639415f13c093bd0190d2165",
        "extendedResponse": {
            "_id": "6396f2d1ed00503088622e4d",
            "submittedBody": {
                "id": "639415f13c093bd0190d21650.9433047266248182",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "toujours à l'écoute, réponses claires et précises - délais de réponses rapides.",
                "creationDate": "2022-12-10T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.thibault@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1d26e93b-e4d7-4574-beac-1358ecf2799b",
            "responseId": "639415f13c093bd0190d2165",
            "receivedResponse": {
                "id": "639415f13c093bd0190d21650.9433047266248182",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "toujours à l'écoute, réponses claires et précises - délais de réponses rapides.",
                "creationDate": "2022-12-10T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-10T05:15:29.258Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-10_06.15.11",
        "integration": {
            "ContactKey": "philippe.thibault@saur.com",
            "CustomerContactEmail": "philippe.thibault@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THIBAULT",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0669582123",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Laurence",
            "XylemContactedPersonName": "GUILLEMINE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087162",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "toujours à l'écoute, réponses claires et précises - délais de réponses rapides.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639415f13f45642f7450a7ae",
        "extendedResponse": {
            "_id": "639aed5e26e40ec7df4914b3",
            "submittedBody": {
                "id": "639415f13f45642f7450a7ae0.4220815237702449",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": ".",
                "creationDate": "2022-12-10T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "VINCENT.GUILLAUMET@DALKIA.FR"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "35d99c49-5283-4d75-93e8-5c79c502a71a",
            "responseId": "639415f13f45642f7450a7ae",
            "receivedResponse": {
                "id": "639415f13f45642f7450a7ae0.4220815237702449",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": ".",
                "creationDate": "2022-12-10T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-10T05:15:29.270Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-10_06.15.11",
        "integration": {
            "ContactKey": "VINCENT.GUILLAUMET@DALKIA.FR",
            "CustomerContactEmail": "VINCENT.GUILLAUMET@DALKIA.FR",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUILLAUMET",
            "CustomerContactFirstName": "VINCENT",
            "CustomerContactMobilePhone": "0616674885",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA CHALONS EN CHAMPAGNE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Fabien",
            "XylemContactedPersonName": "CANNIZZARO",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": ".",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639415f13f45642f7450a7b1",
        "extendedResponse": {
            "_id": "639aed5e26e40ec7df4914b4",
            "submittedBody": {
                "id": "639415f13f45642f7450a7b10.22056831299780866",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commercial très compétent",
                "creationDate": "2022-12-10T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevinbruant@cgr-robinetterie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8b2fb082-8e3c-4c09-9ad1-3472c8e7a922",
            "responseId": "639415f13f45642f7450a7b1",
            "receivedResponse": {
                "id": "639415f13f45642f7450a7b10.22056831299780866",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commercial très compétent",
                "creationDate": "2022-12-10T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-10T05:15:29.270Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-10_06.15.11",
        "integration": {
            "ContactKey": "kevinbruant@cgr-robinetterie.fr",
            "CustomerContactEmail": "kevinbruant@cgr-robinetterie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BRUANT",
            "CustomerContactFirstName": "Kévin",
            "CustomerContactMobilePhone": "0679370422",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CGR",
            "CustomerCompanyGroupName": "CGR ROBINETTERIE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commercial très compétent",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639415f13c093bd0190d21c9",
        "extendedResponse": {
            "_id": "6396d5dd1ad159cdbc39f7d0",
            "submittedBody": {
                "id": "639415f13c093bd0190d21c90.4164654823001248",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Service rapide et efficace",
                "creationDate": "2022-12-10T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-louis@hardysas.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4b691652-c81e-4e4d-9bf8-ff635be387b4",
            "responseId": "639415f13c093bd0190d21c9",
            "receivedResponse": {
                "id": "639415f13c093bd0190d21c90.4164654823001248",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Service rapide et efficace",
                "creationDate": "2022-12-10T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-10T05:15:29.288Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-10_06.15.11",
        "integration": {
            "ContactKey": "jean-louis@hardysas.com",
            "CustomerContactEmail": "jean-louis@hardysas.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HARDY",
            "CustomerContactFirstName": "Jean-Louis",
            "CustomerContactMobilePhone": "+508410301",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAS Louis Hardy",
            "CustomerCompanyGroupName": "LOUIS HARDY SAS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN DT",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087214",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Service rapide et efficace",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639415f13c093bd0190d21eb",
        "extendedResponse": {
            "_id": "6396d5dd1ad159cdbc39f7d1",
            "submittedBody": {
                "id": "639415f13c093bd0190d21eb0.24990008497194305",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité rapidité que du bon!!",
                "creationDate": "2022-12-10T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.dexet@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5d7589df-491f-4546-849b-eef5765d5fa3",
            "responseId": "639415f13c093bd0190d21eb",
            "receivedResponse": {
                "id": "639415f13c093bd0190d21eb0.24990008497194305",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité rapidité que du bon!!",
                "creationDate": "2022-12-10T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-10T05:15:29.299Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-10_06.15.11",
        "integration": {
            "ContactKey": "sebastien.dexet@saur.com",
            "CustomerContactEmail": "sebastien.dexet@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEXET",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR 82",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087303",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité rapidité que du bon!!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4c77",
        "extendedResponse": {
            "_id": "639aed5f26e40ec7df4914b5",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4c770.4148397735706948",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m a plu : Rapiidite de réponse. Conseil technique ",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yoan.chanteloup@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ace6edbc-d305-467f-a34b-847470a6c437",
            "responseId": "63980a72ba625e6dd3bc4c77",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4c770.4148397735706948",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m a plu : Rapiidite de réponse. Conseil technique ",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.882Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "yoan.chanteloup@suez.com",
            "CustomerContactEmail": "yoan.chanteloup@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHANTELOUP",
            "CustomerContactFirstName": "Yoan",
            "CustomerContactMobilePhone": "0671437289",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087543",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m a plu : Rapiidite de réponse. Conseil technique ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "On peut toujours faire mieux :-)"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4c8d",
        "extendedResponse": {
            "_id": "639aed5f26e40ec7df4914b6",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4c8d0.863808221837604",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "j'ai aimé la réactivité et le contenu de l'offre",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vincent.idris@xyleminc.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "8545edb3-5fb7-43c4-b4cc-74cd1a2e9f5d",
            "responseId": "63980a72ba625e6dd3bc4c8d",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4c8d0.863808221837604",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "j'ai aimé la réactivité et le contenu de l'offre",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.883Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "vincent.idris@xyleminc.com",
            "CustomerContactEmail": "vincent.idris@xyleminc.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "IDRIS",
            "CustomerContactFirstName": "VINCENT",
            "CustomerContactMobilePhone": "0682819791",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VINCENT IDRIS",
            "CustomerCompanyGroupName": "VINCENT IDRIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Benjamin",
            "XylemContactedPersonName": "JADOT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087566",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "j'ai aimé la réactivité et le contenu de l'offre",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "je voulais de la documentation commerciale"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4c9c",
        "extendedResponse": {
            "_id": "639aed5f26e40ec7df4914b7",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4c9c0.5629314366022169",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité (Tony Genève)",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c.celle@choltonserp.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a78571da-d259-4dda-9c19-43626dba3405",
            "responseId": "63980a72ba625e6dd3bc4c9c",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4c9c0.5629314366022169",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité (Tony Genève)",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.883Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "c.celle@choltonserp.com",
            "CustomerContactEmail": "c.celle@choltonserp.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CELLE",
            "CustomerContactFirstName": "Corentin",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CHOLTON",
            "CustomerCompanyGroupName": "ENTREPRISE CHOLTON",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222087599",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité (Tony Genève)",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4caa",
        "extendedResponse": {
            "_id": "639aed6026e40ec7df4914b8",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4caa0.817421270760724",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité pour répondre à ma demande",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "franck@aeci01.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "68786eb6-6bf7-4c22-ae11-e52c7bbc3e7a",
            "responseId": "63980a72ba625e6dd3bc4caa",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4caa0.817421270760724",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité pour répondre à ma demande",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.888Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "franck@aeci01.fr",
            "CustomerContactEmail": "franck@aeci01.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAUTY",
            "CustomerContactFirstName": "Franck",
            "CustomerContactMobilePhone": "0622015604",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AECI",
            "CustomerCompanyGroupName": "A.E.C.I.",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222087629",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité pour répondre à ma demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4cb2",
        "extendedResponse": {
            "_id": "639aed6026e40ec7df4914b9",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4cb20.4852295207799924",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\nDepuis de nombreuses années, le partenariat entre XYLEM et SAET fonctionne très bien. Nous avons d'excellentes relations aussi bien avec les sédentaires qu'avec le commercial itinérant. Les seuls points négatifs concernent le service location et les expertises techniques.",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "guy.millerioux@luvica.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "fd10ecba-3ac0-493d-8860-2ba0c650a0e5",
            "responseId": "63980a72ba625e6dd3bc4cb2",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4cb20.4852295207799924",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\nDepuis de nombreuses années, le partenariat entre XYLEM et SAET fonctionne très bien. Nous avons d'excellentes relations aussi bien avec les sédentaires qu'avec le commercial itinérant. Les seuls points négatifs concernent le service location et les expertises techniques.",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.neg",
                                    "label": "Quality of service / Follow-up (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "service.neg",
                "accueil.pos",
                "competence.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.neg",
                    "label": "Quality of service / Follow-up (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.888Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "guy.millerioux@luvica.fr",
            "CustomerContactEmail": "guy.millerioux@luvica.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MILLERIOUX",
            "CustomerContactFirstName": "Guy",
            "CustomerContactMobilePhone": "0608778266",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAET",
            "CustomerCompanyGroupName": "Groupe LUVICA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087639",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\nDepuis de nombreuses années, le partenariat entre XYLEM et SAET fonctionne très bien. Nous avons d'excellentes relations aussi bien avec les sédentaires qu'avec le commercial itinérant. Les seuls points négatifs concernent le service location et les expertises techniques.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Voir ma réponse ci-dessus."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4cdc",
        "extendedResponse": {
            "_id": "639aed6026e40ec7df4914ba",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4cdc0.947424218030785",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tous s'est bien passé, équipe sérieuse en tous points.",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexandre.bour@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cee4ffd4-1126-4e1e-8c98-74db883bc281",
            "responseId": "63980a72ba625e6dd3bc4cdc",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4cdc0.947424218030785",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tous s'est bien passé, équipe sérieuse en tous points.",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.890Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "alexandre.bour@veolia.com",
            "CustomerContactEmail": "alexandre.bour@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUR",
            "CustomerContactFirstName": "ALEXANDRE",
            "CustomerContactMobilePhone": "0627213781",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087678",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tous s'est bien passé, équipe sérieuse en tous points.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4cf0",
        "extendedResponse": {
            "_id": "639aed6126e40ec7df4914bb",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4cf00.38525890376047633",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse ",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-yves.renaudeau@saur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "b0ce07a3-d575-4ebc-8dff-ca0a33539321",
            "responseId": "63980a72ba625e6dd3bc4cf0",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4cf00.38525890376047633",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse ",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.890Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "jean-yves.renaudeau@saur.fr",
            "CustomerContactEmail": "jean-yves.renaudeau@saur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RENAUDEAU",
            "CustomerContactFirstName": "Jean-Yves",
            "CustomerContactMobilePhone": "0661302189",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Laurence",
            "XylemContactedPersonName": "GUILLEMINE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087708",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "La perfection n'est pas de ce monde..."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a72ba625e6dd3bc4d2d",
        "extendedResponse": {
            "_id": "639aed6126e40ec7df4914bc",
            "submittedBody": {
                "id": "63980a72ba625e6dd3bc4d2d0.7548928639932326",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "les devis sont complets et clairs.\nmerci de maintenir la réactivité.",
                "creationDate": "2022-12-13T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "evidal@savoietransmissions.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "eff7d534-0740-4d9a-8855-513944dae286",
            "responseId": "63980a72ba625e6dd3bc4d2d",
            "receivedResponse": {
                "id": "63980a72ba625e6dd3bc4d2d0.7548928639932326",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "les devis sont complets et clairs.\nmerci de maintenir la réactivité.",
                "creationDate": "2022-12-13T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:30.894Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "evidal@savoietransmissions.com",
            "CustomerContactEmail": "evidal@savoietransmissions.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VIDAL",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAVOIE TRANSMISSIONS",
            "CustomerCompanyGroupName": "SAVOIE TRANSMISSIONS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Patrice",
            "XylemContactedPersonName": "PAPONE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222087723",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "les devis sont complets et clairs.\nmerci de maintenir la réactivité.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733c093bd019bce71d",
        "extendedResponse": {
            "_id": "639aed6226e40ec7df4914bd",
            "submittedBody": {
                "id": "63980a733c093bd019bce71d0.9460252904356998",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Date et horaire de livraison non respectée (plus tôt que prévus, tant mieux! Mais organisation compliqué)\nDélais de livraison correct\nManque produit dans la commande (manque panier d’égrilleur, manque rehausse) ",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0667218783"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "3cffbabf-ba16-4226-ab71-65561f2a3ceb",
            "responseId": "63980a733c093bd019bce71d",
            "receivedResponse": {
                "id": "63980a733c093bd019bce71d0.9460252904356998",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Date et horaire de livraison non respectée (plus tôt que prévus, tant mieux! Mais organisation compliqué)\nDélais de livraison correct\nManque produit dans la commande (manque panier d égrilleur, manque rehausse) ",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "manque.produit.neg",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.625Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "0667218783",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "OLIVIER VILLECROZE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0667218783",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PMS 83",
            "CustomerCompanyGroupName": "POMPES MOTEURS SERVICES 83",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "OPERA NATURE",
            "XylemReference": "192116",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Date et horaire de livraison non respectée (plus tôt que prévus, tant mieux! Mais organisation compliqué)\nDélais de livraison correct\nManque produit dans la commande (manque panier d’égrilleur, manque rehausse) ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Respecter la date de livraison OU informer le plus tôt possible du changement \nRespecter les horaires de livraison (7h30 - 12h / 13h - 17h, et donc pas 12h30)\nNe pas oublier des produits de la commande "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733c093bd019bce735",
        "extendedResponse": {
            "_id": "639aed6226e40ec7df4914be",
            "submittedBody": {
                "id": "63980a733c093bd019bce7350.17576934377075082",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide merci",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0678202027"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f78a3a90-736c-485c-bf9a-afb88f56dbed",
            "responseId": "63980a733c093bd019bce735",
            "receivedResponse": {
                "id": "63980a733c093bd019bce7350.17576934377075082",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide merci",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.627Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "0678202027",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Céline BROUETTE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0678202027",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROLYS",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "aep - 13498",
            "XylemReference": "191677",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide merci",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733c093bd019bce74a",
        "extendedResponse": {
            "_id": "639aed6226e40ec7df4914bf",
            "submittedBody": {
                "id": "63980a733c093bd019bce74a0.6052300055193558",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Facilité de commande, livraison rapide.p",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0688275517"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bed7a29a-728c-4f33-9f38-83e55c40badb",
            "responseId": "63980a733c093bd019bce74a",
            "receivedResponse": {
                "id": "63980a733c093bd019bce74a0.6052300055193558",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Facilité de commande, livraison rapide.p",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.629Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "0688275517",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "COUTY FREDERIC",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0688275517",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ 81",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "86TPLA122020827/0",
            "XylemReference": "189523",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Facilité de commande, livraison rapide.p",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733c093bd019bce758",
        "extendedResponse": {
            "_id": "639aed6326e40ec7df4914c0",
            "submittedBody": {
                "id": "63980a733c093bd019bce7580.48818323526695906",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a moins plu, c'est le sms à 6h16 ce matin. Je suis d'astreinte et je dois conserver mon téléphone à portée de main. À l'avenir, merci de faire en sorte que les messages soient envoyés plus tard dans la journée. ",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0662947692"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "2264b25c-7eb6-41b6-b529-4620b5a2b7e5",
            "responseId": "63980a733c093bd019bce758",
            "receivedResponse": {
                "id": "63980a733c093bd019bce7580.48818323526695906",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a moins plu, c'est le sms à 6h16 ce matin. Je suis d'astreinte et je dois conserver mon téléphone à portée de main. À l'avenir, merci de faire en sorte que les messages soient envoyés plus tard dans la journée. ",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.630Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "0662947692",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Benoit SIVADIER",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0662947692",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR 17",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CERA-0000421845",
            "XylemReference": "191438",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a moins plu, c'est le sms à 6h16 ce matin. Je suis d'astreinte et je dois conserver mon téléphone à portée de main. À l'avenir, merci de faire en sorte que les messages soient envoyés plus tard dans la journée. ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733c093bd019bcebbc",
        "extendedResponse": {
            "_id": "639aed6326e40ec7df4914c1",
            "submittedBody": {
                "id": "63980a733c093bd019bcebbc0.685884033154498",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J'ai passé un super moment enrichissant techniquement avec Emilie Noirot. J'en ai appris énormément, cela nous a conforté à l'idée de passer une grande commande chez vous avant la fin de l'année :) \n\n",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "myriem.baydan@spiebatignolles.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "81f502ad-baa9-43db-96f2-7c44d14fccd2",
            "responseId": "63980a733c093bd019bcebbc",
            "receivedResponse": {
                "id": "63980a733c093bd019bcebbc0.685884033154498",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J'ai passé un super moment enrichissant techniquement avec Emilie Noirot. J'en ai appris énormément, cela nous a conforté à l'idée de passer une grande commande chez vous avant la fin de l'année :) \n\n",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.731Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "myriem.baydan@spiebatignolles.fr",
            "CustomerContactEmail": "myriem.baydan@spiebatignolles.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "BAYDAN",
            "CustomerContactFirstName": "Myriem",
            "CustomerContactMobilePhone": "0607801698",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SPIE BATIGNOLLES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "NOIROT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J'ai passé un super moment enrichissant techniquement avec Emilie Noirot. J'en ai appris énormément, cela nous a conforté à l'idée de passer une grande commande chez vous avant la fin de l'année :) \n\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733c093bd019bcebd1",
        "extendedResponse": {
            "_id": "639aed6326e40ec7df4914c2",
            "submittedBody": {
                "id": "63980a733c093bd019bcebd10.017750721375116907",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et professionnalisme du commercial ",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "r.guiheneuc@agglo-pvm.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "56f168d0-0345-4b5f-ae38-f049a5ac0a34",
            "responseId": "63980a733c093bd019bcebd1",
            "receivedResponse": {
                "id": "63980a733c093bd019bcebd10.017750721375116907",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et professionnalisme du commercial ",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.733Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "r.guiheneuc@agglo-pvm.fr",
            "CustomerContactEmail": "r.guiheneuc@agglo-pvm.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUIHENEUC",
            "CustomerContactFirstName": "RENE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COMMUNAUTE D'AGGLO vallée de la MARNE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Flavio",
            "XylemContactedPersonName": "MEDARDONI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et professionnalisme du commercial ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733f45642f74fc87d4",
        "extendedResponse": {
            "_id": "639aed6426e40ec7df4914c5",
            "submittedBody": {
                "id": "63980a733f45642f74fc87d40.4488129301971069",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Suivi de SAV non efficace, date de mise à dispo de l'équipement validée mais équipement non réparé.",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.moreno@stgs.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "8b836ad6-77a2-463e-b6bb-09bc8c8ddfbb",
            "responseId": "63980a733f45642f74fc87d4",
            "receivedResponse": {
                "id": "63980a733f45642f74fc87d40.4488129301971069",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Suivi de SAV non efficace, date de mise à dispo de l'équipement validée mais équipement non réparé.",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "abime.neg",
                                    "label": "Damaged product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.neg",
                                    "label": "Quality of service / Follow-up (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "abime.neg",
                "service.neg",
                "ecoute.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "abime.neg",
                    "label": "Damaged product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "service.neg",
                    "label": "Quality of service / Follow-up (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.937Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "kevin.moreno@stgs.fr",
            "CustomerContactEmail": "kevin.moreno@stgs.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORENO",
            "CustomerContactFirstName": "Kévin",
            "CustomerContactMobilePhone": "0680837974",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "STGS",
            "CustomerCompanyGroupName": "FEDERATION DES DISTRIBUTEURS D'EAU INDEPENDANTS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Stéphanie",
            "XylemContactedPersonName": "CLOITRE",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "51149",
            "XylemReference": "2RF033314",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Suivi de SAV non efficace, date de mise à dispo de l'équipement validée mais équipement non réparé.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733f45642f74fc87d0",
        "extendedResponse": {
            "_id": "639aed6426e40ec7df4914c3",
            "submittedBody": {
                "id": "63980a733f45642f74fc87d00.6983494878987422",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Plus: Interlocuteur réactif et professionnel\nMoins: Attente quand on est dans l'urgence. On pourrait réactualiser celle-ci, après avoir lancé l'opération dans des situations d'urgences.",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cedric.lamure@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "9d21c3cc-eb44-490d-b710-c0cfc8b230d3",
            "responseId": "63980a733f45642f74fc87d0",
            "receivedResponse": {
                "id": "63980a733f45642f74fc87d00.6983494878987422",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Plus: Interlocuteur réactif et professionnel\nMoins: Attente quand on est dans l'urgence. On pourrait réactualiser celle-ci, après avoir lancé l'opération dans des situations d'urgences.",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.937Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "cedric.lamure@suez.com",
            "CustomerContactEmail": "cedric.lamure@suez.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0642900882",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Annabel",
            "XylemContactedPersonName": "VALENTIN",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "89TPLA122000448/0",
            "XylemReference": "2RF029804",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Plus: Interlocuteur réactif et professionnel\nMoins: Attente quand on est dans l'urgence. On pourrait réactualiser celle-ci, après avoir lancé l'opération dans des situations d'urgences.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Plus de réactivité sur des situations d'urgences pour des histoires d'administratifs."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63980a733f45642f74fc87d1",
        "extendedResponse": {
            "_id": "639aed6426e40ec7df4914c4",
            "submittedBody": {
                "id": "63980a733f45642f74fc87d10.4872131100671808",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "pas de reproche à faire. un travail très professionnel est effectué.\n\ncordialement",
                "creationDate": "2022-12-13T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christian.masson@siaap.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cf07ae36-2126-4f1f-902f-ed203a086ae3",
            "responseId": "63980a733f45642f74fc87d1",
            "receivedResponse": {
                "id": "63980a733f45642f74fc87d10.4872131100671808",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "pas de reproche à faire. un travail très professionnel est effectué.\n\ncordialement",
                "creationDate": "2022-12-13T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-13T05:15:31.937Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2022-12-13_06.15.09",
        "integration": {
            "ContactKey": "christian.masson@siaap.fr",
            "CustomerContactEmail": "christian.masson@siaap.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MASSON",
            "CustomerContactFirstName": "Christian",
            "CustomerContactMobilePhone": "0141191110",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIAAP-SITE SEC-SEINE CENTRE-COLOMBES",
            "CustomerCompanyGroupName": "SIAAP (Groupe National)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Grégory",
            "XylemSalespeopleName": "MENANT",
            "XylemContactedPersonFirstName": "Tchandra",
            "XylemContactedPersonName": "AMBROISE",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "520619",
            "XylemReference": "2RF044351",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "pas de reproche à faire. un travail très professionnel est effectué.\n\ncordialement",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf53f45642f74717c46",
        "extendedResponse": {
            "_id": "639aed6526e40ec7df4914c7",
            "submittedBody": {
                "id": "63995bf53f45642f74717c460.4352355227238214",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "tous c'est très bien passer.",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolas.banos@soverdi.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "11b8f56a-c2fa-4df6-825b-24c476ba8ce7",
            "responseId": "63995bf53f45642f74717c46",
            "receivedResponse": {
                "id": "63995bf53f45642f74717c460.4352355227238214",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "tous c'est très bien passer.",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.078Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "nicolas.banos@soverdi.fr",
            "CustomerContactEmail": "nicolas.banos@soverdi.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BANOS",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0677846807",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOVERDI 40",
            "CustomerCompanyGroupName": "SOVERDI",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "RICHARD",
            "XylemContactedPersonRole": "Responsable régional ventes",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "tous c'est très bien passer.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf53f45642f74717c59",
        "extendedResponse": {
            "_id": "639aed6526e40ec7df4914c8",
            "submittedBody": {
                "id": "63995bf53f45642f74717c590.27346825830214017",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien à dire, très professionnel et disponible \nTrès bonne collaboration ",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ndrubay@vldc.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "72ce564a-1f41-4d46-b8bc-530ac5baec2e",
            "responseId": "63995bf53f45642f74717c59",
            "receivedResponse": {
                "id": "63995bf53f45642f74717c590.27346825830214017",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien à dire, très professionnel et disponible \nTrès bonne collaboration ",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "competence.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.079Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "ndrubay@vldc.fr",
            "CustomerContactEmail": "ndrubay@vldc.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DRUBAY",
            "CustomerContactFirstName": "NICOLAS",
            "CustomerContactMobilePhone": "0612295530",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CAVE DE DURAS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "RICHARD",
            "XylemContactedPersonRole": "Responsable régional ventes",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien à dire, très professionnel et disponible \nTrès bonne collaboration ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf53f45642f74717c68",
        "extendedResponse": {
            "_id": "639aed6626e40ec7df4914c9",
            "submittedBody": {
                "id": "63995bf53f45642f74717c680.30272810351401347",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "professionnalisme, écoute, technique et sympas.",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-francois.levrard@groupe-fb.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1211b02b-dfb6-4300-8265-09704f87e6b8",
            "responseId": "63995bf53f45642f74717c68",
            "receivedResponse": {
                "id": "63995bf53f45642f74717c680.30272810351401347",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "professionnalisme, écoute, technique et sympa.",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.080Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "jean-francois.levrard@groupe-fb.com",
            "CustomerContactEmail": "jean-francois.levrard@groupe-fb.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEVRARD",
            "CustomerContactFirstName": "Jean-Francois",
            "CustomerContactMobilePhone": "0684978214",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (155)",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Fabrice",
            "XylemContactedPersonName": "ROBIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "professionnalisme, écoute, technique et sympas.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd3434026",
        "extendedResponse": {
            "_id": "639aed6626e40ec7df4914ca",
            "submittedBody": {
                "id": "63995bf5ba625e6dd34340260.9951714229502677",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité de réponse conforme à la demande",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sbrouard@agglo-lisieux.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dc447900-7465-455e-a04b-da88a3bea91d",
            "responseId": "63995bf5ba625e6dd3434026",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd34340260.9951714229502677",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité de réponse conforme à la demande",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.910Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "sbrouard@agglo-lisieux.fr",
            "CustomerContactEmail": "sbrouard@agglo-lisieux.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "BROUARD",
            "CustomerContactFirstName": "Stéphanie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "S.I.T.E.",
            "CustomerCompanyGroupName": "CA LISIEUX NORMANDIE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088014",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité de réponse conforme à la demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd343402c",
        "extendedResponse": {
            "_id": "639aed6726e40ec7df4914cb",
            "submittedBody": {
                "id": "63995bf5ba625e6dd343402c0.08435483562793089",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité dans la réponse\nConformité face à la demande ",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "didier.yvon@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "09161c7f-383a-4a21-84f6-6e14b6ed14dd",
            "responseId": "63995bf5ba625e6dd343402c",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd343402c0.08435483562793089",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité dans la réponse\nConformité face à la demande ",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.911Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "didier.yvon@saur.com",
            "CustomerContactEmail": "didier.yvon@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "YVON",
            "CustomerContactFirstName": "Didier",
            "CustomerContactMobilePhone": "0675498464",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR CENTRE DE LUDRES",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jérôme",
            "XylemSalespeopleName": "STEINMETZ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088032",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité dans la réponse\nConformité face à la demande ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd343403f",
        "extendedResponse": {
            "_id": "639aed6826e40ec7df4914cc",
            "submittedBody": {
                "id": "63995bf5ba625e6dd343403f0.4266573816472885",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "facilité pour vous joindre, détails des explications techniques, réactivité",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gregoire.delvaux@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b37fd2cb-9163-4c79-9f47-93f5963881ab",
            "responseId": "63995bf5ba625e6dd343403f",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd343403f0.4266573816472885",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "facilité pour vous joindre, détails des explications techniques, réactivité",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.pos",
                                    "label": "Reachability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "joignabilite.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "joignabilite.pos",
                    "label": "Reachability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.911Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "gregoire.delvaux@veolia.com",
            "CustomerContactEmail": "gregoire.delvaux@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELVAUX",
            "CustomerContactFirstName": "Grégoire",
            "CustomerContactMobilePhone": "+33629442224",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE ARRAS)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222084410",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "facilité pour vous joindre, détails des explications techniques, réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd343406e",
        "extendedResponse": {
            "_id": "639aed6926e40ec7df4914cd",
            "submittedBody": {
                "id": "63995bf5ba625e6dd343406e0.38415421311036524",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Encore une hausse de tarifs ",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.turnaco@communaute-paysbasque.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "e7a7a661-3c40-46f2-92ed-64e5182c0327",
            "responseId": "63995bf5ba625e6dd343406e",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd343406e0.38415421311036524",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Encore une hausse de tarifs ",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.var.neg",
                                    "label": "Price increase (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "prix.var.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "prix.var.neg",
                    "label": "Price increase (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.913Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "j.turnaco@communaute-paysbasque.fr",
            "CustomerContactEmail": "j.turnaco@communaute-paysbasque.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TURNACO",
            "CustomerContactFirstName": "JEROME",
            "CustomerContactMobilePhone": "0629194522",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COMMUNAUTE D'AGGLO. CÔTE BASQUE",
            "CustomerCompanyGroupName": "COMMUNAUTÉ D'AGGLOMÉRATION",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "ACHARD",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "219032903",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Encore une hausse de tarifs ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd3434078",
        "extendedResponse": {
            "_id": "63b58bbff3c2ca4e91762c9b",
            "submittedBody": {
                "id": "63995bf5ba625e6dd34340780.22077907672395436",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les échanges fluides avec vos services lors de demande de devis,rapidité des retours propositions en adéquation avec nos demandes.",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.blactot@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "be93050f-38cb-4775-8cb4-56384733d15e",
            "responseId": "63995bf5ba625e6dd3434078",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd34340780.22077907672395436",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les échanges fluides avec vos services lors de demande de devis,rapidité des retours propositions en adéquation avec nos demandes.",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.913Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "laurent.blactot@veolia.com",
            "CustomerContactEmail": "laurent.blactot@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BLACTOT",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0634417940",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE EXPLOITATION DE NDIE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Christophe",
            "XylemSalespeopleName": "DOUAY",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087923",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les échanges fluides avec vos services lors de demande de devis,rapidité des retours propositions en adéquation avec nos demandes.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd34340bc",
        "extendedResponse": {
            "_id": "639aed6a26e40ec7df4914ce",
            "submittedBody": {
                "id": "63995bf5ba625e6dd34340bc0.3990126146440094",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commercial sédentaire très pro",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "a.montanes@serclim.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "031d262e-57a2-4d60-be07-8e230d49b97a",
            "responseId": "63995bf5ba625e6dd34340bc",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd34340bc0.3990126146440094",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commercial sédentaire très professionnel",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.916Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "a.montanes@serclim.fr",
            "CustomerContactEmail": "a.montanes@serclim.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONTANES",
            "CustomerContactFirstName": "ALBERT",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERCLIM",
            "CustomerCompanyGroupName": "SAS SERCLIM",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222087968",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commercial sédentaire très pro",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd34340bd",
        "extendedResponse": {
            "_id": "639aed6a26e40ec7df4914cf",
            "submittedBody": {
                "id": "63995bf5ba625e6dd34340bd0.6728796550079008",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Personnel très réactif et professionnel",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "khalid.bayadroun@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f10b5e5c-f6b2-45b2-82ad-1ce5e60ea868",
            "responseId": "63995bf5ba625e6dd34340bd",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd34340bd0.6728796550079008",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Personnel très réactif et professionnel",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.916Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "khalid.bayadroun@veolia.com",
            "CustomerContactEmail": "khalid.bayadroun@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BAYADROUN",
            "CustomerContactFirstName": "Khalid",
            "CustomerContactMobilePhone": "0601145559",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088029",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Personnel très réactif et professionnel",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd34340d4",
        "extendedResponse": {
            "_id": "639aed6b26e40ec7df4914d0",
            "submittedBody": {
                "id": "63995bf5ba625e6dd34340d40.4041084857156374",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis généralement rendu rapidement. e.Access à améliorer encore, manque par exemple l'accès aux BL et  Factures . ",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pmoreau@sofintec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "8e5972f3-a93b-477a-b9bf-0a428c82441b",
            "responseId": "63995bf5ba625e6dd34340d4",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd34340d40.4041084857156374",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis généralement rendu rapidement. e.accès à améliorer encore, manque par exemple l'accès aux BL et Factures . ",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.917Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "pmoreau@sofintec.fr",
            "CustomerContactEmail": "pmoreau@sofintec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MOREAU",
            "CustomerContactFirstName": "Patrick",
            "CustomerContactMobilePhone": "0675230615",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTEC INDUSTRIES",
            "CustomerCompanyGroupName": "SOFINTEC (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088057",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis généralement rendu rapidement. e.Access à améliorer encore, manque par exemple l'accès aux BL et  Factures . ",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd3434121",
        "extendedResponse": {
            "_id": "639aed6c26e40ec7df4914d2",
            "submittedBody": {
                "id": "63995bf5ba625e6dd34341210.9799066328033763",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bonne réactivité",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bruno.gaudion@henon.pro"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dbffd33d-58ea-4599-8fd2-a8c3f5c81940",
            "responseId": "63995bf5ba625e6dd3434121",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd34341210.9799066328033763",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bonne réactivité",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.919Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "bruno.gaudion@henon.pro",
            "CustomerContactEmail": "bruno.gaudion@henon.pro",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GAUDION",
            "CustomerContactFirstName": "BRUNO",
            "CustomerContactMobilePhone": "0629194553",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HENON SAS",
            "CustomerCompanyGroupName": "HENON SAS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088168",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bonne réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63995bf5ba625e6dd343410b",
        "extendedResponse": {
            "_id": "639aed6b26e40ec7df4914d1",
            "submittedBody": {
                "id": "63995bf5ba625e6dd343410b0.012385223107629129",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide - rien à dire",
                "creationDate": "2022-12-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@pompegedo.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "afc566aa-e126-43be-b530-a06b16090f9a",
            "responseId": "63995bf5ba625e6dd343410b",
            "receivedResponse": {
                "id": "63995bf5ba625e6dd343410b0.012385223107629129",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide - rien à dire",
                "creationDate": "2022-12-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-14T05:15:33.919Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-14_06.15.11",
        "integration": {
            "ContactKey": "contact@pompegedo.com",
            "CustomerContactEmail": "contact@pompegedo.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THIRIEZ",
            "CustomerContactFirstName": "Rodolphe",
            "CustomerContactMobilePhone": "0680243591",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GEDO POMPES",
            "CustomerCompanyGroupName": "ALPHEUS - GEDO POMPES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Thierry",
            "XylemContactedPersonName": "HERMAN",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222088130",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide - rien à dire",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c8547f",
        "extendedResponse": {
            "_id": "639aed6c26e40ec7df4914d3",
            "submittedBody": {
                "id": "639aad6e771ded1430c8547f0.7523526220663121",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "DEVIS RAPIDE \nPRECIS\nAVEC DES DELAIS INDIQUES\nPARFAIT",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dmerdy@sofintec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cd57fb7d-9d81-406e-b350-366392446537",
            "responseId": "639aad6e771ded1430c8547f",
            "receivedResponse": {
                "id": "639aad6e771ded1430c8547f0.7523526220663121",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "DEVIS RAPIDE \nPRECIS\nAVEC DES DELAIS INDIQUES\nPARFAIT",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.896Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "dmerdy@sofintec.fr",
            "CustomerContactEmail": "dmerdy@sofintec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MERDY",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTEC INDUSTRIES",
            "CustomerCompanyGroupName": "SOFINTEC (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088260",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "DEVIS RAPIDE \nPRECIS\nAVEC DES DELAIS INDIQUES\nPARFAIT",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c8548f",
        "extendedResponse": {
            "_id": "639aed6c26e40ec7df4914d4",
            "submittedBody": {
                "id": "639aad6e771ded1430c8548f0.9869127007683987",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jeremie.fripier@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ad560b5b-e5e0-4e2f-bf5f-f8ece1f4b583",
            "responseId": "639aad6e771ded1430c8548f",
            "receivedResponse": {
                "id": "639aad6e771ded1430c8548f0.9869127007683987",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.897Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "jeremie.fripier@veolia.com",
            "CustomerContactEmail": "jeremie.fripier@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FRIPIER",
            "CustomerContactFirstName": "Jérémie",
            "CustomerContactMobilePhone": "0646980895",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088352",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c854a8",
        "extendedResponse": {
            "_id": "639aed6d26e40ec7df4914d5",
            "submittedBody": {
                "id": "639aad6e771ded1430c854a80.06065382982249701",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Facile rapide RAS de ce coté, par contre le SMS à 6h du matin pour l'enquête de satisfaction pas top.",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.couty@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c8555954-2ce5-4816-a840-7708d193ffd3",
            "responseId": "639aad6e771ded1430c854a8",
            "receivedResponse": {
                "id": "639aad6e771ded1430c854a80.06065382982249701",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Facile rapide RAS de ce côté, par contre le SMS à 6h du matin pour l'enquête de satisfaction pas top.",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.898Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "frederic.couty@suez.com",
            "CustomerContactEmail": "frederic.couty@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COUTY",
            "CustomerContactFirstName": "FREDERIC",
            "CustomerContactMobilePhone": "0688275517",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ 81",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088438",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Facile rapide RAS de ce coté, par contre le SMS à 6h du matin pour l'enquête de satisfaction pas top.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c854b6",
        "extendedResponse": {
            "_id": "639aed6d26e40ec7df4914d6",
            "submittedBody": {
                "id": "639aad6e771ded1430c854b60.8911839830790762",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponses rapide pour les pièces detachees",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@moteur-pinson.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "c5361ff6-4f79-4427-86da-4f01a946c074",
            "responseId": "639aad6e771ded1430c854b6",
            "receivedResponse": {
                "id": "639aad6e771ded1430c854b60.8911839830790762",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponses rapide pour les pièces détachées",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.899Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "contact@moteur-pinson.com",
            "CustomerContactEmail": "contact@moteur-pinson.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "PROVOST",
            "CustomerContactFirstName": "Christine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PINSON SARL",
            "CustomerCompanyGroupName": "ETS PINSON",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222088556",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponses rapide pour les pièces detachees",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c854b5",
        "extendedResponse": {
            "_id": "63b58bc0f3c2ca4e91762c9c",
            "submittedBody": {
                "id": "639aad6e771ded1430c854b50.896374305623902",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le prix que vous m'avez donné est équivalent à celui que Rexel a remis à mon client final. Je pense que le groupe CIR n'a pas les conditions nécessaires pour distribuer vos produits. Je continuerai donc à proposer Jetly ou Pedrollo  ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "h.deville@cir.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "7626c601-c2dd-458f-8681-1a428abbf02a",
            "responseId": "639aad6e771ded1430c854b5",
            "receivedResponse": {
                "id": "639aad6e771ded1430c854b50.896374305623902",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le prix que vous m'avez donné est équivalent à celui que Rexel a remis à mon client final. Je pense que le groupe CIR n'a pas les conditions nécessaires pour distribuer vos produits. Je continuerai donc à proposer Jetly ou Pedrollo ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.899Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "h.deville@cir.fr",
            "CustomerContactEmail": "h.deville@cir.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEVILLE",
            "CustomerContactFirstName": "Hughes",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CIR",
            "CustomerCompanyGroupName": "CIR",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088553",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le prix que vous m'avez donné est équivalent à celui que Rexel a remis à mon client final. Je pense que le groupe CIR n'a pas les conditions nécessaires pour distribuer vos produits. Je continuerai donc à proposer Jetly ou Pedrollo  ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c854e2",
        "extendedResponse": {
            "_id": "639aed6d26e40ec7df4914d7",
            "submittedBody": {
                "id": "639aad6e771ded1430c854e20.8635255353071314",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Votre réactivité et un devis fait rapidement. ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.mencaglia@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e7752dcf-0689-4393-b27e-2ba5e6771460",
            "responseId": "639aad6e771ded1430c854e2",
            "receivedResponse": {
                "id": "639aad6e771ded1430c854e20.8635255353071314",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Votre réactivité et un devis fait rapidement. ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.902Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "eric.mencaglia@suez.com",
            "CustomerContactEmail": "eric.mencaglia@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MENCAGLIA",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONN. DES EAUX (D.R.)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": ".",
            "XylemSalespeopleName": "TCS ZONE SUD SUD EST",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088304",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Votre réactivité et un devis fait rapidement. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c854e4",
        "extendedResponse": {
            "_id": "639aed6e26e40ec7df4914d8",
            "submittedBody": {
                "id": "639aad6e771ded1430c854e40.42040292297902426",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les retours de devis sont rapides, merci après des années où cela a été plus difficile... ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "herve.tourniaire@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "8ad2649e-92f4-428c-abfe-def8db9c66bf",
            "responseId": "639aad6e771ded1430c854e4",
            "receivedResponse": {
                "id": "639aad6e771ded1430c854e40.42040292297902426",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les retours de devis sont rapides, merci après des années où cela a été plus difficile... ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.902Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "herve.tourniaire@saur.com",
            "CustomerContactEmail": "herve.tourniaire@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TOURNIAIRE",
            "CustomerContactFirstName": "HERVE",
            "CustomerContactMobilePhone": "0680488306",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222088298",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les retours de devis sont rapides, merci après des années où cela a été plus difficile... ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "reste encore un effort à réaliser sur les devis de produits + complexes (postes de relevage complets, etc.).\nLes commerciaux sédentaires sont trop difficiles à joindre au téléphone."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c8550a",
        "extendedResponse": {
            "_id": "639aed6e26e40ec7df4914d9",
            "submittedBody": {
                "id": "639aad6e771ded1430c8550a0.44758460116867194",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Personnellement, il faudrait que l'on est 1 ou 2 interlocuteurs par région, car la on envoi nos demandes sur une boite numérique général (j'ai toujours eu un retour!!!).",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.rodrigues@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "90d4e01b-f9f1-4db5-b25f-d4aae51ce0a5",
            "responseId": "639aad6e771ded1430c8550a",
            "receivedResponse": {
                "id": "639aad6e771ded1430c8550a0.44758460116867194",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Personnellement, il faudrait que l'on est 1 ou 2 interlocuteurs par région, car la on envoi nos demandes sur une boîte numérique général (j'ai toujours eu un retour!!!).",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.904Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "christophe.rodrigues@saur.com",
            "CustomerContactEmail": "christophe.rodrigues@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RODRIGUES",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0668037832",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR - AGGLOPOLE PROVENCE ASSAINISSEMENT",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088360",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Personnellement, il faudrait que l'on est 1 ou 2 interlocuteurs par région, car la on envoi nos demandes sur une boite numérique général (j'ai toujours eu un retour!!!).",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Que le ou les commerciaux de mon secteur prenne un peu plus contact avec nous , viennes nous voir sur place pour échanger, présenter les nouveaux produits, technologies etc...."
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c8557a",
        "extendedResponse": {
            "_id": "639aed6e26e40ec7df4914da",
            "submittedBody": {
                "id": "639aad6e771ded1430c8557a0.12052333719102903",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jbouveret@cabinet-andre.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d2f511a5-2ea8-4a29-b936-8563ef9f9f02",
            "responseId": "639aad6e771ded1430c8557a",
            "receivedResponse": {
                "id": "639aad6e771ded1430c8557a0.12052333719102903",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.909Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "jbouveret@cabinet-andre.fr",
            "CustomerContactEmail": "jbouveret@cabinet-andre.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUVERET",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "0764895982",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CABINET ANDRE(Groupe MERLIN)",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222070255",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Je ne sais pas"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c85590",
        "extendedResponse": {
            "_id": "639aed6f26e40ec7df4914db",
            "submittedBody": {
                "id": "639aad6e771ded1430c855900.4248923065823762",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délais de réponse rapide et correcte ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ali.jalbakh@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "764b9fd5-5b0d-4cef-9ecf-be10902c931a",
            "responseId": "639aad6e771ded1430c85590",
            "receivedResponse": {
                "id": "639aad6e771ded1430c855900.4248923065823762",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délais de réponse rapide et correcte ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.911Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "ali.jalbakh@suez.com",
            "CustomerContactEmail": "ali.jalbakh@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JALBAKH",
            "CustomerContactFirstName": "Ali",
            "CustomerContactMobilePhone": "0637278593",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088554",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délais de réponse rapide et correcte ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e771ded1430c8559f",
        "extendedResponse": {
            "_id": "63b58bc0f3c2ca4e91762c9d",
            "submittedBody": {
                "id": "639aad6e771ded1430c8559f0.630618034186136",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "ce qui me plait c'est la rapidité de répondre de la part de Mr EHRENFELD ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "franck.dupuy@aqua-clean.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c1b82b2e-3211-499b-bbca-d0d54e4da774",
            "responseId": "639aad6e771ded1430c8559f",
            "receivedResponse": {
                "id": "639aad6e771ded1430c8559f0.630618034186136",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "ce qui me plaît c'est la rapidité de répondre de la part de Mr EHRENFELD ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.911Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "franck.dupuy@aqua-clean.fr",
            "CustomerContactEmail": "franck.dupuy@aqua-clean.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUPUY",
            "CustomerContactFirstName": "Franck",
            "CustomerContactMobilePhone": "0782452221",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "L EAU REINE",
            "CustomerCompanyGroupName": "L'EAU REINE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "EHRENFELD",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "222088571",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "ce qui me plait c'est la rapidité de répondre de la part de Mr EHRENFELD ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e3c093bd01979ddd6",
        "extendedResponse": {
            "_id": "639aed6f26e40ec7df4914dc",
            "submittedBody": {
                "id": "639aad6e3c093bd01979ddd60.31579460333332565",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Prévenance de date de livraison ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0615140540"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d5871926-1c4f-4521-aca7-c134de51c54b",
            "responseId": "639aad6e3c093bd01979ddd6",
            "receivedResponse": {
                "id": "639aad6e3c093bd01979ddd60.31579460333332565",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Prévenance de date de livraison ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.921Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "0615140540",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. ROMAIN VAYSSIERES",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0615140540",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE BOE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Bénedicte",
            "XylemContactedPersonName": "REBOUD",
            "XylemContactedPersonRole": "GECO",
            "CustomerReference": "CDF E0042 2022 043296",
            "XylemReference": "185213",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Prévenance de date de livraison ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e3c093bd01979ddfa",
        "extendedResponse": {
            "_id": "639aed6f26e40ec7df4914dd",
            "submittedBody": {
                "id": "639aad6e3c093bd01979ddfa0.9790022105692757",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délais de livraison rapide ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0610282007"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "15200c23-1ea5-468c-95b6-37bdc22f39ed",
            "responseId": "639aad6e3c093bd01979ddfa",
            "receivedResponse": {
                "id": "639aad6e3c093bd01979ddfa0.9790022105692757",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délais de livraison rapide ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.924Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "0610282007",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "VIMONT Denis",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0610282007",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3633533",
            "XylemReference": "192544",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délais de livraison rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6e3c093bd01979ddff",
        "extendedResponse": {
            "_id": "639aed7026e40ec7df4914de",
            "submittedBody": {
                "id": "639aad6e3c093bd01979ddff0.9939889108033133",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité  pour les livraisons et les devis et les produits bien emballer ",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0626656427"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e9939ef7-688b-4f0b-bf85-613812f8d919",
            "responseId": "639aad6e3c093bd01979ddff",
            "receivedResponse": {
                "id": "639aad6e3c093bd01979ddff0.9939889108033133",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité pour les livraisons et les devis et les produits bien emballer ",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.924Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "0626656427",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "CHATRE Antoine",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0626656427",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "69TPLA122026969/0",
            "XylemReference": "191653",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité  pour les livraisons et les devis et les produits bien emballer ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639aad6eba625e6dd3a92544",
        "extendedResponse": {
            "_id": "639aed7026e40ec7df4914df",
            "submittedBody": {
                "id": "639aad6eba625e6dd3a925440.3111809842528286",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon contact, suivi régulier et toujours disponible",
                "creationDate": "2022-12-15T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cyril.durand@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d5b8736a-9d5d-481c-a29c-e5b7c2d709f9",
            "responseId": "639aad6eba625e6dd3a92544",
            "receivedResponse": {
                "id": "639aad6eba625e6dd3a925440.3111809842528286",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon contact, suivi régulier et toujours disponible",
                "creationDate": "2022-12-15T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "service.pos",
                "accueil.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-15T05:15:26.945Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-15_06.15.09",
        "integration": {
            "ContactKey": "cyril.durand@veolia.com",
            "CustomerContactEmail": "cyril.durand@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DURAND",
            "CustomerContactFirstName": "Cyril",
            "CustomerContactMobilePhone": "0623436125",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Pierrick",
            "XylemContactedPersonName": "FRAPPAS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon contact, suivi régulier et toujours disponible",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef0ba625e6dd3287c4c",
        "extendedResponse": {
            "_id": "63b58bc1f3c2ca4e91762c9e",
            "submittedBody": {
                "id": "639bfef0ba625e6dd3287c4c0.23689824001776172",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Formation des équipe de Marmande\nDiscours franc et échange constructif\n",
                "creationDate": "2022-12-16T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gilles.danet@materiauxtp.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5d1aa86f-9ed3-486f-a600-69eee6f6678f",
            "responseId": "639bfef0ba625e6dd3287c4c",
            "receivedResponse": {
                "id": "639bfef0ba625e6dd3287c4c0.23689824001776172",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Formation des équipe de Marmande\nDiscours franc et échange constructif\n",
                "creationDate": "2022-12-16T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.neg",
                                    "label": "Skills (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.neg",
                    "label": "Skills (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-16T05:15:28.693Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "gilles.danet@materiauxtp.fr",
            "CustomerContactEmail": "gilles.danet@materiauxtp.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DANET",
            "CustomerContactFirstName": "Gilles",
            "CustomerContactMobilePhone": "0675241252",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MTP",
            "CustomerCompanyGroupName": "MTP",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "RIGAL",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Formation des équipe de Marmande\nDiscours franc et échange constructif\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef03c093bd019f8275b",
        "extendedResponse": {
            "_id": "63b58bc1f3c2ca4e91762c9f",
            "submittedBody": {
                "id": "639bfef03c093bd019f8275b0.7137912714064976",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Qualité",
                "creationDate": "2022-12-16T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0624960314"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8a1b9ac0-6bc9-44e8-bc72-7aef54e642f8",
            "responseId": "639bfef03c093bd019f8275b",
            "receivedResponse": {
                "id": "639bfef03c093bd019f8275b0.7137912714064976",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Qualité",
                "creationDate": "2022-12-16T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-16T05:15:28.729Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "0624960314",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LEVILLAIN Anthony",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0624960314",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE NORMANDIE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "61TPLA122003377/0",
            "XylemReference": "193062",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Qualité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef03c093bd019f8277f",
        "extendedResponse": {
            "_id": "63b58bc2f3c2ca4e91762ca0",
            "submittedBody": {
                "id": "639bfef03c093bd019f8277f0.42703615468805567",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ras",
                "creationDate": "2022-12-16T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0627889348"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b1de1cac-2d92-4a74-9ea5-ec308396f5a5",
            "responseId": "639bfef03c093bd019f8277f",
            "receivedResponse": {
                "id": "639bfef03c093bd019f8277f0.42703615468805567",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ras",
                "creationDate": "2022-12-16T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-16T05:15:28.732Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "0627889348",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Simon TOURDE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0627889348",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ACTEMIUM/API",
            "CustomerCompanyGroupName": "ACTEMIUM / API",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN (L)",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "7125 -DAUDE",
            "XylemReference": "192644",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ras",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef1ba625e6dd3288447",
        "extendedResponse": {
            "_id": "63b58bc2f3c2ca4e91762ca1",
            "submittedBody": {
                "id": "639bfef1ba625e6dd32884470.7027409561260543",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité efficacité et service compétant",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vincent.tefau@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1d3f4c23-1d8a-48a2-af52-1feeb6653cb2",
            "responseId": "639bfef1ba625e6dd3288447",
            "receivedResponse": {
                "id": "639bfef1ba625e6dd32884470.7027409561260543",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité efficacité et service compétent",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "service.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.767Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "vincent.tefau@suez.com",
            "CustomerContactEmail": "vincent.tefau@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TEFAU",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0764885985",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ STEP FEYSSINE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Annabel",
            "XylemContactedPersonName": "VALENTIN",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "89TPLA122000462/0",
            "XylemReference": "2RF029204",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité efficacité et service compétant",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f8313b",
        "extendedResponse": {
            "_id": "63b58bc3f3c2ca4e91762ca2",
            "submittedBody": {
                "id": "639bfef13c093bd019f8313b0.8364089767753773",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Parfait, équipe très réactive, très  pro.\n",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "csecorro.abm78@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "209bf186-a4ac-478d-8292-4f5194430698",
            "responseId": "639bfef13c093bd019f8313b",
            "receivedResponse": {
                "id": "639bfef13c093bd019f8313b0.8364089767753773",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Parfait, équipe très réactive, très professionnel.\n",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.772Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "csecorro.abm78@gmail.com",
            "CustomerContactEmail": "csecorro.abm78@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BRESSON",
            "CustomerContactFirstName": "Celine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ABM",
            "CustomerCompanyGroupName": "A B M (ATEL. BOBINAGE MUREAUX)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088636",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Parfait, équipe très réactive, très  pro.\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f83155",
        "extendedResponse": {
            "_id": "63b58bc3f3c2ca4e91762ca3",
            "submittedBody": {
                "id": "639bfef13c093bd019f831550.46244407613036853",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "- ce qui m'a plu : qualité des matériaux\n- ce qui m'a déplu : pas de contact\n- pas de sérieux pour le délais de livraison \n- pas d'échange direct avec un interlocuteur \n- délai dans les devis\n- pas d'organisation ",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yann-erwan.parent@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "8c271972-ae4d-4b2c-ad3e-71f63059f88f",
            "responseId": "639bfef13c093bd019f83155",
            "receivedResponse": {
                "id": "639bfef13c093bd019f831550.46244407613036853",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "- ce qui m'a plu : qualité des matériaux\n- ce qui m'a déplu : pas de contact\n- pas de sérieux pour le délais de livraison \n- pas d'échange direct avec un interlocuteur \n- délai dans les devis\n- pas d'organisation ",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.neg",
                                    "label": "Quality (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "qualite.neg",
                "delai.neg",
                "joignabilite.neg",
                "ressenti.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "qualite.neg",
                    "label": "Quality (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.774Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "yann-erwan.parent@suez.com",
            "CustomerContactEmail": "yann-erwan.parent@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PARENT",
            "CustomerContactFirstName": "Yann-Erwan",
            "CustomerContactMobilePhone": "0629990552",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EPuRéo STEP TOUGAS",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222088769",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "- ce qui m'a plu : qualité des matériaux\n- ce qui m'a déplu : pas de contact\n- pas de sérieux pour le délais de livraison \n- pas d'échange direct avec un interlocuteur \n- délai dans les devis\n- pas d'organisation ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f83165",
        "extendedResponse": {
            "_id": "63b58bc4f3c2ca4e91762ca4",
            "submittedBody": {
                "id": "639bfef13c093bd019f831650.985271735245637",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui nous a plu : rien.\nCe qui ne nous a pas plu : La réduction drastique de nos remises sur certaines gammes de pompe se qui occasionne des augmentations incompréhensibles pour nous et nos clients communs. A noter également les augmentations liées au coût de l'énergie et à la guerre en Ukraine qui dépassent largement celles de vos principaux concurrents. ( encore 9% en novembre 2022 contre 5.5 % en février 2023 pour votre concurent)\nA titre d'exemple :\n1 pompe NP 3085 MT 460 achetée en 2020 : 1361.77 € HT \n1 pompe NP 3085 MT 460 achetée en 2022 : 2073.79€ HT\nAugmentation de plus de 52.00%, le prix d'achat en 2022 est supérieur notre prix de vente 2020. \nVous comprendrez aisément que ces nouvelles conditions ne sont pas propices à la vente de vos produits, c'est bien dommage.\nPascal ARNAULT - HABERT SAS\n\n\n\n",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arnault.habert@orange.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "dd27c17f-28a4-48b4-a9d5-ea3d55e67241",
            "responseId": "639bfef13c093bd019f83165",
            "receivedResponse": {
                "id": "639bfef13c093bd019f831650.985271735245637",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui nous a plu : rien.\nCe qui ne nous a pas plu : La réduction drastique de nos remises sur certaines gammes de pompe se qui occasionne des augmentations incompréhensibles pour nous et nos clients communs. A noter également les augmentations liées au coût de l'énergie et à la guerre en Ukraine qui dépassent largement celles de vos principaux concurrents. ( encore 9% en novembre 2022 contre 5.5 % en février 2023 pour votre concurrent)\nA titre d'exemple :\n1 pompe NP 3085 MT 460 achetée en 2020 : 1361.77 HT \n1 pompe NP 3085 MT 460 achetée en 2022 : 2073.79 HT\nAugmentation de plus de 52.00%, le prix d'achat en 2022 est supérieur notre prix de vente 2020. \nVous comprendrez aisément que ces nouvelles conditions ne sont pas propices à la vente de vos produits, c'est bien dommage.\nPascal ARNAULT - HABERT SAS\n\n\n\n",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.var.neg",
                                    "label": "Price increase (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "prix.autre",
                "prix.var.neg",
                "choix.pos",
                "efficacite.pos",
                "ressenti.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "prix.var.neg",
                    "label": "Price increase (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.775Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "arnault.habert@orange.fr",
            "CustomerContactEmail": "arnault.habert@orange.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ARNAULT",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "0608247600",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HABERT",
            "CustomerCompanyGroupName": "HABERT S.A.",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Guillaume",
            "XylemContactedPersonName": "BONVALOT",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222088876",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui nous a plu : rien.\nCe qui ne nous a pas plu : La réduction drastique de nos remises sur certaines gammes de pompe se qui occasionne des augmentations incompréhensibles pour nous et nos clients communs. A noter également les augmentations liées au coût de l'énergie et à la guerre en Ukraine qui dépassent largement celles de vos principaux concurrents. ( encore 9% en novembre 2022 contre 5.5 % en février 2023 pour votre concurent)\nA titre d'exemple :\n1 pompe NP 3085 MT 460 achetée en 2020 : 1361.77 € HT \n1 pompe NP 3085 MT 460 achetée en 2022 : 2073.79€ HT\nAugmentation de plus de 52.00%, le prix d'achat en 2022 est supérieur notre prix de vente 2020. \nVous comprendrez aisément que ces nouvelles conditions ne sont pas propices à la vente de vos produits, c'est bien dommage.\nPascal ARNAULT - HABERT SAS\n\n\n\n",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f8317a",
        "extendedResponse": {
            "_id": "63b58bc4f3c2ca4e91762ca5",
            "submittedBody": {
                "id": "639bfef13c093bd019f8317a0.8274338662350347",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "benjamin.venet@ags-developpement.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "781d9766-00c6-4923-8f8e-15bb26b8167a",
            "responseId": "639bfef13c093bd019f8317a",
            "receivedResponse": {
                "id": "639bfef13c093bd019f8317a0.8274338662350347",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.777Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "benjamin.venet@ags-developpement.com",
            "CustomerContactEmail": "benjamin.venet@ags-developpement.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VENET",
            "CustomerContactFirstName": "BENJAMIN",
            "CustomerContactMobilePhone": "0623670915",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AGS DEVELOPPEMENT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088620",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f831b1",
        "extendedResponse": {
            "_id": "63b58bc5f3c2ca4e91762ca6",
            "submittedBody": {
                "id": "639bfef13c093bd019f831b10.14025903306972376",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité et qualité de service.",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-michel.deffaix@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "da5938dc-14c7-4ce0-b87f-14f5ab780902",
            "responseId": "639bfef13c093bd019f831b1",
            "receivedResponse": {
                "id": "639bfef13c093bd019f831b10.14025903306972376",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité et qualité de service.",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.780Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "jean-michel.deffaix@saur.com",
            "CustomerContactEmail": "jean-michel.deffaix@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEFFAIX",
            "CustomerContactFirstName": "Jean Michel",
            "CustomerContactMobilePhone": "0762531623",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR FRANCE",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088704",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité et qualité de service.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f831b4",
        "extendedResponse": {
            "_id": "63b58bc5f3c2ca4e91762ca7",
            "submittedBody": {
                "id": "639bfef13c093bd019f831b40.2890480429737592",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la rapidité de la réponse \nle MOINS la mise en page de vos devis format paysage ",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "luc.marcellin@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "3d6501c4-c907-4b04-a075-4e4c048c47c3",
            "responseId": "639bfef13c093bd019f831b4",
            "receivedResponse": {
                "id": "639bfef13c093bd019f831b40.2890480429737592",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la rapidité de la réponse \nle MOINS la mise en page de vos devis format paysage ",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.780Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "luc.marcellin@sofinther.fr",
            "CustomerContactEmail": "luc.marcellin@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARCELLIN",
            "CustomerContactFirstName": "Luc",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088073",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la rapidité de la réponse \nle MOINS la mise en page de vos devis format paysage ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "voir le  - !"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f83236",
        "extendedResponse": {
            "_id": "63b58bc6f3c2ca4e91762ca8",
            "submittedBody": {
                "id": "639bfef13c093bd019f832360.7749580947940669",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a plu              > Réponse rapide avec documentation , disponibilité et délai \nCe qui m'a moins plu  > Tarif non compétitif , frais de port , commande à faire chez vos partenaire \nrevendeur et non communiqué sur le devis ",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dominique.pothet@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "9c548e9a-3ab5-40fd-9dde-3c409ddd9d3e",
            "responseId": "639bfef13c093bd019f83236",
            "receivedResponse": {
                "id": "639bfef13c093bd019f832360.7749580947940669",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a plu > Réponse rapide avec documentation , disponibilité et délai \nCe qui m'a moins plu > Tarif non compétitif , frais de port , commande à faire chez vos partenaire \nrevendeur et non communiqué sur le devis ",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.816Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "dominique.pothet@dalkia.fr",
            "CustomerContactEmail": "dominique.pothet@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POTHET",
            "CustomerContactFirstName": "Dominique",
            "CustomerContactMobilePhone": "0611697438",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222088877",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a plu              > Réponse rapide avec documentation , disponibilité et délai \nCe qui m'a moins plu  > Tarif non compétitif , frais de port , commande à faire chez vos partenaire \nrevendeur et non communiqué sur le devis ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Comme on passe par vos revendeur tarif équivalent et sans frais de port \nTarif plus compétitif \nNous marquer sur le devis bon de commande à effectuer au revendeur de notre choix "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639bfef13c093bd019f8323a",
        "extendedResponse": {
            "_id": "63b58bc6f3c2ca4e91762ca9",
            "submittedBody": {
                "id": "639bfef13c093bd019f8323a0.9453460563070106",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+ Réactivité \n",
                "creationDate": "2022-12-16T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.boutry@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "acfd3f53-1c8c-4dc3-b9a9-f166237140f1",
            "responseId": "639bfef13c093bd019f8323a",
            "receivedResponse": {
                "id": "639bfef13c093bd019f8323a0.9453460563070106",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+ Réactivité \n",
                "creationDate": "2022-12-16T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-16T05:15:29.816Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-16_06.15.10",
        "integration": {
            "ContactKey": "kevin.boutry@dalkia.fr",
            "CustomerContactEmail": "kevin.boutry@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUTRY",
            "CustomerContactFirstName": "Kevin",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA AGENCE GRAND LILLE",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222088878",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+ Réactivité \n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071057cd73e95ee5c76",
        "extendedResponse": {
            "_id": "63b58bc7f3c2ca4e91762caa",
            "submittedBody": {
                "id": "639d5071057cd73e95ee5c760.23816907806242482",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien à signaler.\nLe rdv s'est bien passé, avec des échanges constructives dans une ambiance collégiale.",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "andrei.rist@alstomgroup.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5bee2755-9668-4497-a7db-1d8f72fd86d5",
            "responseId": "639d5071057cd73e95ee5c76",
            "receivedResponse": {
                "id": "639d5071057cd73e95ee5c760.23816907806242482",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien à signaler.\nLe rendez-vous s'est bien passé, avec des échanges constructives dans une ambiance collégiale.",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.815Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "andrei.rist@alstomgroup.com",
            "CustomerContactEmail": "andrei.rist@alstomgroup.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RIST",
            "CustomerContactFirstName": "Andrei",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COLAS RAIL",
            "CustomerCompanyGroupName": "COLAS RAIL",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "TRAPP",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien à signaler.\nLe rdv s'est bien passé, avec des échanges constructives dans une ambiance collégiale.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071057cd73e95ee5c78",
        "extendedResponse": {
            "_id": "63b58bc7f3c2ca4e91762cab",
            "submittedBody": {
                "id": "639d5071057cd73e95ee5c780.13386105594375586",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Transparence des échanges concernant points forts et points faibles aussi bien pour GED que XYLEM.\nXYLEM met tout en oeuvre pour nous aider mutuellement à augmenter notre business dt la satisfaction de nos clients, nous apprécions la demarche.",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lmazeres@g-e-d.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "83b892ae-0c8d-4082-9097-6d328f162c2c",
            "responseId": "639d5071057cd73e95ee5c78",
            "receivedResponse": {
                "id": "639d5071057cd73e95ee5c780.13386105594375586",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Transparence des échanges concernant points forts et points faibles aussi bien pour GED que XYLEM.\nXYLEM met tout en oeuvre pour nous aider mutuellement à augmenter notre business dt la satisfaction de nos clients, nous apprécions la démarche.",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.815Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "lmazeres@g-e-d.fr",
            "CustomerContactEmail": "lmazeres@g-e-d.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MAZERES",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0673893025",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GROUPE EMILE DUFOUR",
            "CustomerCompanyGroupName": "GED",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "TRAPP",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Transparence des échanges concernant points forts et points faibles aussi bien pour GED que XYLEM.\nXYLEM met tout en oeuvre pour nous aider mutuellement à augmenter notre business dt la satisfaction de nos clients, nous apprécions la demarche.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071561784b8ddc7e75d",
        "extendedResponse": {
            "_id": "63b58bc7f3c2ca4e91762cac",
            "submittedBody": {
                "id": "639d5071561784b8ddc7e75d0.037567268653423636",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai e réponse correct",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jeanfrancois.durfort@guedo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6c8d7c0c-91e9-4a75-9fdd-06f5732f0d54",
            "responseId": "639d5071561784b8ddc7e75d",
            "receivedResponse": {
                "id": "639d5071561784b8ddc7e75d0.037567268653423636",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai e réponse correct",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.850Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "jeanfrancois.durfort@guedo.fr",
            "CustomerContactEmail": "jeanfrancois.durfort@guedo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DURFORT",
            "CustomerContactFirstName": "Jean François",
            "CustomerContactMobilePhone": "0614180124",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GUEDO",
            "CustomerCompanyGroupName": "SARL GUEDO",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222089072",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai e réponse correct",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Prix moins élevé"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071561784b8ddc7e79b",
        "extendedResponse": {
            "_id": "63b58bc8f3c2ca4e91762cad",
            "submittedBody": {
                "id": "639d5071561784b8ddc7e79b0.4846038035254785",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactif",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "anthony.saouzanet@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "388115eb-bad5-4234-a3ab-74b4d22ee481",
            "responseId": "639d5071561784b8ddc7e79b",
            "receivedResponse": {
                "id": "639d5071561784b8ddc7e79b0.4846038035254785",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactif",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.851Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "anthony.saouzanet@suez.com",
            "CustomerContactEmail": "anthony.saouzanet@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SAOUZANET",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0637171165",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ STEP PORTUAIRE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089106",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071561784b8ddc7e87b",
        "extendedResponse": {
            "_id": "63b58bc8f3c2ca4e91762cae",
            "submittedBody": {
                "id": "639d5071561784b8ddc7e87b0.39928902110665687",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité; très bien",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "saet.distribution2@luvica.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3f0d4640-4789-4885-bcdd-88761efa22f8",
            "responseId": "639d5071561784b8ddc7e87b",
            "receivedResponse": {
                "id": "639d5071561784b8ddc7e87b0.39928902110665687",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité; très bien",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.858Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "saet.distribution2@luvica.fr",
            "CustomerContactEmail": "saet.distribution2@luvica.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONNOURY",
            "CustomerContactFirstName": "Denis",
            "CustomerContactMobilePhone": "0970823903",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAET",
            "CustomerCompanyGroupName": "Groupe LUVICA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088919",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité; très bien",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071561784b8ddc7e91e",
        "extendedResponse": {
            "_id": "63b58bc9f3c2ca4e91762caf",
            "submittedBody": {
                "id": "639d5071561784b8ddc7e91e0.8875571457025193",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réceptivité et l'aide technique.",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.longeot@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7edf6627-46c4-4fb4-8871-584f1ba79741",
            "responseId": "639d5071561784b8ddc7e91e",
            "receivedResponse": {
                "id": "639d5071561784b8ddc7e91e0.8875571457025193",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réceptivité et l'aide technique.",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.864Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "olivier.longeot@suez.com",
            "CustomerContactEmail": "olivier.longeot@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LONGEOT",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "0645478104",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ROANNE ASSAINISSEMENT / LYONNAISE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN (L)",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222088956",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réceptivité et l'aide technique.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071561784b8ddc7e98b",
        "extendedResponse": {
            "_id": "63b58bc9f3c2ca4e91762cb0",
            "submittedBody": {
                "id": "639d5071561784b8ddc7e98b0.5623433640953512",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@sklserbat.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a1bfbe6b-897d-4e59-8715-7f6c0f6c0232",
            "responseId": "639d5071561784b8ddc7e98b",
            "receivedResponse": {
                "id": "639d5071561784b8ddc7e98b0.5623433640953512",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.871Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "contact@sklserbat.com",
            "CustomerContactEmail": "contact@sklserbat.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "OURSELIN",
            "CustomerContactFirstName": "ISABELLE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SKL",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089005",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071561784b8ddc7eb4c",
        "extendedResponse": {
            "_id": "63b58bcaf3c2ca4e91762cb1",
            "submittedBody": {
                "id": "639d5071561784b8ddc7eb4c0.2891068639029577",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+Livraison rapide.\n-Facture à l'envoi et non à réception ",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0661302096"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "6d6e0223-10e3-4df1-a3b8-27f5d1dc747e",
            "responseId": "639d5071561784b8ddc7eb4c",
            "receivedResponse": {
                "id": "639d5071561784b8ddc7eb4c0.2891068639029577",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+Livraison rapide.\n-Facture à l'envoi et non à réception ",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.944Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "0661302096",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "DAHERON Wilfried",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0661302096",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000428324",
            "XylemReference": "192912",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+Livraison rapide.\n-Facture à l'envoi et non à réception ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Facture à réception"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "639d5071561784b8ddc7eb5c",
        "extendedResponse": {
            "_id": "63b58bcaf3c2ca4e91762cb2",
            "submittedBody": {
                "id": "639d5071561784b8ddc7eb5c0.4990247163061934",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Qualité de conseil technique et commercial au top. \nPoint négatif : respect des délais affiches; près d'une semaine de retard/ celui donné ( peut être à cause du transporteur....)",
                "creationDate": "2022-12-17T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0681975182"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a7860a7b-8047-4e18-8548-2b8f5f2a57b0",
            "responseId": "639d5071561784b8ddc7eb5c",
            "receivedResponse": {
                "id": "639d5071561784b8ddc7eb5c0.4990247163061934",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Qualité de conseil technique et commercial au top. \nPoint négatif : respect des délais affiches; près d'une semaine de retard/ celui donné ( peut être à cause du transporteur....)",
                "creationDate": "2022-12-17T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.neg",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-17T05:15:29.947Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-17_06.15.09",
        "integration": {
            "ContactKey": "0681975182",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "GRUBO Guy",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0681975182",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CASTETS GRUBO SARL",
            "CustomerCompanyGroupName": "CASTETS GRUBO SARL",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "VANNEREAU Anne",
            "XylemContactedPersonRole": "",
            "CustomerReference": "ACCORD 15/11/22",
            "XylemReference": "188975",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Qualité de conseil technique et commercial au top. \nPoint négatif : respect des délais affiches; près d'une semaine de retard/ celui donné ( peut être à cause du transporteur....)",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Maîtriser le délai..."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61b56",
        "extendedResponse": {
            "_id": "63b58bcbf3c2ca4e91762cb3",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61b560.47837666408306734",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "demande de devis traitée très rapidement. un effort sur le tarif des pompes type DXV... serait le bien venu.",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.fauche@pompes2s.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d03b37af-c732-43ff-8579-2dcf7c609720",
            "responseId": "63a144ef057cd73e95e61b56",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61b560.47837666408306734",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "demande de devis traitée très rapidement. un effort sur le tarif des pompes type DXV... serait le bien venu.",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.671Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "laurent.fauche@pompes2s.com",
            "CustomerContactEmail": "laurent.fauche@pompes2s.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FAUCHE",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0613254489",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "P2S",
            "CustomerCompanyGroupName": "POMPES SOLUTIONS SERVICES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089183",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "demande de devis traitée très rapidement. un effort sur le tarif des pompes type DXV... serait le bien venu.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "nous accompagner sur les tarifs"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61b8e",
        "extendedResponse": {
            "_id": "63b58bcbf3c2ca4e91762cb4",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61b8e0.288822448009737",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité ",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.valat@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c68d762d-60f6-424b-905d-686a397e81ec",
            "responseId": "63a144ef057cd73e95e61b8e",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61b8e0.288822448009737",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité ",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.674Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "sebastien.valat@veolia.com",
            "CustomerContactEmail": "sebastien.valat@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VALAT",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "0620308534",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU MURET",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089455",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61bc6",
        "extendedResponse": {
            "_id": "63b58bccf3c2ca4e91762cb5",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61bc60.2457609239146008",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "reactivité, accompagnement a la determination",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "edossantos@larobinetterie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2a1026ef-df96-464d-9f45-daf213f93306",
            "responseId": "63a144ef057cd73e95e61bc6",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61bc60.2457609239146008",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité, accompagnement a la determination",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.678Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "edossantos@larobinetterie.com",
            "CustomerContactEmail": "edossantos@larobinetterie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DOS SANTOS",
            "CustomerContactFirstName": "CHISTOPHE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LRI ORLEANS",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "François",
            "XylemSalespeopleName": "MARTIN",
            "XylemContactedPersonFirstName": "François",
            "XylemContactedPersonName": "MARTIN",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "222089234",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "reactivité, accompagnement a la determination",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61bce",
        "extendedResponse": {
            "_id": "63b58bccf3c2ca4e91762cb6",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61bce0.8233141479412913",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+ le delai\n-  RAS",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "a.montanes@serclim.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4d1651c7-d0d1-45eb-a6ff-ca8274b261e6",
            "responseId": "63a144ef057cd73e95e61bce",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61bce0.8233141479412913",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+ le délai\n- RAS",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.678Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "a.montanes@serclim.fr",
            "CustomerContactEmail": "a.montanes@serclim.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONTANES",
            "CustomerContactFirstName": "ALBERT",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERCLIM",
            "CustomerCompanyGroupName": "SAS SERCLIM",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089248",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+ le delai\n-  RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61bd6",
        "extendedResponse": {
            "_id": "63b58bcdf3c2ca4e91762cb7",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61bd60.7181593607960775",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité\nRéponse précise",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mlebrun@cabinet-bourgois.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "48907d30-a214-4f44-980e-54834dad4a1e",
            "responseId": "63a144ef057cd73e95e61bd6",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61bd60.7181593607960775",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité\nRéponse précise",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.679Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "mlebrun@cabinet-bourgois.fr",
            "CustomerContactEmail": "mlebrun@cabinet-bourgois.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEBRUN",
            "CustomerContactFirstName": "MAXIME",
            "CustomerContactMobilePhone": "0684415475",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CABINET BOURGOIS",
            "CustomerCompanyGroupName": "CABINET BOURGOIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Fabrice",
            "XylemContactedPersonName": "ROBIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222089258",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité\nRéponse précise",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "4 étoiles c'est déjà très bien :)\nCette dernière question est de trop, elle tend à mettre directement 5 étoiles pour éviter de justifier sa note\nCela biaise le questionnaire"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61be4",
        "extendedResponse": {
            "_id": "63b58bcdf3c2ca4e91762cb8",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61be40.30747522957151596",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "nous étions satisfaits de la qualité et le détail des informations fournies, de la rapidité de réponse et de l'accessibilité et l' ouverture de la communication et le renseignement.",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "natalia.aljanati@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b2ffbd51-968b-401b-b163-fe87fe1b7cb5",
            "responseId": "63a144ef057cd73e95e61be4",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61be40.30747522957151596",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "nous étions satisfaits de la qualité et le détail des informations fournies, de la rapidité de réponse et de l'accessibilité et l' ouverture de la communication et le renseignement.",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.pos",
                "ecoute.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.679Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "natalia.aljanati@eiffage.com",
            "CustomerContactEmail": "natalia.aljanati@eiffage.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "AL JANATI",
            "CustomerContactFirstName": "NATALIA",
            "CustomerContactMobilePhone": "0616365294",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NFEE DLE OUEST",
            "CustomerCompanyGroupName": "DLE OUEST",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Christophe",
            "XylemSalespeopleName": "DOUAY",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089282",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "nous étions satisfaits de la qualité et le détail des informations fournies, de la rapidité de réponse et de l'accessibilité et l' ouverture de la communication et le renseignement.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61c1c",
        "extendedResponse": {
            "_id": "63b58bcef3c2ca4e91762cb9",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61c1c0.008213398726650833",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "reponse rapide et bonne ",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9910d5c7-75c9-49d8-885d-20d6c78bbedf",
            "responseId": "63a144ef057cd73e95e61c1c",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61c1c0.008213398726650833",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide et bonne ",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.683Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089347",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "reponse rapide et bonne ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a144ef057cd73e95e61c7b",
        "extendedResponse": {
            "_id": "63b58bcef3c2ca4e91762cba",
            "submittedBody": {
                "id": "63a144ef057cd73e95e61c7b0.3715453239588684",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J'ai beaucoup apprécié le sérieux, la qualité et la rapidité des réponses apportées à mes demandes mais aussi la patience de mon interlocuteur lors de la finalisation technique de l'offre une fois que de mon côté j'ai l'offre et que je suis sûr que je vais vous passer la commande.\nCe que j'ai moins aimé c'est qu'en phase AO vous répondez approximativement techniquement et sur des délais plus longs. Du coup il est trop tard pour faire des va et vient d'ajustements de l'offre technique et financière et quand on a la commande et que l'on revient vers vous forcément il y a des couacs car vous dites que vous ne l'avez pas chiffré ainsi alors que pourtant au moment de la consultation toutes les données avaient été transmises.\n",
                "creationDate": "2022-12-20T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gabriel.desousa@cegelec.pf"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d5031672-2d2e-46fd-9a0b-d5d63c44e70f",
            "responseId": "63a144ef057cd73e95e61c7b",
            "receivedResponse": {
                "id": "63a144ef057cd73e95e61c7b0.3715453239588684",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J'ai beaucoup apprécié le sérieux, la qualité et la rapidité des réponses apportées à mes demandes mais aussi la patience de mon interlocuteur lors de la finalisation technique de l'offre une fois que de mon côté j'ai l'offre et que je suis sûr que je vais vous passer la commande.\nCe que j'ai moins aimé c'est qu'en phase AO vous répondez approximativement techniquement et sur des délais plus longs. Du coup il est trop tard pour faire des va et vient d'ajustements de l'offre technique et financière et quand on a la commande et que l'on revient vers vous forcément il y a des couacs car vous dites que vous ne l'avez pas chiffré ainsi alors que pourtant au moment de la consultation toutes les données avaient été transmises.\n",
                "creationDate": "2022-12-20T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.neg",
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-20T05:15:27.689Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-20_06.15.09",
        "integration": {
            "ContactKey": "gabriel.desousa@cegelec.pf",
            "CustomerContactEmail": "gabriel.desousa@cegelec.pf",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DE SOUSA",
            "CustomerContactFirstName": "Gabriel",
            "CustomerContactMobilePhone": "+68987770294",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CEGELEC",
            "CustomerCompanyGroupName": "CEGELEC POLYNESIE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Reynald",
            "XylemContactedPersonName": "MEGRET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222083905",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J'ai beaucoup apprécié le sérieux, la qualité et la rapidité des réponses apportées à mes demandes mais aussi la patience de mon interlocuteur lors de la finalisation technique de l'offre une fois que de mon côté j'ai l'offre et que je suis sûr que je vais vous passer la commande.\nCe que j'ai moins aimé c'est qu'en phase AO vous répondez approximativement techniquement et sur des délais plus longs. Du coup il est trop tard pour faire des va et vient d'ajustements de l'offre technique et financière et quand on a la commande et que l'on revient vers vous forcément il y a des couacs car vous dites que vous ne l'avez pas chiffré ainsi alors que pourtant au moment de la consultation toutes les données avaient été transmises.\n",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Faire plus attention à votre offre de départ en lisant bien les éléments transmis pour l'élaboration de votre offre."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a29670875dcc2e53f3b418",
        "extendedResponse": {
            "_id": "63b58bcff3c2ca4e91762cbb",
            "submittedBody": {
                "id": "63a29670875dcc2e53f3b4180.9778838936789074",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.pourchel@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c1742c72-e4a3-4b5c-befc-2c81aeaf1efe",
            "responseId": "63a29670875dcc2e53f3b418",
            "receivedResponse": {
                "id": "63a29670875dcc2e53f3b4180.9778838936789074",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.603Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "xavier.pourchel@veolia.com",
            "CustomerContactEmail": "xavier.pourchel@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POURCHEL",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0603492814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE SAINT OMER (STEP)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089591",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a29670875dcc2e53f3b49c",
        "extendedResponse": {
            "_id": "63b58bcff3c2ca4e91762cbc",
            "submittedBody": {
                "id": "63a29670875dcc2e53f3b49c0.47970770277115293",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne prestation avec des personnes compétentes.",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.vilain@siaap.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "805c352c-d2f0-4033-a0f5-4b462ce34d26",
            "responseId": "63a29670875dcc2e53f3b49c",
            "receivedResponse": {
                "id": "63a29670875dcc2e53f3b49c0.47970770277115293",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne prestation avec des personnes compétentes.",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.609Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "frederic.vilain@siaap.fr",
            "CustomerContactEmail": "frederic.vilain@siaap.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VILAIN",
            "CustomerContactFirstName": "FRÉDÉRIC",
            "CustomerContactMobilePhone": "0770205822",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIAAP-SITE SAV-SEINE AVAL-Achères",
            "CustomerCompanyGroupName": "SIAAP MULTISITES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Tchandra",
            "XylemContactedPersonName": "AMBROISE",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "525159",
            "XylemReference": "2RF066651",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne prestation avec des personnes compétentes.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a29670875dcc2e53f3b4e8",
        "extendedResponse": {
            "_id": "63b58bd0f3c2ca4e91762cbd",
            "submittedBody": {
                "id": "63a29670875dcc2e53f3b4e80.4012229322366736",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "continuez ainsi ",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6149ca7a-5c8b-40de-a936-fb54fff07bab",
            "responseId": "63a29670875dcc2e53f3b4e8",
            "receivedResponse": {
                "id": "63a29670875dcc2e53f3b4e80.4012229322366736",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "continuez ainsi ",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.624Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Cybélia",
            "XylemContactedPersonName": "CAMELOT",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089585",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "continuez ainsi ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a296702a3244f429eed160",
        "extendedResponse": {
            "_id": "63b58bd0f3c2ca4e91762cbe",
            "submittedBody": {
                "id": "63a296702a3244f429eed1600.7983112112388393",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "tout va bien ! ",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.buffiere@arrolimousin.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "774e532d-2996-40eb-a8c5-149669b48a3e",
            "responseId": "63a296702a3244f429eed160",
            "receivedResponse": {
                "id": "63a296702a3244f429eed1600.7983112112388393",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "tout va bien ! ",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.629Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "philippe.buffiere@arrolimousin.com",
            "CustomerContactEmail": "philippe.buffiere@arrolimousin.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BUFFIERE",
            "CustomerContactFirstName": "PHILIPPE",
            "CustomerContactMobilePhone": "0611041344",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ARROLIMOUSIN SARL",
            "CustomerCompanyGroupName": "ARROLIMOUSIN SARL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "RICHARD",
            "XylemContactedPersonRole": "Responsable régional ventes",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "tout va bien ! ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a296702a3244f429eed16e",
        "extendedResponse": {
            "_id": "63b58bd1f3c2ca4e91762cbf",
            "submittedBody": {
                "id": "63a296702a3244f429eed16e0.8960075748764331",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le partage d'expérience et le professionnalisme du commercial",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabrice.saubion@thouars-communaute.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4e04bba1-1129-4063-8e3c-3fa5e2b39b10",
            "responseId": "63a296702a3244f429eed16e",
            "receivedResponse": {
                "id": "63a296702a3244f429eed16e0.8960075748764331",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le partage d'expérience et le professionnalisme du commercial",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.631Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "fabrice.saubion@thouars-communaute.fr",
            "CustomerContactEmail": "fabrice.saubion@thouars-communaute.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SAUBION",
            "CustomerContactFirstName": "Fabrice",
            "CustomerContactMobilePhone": "0608607745",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COMMUNAUTE DE COMMUNES DU THOUARSAIS / STEP THOUET",
            "CustomerCompanyGroupName": "CC DU THOUARSAIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Pierrick",
            "XylemContactedPersonName": "FRAPPAS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le partage d'expérience et le professionnalisme du commercial",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a29670875dcc2e53f3b543",
        "extendedResponse": {
            "_id": "63b58bd1f3c2ca4e91762cc0",
            "submittedBody": {
                "id": "63a29670875dcc2e53f3b5430.8795119263695172",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réponse ",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-louis.dabbadie@belloc-cie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6e7092d4-0d39-4f99-bdd5-615802fa8a7f",
            "responseId": "63a29670875dcc2e53f3b543",
            "receivedResponse": {
                "id": "63a29670875dcc2e53f3b5430.8795119263695172",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réponse ",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.636Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "jean-louis.dabbadie@belloc-cie.fr",
            "CustomerContactEmail": "jean-louis.dabbadie@belloc-cie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DABBADIE",
            "CustomerContactFirstName": "JEAN-LOUIS",
            "CustomerContactMobilePhone": "0602475866",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BELLOC",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089716",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a29670875dcc2e53f3b584",
        "extendedResponse": {
            "_id": "63b58bd2f3c2ca4e91762cc1",
            "submittedBody": {
                "id": "63a29670875dcc2e53f3b5840.827842455200942",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité, réactivité, compétence et relationnel de la commerciale rencontrée.\nAide pour vérifier qu'une pompe était sous-dimensionnée sur site, devis sous 2 heures (super), et un geste commercial au niveau du prix.\nTOP -  continuer",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bertrand.jolivet@engie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "785ce380-7329-4641-9364-91447b69d097",
            "responseId": "63a29670875dcc2e53f3b584",
            "receivedResponse": {
                "id": "63a29670875dcc2e53f3b5840.827842455200942",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité, réactivité, compétence et relationnel de la commerciale rencontrée.\nAide pour vérifier qu'une pompe était sous-dimensionnée sur site, devis sous 2 heures (super), et un geste commercial au niveau du prix.\nTOP - continuer",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "geste.co.all",
                                    "label": "Commercial trade off",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "geste.co.all",
                "accueil.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "geste.co.all",
                    "label": "Commercial trade off",
                    "suffix": "all"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.645Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "bertrand.jolivet@engie.com",
            "CustomerContactEmail": "bertrand.jolivet@engie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JOLIVET",
            "CustomerContactFirstName": "BERTRAND",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ENGIE TSP",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Sarah",
            "XylemContactedPersonName": "NERON",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "222089777",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité, réactivité, compétence et relationnel de la commerciale rencontrée.\nAide pour vérifier qu'une pompe était sous-dimensionnée sur site, devis sous 2 heures (super), et un geste commercial au niveau du prix.\nTOP -  continuer",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a29670875dcc2e53f3b58e",
        "extendedResponse": {
            "_id": "63b58bd2f3c2ca4e91762cc2",
            "submittedBody": {
                "id": "63a29670875dcc2e53f3b58e0.7214101005311482",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse\n",
                "creationDate": "2022-12-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "p.hernandez@c2s.mc"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b333128c-976f-49fe-bfc9-2ac9e9e14a11",
            "responseId": "63a29670875dcc2e53f3b58e",
            "receivedResponse": {
                "id": "63a29670875dcc2e53f3b58e0.7214101005311482",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse\n",
                "creationDate": "2022-12-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-21T05:15:28.646Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-21_06.15.10",
        "integration": {
            "ContactKey": "p.hernandez@c2s.mc",
            "CustomerContactEmail": "p.hernandez@c2s.mc",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HERNANDEZ",
            "CustomerContactFirstName": "Patrick",
            "CustomerContactMobilePhone": "0678636722",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "C2S",
            "CustomerCompanyGroupName": "C2S",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089786",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e539122c3",
        "extendedResponse": {
            "_id": "63b58bd3f3c2ca4e91762cc3",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539122c30.27999224370894216",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité du retour de devis",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.quinton@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0f53d1ae-a061-446e-aa7e-e698b84d1a08",
            "responseId": "63a3e7ed875dcc2e539122c3",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539122c30.27999224370894216",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité du retour de devis",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.375Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "thomas.quinton@saur.com",
            "CustomerContactEmail": "thomas.quinton@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "QUINTON",
            "CustomerContactFirstName": "Thomas",
            "CustomerContactMobilePhone": "0687714546",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR FRANCE",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222089925",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité du retour de devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e539122db",
        "extendedResponse": {
            "_id": "63b58bd3f3c2ca4e91762cc4",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539122db0.6074363461289782",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "m",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "p.charpenel@materiauxtp.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "f06befce-9caa-4c10-92af-fc3ead195b49",
            "responseId": "63a3e7ed875dcc2e539122db",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539122db0.6074363461289782",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "m",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.402Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "p.charpenel@materiauxtp.fr",
            "CustomerContactEmail": "p.charpenel@materiauxtp.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHARPENEL",
            "CustomerContactFirstName": "Pierre",
            "CustomerContactMobilePhone": "0630397823",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MTP SAINT PAUL LES DAX 40",
            "CustomerCompanyGroupName": "MTP",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090048",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "m",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e539122e1",
        "extendedResponse": {
            "_id": "63b58bd4f3c2ca4e91762cc5",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539122e10.04540608436710891",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Échange \nSur ma demande \nSympathique \nEt professionnel",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.lemarchand@materiauxtp.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5a19ca5b-2027-4542-9e45-06c6b5af146f",
            "responseId": "63a3e7ed875dcc2e539122e1",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539122e10.04540608436710891",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Échange \nSur ma demande \nSympathique \nEt professionnel",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.408Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "s.lemarchand@materiauxtp.fr",
            "CustomerContactEmail": "s.lemarchand@materiauxtp.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEMARCHAND",
            "CustomerContactFirstName": "Serge",
            "CustomerContactMobilePhone": "0789220343",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MTP",
            "CustomerCompanyGroupName": "MTP",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089835",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Échange \nSur ma demande \nSympathique \nEt professionnel",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e53912303",
        "extendedResponse": {
            "_id": "63b58bd4f3c2ca4e91762cc6",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539123030.4453365663622624",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de réponse",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.petit@mci.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9f4acca5-7fd5-4064-bf32-d220cfcb635a",
            "responseId": "63a3e7ed875dcc2e53912303",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539123030.4453365663622624",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de réponse",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.446Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "olivier.petit@mci.fr",
            "CustomerContactEmail": "olivier.petit@mci.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PETIT",
            "CustomerContactFirstName": "OLIVIER",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MCI",
            "CustomerCompanyGroupName": "MCI SAS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "EHRENFELD",
            "XylemContactedPersonFirstName": "Cybélia",
            "XylemContactedPersonName": "CAMELOT",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089877",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e53912325",
        "extendedResponse": {
            "_id": "63b58bd4f3c2ca4e91762cc7",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539123250.826327283903509",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour, vos devis me conviennent mais je ne vais pas vous répondre à chaque fois que je reçois une offre",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexis.brotel@cros.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "778b9355-99fa-4388-91b1-e4f048ea7d90",
            "responseId": "63a3e7ed875dcc2e53912325",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539123250.826327283903509",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour, vos devis me conviennent mais je ne vais pas vous répondre à chaque fois que je reçois une offre",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.pos",
                                    "label": "Price (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.pos",
                    "label": "Price (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.482Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "alexis.brotel@cros.com",
            "CustomerContactEmail": "alexis.brotel@cros.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BROTEL",
            "CustomerContactFirstName": "Alexis",
            "CustomerContactMobilePhone": "0611128069",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CROS SAS à Mission",
            "CustomerCompanyGroupName": "CROS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089932",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour, vos devis me conviennent mais je ne vais pas vous répondre à chaque fois que je reçois une offre",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e53912333",
        "extendedResponse": {
            "_id": "63b58bd5f3c2ca4e91762cc8",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539123330.8968100864998754",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "l'échange avec le technicien de xylem pour pouvoir trouver une pompe qui correspond à notre problème vraiment très bien ",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "franck.stumpf@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "03bfa19d-45dd-4d1f-80b2-3cb3e6018635",
            "responseId": "63a3e7ed875dcc2e53912333",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539123330.8968100864998754",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "l'échange avec le technicien de xylem pour pouvoir trouver une pompe qui correspond à notre problème vraiment très bien ",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "abime.neg",
                                    "label": "Damaged product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "abime.neg",
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "abime.neg",
                    "label": "Damaged product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.498Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "franck.stumpf@veolia.com",
            "CustomerContactEmail": "franck.stumpf@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "STUMPF",
            "CustomerContactFirstName": "FRANCK",
            "CustomerContactMobilePhone": "0778240388",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089963",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "l'échange avec le technicien de xylem pour pouvoir trouver une pompe qui correspond à notre problème vraiment très bien ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e53912344",
        "extendedResponse": {
            "_id": "63b58bd5f3c2ca4e91762cc9",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539123440.06975473825172163",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "contact et rapidité de l'offre",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "martial.marlin@spie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6e38ff05-fbfa-4bdd-a0e9-5f87dd5805a2",
            "responseId": "63a3e7ed875dcc2e53912344",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539123440.06975473825172163",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "contact et rapidité de l'offre",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.514Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "martial.marlin@spie.com",
            "CustomerContactEmail": "martial.marlin@spie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARLIN",
            "CustomerContactFirstName": "MARTIAL",
            "CustomerContactMobilePhone": "0643741957",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SPIE EST OPERATION GENIE CLIMATIQUE",
            "CustomerCompanyGroupName": "SPIE INDUSTRIE ET TERTIAIRE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222089271",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "contact et rapidité de l'offre",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e53912398",
        "extendedResponse": {
            "_id": "63b58bd6f3c2ca4e91762cca",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539123980.09968840228155229",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "déjà répondu à ce questionnaire",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pmoreau@sofintec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "bbdaab01-d053-4fb3-8453-8ec87f135f2b",
            "responseId": "63a3e7ed875dcc2e53912398",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539123980.09968840228155229",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "déjà répondu à ce questionnaire",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.553Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "pmoreau@sofintec.fr",
            "CustomerContactEmail": "pmoreau@sofintec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MOREAU",
            "CustomerContactFirstName": "Patrick",
            "CustomerContactMobilePhone": "0675230615",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTEC INDUSTRIES",
            "CustomerCompanyGroupName": "SOFINTEC (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "221019789",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "déjà répondu à ce questionnaire",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e539123d0",
        "extendedResponse": {
            "_id": "63b58bd6f3c2ca4e91762ccb",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539123d00.4223319758960531",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonnes disponibilité et réactivité. ",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lilian.bringuier@hydrolys.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e0e1cf4a-11fb-4713-acf7-27984fce7c24",
            "responseId": "63a3e7ed875dcc2e539123d0",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539123d00.4223319758960531",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonnes disponibilité et réactivité. ",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.572Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "lilian.bringuier@hydrolys.fr",
            "CustomerContactEmail": "lilian.bringuier@hydrolys.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BRINGUIER",
            "CustomerContactFirstName": "Lilian",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROLYS",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "MAURE-PATUREL",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222089982",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonnes disponibilité et réactivité. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed875dcc2e53912423",
        "extendedResponse": {
            "_id": "63b58bd7f3c2ca4e91762ccc",
            "submittedBody": {
                "id": "63a3e7ed875dcc2e539124230.07398216771363075",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "BONJOUR\npoint négatif : aucuns\npoint positif : votre réactivité sur les demandes de prix . Ou sinon impeccable . rien à redire .\ntravailler avec des gens compétents  , cela fait vraiment plaisir .\nMerci à toute votre équipe .\nBonne fêtes .\nBien cordialement\nM.Mirmont johnson\nSBEMV FREJUS\n",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "johnson.mirmont@servicebobinage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "83de8830-9fab-436a-8a87-abf2a2117a1d",
            "responseId": "63a3e7ed875dcc2e53912423",
            "receivedResponse": {
                "id": "63a3e7ed875dcc2e539124230.07398216771363075",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "BONJOUR\npoint négatif : aucuns\npoint positif : votre réactivité sur les demandes de prix . Ou sinon impeccable . rien à redire .\ntravailler avec des gens compétents , cela fait vraiment plaisir .\nMerci à toute votre équipe .\nBonne fêtes .\nBien cordialement\nM.Mirmont johnson\nSBEMV FREJUS\n",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "accueil.pos",
                "efficacite.pos",
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.616Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "johnson.mirmont@servicebobinage.com",
            "CustomerContactEmail": "johnson.mirmont@servicebobinage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MIRMONT",
            "CustomerContactFirstName": "JOHNSON",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERVICE BOBINAGE",
            "CustomerCompanyGroupName": "SCE BOB.ELEC.MECA.VAROIS SBEMV",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090133",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "BONJOUR\npoint négatif : aucuns\npoint positif : votre réactivité sur les demandes de prix . Ou sinon impeccable . rien à redire .\ntravailler avec des gens compétents  , cela fait vraiment plaisir .\nMerci à toute votre équipe .\nBonne fêtes .\nBien cordialement\nM.Mirmont johnson\nSBEMV FREJUS\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a3e7ed7b00034145a13b1a",
        "extendedResponse": {
            "_id": "63b58bd7f3c2ca4e91762ccd",
            "submittedBody": {
                "id": "63a3e7ed7b00034145a13b1a0.5696003358987174",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tout parfait, arriver,retour,  et matériel  en excellent état, merci encore pour vos services.",
                "creationDate": "2022-12-22T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rigsmp11@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0c8ff973-d56b-4632-8ff4-b8daeb314978",
            "responseId": "63a3e7ed7b00034145a13b1a",
            "receivedResponse": {
                "id": "63a3e7ed7b00034145a13b1a0.5696003358987174",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tout parfait, arriver,retour, et matériel en excellent état, merci encore pour vos services.",
                "creationDate": "2022-12-22T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-22T05:15:25.873Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2022-12-22_06.15.08",
        "integration": {
            "ContactKey": "rigsmp11@gmail.com",
            "CustomerContactEmail": "rigsmp11@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LOPEZ",
            "CustomerContactFirstName": "Stephane",
            "CustomerContactMobilePhone": "0777905209",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Société Maintenace Pétrolière (SMP)",
            "CustomerCompanyGroupName": "S.M.P.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Yann",
            "XylemContactedPersonName": "JOSSE",
            "XylemContactedPersonRole": "TCS Location",
            "CustomerReference": "SMP#9-22-11-3344-REF",
            "XylemReference": "754535",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tout parfait, arriver,retour,  et matériel  en excellent état, merci encore pour vos services.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396c875dcc2e53207c00",
        "extendedResponse": {
            "_id": "63b58bd8f3c2ca4e91762cce",
            "submittedBody": {
                "id": "63a5396c875dcc2e53207c000.6946118115219573",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Nous n'avons vu personne, ce n'est pas trop grave a partir du moment ou nous pouvons répondre aux demandes de nos clients avec l'aide de votre support interne !\nMerci\nCdlt",
                "creationDate": "2022-12-23T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nperrenot@alpespompeschauffage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "ff81fc03-c520-4697-82c6-070e788ad944",
            "responseId": "63a5396c875dcc2e53207c00",
            "receivedResponse": {
                "id": "63a5396c875dcc2e53207c000.6946118115219573",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Nous n'avons vu personne, ce n'est pas trop grave a partir du moment ou nous pouvons répondre aux demandes de nos clients avec l'aide de votre support interne !\nMerci\nCdlt",
                "creationDate": "2022-12-23T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:24.858Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "nperrenot@alpespompeschauffage.fr",
            "CustomerContactEmail": "nperrenot@alpespompeschauffage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PERRENOT",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ALPES POMPES CHAUFFAGE",
            "CustomerCompanyGroupName": "ALPES POMPES CHAUFFAGE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Christian",
            "XylemContactedPersonName": "DODDI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Nous n'avons vu personne, ce n'est pas trop grave a partir du moment ou nous pouvons répondre aux demandes de nos clients avec l'aide de votre support interne !\nMerci\nCdlt",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396c875dcc2e53207c21",
        "extendedResponse": {
            "_id": "63b58bd8f3c2ca4e91762ccf",
            "submittedBody": {
                "id": "63a5396c875dcc2e53207c210.31472640511264727",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai non respecté ",
                "creationDate": "2022-12-23T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0638495903"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "102911d3-a26d-4b3d-8375-4e3a5dd3ba99",
            "responseId": "63a5396c875dcc2e53207c21",
            "receivedResponse": {
                "id": "63a5396c875dcc2e53207c210.31472640511264727",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai non respecté ",
                "creationDate": "2022-12-23T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2022-12-23T05:15:24.893Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "0638495903",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Dominique WEBER",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0638495903",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RM HYDRAULIQUE",
            "CustomerCompanyGroupName": "RM HYDRAULIQUE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "12151/DW -REMPLACEMENT SHE4 32-200/05/A - 10741073",
            "XylemReference": "189752",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai non respecté ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396c2a3244f429237ba2",
        "extendedResponse": {
            "_id": "63b58bd9f3c2ca4e91762cd0",
            "submittedBody": {
                "id": "63a5396c2a3244f429237ba20.5333287016468864",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapide ",
                "creationDate": "2022-12-23T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.morel@motralec.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "14f4297b-5c59-478b-9910-4300f77e8585",
            "responseId": "63a5396c2a3244f429237ba2",
            "receivedResponse": {
                "id": "63a5396c2a3244f429237ba20.5333287016468864",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapide ",
                "creationDate": "2022-12-23T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:24.913Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "stephane.morel@motralec.com",
            "CustomerContactEmail": "stephane.morel@motralec.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MOREL",
            "CustomerContactFirstName": "STEPHANE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MOTRALEC DEPARTEMENT RSI 91*",
            "CustomerCompanyGroupName": "MOTRALEC (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090298",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396c2a3244f429237bf0",
        "extendedResponse": {
            "_id": "63b58bd9f3c2ca4e91762cd1",
            "submittedBody": {
                "id": "63a5396c2a3244f429237bf00.21894787255821657",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "délai et livraison ok.",
                "creationDate": "2022-12-23T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-michel.herve@cooperl.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "52643319-18a9-477c-bb8d-e999af25ed56",
            "responseId": "63a5396c2a3244f429237bf0",
            "receivedResponse": {
                "id": "63a5396c2a3244f429237bf00.21894787255821657",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délai et livraison ok.",
                "creationDate": "2022-12-23T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2022-12-23T05:15:24.929Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "jean-michel.herve@cooperl.com",
            "CustomerContactEmail": "jean-michel.herve@cooperl.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HERVE",
            "CustomerContactFirstName": "Jean Michel",
            "CustomerContactMobilePhone": "0689103216",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DENITRAL",
            "CustomerCompanyGroupName": "DÉNITRAL SA",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "222090324",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "délai et livraison ok.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396c2a3244f429237c52",
        "extendedResponse": {
            "_id": "63b58bdaf3c2ca4e91762cd2",
            "submittedBody": {
                "id": "63a5396c2a3244f429237c520.01013394543535795",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide\nDisponibilité erronée ce qui a mis notre client et nous-mêmes dans une situation compliquée. AR de commande le lendemain à midi seulement alors que la commande avait été passée la veille avant 17h.",
                "creationDate": "2022-12-23T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@nicolas-bobinage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "e047d32e-0c28-4a80-b908-c5c6b46af48e",
            "responseId": "63a5396c2a3244f429237c52",
            "receivedResponse": {
                "id": "63a5396c2a3244f429237c520.01013394543535795",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide\nDisponibilité erronée ce qui a mis notre client et nous-mêmes dans une situation compliquée. AR de commande le lendemain à midi seulement alors que la commande avait été passée la veille avant 17h.",
                "creationDate": "2022-12-23T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:24.943Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "commercial@nicolas-bobinage.com",
            "CustomerContactEmail": "commercial@nicolas-bobinage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PAUNON",
            "CustomerContactFirstName": "Yann",
            "CustomerContactMobilePhone": "0607844014",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NICOLAS ELECTRO BOBINAGE",
            "CustomerCompanyGroupName": "NICOLAS ELECTRO BOBINAGE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090363",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide\nDisponibilité erronée ce qui a mis notre client et nous-mêmes dans une situation compliquée. AR de commande le lendemain à midi seulement alors que la commande avait été passée la veille avant 17h.",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"PAUNON Yann\",\n  \"phone\": \"0450961682\",\n  \"mail\": \"commercial@nicolas-bobinage.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237c8c",
        "extendedResponse": {
            "_id": "63b58bdaf3c2ca4e91762cd3",
            "submittedBody": {
                "id": "63a5396d2a3244f429237c8c0.8214651238508865",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponses pour création de devis, ainsi que sur les informations demandées",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-luc.lagarde@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5b8527e4-9151-4e63-80f4-fc90d61fb851",
            "responseId": "63a5396d2a3244f429237c8c",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237c8c0.8214651238508865",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponses pour création de devis, ainsi que sur les informations demandées",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.015Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "jean-luc.lagarde@suez.com",
            "CustomerContactEmail": "jean-luc.lagarde@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAGARDE",
            "CustomerContactFirstName": "Jean Luc",
            "CustomerContactMobilePhone": "0676200316",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090208",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponses pour création de devis, ainsi que sur les informations demandées",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237c8f",
        "extendedResponse": {
            "_id": "63b58bdaf3c2ca4e91762cd4",
            "submittedBody": {
                "id": "63a5396d2a3244f429237c8f0.1461830091429661",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité ",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "g.mounzeo@betek-ingenierie.mc"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "04d8ed5f-5f78-4c36-9a89-156930ef5e47",
            "responseId": "63a5396d2a3244f429237c8f",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237c8f0.1461830091429661",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité ",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.018Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "g.mounzeo@betek-ingenierie.mc",
            "CustomerContactEmail": "g.mounzeo@betek-ingenierie.mc",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MOUNZEO",
            "CustomerContactFirstName": "Gloria",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BETEK",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090214",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237d01",
        "extendedResponse": {
            "_id": "63b58bdbf3c2ca4e91762cd5",
            "submittedBody": {
                "id": "63a5396d2a3244f429237d010.28236195418698773",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "TRES BIEN COMMAE CELA NE RIEN CHANGER ",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pregnier@larobinetterie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "08b71513-9603-479e-a94b-e7e6b6519e66",
            "responseId": "63a5396d2a3244f429237d01",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237d010.28236195418698773",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très BIEN COMMAE CELA NE RIEN CHANGER ",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.113Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "pregnier@larobinetterie.com",
            "CustomerContactEmail": "pregnier@larobinetterie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REGNIER",
            "CustomerContactFirstName": "PHILIPPE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LRI",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090293",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "TRES BIEN COMMAE CELA NE RIEN CHANGER ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237d4a",
        "extendedResponse": {
            "_id": "63b58bdbf3c2ca4e91762cd6",
            "submittedBody": {
                "id": "63a5396d2a3244f429237d4a0.7721809933599477",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Efficace ",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dimitri.lafond@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b724440b-2752-4da5-ab52-3e2f0245767f",
            "responseId": "63a5396d2a3244f429237d4a",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237d4a0.7721809933599477",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Efficace ",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.134Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "dimitri.lafond@veolia.com",
            "CustomerContactEmail": "dimitri.lafond@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAFOND",
            "CustomerContactFirstName": "Dimitri",
            "CustomerContactMobilePhone": "0610330117",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA UDEP DE FLAINE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090334",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Efficace ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237d7e",
        "extendedResponse": {
            "_id": "63b58bdcf3c2ca4e91762cd7",
            "submittedBody": {
                "id": "63a5396d2a3244f429237d7e0.768662605962479",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité et efficacité pour ce qui m'a plu et d'une façon général vous ne mettez jamais le nom sur le colis livré et c'est assez compliqué pour nous à gérer.",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pascal.canovas@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a31dfc4c-909a-4798-ac47-e17f5914bf3d",
            "responseId": "63a5396d2a3244f429237d7e",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237d7e0.768662605962479",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité et efficacité pour ce qui m'a plu et d'une façon général vous ne mettez jamais le nom sur le colis livré et c'est assez compliqué pour nous à gérer.",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.157Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "pascal.canovas@veolia.com",
            "CustomerContactEmail": "pascal.canovas@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CANOVAS",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "0624796515",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090353",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité et efficacité pour ce qui m'a plu et d'une façon général vous ne mettez jamais le nom sur le colis livré et c'est assez compliqué pour nous à gérer.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237d82",
        "extendedResponse": {
            "_id": "63b58bdcf3c2ca4e91762cd8",
            "submittedBody": {
                "id": "63a5396d2a3244f429237d820.9421517776107651",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité sur la demande de proposition technique.\nLe bureau d'études est accessible. Cela permet d'échanger sur les opérations et optimiser nos propositions techniques.\nNous sommes très satisfaits du service.",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "o.valette@cereg.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0fa9d0f8-4f47-490c-821d-cdf96660869d",
            "responseId": "63a5396d2a3244f429237d82",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237d820.9421517776107651",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité sur la demande de proposition technique.\nLe bureau d'études est accessible. Cela permet d'échanger sur les opérations et optimiser nos propositions techniques.\nNous sommes très satisfaits du service.",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.162Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "o.valette@cereg.com",
            "CustomerContactEmail": "o.valette@cereg.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VALETTE",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "0670763556",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CEREG",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090308",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité sur la demande de proposition technique.\nLe bureau d'études est accessible. Cela permet d'échanger sur les opérations et optimiser nos propositions techniques.\nNous sommes très satisfaits du service.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237dff",
        "extendedResponse": {
            "_id": "63b58bddf3c2ca4e91762cd9",
            "submittedBody": {
                "id": "63a5396d2a3244f429237dff0.5898727937423369",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse super rapide , le top",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "joel.bigotte@matal.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "db88e34b-59c2-49c7-b65e-410425ad2361",
            "responseId": "63a5396d2a3244f429237dff",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237dff0.5898727937423369",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse super rapide , le top",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.210Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "joel.bigotte@matal.fr",
            "CustomerContactEmail": "joel.bigotte@matal.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIGOTTE",
            "CustomerContactFirstName": "Joel",
            "CustomerContactMobilePhone": "0618691545",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MATAL",
            "CustomerCompanyGroupName": "MATAL S.A.S",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090399",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse super rapide , le top",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a5396d2a3244f429237e03",
        "extendedResponse": {
            "_id": "63b58bddf3c2ca4e91762cda",
            "submittedBody": {
                "id": "63a5396d2a3244f429237e030.3973317441610731",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai de réponse rapide. Réponse conforme à mes attentes",
                "creationDate": "2022-12-23T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christian.camguilhem@electromontage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "0f669b67-a005-4a22-9817-7053dabf85e8",
            "responseId": "63a5396d2a3244f429237e03",
            "receivedResponse": {
                "id": "63a5396d2a3244f429237e030.3973317441610731",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai de réponse rapide. Réponse conforme à mes attentes",
                "creationDate": "2022-12-23T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-23T05:15:25.212Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-23_06.15.08",
        "integration": {
            "ContactKey": "christian.camguilhem@electromontage.fr",
            "CustomerContactEmail": "christian.camguilhem@electromontage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAMGUILHEM",
            "CustomerContactFirstName": "CHRISTIAN",
            "CustomerContactMobilePhone": "0614186567",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELECTROMONTAGE 47",
            "CustomerCompanyGroupName": "ELECTROMONTAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090405",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai de réponse rapide. Réponse conforme à mes attentes",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63a68aeed459df4b0e6b6876",
        "extendedResponse": {
            "_id": "63b58bdef3c2ca4e91762cdb",
            "submittedBody": {
                "id": "63a68aeed459df4b0e6b68760.44019393507495397",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "devis et livraison au TOP",
                "creationDate": "2022-12-24T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f236fb8d-33ea-43fc-9bbd-6203cbd548a8",
            "responseId": "63a68aeed459df4b0e6b6876",
            "receivedResponse": {
                "id": "63a68aeed459df4b0e6b68760.44019393507495397",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "devis et livraison au TOP",
                "creationDate": "2022-12-24T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2022-12-24T05:15:26.025Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-24_06.15.08",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222090392",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "devis et livraison au TOP",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63aa7f623a2355808eb95875",
        "extendedResponse": {
            "_id": "63b58bdef3c2ca4e91762cdc",
            "submittedBody": {
                "id": "63aa7f623a2355808eb958750.5386309162217442",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide, palette bien emballée ",
                "creationDate": "2022-12-27T06:15:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0658091746"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b79c0d9c-30a3-4e15-99ec-f56e0830e1df",
            "responseId": "63aa7f623a2355808eb95875",
            "receivedResponse": {
                "id": "63aa7f623a2355808eb958750.5386309162217442",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide, palette bien emballée ",
                "creationDate": "2022-12-27T06:15:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-27T05:15:14.386Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-27_06.15.04",
        "integration": {
            "ContactKey": "0658091746",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Gabriel BUSOLIN",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0658091746",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROLYS",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "13608",
            "XylemReference": "194544",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide, palette bien emballée ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63abd0e22a3244f429506721",
        "extendedResponse": {
            "_id": "63b58bdff3c2ca4e91762cdd",
            "submittedBody": {
                "id": "63abd0e22a3244f4295067210.1942021590157068",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je suis content de la commande et de l'envoi mais comme il y a les vacances et qu'il me manque les joints je ne peux pas la faire fonctionner ",
                "creationDate": "2022-12-28T06:15:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0632440152"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d1730cd5-c3a8-468f-921b-fb5cf053ba4b",
            "responseId": "63abd0e22a3244f429506721",
            "receivedResponse": {
                "id": "63abd0e22a3244f4295067210.1942021590157068",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je suis content de la commande et de l'envoi mais comme il y a les vacances et qu'il me manque les joints je ne peux pas la faire fonctionner ",
                "creationDate": "2022-12-28T06:15:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.pos",
                                    "label": "Reachability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "manque.produit.neg",
                "joignabilite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "joignabilite.pos",
                    "label": "Reachability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-28T05:15:14.193Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-28_06.15.04",
        "integration": {
            "ContactKey": "0632440152",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Dominique LAURENT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0632440152",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MOTRALEC",
            "CustomerCompanyGroupName": "MOTRALEC (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF HER042371",
            "XylemReference": "194468",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je suis content de la commande et de l'envoi mais comme il y a les vacances et qu'il me manque les joints je ne peux pas la faire fonctionner ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ad22623a2355808e6f4e3a",
        "extendedResponse": {
            "_id": "63b58bdff3c2ca4e91762cde",
            "submittedBody": {
                "id": "63ad22623a2355808e6f4e3a0.48236951378977033",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse à une demande même pendant les congés",
                "creationDate": "2022-12-29T06:15:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.perez@actemium.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "0c4efdb6-459e-4286-bf7b-68cc1d61d022",
            "responseId": "63ad22623a2355808e6f4e3a",
            "receivedResponse": {
                "id": "63ad22623a2355808e6f4e3a0.48236951378977033",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse à une demande même pendant les congés",
                "creationDate": "2022-12-29T06:15:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-29T05:15:14.028Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2022-12-29_06.15.03",
        "integration": {
            "ContactKey": "laurent.perez@actemium.com",
            "CustomerContactEmail": "laurent.perez@actemium.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PEREZ",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0666305862",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ACTEMIUM",
            "CustomerCompanyGroupName": "FOURNIE GROSPAUD ACTEMIUM",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "BOUZIGON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222090596",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse à une demande même pendant les congés",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ae73e23a2355808e851499",
        "extendedResponse": {
            "_id": "63b58be0f3c2ca4e91762cdf",
            "submittedBody": {
                "id": "63ae73e23a2355808e8514990.7370831922070558",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livreur sympa. A pris le temps de chercher le lieu de livraison. ",
                "creationDate": "2022-12-30T06:15:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0633030943"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "686c22df-593f-4548-a894-247c7b5fc06c",
            "responseId": "63ae73e23a2355808e851499",
            "receivedResponse": {
                "id": "63ae73e23a2355808e8514990.7370831922070558",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livreur sympa. A pris le temps de chercher le lieu de livraison. ",
                "creationDate": "2022-12-30T06:15:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2022-12-30T05:15:14.207Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2022-12-30_06.15.03",
        "integration": {
            "ContactKey": "0633030943",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Pralong",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0633030943",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CDF E0043 2022 059541",
            "XylemReference": "191786",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livreur sympa. A pris le temps de chercher le lieu de livraison. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b3b9e22a3244f4293f0e57",
        "extendedResponse": {
            "_id": "63b58be0f3c2ca4e91762ce0",
            "submittedBody": {
                "id": "63b3b9e22a3244f4293f0e570.01966068277751365",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Dèlai annoncé non respecté (suivant votre AR), impossible de joindre quelqu'un pour avoir une réponse ",
                "creationDate": "2023-01-03T06:15:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0683865201"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "b5165c60-e844-4964-87a4-979ecf6935d5",
            "responseId": "63b3b9e22a3244f4293f0e57",
            "receivedResponse": {
                "id": "63b3b9e22a3244f4293f0e570.01966068277751365",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Dèlai annoncé non respecté (suivant votre AR), impossible de joindre quelqu'un pour avoir une réponse ",
                "creationDate": "2023-01-03T06:15:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.neg",
                                    "label": "Quality of service / Follow-up (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.neg",
                                    "label": "Welcome / Kindness (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "service.neg",
                "joignabilite.neg",
                "accueil.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.neg",
                    "label": "Quality of service / Follow-up (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "accueil.neg",
                    "label": "Welcome / Kindness (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-03T05:15:14.695Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-03_06.15.04",
        "integration": {
            "ContactKey": "0683865201",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "DALLA VALLE Guido",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0683865201",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GDV",
            "CustomerCompanyGroupName": "SARL GDV",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "2212C533",
            "XylemReference": "194405",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Dèlai annoncé non respecté (suivant votre AR), impossible de joindre quelqu'un pour avoir une réponse ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Sarl GDV\",\n  \"phone\": \"0683865201\",\n  \"mail\": \"sarlgdv@orange.fr \"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b3b9e22a3244f4293f0e58",
        "extendedResponse": {
            "_id": "63b58be1f3c2ca4e91762ce1",
            "submittedBody": {
                "id": "63b3b9e22a3244f4293f0e580.6043772860450995",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai OK\nCarton abimé à la réception mais RAS",
                "creationDate": "2023-01-03T06:15:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0683976607"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "7394322c-3af6-4f68-a496-e5ed81895bbc",
            "responseId": "63b3b9e22a3244f4293f0e58",
            "receivedResponse": {
                "id": "63b3b9e22a3244f4293f0e580.6043772860450995",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai OK\nCarton abîmé à la réception mais RAS",
                "creationDate": "2023-01-03T06:15:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "abime.neg",
                                    "label": "Damaged product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "abime.neg",
                "delai.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "abime.neg",
                    "label": "Damaged product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-03T05:15:14.696Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-03_06.15.04",
        "integration": {
            "ContactKey": "0683976607",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. LAURENT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0683976607",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ALTECH",
            "CustomerCompanyGroupName": "ALRE BOBINAGE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 20/12/2022 - Devis 222086997",
            "XylemReference": "194055",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai OK\nCarton abimé à la réception mais RAS",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b3b9e22a3244f4293f0e59",
        "extendedResponse": {
            "_id": "63b58be1f3c2ca4e91762ce2",
            "submittedBody": {
                "id": "63b3b9e22a3244f4293f0e590.8097855229831807",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide. Est-il possible que les factures ne soient envoyées qu'à la réception de la marchandise chez vos clients, et non pas dès le chargement dans vos entrepôts ?",
                "creationDate": "2023-01-03T06:15:14+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0661302189"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "9eb8e44f-28c8-47f2-ab18-74bcb9cebe3f",
            "responseId": "63b3b9e22a3244f4293f0e59",
            "receivedResponse": {
                "id": "63b3b9e22a3244f4293f0e590.8097855229831807",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide. Est-il possible que les factures ne soient envoyées qu'à la réception de la marchandise chez vos clients, et non pas dès le chargement dans vos entrepôts ?",
                "creationDate": "2023-01-03T06:15:14Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-03T05:15:14.698Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-03_06.15.04",
        "integration": {
            "ContactKey": "0661302189",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "RENAUDEAU Jean-Yves",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0661302189",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000434735",
            "XylemReference": "194487",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide. Est-il possible que les factures ne soient envoyées qu'à la réception de la marchandise chez vos clients, et non pas dès le chargement dans vos entrepôts ?",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Décaler l'envoi des factures comme indiqué précédemment.\nCordialement "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6c2a3244f42976c8f6",
        "extendedResponse": {
            "_id": "63b58be2f3c2ca4e91762ce3",
            "submittedBody": {
                "id": "63b50b6c2a3244f42976c8f60.24397580861747037",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Interlocuteur disponible ",
                "creationDate": "2023-01-04T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0622254542"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e8ba2c62-4b92-4a18-9e94-c53026508da0",
            "responseId": "63b50b6c2a3244f42976c8f6",
            "receivedResponse": {
                "id": "63b50b6c2a3244f42976c8f60.24397580861747037",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Interlocuteur disponible ",
                "creationDate": "2023-01-04T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:24.940Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "0622254542",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. David BEZANNIER HUSSON",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0622254542",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (700) LE MANS",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Ana-Sofia",
            "XylemContactedPersonName": "LOURENCO",
            "XylemContactedPersonRole": "CRC",
            "CustomerReference": "344022",
            "XylemReference": "189563",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Interlocuteur disponible ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6cc17713ef6fff5bdf",
        "extendedResponse": {
            "_id": "63b58be2f3c2ca4e91762ce4",
            "submittedBody": {
                "id": "63b50b6cc17713ef6fff5bdf0.022356266393124935",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la rapidité",
                "creationDate": "2023-01-04T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "herve.tourniaire@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "610be4cc-ce1b-4297-80f9-9ce85e9a2c30",
            "responseId": "63b50b6cc17713ef6fff5bdf",
            "receivedResponse": {
                "id": "63b50b6cc17713ef6fff5bdf0.022356266393124935",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la rapidité",
                "creationDate": "2023-01-04T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:24.976Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "herve.tourniaire@saur.com",
            "CustomerContactEmail": "herve.tourniaire@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TOURNIAIRE",
            "CustomerContactFirstName": "HERVE",
            "CustomerContactMobilePhone": "0680488306",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000008",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6cc17713ef6fff5be2",
        "extendedResponse": {
            "_id": "63b58be3f3c2ca4e91762ce5",
            "submittedBody": {
                "id": "63b50b6cc17713ef6fff5be20.7433714296232368",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse et adaptation du matériel ",
                "creationDate": "2023-01-04T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francis.faivre@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "88eac18b-d060-4b31-8911-2382ba23eb18",
            "responseId": "63b50b6cc17713ef6fff5be2",
            "receivedResponse": {
                "id": "63b50b6cc17713ef6fff5be20.7433714296232368",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse et adaptation du matériel ",
                "creationDate": "2023-01-04T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:24.981Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "francis.faivre@veolia.com",
            "CustomerContactEmail": "francis.faivre@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FAIVRE",
            "CustomerContactFirstName": "Francis",
            "CustomerContactMobilePhone": "0778514167",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000023",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse et adaptation du matériel ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff5c2a",
        "extendedResponse": {
            "_id": "63b58be3f3c2ca4e91762ce6",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff5c2a0.7631911600848849",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Moins plu; impossible de vous joindre par tél,le temps de réactivité pour demandes de devis,pour livraison du matériel.....\nA plus; les commerciaux de TOULOUSE qui sont à l'écoute et répondent assez rapidement.\n",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.launay@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "68148e12-2345-4993-90c7-4165c00d8fca",
            "responseId": "63b50b6dc17713ef6fff5c2a",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff5c2a0.7631911600848849",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Moins plu; impossible de vous joindre par tél,le temps de réactivité pour demandes de devis,pour livraison du matériel.....\nA plus; les commerciaux de TOULOUSE qui sont à l'écoute et répondent assez rapidement.\n",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "joignabilite.neg",
                "ecoute.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.068Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "stephane.launay@veolia.com",
            "CustomerContactEmail": "stephane.launay@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAUNAY",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "0621112624",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RABA SARP SUD OUEST",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000217",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Moins plu; impossible de vous joindre par tél,le temps de réactivité pour demandes de devis,pour livraison du matériel.....\nA plus; les commerciaux de TOULOUSE qui sont à l'écoute et répondent assez rapidement.\n",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff5c79",
        "extendedResponse": {
            "_id": "63b58be4f3c2ca4e91762ce7",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff5c790.7083513478197914",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'écoute et la réactivité face à la demande",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "adamians@kiloutou-energie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "04047d96-dc38-4e8b-b532-d85fb674c8c9",
            "responseId": "63b50b6dc17713ef6fff5c79",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff5c790.7083513478197914",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'écoute et la réactivité face à la demande",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.097Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "adamians@kiloutou-energie.fr",
            "CustomerContactEmail": "adamians@kiloutou-energie.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DAMIANS",
            "CustomerContactFirstName": "Audrey",
            "CustomerContactMobilePhone": "0609229379",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KILOUTOU ENERGIES",
            "CustomerCompanyGroupName": "KILOUTOU - COMPTA FOURNISSEURR",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000267",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'écoute et la réactivité face à la demande",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff5d21",
        "extendedResponse": {
            "_id": "63b58be4f3c2ca4e91762ce8",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff5d210.11327367033168301",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité\nUn + à conserver",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.grandidier@tdm-pompes.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b0a4c90c-963a-4ccc-94a6-55b0834963bc",
            "responseId": "63b50b6dc17713ef6fff5d21",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff5d210.11327367033168301",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité\nUn + à conserver",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.167Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "j.grandidier@tdm-pompes.com",
            "CustomerContactEmail": "j.grandidier@tdm-pompes.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GRANDIDIER",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TDM",
            "CustomerCompanyGroupName": "T.D.M.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000458",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité\nUn + à conserver",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff5e68",
        "extendedResponse": {
            "_id": "63b58be5f3c2ca4e91762ce9",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff5e680.5889253911637404",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "LA RAPIDITE DE LA REPONSE",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabrice.viel@ets-lescure.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2a37fdf0-799f-4650-8d4a-aece0f9d8fa4",
            "responseId": "63b50b6dc17713ef6fff5e68",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff5e680.5889253911637404",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "LA rapidité DE LA RÉPONSE",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.334Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "fabrice.viel@ets-lescure.fr",
            "CustomerContactEmail": "fabrice.viel@ets-lescure.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VIEL",
            "CustomerContactFirstName": "Fabrice",
            "CustomerContactMobilePhone": "0617322768",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LESCURE",
            "CustomerCompanyGroupName": "ETS LESCURE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000064",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "LA RAPIDITE DE LA REPONSE",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff5eaa",
        "extendedResponse": {
            "_id": "63b58be5f3c2ca4e91762cea",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff5eaa0.9344336264969069",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\nDevis présent sur E-ACCESS (visible), mais impossible à ouvrir, DOMMAGE.",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "h.lafond@01pompage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "d13d9fca-2c4c-4cae-bf85-e47e240537bd",
            "responseId": "63b50b6dc17713ef6fff5eaa",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff5eaa0.9344336264969069",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\nDevis présent sur E-ACCESS (visible), mais impossible à ouvrir, DOMMAGE.",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.352Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "h.lafond@01pompage.fr",
            "CustomerContactEmail": "h.lafond@01pompage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAFOND",
            "CustomerContactFirstName": "Herve",
            "CustomerContactMobilePhone": "0667438523",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "01 POMPAGE",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223000094",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\nDevis présent sur E-ACCESS (visible), mais impossible à ouvrir, DOMMAGE.",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Un système qui fonctionne afin que l'on valide le devis en commande."
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff5f9b",
        "extendedResponse": {
            "_id": "63b58be6f3c2ca4e91762ceb",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff5f9b0.09846513008577551",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité !",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "saet.distribution2@luvica.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1fb29630-25ce-41e1-8968-d13e175a23e4",
            "responseId": "63b50b6dc17713ef6fff5f9b",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff5f9b0.09846513008577551",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité !",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.443Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "saet.distribution2@luvica.fr",
            "CustomerContactEmail": "saet.distribution2@luvica.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONNOURY",
            "CustomerContactFirstName": "Denis",
            "CustomerContactMobilePhone": "0970823903",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAET",
            "CustomerCompanyGroupName": "Groupe LUVICA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000157",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff60d1",
        "extendedResponse": {
            "_id": "63b58be6f3c2ca4e91762cec",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff60d10.21591445862640435",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "- la rapidité",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "info@corsicabobinage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e4aeb9c5-97bd-42e7-b397-2935d7e793dd",
            "responseId": "63b50b6dc17713ef6fff60d1",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff60d10.21591445862640435",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "- la rapidité",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.578Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "info@corsicabobinage.fr",
            "CustomerContactEmail": "info@corsicabobinage.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MORDICONI",
            "CustomerContactFirstName": "Joëlle",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CORSICA BOBINAGE",
            "CustomerCompanyGroupName": "CORSICA BOBINAGE  SARL",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223000238",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "- la rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff60fe",
        "extendedResponse": {
            "_id": "63b58be7f3c2ca4e91762ced",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff60fe0.34392708096246305",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.wattrelot@thermap.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6075c157-61d0-40be-9ba5-a9b52613fdba",
            "responseId": "63b50b6dc17713ef6fff60fe",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff60fe0.34392708096246305",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.591Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "emmanuel.wattrelot@thermap.fr",
            "CustomerContactEmail": "emmanuel.wattrelot@thermap.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "WATTRELOT",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0757439229",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "THERMAP",
            "CustomerCompanyGroupName": "THERMAP",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Remy",
            "XylemContactedPersonName": "BERNETIERE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223000247",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff617a",
        "extendedResponse": {
            "_id": "63b58be7f3c2ca4e91762cee",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff617a0.4501685036588645",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délais de réponse, délais de livraison prix compétitif",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ymace@larobinetterie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "14cd5be7-cedf-490a-8be2-1956adf409b1",
            "responseId": "63b50b6dc17713ef6fff617a",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff617a0.4501685036588645",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délais de réponse, délais de livraison prix compétitif",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.pos",
                                    "label": "Price (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.pos",
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.pos",
                    "label": "Price (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.681Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "ymace@larobinetterie.com",
            "CustomerContactEmail": "ymace@larobinetterie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MACE",
            "CustomerContactFirstName": "Yves",
            "CustomerContactMobilePhone": "0658462924",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE - LRI SODIME",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000335",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délais de réponse, délais de livraison prix compétitif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff61a8",
        "extendedResponse": {
            "_id": "63b58be8f3c2ca4e91762cef",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff61a80.12276449256898458",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité ",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@thieulin.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ac16f78d-e33c-49f5-b41c-c31f16b317f2",
            "responseId": "63b50b6dc17713ef6fff61a8",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff61a80.12276449256898458",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité ",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.744Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "commercial@thieulin.com",
            "CustomerContactEmail": "commercial@thieulin.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FESQUET",
            "CustomerContactFirstName": "Hervé",
            "CustomerContactMobilePhone": "0688867016",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "THIEULIN",
            "CustomerCompanyGroupName": "STE THIEULIN",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000326",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff61bc",
        "extendedResponse": {
            "_id": "63b58be8f3c2ca4e91762cf0",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff61bc0.6148253610413661",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse.",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "atelier@thieulin.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3a788acc-22ae-49a8-9984-ca67eda09f2c",
            "responseId": "63b50b6dc17713ef6fff61bc",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff61bc0.6148253610413661",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse.",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.776Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "atelier@thieulin.com",
            "CustomerContactEmail": "atelier@thieulin.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "NICOD",
            "CustomerContactFirstName": "Mickael",
            "CustomerContactMobilePhone": "0631590365",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "THIEULIN",
            "CustomerCompanyGroupName": "STE THIEULIN",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000432",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff61bf",
        "extendedResponse": {
            "_id": "63b58be8f3c2ca4e91762cf1",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff61bf0.6717993216352978",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité, efficacité du délais de réponse.",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "clement.garrigos@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "81a186f9-dc44-4fcd-b8b5-c3e5c2fe23bb",
            "responseId": "63b50b6dc17713ef6fff61bf",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff61bf0.6717993216352978",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité, efficacité du délais de réponse.",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.779Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "clement.garrigos@veolia.com",
            "CustomerContactEmail": "clement.garrigos@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GARRIGOS",
            "CustomerContactFirstName": "Clément",
            "CustomerContactMobilePhone": "0623138457",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOCIETE DES EAUX DE LA METROPOLE NIMOISE (S.E.M.N)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000440",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité, efficacité du délais de réponse.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff65d7",
        "extendedResponse": {
            "_id": "63b58be9f3c2ca4e91762cf2",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff65d70.77356290099504",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour, la réactivité par rapport à mes demandes",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.rodrigues@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "fc10871b-62ac-49b0-b87e-bda157c0f255",
            "responseId": "63b50b6dc17713ef6fff65d7",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff65d70.77356290099504",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour, la réactivité par rapport à mes demandes",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.859Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "christophe.rodrigues@saur.com",
            "CustomerContactEmail": "christophe.rodrigues@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RODRIGUES",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0668037832",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR - AGGLOPOLE PROVENCE ASSAINISSEMENT",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000523",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour, la réactivité par rapport à mes demandes",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Prendre contact avec moi un peu plus souvent pour présenter vos nouvelles technologies"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff6602",
        "extendedResponse": {
            "_id": "63bc06ab3f490710f016591d",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff66020.02652640509931148",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bonne équipe commerciale.\ndélais Concertor un peu long",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "samuel.vanhaecke@seihe.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c8bdf139-47e9-4e22-9e4d-93b2cad4cece",
            "responseId": "63b50b6dc17713ef6fff6602",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff66020.02652640509931148",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bonne équipe commerciale.\ndélais Concertor un peu long",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.861Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "samuel.vanhaecke@seihe.fr",
            "CustomerContactEmail": "samuel.vanhaecke@seihe.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANHAECKE",
            "CustomerContactFirstName": "SAMUEL",
            "CustomerContactMobilePhone": "0757006878",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEIHE 32",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000525",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bonne équipe commerciale.\ndélais Concertor un peu long",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b50b6dc17713ef6fff663f",
        "extendedResponse": {
            "_id": "63b58be9f3c2ca4e91762cf3",
            "submittedBody": {
                "id": "63b50b6dc17713ef6fff663f0.06499105566816965",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité des réponses ",
                "creationDate": "2023-01-04T06:15:25+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mickael.ferrand@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "27151985-adb9-4d98-a217-1a5b9e3d43e0",
            "responseId": "63b50b6dc17713ef6fff663f",
            "receivedResponse": {
                "id": "63b50b6dc17713ef6fff663f0.06499105566816965",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité des réponses ",
                "creationDate": "2023-01-04T06:15:25Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-04T05:15:25.876Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-04_06.15.07",
        "integration": {
            "ContactKey": "mickael.ferrand@veolia.com",
            "CustomerContactEmail": "mickael.ferrand@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FERRAND",
            "CustomerContactFirstName": "Mickael",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Rémi",
            "XylemContactedPersonName": "BOUQUIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223000538",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité des réponses ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cefa304105fae04b72e",
        "extendedResponse": {
            "_id": "63bc06ac3f490710f016591e",
            "submittedBody": {
                "id": "63b65cefa304105fae04b72e0.28985301900085747",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon contact \nA l'écoute de nos besoins\nUn catalogue complet et correspondant à nos besoins\nDes prix compétitifs et des délais corrects",
                "creationDate": "2023-01-05T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c.foulon@delamotte.pro"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6aade590-23f4-4d58-9c3b-41b60ef35e27",
            "responseId": "63b65cefa304105fae04b72e",
            "receivedResponse": {
                "id": "63b65cefa304105fae04b72e0.28985301900085747",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon contact \nA l'écoute de nos besoins\nUn catalogue complet et correspondant à nos besoins\nDes prix compétitifs et des délais corrects",
                "creationDate": "2023-01-05T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.pos",
                                    "label": "Price (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.pos",
                "fiabilite.pos",
                "delai.pos",
                "accueil.pos",
                "ecoute.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.pos",
                    "label": "Price (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:27.815Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "c.foulon@delamotte.pro",
            "CustomerContactEmail": "c.foulon@delamotte.pro",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "FOULON",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DELAMOTTE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Clement",
            "XylemContactedPersonName": "FAIVRE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon contact \nA l'écoute de nos besoins\nUn catalogue complet et correspondant à nos besoins\nDes prix compétitifs et des délais corrects",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Avoir des prix encore plus compétitifs\nPouvoir travailler en direct sans passer par la distribution"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cefa304105fae04b741",
        "extendedResponse": {
            "_id": "63bc06ac3f490710f016591f",
            "submittedBody": {
                "id": "63b65cefa304105fae04b7410.12138883206865536",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très réactif et à l’écoute des besoins.\nGrand merci à lui",
                "creationDate": "2023-01-05T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "expertise.sateb@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9d5cc4e0-fd3f-44a6-9c3e-3096c2de3f8f",
            "responseId": "63b65cefa304105fae04b741",
            "receivedResponse": {
                "id": "63b65cefa304105fae04b7410.12138883206865536",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très réactif et à l écoute des besoins.\nGrand merci à lui",
                "creationDate": "2023-01-05T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:27.823Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "expertise.sateb@gmail.com",
            "CustomerContactEmail": "expertise.sateb@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ALLIMONIER",
            "CustomerContactFirstName": "DANNY",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SATEB",
            "CustomerCompanyGroupName": "S.A.T.E.B.",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Clement",
            "XylemContactedPersonName": "FAIVRE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très réactif et à l’écoute des besoins.\nGrand merci à lui",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cefa304105fae04b749",
        "extendedResponse": {
            "_id": "63bc06ac3f490710f0165920",
            "submittedBody": {
                "id": "63b65cefa304105fae04b7490.5641802276053272",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bien ",
                "creationDate": "2023-01-05T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romuald.lepy@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b478f411-0cc3-4937-b623-73de38a0a1f0",
            "responseId": "63b65cefa304105fae04b749",
            "receivedResponse": {
                "id": "63b65cefa304105fae04b7490.5641802276053272",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bien ",
                "creationDate": "2023-01-05T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-05T05:15:27.830Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "romuald.lepy@saur.com",
            "CustomerContactEmail": "romuald.lepy@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEPY",
            "CustomerContactFirstName": "Romuald",
            "CustomerContactMobilePhone": "0659808802",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Pierrick",
            "XylemContactedPersonName": "FRAPPAS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bien ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716b8d",
        "extendedResponse": {
            "_id": "63bc06ad3f490710f0165921",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716b8d0.820516391395927",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDITE ET PROFESSIONALISME",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "assainissement@4cvs.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3952b212-771b-463c-8c0a-87acac361095",
            "responseId": "63b65cf08f2415d78b716b8d",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716b8d0.820516391395927",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité ET professionnalisme",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.237Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "assainissement@4cvs.fr",
            "CustomerContactEmail": "assainissement@4cvs.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BARTHELEMY",
            "CustomerContactFirstName": "Stephane",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Com Com Cotes de Champagne et Val de Saulx 4CVS",
            "CustomerCompanyGroupName": "COMMUNAUTE DE COMMUNES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000669",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDITE ET PROFESSIONALISME",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf0a304105fae04c43a",
        "extendedResponse": {
            "_id": "63bc06ad3f490710f0165922",
            "submittedBody": {
                "id": "63b65cf0a304105fae04c43a0.7340809076847119",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Arrêter vos envois d enquête\nMerci ",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0608216428"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "4b5e5a72-a934-4ed6-878b-6477392197c7",
            "responseId": "63b65cf0a304105fae04c43a",
            "receivedResponse": {
                "id": "63b65cf0a304105fae04c43a0.7340809076847119",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Arrêter vos envois d enquête\nMerci ",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.269Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "0608216428",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "David HAMARD",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0608216428",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR (TVX NEUFS)",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000435241",
            "XylemReference": "194532",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Arrêter vos envois d enquête\nMerci ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716bb0",
        "extendedResponse": {
            "_id": "63bc06ae3f490710f0165923",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716bb00.14469782119564223",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour , rapidité dans les retours de demande d offre , au top !!!!",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "elemaire@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c251f743-c458-4aa7-b5c0-2b742b4d343b",
            "responseId": "63b65cf08f2415d78b716bb0",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716bb00.14469782119564223",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour , rapidité dans les retours de demande d offre , au top !!!!",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.294Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "elemaire@gressier.net",
            "CustomerContactEmail": "elemaire@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEMAIRE",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0666979422",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER ET FILS",
            "CustomerCompanyGroupName": "ETS GRESSIER ET FILS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000842",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour , rapidité dans les retours de demande d offre , au top !!!!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716bc4",
        "extendedResponse": {
            "_id": "63bc06ae3f490710f0165924",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716bc40.2954544822077574",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de réponse, réactivité",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rodolphe.camus@orexad.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "569a5257-4c63-4ed2-9feb-d31bbd536647",
            "responseId": "63b65cf08f2415d78b716bc4",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716bc40.2954544822077574",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de réponse, réactivité",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.331Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "rodolphe.camus@orexad.com",
            "CustomerContactEmail": "rodolphe.camus@orexad.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAMUS",
            "CustomerContactFirstName": "Rodolphe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OREXAD NANTES",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000906",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de réponse, réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716c09",
        "extendedResponse": {
            "_id": "63bc06ae3f490710f0165925",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716c090.7560879807692882",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "chiffrage très bon et correct - la dispo et livraison aussi très bien",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d0577bbf-a602-456c-9b3f-5111f2c6e02f",
            "responseId": "63b65cf08f2415d78b716c09",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716c090.7560879807692882",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "chiffrage très bon et correct - la dispo et livraison aussi très bien",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.445Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000555",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "chiffrage très bon et correct - la dispo et livraison aussi très bien",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716c21",
        "extendedResponse": {
            "_id": "63bc06af3f490710f0165926",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716c210.8090329492416781",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse et qualité du contact technique et commercial",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jmsemonsu@nge.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c7b9ecab-31a4-447a-b155-ab975a8fe9e2",
            "responseId": "63b65cf08f2415d78b716c21",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716c210.8090329492416781",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse et qualité du contact technique et commercial",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.482Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "jmsemonsu@nge.fr",
            "CustomerContactEmail": "jmsemonsu@nge.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SEMONSU",
            "CustomerContactFirstName": "Jean-Maichel",
            "CustomerContactMobilePhone": "0622495681",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GUINTOLI - EHTP",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "David",
            "XylemContactedPersonName": "FOUNAU JACQUARD",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223000653",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse et qualité du contact technique et commercial",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716c23",
        "extendedResponse": {
            "_id": "63bc06af3f490710f0165927",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716c230.21463909390265346",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour\nMerci pour la rapidité de réponse, et la clarté du devis\nBelle journée",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "audrey.romestin@santerne.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d75a7d2f-72c4-44c1-88f1-a418eb8f16e7",
            "responseId": "63b65cf08f2415d78b716c23",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716c230.21463909390265346",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour\nMerci pour la rapidité de réponse, et la clarté du devis\nBelle journée",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.486Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "audrey.romestin@santerne.fr",
            "CustomerContactEmail": "audrey.romestin@santerne.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROMESTIN",
            "CustomerContactFirstName": "Audrey",
            "CustomerContactMobilePhone": "0629744317",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SANTERNE FLUIDES",
            "CustomerCompanyGroupName": "SANTERNE FLUIDES",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000654",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour\nMerci pour la rapidité de réponse, et la clarté du devis\nBelle journée",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716c63",
        "extendedResponse": {
            "_id": "63bc06b03f490710f0165928",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716c630.3601343212064967",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide ",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.joonnekindt@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "305895b7-2cc2-4735-9602-811999679667",
            "responseId": "63b65cf08f2415d78b716c63",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716c630.3601343212064967",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide ",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.584Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "olivier.joonnekindt@dalkia.fr",
            "CustomerContactEmail": "olivier.joonnekindt@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JOONNEKINDT",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "0664552789",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA Région CENTRE EST",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Gaetan",
            "XylemContactedPersonName": "BRETEAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000742",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716c6e",
        "extendedResponse": {
            "_id": "63bc06b03f490710f0165929",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716c6e0.03910116886601522",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de la prise en compte de notre demande et dans la réception de l'offre de prix.",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ct1@eurotec-dev.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e4387a68-b9c3-41c7-8c07-00e8a8756ee3",
            "responseId": "63b65cf08f2415d78b716c6e",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716c6e0.03910116886601522",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de la prise en compte de notre demande et dans la réception de l'offre de prix.",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.600Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "ct1@eurotec-dev.fr",
            "CustomerContactEmail": "ct1@eurotec-dev.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0676768464",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EUROTEC DEVELOPPEMENT",
            "CustomerCompanyGroupName": "EUROTEC DEVELOPPEMENT",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000707",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de la prise en compte de notre demande et dans la réception de l'offre de prix.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716c72",
        "extendedResponse": {
            "_id": "63bc06b13f490710f016592a",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716c720.31560683575114434",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ecoute du besoin et discussion pour trouver la meilleure solution. +++",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hilaire.lecornec@roger-marteau.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d13b493c-034c-4de8-9a04-cd5dd259780a",
            "responseId": "63b65cf08f2415d78b716c72",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716c720.31560683575114434",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ecoute du besoin et discussion pour trouver la meilleure solution. +++",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.607Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactEmail": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE CORNEC",
            "CustomerContactFirstName": "Hilaire",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MARTEAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000766",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ecoute du besoin et discussion pour trouver la meilleure solution. +++",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716cd5",
        "extendedResponse": {
            "_id": "63bc06b13f490710f016592b",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716cd50.8423491182857719",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Toutes les infos nécessaires ont été reprises ssur le devis",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bricout.christelle@dimp-nordpompes.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ff545989-ac49-4a0c-b8c3-55ee25d85d24",
            "responseId": "63b65cf08f2415d78b716cd5",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716cd50.8423491182857719",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Toutes les informations nécessaires ont été reprises sur le devis",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.753Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "bricout.christelle@dimp-nordpompes.fr",
            "CustomerContactEmail": "bricout.christelle@dimp-nordpompes.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "BRICOUT",
            "CustomerContactFirstName": "Christelle",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DIMP - NORD POMPES",
            "CustomerCompanyGroupName": "DIMP - NORD POMPES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Daniel",
            "XylemContactedPersonName": "RICHEZ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223000920",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Toutes les infos nécessaires ont été reprises ssur le devis",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716cee",
        "extendedResponse": {
            "_id": "63bc06b13f490710f016592c",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716cee0.4045559023490406",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide\nFaire figurer le prix public\nDélai reelle.",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jfm@etsmaria.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "53551fb1-4195-4df9-8d54-a12d466672f6",
            "responseId": "63b65cf08f2415d78b716cee",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716cee0.4045559023490406",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide\nFaire figurer le prix public\nDélai réelle.",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.786Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "jfm@etsmaria.fr",
            "CustomerContactEmail": "jfm@etsmaria.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARTINEZ",
            "CustomerContactFirstName": "Jean-François",
            "CustomerContactMobilePhone": "0628826867",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MARIA ETS",
            "CustomerCompanyGroupName": "MARIA ETS SAS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Franck",
            "XylemContactedPersonName": "PENVERN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223000963",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide\nFaire figurer le prix public\nDélai reelle.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "délais précis"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716d09",
        "extendedResponse": {
            "_id": "63bc06b23f490710f016592d",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716d090.6563133113972335",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapide et ecoute",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hlacan@innov-elevage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a7c3ec2f-0f48-4163-88b3-53653176473b",
            "responseId": "63b65cf08f2415d78b716d09",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716d090.6563133113972335",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapide et écoute",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.822Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "hlacan@innov-elevage.fr",
            "CustomerContactEmail": "hlacan@innov-elevage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACAN",
            "CustomerContactFirstName": "HERVE",
            "CustomerContactMobilePhone": "0632631162",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DAFFINI SARL",
            "CustomerCompanyGroupName": "DAFFINI SARL (INNOV'ELEVAGE)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000994",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapide et ecoute",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b65cf08f2415d78b716d0f",
        "extendedResponse": {
            "_id": "63bc06b23f490710f016592e",
            "submittedBody": {
                "id": "63b65cf08f2415d78b716d0f0.25846249505071506",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Super reactif",
                "creationDate": "2023-01-05T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ejouanneau@sipeleec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "78f4a0de-3716-4c34-b203-2686a00af936",
            "responseId": "63b65cf08f2415d78b716d0f",
            "receivedResponse": {
                "id": "63b65cf08f2415d78b716d0f0.25846249505071506",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Super réactif",
                "creationDate": "2023-01-05T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-05T05:15:28.832Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-05_06.15.11",
        "integration": {
            "ContactKey": "ejouanneau@sipeleec.fr",
            "CustomerContactEmail": "ejouanneau@sipeleec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JOUANNEAU",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0681147340",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIPELEEC",
            "CustomerCompanyGroupName": "SARL SIPELEEC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001001",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Super reactif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3c83c",
        "extendedResponse": {
            "_id": "63bc06b33f490710f016592f",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3c83c0.010132182722031535",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Professionnalisme réactivité ",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eauplusservices@aol.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2e4255f5-7281-4d10-8108-21b4a3dbcbbc",
            "responseId": "63b7ae718f2415d78bd3c83c",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3c83c0.010132182722031535",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Professionnalisme réactivité ",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.009Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "eauplusservices@aol.com",
            "CustomerContactEmail": "eauplusservices@aol.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAILLAT",
            "CustomerContactFirstName": "Romain",
            "CustomerContactMobilePhone": "0619930041",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Eau Plus Services",
            "CustomerCompanyGroupName": "EAU + SERVICES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001128",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Professionnalisme réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3c85c",
        "extendedResponse": {
            "_id": "63bc06b33f490710f0165930",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3c85c0.32401598349686855",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Delai de livraison respecté",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "didier.yvon@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "890f5795-5c64-49dc-bff2-93e32407a17c",
            "responseId": "63b7ae718f2415d78bd3c85c",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3c85c0.32401598349686855",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délai de livraison respecté",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.064Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "didier.yvon@saur.com",
            "CustomerContactEmail": "didier.yvon@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "YVON",
            "CustomerContactFirstName": "Didier",
            "CustomerContactMobilePhone": "0675498464",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR CENTRE DE LUDRES",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001246",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Delai de livraison respecté",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3c870",
        "extendedResponse": {
            "_id": "63bc06b33f490710f0165931",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3c8700.995334468150123",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "A plu : Réponse très  rapide \nMoins plu : réponse générique je suppose mais...  les hausses de tarifs ",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pascal.hollocou@fcvsarl.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "e2f3a0e8-4161-4b92-bf5e-2d14aa636c5a",
            "responseId": "63b7ae718f2415d78bd3c870",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3c8700.995334468150123",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "A plu : Réponse très rapide \nMoins plu : réponse générique je suppose mais... les hausses de tarifs ",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.var.neg",
                                    "label": "Price increase (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "prix.var.neg",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "prix.var.neg",
                    "label": "Price increase (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.100Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "pascal.hollocou@fcvsarl.fr",
            "CustomerContactEmail": "pascal.hollocou@fcvsarl.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HOLLOCOU",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "0607962093",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FCV SARL",
            "CustomerCompanyGroupName": "SARL F.C.V",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Maryse",
            "XylemSalespeopleName": "VANOVERBERGHE",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001359",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "A plu : Réponse très  rapide \nMoins plu : réponse générique je suppose mais...  les hausses de tarifs ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Modéré vos hausses de prix.. ou peut être pratiquer des \"baisses\" de prix quand ce sera possible... "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3c895",
        "extendedResponse": {
            "_id": "63bc06b43f490710f0165932",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3c8950.7830067148024509",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse à ma demande",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jeanmichel.leveque@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "5ac47fb3-222a-4d70-8a12-64c665207ddc",
            "responseId": "63b7ae718f2415d78bd3c895",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3c8950.7830067148024509",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse à ma demande",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.161Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "jeanmichel.leveque@sofinther.fr",
            "CustomerContactEmail": "jeanmichel.leveque@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEVEQUE",
            "CustomerContactFirstName": "JEAN MICHEL",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Christian",
            "XylemContactedPersonName": "DODDI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222072955",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse à ma demande",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3c9f7",
        "extendedResponse": {
            "_id": "63bc06b43f490710f0165933",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3c9f70.8881687921857613",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Comme d'habitude, efficacité et rapidité de votre équipe de Noyal sur Vilaine à nous faire parvenir une offre.",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ct1@eurotec-dev.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0dced6ef-0d35-4a9d-bde8-7878ff128f22",
            "responseId": "63b7ae718f2415d78bd3c9f7",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3c9f70.8881687921857613",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Comme d'habitude, efficacité et rapidité de votre équipe de Noyal sur Vilaine à nous faire parvenir une offre.",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.175Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "ct1@eurotec-dev.fr",
            "CustomerContactEmail": "ct1@eurotec-dev.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0676768464",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EUROTEC DEVELOPPEMENT",
            "CustomerCompanyGroupName": "EUROTEC DEVELOPPEMENT",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001041",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Comme d'habitude, efficacité et rapidité de votre équipe de Noyal sur Vilaine à nous faire parvenir une offre.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3ca04",
        "extendedResponse": {
            "_id": "63bc06b53f490710f0165934",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3ca040.8943232073769374",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour\nAvantage : Pièces détachées en stock\nTarif : à revoir",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bobinagedutriangle@hotmail.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "3b3bb029-aeb8-4522-8c3f-87079c6c6687",
            "responseId": "63b7ae718f2415d78bd3ca04",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3ca040.8943232073769374",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour\nAvantage : Pièces détachées en stock\nTarif : à revoir",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "dispo.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.197Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "bobinagedutriangle@hotmail.fr",
            "CustomerContactEmail": "bobinagedutriangle@hotmail.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "WOLFF",
            "CustomerContactFirstName": "Sylvain",
            "CustomerContactMobilePhone": "0603603647",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BOBINAGE DU TRIANGLE",
            "CustomerCompanyGroupName": "BOBINAGE DU TRIANGLE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Corinne",
            "XylemContactedPersonName": "LAQUAINE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223001053",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour\nAvantage : Pièces détachées en stock\nTarif : à revoir",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Parfois les réponse à la demande de devis un peu long\n\n\n\nLa saisie de commande 48 h !\nMerci à Mme Laquaine très réactive\n\nCordialement, Mr Sylvain Wolff "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3ca09",
        "extendedResponse": {
            "_id": "63bc06b53f490710f0165935",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3ca090.3081709487761606",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité\nClarté du devis",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "michel.frerejean@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4512b656-6ee9-4afc-b209-ab62313e391a",
            "responseId": "63b7ae718f2415d78bd3ca09",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3ca090.3081709487761606",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité\nClarté du devis",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.208Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "michel.frerejean@veolia.com",
            "CustomerContactEmail": "michel.frerejean@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FREREJEAN",
            "CustomerContactFirstName": "Michel",
            "CustomerContactMobilePhone": "0646647321",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Roberto",
            "XylemContactedPersonName": "FERNANDES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001019",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité\nClarté du devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d297",
        "extendedResponse": {
            "_id": "63bc06b63f490710f0165936",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d2970.6975356454639732",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité +++\n",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hilaire.lecornec@roger-marteau.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1932bae2-53f8-449f-a246-7af9700fa589",
            "responseId": "63b7ae718f2415d78bd3d297",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d2970.6975356454639732",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité +++\n",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.272Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactEmail": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE CORNEC",
            "CustomerContactFirstName": "Hilaire",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MARTEAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001118",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité +++\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae71a304105fae569155",
        "extendedResponse": {
            "_id": "63bc06b63f490710f0165937",
            "submittedBody": {
                "id": "63b7ae71a304105fae5691550.5474852846572866",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Appel 10min avant la livraison ++\nPas possible de voir le suivi du colis -",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0671222063"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "81631df8-aa2f-408f-971e-7fb0705385d2",
            "responseId": "63b7ae71a304105fae569155",
            "receivedResponse": {
                "id": "63b7ae71a304105fae5691550.5474852846572866",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Appel 10min avant la livraison ++\nPas possible de voir le suivi du colis -",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.neg",
                                    "label": "Quality of service / Follow-up (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "service.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.neg",
                    "label": "Quality of service / Follow-up (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.281Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "0671222063",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Lionel THOMAS",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0671222063",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GECONEX",
            "CustomerCompanyGroupName": "GECONEX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "Commande du 21/12/22 - devis 222089934",
            "XylemReference": "194523",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Appel 10min avant la livraison ++\nPas possible de voir le suivi du colis -",
            "pageNo": "0",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Avoir un suivi du colis depuis votre prise en charge"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d353",
        "extendedResponse": {
            "_id": "63bc06b63f490710f0165938",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d3530.05440225608457028",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité (devis dans la journée)\n",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.ame@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ac28aa9f-a458-4b1d-9d2d-b01e1bc49e41",
            "responseId": "63b7ae718f2415d78bd3d353",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d3530.05440225608457028",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité (devis dans la journée)\n",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.328Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "christophe.ame@veolia.com",
            "CustomerContactEmail": "christophe.ame@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "AME",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0778512464",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - C.G.E.",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001169",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité (devis dans la journée)\n",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "La quantité de l'offre est supérieure à celle demandée (fourniture par lot?), il s'agit de régulateurs de niveau (poire)"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae710653c37343f43de8",
        "extendedResponse": {
            "_id": "63bc06b73f490710f0165939",
            "submittedBody": {
                "id": "63b7ae710653c37343f43de80.5964033721958537",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Points positifs :\n- Réactivité sur la location ou l'achat de matériels\n- Capacité à s'associer avec des installateurs pour les opérations de renouvellement de pompes\n- Attractivité des prix via les accords cadre\n- Qualité des échanges avec les commerciaux\n- Apports de conseils techniques",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "simon.genty.ext@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "df546343-4b5c-4021-a68d-708dd7c7103b",
            "responseId": "63b7ae710653c37343f43de8",
            "receivedResponse": {
                "id": "63b7ae710653c37343f43de80.5964033721958537",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Points positifs :\n- Réactivité sur la location ou l'achat de matériels\n- Capacité à s'associer avec des installateurs pour les opérations de renouvellement de pompes\n- Attractivité des prix via les accords cadre\n- Qualité des échanges avec les commerciaux\n- Apports de conseils techniques",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "ecoute.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.354Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "simon.genty.ext@suez.com",
            "CustomerContactEmail": "simon.genty.ext@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GENTY",
            "CustomerContactFirstName": "Simon",
            "CustomerContactMobilePhone": "0673514613",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEVESC",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Nathalie",
            "XylemContactedPersonName": "CLAIRET",
            "XylemContactedPersonRole": "TCS Location",
            "CustomerReference": "92TPLA123000012/0",
            "XylemReference": "757896",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Points positifs :\n- Réactivité sur la location ou l'achat de matériels\n- Capacité à s'associer avec des installateurs pour les opérations de renouvellement de pompes\n- Attractivité des prix via les accords cadre\n- Qualité des échanges avec les commerciaux\n- Apports de conseils techniques",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d8f2",
        "extendedResponse": {
            "_id": "63bc06b73f490710f016593a",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d8f20.21064193578105206",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "accueil rapidite ",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@cbmservices.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "12cd047f-c797-4ce4-ac70-e98aaa71bb1b",
            "responseId": "63b7ae718f2415d78bd3d8f2",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d8f20.21064193578105206",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "accueil rapidité ",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.438Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "contact@cbmservices.fr",
            "CustomerContactEmail": "contact@cbmservices.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "STRYJAKOWSKI",
            "CustomerContactFirstName": "ANGELIQUE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COTENTIN BOBINAGE ET MAINTENANCE",
            "CustomerCompanyGroupName": "COTENTIN BOBINAGE/MAINTENANCE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001266",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "accueil rapidite ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d8fe",
        "extendedResponse": {
            "_id": "63bc06b83f490710f016593b",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d8fe0.4774344970796167",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactif et conforme à la demande. ",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.galopin@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "45bab95e-6f05-4fde-af99-df3cb5154d4d",
            "responseId": "63b7ae718f2415d78bd3d8fe",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d8fe0.4774344970796167",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactif et conforme à la demande. ",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.460Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "kevin.galopin@sofinther.fr",
            "CustomerContactEmail": "kevin.galopin@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GALOPIN",
            "CustomerContactFirstName": "Kevin",
            "CustomerContactMobilePhone": "0659244657",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER ROUEN",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Clement",
            "XylemContactedPersonName": "FAIVRE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223001125",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactif et conforme à la demande. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d90c",
        "extendedResponse": {
            "_id": "63bc06b83f490710f016593c",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d90c0.09307424352679772",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse à ma demande très rapide",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "m.beller@evac-eau.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "10b2b6ec-d80f-4725-9fb3-792b8d0bf3b8",
            "responseId": "63b7ae718f2415d78bd3d90c",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d90c0.09307424352679772",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse à ma demande très rapide",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.489Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "m.beller@evac-eau.com",
            "CustomerContactEmail": "m.beller@evac-eau.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BELLER",
            "CustomerContactFirstName": "Martin",
            "CustomerContactMobilePhone": "0686410356",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EVAC EAU",
            "CustomerCompanyGroupName": "EVAC EAU",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001301",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse à ma demande très rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d913",
        "extendedResponse": {
            "_id": "63bc06b93f490710f016593d",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d9130.8407485162932644",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Stock sur les suppresseurs, rapidité de livraison. Commercial très pro ",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francois.matignon@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a85e262f-43e4-47c4-bd5b-88385a52edd8",
            "responseId": "63b7ae718f2415d78bd3d913",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d9130.8407485162932644",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Stock sur les suppresseurs, rapidité de livraison. Commercial très professionnel ",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.498Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "francois.matignon@sofinther.fr",
            "CustomerContactEmail": "francois.matignon@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MATIGNON",
            "CustomerContactFirstName": "François",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER ANGERS",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222061983",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Stock sur les suppresseurs, rapidité de livraison. Commercial très pro ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d91d",
        "extendedResponse": {
            "_id": "63bc06b93f490710f016593e",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d91d0.6576070080926919",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "MOINS PLU,\nla vérification technique par rapport a l'ancien matériel\nMIEUX PLU,\nle délais court de réponse et bon suivi commercial pour épauler le distributeur",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolasgolovko@sasravey.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "4399c63d-e27c-40cf-8ca2-e11c885e2fa6",
            "responseId": "63b7ae718f2415d78bd3d91d",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d91d0.6576070080926919",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "MOINS PLU,\nla vérification technique par rapport a l'ancien matériel\nMIEUX PLU,\nle délais court de réponse et bon suivi commercial pour épauler le distributeur",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.514Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "nicolasgolovko@sasravey.fr",
            "CustomerContactEmail": "nicolasgolovko@sasravey.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GOLOVKO",
            "CustomerContactFirstName": "NICOLAS",
            "CustomerContactMobilePhone": "0660031470",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RAVEY SA",
            "CustomerCompanyGroupName": "RAVEY  SA  ET CIE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "Gauthier",
            "XylemContactedPersonName": "DUFOUR",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223001328",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "MOINS PLU,\nla vérification technique par rapport a l'ancien matériel\nMIEUX PLU,\nle délais court de réponse et bon suivi commercial pour épauler le distributeur",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "se référé à la question 1"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b7ae718f2415d78bd3d929",
        "extendedResponse": {
            "_id": "63bc06b93f490710f016593f",
            "submittedBody": {
                "id": "63b7ae718f2415d78bd3d9290.5376142220385405",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2023-01-06T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@alre-bobinage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "47d9404e-4390-460a-a4b8-5c05d00b8e2b",
            "responseId": "63b7ae718f2415d78bd3d929",
            "receivedResponse": {
                "id": "63b7ae718f2415d78bd3d9290.5376142220385405",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2023-01-06T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-06T05:15:29.537Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-06_06.15.10",
        "integration": {
            "ContactKey": "contact@alre-bobinage.fr",
            "CustomerContactEmail": "contact@alre-bobinage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BALDINI",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0683976607",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ALRE BOBINAGE",
            "CustomerCompanyGroupName": "ALRE BOBINAGE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001347",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffee416e472f6e34516a",
        "extendedResponse": {
            "_id": "63bc06ba3f490710f0165940",
            "submittedBody": {
                "id": "63b8ffee416e472f6e34516a0.5116737648002543",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Aimabilite et réactivité ",
                "creationDate": "2023-01-07T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dominique.pindon@proservice-sema.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8e7eb338-4232-4db3-af3f-135affbd7b87",
            "responseId": "63b8ffee416e472f6e34516a",
            "receivedResponse": {
                "id": "63b8ffee416e472f6e34516a0.5116737648002543",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "amabilité et réactivité ",
                "creationDate": "2023-01-07T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-07T05:15:26.804Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "dominique.pindon@proservice-sema.fr",
            "CustomerContactEmail": "dominique.pindon@proservice-sema.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PINDON",
            "CustomerContactFirstName": "DOMINIQUE",
            "CustomerContactMobilePhone": "0611277195",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PROSERVICE - SEMA",
            "CustomerCompanyGroupName": "PROSERVICE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Aimabilite et réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345c8b",
        "extendedResponse": {
            "_id": "63bc06ba3f490710f0165941",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345c8b0.31817798044359025",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de réponse ",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pascal.baudry@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9d10220e-35c4-4140-976d-95d84319d6d3",
            "responseId": "63b8ffef416e472f6e345c8b",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345c8b0.31817798044359025",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de réponse ",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.282Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "pascal.baudry@suez.com",
            "CustomerContactEmail": "pascal.baudry@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BAUDRY",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "+33680340627",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (EAU ET FORCE) (STEP WAVRECHAIN)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Maryse",
            "XylemSalespeopleName": "VANOVERBERGHE",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001542",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345c98",
        "extendedResponse": {
            "_id": "63bc06bb3f490710f0165942",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345c980.7657063117006546",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la difficulté de trouver les adresses pour les devis et connaitre notre commercial. le plus la rapidité du devis.",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rene.blindal@babcock-wanson.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "e111c338-5f34-4f6e-b757-c9ee24aa1f5c",
            "responseId": "63b8ffef416e472f6e345c98",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345c980.7657063117006546",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la difficulté de trouver les adresses pour les devis et connaître notre commercial. le plus la rapidité du devis.",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.304Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "rene.blindal@babcock-wanson.fr",
            "CustomerContactEmail": "rene.blindal@babcock-wanson.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BLINDAL",
            "CustomerContactFirstName": "René",
            "CustomerContactMobilePhone": "0553652354",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BABCOCK WANSON SA 47",
            "CustomerCompanyGroupName": "BABCOCK-WANSON",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "GARCIA",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001120",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la difficulté de trouver les adresses pour les devis et connaitre notre commercial. le plus la rapidité du devis.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Les adresses de contact."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345ddd",
        "extendedResponse": {
            "_id": "63bc06bb3f490710f0165943",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345ddd0.28714762486108936",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse professionnelle et rapide",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aturrin@dumontenergies.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a48444e1-6bec-4cd5-9619-7ac4c5365fa0",
            "responseId": "63b8ffef416e472f6e345ddd",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345ddd0.28714762486108936",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse professionnelle et rapide",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.431Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "aturrin@dumontenergies.fr",
            "CustomerContactEmail": "aturrin@dumontenergies.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TURRIN",
            "CustomerContactFirstName": "Amaury",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DUMONT ENERGIES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223001518",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse professionnelle et rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345e0c",
        "extendedResponse": {
            "_id": "63bc06bb3f490710f0165944",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345e0c0.0478323110022405",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour, retour assez rapide par rapport aux diverses demandes",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.rodrigues@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "e8ae21e5-d2e2-4af9-9201-f5715b550953",
            "responseId": "63b8ffef416e472f6e345e0c",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345e0c0.0478323110022405",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour, retour assez rapide par rapport aux diverses demandes",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.469Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "christophe.rodrigues@saur.com",
            "CustomerContactEmail": "christophe.rodrigues@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RODRIGUES",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0668037832",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR - AGGLOPOLE PROVENCE ASSAINISSEMENT",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001552",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour, retour assez rapide par rapport aux diverses demandes",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Le commercial de notre secteur, se déplace pour nous rencontrer et nous informes des nouvelles évolutions"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345e12",
        "extendedResponse": {
            "_id": "63bc06bc3f490710f0165945",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345e120.6386221529694727",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon échange pour la détermination du matériel  ",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sylvain.varin@airlum.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "391d9295-bf5f-4798-9147-d77bb8ed6515",
            "responseId": "63b8ffef416e472f6e345e12",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345e120.6386221529694727",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon échange pour la détermination du matériel ",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.475Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "sylvain.varin@airlum.com",
            "CustomerContactEmail": "sylvain.varin@airlum.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VARIN",
            "CustomerContactFirstName": "SYLVAIN",
            "CustomerContactMobilePhone": "0621737532",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AIRLUM SAS",
            "CustomerCompanyGroupName": "AIRLUM",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001557",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon échange pour la détermination du matériel  ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345e13",
        "extendedResponse": {
            "_id": "63ce3138278650d23f43673d",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345e130.5548308578868413",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très réactif et très bonne compréhension de la demande",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "daoud.kenniche@ccpi-idf.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a75173db-2fec-4079-9b97-6ee5375b491b",
            "responseId": "63b8ffef416e472f6e345e13",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345e130.5548308578868413",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très réactif et très bonne compréhension de la demande",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.477Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "daoud.kenniche@ccpi-idf.fr",
            "CustomerContactEmail": "daoud.kenniche@ccpi-idf.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "KENNICHE",
            "CustomerContactFirstName": "DAOUD",
            "CustomerContactMobilePhone": "0766280330",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CCPI",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Sarah",
            "XylemContactedPersonName": "NERON",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "223001558",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très réactif et très bonne compréhension de la demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345ee0",
        "extendedResponse": {
            "_id": "63bc06bc3f490710f0165946",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345ee00.7810533534235131",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tj excellent service",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.schlotzer@vinci-construction.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b132f2d4-9cf5-4e2f-a1b9-32e919eeef7d",
            "responseId": "63b8ffef416e472f6e345ee0",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345ee00.7810533534235131",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tj excellent service",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.591Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "thomas.schlotzer@vinci-construction.fr",
            "CustomerContactEmail": "thomas.schlotzer@vinci-construction.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SCHLOTZER",
            "CustomerContactFirstName": "Thomas",
            "CustomerContactMobilePhone": "0778705698",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEA EST",
            "CustomerCompanyGroupName": "VINCI CF (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Benoit",
            "XylemContactedPersonName": "BOLTZ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223001658",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tj excellent service",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63b8ffef416e472f6e345f83",
        "extendedResponse": {
            "_id": "63bc06bd3f490710f0165947",
            "submittedBody": {
                "id": "63b8ffef416e472f6e345f830.34421172578334636",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour, efficacité et rapidité.",
                "creationDate": "2023-01-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.pomies@saepg81.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6351ba13-9720-4325-88db-2509a188045e",
            "responseId": "63b8ffef416e472f6e345f83",
            "receivedResponse": {
                "id": "63b8ffef416e472f6e345f830.34421172578334636",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour, efficacité et rapidité.",
                "creationDate": "2023-01-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-07T05:15:27.738Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-07_06.15.09",
        "integration": {
            "ContactKey": "eric.pomies@saepg81.fr",
            "CustomerContactEmail": "eric.pomies@saepg81.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POMIES",
            "CustomerContactFirstName": "ERIC",
            "CustomerContactMobilePhone": "0633498909",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "S.A.E.P du Gaillacois",
            "CustomerCompanyGroupName": "S.I.A.E.P.  DU GAILLACOIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "MAURE-PATUREL",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223001819",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour, efficacité et rapidité.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bba2e0416e472f6e6a07c3",
        "extendedResponse": {
            "_id": "63bc06bd3f490710f0165948",
            "submittedBody": {
                "id": "63bba2e0416e472f6e6a07c30.27758894534236567",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J’adore le retour rapide et très compétent de votre commercial qui connaît la gamme de produit, cela facilite énormément notre travail ",
                "creationDate": "2023-01-09T06:15:12+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "maxime.leclere@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "08b79f35-c64d-44f7-917b-97ef595a4e52",
            "responseId": "63bba2e0416e472f6e6a07c3",
            "receivedResponse": {
                "id": "63bba2e0416e472f6e6a07c30.27758894534236567",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J adore le retour rapide et très compétent de votre commercial qui connaît la gamme de produit, cela facilité énormément notre travail ",
                "creationDate": "2023-01-09T06:15:12Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "delai.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-09T05:15:12.005Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-09_06.15.03",
        "integration": {
            "ContactKey": "maxime.leclere@dalkia.fr",
            "CustomerContactEmail": "maxime.leclere@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LECLERE",
            "CustomerContactFirstName": "Maxime",
            "CustomerContactMobilePhone": "0645889193",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA CENTRE OPERATIONNEL SUD LORRAINE",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "EHRENFELD",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "EHRENFELD",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "223001852",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J’adore le retour rapide et très compétent de votre commercial qui connaît la gamme de produit, cela facilite énormément notre travail ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf46f36094271542a9f4f",
        "extendedResponse": {
            "_id": "63ce3138278650d23f43673e",
            "submittedBody": {
                "id": "63bcf46f36094271542a9f4f0.9618383922301401",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La présentation était très claire, elle a répondu à nos attentes et nos interrogations. Les personnes qui sont intervenues ont été très à l'écoute en capacité de nous parler des différentes solutions qui pouvaient nous intéresser, de nous exposer leurs retours d'expériences. Au delà de la présentation ils sont très réactifs par mails quand nous avons des demandes, nous sommes très contents de travailler avec eux.",
                "creationDate": "2023-01-10T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julie.troussel@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "06db8019-7445-4a09-85f4-fc12cf68fe0b",
            "responseId": "63bcf46f36094271542a9f4f",
            "receivedResponse": {
                "id": "63bcf46f36094271542a9f4f0.9618383922301401",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La présentation était très claire, elle a répondu à nos attentes et nos interrogations. Les personnes qui sont intervenues ont été très à l'écoute en capacité de nous parler des différentes solutions qui pouvaient nous intéresser, de nous exposer leurs retours d'expériences. Au delà de la présentation ils sont très réactifs par mails quand nous avons des demandes, nous sommes très contents de travailler avec eux.",
                "creationDate": "2023-01-10T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "ecoute.pos",
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:27.801Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "julie.troussel@saur.com",
            "CustomerContactEmail": "julie.troussel@saur.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "TROUSSEL",
            "CustomerContactFirstName": "Julie",
            "CustomerContactMobilePhone": "+33668833734",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR - BUREAU D'ETUDE (NIMES)",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La présentation était très claire, elle a répondu à nos attentes et nos interrogations. Les personnes qui sont intervenues ont été très à l'écoute en capacité de nous parler des différentes solutions qui pouvaient nous intéresser, de nous exposer leurs retours d'expériences. Au delà de la présentation ils sont très réactifs par mails quand nous avons des demandes, nous sommes très contents de travailler avec eux.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf46f36094271542a9f78",
        "extendedResponse": {
            "_id": "63ce3138278650d23f43673f",
            "submittedBody": {
                "id": "63bcf46f36094271542a9f780.085679701466302",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Surement un des meilleur commercial qui nous suit",
                "creationDate": "2023-01-10T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabrice.balan@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6d22b934-9872-4dc4-9d8b-0f4df4c93f8e",
            "responseId": "63bcf46f36094271542a9f78",
            "receivedResponse": {
                "id": "63bcf46f36094271542a9f780.085679701466302",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Surement un des meilleur commercial qui nous suit",
                "creationDate": "2023-01-10T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:27.838Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "fabrice.balan@sofinther.fr",
            "CustomerContactEmail": "fabrice.balan@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BALAN",
            "CustomerContactFirstName": "Fabrice",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "BOUZIGON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Surement un des meilleur commercial qui nous suit",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aa577",
        "extendedResponse": {
            "_id": "63ce3139278650d23f436740",
            "submittedBody": {
                "id": "63bcf47036094271542aa5770.07150553986558794",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDITé",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.richard@dsl.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ddaa390a-4bf7-4b6a-b5aa-b8b55652a2f2",
            "responseId": "63bcf47036094271542aa577",
            "receivedResponse": {
                "id": "63bcf47036094271542aa5770.07150553986558794",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAPIDITé",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.220Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "thomas.richard@dsl.fr",
            "CustomerContactEmail": "thomas.richard@dsl.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RICHARD",
            "CustomerContactFirstName": "Thomas",
            "CustomerContactMobilePhone": "0609416230",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DELTA SERVICE LOCATION",
            "CustomerCompanyGroupName": "DSL (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002140",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDITé",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aa58d",
        "extendedResponse": {
            "_id": "63ce3139278650d23f436741",
            "submittedBody": {
                "id": "63bcf47036094271542aa58d0.6009034656271539",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-yves.renaudeau@saur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bd19d8b3-8137-4393-9304-3c847919ed33",
            "responseId": "63bcf47036094271542aa58d",
            "receivedResponse": {
                "id": "63bcf47036094271542aa58d0.6009034656271539",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.241Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "jean-yves.renaudeau@saur.fr",
            "CustomerContactEmail": "jean-yves.renaudeau@saur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RENAUDEAU",
            "CustomerContactFirstName": "Jean-Yves",
            "CustomerContactMobilePhone": "0661302189",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002211",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aa5d4",
        "extendedResponse": {
            "_id": "63ce3139278650d23f436742",
            "submittedBody": {
                "id": "63bcf47036094271542aa5d40.7722400790865127",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laura.dufrene@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8c3e3114-8e95-4c3d-8841-c01f00aff372",
            "responseId": "63bcf47036094271542aa5d4",
            "receivedResponse": {
                "id": "63bcf47036094271542aa5d40.7722400790865127",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.298Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "laura.dufrene@saur.com",
            "CustomerContactEmail": "laura.dufrene@saur.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DUFRENE",
            "CustomerContactFirstName": "LAURA",
            "CustomerContactMobilePhone": "0675080517",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223001951",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf470416e472f6e8714ce",
        "extendedResponse": {
            "_id": "63ce313a278650d23f436743",
            "submittedBody": {
                "id": "63bcf470416e472f6e8714ce0.7038634905275276",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Excellente prestation,  très sérieux ",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0614038480"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "22c1a100-2428-4043-9aca-abba42e11fb9",
            "responseId": "63bcf470416e472f6e8714ce",
            "receivedResponse": {
                "id": "63bcf470416e472f6e8714ce0.7038634905275276",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Excellente prestation, très sérieux ",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.362Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "0614038480",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LONGNY GERARD",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0614038480",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VAREO",
            "CustomerCompanyGroupName": "VAREO",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF LONGNY",
            "XylemReference": "195496",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Excellente prestation,  très sérieux ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aad80",
        "extendedResponse": {
            "_id": "63ce313a278650d23f436744",
            "submittedBody": {
                "id": "63bcf47036094271542aad800.195428760500854",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité des réponses , support technique complet  ",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "b.garcin@lestechniciensdeleau.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "218c4930-9823-4b08-807b-f8b31381a39f",
            "responseId": "63bcf47036094271542aad80",
            "receivedResponse": {
                "id": "63bcf47036094271542aad800.195428760500854",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité des réponses , support technique complet ",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.371Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "b.garcin@lestechniciensdeleau.com",
            "CustomerContactEmail": "b.garcin@lestechniciensdeleau.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GARCIN",
            "CustomerContactFirstName": "Benoît",
            "CustomerContactMobilePhone": "0649166409",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LES TECHNICIENS DE L'EAU",
            "CustomerCompanyGroupName": "LES TECHNICIENS DE L’EAU",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "Joachim",
            "XylemContactedPersonName": "GAUDON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002045",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité des réponses , support technique complet  ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aadac",
        "extendedResponse": {
            "_id": "63ce313a278650d23f436745",
            "submittedBody": {
                "id": "63bcf47036094271542aadac0.9833887207949255",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très pro et riche en renseignements , offre dans la foulée .",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.martinec@engie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7ae43a10-2150-4a8d-a5bd-c5f5be82f6de",
            "responseId": "63bcf47036094271542aadac",
            "receivedResponse": {
                "id": "63bcf47036094271542aadac0.9833887207949255",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très professionnel et riche en renseignements , offre dans la foulée .",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.372Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "david.martinec@engie.com",
            "CustomerContactEmail": "david.martinec@engie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARTINEC",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "0689878362",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ENERGIVRY",
            "CustomerCompanyGroupName": "COFELY (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Remy",
            "XylemContactedPersonName": "BERNETIERE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223002047",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très pro et riche en renseignements , offre dans la foulée .",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aae0e",
        "extendedResponse": {
            "_id": "63ce313a278650d23f436746",
            "submittedBody": {
                "id": "63bcf47036094271542aae0e0.3266570093827401",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Technicien à l'heure, communication, rapport dans la foulée de l'intervention",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.courjol@semopsival.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "da0e594a-9f46-4846-a1f7-abf37667cbd5",
            "responseId": "63bcf47036094271542aae0e",
            "receivedResponse": {
                "id": "63bcf47036094271542aae0e0.3266570093827401",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Technicien à l'heure, communication, rapport dans la foulée de l'intervention",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.377Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "kevin.courjol@semopsival.fr",
            "CustomerContactEmail": "kevin.courjol@semopsival.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COURJOL",
            "CustomerContactFirstName": "Kévin",
            "CustomerContactMobilePhone": "0612769439",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIVAL USINE EPURATION SEINE AMONT",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE",
            "XylemContactedPersonFirstName": "Tchandra",
            "XylemContactedPersonName": "AMBROISE",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "2022-2535",
            "XylemReference": "2RF061951",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Technicien à l'heure, communication, rapport dans la foulée de l'intervention",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf470416e472f6e8714d7",
        "extendedResponse": {
            "_id": "63ce313b278650d23f436747",
            "submittedBody": {
                "id": "63bcf470416e472f6e8714d70.05143364008474194",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison parfaite ",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0650393991"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2db8912b-1229-4444-86ea-b8651feabfab",
            "responseId": "63bcf470416e472f6e8714d7",
            "receivedResponse": {
                "id": "63bcf470416e472f6e8714d70.05143364008474194",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison parfaite ",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.381Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "0650393991",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "AIRIAU Anthony",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0650393991",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR STEP DES 60 BORNES",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000439912",
            "XylemReference": "195369",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison parfaite ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf470416e472f6e8714d9",
        "extendedResponse": {
            "_id": "63ce313b278650d23f436748",
            "submittedBody": {
                "id": "63bcf470416e472f6e8714d90.7347962719772942",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très pro",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0651664520"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "57dc8f5b-dd80-405b-8a75-b957b9c80981",
            "responseId": "63bcf470416e472f6e8714d9",
            "receivedResponse": {
                "id": "63bcf470416e472f6e8714d90.7347962719772942",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très professionnel",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.383Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "0651664520",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. MAGANE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0651664520",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ACELIA",
            "CustomerCompanyGroupName": "ACELIA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 21/12/2022 - Devis 222089245",
            "XylemReference": "194451",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très pro",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf470416e472f6e8714e0",
        "extendedResponse": {
            "_id": "63ce313b278650d23f436749",
            "submittedBody": {
                "id": "63bcf470416e472f6e8714e00.22403735314493045",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le délai de livraison  parfait ",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0622976622"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6567893e-41e7-4feb-9488-dd804167b984",
            "responseId": "63bcf470416e472f6e8714e0",
            "receivedResponse": {
                "id": "63bcf470416e472f6e8714e00.22403735314493045",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le délai de livraison parfait ",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.394Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "0622976622",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BENETEAU Emmanuel",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0622976622",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COM D'AGGLO LA ROCHELLE",
            "CustomerCompanyGroupName": "COMMUNAUTE AGGLO. LA ROCHELLE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AS221644",
            "XylemReference": "187210",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le délai de livraison  parfait ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aaec5",
        "extendedResponse": {
            "_id": "63ce313c278650d23f43674a",
            "submittedBody": {
                "id": "63bcf47036094271542aaec50.01043194901093214",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon relationnel, étudie le projet de manière sérieuse \nJe recommande",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jp-desousa@delacommune-dumont.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "732e61de-6363-4474-b01b-1a030bc9ad33",
            "responseId": "63bcf47036094271542aaec5",
            "receivedResponse": {
                "id": "63bcf47036094271542aaec50.01043194901093214",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon relationnel, étudie le projet de manière sérieuse \nJe recommande",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.499Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "jp-desousa@delacommune-dumont.fr",
            "CustomerContactEmail": "jp-desousa@delacommune-dumont.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DE SOUSA",
            "CustomerContactFirstName": "Jean-Paul",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DELACOMMUNE ET DUMONT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002165",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon relationnel, étudie le projet de manière sérieuse \nJe recommande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aaee5",
        "extendedResponse": {
            "_id": "63ce313c278650d23f43674b",
            "submittedBody": {
                "id": "63bcf47036094271542aaee50.6897702866226392",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le - : Le délai de réponse est trop long. J'ai déjà répondu au client.\nle+ : Devis détaillé est complet avec plan ",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tp@baudry-sa.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "5dd9a120-c3a7-466f-8443-75171563bba9",
            "responseId": "63bcf47036094271542aaee5",
            "receivedResponse": {
                "id": "63bcf47036094271542aaee50.6897702866226392",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le - : Le délai de réponse est trop long. J'ai déjà répondu au client.\nle+ : Devis détaillé est complet avec plan ",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.neg",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.507Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "tp@baudry-sa.com",
            "CustomerContactEmail": "tp@baudry-sa.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BONNET",
            "CustomerContactFirstName": "JOSSELIN",
            "CustomerContactMobilePhone": "0607720459",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BAUDRY",
            "CustomerCompanyGroupName": "BAUDRY",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002175",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le - : Le délai de réponse est trop long. J'ai déjà répondu au client.\nle+ : Devis détaillé est complet avec plan ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Délais"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bcf47036094271542aafc6",
        "extendedResponse": {
            "_id": "63ce313c278650d23f43674c",
            "submittedBody": {
                "id": "63bcf47036094271542aafc60.12685997145102612",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis réalisé très rapidement, c'était parfait",
                "creationDate": "2023-01-10T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rene.souliere-hervo@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "554b1200-fd72-46ab-ad3d-655664003b89",
            "responseId": "63bcf47036094271542aafc6",
            "receivedResponse": {
                "id": "63bcf47036094271542aafc60.12685997145102612",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis réalisé très rapidement, c'était parfait",
                "creationDate": "2023-01-10T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-10T05:15:28.561Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-10_06.15.08",
        "integration": {
            "ContactKey": "rene.souliere-hervo@saur.com",
            "CustomerContactEmail": "rene.souliere-hervo@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SOULIERE-HERVO",
            "CustomerContactFirstName": "René",
            "CustomerContactMobilePhone": "0665530024",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223002254",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis réalisé très rapidement, c'était parfait",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b6530",
        "extendedResponse": {
            "_id": "63ce313c278650d23f43674d",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b65300.025312970668077917",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Convivialité, professionnalisme",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.mantovanelli@syndicat-crw.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "adc65447-e6b0-447a-a7f1-6f429ac5fea6",
            "responseId": "63be45f0914f84fe6f0b6530",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b65300.025312970668077917",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Convivialité, professionnalisme",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.057Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "j.mantovanelli@syndicat-crw.fr",
            "CustomerContactEmail": "j.mantovanelli@syndicat-crw.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MANTOVANELLI",
            "CustomerContactFirstName": "JONATHAN",
            "CustomerContactMobilePhone": "0672716946",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Syndicat Contrat Rivière Woigot (CRW) - BRIEY",
            "CustomerCompanyGroupName": "CONTRAT RIVIERE WOIGOT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Hugo",
            "XylemContactedPersonName": "DEBETTE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Convivialité, professionnalisme",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b6535",
        "extendedResponse": {
            "_id": "63ce313d278650d23f43674e",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b65350.693543623927412",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité à chaque sollicitation, pas de démarchage commerciale agressif",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julien.couteleau@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "4df2e4c3-5cba-486a-9679-e2914b38d1e3",
            "responseId": "63be45f0914f84fe6f0b6535",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b65350.693543623927412",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité à chaque sollicitation, pas de démarchage commerciale agressif",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.neg",
                                    "label": "Welcome / Kindness (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "accueil.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.neg",
                    "label": "Welcome / Kindness (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.060Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "julien.couteleau@veolia.com",
            "CustomerContactEmail": "julien.couteleau@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COUTELEAU",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "0622464528",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Pierrick",
            "XylemContactedPersonName": "FRAPPAS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité à chaque sollicitation, pas de démarchage commerciale agressif",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b6fb3",
        "extendedResponse": {
            "_id": "63ce313d278650d23f43674f",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b6fb30.42358158323888784",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide pour le devis",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "p.laboulle@groupe-seche.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "dd4bf39a-994f-4c73-8299-ae348dd05d18",
            "responseId": "63be45f0914f84fe6f0b6fb3",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b6fb30.42358158323888784",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide pour le devis",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.260Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "p.laboulle@groupe-seche.com",
            "CustomerContactEmail": "p.laboulle@groupe-seche.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LABOULLE",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "0618246655",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SECHE ECO SERVICES",
            "CustomerCompanyGroupName": "SECHE ECO SERVICES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002382",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide pour le devis",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b6fb7",
        "extendedResponse": {
            "_id": "63ce313d278650d23f436750",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b6fb70.882453350371833",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "ce qui m'a plus la reactivite sur le devis, par rapport au moment de l'appelle et la reponse a mes attente",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "v.houzan@groupe-seche.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "71d15623-8f14-47fb-a1f6-6ba9e6b9e3fb",
            "responseId": "63be45f0914f84fe6f0b6fb7",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b6fb70.882453350371833",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "ce qui m'a plus la réactivité sur le devis, par rapport au moment de l'appelle et la réponse a mes attente",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.263Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "v.houzan@groupe-seche.com",
            "CustomerContactEmail": "v.houzan@groupe-seche.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HOUZAN",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0635562512",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SECHE ECO SERVICES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223000835",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "ce qui m'a plus la reactivite sur le devis, par rapport au moment de l'appelle et la reponse a mes attente",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "je suis difficile pour donner un 5"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b7118",
        "extendedResponse": {
            "_id": "63ce313e278650d23f436751",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b71180.818974418652328",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et expertise +++++",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.vandenesse@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "443320c6-ce46-4d1f-8b5d-4fbbc17aac31",
            "responseId": "63be45f0914f84fe6f0b7118",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b71180.818974418652328",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et expertise +++++",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.369Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "kevin.vandenesse@sade-cgth.fr",
            "CustomerContactEmail": "kevin.vandenesse@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANDENESSE",
            "CustomerContactFirstName": "Kévin",
            "CustomerContactMobilePhone": "0621455746",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223002324",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et expertise +++++",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b71b4",
        "extendedResponse": {
            "_id": "63ce313e278650d23f436752",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b71b40.9552904091793362",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.canevari@eridis.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8d4b8a58-48ba-40bc-a411-154b45e488bb",
            "responseId": "63be45f0914f84fe6f0b71b4",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b71b40.9552904091793362",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.454Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "philippe.canevari@eridis.fr",
            "CustomerContactEmail": "philippe.canevari@eridis.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CANEVARI",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0684830448",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ERIDIS",
            "CustomerCompanyGroupName": "ERIDIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Michel",
            "XylemSalespeopleName": "ALBRECHT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002468",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b71d5",
        "extendedResponse": {
            "_id": "63ce313e278650d23f436753",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b71d50.8657426799272401",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bonne réactivité",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.poucet@01pompage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "da2301b5-a151-4430-b819-f201542af840",
            "responseId": "63be45f0914f84fe6f0b71d5",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b71d50.8657426799272401",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bonne réactivité",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.465Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "j.poucet@01pompage.fr",
            "CustomerContactEmail": "j.poucet@01pompage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POUCET",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "0672711199",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "01 POMPAGE",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223002488",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bonne réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b7203",
        "extendedResponse": {
            "_id": "63ce313e278650d23f436754",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b72030.0929011399365196",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le commercial s’est le plus beau très fort gentil et costaud 💪🏼 il va sûrement pousser de la fonte à la salle le soir pour pécho après le week-end 😉",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aubin.salvan@tereva.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d194fd26-f645-4d3b-9b15-a3114dc6d080",
            "responseId": "63be45f0914f84fe6f0b7203",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b72030.0929011399365196",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le commercial s est le plus beau très fort gentil et costaud il va sûrement pousser de la fonte à la salle le soir pour pécho après le week-end ",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.474Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "aubin.salvan@tereva.fr",
            "CustomerContactEmail": "aubin.salvan@tereva.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SALVAN",
            "CustomerContactFirstName": "Aubin",
            "CustomerContactMobilePhone": "0555866137",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREVA BRIVE",
            "CustomerCompanyGroupName": "MBE (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002495",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le commercial s’est le plus beau très fort gentil et costaud 💪🏼 il va sûrement pousser de la fonte à la salle le soir pour pécho après le week-end 😉",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b72de",
        "extendedResponse": {
            "_id": "63ce313f278650d23f436755",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b72de0.6555523191258485",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La qualité de l'offre, et le délai de réponse court.",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pierre.sabatier@induspompes.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3d11f671-fff4-496a-9d00-61cbe631fe03",
            "responseId": "63be45f0914f84fe6f0b72de",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b72de0.6555523191258485",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La qualité de l'offre, et le délai de réponse court.",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.580Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "pierre.sabatier@induspompes.fr",
            "CustomerContactEmail": "pierre.sabatier@induspompes.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SABATIER",
            "CustomerContactFirstName": "Pierre",
            "CustomerContactMobilePhone": "0603460731",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "INDUS POMPES",
            "CustomerCompanyGroupName": "INDUS POMPES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002621",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La qualité de l'offre, et le délai de réponse court.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b7350",
        "extendedResponse": {
            "_id": "63ce313f278650d23f436756",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b73500.6904214623595415",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "OFFRE ENVOYEE RAPIDEMENT ET BIEN DETAILLEE ",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sabine.lahalle@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c0a992b0-f2e2-4e2e-a5cf-599550f0a46a",
            "responseId": "63be45f0914f84fe6f0b7350",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b73500.6904214623595415",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "OFFRE ENVOYEE RAPIDEMENT ET BIEN détaillée ",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.638Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "sabine.lahalle@suez.com",
            "CustomerContactEmail": "sabine.lahalle@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAHALLE",
            "CustomerContactFirstName": "Sabine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Patrick",
            "XylemSalespeopleName": "RECLUS",
            "XylemContactedPersonFirstName": "Patrick",
            "XylemContactedPersonName": "RECLUS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223002684",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "OFFRE ENVOYEE RAPIDEMENT ET BIEN DETAILLEE ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b7358",
        "extendedResponse": {
            "_id": "63ce313f278650d23f436757",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b73580.703605727966945",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "l'offre technique est claire mais le prix 20% trop cher",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.desnoyers@sd-eau.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0d13428e-3fec-4a03-8bc9-ebcf0940abed",
            "responseId": "63be45f0914f84fe6f0b7358",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b73580.703605727966945",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "l'offre technique est claire mais le prix 20% trop cher",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.644Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "laurent.desnoyers@sd-eau.fr",
            "CustomerContactEmail": "laurent.desnoyers@sd-eau.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DESNOYERS",
            "CustomerContactFirstName": "LAURENT",
            "CustomerContactMobilePhone": "0658939446",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SD-EAU",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002691",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "l'offre technique est claire mais le prix 20% trop cher",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63be45f0914f84fe6f0b73bf",
        "extendedResponse": {
            "_id": "63ce3140278650d23f436758",
            "submittedBody": {
                "id": "63be45f0914f84fe6f0b73bf0.58322186113027",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Grande réactivité de réponse à ma demande de prix, rien à dire !",
                "creationDate": "2023-01-11T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "l.bezombes@etchart-energies.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0c4b834b-663d-44db-a536-f5b5e8ac6853",
            "responseId": "63be45f0914f84fe6f0b73bf",
            "receivedResponse": {
                "id": "63be45f0914f84fe6f0b73bf0.58322186113027",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Grande réactivité de réponse à ma demande de prix, rien à dire !",
                "creationDate": "2023-01-11T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "competence.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-11T05:15:28.661Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-11_06.15.08",
        "integration": {
            "ContactKey": "l.bezombes@etchart-energies.fr",
            "CustomerContactEmail": "l.bezombes@etchart-energies.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BEZOMBES",
            "CustomerContactFirstName": "Ludovic",
            "CustomerContactMobilePhone": "0686128948",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ETCHART ENERGIES",
            "CustomerCompanyGroupName": "ETCHART ENERGIES",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "BOUZIGON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223002708",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Grande réactivité de réponse à ma demande de prix, rien à dire !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771914f84fe6fa1c214",
        "extendedResponse": {
            "_id": "63ce3140278650d23f436759",
            "submittedBody": {
                "id": "63bf9771914f84fe6fa1c2140.2363684316282133",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tout a été parfait ",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0672503880"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "691eb7db-a171-40f4-803c-bbfe311d7d96",
            "responseId": "63bf9771914f84fe6fa1c214",
            "receivedResponse": {
                "id": "63bf9771914f84fe6fa1c2140.2363684316282133",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tout a été parfait ",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.140Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "0672503880",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "THOMAS CHARLES",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0672503880",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEPI-POMPES.COM",
            "CustomerCompanyGroupName": "SEPI-POMPES.COM",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "PO21778",
            "XylemReference": "196044",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tout a été parfait ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6d97",
        "extendedResponse": {
            "_id": "63ce3140278650d23f43675a",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6d970.328004834338401",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de la réponse ,devis clair et bien détaillé.",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.duffau@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f7ae22a0-b813-4e88-bbe2-d66597a795ca",
            "responseId": "63bf9771416e472f6e7c6d97",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6d970.328004834338401",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de la réponse ,devis clair et bien détaillé.",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.163Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "eric.duffau@suez.com",
            "CustomerContactEmail": "eric.duffau@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUFFAU",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "0632541199",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002738",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de la réponse ,devis clair et bien détaillé.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6df9",
        "extendedResponse": {
            "_id": "63ce3141278650d23f43675b",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6df90.026615907283035423",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ras",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-paul.coste@eaudetm-asst.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "487820aa-2855-485c-a306-7eefa863d611",
            "responseId": "63bf9771416e472f6e7c6df9",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6df90.026615907283035423",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ras",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.229Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "jean-paul.coste@eaudetm-asst.fr",
            "CustomerContactEmail": "jean-paul.coste@eaudetm-asst.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COSTE",
            "CustomerContactFirstName": "Jean Paul",
            "CustomerContactMobilePhone": "0763553565",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ASTEO",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002846",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ras",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6e01",
        "extendedResponse": {
            "_id": "63ce3141278650d23f43675c",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6e010.3120377188493926",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour. Ce qui me plait c'est la compétence l'écoute la rapidité des réponses la réactivité de votre société. Ce qui me plait moins c'est les prix je serais ravi d'avoir un peu plus de remise sur le neuf et les pièces de rechanges. J'espère que cela pourra être négociable.je vous remercie. Cordialement",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "atelier@servicebobinage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "b08d2940-09c8-45f0-9df0-a769701f3a2d",
            "responseId": "63bf9771416e472f6e7c6e01",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6e010.3120377188493926",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour. Ce qui me plaît c'est la compétence l'écoute la rapidité des réponses la réactivité de votre société. Ce qui me plaît moins c'est les prix je serais ravi d'avoir un peu plus de remise sur le neuf et les pièces de rechanges. J'espère que cela pourra être négociable.je vous remercie. Cordialement",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "prix.autre",
                "delai.pos",
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.240Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "atelier@servicebobinage.com",
            "CustomerContactEmail": "atelier@servicebobinage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": ".",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERVICE BOBINAGE",
            "CustomerCompanyGroupName": "SCE BOB.ELEC.MECA.VAROIS SBEMV",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002896",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour. Ce qui me plait c'est la compétence l'écoute la rapidité des réponses la réactivité de votre société. Ce qui me plait moins c'est les prix je serais ravi d'avoir un peu plus de remise sur le neuf et les pièces de rechanges. J'espère que cela pourra être négociable.je vous remercie. Cordialement",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "nous faire bénéficier d'une remise suplémentaire.cordialement"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6e04",
        "extendedResponse": {
            "_id": "63ce3141278650d23f43675d",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6e040.12320689425474107",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le retour rapide et précis du devis",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.pourchel@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ec606418-9eb4-403c-9d1a-22a004d9df86",
            "responseId": "63bf9771416e472f6e7c6e04",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6e040.12320689425474107",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le retour rapide et précis du devis",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.245Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "xavier.pourchel@veolia.com",
            "CustomerContactEmail": "xavier.pourchel@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POURCHEL",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0603492814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE SAINT OMER (STEP)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002906",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le retour rapide et précis du devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6e0f",
        "extendedResponse": {
            "_id": "63ce3142278650d23f43675e",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6e0f0.19832854097745956",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "mauvaise réponse",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christian.reynaud@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "6c1e3dbd-6bbf-4617-aebe-91f7410cdce0",
            "responseId": "63bf9771416e472f6e7c6e0f",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6e0f0.19832854097745956",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "mauvaise réponse",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.neg",
                                    "label": "Skills (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "competence.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.neg",
                    "label": "Skills (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.264Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "christian.reynaud@sofinther.fr",
            "CustomerContactEmail": "christian.reynaud@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REYNAUD",
            "CustomerContactFirstName": "Christian",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER NANTES",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223002935",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "mauvaise réponse",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Christian REYNAUD\",\n  \"mail\": \"christian.reynaud@sofinther.fr\"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6e25",
        "extendedResponse": {
            "_id": "63ce3142278650d23f43675f",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6e250.9701299467518354",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "  Réactivité sur les demandes. ",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tmichaud@prolians.eu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "46e7a4cd-082b-41bf-809c-dcc1a4a01dea",
            "responseId": "63bf9771416e472f6e7c6e25",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6e250.9701299467518354",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": " Réactivité sur les demandes. ",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.301Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "tmichaud@prolians.eu",
            "CustomerContactEmail": "tmichaud@prolians.eu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MICHAUD",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "0546006949",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VAMA - DOCK",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003001",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "  Réactivité sur les demandes. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6e3c",
        "extendedResponse": {
            "_id": "63ce3142278650d23f436760",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6e3c0.4143646511009773",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La première interlocutrice incapable de donner la référence pièces, c'est vrai je n'était pas au bon service.\nLa deuxième interlocutrice Madame Laquaine très professionnelle, malgré le dépassement horaire 17h10.",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "service.vrd@ville-guichenpontrean.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "f2b86a78-93f1-41fc-b97c-f02afa1b11c9",
            "responseId": "63bf9771416e472f6e7c6e3c",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6e3c0.4143646511009773",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La première interlocutrice incapable de donner la référence pièces, c'est vrai je n'était pas au bon service.\nLa deuxième interlocutrice Madame Laquaine très professionnelle, malgré le dépassement horaire 17h10.",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.333Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "service.vrd@ville-guichenpontrean.fr",
            "CustomerContactEmail": "service.vrd@ville-guichenpontrean.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RENAUD",
            "CustomerContactFirstName": "DAVID",
            "CustomerContactMobilePhone": "0632646084",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MAIRIE GUICHEN",
            "CustomerCompanyGroupName": "MAIRIE DE GUICHEN",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Corinne",
            "XylemContactedPersonName": "LAQUAINE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223002722",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La première interlocutrice incapable de donner la référence pièces, c'est vrai je n'était pas au bon service.\nLa deuxième interlocutrice Madame Laquaine très professionnelle, malgré le dépassement horaire 17h10.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"RENAUD David\",\n  \"phone\": \"06 32 64 60 84\",\n  \"mail\": \"service.vrd@ville-guichenpontrean.fr\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6e9e",
        "extendedResponse": {
            "_id": "63ce3142278650d23f436761",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6e9e0.12436897196913876",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité de réponse",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "g.delia@ppi-pompes.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5b8a5154-364d-416c-9edd-0a10364c3c34",
            "responseId": "63bf9771416e472f6e7c6e9e",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6e9e0.12436897196913876",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité de réponse",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.392Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "g.delia@ppi-pompes.fr",
            "CustomerContactEmail": "g.delia@ppi-pompes.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELIA",
            "CustomerContactFirstName": "Giovanni",
            "CustomerContactMobilePhone": "0672206391",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PPI",
            "CustomerCompanyGroupName": "POMPES ET PROCESS INDUSTRIE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002767",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63bf9771416e472f6e7c6eec",
        "extendedResponse": {
            "_id": "63ce3143278650d23f436762",
            "submittedBody": {
                "id": "63bf9771416e472f6e7c6eec0.3611912921521345",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS bon service",
                "creationDate": "2023-01-12T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lionel.mihe@rubix.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "12b9ae68-b549-4eae-bf6c-6e7e6b38eea1",
            "responseId": "63bf9771416e472f6e7c6eec",
            "receivedResponse": {
                "id": "63bf9771416e472f6e7c6eec0.3611912921521345",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS bon service",
                "creationDate": "2023-01-12T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-12T05:15:29.463Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-12_06.15.10",
        "integration": {
            "ContactKey": "lionel.mihe@rubix.com",
            "CustomerContactEmail": "lionel.mihe@rubix.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MIHE",
            "CustomerContactFirstName": "Lionel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LE CORVAISIER JEAN - OREXAD",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002829",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS bon service",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5de7",
        "extendedResponse": {
            "_id": "63ce3143278650d23f436763",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5de70.37627567534598527",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Reponse rapide",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.pourchel@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9beef82b-7b00-47a7-9b28-1b1fef3c862a",
            "responseId": "63c0e8f1914f84fe6f1e5de7",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5de70.37627567534598527",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.154Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "xavier.pourchel@veolia.com",
            "CustomerContactEmail": "xavier.pourchel@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POURCHEL",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0603492814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE SAINT OMER (STEP)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002994",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Reponse rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5e50",
        "extendedResponse": {
            "_id": "63ce3143278650d23f436764",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5e500.3720734582252081",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Depuis 30 ans c'est toujours au top malgré les façons de travail qui ont énormément changés",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bernard.faux@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "014b8e73-faa1-4be5-907d-6759a2352978",
            "responseId": "63c0e8f1914f84fe6f1e5e50",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5e500.3720734582252081",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Depuis 30 ans c'est toujours au top malgré les façons de travail qui ont énormément changés",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.175Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "bernard.faux@veolia.com",
            "CustomerContactEmail": "bernard.faux@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FAUX",
            "CustomerContactFirstName": "BERNARD",
            "CustomerContactMobilePhone": "0611260748",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU MARMANDE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003168",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Depuis 30 ans c'est toujours au top malgré les façons de travail qui ont énormément changés",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5e59",
        "extendedResponse": {
            "_id": "63ce3143278650d23f436765",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5e590.06410779779786857",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la franchise, la rapidité de prise de rdv,la rapidité de l'envoie des documents",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane@ccc35.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8edb2116-bb07-4834-92f4-1f2b29d5c88f",
            "responseId": "63c0e8f1914f84fe6f1e5e59",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5e590.06410779779786857",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la franchise, la rapidité de prise de rendez-vous,la rapidité de l'envoie des documents",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.182Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "stephane@ccc35.com",
            "CustomerContactEmail": "stephane@ccc35.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HIRET",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Compagnie Chauffage Climatisation",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Simon",
            "XylemContactedPersonName": "CLEMENT",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la franchise, la rapidité de prise de rdv,la rapidité de l'envoie des documents",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5e76",
        "extendedResponse": {
            "_id": "63ce3144278650d23f436766",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5e760.9469635175873807",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bon échange. Proposition pour mettre en place une stratégie commerciale avec un ancien client que nous avions en commun. Proposition de partenariat.\n",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emiliebranjonneau@mei-services.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dcec0171-cb43-4a14-9530-49701e945fe4",
            "responseId": "63c0e8f1914f84fe6f1e5e76",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5e760.9469635175873807",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bon échange. Proposition pour mettre en place une stratégie commerciale avec un ancien client que nous avions en commun. Proposition de partenariat.\n",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.201Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "emiliebranjonneau@mei-services.fr",
            "CustomerContactEmail": "emiliebranjonneau@mei-services.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "BRANJONNEAU",
            "CustomerContactFirstName": "Emilie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MEI SERVICES",
            "CustomerCompanyGroupName": "MEI SERVICES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Mehmet Akif",
            "XylemContactedPersonName": "TOKER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bon échange. Proposition pour mettre en place une stratégie commerciale avec un ancien client que nous avions en commun. Proposition de partenariat.\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5ea8",
        "extendedResponse": {
            "_id": "63ce3144278650d23f436767",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5ea80.0476311362373405",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et professionnalisme. ",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexandre.gene@cqs-caraibes.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "b8140d29-703c-4f0d-a6f7-d231d26d1cf5",
            "responseId": "63c0e8f1914f84fe6f1e5ea8",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5ea80.0476311362373405",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et professionnalisme. ",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.256Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "alexandre.gene@cqs-caraibes.com",
            "CustomerContactEmail": "alexandre.gene@cqs-caraibes.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GENE",
            "CustomerContactFirstName": "Alexandre",
            "CustomerContactMobilePhone": "0690310501",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CARAIBES QUALITE SERVICE (VINCI)",
            "CustomerCompanyGroupName": "CARAIBES QUALITE SERVICE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003341",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et professionnalisme. ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Toujours plus de remise partenaire :) "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5ead",
        "extendedResponse": {
            "_id": "63ce3144278650d23f436768",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5ead0.36705382339093817",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "les retours de devis sont assez rapides  , ",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yvan.leclerc@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d0ec8fec-ad2b-473d-844b-46b03b8f0285",
            "responseId": "63c0e8f1914f84fe6f1e5ead",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5ead0.36705382339093817",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "les retours de devis sont assez rapides , ",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.262Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "yvan.leclerc@veolia.com",
            "CustomerContactEmail": "yvan.leclerc@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LECLERC",
            "CustomerContactFirstName": "YVAN",
            "CustomerContactMobilePhone": "0778514026",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003367",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "les retours de devis sont assez rapides  , ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "continuez ainsi "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5eb2",
        "extendedResponse": {
            "_id": "63ce3145278650d23f436769",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5eb20.8388333951503553",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+ =  la réactivité ",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.machet@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "3c7b01e8-aa06-4ddc-b06b-f7eac78acaca",
            "responseId": "63c0e8f1914f84fe6f1e5eb2",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5eb20.8388333951503553",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+ = la réactivité ",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.271Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "eric.machet@veolia.com",
            "CustomerContactEmail": "eric.machet@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MACHET",
            "CustomerContactFirstName": "ERIC",
            "CustomerContactMobilePhone": "0610699761",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003380",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+ =  la réactivité ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1c60bdb3ef88f8108",
        "extendedResponse": {
            "_id": "63ce3145278650d23f43676a",
            "submittedBody": {
                "id": "63c0e8f1c60bdb3ef88f81080.044060885930113924",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Moins plu : Votre questionnaire ! Et je ne peux pas me désabonner !\nMerci d'arrêter ces envois !\n",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0608216428"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "c4a8f20f-9d84-404e-b6a4-5391cb8a4d82",
            "responseId": "63c0e8f1c60bdb3ef88f8108",
            "receivedResponse": {
                "id": "63c0e8f1c60bdb3ef88f81080.044060885930113924",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Moins plu : Votre questionnaire ! Et je ne peux pas me désabonner !\nMerci d'arrêter ces envois !\n",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.277Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "0608216428",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "David HAMARD",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0608216428",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR (TVX NEUFS)",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000441995",
            "XylemReference": "195923",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Moins plu : Votre questionnaire ! Et je ne peux pas me désabonner !\nMerci d'arrêter ces envois !\n",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1c60bdb3ef88f8113",
        "extendedResponse": {
            "_id": "63ce3145278650d23f43676b",
            "submittedBody": {
                "id": "63c0e8f1c60bdb3ef88f81130.4291654453408964",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0685079734"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6e521ab2-4076-4307-84ef-5dd64fc29426",
            "responseId": "63c0e8f1c60bdb3ef88f8113",
            "receivedResponse": {
                "id": "63c0e8f1c60bdb3ef88f81130.4291654453408964",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.294Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "0685079734",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BAUMANN JOEL",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0685079734",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Colmarienne des Eaux - SUEZ Environnement",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "34690",
            "XylemReference": "196082",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5ec2",
        "extendedResponse": {
            "_id": "63ce3146278650d23f43676c",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5ec20.0519449020906384",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité\nCordialement ",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dominique.rigaux@o2bray.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "791e7a4e-3201-4e3c-8725-0504de5e38f3",
            "responseId": "63c0e8f1914f84fe6f1e5ec2",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5ec20.0519449020906384",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité\nCordialement ",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.294Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "dominique.rigaux@o2bray.fr",
            "CustomerContactEmail": "dominique.rigaux@o2bray.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RIGAUX",
            "CustomerContactFirstName": "DOMINIQUE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIAEPA O2 BRAY",
            "CustomerCompanyGroupName": "S.I.A.E.P.A. O2 BRAY",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003452",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité\nCordialement ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5ed2",
        "extendedResponse": {
            "_id": "63ce3146278650d23f43676d",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5ed20.023877787660930405",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les mots me manquent pour expliquer à quel point le commercial est talentueux et beau gosse. D’ailleurs je suis secrètement jaloux… ne lui répétez pas ça svp",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "quentin.delahaye@tereva.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cd81600e-e468-411f-8b58-feae781f27d9",
            "responseId": "63c0e8f1914f84fe6f1e5ed2",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5ed20.023877787660930405",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les mots me manquent pour expliquer à quel point le commercial est talentueux et beau gosse. D ailleurs je suis secrètement jaloux ne lui répétez pas ça svp",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.311Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "quentin.delahaye@tereva.fr",
            "CustomerContactEmail": "quentin.delahaye@tereva.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELAHAYE",
            "CustomerContactFirstName": "Quentin",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREVA BRIVE",
            "CustomerCompanyGroupName": "MBE (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223003103",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les mots me manquent pour expliquer à quel point le commercial est talentueux et beau gosse. D’ailleurs je suis secrètement jaloux… ne lui répétez pas ça svp",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5f32",
        "extendedResponse": {
            "_id": "63ce3146278650d23f43676e",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5f320.47545056925398876",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Votre réactivité",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pierre-yves.gouillias@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "84cdbdc0-2aed-4d6f-ab00-e9196949503e",
            "responseId": "63c0e8f1914f84fe6f1e5f32",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5f320.47545056925398876",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Votre réactivité",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.385Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "pierre-yves.gouillias@veolia.com",
            "CustomerContactEmail": "pierre-yves.gouillias@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GOUILLIAS",
            "CustomerContactFirstName": "Pierre-Yves",
            "CustomerContactMobilePhone": "0619916365",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "APG STEP LIVERY",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003179",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Votre réactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e5fbc",
        "extendedResponse": {
            "_id": "63ce3147278650d23f43676f",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e5fbc0.8473132435506205",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-louis.euvrard@saur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e106df4f-7cb9-4752-8ebc-4593e0717120",
            "responseId": "63c0e8f1914f84fe6f1e5fbc",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e5fbc0.8473132435506205",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.428Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "jean-louis.euvrard@saur.fr",
            "CustomerContactEmail": "jean-louis.euvrard@saur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "EUVRARD",
            "CustomerContactFirstName": "JEAN LOUIS",
            "CustomerContactMobilePhone": "0670310042",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003221",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e6072",
        "extendedResponse": {
            "_id": "63ce3147278650d23f436770",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e60720.8140328457711932",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "PRIX  DELAI ET REPONSE RAPIDE",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pregnier@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8c483a6a-d4ea-43d9-9b6d-76518e25579c",
            "responseId": "63c0e8f1914f84fe6f1e6072",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e60720.8140328457711932",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "PRIX DELAI ET RÉPONSE RAPIDE",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.530Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "pregnier@lri-sodime.com",
            "CustomerContactEmail": "pregnier@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REGNIER",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003311",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "PRIX  DELAI ET REPONSE RAPIDE",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e6074",
        "extendedResponse": {
            "_id": "63ce3147278650d23f436771",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e60740.832970484015223",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide mais le rattachement par rapport à une entité peut poser un problème d'identification chez XYLEM et donc un retard sur une demande d'offre",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denis.leiros@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "051805fb-6d11-4803-b3df-c781d930015f",
            "responseId": "63c0e8f1914f84fe6f1e6074",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e60740.832970484015223",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide mais le rattachement par rapport à une entité peut poser un problème d'identification chez XYLEM et donc un retard sur une demande d'offre",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.535Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "denis.leiros@suez.com",
            "CustomerContactEmail": "denis.leiros@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEIROS",
            "CustomerContactFirstName": "DENIS",
            "CustomerContactMobilePhone": "0632311684",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003310",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide mais le rattachement par rapport à une entité peut poser un problème d'identification chez XYLEM et donc un retard sur une demande d'offre",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Transmettre un dossier entre service plus rapidement"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e60ec",
        "extendedResponse": {
            "_id": "63ce3148278650d23f436772",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e60ec0.9407350968357957",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le délai de réponse réactif et le devis sur aecss.",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gesnouf@g-e-d.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "17bc3c55-a66c-4f8b-bceb-bcfb3340d26f",
            "responseId": "63c0e8f1914f84fe6f1e60ec",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e60ec0.9407350968357957",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le délai de réponse réactif et le devis sur aecss.",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.614Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "gesnouf@g-e-d.fr",
            "CustomerContactEmail": "gesnouf@g-e-d.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ESNOUF",
            "CustomerContactFirstName": "Guy",
            "CustomerContactMobilePhone": "0673288392",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GED GAILLON",
            "CustomerCompanyGroupName": "GED",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223003388",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le délai de réponse réactif et le devis sur aecss.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e60f6",
        "extendedResponse": {
            "_id": "63ce3148278650d23f436773",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e60f60.3768116559934218",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commercial très réactif et à l'écoute ",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "anthony.charton@carct.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "326a2c39-69e5-4fae-8e3a-57810d5f71ed",
            "responseId": "63c0e8f1914f84fe6f1e60f6",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e60f60.3768116559934218",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commercial très réactif et à l'écoute ",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.630Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "anthony.charton@carct.fr",
            "CustomerContactEmail": "anthony.charton@carct.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHARTON",
            "CustomerContactFirstName": "Anthony",
            "CustomerContactMobilePhone": "0785326208",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "C.A.R.C.T.",
            "CustomerCompanyGroupName": "CA REGION DE CHATEAU THIERRY",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003397",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commercial très réactif et à l'écoute ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e611a",
        "extendedResponse": {
            "_id": "63ce3148278650d23f436774",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e611a0.5962058343029868",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "positif : Réponse assez rapide\nNégatif : prix prohibitif",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jeanfrancois.durfort@guedo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "29284a3f-43dd-46fe-89b0-834aea097f8e",
            "responseId": "63c0e8f1914f84fe6f1e611a",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e611a0.5962058343029868",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "positif : Réponse assez rapide\nNégatif : prix prohibitif",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "delai.pos",
                "ressenti.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.694Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "jeanfrancois.durfort@guedo.fr",
            "CustomerContactEmail": "jeanfrancois.durfort@guedo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DURFORT",
            "CustomerContactFirstName": "Jean François",
            "CustomerContactMobilePhone": "0614180124",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GUEDO",
            "CustomerCompanyGroupName": "SARL GUEDO",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223002546",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "positif : Réponse assez rapide\nNégatif : prix prohibitif",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "revoir vos hausse injustifiées"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c0e8f1914f84fe6f1e6128",
        "extendedResponse": {
            "_id": "63ce3148278650d23f436775",
            "submittedBody": {
                "id": "63c0e8f1914f84fe6f1e61280.901073112375449",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité et sérieux sur les offres de prix, Disponibilité des pièces, SAV réactif. ",
                "creationDate": "2023-01-13T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.pagnoux@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "32f031d1-de97-4530-b642-43a608255b5f",
            "responseId": "63c0e8f1914f84fe6f1e6128",
            "receivedResponse": {
                "id": "63c0e8f1914f84fe6f1e61280.901073112375449",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité et sérieux sur les offres de prix, Disponibilité des pièces, SAV réactif. ",
                "creationDate": "2023-01-13T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "prix.autre",
                "dispo.pos",
                "delai.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-13T05:15:29.711Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-13_06.15.08",
        "integration": {
            "ContactKey": "frederic.pagnoux@veolia.com",
            "CustomerContactEmail": "frederic.pagnoux@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PAGNOUX",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0623937631",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223003482",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité et sérieux sur les offres de prix, Disponibilité des pièces, SAV réactif. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c23a71c60bdb3ef89afbcb",
        "extendedResponse": {
            "_id": "63ce3149278650d23f436776",
            "submittedBody": {
                "id": "63c23a71c60bdb3ef89afbcb0.8187356812793374",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonne entente et bonne compréhension de nos problématiques\nj'ai de bon rapport en général avec notre commercial  ",
                "creationDate": "2023-01-14T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "occipompes.lg@yahoo.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f4d54735-acc7-4727-8808-00aecfb19257",
            "responseId": "63c23a71c60bdb3ef89afbcb",
            "receivedResponse": {
                "id": "63c23a71c60bdb3ef89afbcb0.8187356812793374",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonne entente et bonne compréhension de nos problématiques\nj'ai de bon rapport en général avec notre commercial ",
                "creationDate": "2023-01-14T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-14T05:15:29.352Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-14_06.15.08",
        "integration": {
            "ContactKey": "occipompes.lg@yahoo.com",
            "CustomerContactEmail": "occipompes.lg@yahoo.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GENOVESE",
            "CustomerContactFirstName": "Ludovic",
            "CustomerContactMobilePhone": "0612207156",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OCCIPOMPES",
            "CustomerCompanyGroupName": "OCCIPOMPES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Philippe",
            "XylemContactedPersonName": "CARRARA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonne entente et bonne compréhension de nos problématiques\nj'ai de bon rapport en général avec notre commercial  ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c23a71c60bdb3ef89afbd1",
        "extendedResponse": {
            "_id": "63ce3149278650d23f436777",
            "submittedBody": {
                "id": "63c23a71c60bdb3ef89afbd10.1274260392728399",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ponctualité, professionnalisme, \nConnaissance de son travail, une perle!",
                "creationDate": "2023-01-14T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrice.uguen@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "84985317-b733-4565-a1e3-bebd4b09b5da",
            "responseId": "63c23a71c60bdb3ef89afbd1",
            "receivedResponse": {
                "id": "63c23a71c60bdb3ef89afbd10.1274260392728399",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ponctualité, professionnalisme, \nConnaissance de son travail, une perle!",
                "creationDate": "2023-01-14T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-14T05:15:29.360Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-14_06.15.08",
        "integration": {
            "ContactKey": "patrice.uguen@suez.com",
            "CustomerContactEmail": "patrice.uguen@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "UGUEN",
            "CustomerContactFirstName": "Patrice",
            "CustomerContactMobilePhone": "0672823322",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Dominique",
            "XylemContactedPersonName": "KEROUANTON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ponctualité, professionnalisme, \nConnaissance de son travail, une perle!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c23a7268718f61283617d1",
        "extendedResponse": {
            "_id": "63ce3149278650d23f436778",
            "submittedBody": {
                "id": "63c23a7268718f61283617d10.8145545877060394",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de la réponse. Une entreprise qui connaît ses produits.",
                "creationDate": "2023-01-14T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mathieu.layrac@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "43f1708b-ead3-4f9d-9851-29643b6e92a3",
            "responseId": "63c23a7268718f61283617d1",
            "receivedResponse": {
                "id": "63c23a7268718f61283617d10.8145545877060394",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de la réponse. Une entreprise qui connaît ses produits.",
                "creationDate": "2023-01-14T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-14T05:15:30.236Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-14_06.15.08",
        "integration": {
            "ContactKey": "mathieu.layrac@suez.com",
            "CustomerContactEmail": "mathieu.layrac@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAYRAC",
            "CustomerContactFirstName": "MATHIEU",
            "CustomerContactMobilePhone": "0677513362",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ 12",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003529",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de la réponse. Une entreprise qui connaît ses produits.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c23a7268718f61283618d5",
        "extendedResponse": {
            "_id": "63ce314a278650d23f436779",
            "submittedBody": {
                "id": "63c23a7268718f61283618d50.6246051117745888",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour j'ai apprécié votre réactivité, vous vous êtes bien amélioré ces derniers tps\nbravo\n",
                "creationDate": "2023-01-14T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "benoit.swaenepoel@groupe-sarp.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6ea5d5d0-d583-4123-b0a7-3fe73dfd48b8",
            "responseId": "63c23a7268718f61283618d5",
            "receivedResponse": {
                "id": "63c23a7268718f61283618d50.6246051117745888",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour j'ai apprécié votre réactivité, vous vous êtes bien amélioré ces derniers temps\nbravo\n",
                "creationDate": "2023-01-14T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-14T05:15:30.490Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-14_06.15.08",
        "integration": {
            "ContactKey": "benoit.swaenepoel@groupe-sarp.com",
            "CustomerContactEmail": "benoit.swaenepoel@groupe-sarp.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SWAENEPOEL",
            "CustomerContactFirstName": "BENOIT",
            "CustomerContactMobilePhone": "0619820507",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BACHELET-BONNEFOND SAS",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rachid",
            "XylemSalespeopleName": "ABDELKRIM",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003575",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour j'ai apprécié votre réactivité, vous vous êtes bien amélioré ces derniers tps\nbravo\n",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "si vous pouviez mettre la doc de la pompe ou de la pièce commandée ce serait mieux cela me ferait gagner beaucoup de tps car je suis obligé de rechercher pour la présenter à mon client et ce n'est pas tjrs simple de la trouver "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c23a7268718f61283619ba",
        "extendedResponse": {
            "_id": "63ce314a278650d23f43677a",
            "submittedBody": {
                "id": "63c23a7268718f61283619ba0.842733931200131",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité suivi des fiches techniques ",
                "creationDate": "2023-01-14T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexandre.gene@cqs-caraibes.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "1e310966-9556-41af-870d-5e505f4d5e25",
            "responseId": "63c23a7268718f61283619ba",
            "receivedResponse": {
                "id": "63c23a7268718f61283619ba0.842733931200131",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité suivi des fiches techniques ",
                "creationDate": "2023-01-14T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-14T05:15:30.612Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-14_06.15.08",
        "integration": {
            "ContactKey": "alexandre.gene@cqs-caraibes.com",
            "CustomerContactEmail": "alexandre.gene@cqs-caraibes.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GENE",
            "CustomerContactFirstName": "Alexandre",
            "CustomerContactMobilePhone": "0690310501",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CARAIBES QUALITE SERVICE (VINCI)",
            "CustomerCompanyGroupName": "CARAIBES QUALITE SERVICE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE DT",
            "XylemContactedPersonFirstName": "Reynald",
            "XylemContactedPersonName": "MEGRET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003665",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité suivi des fiches techniques ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c23a7268718f61283619d4",
        "extendedResponse": {
            "_id": "63ce314a278650d23f43677b",
            "submittedBody": {
                "id": "63c23a7268718f61283619d40.7219626831535497",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J'ai apprécié la réactivité ",
                "creationDate": "2023-01-14T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.karbowy@rubix.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f57d3cae-2bf3-4423-ac5c-04110e6e2f5e",
            "responseId": "63c23a7268718f61283619d4",
            "receivedResponse": {
                "id": "63c23a7268718f61283619d40.7219626831535497",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J'ai apprécié la réactivité ",
                "creationDate": "2023-01-14T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-14T05:15:30.647Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-14_06.15.08",
        "integration": {
            "ContactKey": "alain.karbowy@rubix.com",
            "CustomerContactEmail": "alain.karbowy@rubix.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "KARBOWY",
            "CustomerContactFirstName": "ALAIN",
            "CustomerContactMobilePhone": "0614357811",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RUBIX - OREXAD",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003694",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J'ai apprécié la réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef4416e472f6e99342d",
        "extendedResponse": {
            "_id": "63ce314a278650d23f43677c",
            "submittedBody": {
                "id": "63c62ef4416e472f6e99342d0.43852198843255286",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité ",
                "creationDate": "2023-01-17T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact.ckservices@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6cc7335a-7e74-4abb-abe8-0112bf1f0c06",
            "responseId": "63c62ef4416e472f6e99342d",
            "receivedResponse": {
                "id": "63c62ef4416e472f6e99342d0.43852198843255286",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité ",
                "creationDate": "2023-01-17T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-17T05:15:32.517Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "contact.ckservices@gmail.com",
            "CustomerContactEmail": "contact.ckservices@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GENETAY",
            "CustomerContactFirstName": "CHRISTOPHE",
            "CustomerContactMobilePhone": "0781667980",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CK SERVICES",
            "CustomerCompanyGroupName": "CK SERVICES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Mickael",
            "XylemContactedPersonName": "AUBRY",
            "XylemContactedPersonRole": "TCS Location",
            "CustomerReference": "BPA Mr GENETAY",
            "XylemReference": "749380",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef4296cd45fee72844b",
        "extendedResponse": {
            "_id": "63ce314b278650d23f43677d",
            "submittedBody": {
                "id": "63c62ef4296cd45fee72844b0.05053886182414158",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très réactif ",
                "creationDate": "2023-01-17T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "daoud.kenniche@ccpi-idf.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f4ab8e1f-5ec7-4868-8667-57f7e725695c",
            "responseId": "63c62ef4296cd45fee72844b",
            "receivedResponse": {
                "id": "63c62ef4296cd45fee72844b0.05053886182414158",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très réactif ",
                "creationDate": "2023-01-17T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-17T05:15:32.529Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "daoud.kenniche@ccpi-idf.fr",
            "CustomerContactEmail": "daoud.kenniche@ccpi-idf.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "KENNICHE",
            "CustomerContactFirstName": "DAOUD",
            "CustomerContactMobilePhone": "0766280330",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CCPI",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Sarah",
            "XylemContactedPersonName": "NERON",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très réactif ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef4296cd45fee7284c6",
        "extendedResponse": {
            "_id": "63ce314b278650d23f43677e",
            "submittedBody": {
                "id": "63c62ef4296cd45fee7284c60.9633682417581055",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité, le professionnalisme... ",
                "creationDate": "2023-01-17T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-luc.zieba@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "09c53e25-a5da-46dc-8f71-a7caf2bc5c14",
            "responseId": "63c62ef4296cd45fee7284c6",
            "receivedResponse": {
                "id": "63c62ef4296cd45fee7284c60.9633682417581055",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité, le professionnalisme... ",
                "creationDate": "2023-01-17T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-17T05:15:32.553Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "jean-luc.zieba@veolia.com",
            "CustomerContactEmail": "jean-luc.zieba@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ZIEBA",
            "CustomerContactFirstName": "Jean Luc",
            "CustomerContactMobilePhone": "0615417389",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Flavio",
            "XylemContactedPersonName": "MEDARDONI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité, le professionnalisme... ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef4296cd45fee7284f6",
        "extendedResponse": {
            "_id": "63ce314b278650d23f43677f",
            "submittedBody": {
                "id": "63c62ef4296cd45fee7284f60.7538502489105172",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'écoute et prise en compte de nos attentes. Reste à définir l'amélioration demandé sur le sujet support technique \"terrain\".",
                "creationDate": "2023-01-17T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.blanc@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6a4ddd8d-5cca-4a47-bd87-09e6c234c1a0",
            "responseId": "63c62ef4296cd45fee7284f6",
            "receivedResponse": {
                "id": "63c62ef4296cd45fee7284f60.7538502489105172",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'écoute et prise en compte de nos attentes. Reste à définir l'amélioration demandé sur le sujet support technique \"terrain\".",
                "creationDate": "2023-01-17T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-17T05:15:32.602Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "frederic.blanc@veolia.com",
            "CustomerContactEmail": "frederic.blanc@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BLANC",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0676746913",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SRDE - VEOLIA EAU (STEP ALES)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Sonia",
            "XylemContactedPersonName": "HUBERT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'écoute et prise en compte de nos attentes. Reste à définir l'amélioration demandé sur le sujet support technique \"terrain\".",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef4296cd45fee728555",
        "extendedResponse": {
            "_id": "63ce314c278650d23f436780",
            "submittedBody": {
                "id": "63c62ef4296cd45fee7285550.769508236374346",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "livraison dans les temps annoncé mais pas assez rapide",
                "creationDate": "2023-01-17T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@moteur-pinson.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "1f6a5f33-b853-419f-9fdc-731600c0f380",
            "responseId": "63c62ef4296cd45fee728555",
            "receivedResponse": {
                "id": "63c62ef4296cd45fee7285550.769508236374346",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "livraison dans les temps annoncé mais pas assez rapide",
                "creationDate": "2023-01-17T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-17T05:15:32.674Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "contact@moteur-pinson.com",
            "CustomerContactEmail": "contact@moteur-pinson.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "PROVOST",
            "CustomerContactFirstName": "Christine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PINSON SARL",
            "CustomerCompanyGroupName": "ETS PINSON",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223004155",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "livraison dans les temps annoncé mais pas assez rapide",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "livraison plus rapide des pièces en stock"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef4296cd45fee728567",
        "extendedResponse": {
            "_id": "63ce314c278650d23f436781",
            "submittedBody": {
                "id": "63c62ef4296cd45fee7285670.28324953303877876",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réception du devis ! 5 minutes ! / La personne au téléphone très pro et efficace ! ",
                "creationDate": "2023-01-17T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "florian.servigne@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5876d251-76a9-4053-9dad-50c72b4818fc",
            "responseId": "63c62ef4296cd45fee728567",
            "receivedResponse": {
                "id": "63c62ef4296cd45fee7285670.28324953303877876",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réception du devis ! 5 minutes ! / La personne au téléphone très professionnel et efficace ! ",
                "creationDate": "2023-01-17T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-17T05:15:32.695Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "florian.servigne@veolia.com",
            "CustomerContactEmail": "florian.servigne@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SERVIGNE",
            "CustomerContactFirstName": "Florian",
            "CustomerContactMobilePhone": "0624307226",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004185",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réception du devis ! 5 minutes ! / La personne au téléphone très pro et efficace ! ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef4296cd45fee728630",
        "extendedResponse": {
            "_id": "63ce314c278650d23f436782",
            "submittedBody": {
                "id": "63c62ef4296cd45fee7286300.7649094237352456",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de réponse",
                "creationDate": "2023-01-17T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-paul.tabarly@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "26676650-85ef-479d-9d42-d503bb9b60bb",
            "responseId": "63c62ef4296cd45fee728630",
            "receivedResponse": {
                "id": "63c62ef4296cd45fee7286300.7649094237352456",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de réponse",
                "creationDate": "2023-01-17T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-17T05:15:32.866Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "jean-paul.tabarly@veolia.com",
            "CustomerContactEmail": "jean-paul.tabarly@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TABARLY",
            "CustomerContactFirstName": "JEAN-PAUL",
            "CustomerContactMobilePhone": "0611890252",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223003957",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef5296cd45fee7286e0",
        "extendedResponse": {
            "_id": "63ce314d278650d23f436783",
            "submittedBody": {
                "id": "63c62ef5296cd45fee7286e00.4003961001618439",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a plu s'est l écouté du commercial super 👍",
                "creationDate": "2023-01-17T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.canale@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a6f79160-a86f-4343-923a-fe01e7514eaf",
            "responseId": "63c62ef5296cd45fee7286e0",
            "receivedResponse": {
                "id": "63c62ef5296cd45fee7286e00.4003961001618439",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a plu s'est l écouté du commercial super ",
                "creationDate": "2023-01-17T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-17T05:15:33.020Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "christophe.canale@dalkia.fr",
            "CustomerContactEmail": "christophe.canale@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CANALE",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0614321830",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA",
            "CustomerCompanyGroupName": "DALKIA FRANCE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Guillaume",
            "XylemContactedPersonName": "GEYER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223004026",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a plu s'est l écouté du commercial super 👍",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef5296cd45fee72877a",
        "extendedResponse": {
            "_id": "63ce314d278650d23f436784",
            "submittedBody": {
                "id": "63c62ef5296cd45fee72877a0.6143989824201093",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "les + \ndevis clair et bien réalisé  \n\nles - \ndélai de réponse un peu long .\nprix ? \n",
                "creationDate": "2023-01-17T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bernard.pellechia@groupe-enki.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "21da8034-c9dc-44e6-afbf-2809a7039df7",
            "responseId": "63c62ef5296cd45fee72877a",
            "receivedResponse": {
                "id": "63c62ef5296cd45fee72877a0.6143989824201093",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "les + \ndevis clair et bien réalisé \n\nles - \ndélai de réponse un peu long .\nprix ? \n",
                "creationDate": "2023-01-17T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-17T05:15:33.209Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "bernard.pellechia@groupe-enki.com",
            "CustomerContactEmail": "bernard.pellechia@groupe-enki.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PELLECHIA",
            "CustomerContactFirstName": "Bernard",
            "CustomerContactMobilePhone": "0622086312",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HMT SERVICE",
            "CustomerCompanyGroupName": "GROUPE ENKI - ATELIER MATHIEU",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Joachim",
            "XylemContactedPersonName": "GAUDON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004144",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "les + \ndevis clair et bien réalisé  \n\nles - \ndélai de réponse un peu long .\nprix ? \n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef5296cd45fee728789",
        "extendedResponse": {
            "_id": "63ce314d278650d23f436785",
            "submittedBody": {
                "id": "63c62ef5296cd45fee7287890.1510244446707234",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Aucun probleme chez xylem, demande de devis très rapide et très précis , interlocuteur gilbert jorge ou elie bayet tres professionnel. ",
                "creationDate": "2023-01-17T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "franck.philibert@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e1bf7726-89c2-4a80-af68-6d9ebaeef7ab",
            "responseId": "63c62ef5296cd45fee728789",
            "receivedResponse": {
                "id": "63c62ef5296cd45fee7287890.1510244446707234",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Aucun problème chez xylem, demande de devis très rapide et très précis , interlocuteur gilbert jorge ou elie bayet très professionnel. ",
                "creationDate": "2023-01-17T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-17T05:15:33.229Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "franck.philibert@veolia.com",
            "CustomerContactEmail": "franck.philibert@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PHILIBERT",
            "CustomerContactFirstName": "Franck",
            "CustomerContactMobilePhone": "0621552577",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA - EAU  - CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223004164",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Aucun probleme chez xylem, demande de devis très rapide et très précis , interlocuteur gilbert jorge ou elie bayet tres professionnel. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c62ef5296cd45fee72878c",
        "extendedResponse": {
            "_id": "63ce314d278650d23f436786",
            "submittedBody": {
                "id": "63c62ef5296cd45fee72878c0.4386723989960162",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "les offres sont facilement copiables , c est précis , on comprend tout du premier coup d’œil ,\nle prix sont cohérents et vous répondez rapidement , rien a redire!\n",
                "creationDate": "2023-01-17T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "briceraffini@cgr-robinetterie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fa7d8ed7-2044-4b90-9d4b-d17832a84c52",
            "responseId": "63c62ef5296cd45fee72878c",
            "receivedResponse": {
                "id": "63c62ef5296cd45fee72878c0.4386723989960162",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "les offres sont facilement copiables , c est précis , on comprend tout du premier coup d œil ,\nle prix sont cohérents et vous répondez rapidement , rien a redire!\n",
                "creationDate": "2023-01-17T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "prix.autre",
                "delai.pos",
                "competence.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-17T05:15:33.234Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-17_06.15.10",
        "integration": {
            "ContactKey": "briceraffini@cgr-robinetterie.fr",
            "CustomerContactEmail": "briceraffini@cgr-robinetterie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RAFFINI",
            "CustomerContactFirstName": "Brice",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CGR",
            "CustomerCompanyGroupName": "CGR ROBINETTERIE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004172",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "les offres sont facilement copiables , c est précis , on comprend tout du premier coup d’œil ,\nle prix sont cohérents et vous répondez rapidement , rien a redire!\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807368718f61286a9571",
        "extendedResponse": {
            "_id": "63ce314e278650d23f436787",
            "submittedBody": {
                "id": "63c7807368718f61286a95710.9504367884558464",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne  réactivité dans les réponses aux différentes demandes de devis.\n\nPar contre produits de mauvaises qualités dans le domaine des petits surpresseurs(pompes, ballons).\n",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.serrand@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "2ab1243d-34fb-4e3b-8f4d-305fa6be6dfb",
            "responseId": "63c7807368718f61286a9571",
            "receivedResponse": {
                "id": "63c7807368718f61286a95710.9504367884558464",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité dans les réponses aux différentes demandes de devis.\n\nPar contre produits de mauvaises qualités dans le domaine des petits surpresseurs(pompes, ballons).\n",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.neg",
                                    "label": "Quality (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "qualite.neg",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "qualite.neg",
                    "label": "Quality (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.596Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "stephane.serrand@veolia.com",
            "CustomerContactEmail": "stephane.serrand@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SERRAND",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "0614263342",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004209",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne  réactivité dans les réponses aux différentes demandes de devis.\n\nPar contre produits de mauvaises qualités dans le domaine des petits surpresseurs(pompes, ballons).\n",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c78073296cd45fee33bd40",
        "extendedResponse": {
            "_id": "63ce314e278650d23f436788",
            "submittedBody": {
                "id": "63c78073296cd45fee33bd400.994865476002414",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Votre réactivité et vos conseils sont très appréciables . ",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aymeric.trisson@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e4051a86-8884-4619-a67f-1a7a926e13be",
            "responseId": "63c78073296cd45fee33bd40",
            "receivedResponse": {
                "id": "63c78073296cd45fee33bd400.994865476002414",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Votre réactivité et vos conseils sont très appréciables . ",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.600Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "aymeric.trisson@veolia.com",
            "CustomerContactEmail": "aymeric.trisson@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TRISSON",
            "CustomerContactFirstName": "aymeric",
            "CustomerContactMobilePhone": "0626132201",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Nathalie",
            "XylemContactedPersonName": "STOESSEL",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "CZZ3649902",
            "XylemReference": "2RF040802",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Votre réactivité et vos conseils sont très appréciables . ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807368718f61286a95a8",
        "extendedResponse": {
            "_id": "63ce314e278650d23f436789",
            "submittedBody": {
                "id": "63c7807368718f61286a95a80.5195365589462171",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis reçu très rapidement et équipements proposés en option ",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "antoine.thomas@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6d32d564-d61b-457f-9471-5565c5dd4c02",
            "responseId": "63c7807368718f61286a95a8",
            "receivedResponse": {
                "id": "63c7807368718f61286a95a80.5195365589462171",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis reçu très rapidement et équipements proposés en option ",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.605Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "antoine.thomas@veolia.com",
            "CustomerContactEmail": "antoine.thomas@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Antoine",
            "CustomerContactMobilePhone": "0603500636",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU CENTRE LITTORAL",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004302",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis reçu très rapidement et équipements proposés en option ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807368718f61286a962b",
        "extendedResponse": {
            "_id": "63ce314f278650d23f43678a",
            "submittedBody": {
                "id": "63c7807368718f61286a962b0.6089346261229014",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Notre entretien ",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thierry.criquioche@saint-gobain.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cc0a47c9-b8b4-4cc3-8742-709b17c2cc89",
            "responseId": "63c7807368718f61286a962b",
            "receivedResponse": {
                "id": "63c7807368718f61286a962b0.6089346261229014",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Notre entretien ",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.635Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "thierry.criquioche@saint-gobain.com",
            "CustomerContactEmail": "thierry.criquioche@saint-gobain.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CRIQUIOCHE",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "0610626275",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DSC",
            "CustomerCompanyGroupName": "DSC - Distribution Sanitaire Chauffage",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Clement",
            "XylemContactedPersonName": "FAIVRE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Notre entretien ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807368718f61286a963b",
        "extendedResponse": {
            "_id": "63ce314f278650d23f43678b",
            "submittedBody": {
                "id": "63c7807368718f61286a963b0.9840619438474383",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et documents transmis",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dominique.debray@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "1b717924-015c-4326-a19b-b2ac4c4cdddc",
            "responseId": "63c7807368718f61286a963b",
            "receivedResponse": {
                "id": "63c7807368718f61286a963b0.9840619438474383",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et documents transmis",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.648Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "dominique.debray@suez.com",
            "CustomerContactEmail": "dominique.debray@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEBRAY",
            "CustomerContactFirstName": "DOMINIQUE",
            "CustomerContactMobilePhone": "0687866382",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004375",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et documents transmis",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807368718f61286a9673",
        "extendedResponse": {
            "_id": "63ce314f278650d23f43678c",
            "submittedBody": {
                "id": "63c7807368718f61286a96730.016548322609956534",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Demande envoyé le 6 janvier au commercial reçu le 17 janvier. Pour 1 simple alarme.... ça fait un peu long",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.robert@materiauxtp.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "51551f39-b56a-4df9-9d08-09439e0345c3",
            "responseId": "63c7807368718f61286a9673",
            "receivedResponse": {
                "id": "63c7807368718f61286a96730.016548322609956534",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Demande envoyé le 6 janvier au commercial reçu le 17 janvier. Pour 1 simple alarme.... ça fait un peu long",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.740Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "s.robert@materiauxtp.fr",
            "CustomerContactEmail": "s.robert@materiauxtp.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROBERT",
            "CustomerContactFirstName": "Sandrine",
            "CustomerContactMobilePhone": "0665704125",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MTP SAINT PAUL LES DAX 40",
            "CustomerCompanyGroupName": "MTP",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004566",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Demande envoyé le 6 janvier au commercial reçu le 17 janvier. Pour 1 simple alarme.... ça fait un peu long",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c78073296cd45fee33c588",
        "extendedResponse": {
            "_id": "63ce314f278650d23f43678d",
            "submittedBody": {
                "id": "63c78073296cd45fee33c5880.920574674610739",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ras",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0610256838"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2f7c4e28-a2ba-4715-ae9d-70da6b078314",
            "responseId": "63c78073296cd45fee33c588",
            "receivedResponse": {
                "id": "63c78073296cd45fee33c5880.920574674610739",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ras",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.772Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "0610256838",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "FLAVIE DORNE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0610256838",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU USINE DEPOLLUTION CHAMBENIER",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3659970",
            "XylemReference": "196908",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ras",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c78073296cd45fee33c5e4",
        "extendedResponse": {
            "_id": "63ce3150278650d23f43678e",
            "submittedBody": {
                "id": "63c78073296cd45fee33c5e40.8338091647651238",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de livraison ",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0608607745"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1565d892-aab7-4786-a0c9-576033582b21",
            "responseId": "63c78073296cd45fee33c5e4",
            "receivedResponse": {
                "id": "63c78073296cd45fee33c5e40.8338091647651238",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de livraison ",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.807Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "0608607745",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "SAUBION Fabrice",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0608607745",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COMMUNAUTE DE COMMUNES DU THOUARSAIS / STEP THOUET",
            "CustomerCompanyGroupName": "CC DU THOUARSAIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AS230021",
            "XylemReference": "196716",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de livraison ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807368718f61286a9798",
        "extendedResponse": {
            "_id": "63ce3150278650d23f43678f",
            "submittedBody": {
                "id": "63c7807368718f61286a97980.7547715901871965",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDITE ET ADATATION DU BESOIN",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "assainissement@4cvs.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "14b51cde-093c-4683-8f18-0b09a076a95b",
            "responseId": "63c7807368718f61286a9798",
            "receivedResponse": {
                "id": "63c7807368718f61286a97980.7547715901871965",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité ET ADATATION DU BESOIN",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.955Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "assainissement@4cvs.fr",
            "CustomerContactEmail": "assainissement@4cvs.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BARTHELEMY",
            "CustomerContactFirstName": "Stephane",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Com Com Cotes de Champagne et Val de Saulx 4CVS",
            "CustomerCompanyGroupName": "COMMUNAUTE DE COMMUNES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004362",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDITE ET ADATATION DU BESOIN",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807368718f61286a97ac",
        "extendedResponse": {
            "_id": "63ce3150278650d23f436790",
            "submittedBody": {
                "id": "63c7807368718f61286a97ac0.7534879244206976",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité par rapport à ma demande et le réajustement du devis selon ma demande. De plus le délai de livraison , très rapide pour le cas cité ",
                "creationDate": "2023-01-18T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jcalbert@saur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a4f3e0cd-fb0d-4497-a50b-dc0a3048cd49",
            "responseId": "63c7807368718f61286a97ac",
            "receivedResponse": {
                "id": "63c7807368718f61286a97ac0.7534879244206976",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité par rapport à ma demande et le réajustement du devis selon ma demande. De plus le délai de livraison , très rapide pour le cas cité ",
                "creationDate": "2023-01-18T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:31.990Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "jcalbert@saur.fr",
            "CustomerContactEmail": "jcalbert@saur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ALBERT",
            "CustomerContactFirstName": "Jean-Claude",
            "CustomerContactMobilePhone": "0662945506",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004418",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité par rapport à ma demande et le réajustement du devis selon ma demande. De plus le délai de livraison , très rapide pour le cas cité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807468718f61286a986e",
        "extendedResponse": {
            "_id": "63ce3151278650d23f436791",
            "submittedBody": {
                "id": "63c7807468718f61286a986e0.43523555189261964",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la rapidité de réponse concernant ma commande et la disponibilité de pièce.",
                "creationDate": "2023-01-18T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "service.sud@sterigene.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "005fb6e8-7c2a-43a6-ab84-e85451438775",
            "responseId": "63c7807468718f61286a986e",
            "receivedResponse": {
                "id": "63c7807468718f61286a986e0.43523555189261964",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la rapidité de réponse concernant ma commande et la disponibilité de pièce.",
                "creationDate": "2023-01-18T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:32.110Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "service.sud@sterigene.com",
            "CustomerContactEmail": "service.sud@sterigene.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "LAFON",
            "CustomerContactFirstName": "Isabelle",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "STERIGENE",
            "CustomerCompanyGroupName": "STERIGENE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223004557",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la rapidité de réponse concernant ma commande et la disponibilité de pièce.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807468718f61286a9883",
        "extendedResponse": {
            "_id": "63ce3151278650d23f436792",
            "submittedBody": {
                "id": "63c7807468718f61286a98830.3091863247856239",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse. Correspondance offre vs demande",
                "creationDate": "2023-01-18T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christian.camguilhem@electromontage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "df141b55-21cb-42bd-ba2f-84a5a4074bd7",
            "responseId": "63c7807468718f61286a9883",
            "receivedResponse": {
                "id": "63c7807468718f61286a98830.3091863247856239",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse. Correspondance offre vs demande",
                "creationDate": "2023-01-18T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:32.113Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "christian.camguilhem@electromontage.fr",
            "CustomerContactEmail": "christian.camguilhem@electromontage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAMGUILHEM",
            "CustomerContactFirstName": "CHRISTIAN",
            "CustomerContactMobilePhone": "0614186567",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELECTROMONTAGE 47",
            "CustomerCompanyGroupName": "ELECTROMONTAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004558",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse. Correspondance offre vs demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807468718f61286a98bf",
        "extendedResponse": {
            "_id": "63ce3151278650d23f436793",
            "submittedBody": {
                "id": "63c7807468718f61286a98bf0.36712998044350664",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai de réponse demandé respecté. ",
                "creationDate": "2023-01-18T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "e.rolin@bouygues-es.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "f4d512de-34fd-4b2d-8468-ee5f567cda33",
            "responseId": "63c7807468718f61286a98bf",
            "receivedResponse": {
                "id": "63c7807468718f61286a98bf0.36712998044350664",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai de réponse demandé respecté. ",
                "creationDate": "2023-01-18T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-18T05:15:32.149Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "e.rolin@bouygues-es.com",
            "CustomerContactEmail": "e.rolin@bouygues-es.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROLIN",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0764175901",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BOUYGUES ENERGIES ET SERVICES",
            "CustomerCompanyGroupName": "BOUYGUES BATIMENT IDF S28X",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004523",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai de réponse demandé respecté. ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c7807468718f61286a98d0",
        "extendedResponse": {
            "_id": "63ce3151278650d23f436794",
            "submittedBody": {
                "id": "63c7807468718f61286a98d00.7787529032334526",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de mr Rémi Bouquin sur les devis",
                "creationDate": "2023-01-18T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mickael.ferrand@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "4d7d039f-f75b-4dae-b853-f6977fca912d",
            "responseId": "63c7807468718f61286a98d0",
            "receivedResponse": {
                "id": "63c7807468718f61286a98d00.7787529032334526",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de mr Rémi Bouquin sur les devis",
                "creationDate": "2023-01-18T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-18T05:15:32.172Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-18_06.15.09",
        "integration": {
            "ContactKey": "mickael.ferrand@veolia.com",
            "CustomerContactEmail": "mickael.ferrand@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FERRAND",
            "CustomerContactFirstName": "Mickael",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004612",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de mr Rémi Bouquin sur les devis",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Puis je avoir un autre contact qu en Rémi Bouquin est en congés ou abs..\nMerci"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f3296cd45fee8d4332",
        "extendedResponse": {
            "_id": "63ce3152278650d23f436795",
            "submittedBody": {
                "id": "63c8d1f3296cd45fee8d43320.19415958741660244",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Emballage soigné du matériel et dépos de ce dernier à ma convenance.",
                "creationDate": "2023-01-19T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0614842663"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "64a2f196-babb-40a3-a3ea-3bd4d8eac384",
            "responseId": "63c8d1f3296cd45fee8d4332",
            "receivedResponse": {
                "id": "63c8d1f3296cd45fee8d43320.19415958741660244",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Emballage soigné du matériel et dépos de ce dernier à ma convenance.",
                "creationDate": "2023-01-19T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-19T05:15:31.198Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "0614842663",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Jean-Pierre BOURGEOIS",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0614842663",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROLYS",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "13492",
            "XylemReference": "194586",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Emballage soigné du matériel et dépos de ce dernier à ma convenance.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f3296cd45fee8d4348",
        "extendedResponse": {
            "_id": "63ce3152278650d23f436796",
            "submittedBody": {
                "id": "63c8d1f3296cd45fee8d43480.7939813424201245",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-01-19T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0651400651"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "40ffb6d1-f1d9-4b9e-8497-2a28cb83f108",
            "responseId": "63c8d1f3296cd45fee8d4348",
            "receivedResponse": {
                "id": "63c8d1f3296cd45fee8d43480.7939813424201245",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-01-19T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-19T05:15:31.233Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "0651400651",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Stéphane CHOMAT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0651400651",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROLYS",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "13726",
            "XylemReference": "197148",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f368718f6128126ea5",
        "extendedResponse": {
            "_id": "63ce3152278650d23f436797",
            "submittedBody": {
                "id": "63c8d1f368718f6128126ea50.8255702451256339",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2023-01-19T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gerald.moretto@general-technic.lu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6edce2a3-0e30-4942-b6a9-2ad9a4575d3d",
            "responseId": "63c8d1f368718f6128126ea5",
            "receivedResponse": {
                "id": "63c8d1f368718f6128126ea50.8255702451256339",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2023-01-19T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-19T05:15:31.245Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "gerald.moretto@general-technic.lu",
            "CustomerContactEmail": "gerald.moretto@general-technic.lu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORETTO",
            "CustomerContactFirstName": "GERALD",
            "CustomerContactMobilePhone": "+352495174435",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "General Technic",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Fabien",
            "XylemContactedPersonName": "CANNIZZARO",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f368718f6128126ead",
        "extendedResponse": {
            "_id": "63ce3153278650d23f436798",
            "submittedBody": {
                "id": "63c8d1f368718f6128126ead0.9016243213738726",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Echange sur les technologies des moteurs",
                "creationDate": "2023-01-19T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.lache@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0967785d-9657-4251-a3e6-8aeafee4560c",
            "responseId": "63c8d1f368718f6128126ead",
            "receivedResponse": {
                "id": "63c8d1f368718f6128126ead0.9016243213738726",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Echange sur les technologies des moteurs",
                "creationDate": "2023-01-19T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-19T05:15:31.268Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "sebastien.lache@dalkia.fr",
            "CustomerContactEmail": "sebastien.lache@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACHE",
            "CustomerContactFirstName": "SEBASTIEN",
            "CustomerContactMobilePhone": "+33614712004",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA France",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Fabien",
            "XylemContactedPersonName": "CANNIZZARO",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Echange sur les technologies des moteurs",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f3296cd45fee8d43b2",
        "extendedResponse": {
            "_id": "63ce3153278650d23f436799",
            "submittedBody": {
                "id": "63c8d1f3296cd45fee8d43b20.5181812873048997",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité d'expédition\n",
                "creationDate": "2023-01-19T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0610698360"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0ab99351-8e8b-4542-8f35-24dc96fd2981",
            "responseId": "63c8d1f3296cd45fee8d43b2",
            "receivedResponse": {
                "id": "63c8d1f3296cd45fee8d43b20.5181812873048997",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité d'expédition\n",
                "creationDate": "2023-01-19T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-19T05:15:31.305Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "0610698360",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Abou MBAYE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0610698360",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROLYS",
            "CustomerCompanyGroupName": "DUROLAGE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "13718",
            "XylemReference": "197023",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité d'expédition\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f495510f6a3449256b",
        "extendedResponse": {
            "_id": "63ce3153278650d23f43679a",
            "submittedBody": {
                "id": "63c8d1f495510f6a3449256b0.5737221169549427",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "parfait, rapidité de l'envoi du devis",
                "creationDate": "2023-01-19T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julien.david@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "926c549c-a3d8-4528-a997-19c248a44736",
            "responseId": "63c8d1f495510f6a3449256b",
            "receivedResponse": {
                "id": "63c8d1f495510f6a3449256b0.5737221169549427",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "parfait, rapidité de l'envoi du devis",
                "creationDate": "2023-01-19T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-19T05:15:32.050Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "julien.david@veolia.com",
            "CustomerContactEmail": "julien.david@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DAVID",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "0619562306",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - ECHM",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004717",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "parfait, rapidité de l'envoi du devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f495510f6a34492668",
        "extendedResponse": {
            "_id": "63ce3154278650d23f43679b",
            "submittedBody": {
                "id": "63c8d1f495510f6a344926680.7186468399627504",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Xylem est un groupe avec des compétences en hydrauliques indiscutables. Un savoir faire et une base de données qui rendent les échanges très fluides. \nLe plus \"décevant\" reste l'indisponibilité du matériel et l'attente de livraison...",
                "creationDate": "2023-01-19T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pchamboissier@kiloutou-energie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5dad32f8-4bd8-4718-93b8-f2603ac9bbf4",
            "responseId": "63c8d1f495510f6a34492668",
            "receivedResponse": {
                "id": "63c8d1f495510f6a344926680.7186468399627504",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Xylem est un groupe avec des compétences en hydrauliques indiscutables. Un savoir faire et une base de données qui rendent les échanges très fluides. \nLe plus \"décevant\" reste l'indisponibilité du matériel et l'attente de livraison...",
                "creationDate": "2023-01-19T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.neg",
                                    "label": "Availability / Stock (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.neg",
                "manque.produit.neg",
                "competence.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.neg",
                    "label": "Availability / Stock (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-19T05:15:32.289Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "pchamboissier@kiloutou-energie.fr",
            "CustomerContactEmail": "pchamboissier@kiloutou-energie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHAMBOISSIER",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0615848996",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KILOUTOU ENERGIE",
            "CustomerCompanyGroupName": "KILOUTOU ENERGIE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004775",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Xylem est un groupe avec des compétences en hydrauliques indiscutables. Un savoir faire et une base de données qui rendent les échanges très fluides. \nLe plus \"décevant\" reste l'indisponibilité du matériel et l'attente de livraison...",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f495510f6a344926e9",
        "extendedResponse": {
            "_id": "63ce3154278650d23f43679c",
            "submittedBody": {
                "id": "63c8d1f495510f6a344926e90.19251836297688518",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Problème de bug ? \nReçu offre de prix en prix public ( sans remise )\nC'est nouveau ?",
                "creationDate": "2023-01-19T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "spoynard@dispro-irrigation.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "7e53564f-def4-4c26-b244-99d8c6442c91",
            "responseId": "63c8d1f495510f6a344926e9",
            "receivedResponse": {
                "id": "63c8d1f495510f6a344926e90.19251836297688518",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Problème de bug ? \nReçu offre de prix en prix public ( sans remise )\nC'est nouveau ?",
                "creationDate": "2023-01-19T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-01-19T05:15:32.413Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "spoynard@dispro-irrigation.com",
            "CustomerContactEmail": "spoynard@dispro-irrigation.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POYNARD",
            "CustomerContactFirstName": "Stephan",
            "CustomerContactMobilePhone": "0640908714",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DISTRIBUTION PROVENCALE",
            "CustomerCompanyGroupName": "DISTRIBUTION PROVENCALE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004863",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Problème de bug ? \nReçu offre de prix en prix public ( sans remise )\nC'est nouveau ?",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Eviter ces erreurs !"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f495510f6a34492731",
        "extendedResponse": {
            "_id": "63ce3154278650d23f43679d",
            "submittedBody": {
                "id": "63c8d1f495510f6a344927310.14666290731508602",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité respect des délais ",
                "creationDate": "2023-01-19T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aissam.bouhouche@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b0234496-1f3b-4af0-8432-60fdd7e3f8ab",
            "responseId": "63c8d1f495510f6a34492731",
            "receivedResponse": {
                "id": "63c8d1f495510f6a344927310.14666290731508602",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité respect des délais ",
                "creationDate": "2023-01-19T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-19T05:15:32.495Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "aissam.bouhouche@sade-cgth.fr",
            "CustomerContactEmail": "aissam.bouhouche@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUHOUCHE",
            "CustomerContactFirstName": "AISSAM",
            "CustomerContactMobilePhone": "0646016069",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004942",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité respect des délais ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63c8d1f495510f6a34492738",
        "extendedResponse": {
            "_id": "63ce3154278650d23f43679e",
            "submittedBody": {
                "id": "63c8d1f495510f6a344927380.7887381153012052",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "impeccable ,  bonne réactivité , et l'offre est bien complète , merci .",
                "creationDate": "2023-01-19T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "evidal@savoietransmissions.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "307f5b59-ef8d-489d-8505-ca561997241e",
            "responseId": "63c8d1f495510f6a34492738",
            "receivedResponse": {
                "id": "63c8d1f495510f6a344927380.7887381153012052",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "impeccable , bonne réactivité , et l'offre est bien complète , merci .",
                "creationDate": "2023-01-19T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-19T05:15:32.499Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-19_06.15.08",
        "integration": {
            "ContactKey": "evidal@savoietransmissions.com",
            "CustomerContactEmail": "evidal@savoietransmissions.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VIDAL",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAVOIE TRANSMISSIONS",
            "CustomerCompanyGroupName": "SAVOIE TRANSMISSIONS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223004946",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "impeccable ,  bonne réactivité , et l'offre est bien complète , merci .",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca2372296cd45fee37fdb2",
        "extendedResponse": {
            "_id": "63ce3155278650d23f43679f",
            "submittedBody": {
                "id": "63ca2372296cd45fee37fdb20.4613815073323748",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J’ai du mal à vous dire que je vous aime quand je reçois votre message à 5h du matin ",
                "creationDate": "2023-01-20T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0625735292"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "c60aa490-18b0-43b7-a656-f0205ead5516",
            "responseId": "63ca2372296cd45fee37fdb2",
            "receivedResponse": {
                "id": "63ca2372296cd45fee37fdb20.4613815073323748",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J ai du mal à vous dire que je vous aime quand je reçois votre message à 5h du matin ",
                "creationDate": "2023-01-20T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:30.831Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "0625735292",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Christophe DETTORI",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0625735292",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KYRNOLIA (AGENCE SAGONE)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "4 105 137 826",
            "XylemReference": "635708",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J’ai du mal à vous dire que je vous aime quand je reçois votre message à 5h du matin ",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237268718f61288cf556",
        "extendedResponse": {
            "_id": "63ce3155278650d23f4367a0",
            "submittedBody": {
                "id": "63ca237268718f61288cf5560.2264559893210274",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapide et réactif",
                "creationDate": "2023-01-20T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "marion.alix@sogedoc.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e74e7cf9-689e-40d6-a5f6-ce4bc2fd75a9",
            "responseId": "63ca237268718f61288cf556",
            "receivedResponse": {
                "id": "63ca237268718f61288cf5560.2264559893210274",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapide et réactif",
                "creationDate": "2023-01-20T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:30.885Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "marion.alix@sogedoc.com",
            "CustomerContactEmail": "marion.alix@sogedoc.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "ALIX",
            "CustomerContactFirstName": "Marion",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEDOC",
            "CustomerCompanyGroupName": "SOGEDOC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005153",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapide et réactif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237268718f61288cf581",
        "extendedResponse": {
            "_id": "63ce3155278650d23f4367a1",
            "submittedBody": {
                "id": "63ca237268718f61288cf5810.7299798676900633",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis reçu rapidement merci ",
                "creationDate": "2023-01-20T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.tanguy@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "11b4a19e-ef8e-43f5-afb0-4ebc27561240",
            "responseId": "63ca237268718f61288cf581",
            "receivedResponse": {
                "id": "63ca237268718f61288cf5810.7299798676900633",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis reçu rapidement merci ",
                "creationDate": "2023-01-20T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:30.958Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "david.tanguy@saur.com",
            "CustomerContactEmail": "david.tanguy@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TANGUY",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR SARZEAU",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005381",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis reçu rapidement merci ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237268718f61288cf583",
        "extendedResponse": {
            "_id": "63ce3156278650d23f4367a2",
            "submittedBody": {
                "id": "63ca237268718f61288cf5830.14837511203467146",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "votre réactivité",
                "creationDate": "2023-01-20T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.rigaud@cirhyo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "6ea09233-9a84-481e-ac3b-1401cf2046f8",
            "responseId": "63ca237268718f61288cf583",
            "receivedResponse": {
                "id": "63ca237268718f61288cf5830.14837511203467146",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "votre réactivité",
                "creationDate": "2023-01-20T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:30.963Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "s.rigaud@cirhyo.fr",
            "CustomerContactEmail": "s.rigaud@cirhyo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RIGAUD",
            "CustomerContactFirstName": "Serge",
            "CustomerContactMobilePhone": "0603123189",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CIRHYO (MC PORC) ANC SCAPP 89",
            "CustomerCompanyGroupName": "CIRHYO",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005398",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "votre réactivité",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Maitriser mieux vos hausses tarifaire et en particulier sur les accessoires inox"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237268718f61288cf591",
        "extendedResponse": {
            "_id": "63ce3156278650d23f4367a3",
            "submittedBody": {
                "id": "63ca237268718f61288cf5910.10395378140836575",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "c est parfait",
                "creationDate": "2023-01-20T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jl.seignourel@cg-irrigation.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4b1e9282-8b8b-44f9-b8a8-cc5480816c25",
            "responseId": "63ca237268718f61288cf591",
            "receivedResponse": {
                "id": "63ca237268718f61288cf5910.10395378140836575",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "c est parfait",
                "creationDate": "2023-01-20T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:30.990Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "jl.seignourel@cg-irrigation.com",
            "CustomerContactEmail": "jl.seignourel@cg-irrigation.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SEIGNOUREL",
            "CustomerContactFirstName": "Jean-Luc",
            "CustomerContactMobilePhone": "0679239724",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CG IRRIGATION",
            "CustomerCompanyGroupName": "CG IRRIGATION SARL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005460",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "c est parfait",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca2372296cd45fee3805ae",
        "extendedResponse": {
            "_id": "63ce3156278650d23f4367a4",
            "submittedBody": {
                "id": "63ca2372296cd45fee3805ae0.8722166866916026",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne écoute",
                "creationDate": "2023-01-20T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "william.gatignol@constellium.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "09360a78-efe7-41a5-97aa-d9882a7de6d0",
            "responseId": "63ca2372296cd45fee3805ae",
            "receivedResponse": {
                "id": "63ca2372296cd45fee3805ae0.8722166866916026",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne écoute",
                "creationDate": "2023-01-20T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:30.993Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "william.gatignol@constellium.com",
            "CustomerContactEmail": "william.gatignol@constellium.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GATIGNOL",
            "CustomerContactFirstName": "William",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CONSTELLIUM",
            "CustomerCompanyGroupName": "CONSTELLIUM ISSOIRE SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "Gauthier",
            "XylemContactedPersonName": "DUFOUR",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne écoute",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca2373296cd45fee3805ba",
        "extendedResponse": {
            "_id": "63ce3156278650d23f4367a5",
            "submittedBody": {
                "id": "63ca2373296cd45fee3805ba0.4810032541686653",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La fluidité et la précision des explications, une très bonne dynamique, comme d'habitude.\nDiscutions riches en nouvelles idées, nouvelle stratégies ...",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "n.rousseau@advinsi.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "406832de-16a6-45b8-be21-c28c3121015b",
            "responseId": "63ca2373296cd45fee3805ba",
            "receivedResponse": {
                "id": "63ca2373296cd45fee3805ba0.4810032541686653",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La fluidité et la précision des explications, une très bonne dynamique, comme d'habitude.\nDiscutions riches en nouvelles idées, nouvelle stratégies ...",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.016Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "n.rousseau@advinsi.com",
            "CustomerContactEmail": "n.rousseau@advinsi.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROUSSEAU",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0660408462",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ADVINSI",
            "CustomerCompanyGroupName": "SAS CASLA ADVINSI",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Claude",
            "XylemContactedPersonName": "GUILLERMET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La fluidité et la précision des explications, une très bonne dynamique, comme d'habitude.\nDiscutions riches en nouvelles idées, nouvelle stratégies ...",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237368718f61288cf66b",
        "extendedResponse": {
            "_id": "63ce3157278650d23f4367a6",
            "submittedBody": {
                "id": "63ca237368718f61288cf66b0.9677212628709397",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "reponse rapide et bonne ",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cb272878-c9ec-4566-964f-5aa48d5b5da4",
            "responseId": "63ca237368718f61288cf66b",
            "receivedResponse": {
                "id": "63ca237368718f61288cf66b0.9677212628709397",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide et bonne ",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.089Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005135",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "reponse rapide et bonne ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237368718f61288cf798",
        "extendedResponse": {
            "_id": "63ce3157278650d23f4367a7",
            "submittedBody": {
                "id": "63ca237368718f61288cf7980.716781524885248",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse très rapide de votre part.",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "james.billon@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e450edb4-6983-473c-a8fa-f6acc2cb7e7c",
            "responseId": "63ca237368718f61288cf798",
            "receivedResponse": {
                "id": "63ca237368718f61288cf7980.716781524885248",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse très rapide de votre part.",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.270Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "james.billon@saur.com",
            "CustomerContactEmail": "james.billon@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BILLON",
            "CustomerContactFirstName": "JAMES",
            "CustomerContactMobilePhone": "0662544625",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR 17",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005270",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse très rapide de votre part.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237368718f61288cf7e4",
        "extendedResponse": {
            "_id": "63ce3157278650d23f4367a8",
            "submittedBody": {
                "id": "63ca237368718f61288cf7e40.5485353230879255",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Merci pour votre proposition précise qui correspond à mes attentes.",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romain.louradour@naldeo.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7360f89d-d0b3-4f8e-9225-591e540b93d4",
            "responseId": "63ca237368718f61288cf7e4",
            "receivedResponse": {
                "id": "63ca237368718f61288cf7e40.5485353230879255",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Merci pour votre proposition précise qui correspond à mes attentes.",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.333Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "romain.louradour@naldeo.com",
            "CustomerContactEmail": "romain.louradour@naldeo.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LOURADOUR",
            "CustomerContactFirstName": "ROMAIN",
            "CustomerContactMobilePhone": "0685089885",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NALDEO",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005297",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Merci pour votre proposition précise qui correspond à mes attentes.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237368718f61288cf816",
        "extendedResponse": {
            "_id": "63ce3158278650d23f4367a9",
            "submittedBody": {
                "id": "63ca237368718f61288cf8160.12353576938430444",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Votre rapidité de nous répondre, les solutions proposées et vos tarifs contrat-cadres.",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nelson.pangkoui@polynesienne-des-eaux.pf"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "f3c9a16d-b0ad-40c8-b183-f22b4d6872dd",
            "responseId": "63ca237368718f61288cf816",
            "receivedResponse": {
                "id": "63ca237368718f61288cf8160.12353576938430444",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Votre rapidité de nous répondre, les solutions proposées et vos tarifs contrat-cadres.",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.418Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "nelson.pangkoui@polynesienne-des-eaux.pf",
            "CustomerContactEmail": "nelson.pangkoui@polynesienne-des-eaux.pf",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PANG KOUI",
            "CustomerContactFirstName": "Nelson",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "POLYNESIENNE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005390",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Votre rapidité de nous répondre, les solutions proposées et vos tarifs contrat-cadres.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Pour nous étendre vos tarifs contrat-cadres."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237368718f61288cf837",
        "extendedResponse": {
            "_id": "63ce3158278650d23f4367aa",
            "submittedBody": {
                "id": "63ca237368718f61288cf8370.5992032339162146",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide. ",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabien.olivieri@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "10e30801-7819-400a-a62f-7325ea3800c4",
            "responseId": "63ca237368718f61288cf837",
            "receivedResponse": {
                "id": "63ca237368718f61288cf8370.5992032339162146",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide. ",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.475Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "fabien.olivieri@veolia.com",
            "CustomerContactEmail": "fabien.olivieri@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "OLIVIERI",
            "CustomerContactFirstName": "Fabien",
            "CustomerContactMobilePhone": "0627744138",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU ALES",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005430",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide. ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237368718f61288cf83e",
        "extendedResponse": {
            "_id": "63ce3158278650d23f4367ab",
            "submittedBody": {
                "id": "63ca237368718f61288cf83e0.12543161532336677",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité, conseil",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.joonnekindt@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "93f9a88e-a097-49c0-94bb-389b7732c9d3",
            "responseId": "63ca237368718f61288cf83e",
            "receivedResponse": {
                "id": "63ca237368718f61288cf83e0.12543161532336677",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité, conseil",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.483Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "olivier.joonnekindt@dalkia.fr",
            "CustomerContactEmail": "olivier.joonnekindt@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JOONNEKINDT",
            "CustomerContactFirstName": "OLIVIER",
            "CustomerContactMobilePhone": "0664552789",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Dalkia Smart Building",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Remy",
            "XylemContactedPersonName": "BERNETIERE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223005163",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité, conseil",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ca237368718f61288cf854",
        "extendedResponse": {
            "_id": "63e4a834956a2e76a213bd23",
            "submittedBody": {
                "id": "63ca237368718f61288cf8540.7784607611567227",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité et réponse très claires",
                "creationDate": "2023-01-20T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "r.robin@belle-environnement.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d8dba4be-dc47-46ec-ad8e-1e55904b5366",
            "responseId": "63ca237368718f61288cf854",
            "receivedResponse": {
                "id": "63ca237368718f61288cf8540.7784607611567227",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité et réponse très claires",
                "creationDate": "2023-01-20T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-20T05:15:31.521Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "r.robin@belle-environnement.fr",
            "CustomerContactEmail": "r.robin@belle-environnement.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "ROBIN",
            "CustomerContactFirstName": "ROXANE",
            "CustomerContactMobilePhone": "0788645594",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BELLE ENVIRONNEMENT",
            "CustomerCompanyGroupName": "BELLE ENVIRONNEMENT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005481",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité et réponse très claires",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f268718f61284e9873",
        "extendedResponse": {
            "_id": "63ce3158278650d23f4367ac",
            "submittedBody": {
                "id": "63cb74f268718f61284e98730.4099966071316763",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai de réponse des offres un peu long ",
                "creationDate": "2023-01-21T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arnauld.aureyre@abcd-xogroupe.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "aec53460-9514-4761-9b7a-e9e16d01c5fb",
            "responseId": "63cb74f268718f61284e9873",
            "receivedResponse": {
                "id": "63cb74f268718f61284e98730.4099966071316763",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai de réponse des offres un peu long ",
                "creationDate": "2023-01-21T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "promo.all"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                }
            ]
        },
        "date": "2023-01-21T05:15:30.750Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-21_06.15.09",
        "integration": {
            "ContactKey": "arnauld.aureyre@abcd-xogroupe.fr",
            "CustomerContactEmail": "arnauld.aureyre@abcd-xogroupe.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "AUREYRE",
            "CustomerContactFirstName": "Arnauld",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ABCD",
            "CustomerCompanyGroupName": "A B C D",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005453",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai de réponse des offres un peu long ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Plus réactif "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f295510f6a34c2d82b",
        "extendedResponse": {
            "_id": "63ce3159278650d23f4367ad",
            "submittedBody": {
                "id": "63cb74f295510f6a34c2d82b0.5759264060871856",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité de vos équipe, remplacement de pompe sous 24h!",
                "creationDate": "2023-01-21T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.cortial@cegelec.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "311483f1-ae76-4264-92a5-e22e371af6d3",
            "responseId": "63cb74f295510f6a34c2d82b",
            "receivedResponse": {
                "id": "63cb74f295510f6a34c2d82b0.5759264060871856",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité de vos équipe, remplacement de pompe sous 24h!",
                "creationDate": "2023-01-21T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-21T05:15:30.781Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "thomas.cortial@cegelec.com",
            "CustomerContactEmail": "thomas.cortial@cegelec.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CORTIAL",
            "CustomerContactFirstName": "THOMAS",
            "CustomerContactMobilePhone": "0661602500",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CEGELEC",
            "CustomerCompanyGroupName": "SANTERNE CENTRE EST ENERGIES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "POINARD",
            "XylemContactedPersonRole": "TCS Location",
            "CustomerReference": "961761551",
            "XylemReference": "749699",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité de vos équipe, remplacement de pompe sous 24h!",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "être encore plus rapide"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f268718f61284e9889",
        "extendedResponse": {
            "_id": "63ce4af662fde4dd12f17550",
            "submittedBody": {
                "id": "63cb74f268718f61284e98890.0328370685427779",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide à une demande de devis ",
                "creationDate": "2023-01-21T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.bizet@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a0e6a5d5-f538-4f1f-a7b6-85667d54de21",
            "responseId": "63cb74f268718f61284e9889",
            "receivedResponse": {
                "id": "63cb74f268718f61284e98890.0328370685427779",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide à une demande de devis ",
                "creationDate": "2023-01-21T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-21T05:15:30.793Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-21_06.15.09",
        "integration": {
            "ContactKey": "philippe.bizet@veolia.com",
            "CustomerContactEmail": "philippe.bizet@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIZET",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0777857376",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005623",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide à une demande de devis ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f268718f61284e98aa",
        "extendedResponse": {
            "_id": "63e4a834956a2e76a213bd24",
            "submittedBody": {
                "id": "63cb74f268718f61284e98aa0.6470455458924831",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "j'ai récemment contacté le service pièces détachés  pour établir une offre concernant un capteur de niveau. le service a été réactif  et m a transféré mon offre dans un délais acceptable.\nen revanche comme le capteur a un cout élevé il eu été judicieux de me transmettre le prix d'une pompe neuve. car il a été nécessaire de reprendre contact pour cela.\ntrès cordialement\nPhilippe",
                "creationDate": "2023-01-21T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.despruniers@stgs.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "4c2299bf-4a09-4915-b391-69570e2f3fe2",
            "responseId": "63cb74f268718f61284e98aa",
            "receivedResponse": {
                "id": "63cb74f268718f61284e98aa0.6470455458924831",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "j'ai récemment contacté le service pièces détachés pour établir une offre concernant un capteur de niveau. le service a été réactif et m a transféré mon offre dans un délais acceptable.\nen revanche comme le capteur a un coût élevé il eu été judicieux de me transmettre le prix d'une pompe neuve. car il a été nécessaire de reprendre contact pour cela.\ntrès cordialement\nPhilippe",
                "creationDate": "2023-01-21T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "choix.pos",
                "delai.pos",
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-21T05:15:30.855Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-21_06.15.09",
        "integration": {
            "ContactKey": "philippe.despruniers@stgs.fr",
            "CustomerContactEmail": "philippe.despruniers@stgs.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DESPRUNIERS",
            "CustomerContactFirstName": "philippe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "STGS",
            "CustomerCompanyGroupName": "FEDERATION DES DISTRIBUTEURS D'EAU INDEPENDANTS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": ".",
            "XylemSalespeopleName": "TCS ZONE NORD",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005778",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "j'ai récemment contacté le service pièces détachés  pour établir une offre concernant un capteur de niveau. le service a été réactif  et m a transféré mon offre dans un délais acceptable.\nen revanche comme le capteur a un cout élevé il eu été judicieux de me transmettre le prix d'une pompe neuve. car il a été nécessaire de reprendre contact pour cela.\ntrès cordialement\nPhilippe",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "rappeler  le prix de la pompe neuve  en plus du prix du capteur a recâbler sur la pompe abimée."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f368718f61284e9982",
        "extendedResponse": {
            "_id": "63ce4af762fde4dd12f17551",
            "submittedBody": {
                "id": "63cb74f368718f61284e99820.223092049363244",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Plu : rapidité dans la réponse\nMoins Plu : logiciel de chiffrage sur le site : pas possible pour un réseau eau glycolée -8°C",
                "creationDate": "2023-01-21T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.guais@matal.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "5462a9cd-2510-4406-af88-bad6a8276b3b",
            "responseId": "63cb74f368718f61284e9982",
            "receivedResponse": {
                "id": "63cb74f368718f61284e99820.223092049363244",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Plu : rapidité dans la réponse\nMoins Plu : logiciel de chiffrage sur le site : pas possible pour un réseau eau glycolée -8°C",
                "creationDate": "2023-01-21T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-21T05:15:31.125Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-21_06.15.09",
        "integration": {
            "ContactKey": "sebastien.guais@matal.fr",
            "CustomerContactEmail": "sebastien.guais@matal.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUAIS",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "0699980697",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MATAL",
            "CustomerCompanyGroupName": "MATAL SAS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005685",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Plu : rapidité dans la réponse\nMoins Plu : logiciel de chiffrage sur le site : pas possible pour un réseau eau glycolée -8°C",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Mettre a disposition une aide pour l'utilisation du logiciel de sélection sur le site."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f368718f61284e99a8",
        "extendedResponse": {
            "_id": "63ce3159278650d23f4367ae",
            "submittedBody": {
                "id": "63cb74f368718f61284e99a80.6015766121471502",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de la réponse\nQualité de la réponse",
                "creationDate": "2023-01-21T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julien.dublineau@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fd73feb9-8711-4a3a-825d-8b8f2deedb02",
            "responseId": "63cb74f368718f61284e99a8",
            "receivedResponse": {
                "id": "63cb74f368718f61284e99a80.6015766121471502",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de la réponse\nQualité de la réponse",
                "creationDate": "2023-01-21T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-21T05:15:31.193Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-21_06.15.09",
        "integration": {
            "ContactKey": "julien.dublineau@eiffage.com",
            "CustomerContactEmail": "julien.dublineau@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUBLINEAU",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "0693330080",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROTECH SARL",
            "CustomerCompanyGroupName": "HYDROTECH",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN DT",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005015",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de la réponse\nQualité de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f368718f61284e99be",
        "extendedResponse": {
            "_id": "63ce3159278650d23f4367af",
            "submittedBody": {
                "id": "63cb74f368718f61284e99be0.5787406700098419",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Au top, réponse rapide et précise",
                "creationDate": "2023-01-21T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "damien.barbosa@rubix.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8a906191-8748-4240-9d6e-ce1a86cf2aba",
            "responseId": "63cb74f368718f61284e99be",
            "receivedResponse": {
                "id": "63cb74f368718f61284e99be0.5787406700098419",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Au top, réponse rapide et précise",
                "creationDate": "2023-01-21T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-21T05:15:31.234Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-21_06.15.09",
        "integration": {
            "ContactKey": "damien.barbosa@rubix.com",
            "CustomerContactEmail": "damien.barbosa@rubix.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BARBOSA",
            "CustomerContactFirstName": "Damien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OREXAD BRAMMER",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Lea",
            "XylemSalespeopleName": "FATOWIEZ",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005774",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Au top, réponse rapide et précise",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cb74f368718f61284e99d3",
        "extendedResponse": {
            "_id": "63ce4af762fde4dd12f17552",
            "submittedBody": {
                "id": "63cb74f368718f61284e99d30.7131482995545657",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité, offres complètes et précises",
                "creationDate": "2023-01-21T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "n.rousseau@advinsi.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5159e945-b426-4133-864b-7a5e81c71a83",
            "responseId": "63cb74f368718f61284e99d3",
            "receivedResponse": {
                "id": "63cb74f368718f61284e99d30.7131482995545657",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité, offres complètes et précises",
                "creationDate": "2023-01-21T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-21T05:15:31.267Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-21_06.15.09",
        "integration": {
            "ContactKey": "n.rousseau@advinsi.com",
            "CustomerContactEmail": "n.rousseau@advinsi.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROUSSEAU",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0660408462",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ADVINSI",
            "CustomerCompanyGroupName": "SAS CASLA ADVINSI",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223005809",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité, offres complètes et précises",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf696f4a6587652f03e6bc",
        "extendedResponse": {
            "_id": "63e4a835956a2e76a213bd25",
            "submittedBody": {
                "id": "63cf696f4a6587652f03e6bc0.5517128630614123",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Parfait ",
                "creationDate": "2023-01-24T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.pariot@groupe-fb.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7370d487-d93f-498d-a0ba-c4ea2ef3cc0d",
            "responseId": "63cf696f4a6587652f03e6bc",
            "receivedResponse": {
                "id": "63cf696f4a6587652f03e6bc0.5517128630614123",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Parfait ",
                "creationDate": "2023-01-24T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:27.362Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "thomas.pariot@groupe-fb.com",
            "CustomerContactEmail": "thomas.pariot@groupe-fb.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PARIOT",
            "CustomerContactFirstName": "Thomas",
            "CustomerContactMobilePhone": "0610765227",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (112)",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "Erwan",
            "XylemContactedPersonName": "CRAQUELIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Parfait ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf696f4a6587652f03e6d5",
        "extendedResponse": {
            "_id": "63e4a835956a2e76a213bd26",
            "submittedBody": {
                "id": "63cf696f4a6587652f03e6d50.038198367398770916",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon contact , très sympa et compétente",
                "creationDate": "2023-01-24T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "georges.prat@engie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d05fa50b-d104-434a-b424-001dff0639fd",
            "responseId": "63cf696f4a6587652f03e6d5",
            "receivedResponse": {
                "id": "63cf696f4a6587652f03e6d50.038198367398770916",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon contact , très sympa et compétente",
                "creationDate": "2023-01-24T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:27.392Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-20_06.15.10",
        "integration": {
            "ContactKey": "georges.prat@engie.com",
            "CustomerContactEmail": "georges.prat@engie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PRAT",
            "CustomerContactFirstName": "GEORGES",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ENGIE COFELY BL HPR",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Sarah",
            "XylemContactedPersonName": "NERON",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon contact , très sympa et compétente",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf696f4a6587652f03f703",
        "extendedResponse": {
            "_id": "63e4a835956a2e76a213bd27",
            "submittedBody": {
                "id": "63cf696f4a6587652f03f7030.30931172325856293",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité. Disponibilité",
                "creationDate": "2023-01-24T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.lacanal@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d2b963b9-f91a-4f6a-9e25-6433337554b2",
            "responseId": "63cf696f4a6587652f03f703",
            "receivedResponse": {
                "id": "63cf696f4a6587652f03f7030.30931172325856293",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité. Disponibilité",
                "creationDate": "2023-01-24T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:27.769Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "xavier.lacanal@veolia.com",
            "CustomerContactEmail": "xavier.lacanal@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACANAL",
            "CustomerContactFirstName": "XAVIER",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE CARCASSONNE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006021",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité. Disponibilité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf696f4a6587652f03f717",
        "extendedResponse": {
            "_id": "63e4a836956a2e76a213bd28",
            "submittedBody": {
                "id": "63cf696f4a6587652f03f7170.15865903275983162",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "votre réactivité  et vos conseils ",
                "creationDate": "2023-01-24T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "didier.rio@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "02965fe6-fab3-4448-a4a4-b402fa0ff3b3",
            "responseId": "63cf696f4a6587652f03f717",
            "receivedResponse": {
                "id": "63cf696f4a6587652f03f7170.15865903275983162",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "votre réactivité et vos conseils ",
                "creationDate": "2023-01-24T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:27.791Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "didier.rio@saur.com",
            "CustomerContactEmail": "didier.rio@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RIO",
            "CustomerContactFirstName": "Didier",
            "CustomerContactMobilePhone": "0673485516",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR MAINTENANCE",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006081",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "votre réactivité  et vos conseils ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf696f4a6587652f03f73a",
        "extendedResponse": {
            "_id": "63e4a836956a2e76a213bd29",
            "submittedBody": {
                "id": "63cf696f4a6587652f03f73a0.1669507385515061",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le délais de réponse est bcp trop long.....",
                "creationDate": "2023-01-24T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolas.fournier@genmills.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "3578436b-e00b-45fe-85d6-2eee81465fc8",
            "responseId": "63cf696f4a6587652f03f73a",
            "receivedResponse": {
                "id": "63cf696f4a6587652f03f73a0.1669507385515061",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le délais de réponse est beaucoup trop long.....",
                "creationDate": "2023-01-24T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-24T05:15:27.809Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "nicolas.fournier@genmills.com",
            "CustomerContactEmail": "nicolas.fournier@genmills.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FOURNIER",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HAAGEN DAZS",
            "CustomerCompanyGroupName": "HAAGEN DAZS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "DUVEREL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006166",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le délais de réponse est bcp trop long.....",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf696f4a6587652f03f748",
        "extendedResponse": {
            "_id": "63e4a837956a2e76a213bd2a",
            "submittedBody": {
                "id": "63cf696f4a6587652f03f7480.572087355112618",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+ : DELAI LIVRISON IMPECCABLE\n- : SMS et Mail reçus à 06h15 du matin... merci d'envoyer pendant les horaires de bureau!",
                "creationDate": "2023-01-24T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arnaud.boulange@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "04d875f1-8e9e-4a98-a166-666b5e340e83",
            "responseId": "63cf696f4a6587652f03f748",
            "receivedResponse": {
                "id": "63cf696f4a6587652f03f7480.572087355112618",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+ : DELAI LIVRAISON IMPECCABLE\n- : SMS et Mail reçus à 06h15 du matin... merci d'envoyer pendant les horaires de bureau!",
                "creationDate": "2023-01-24T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:27.815Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "arnaud.boulange@saur.com",
            "CustomerContactEmail": "arnaud.boulange@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOULANGE",
            "CustomerContactFirstName": "Arnaud",
            "CustomerContactMobilePhone": "0698039385",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR (ST GELY DU FESC)",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006185",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+ : DELAI LIVRISON IMPECCABLE\n- : SMS et Mail reçus à 06h15 du matin... merci d'envoyer pendant les horaires de bureau!",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "envoyer pendant les horaires de bureau! 08h--17h"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf696f4a6587652f03f776",
        "extendedResponse": {
            "_id": "63e4a837956a2e76a213bd2b",
            "submittedBody": {
                "id": "63cf696f4a6587652f03f7760.2264689808664213",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de la réponse.",
                "creationDate": "2023-01-24T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "p.bassot@shp-industries.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cc3cf3bd-c6c5-46d8-8767-c64925c3de98",
            "responseId": "63cf696f4a6587652f03f776",
            "receivedResponse": {
                "id": "63cf696f4a6587652f03f7760.2264689808664213",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de la réponse.",
                "creationDate": "2023-01-24T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:27.849Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "p.bassot@shp-industries.com",
            "CustomerContactEmail": "p.bassot@shp-industries.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BASSOT",
            "CustomerContactFirstName": "PHILIPPE",
            "CustomerContactMobilePhone": "0647258358",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SHP INDUSTRIES",
            "CustomerCompanyGroupName": "SHP INDUSTRIES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223005889",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de la réponse.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf69704a6587652f040270",
        "extendedResponse": {
            "_id": "63e4a838956a2e76a213bd2c",
            "submittedBody": {
                "id": "63cf69704a6587652f0402700.655421739568538",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité ",
                "creationDate": "2023-01-24T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "b.garcin@lestechniciensdeleau.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "85424ea3-63a6-4e6c-9bfb-07224302047f",
            "responseId": "63cf69704a6587652f040270",
            "receivedResponse": {
                "id": "63cf69704a6587652f0402700.655421739568538",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité ",
                "creationDate": "2023-01-24T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:28.024Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "b.garcin@lestechniciensdeleau.com",
            "CustomerContactEmail": "b.garcin@lestechniciensdeleau.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GARCIN",
            "CustomerContactFirstName": "Benoît",
            "CustomerContactMobilePhone": "0649166409",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LES TECHNICIENS DE L'EAU",
            "CustomerCompanyGroupName": "LES TECHNICIENS DE L’EAU",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223006092",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf69704a6587652f0402d0",
        "extendedResponse": {
            "_id": "63e4a838956a2e76a213bd2d",
            "submittedBody": {
                "id": "63cf69704a6587652f0402d00.9329174090590071",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité.",
                "creationDate": "2023-01-24T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mathilde@dmpi.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "83df07ae-588c-4169-a693-cfbbbd02be2a",
            "responseId": "63cf69704a6587652f0402d0",
            "receivedResponse": {
                "id": "63cf69704a6587652f0402d00.9329174090590071",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité.",
                "creationDate": "2023-01-24T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:28.059Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "mathilde@dmpi.fr",
            "CustomerContactEmail": "mathilde@dmpi.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "SOUCHARD",
            "CustomerContactFirstName": "MATHILDE",
            "CustomerContactMobilePhone": "0684899272",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DMPI",
            "CustomerCompanyGroupName": "DMPI",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006065",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf69704a6587652f0402d3",
        "extendedResponse": {
            "_id": "63e4a839956a2e76a213bd2e",
            "submittedBody": {
                "id": "63cf69704a6587652f0402d30.7523527785670108",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "un commercial à l'écoute ",
                "creationDate": "2023-01-24T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-michel.herve@cooperl.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "5c3519c6-f239-48af-9ea4-1532eaaab830",
            "responseId": "63cf69704a6587652f0402d3",
            "receivedResponse": {
                "id": "63cf69704a6587652f0402d30.7523527785670108",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "un commercial à l'écoute ",
                "creationDate": "2023-01-24T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:28.063Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "jean-michel.herve@cooperl.com",
            "CustomerContactEmail": "jean-michel.herve@cooperl.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HERVE",
            "CustomerContactFirstName": "Jean Michel",
            "CustomerContactMobilePhone": "0689103216",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DENITRAL",
            "CustomerCompanyGroupName": "DÉNITRAL SA",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006142",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "un commercial à l'écoute ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "des augmentations de tarif un peu moins agressives"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf69704a6587652f040304",
        "extendedResponse": {
            "_id": "63e4a839956a2e76a213bd2f",
            "submittedBody": {
                "id": "63cf69704a6587652f0403040.26514726023238366",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse correspondant à notre demande",
                "creationDate": "2023-01-24T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "h.lafond@01pompage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "abe6f2ee-96ab-435d-89eb-6ea33c784464",
            "responseId": "63cf69704a6587652f040304",
            "receivedResponse": {
                "id": "63cf69704a6587652f0403040.26514726023238366",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse correspondant à notre demande",
                "creationDate": "2023-01-24T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-24T05:15:28.065Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "h.lafond@01pompage.fr",
            "CustomerContactEmail": "h.lafond@01pompage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAFOND",
            "CustomerContactFirstName": "Herve",
            "CustomerContactMobilePhone": "0667438523",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "01 POMPAGE",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223006148",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse correspondant à notre demande",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Amélioration sur les prix"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63cf69704a6587652f040369",
        "extendedResponse": {
            "_id": "63e4a83a956a2e76a213bd30",
            "submittedBody": {
                "id": "63cf69704a6587652f0403690.709674145487867",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le site ne permet pas d'ajouter des éléments d'un devis à des éléments mis dans le panier pour grouper les commandes.\nNous recevons beaucoup plus de mail pour une seule commande qu'avant, cela fait beaucoup de mails pour \"pas grand chose\".\nLe site est plus ergonomique qu'avant.",
                "creationDate": "2023-01-24T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ines@dmpi.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "7797dca2-0397-459f-807d-a785602912c9",
            "responseId": "63cf69704a6587652f040369",
            "receivedResponse": {
                "id": "63cf69704a6587652f0403690.709674145487867",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le site ne permet pas d'ajouter des éléments d'un devis à des éléments mis dans le panier pour grouper les commandes.\nNous recevons beaucoup plus de mail pour une seule commande qu'avant, cela fait beaucoup de mails pour \"pas grand chose\".\nLe site est plus ergonomique qu'avant.",
                "creationDate": "2023-01-24T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-24T05:15:28.112Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-24_06.15.05",
        "integration": {
            "ContactKey": "ines@dmpi.fr",
            "CustomerContactEmail": "ines@dmpi.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "PORCQ",
            "CustomerContactFirstName": "Ines",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DMPI",
            "CustomerCompanyGroupName": "DMPI",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Patrice",
            "XylemContactedPersonName": "PAPONE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223006210",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le site ne permet pas d'ajouter des éléments d'un devis à des éléments mis dans le panier pour grouper les commandes.\nNous recevons beaucoup plus de mail pour une seule commande qu'avant, cela fait beaucoup de mails pour \"pas grand chose\".\nLe site est plus ergonomique qu'avant.",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Améliorer le passage de commande sur le site avec le fait d'ajouter des éléments du panier au devis sélectionné."
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2e654",
        "extendedResponse": {
            "_id": "63e4a83a956a2e76a213bd31",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2e6540.8497920258778882",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Concernant les pièces détachées, la réponse est toujours rapide, concernant le matériel neuf le délai de réponse dépasse souvent les 5 jours ouvrés.....",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stelec89@stelec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3ddd8acc-06db-4b4d-a8e2-f58ee61564ed",
            "responseId": "63d0baf24a6587652fa2e654",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2e6540.8497920258778882",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Concernant les pièces détachées, la réponse est toujours rapide, concernant le matériel neuf le délai de réponse dépasse souvent les 5 jours ouvrés.....",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.541Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "stelec89@stelec.fr",
            "CustomerContactEmail": "stelec89@stelec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELCLOS",
            "CustomerContactFirstName": "Pierre",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "STELEC ELECTRIC SERVICE",
            "CustomerCompanyGroupName": "STELEC / ELECTRIC SERVICE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223006554",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Concernant les pièces détachées, la réponse est toujours rapide, concernant le matériel neuf le délai de réponse dépasse souvent les 5 jours ouvrés.....",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2e65a",
        "extendedResponse": {
            "_id": "63e4a83b956a2e76a213bd32",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2e65a0.9394866957532682",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Offre commercial plutôt réactive",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "adamians@kiloutou-energie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ff4c2652-9618-4bdd-bc31-b227d93db168",
            "responseId": "63d0baf24a6587652fa2e65a",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2e65a0.9394866957532682",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Offre commercial plutôt réactive",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.548Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "adamians@kiloutou-energie.fr",
            "CustomerContactEmail": "adamians@kiloutou-energie.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DAMIANS",
            "CustomerContactFirstName": "Audrey",
            "CustomerContactMobilePhone": "0609229379",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KILOUTOU ENERGIES",
            "CustomerCompanyGroupName": "KILOUTOU - COMPTA FOURNISSEURR",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006551",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Offre commercial plutôt réactive",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2e68c",
        "extendedResponse": {
            "_id": "63e4a83b956a2e76a213bd33",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2e68c0.322504101100088",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "CE QUI M'A PLUS EST D OBTENIR UNE REPONSE RAPIDE DE VOTRE PART\nce qui m'a moins plus ce que l'objet de votre offre ne reprenais pas le nom de l'affaire (reçu 4 offres en même temps)",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bbigot@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6045cb99-fee8-43b8-a47e-76e9c11cb0e8",
            "responseId": "63d0baf24a6587652fa2e68c",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2e68c0.322504101100088",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "CE QUI M'A PLUS EST D OBTENIR UNE RÉPONSE RAPIDE DE VOTRE PART\nce qui m'a moins plus ce que l'objet de votre offre ne reprenais pas le nom de l'affaire (reçu 4 offres en même temps)",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.606Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "bbigot@lri-sodime.com",
            "CustomerContactEmail": "bbigot@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIGOT",
            "CustomerContactFirstName": "Bruno",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006317",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "CE QUI M'A PLUS EST D OBTENIR UNE REPONSE RAPIDE DE VOTRE PART\nce qui m'a moins plus ce que l'objet de votre offre ne reprenais pas le nom de l'affaire (reçu 4 offres en même temps)",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "rappeler le nom de l'affaire dans l'objet de votre offre "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2e692",
        "extendedResponse": {
            "_id": "63e4a83c956a2e76a213bd34",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2e6920.2508921484544906",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité !!!",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vvantrimpont@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a5b7bebd-b0c5-4af2-a1bd-2eff2521903f",
            "responseId": "63d0baf24a6587652fa2e692",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2e6920.2508921484544906",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité !!!",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.611Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "vvantrimpont@gressier.net",
            "CustomerContactEmail": "vvantrimpont@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANTRIMPONT",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0626329823",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER ET FILS",
            "CustomerCompanyGroupName": "ETS GRESSIER ET FILS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006332",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité !!!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2e6ab",
        "extendedResponse": {
            "_id": "63e4a83c956a2e76a213bd35",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2e6ab0.4795496950211675",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité des retours d'offres\nMise en service avec variateur HYDROVAR\nPompes bien conçues",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yann.thiebaut@dalkiafroidsolutions.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9baaf2ce-110c-4031-bf4b-2e4c39a451a8",
            "responseId": "63d0baf24a6587652fa2e6ab",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2e6ab0.4795496950211675",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité des retours d'offres\nMise en service avec variateur HYDROVAR\nPompes bien conçues",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.637Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "yann.thiebaut@dalkiafroidsolutions.com",
            "CustomerContactEmail": "yann.thiebaut@dalkiafroidsolutions.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THIEBAUT",
            "CustomerContactFirstName": "Yann",
            "CustomerContactMobilePhone": "0680921699",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA FRANCE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "EHRENFELD",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "EHRENFELD",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "223006359",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité des retours d'offres\nMise en service avec variateur HYDROVAR\nPompes bien conçues",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2e6cc",
        "extendedResponse": {
            "_id": "63e4a83c956a2e76a213bd36",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2e6cc0.2835219439244452",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Dynamisme et réactivité ",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "carl.lalanne@irri.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "15cfb4dc-ae9e-4429-a0f3-3aa168b82a6c",
            "responseId": "63d0baf24a6587652fa2e6cc",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2e6cc0.2835219439244452",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Dynamisme et réactivité ",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.673Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "carl.lalanne@irri.fr",
            "CustomerContactEmail": "carl.lalanne@irri.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LALANNE",
            "CustomerContactFirstName": "Carl",
            "CustomerContactMobilePhone": "0684249671",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "IRRI-PRO",
            "CustomerCompanyGroupName": "IRRI SARL",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006420",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Dynamisme et réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2ebf1",
        "extendedResponse": {
            "_id": "63e4a83d956a2e76a213bd37",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2ebf10.3382410280206596",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "R.A.S.",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "florian.raye@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e66d9838-dca8-4f72-beb5-3ec4021f85c4",
            "responseId": "63d0baf24a6587652fa2ebf1",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2ebf10.3382410280206596",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "R.A.S.",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.801Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "florian.raye@saur.com",
            "CustomerContactEmail": "florian.raye@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RAYE",
            "CustomerContactFirstName": "Florian",
            "CustomerContactMobilePhone": "0660247805",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR FRANCE EXPLOITATION SEGRE",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006561",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "R.A.S.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2eefc",
        "extendedResponse": {
            "_id": "63e4a83d956a2e76a213bd38",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2eefc0.48143057769099995",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "ponctuel - de bonne humeur - a redynamiser l'équipe - propose les catalogues - répond aux questions techniques -  a repris les devis en cours - et reste  toujours dispo pour toutes questions ",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2d8370a6-4a28-4b3b-88ed-b825401f74bc",
            "responseId": "63d0baf24a6587652fa2eefc",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2eefc0.48143057769099995",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "ponctuel - de bonne humeur - a redynamiser l'équipe - propose les catalogues - répond aux questions techniques - a repris les devis en cours - et reste toujours dispo pour toutes questions ",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.828Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Guillaume",
            "XylemContactedPersonName": "GEYER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "ponctuel - de bonne humeur - a redynamiser l'équipe - propose les catalogues - répond aux questions techniques -  a repris les devis en cours - et reste  toujours dispo pour toutes questions ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2ef0b",
        "extendedResponse": {
            "_id": "63e4a83e956a2e76a213bd39",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2ef0b0.900137042522213",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et détails techniques",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexandre.reboul@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "c03fe984-340f-4742-a63d-f22ba5599491",
            "responseId": "63d0baf24a6587652fa2ef0b",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2ef0b0.900137042522213",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et détails techniques",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.835Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "alexandre.reboul@dalkia.fr",
            "CustomerContactEmail": "alexandre.reboul@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REBOUL",
            "CustomerContactFirstName": "Alexandre",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELM CHAUFFAGE URBAIN GRAND LYON",
            "CustomerCompanyGroupName": "ELM OPERATIONS (11011)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Gaetan",
            "XylemContactedPersonName": "BRETEAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006490",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et détails techniques",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Rendre les offres plus lisibles"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf24a6587652fa2ef70",
        "extendedResponse": {
            "_id": "63e4a83e956a2e76a213bd3a",
            "submittedBody": {
                "id": "63d0baf24a6587652fa2ef700.730776089367307",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Toujours agréable de voir notre TCI Cédric, qui nous suit et nous accompagne lorsque nous en avons besoin.... Tout va très bien. ",
                "creationDate": "2023-01-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ggranet@limatech.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "10e6ec5f-fa9b-4e74-8141-e749b17f5eac",
            "responseId": "63d0baf24a6587652fa2ef70",
            "receivedResponse": {
                "id": "63d0baf24a6587652fa2ef700.730776089367307",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Toujours agréable de voir notre TCI Cédric, qui nous suit et nous accompagne lorsque nous en avons besoin.... Tout va très bien. ",
                "creationDate": "2023-01-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:30.896Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "ggranet@limatech.fr",
            "CustomerContactEmail": "ggranet@limatech.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GRANET",
            "CustomerContactFirstName": "Guillaume",
            "CustomerContactMobilePhone": "0627876049",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LIMATECH",
            "CustomerCompanyGroupName": "LIMATECH SARL",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Cédric",
            "XylemContactedPersonName": "ROYER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Toujours agréable de voir notre TCI Cédric, qui nous suit et nous accompagne lorsque nous en avons besoin.... Tout va très bien. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf3881eab11549af92d",
        "extendedResponse": {
            "_id": "63e4a83f956a2e76a213bd3b",
            "submittedBody": {
                "id": "63d0baf3881eab11549af92d0.8608736400953794",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison nickel !\nPas de point négatif à déclarer!",
                "creationDate": "2023-01-25T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0612581176"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d69986a8-5b40-42f6-9330-7ab5fde6b8eb",
            "responseId": "63d0baf3881eab11549af92d",
            "receivedResponse": {
                "id": "63d0baf3881eab11549af92d0.8608736400953794",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison nickel !\nPas de point négatif à déclarer!",
                "creationDate": "2023-01-25T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-25T05:15:31.137Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "0612581176",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. GONNOT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0612581176",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SCIRPE",
            "CustomerCompanyGroupName": "SCIRPE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SCIRPE/381822/0015",
            "XylemReference": "197721",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison nickel !\nPas de point négatif à déclarer!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d0baf3881eab11549af9a4",
        "extendedResponse": {
            "_id": "63e4a83f956a2e76a213bd3c",
            "submittedBody": {
                "id": "63d0baf3881eab11549af9a40.7699933808333519",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Trop d interlocuteur suivant les régions ",
                "creationDate": "2023-01-25T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0622254542"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "5146ddaf-faba-4869-acc7-1c54c6ae64e8",
            "responseId": "63d0baf3881eab11549af9a4",
            "receivedResponse": {
                "id": "63d0baf3881eab11549af9a40.7699933808333519",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Trop d interlocuteur suivant les régions ",
                "creationDate": "2023-01-25T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-25T05:15:31.192Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "0622254542",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. David BEZANNIER-HUSSON",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0622254542",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (710)",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Ana-Sofia",
            "XylemContactedPersonName": "LOURENCO",
            "XylemContactedPersonRole": "CRC",
            "CustomerReference": "349547",
            "XylemReference": "192136",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Trop d interlocuteur suivant les régions ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "De mettre 1 seul et unique interlocuteur pour 1 affaire "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c73be202fc59e353bf1",
        "extendedResponse": {
            "_id": "63e4a840956a2e76a213bd3d",
            "submittedBody": {
                "id": "63d20c73be202fc59e353bf10.2363050156310358",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Nous sommes dans la phase de pré- étude donc là visite a servie a réduire notre champs de recherche pour nous proposer les produits qui serai susceptible de nous intéressé pour les travaux que nous avons à réaliser sur notre site",
                "creationDate": "2023-01-26T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bessem.benghali-sc@airliquide.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b51893fe-db0f-4110-bd50-18388db0b1f1",
            "responseId": "63d20c73be202fc59e353bf1",
            "receivedResponse": {
                "id": "63d20c73be202fc59e353bf10.2363050156310358",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Nous sommes dans la phase de pré- étude donc là visite a servie a réduire notre champs de recherche pour nous proposer les produits qui serai susceptible de nous intéressé pour les travaux que nous avons à réaliser sur notre site",
                "creationDate": "2023-01-26T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-26T05:15:31.994Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "bessem.benghali-sc@airliquide.com",
            "CustomerContactEmail": "bessem.benghali-sc@airliquide.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BENGHALI",
            "CustomerContactFirstName": "Bessem",
            "CustomerContactMobilePhone": "0629908287",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AIR LIQUIDE FRANCE INDUSTRIE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "Gauthier",
            "XylemContactedPersonName": "DUFOUR",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Nous sommes dans la phase de pré- étude donc là visite a servie a réduire notre champs de recherche pour nous proposer les produits qui serai susceptible de nous intéressé pour les travaux que nous avons à réaliser sur notre site",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c74be202fc59e353c05",
        "extendedResponse": {
            "_id": "63e4a840956a2e76a213bd3e",
            "submittedBody": {
                "id": "63d20c74be202fc59e353c050.3524750039714841",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien a stipuler \nBien ",
                "creationDate": "2023-01-26T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolasgolovko@sasravey.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "16af2789-48a0-4385-a172-dd7a0add981e",
            "responseId": "63d20c74be202fc59e353c05",
            "receivedResponse": {
                "id": "63d20c74be202fc59e353c050.3524750039714841",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien a stipuler \nBien ",
                "creationDate": "2023-01-26T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-26T05:15:32.041Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "nicolasgolovko@sasravey.fr",
            "CustomerContactEmail": "nicolasgolovko@sasravey.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GOLOVKO",
            "CustomerContactFirstName": "NICOLAS",
            "CustomerContactMobilePhone": "0660031470",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RAVEY SA",
            "CustomerCompanyGroupName": "RAVEY  SA  ET CIE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "Gauthier",
            "XylemContactedPersonName": "DUFOUR",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien a stipuler \nBien ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c74be202fc59e353c0f",
        "extendedResponse": {
            "_id": "63e4a841956a2e76a213bd3f",
            "submittedBody": {
                "id": "63d20c74be202fc59e353c0f0.5456542884539735",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "CE QUI NOUS A PLU : Compétence, Proximité, Réactivité",
                "creationDate": "2023-01-26T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pierre-jean.remize@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8a492721-c3f7-413f-bd0b-e35ce115a7b0",
            "responseId": "63d20c74be202fc59e353c0f",
            "receivedResponse": {
                "id": "63d20c74be202fc59e353c0f0.5456542884539735",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "CE QUI NOUS A PLU : Compétence, Proximité, Réactivité",
                "creationDate": "2023-01-26T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.pos",
                                    "label": "Reachability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "joignabilite.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "joignabilite.pos",
                    "label": "Reachability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-26T05:15:32.066Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "pierre-jean.remize@veolia.com",
            "CustomerContactEmail": "pierre-jean.remize@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REMIZE",
            "CustomerContactFirstName": "PIERRE JEAN",
            "CustomerContactMobilePhone": "0614678262",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU 82",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "RIGAL",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "CE QUI NOUS A PLU : Compétence, Proximité, Réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c74881eab1154100b92",
        "extendedResponse": {
            "_id": "63e4a841956a2e76a213bd40",
            "submittedBody": {
                "id": "63d20c74881eab1154100b920.15779125408399963",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le délai annoncé à était respecter",
                "creationDate": "2023-01-26T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0626623081"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a684aec4-aa2b-45ca-8a60-184b42883a78",
            "responseId": "63d20c74881eab1154100b92",
            "receivedResponse": {
                "id": "63d20c74881eab1154100b920.15779125408399963",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le délai annoncé à était respecter",
                "creationDate": "2023-01-26T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-26T05:15:32.426Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "0626623081",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Fabrice BRAX",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0626623081",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CCL",
            "CustomerCompanyGroupName": "C.C.L.",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "03-9221201488",
            "XylemReference": "192715",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le délai annoncé à était respecter",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c74881eab115410139e",
        "extendedResponse": {
            "_id": "63e4a841956a2e76a213bd41",
            "submittedBody": {
                "id": "63d20c74881eab115410139e0.5112738763718276",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\nLe délais entre la demande du devis et la réception de celui-ci est un peu long (8j). J'ai eu un doute sur le traitement de ce dernier. \nBien cordialement,",
                "creationDate": "2023-01-26T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gilles.pernet@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "ab5385fc-486a-4dc3-8575-9caee44602fe",
            "responseId": "63d20c74881eab115410139e",
            "receivedResponse": {
                "id": "63d20c74881eab115410139e0.5112738763718276",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\nLe délais entre la demande du devis et la réception de celui-ci est un peu long (8j). J'ai eu un doute sur le traitement de ce dernier. \nBien cordialement,",
                "creationDate": "2023-01-26T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-26T05:15:32.882Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-26_06.15.09",
        "integration": {
            "ContactKey": "gilles.pernet@veolia.com",
            "CustomerContactEmail": "gilles.pernet@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PERNET",
            "CustomerContactFirstName": "Gilles",
            "CustomerContactMobilePhone": "0624049453",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA - MOSELLANE DES EAUX",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006895",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\nLe délais entre la demande du devis et la réception de celui-ci est un peu long (8j). J'ai eu un doute sur le traitement de ce dernier. \nBien cordialement,",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Plus de communication sur le fait que ma demande est bien en cours de traitement.\n"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c75881eab11541016ed",
        "extendedResponse": {
            "_id": "63e4a842956a2e76a213bd42",
            "submittedBody": {
                "id": "63d20c75881eab11541016ed0.12456985814108434",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de la réception de l'offre",
                "creationDate": "2023-01-26T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "manuel.sanchez1@equans.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b78e8941-7862-4916-bdd4-80b760f1ebb0",
            "responseId": "63d20c75881eab11541016ed",
            "receivedResponse": {
                "id": "63d20c75881eab11541016ed0.12456985814108434",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de la réception de l'offre",
                "creationDate": "2023-01-26T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-26T05:15:33.155Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-26_06.15.09",
        "integration": {
            "ContactKey": "manuel.sanchez1@equans.com",
            "CustomerContactEmail": "manuel.sanchez1@equans.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SANCHEZ",
            "CustomerContactFirstName": "Manuel",
            "CustomerContactMobilePhone": "0672415898",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AXIMA (EQUANS)",
            "CustomerCompanyGroupName": "COFELY (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006881",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de la réception de l'offre",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c75881eab11541018dd",
        "extendedResponse": {
            "_id": "63e4a842956a2e76a213bd43",
            "submittedBody": {
                "id": "63d20c75881eab11541018dd0.3567580908223116",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Service rapide et à l'écoute.",
                "creationDate": "2023-01-26T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "willy.benoni@smec82.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "26bf3f2a-e142-4785-9ba8-4f3a4716da95",
            "responseId": "63d20c75881eab11541018dd",
            "receivedResponse": {
                "id": "63d20c75881eab11541018dd0.3567580908223116",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Service rapide et à l'écoute.",
                "creationDate": "2023-01-26T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-26T05:15:33.192Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "willy.benoni@smec82.fr",
            "CustomerContactEmail": "willy.benoni@smec82.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BENONI",
            "CustomerContactFirstName": "Willy",
            "CustomerContactMobilePhone": "0621040551",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SYNDICAT EAUX CASTELSARRASIN",
            "CustomerCompanyGroupName": "ASS - SMEC",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "BORSALI",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "267",
            "XylemReference": "2RF046906",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Service rapide et à l'écoute.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c75881eab11541018fe",
        "extendedResponse": {
            "_id": "63e4a843956a2e76a213bd44",
            "submittedBody": {
                "id": "63d20c75881eab11541018fe0.2954608272207273",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse pour devis rapide + bonnes options ",
                "creationDate": "2023-01-26T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tom.tourneur@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7c637ef5-8f2b-48fd-ade8-452a2b234c4b",
            "responseId": "63d20c75881eab11541018fe",
            "receivedResponse": {
                "id": "63d20c75881eab11541018fe0.2954608272207273",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse pour devis rapide + bonnes options ",
                "creationDate": "2023-01-26T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-26T05:15:33.240Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-26_06.15.09",
        "integration": {
            "ContactKey": "tom.tourneur@saur.com",
            "CustomerContactEmail": "tom.tourneur@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TOURNEUR",
            "CustomerContactFirstName": "TOM",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006947",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse pour devis rapide + bonnes options ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c75881eab1154101916",
        "extendedResponse": {
            "_id": "63e4a843956a2e76a213bd45",
            "submittedBody": {
                "id": "63d20c75881eab11541019160.9718832858969944",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L’efficience dans le traitement de chaque dossier. ",
                "creationDate": "2023-01-26T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.blactot@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a5d18a65-acf5-44d8-aa74-b7940843f28f",
            "responseId": "63d20c75881eab1154101916",
            "receivedResponse": {
                "id": "63d20c75881eab11541019160.9718832858969944",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L efficience dans le traitement de chaque dossier. ",
                "creationDate": "2023-01-26T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-26T05:15:33.279Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-26_06.15.09",
        "integration": {
            "ContactKey": "laurent.blactot@veolia.com",
            "CustomerContactEmail": "laurent.blactot@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BLACTOT",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0634417940",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE EXPLOITATION",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rachid",
            "XylemSalespeopleName": "ABDELKRIM",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223006996",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L’efficience dans le traitement de chaque dossier. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c75881eab1154101921",
        "extendedResponse": {
            "_id": "63e4a844956a2e76a213bd46",
            "submittedBody": {
                "id": "63d20c75881eab11541019210.2916005820880634",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité",
                "creationDate": "2023-01-26T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.pourchel@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6eb49844-7741-41e2-a065-b2ce8706c19f",
            "responseId": "63d20c75881eab1154101921",
            "receivedResponse": {
                "id": "63d20c75881eab11541019210.2916005820880634",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité",
                "creationDate": "2023-01-26T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-26T05:15:33.297Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-26_06.15.09",
        "integration": {
            "ContactKey": "xavier.pourchel@veolia.com",
            "CustomerContactEmail": "xavier.pourchel@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POURCHEL",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0603492814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE SAINT OMER (STEP)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007015",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c75881eab11541019a0",
        "extendedResponse": {
            "_id": "63e4a844956a2e76a213bd47",
            "submittedBody": {
                "id": "63d20c75881eab11541019a00.23658246499678692",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "des offres reçues en bonne qualité",
                "creationDate": "2023-01-26T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "adel.ghabi.ext@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ba396ada-d8eb-47eb-8044-6e7b4c42092e",
            "responseId": "63d20c75881eab11541019a0",
            "receivedResponse": {
                "id": "63d20c75881eab11541019a00.23658246499678692",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "des offres reçues en bonne qualité",
                "creationDate": "2023-01-26T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-26T05:15:33.362Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-26_06.15.09",
        "integration": {
            "ContactKey": "adel.ghabi.ext@veolia.com",
            "CustomerContactEmail": "adel.ghabi.ext@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GHABI",
            "CustomerContactFirstName": "Adel",
            "CustomerContactMobilePhone": "0647116992",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OTV France Sud SERVICES",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007070",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "des offres reçues en bonne qualité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d20c75881eab11541019d0",
        "extendedResponse": {
            "_id": "63e4a845956a2e76a213bd48",
            "submittedBody": {
                "id": "63d20c75881eab11541019d00.6571974596169117",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonne reactivité ",
                "creationDate": "2023-01-26T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "etienne.mabille@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7e6183a5-83b4-4a19-af5a-7f0e4a541996",
            "responseId": "63d20c75881eab11541019d0",
            "receivedResponse": {
                "id": "63d20c75881eab11541019d00.6571974596169117",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonne réactivité ",
                "creationDate": "2023-01-26T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-26T05:15:33.405Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-26_06.15.09",
        "integration": {
            "ContactKey": "etienne.mabille@saur.com",
            "CustomerContactEmail": "etienne.mabille@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MABILLE",
            "CustomerContactFirstName": "Etienne",
            "CustomerContactMobilePhone": "0761054048",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Nijhuis Saur Industries",
            "CustomerCompanyGroupName": "SAUR",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Gauthier",
            "XylemContactedPersonName": "DUFOUR",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223007098",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonne reactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7dfc1eb99a9be53233",
        "extendedResponse": {
            "_id": "63e4a845956a2e76a213bd49",
            "submittedBody": {
                "id": "63d4af7dfc1eb99a9be532330.23780258386676345",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J'ai apprécié la conversation téléphonique avec mon interlocuteur. En revanche, j'ai dû le relancer plusieurs fois. Une fois que mon offre a été reçu, ce n'était pas exactement ce que j’avais demandé.",
                "creationDate": "2023-01-28T06:15:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "anthony.burgos@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "7b35a015-fb13-44b4-a021-09d67a744176",
            "responseId": "63d4af7dfc1eb99a9be53233",
            "receivedResponse": {
                "id": "63d4af7dfc1eb99a9be532330.23780258386676345",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J'ai apprécié la conversation téléphonique avec mon interlocuteur. En revanche, j'ai dû le relancer plusieurs fois. Une fois que mon offre a été reçu, ce n'était pas exactement ce que j avais demandé.",
                "creationDate": "2023-01-28T06:15:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-28T05:15:41.584Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "anthony.burgos@veolia.com",
            "CustomerContactEmail": "anthony.burgos@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BURGOS",
            "CustomerContactFirstName": "Anthony",
            "CustomerContactMobilePhone": "0634042249",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA - MOSELLANE DES EAUX",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007171",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J'ai apprécié la conversation téléphonique avec mon interlocuteur. En revanche, j'ai dû le relancer plusieurs fois. Une fois que mon offre a été reçu, ce n'était pas exactement ce que j’avais demandé.",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Une réponse plus rapide et une offre correcte du premier coup."
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7dfc1eb99a9be5325e",
        "extendedResponse": {
            "_id": "63e4a846956a2e76a213bd4a",
            "submittedBody": {
                "id": "63d4af7dfc1eb99a9be5325e0.7334183673950947",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2023-01-28T06:15:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rolampont@drekan.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5b05a502-51c5-491d-81de-f952b32a2cde",
            "responseId": "63d4af7dfc1eb99a9be5325e",
            "receivedResponse": {
                "id": "63d4af7dfc1eb99a9be5325e0.7334183673950947",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2023-01-28T06:15:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-01-28T05:15:41.614Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "rolampont@drekan.com",
            "CustomerContactEmail": "rolampont@drekan.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOISSET",
            "CustomerContactFirstName": "Jean-Manuel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DREKAN",
            "CustomerCompanyGroupName": "DREKAN ELECTROMECANIQUE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007047",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7d10133a1888484965",
        "extendedResponse": {
            "_id": "63e4a846956a2e76a213bd4b",
            "submittedBody": {
                "id": "63d4af7d10133a18884849650.5205924522090348",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tarif et délai très corrects",
                "creationDate": "2023-01-28T06:15:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "adrien.grandcolas@ip-france.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a871cfba-ad50-4683-a54f-8f4778ecc158",
            "responseId": "63d4af7d10133a1888484965",
            "receivedResponse": {
                "id": "63d4af7d10133a18884849650.5205924522090348",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tarif et délai très corrects",
                "creationDate": "2023-01-28T06:15:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:41.675Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "adrien.grandcolas@ip-france.net",
            "CustomerContactEmail": "adrien.grandcolas@ip-france.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GRANDCOLAS",
            "CustomerContactFirstName": "Adrien",
            "CustomerContactMobilePhone": "0603410328",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "IP FRANCE",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Nathalie",
            "XylemContactedPersonName": "STOESSEL",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "A8780-FONTENOY",
            "XylemReference": "3RF001603",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tarif et délai très corrects",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7d4d76ee48d6bc83d0",
        "extendedResponse": {
            "_id": "63e4a847956a2e76a213bd4c",
            "submittedBody": {
                "id": "63d4af7d4d76ee48d6bc83d00.40789322132955563",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réponse à cette question est obligatoire.",
                "creationDate": "2023-01-28T06:15:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederick.soulard@equans.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "3fa68b91-ef77-42c3-8436-1a10599ce304",
            "responseId": "63d4af7d4d76ee48d6bc83d0",
            "receivedResponse": {
                "id": "63d4af7d4d76ee48d6bc83d00.40789322132955563",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réponse à cette question est obligatoire.",
                "creationDate": "2023-01-28T06:15:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-28T05:15:41.790Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "frederick.soulard@equans.com",
            "CustomerContactEmail": "frederick.soulard@equans.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SOULARD",
            "CustomerContactFirstName": "Frédérick",
            "CustomerContactMobilePhone": "0608805774",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AXIMA-CONCEPT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Sarah",
            "XylemContactedPersonName": "NERON",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réponse à cette question est obligatoire.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7dfc1eb99a9be534fe",
        "extendedResponse": {
            "_id": "63e4a847956a2e76a213bd4d",
            "submittedBody": {
                "id": "63d4af7dfc1eb99a9be534fe0.8785166804594995",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le délai de vos réponse est correct, la pertinence est plutôt au RDV\nvous n'avez pas toujours les solutions dans votre gamme",
                "creationDate": "2023-01-28T06:15:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.andric@richardson.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "ceeb3aa0-610d-4467-93c0-e6080a90bb10",
            "responseId": "63d4af7dfc1eb99a9be534fe",
            "receivedResponse": {
                "id": "63d4af7dfc1eb99a9be534fe0.8785166804594995",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le délai de vos réponse est correct, la pertinence est plutôt au RDV\nvous n'avez pas toujours les solutions dans votre gamme",
                "creationDate": "2023-01-28T06:15:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "delai.pos",
                "competence.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-28T05:15:41.915Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "stephane.andric@richardson.fr",
            "CustomerContactEmail": "stephane.andric@richardson.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ANDRIC",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "0609520722",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RICHARDSON",
            "CustomerCompanyGroupName": "RICHARDSON",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007891",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le délai de vos réponse est correct, la pertinence est plutôt au RDV\nvous n'avez pas toujours les solutions dans votre gamme",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7d4d76ee48d6bc84f4",
        "extendedResponse": {
            "_id": "63e4a848956a2e76a213bd4e",
            "submittedBody": {
                "id": "63d4af7d4d76ee48d6bc84f40.6819333015567004",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Merci de ne plus m'envoyer de SMS à 6h00 du matin!!! \nSi non après pas de soucis particulier avec Xylem. Merci !",
                "creationDate": "2023-01-28T06:15:41+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0677612144"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "24eb3066-e39e-4e9c-95b1-b709aaa300f5",
            "responseId": "63d4af7d4d76ee48d6bc84f4",
            "receivedResponse": {
                "id": "63d4af7d4d76ee48d6bc84f40.6819333015567004",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Merci de ne plus m'envoyer de SMS à 6h00 du matin!!! \nSi non après pas de soucis particulier avec Xylem. Merci !",
                "creationDate": "2023-01-28T06:15:41Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:41.930Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-01-25_06.15.08",
        "integration": {
            "ContactKey": "0677612144",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BOU Christophe",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0677612144",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ DEGREMONT SERVICE",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "110020224500489904",
            "XylemReference": "192008",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Merci de ne plus m'envoyer de SMS à 6h00 du matin!!! \nSi non après pas de soucis particulier avec Xylem. Merci !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7efc1eb99a9be53614",
        "extendedResponse": {
            "_id": "63e4a848956a2e76a213bd4f",
            "submittedBody": {
                "id": "63d4af7efc1eb99a9be536140.5092404839395275",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Nous aimons votre réactivité pour obtenir les informations demandées.",
                "creationDate": "2023-01-28T06:15:42+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.potier@watertech.mu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b034f268-0da5-44f8-845d-444670e99d61",
            "responseId": "63d4af7efc1eb99a9be53614",
            "receivedResponse": {
                "id": "63d4af7efc1eb99a9be536140.5092404839395275",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Nous aimons votre réactivité pour obtenir les informations demandées.",
                "creationDate": "2023-01-28T06:15:42Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:42.029Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "sebastien.potier@watertech.mu",
            "CustomerContactEmail": "sebastien.potier@watertech.mu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POTIER",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "+23059753716",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "WATERTECH",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN DT",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007134",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Nous aimons votre réactivité pour obtenir les informations demandées.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7efc1eb99a9be53678",
        "extendedResponse": {
            "_id": "63e4a848956a2e76a213bd50",
            "submittedBody": {
                "id": "63d4af7efc1eb99a9be536780.89086136629669",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Compétences de vos collaborateurs - Précision des réponses",
                "creationDate": "2023-01-28T06:15:42+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.andre@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "7f0855f4-5ff2-4e1c-b203-3ff05612e99c",
            "responseId": "63d4af7efc1eb99a9be53678",
            "receivedResponse": {
                "id": "63d4af7efc1eb99a9be536780.89086136629669",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Compétences de vos collaborateurs - Précision des réponses",
                "creationDate": "2023-01-28T06:15:42Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:42.103Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "christophe.andre@suez.com",
            "CustomerContactEmail": "christophe.andre@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ANDRE",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "+33672980260",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (DEGREMONT) (STEP HOUPLIN)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007255",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Compétences de vos collaborateurs - Précision des réponses",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Rapidité de réponse (mais qui reste correcte attention !) . Nous connaissons les difficultés financières et de recrutement actuelles. Donc la charge de travail que cela engendre. Notre partenariat reste intacte, ne vous inquiétez pas. merci."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7efc1eb99a9be53680",
        "extendedResponse": {
            "_id": "63e4a849956a2e76a213bd51",
            "submittedBody": {
                "id": "63d4af7efc1eb99a9be536800.41584051221136775",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-01-28T06:15:42+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "htriboulet@cabinet-merlin.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "885f2080-14a7-4a78-89dc-04cb22da52fc",
            "responseId": "63d4af7efc1eb99a9be53680",
            "receivedResponse": {
                "id": "63d4af7efc1eb99a9be536800.41584051221136775",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-01-28T06:15:42Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:42.110Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "htriboulet@cabinet-merlin.fr",
            "CustomerContactEmail": "htriboulet@cabinet-merlin.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TRIBOULET",
            "CustomerContactFirstName": "Hervé",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CABINET MERLIN",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007265",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7efc1eb99a9be5380d",
        "extendedResponse": {
            "_id": "63e4a849956a2e76a213bd52",
            "submittedBody": {
                "id": "63d4af7efc1eb99a9be5380d0.9096037627322628",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et prix",
                "creationDate": "2023-01-28T06:15:42+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.chevalley@covap.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e17a0388-e637-4b2f-83c7-d25215b14cdf",
            "responseId": "63d4af7efc1eb99a9be5380d",
            "receivedResponse": {
                "id": "63d4af7efc1eb99a9be5380d0.9096037627322628",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et prix",
                "creationDate": "2023-01-28T06:15:42Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:42.536Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "s.chevalley@covap.fr",
            "CustomerContactEmail": "s.chevalley@covap.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHEVALLEY",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COVAP",
            "CustomerCompanyGroupName": "COVAP SA COOP",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007578",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et prix",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7efc1eb99a9be538d3",
        "extendedResponse": {
            "_id": "63e4a84a956a2e76a213bd53",
            "submittedBody": {
                "id": "63d4af7efc1eb99a9be538d30.3308745427552542",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Toujours aussi performants et compétents, merci, continuez.",
                "creationDate": "2023-01-28T06:15:42+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.bizern@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9d34bb16-9440-446b-882a-d9c260f68d4f",
            "responseId": "63d4af7efc1eb99a9be538d3",
            "receivedResponse": {
                "id": "63d4af7efc1eb99a9be538d30.3308745427552542",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Toujours aussi performants et compétents, merci, continuez.",
                "creationDate": "2023-01-28T06:15:42Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:42.746Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "kevin.bizern@veolia.com",
            "CustomerContactEmail": "kevin.bizern@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIZERN",
            "CustomerContactFirstName": "KEVIN",
            "CustomerContactMobilePhone": "0614479518",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (PORT LA NOUVELLE)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007806",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Toujours aussi performants et compétents, merci, continuez.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7efc1eb99a9be538d8",
        "extendedResponse": {
            "_id": "63e4a84a956a2e76a213bd54",
            "submittedBody": {
                "id": "63d4af7efc1eb99a9be538d80.30438577454262816",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très réactifs, devis détaillé. ",
                "creationDate": "2023-01-28T06:15:42+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.pyveteau@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "90539fe9-bc27-46bd-b578-4e260ae77170",
            "responseId": "63d4af7efc1eb99a9be538d8",
            "receivedResponse": {
                "id": "63d4af7efc1eb99a9be538d80.30438577454262816",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très réactifs, devis détaillé. ",
                "creationDate": "2023-01-28T06:15:42Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:42.756Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "emmanuel.pyveteau@saur.com",
            "CustomerContactEmail": "emmanuel.pyveteau@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PYVETEAU",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0660665712",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR 17",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Thierry",
            "XylemContactedPersonName": "BLONDET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007825",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très réactifs, devis détaillé. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d4af7efc1eb99a9be538f1",
        "extendedResponse": {
            "_id": "63e4a84b956a2e76a213bd55",
            "submittedBody": {
                "id": "63d4af7efc1eb99a9be538f10.6729831346612769",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité\n",
                "creationDate": "2023-01-28T06:15:42+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "paulo.marinho@henon.pro"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "963e8a95-1627-4984-9727-876e3b66e7ab",
            "responseId": "63d4af7efc1eb99a9be538f1",
            "receivedResponse": {
                "id": "63d4af7efc1eb99a9be538f10.6729831346612769",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité\n",
                "creationDate": "2023-01-28T06:15:42Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-28T05:15:42.788Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-28_06.15.14",
        "integration": {
            "ContactKey": "paulo.marinho@henon.pro",
            "CustomerContactEmail": "paulo.marinho@henon.pro",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DA SILVA",
            "CustomerContactFirstName": "Paulo",
            "CustomerContactMobilePhone": "0620039932",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HENON SAS",
            "CustomerCompanyGroupName": "HENON SAS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007860",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f2fc1eb99a9b9d9f4d",
        "extendedResponse": {
            "_id": "63e4a84b956a2e76a213bd56",
            "submittedBody": {
                "id": "63d8a3f2fc1eb99a9b9d9f4d0.7254771404478273",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le non respect des délais suivant vos devis ",
                "creationDate": "2023-01-31T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.capelle@tereva.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "168a9ce3-bcc5-404e-8d85-edfac8eeb257",
            "responseId": "63d8a3f2fc1eb99a9b9d9f4d",
            "receivedResponse": {
                "id": "63d8a3f2fc1eb99a9b9d9f4d0.7254771404478273",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le non respect des délais suivant vos devis ",
                "creationDate": "2023-01-31T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.neg",
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:30.967Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-30_06.15.03",
        "integration": {
            "ContactKey": "xavier.capelle@tereva.fr",
            "CustomerContactEmail": "xavier.capelle@tereva.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAPELLE",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0603915133",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREVA",
            "CustomerCompanyGroupName": "MBE (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Christian",
            "XylemContactedPersonName": "DODDI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le non respect des délais suivant vos devis ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Respecter les délais annoncés "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e4cbe",
        "extendedResponse": {
            "_id": "63e4a84c956a2e76a213bd57",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e4cbe0.3732796062232324",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "merci",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier@dossot.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e13e3430-9413-491c-a260-90fed984f36a",
            "responseId": "63d8a3f34d76ee48d68e4cbe",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e4cbe0.3732796062232324",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "merci",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.077Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "olivier@dossot.fr",
            "CustomerContactEmail": "olivier@dossot.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PONCIN",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DOSSOT",
            "CustomerCompanyGroupName": "ETS DOSSOT ET CIE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008137",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "merci",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f3fc1eb99a9b9d9fd6",
        "extendedResponse": {
            "_id": "63e4a84c956a2e76a213bd58",
            "submittedBody": {
                "id": "63d8a3f3fc1eb99a9b9d9fd60.8012092314527413",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Une personne tres compétente dans ce domaine,arrive a trouver des solutions la ou d'autre non pas trouver.\ntoujours disponnible par telephonne.",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "saphir.hanini@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8268eb38-3e11-4c2b-b56b-b56acdebe4ef",
            "responseId": "63d8a3f3fc1eb99a9b9d9fd6",
            "receivedResponse": {
                "id": "63d8a3f3fc1eb99a9b9d9fd60.8012092314527413",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Une personne très compétente dans ce domaine,arrive a trouver des solutions la ou d'autre non pas trouver.\ntoujours disponible par téléphone.",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.108Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-01-30_06.15.03",
        "integration": {
            "ContactKey": "saphir.hanini@veolia.com",
            "CustomerContactEmail": "saphir.hanini@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HANINI",
            "CustomerContactFirstName": "SAPHIR",
            "CustomerContactMobilePhone": "+352621248743",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA LUXEMBOURG",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Fabien",
            "XylemContactedPersonName": "CANNIZZARO",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Une personne tres compétente dans ce domaine,arrive a trouver des solutions la ou d'autre non pas trouver.\ntoujours disponnible par telephonne.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e4d96",
        "extendedResponse": {
            "_id": "63e4a84d956a2e76a213bd59",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e4d960.9583282953936236",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Présence Fiche technique avec le matériel.\nIncohérence du délai entre colonne article et dernière page de l’offre ",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "flahaye@bobinagearmoricain.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "1bb8ed1c-23e5-4d16-9674-c6f09bd9cbab",
            "responseId": "63d8a3f34d76ee48d68e4d96",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e4d960.9583282953936236",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Présence Fiche technique avec le matériel.\nIncohérence du délai entre colonne article et dernière page de l offre ",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.265Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "flahaye@bobinagearmoricain.fr",
            "CustomerContactEmail": "flahaye@bobinagearmoricain.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAHAYE",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0672366731",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BOBINAGE ARMORICAIN",
            "CustomerCompanyGroupName": "BOBINAGE ARMORICAIN",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008004",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Présence Fiche technique avec le matériel.\nIncohérence du délai entre colonne article et dernière page de l’offre ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Ne pas demander des avis et des enquêtes tous les mois…."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e4dec",
        "extendedResponse": {
            "_id": "63e4a84d956a2e76a213bd5a",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e4dec0.44082573455105534",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse\nFiche de sélection du produit fourni",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romain.modave@spiebatignolles.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "714f6241-60a7-4eb3-af8e-0639999ae113",
            "responseId": "63d8a3f34d76ee48d68e4dec",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e4dec0.44082573455105534",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse\nFiche de sélection du produit fourni",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.321Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "romain.modave@spiebatignolles.fr",
            "CustomerContactEmail": "romain.modave@spiebatignolles.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MODAVE",
            "CustomerContactFirstName": "Romain",
            "CustomerContactMobilePhone": "0630580561",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FARASSE FLUIDES",
            "CustomerCompanyGroupName": "FARASSE FLUIDES",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007986",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse\nFiche de sélection du produit fourni",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e4ec2",
        "extendedResponse": {
            "_id": "63e4a84d956a2e76a213bd5b",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e4ec20.6465268284346077",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "délai de réponse long",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "osmer.romero@st2mgroup.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "071656fe-73e9-4bb6-8cb4-6a179bcafcd6",
            "responseId": "63d8a3f34d76ee48d68e4ec2",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e4ec20.6465268284346077",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délai de réponse long",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.432Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "osmer.romero@st2mgroup.com",
            "CustomerContactEmail": "osmer.romero@st2mgroup.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROMERO",
            "CustomerContactFirstName": "OSMER",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ST2M",
            "CustomerCompanyGroupName": "ST2M",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": ".",
            "XylemSalespeopleName": "TCS ZONE IDF",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008098",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "délai de réponse long",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "réactivité"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e4ed3",
        "extendedResponse": {
            "_id": "63e4a84e956a2e76a213bd5c",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e4ed30.6856957053870945",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Retour rapide",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabrice.sannier@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "562a0a1a-ac8c-42ba-9264-d9912f8bea2c",
            "responseId": "63d8a3f34d76ee48d68e4ed3",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e4ed30.6856957053870945",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Retour rapide",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.464Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "fabrice.sannier@veolia.com",
            "CustomerContactEmail": "fabrice.sannier@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SANNIER",
            "CustomerContactFirstName": "Fabrice",
            "CustomerContactMobilePhone": "0611290937",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008134",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Retour rapide",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Neant"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e4ed9",
        "extendedResponse": {
            "_id": "63e4a84e956a2e76a213bd5d",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e4ed90.5052143984334416",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "je n'ai toujours pas eu mon devis",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ateliercannes@servicebobinage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "1714f0b0-9718-4aba-b0b8-e75499f5adeb",
            "responseId": "63d8a3f34d76ee48d68e4ed9",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e4ed90.5052143984334416",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "je n'ai toujours pas eu mon devis",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.473Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "ateliercannes@servicebobinage.com",
            "CustomerContactEmail": "ateliercannes@servicebobinage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FERNANDEZ",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERVICE BOBINAGE SBEMMC/BEM",
            "CustomerCompanyGroupName": "SBEMMC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223007069",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "je n'ai toujours pas eu mon devis",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e4f61",
        "extendedResponse": {
            "_id": "63e4a84f956a2e76a213bd5e",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e4f610.4413576564415682",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité, efficacité et professionnalisme.\n\nJ'aime moins que nos agences n'est pas le même commerciale",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "r.robin@belle-environnement.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b01a4e2b-a285-4e3b-ac55-955ab87bc899",
            "responseId": "63d8a3f34d76ee48d68e4f61",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e4f610.4413576564415682",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité, efficacité et professionnalisme.\n\nJ'aime moins que nos agences n'est pas le même commerciale",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.573Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "r.robin@belle-environnement.fr",
            "CustomerContactEmail": "r.robin@belle-environnement.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "ROBIN",
            "CustomerContactFirstName": "ROXANE",
            "CustomerContactMobilePhone": "0788645594",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BELLE ENVIRONNEMENT",
            "CustomerCompanyGroupName": "BELLE ENVIRONNEMENT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008219",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité, efficacité et professionnalisme.\n\nJ'aime moins que nos agences n'est pas le même commerciale",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e504c",
        "extendedResponse": {
            "_id": "63e4a84f956a2e76a213bd5f",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e504c0.29771968611436317",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la disponibilité et la rapidité de vos équipes ainsi que leur professionnalisme ",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "justin.descours@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3219cf7c-1b31-4c35-9b48-747c1e62bddb",
            "responseId": "63d8a3f34d76ee48d68e504c",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e504c0.29771968611436317",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la disponibilité et la rapidité de vos équipes ainsi que leur professionnalisme ",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.703Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "justin.descours@eiffage.com",
            "CustomerContactEmail": "justin.descours@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DESCOURS",
            "CustomerContactFirstName": "JUSTIN",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIFFAGE ENERGIE THERMIE IDF",
            "CustomerCompanyGroupName": "EES - CLEVIA IDF TI",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008154",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la disponibilité et la rapidité de vos équipes ainsi que leur professionnalisme ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d8a3f34d76ee48d68e5084",
        "extendedResponse": {
            "_id": "63e4a850956a2e76a213bd60",
            "submittedBody": {
                "id": "63d8a3f34d76ee48d68e50840.3784164619072714",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a plu : Clarté du devis, détail technique de l'offre et proposition technique et conseil",
                "creationDate": "2023-01-31T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dimitri.masiero@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e016eeea-166f-43f3-b3ad-c4990807e897",
            "responseId": "63d8a3f34d76ee48d68e5084",
            "receivedResponse": {
                "id": "63d8a3f34d76ee48d68e50840.3784164619072714",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a plu : Clarté du devis, détail technique de l'offre et proposition technique et conseil",
                "creationDate": "2023-01-31T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-01-31T05:15:31.727Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-01-31_06.15.08",
        "integration": {
            "ContactKey": "dimitri.masiero@veolia.com",
            "CustomerContactEmail": "dimitri.masiero@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MASIERO",
            "CustomerContactFirstName": "Dimitri",
            "CustomerContactMobilePhone": "+33614721226",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE TILLOY)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008325",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a plu : Clarté du devis, détail technique de l'offre et proposition technique et conseil",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57610133a1888b90b9d",
        "extendedResponse": {
            "_id": "63e4a850956a2e76a213bd61",
            "submittedBody": {
                "id": "63d9f57610133a1888b90b9d0.9625248734385827",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "REPONSE RAPIDE",
                "creationDate": "2023-02-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mremy@dexis.eu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "361cfb3c-398a-4cac-8f8d-fdd9fd296bf1",
            "responseId": "63d9f57610133a1888b90b9d",
            "receivedResponse": {
                "id": "63d9f57610133a1888b90b9d0.9625248734385827",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RÉPONSE RAPIDE",
                "creationDate": "2023-02-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:34.685Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "mremy@dexis.eu",
            "CustomerContactEmail": "mremy@dexis.eu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REMY",
            "CustomerContactFirstName": "Michel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BFL DEXIS",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008398",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "REPONSE RAPIDE",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57610133a1888b90ba8",
        "extendedResponse": {
            "_id": "63e4a851956a2e76a213bd62",
            "submittedBody": {
                "id": "63d9f57610133a1888b90ba80.7551725005587693",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tout c'est bien passé",
                "creationDate": "2023-02-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "g.rossignol@etc-silly.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b94b1740-ee25-43cd-aa37-e502331953f1",
            "responseId": "63d9f57610133a1888b90ba8",
            "receivedResponse": {
                "id": "63d9f57610133a1888b90ba80.7551725005587693",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tout c'est bien passé",
                "creationDate": "2023-02-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:34.710Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "g.rossignol@etc-silly.com",
            "CustomerContactEmail": "g.rossignol@etc-silly.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROSSIGNOL",
            "CustomerContactFirstName": "Gilles",
            "CustomerContactMobilePhone": "0776990443",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ETC",
            "CustomerCompanyGroupName": "ETC SARL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008439",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tout c'est bien passé",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f5764d76ee48d65a48bd",
        "extendedResponse": {
            "_id": "63e4a851956a2e76a213bd63",
            "submittedBody": {
                "id": "63d9f5764d76ee48d65a48bd0.866638814099834",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour,\nNous sommes  en attente de votre service,offre de prix ,  suivi en SAV",
                "creationDate": "2023-02-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "visambert@snic-chauffage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "599053da-1772-4cdf-8fdb-b378e5ce8044",
            "responseId": "63d9f5764d76ee48d65a48bd",
            "receivedResponse": {
                "id": "63d9f5764d76ee48d65a48bd0.866638814099834",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour,\nNous sommes en attente de votre service,offre de prix , suivi en SAV",
                "creationDate": "2023-02-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "service.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:34.740Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "visambert@snic-chauffage.fr",
            "CustomerContactEmail": "visambert@snic-chauffage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ISEMBERT",
            "CustomerContactFirstName": "VALERY",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SNIC",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Clement",
            "XylemContactedPersonName": "FAIVRE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour,\nNous sommes  en attente de votre service,offre de prix ,  suivi en SAV",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57610133a1888b90bcb",
        "extendedResponse": {
            "_id": "63e4a852956a2e76a213bd64",
            "submittedBody": {
                "id": "63d9f57610133a1888b90bcb0.7971485632571487",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis rapide, adapté, très bon échange commercial ..\nLa petite difficulté c'est les termes employés dans les devis (ex : Mini Cas .. Je ne savais pas de quoi il s'agissait !!) .. entre temps j'ai eu les explications !! donc tout va bien ..",
                "creationDate": "2023-02-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "v.gillet@advinsi.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "be6dd4fa-d684-46fe-919a-cd193d89761e",
            "responseId": "63d9f57610133a1888b90bcb",
            "receivedResponse": {
                "id": "63d9f57610133a1888b90bcb0.7971485632571487",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis rapide, adapté, très bon échange commercial ..\nLa petite difficulté c'est les termes employés dans les devis (ex : Mini Cas .. Je ne savais pas de quoi il s'agissait !!) .. entre temps j'ai eu les explications !! donc tout va bien ..",
                "creationDate": "2023-02-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "ecoute.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:34.771Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "v.gillet@advinsi.com",
            "CustomerContactEmail": "v.gillet@advinsi.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "GILLET",
            "CustomerContactFirstName": "Virginie",
            "CustomerContactMobilePhone": "0664557605",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ADVINSI",
            "CustomerCompanyGroupName": "SAS CASLA ADVINSI",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008539",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis rapide, adapté, très bon échange commercial ..\nLa petite difficulté c'est les termes employés dans les devis (ex : Mini Cas .. Je ne savais pas de quoi il s'agissait !!) .. entre temps j'ai eu les explications !! donc tout va bien ..",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57610133a1888b90bf3",
        "extendedResponse": {
            "_id": "63e4a852956a2e76a213bd65",
            "submittedBody": {
                "id": "63d9f57610133a1888b90bf30.7577875630007771",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Un très bon commercial, apportant bienveillance et conseils éclairés.",
                "creationDate": "2023-02-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romuald.lepy@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8afce2d9-dc6a-4496-98e7-2578d4dd3932",
            "responseId": "63d9f57610133a1888b90bf3",
            "receivedResponse": {
                "id": "63d9f57610133a1888b90bf30.7577875630007771",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Un très bon commercial, apportant bienveillance et conseils éclairés.",
                "creationDate": "2023-02-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:34.804Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "romuald.lepy@saur.com",
            "CustomerContactEmail": "romuald.lepy@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEPY",
            "CustomerContactFirstName": "Romuald",
            "CustomerContactMobilePhone": "0659808802",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008587",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Un très bon commercial, apportant bienveillance et conseils éclairés.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57610133a1888b90c71",
        "extendedResponse": {
            "_id": "63e4a852956a2e76a213bd66",
            "submittedBody": {
                "id": "63d9f57610133a1888b90c710.9909846709899535",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité, technicité ",
                "creationDate": "2023-02-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jocelyn.cotten@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a5198ce8-b8ed-48ab-ad00-ff70264a41b0",
            "responseId": "63d9f57610133a1888b90c71",
            "receivedResponse": {
                "id": "63d9f57610133a1888b90c710.9909846709899535",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité, technicité ",
                "creationDate": "2023-02-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:34.887Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "jocelyn.cotten@veolia.com",
            "CustomerContactEmail": "jocelyn.cotten@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COTTEN",
            "CustomerContactFirstName": "Jocelyn",
            "CustomerContactMobilePhone": "0634075108",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008757",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité, technicité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57710133a1888b90d2f",
        "extendedResponse": {
            "_id": "63e4a853956a2e76a213bd67",
            "submittedBody": {
                "id": "63d9f57710133a1888b90d2f0.1151116049015577",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Clarté de la réponse et conseil, recherche dans les dossiers Xylem de l historique du dossier",
                "creationDate": "2023-02-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gregoire.delvaux@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1eda0b62-12ea-4662-8906-b61f063b8f41",
            "responseId": "63d9f57710133a1888b90d2f",
            "receivedResponse": {
                "id": "63d9f57710133a1888b90d2f0.1151116049015577",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Clarté de la réponse et conseil, recherche dans les dossiers Xylem de l historique du dossier",
                "creationDate": "2023-02-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:35.052Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "gregoire.delvaux@veolia.com",
            "CustomerContactEmail": "gregoire.delvaux@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELVAUX",
            "CustomerContactFirstName": "Grégoire",
            "CustomerContactMobilePhone": "+33629442224",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE ARRAS)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Jean-Philippe",
            "XylemContactedPersonName": "VALTER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223008465",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Clarté de la réponse et conseil, recherche dans les dossiers Xylem de l historique du dossier",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57710133a1888b90d4d",
        "extendedResponse": {
            "_id": "63e4a853956a2e76a213bd68",
            "submittedBody": {
                "id": "63d9f57710133a1888b90d4d0.005262479939673881",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité, qualité de la réponse, prix",
                "creationDate": "2023-02-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jb.ketterle@irisconseil.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "846848e3-1243-49de-8630-64e5f1e09b62",
            "responseId": "63d9f57710133a1888b90d4d",
            "receivedResponse": {
                "id": "63d9f57710133a1888b90d4d0.005262479939673881",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité, qualité de la réponse, prix",
                "creationDate": "2023-02-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:35.099Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "jb.ketterle@irisconseil.fr",
            "CustomerContactEmail": "jb.ketterle@irisconseil.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "KETTERLE",
            "CustomerContactFirstName": "JEAN - BAPTISTE",
            "CustomerContactMobilePhone": "0632644207",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Iris Conseil",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Roberto",
            "XylemContactedPersonName": "FERNANDES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008508",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité, qualité de la réponse, prix",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57710133a1888b90d65",
        "extendedResponse": {
            "_id": "63e4a854956a2e76a213bd69",
            "submittedBody": {
                "id": "63d9f57710133a1888b90d650.08730984221423554",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "LA REACTIVITE DANS LES OFFRES",
                "creationDate": "2023-02-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "france.dupont@proservice-sema.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e4a6f5ff-989b-46a3-8f11-5db17296b735",
            "responseId": "63d9f57710133a1888b90d65",
            "receivedResponse": {
                "id": "63d9f57710133a1888b90d650.08730984221423554",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "LA RÉACTIVITÉ DANS LES OFFRES",
                "creationDate": "2023-02-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:35.138Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "france.dupont@proservice-sema.fr",
            "CustomerContactEmail": "france.dupont@proservice-sema.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DUPONT",
            "CustomerContactFirstName": "FRANCE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PROSERVICE",
            "CustomerCompanyGroupName": "PROSERVICE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008548",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "LA REACTIVITE DANS LES OFFRES",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57710133a1888b90dbe",
        "extendedResponse": {
            "_id": "63e4a854956a2e76a213bd6a",
            "submittedBody": {
                "id": "63d9f57710133a1888b90dbe0.647118890033306",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "f",
                "creationDate": "2023-02-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.sampieri@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "cfc38dfb-7ded-413c-b2bd-47f9c552ab98",
            "responseId": "63d9f57710133a1888b90dbe",
            "receivedResponse": {
                "id": "63d9f57710133a1888b90dbe0.647118890033306",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "f",
                "creationDate": "2023-02-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-01T05:15:35.155Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "alain.sampieri@veolia.com",
            "CustomerContactEmail": "alain.sampieri@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SAMPIERI",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "0620888587",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CMESE - CEO",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008558",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "f",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "f"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57710133a1888b90e2e",
        "extendedResponse": {
            "_id": "63e4a855956a2e76a213bd6b",
            "submittedBody": {
                "id": "63d9f57710133a1888b90e2e0.9616471854364914",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide ",
                "creationDate": "2023-02-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denis.leiros@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c9150190-ad01-4cf2-9fea-603de0aa9f3c",
            "responseId": "63d9f57710133a1888b90e2e",
            "receivedResponse": {
                "id": "63d9f57710133a1888b90e2e0.9616471854364914",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide ",
                "creationDate": "2023-02-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:35.262Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "denis.leiros@suez.com",
            "CustomerContactEmail": "denis.leiros@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEIROS",
            "CustomerContactFirstName": "DENIS",
            "CustomerContactMobilePhone": "0632311684",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008661",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57710133a1888b90e4c",
        "extendedResponse": {
            "_id": "63e4a855956a2e76a213bd6c",
            "submittedBody": {
                "id": "63d9f57710133a1888b90e4c0.883523666142803",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'interlocuteur de Xylem, M. Boltz, est réactif, à l'écoute, sait faire preuve de beaucoup de pédagogie, est très compétent techniquement et sait apporter des conseils précieux pour le projet.",
                "creationDate": "2023-02-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "antoine.garcia@grandbesancon.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5f49e230-7bab-4e38-a7bb-01e1a432a702",
            "responseId": "63d9f57710133a1888b90e4c",
            "receivedResponse": {
                "id": "63d9f57710133a1888b90e4c0.883523666142803",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'interlocuteur de Xylem, M. Boltz, est réactif, à l'écoute, sait faire preuve de beaucoup de pédagogie, est très compétent techniquement et sait apporter des conseils précieux pour le projet.",
                "creationDate": "2023-02-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-01T05:15:35.308Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "antoine.garcia@grandbesancon.fr",
            "CustomerContactEmail": "antoine.garcia@grandbesancon.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GARCIA",
            "CustomerContactFirstName": "Antoine",
            "CustomerContactMobilePhone": "0683169925",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRAND BESANCON METROPOLE",
            "CustomerCompanyGroupName": "GRAND BESANCON METROPOLE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Benoit",
            "XylemContactedPersonName": "BOLTZ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223008691",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'interlocuteur de Xylem, M. Boltz, est réactif, à l'écoute, sait faire preuve de beaucoup de pédagogie, est très compétent techniquement et sait apporter des conseils précieux pour le projet.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63d9f57710133a1888b90e97",
        "extendedResponse": {
            "_id": "63e4a856956a2e76a213bd6d",
            "submittedBody": {
                "id": "63d9f57710133a1888b90e970.9214353560878135",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS comme d'habitude.",
                "creationDate": "2023-02-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-claude.fath@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e5023840-af3f-4c03-98ae-450689aeaf56",
            "responseId": "63d9f57710133a1888b90e97",
            "receivedResponse": {
                "id": "63d9f57710133a1888b90e970.9214353560878135",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS comme d'habitude.",
                "creationDate": "2023-02-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-01T05:15:35.389Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "jean-claude.fath@veolia.com",
            "CustomerContactEmail": "jean-claude.fath@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FATH",
            "CustomerContactFirstName": "Jean-Claude",
            "CustomerContactMobilePhone": "0617946383",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008767",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS comme d'habitude.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f1f972a33e15809b1f",
        "extendedResponse": {
            "_id": "63e4a856956a2e76a213bd6e",
            "submittedBody": {
                "id": "63db46f1f972a33e15809b1f0.43287448916348503",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Il me manque 1 armoire, et je n’ai toujours pas de solution alors que mon chantier se termine dans 2 jours ",
                "creationDate": "2023-02-02T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0611744772"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "eabf1dce-ce82-434b-967e-198100590fd9",
            "responseId": "63db46f1f972a33e15809b1f",
            "receivedResponse": {
                "id": "63db46f1f972a33e15809b1f0.43287448916348503",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Il me manque 1 armoire, et je n ai toujours pas de solution alors que mon chantier se termine dans 2 jours ",
                "creationDate": "2023-02-02T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "manque.produit.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-02T05:15:29.445Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "0611744772",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "FRANCOIS MARION",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0611744772",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (710)",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Ana-Sofia",
            "XylemContactedPersonName": "LOURENCO",
            "XylemContactedPersonRole": "CRC",
            "CustomerReference": "349547",
            "XylemReference": "192137",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Il me manque 1 armoire, et je n’ai toujours pas de solution alors que mon chantier se termine dans 2 jours ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f1f972a33e15809bed",
        "extendedResponse": {
            "_id": "63e4a856956a2e76a213bd6f",
            "submittedBody": {
                "id": "63db46f1f972a33e15809bed0.6973165532997985",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Pas de problème \nBien reçu \nBien conditionné ",
                "creationDate": "2023-02-02T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0603916309"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "461bef88-97c4-442d-bd74-8ca164a59c72",
            "responseId": "63db46f1f972a33e15809bed",
            "receivedResponse": {
                "id": "63db46f1f972a33e15809bed0.6973165532997985",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Pas de problème \nBien reçu \nBien conditionné ",
                "creationDate": "2023-02-02T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:29.510Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "0603916309",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "DURIEU Gilles",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0603916309",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAV",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "C30601696",
            "XylemReference": "198933",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Pas de problème \nBien reçu \nBien conditionné ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Rien"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f1625675f36a194ffb",
        "extendedResponse": {
            "_id": "63e4a857956a2e76a213bd70",
            "submittedBody": {
                "id": "63db46f1625675f36a194ffb0.7526309625237146",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les détails techniques sont bien. \nAmélioration : envoyer les devis en réponse au mail de demande",
                "creationDate": "2023-02-02T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bertille.damet@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "9a2dd544-a7cd-4212-b701-49e9e2a7f1f0",
            "responseId": "63db46f1625675f36a194ffb",
            "receivedResponse": {
                "id": "63db46f1625675f36a194ffb0.7526309625237146",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les détails techniques sont bien. \nAmélioration : envoyer les devis en réponse au mail de demande",
                "creationDate": "2023-02-02T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-02-02T05:15:29.946Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "bertille.damet@saur.com",
            "CustomerContactEmail": "bertille.damet@saur.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DAMET",
            "CustomerContactFirstName": "BERTILLE",
            "CustomerContactMobilePhone": "0764714350",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009106",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les détails techniques sont bien. \nAmélioration : envoyer les devis en réponse au mail de demande",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f1625675f36a194ffe",
        "extendedResponse": {
            "_id": "63e4a857956a2e76a213bd71",
            "submittedBody": {
                "id": "63db46f1625675f36a194ffe0.5591887221853435",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "delai de réponse a ma demande de prix rapide",
                "creationDate": "2023-02-02T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "andre.david@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8b9302bf-a108-4a89-b09a-359da35c352c",
            "responseId": "63db46f1625675f36a194ffe",
            "receivedResponse": {
                "id": "63db46f1625675f36a194ffe0.5591887221853435",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délai de réponse a ma demande de prix rapide",
                "creationDate": "2023-02-02T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:29.947Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "andre.david@veolia.com",
            "CustomerContactEmail": "andre.david@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DAVID",
            "CustomerContactFirstName": "ANDRE",
            "CustomerContactMobilePhone": "0646211297",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009113",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "delai de réponse a ma demande de prix rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f1625675f36a195009",
        "extendedResponse": {
            "_id": "63e4a858956a2e76a213bd72",
            "submittedBody": {
                "id": "63db46f1625675f36a1950090.14923231757462174",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Prise en compte du besoin et rapidité de réponse",
                "creationDate": "2023-02-02T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hilaire.lecornec@roger-marteau.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cbb523bd-f998-4e84-91c4-0048b1fa728c",
            "responseId": "63db46f1625675f36a195009",
            "receivedResponse": {
                "id": "63db46f1625675f36a1950090.14923231757462174",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Prise en compte du besoin et rapidité de réponse",
                "creationDate": "2023-02-02T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:29.956Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactEmail": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE CORNEC",
            "CustomerContactFirstName": "Hilaire",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MARTEAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009115",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Prise en compte du besoin et rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a195103",
        "extendedResponse": {
            "_id": "63e4a858956a2e76a213bd73",
            "submittedBody": {
                "id": "63db46f2625675f36a1951030.10114344254218666",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rien",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arlette.monny@groupe-coriance.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a21f70ec-6d55-4fd4-94b3-7b7990283acf",
            "responseId": "63db46f2625675f36a195103",
            "receivedResponse": {
                "id": "63db46f2625675f36a1951030.10114344254218666",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rien",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.197Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "arlette.monny@groupe-coriance.fr",
            "CustomerContactEmail": "arlette.monny@groupe-coriance.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MONNY",
            "CustomerContactFirstName": "Arlette",
            "CustomerContactMobilePhone": "0750690303",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CORIANCE",
            "CustomerCompanyGroupName": "CORIANCE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Gaetan",
            "XylemContactedPersonName": "BRETEAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008021",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rien",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a1952be",
        "extendedResponse": {
            "_id": "63e4a859956a2e76a213bd74",
            "submittedBody": {
                "id": "63db46f2625675f36a1952be0.053586029635541044",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ludovic.lahaye@richardson.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "e5b07d07-4012-4577-aa69-e1e9628b9f90",
            "responseId": "63db46f2625675f36a1952be",
            "receivedResponse": {
                "id": "63db46f2625675f36a1952be0.053586029635541044",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.261Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "ludovic.lahaye@richardson.fr",
            "CustomerContactEmail": "ludovic.lahaye@richardson.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAHAYE",
            "CustomerContactFirstName": "LUDOVIC",
            "CustomerContactMobilePhone": "0619068333",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RICHARDSON",
            "CustomerCompanyGroupName": "RICHARDSON",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Léa",
            "XylemContactedPersonName": "TANNOUS",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223008572",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a19530a",
        "extendedResponse": {
            "_id": "63e4a859956a2e76a213bd75",
            "submittedBody": {
                "id": "63db46f2625675f36a19530a0.0044291119285659875",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je n'aime pas votre standard électronique d'accueil.",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pelus.hubert@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "314d5345-5d59-463a-b729-39dbdd8950b1",
            "responseId": "63db46f2625675f36a19530a",
            "receivedResponse": {
                "id": "63db46f2625675f36a19530a0.0044291119285659875",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je n'aime pas votre standard électronique d'accueil.",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.283Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "pelus.hubert@sade-cgth.fr",
            "CustomerContactEmail": "pelus.hubert@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PELUS",
            "CustomerContactFirstName": "HUBERT",
            "CustomerContactMobilePhone": "0609726334",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Fabrice",
            "XylemContactedPersonName": "ROBIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223008927",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je n'aime pas votre standard électronique d'accueil.",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Vous inspirer de la disponibilité et de la diligence de votre collaborateur Fabrice ROBIN.   "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a19576b",
        "extendedResponse": {
            "_id": "63e4a85a956a2e76a213bd76",
            "submittedBody": {
                "id": "63db46f2625675f36a19576b0.279290949885866",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité, conseil et détail de l'offre.",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "florent.briand@vinci-construction.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0e4d8ba5-37ec-4157-b726-a5c1ed512fc8",
            "responseId": "63db46f2625675f36a19576b",
            "receivedResponse": {
                "id": "63db46f2625675f36a19576b0.279290949885866",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité, conseil et détail de l'offre.",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.406Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "florent.briand@vinci-construction.fr",
            "CustomerContactEmail": "florent.briand@vinci-construction.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BRIAND",
            "CustomerContactFirstName": "Florent",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEA ENVIRONNEMENT 44",
            "CustomerCompanyGroupName": "VINCI CF (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "222047166",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité, conseil et détail de l'offre.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a195821",
        "extendedResponse": {
            "_id": "63e4a85a956a2e76a213bd77",
            "submittedBody": {
                "id": "63db46f2625675f36a1958210.7796469339137566",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je n'arrive pas à avoir un document, fichier PDF, du produit.",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "combe-valence@combe-industrie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "ad0ff73b-90f2-4738-8c31-d7476a99b7e7",
            "responseId": "63db46f2625675f36a195821",
            "receivedResponse": {
                "id": "63db46f2625675f36a1958210.7796469339137566",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je n'arrive pas à avoir un document, fichier PDF, du produit.",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.510Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "combe-valence@combe-industrie.fr",
            "CustomerContactEmail": "combe-valence@combe-industrie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROYER",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COMBE",
            "CustomerCompanyGroupName": "ETS COMBE ELECTROBOBINAGE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009116",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je n'arrive pas à avoir un document, fichier PDF, du produit.",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "avoir en pièce jointe du produit choisi, de la documentation pour joindre à notre offre de prix à notre client."
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a195842",
        "extendedResponse": {
            "_id": "63e4a85b956a2e76a213bd78",
            "submittedBody": {
                "id": "63db46f2625675f36a1958420.340313177541123",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide + documentation sur les offres ",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francois.matignon@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1fdc85af-4f8b-4b16-9036-693b7722fa5d",
            "responseId": "63db46f2625675f36a195842",
            "receivedResponse": {
                "id": "63db46f2625675f36a1958420.340313177541123",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide + documentation sur les offres ",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.560Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "francois.matignon@sofinther.fr",
            "CustomerContactEmail": "francois.matignon@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MATIGNON",
            "CustomerContactFirstName": "François",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER ANGERS",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009169",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide + documentation sur les offres ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a1958d3",
        "extendedResponse": {
            "_id": "63e4a85b956a2e76a213bd79",
            "submittedBody": {
                "id": "63db46f2625675f36a1958d30.10732609589788855",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bien pour les raisons suivantes:\nRapidité de réponse - pertinence de l'offre",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pro.eurobobinage@orange.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b3fdefe7-2552-4590-9d94-4f548225b17e",
            "responseId": "63db46f2625675f36a1958d3",
            "receivedResponse": {
                "id": "63db46f2625675f36a1958d30.10732609589788855",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bien pour les raisons suivantes:\nRapidité de réponse - pertinence de l'offre",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.600Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "pro.eurobobinage@orange.fr",
            "CustomerContactEmail": "pro.eurobobinage@orange.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ORSAT",
            "CustomerContactFirstName": "William",
            "CustomerContactMobilePhone": "0630529419",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EUROBOBINAGE",
            "CustomerCompanyGroupName": "EUROBOBINAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223009202",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bien pour les raisons suivantes:\nRapidité de réponse - pertinence de l'offre",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a1958d4",
        "extendedResponse": {
            "_id": "63e4a85c956a2e76a213bd7a",
            "submittedBody": {
                "id": "63db46f2625675f36a1958d40.9939902657960851",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la reactivité",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ateliercannes@servicebobinage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "792ebe88-ae49-49bc-af69-0be96b34aaea",
            "responseId": "63db46f2625675f36a1958d4",
            "receivedResponse": {
                "id": "63db46f2625675f36a1958d40.9939902657960851",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.602Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "ateliercannes@servicebobinage.com",
            "CustomerContactEmail": "ateliercannes@servicebobinage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FERNANDEZ",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERVICE BOBINAGE SBEMMC/BEM",
            "CustomerCompanyGroupName": "SBEMMC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223009203",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la reactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a1958eb",
        "extendedResponse": {
            "_id": "63e4a85c956a2e76a213bd7b",
            "submittedBody": {
                "id": "63db46f2625675f36a1958eb0.6345845793763107",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "sympathie\nreactivité",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.chamard@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1389336a-8755-4c81-ac68-1e53e9b47383",
            "responseId": "63db46f2625675f36a1958eb",
            "receivedResponse": {
                "id": "63db46f2625675f36a1958eb0.6345845793763107",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "sympathie\nréactivité",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.623Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "xavier.chamard@eiffage.com",
            "CustomerContactEmail": "xavier.chamard@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHAMARD",
            "CustomerContactFirstName": "XAVIER",
            "CustomerContactMobilePhone": "0164812410",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIFFAGE TRAVAUX PUBLICS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009221",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "sympathie\nreactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a195903",
        "extendedResponse": {
            "_id": "63e4a85c956a2e76a213bd7c",
            "submittedBody": {
                "id": "63db46f2625675f36a1959030.15945678428398335",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La compréhension de la demande, la technicité de la réponse, et la rapidité de la réponse",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sabine.vaillant@vinci-construction.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1f0ce97e-8c21-4d5a-ab32-e05ed99e18ca",
            "responseId": "63db46f2625675f36a195903",
            "receivedResponse": {
                "id": "63db46f2625675f36a1959030.15945678428398335",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La compréhension de la demande, la technicité de la réponse, et la rapidité de la réponse",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.661Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "sabine.vaillant@vinci-construction.fr",
            "CustomerContactEmail": "sabine.vaillant@vinci-construction.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "VAILLANT",
            "CustomerContactFirstName": "SABINE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEA SUD HYDRAULIQUE - PROCESS",
            "CustomerCompanyGroupName": "VINCI CF (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009256",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La compréhension de la demande, la technicité de la réponse, et la rapidité de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a19590a",
        "extendedResponse": {
            "_id": "63e4a85d956a2e76a213bd7d",
            "submittedBody": {
                "id": "63db46f2625675f36a19590a0.8920827428697171",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui me plaît c'est la réactivité et la disponibilité de votre service",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-sebastien.afonso@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b55737b5-8bdd-4f58-bd44-50b2564913d3",
            "responseId": "63db46f2625675f36a19590a",
            "receivedResponse": {
                "id": "63db46f2625675f36a19590a0.8920827428697171",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui me plaît c'est la réactivité et la disponibilité de votre service",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.669Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "jean-sebastien.afonso@saur.com",
            "CustomerContactEmail": "jean-sebastien.afonso@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "AFONSO",
            "CustomerContactFirstName": "Jean-Sebastien",
            "CustomerContactMobilePhone": "0764894668",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009264",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui me plaît c'est la réactivité et la disponibilité de votre service",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63db46f2625675f36a195920",
        "extendedResponse": {
            "_id": "63e4a85d956a2e76a213bd7e",
            "submittedBody": {
                "id": "63db46f2625675f36a1959200.4562666082058884",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a plu : rapidité de réponse, prix.",
                "creationDate": "2023-02-02T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "seelec.etude@wanadoo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "31921ccf-af04-4e5a-afb5-d849fc4b7c8d",
            "responseId": "63db46f2625675f36a195920",
            "receivedResponse": {
                "id": "63db46f2625675f36a1959200.4562666082058884",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a plu : rapidité de réponse, prix.",
                "creationDate": "2023-02-02T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-02T05:15:30.701Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-02_06.15.08",
        "integration": {
            "ContactKey": "seelec.etude@wanadoo.fr",
            "CustomerContactEmail": "seelec.etude@wanadoo.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "FOUILLOUSE",
            "CustomerContactFirstName": "ANNIE",
            "CustomerContactMobilePhone": "0555621637",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEELEC",
            "CustomerCompanyGroupName": "SEELEC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "RICHARD",
            "XylemContactedPersonRole": "Responsable régional ventes",
            "CustomerReference": "",
            "XylemReference": "223009295",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a plu : rapidité de réponse, prix.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63dc986bc33a29e0a646a194",
        "extendedResponse": {
            "_id": "63e4a85e956a2e76a213bd7f",
            "submittedBody": {
                "id": "63dc986bc33a29e0a646a1940.11454028531557636",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité efficacité ",
                "creationDate": "2023-02-03T06:15:23+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0687997498"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "efecaa2d-cde6-4233-84b1-163df2e80fb9",
            "responseId": "63dc986bc33a29e0a646a194",
            "receivedResponse": {
                "id": "63dc986bc33a29e0a646a1940.11454028531557636",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité efficacité ",
                "creationDate": "2023-02-03T06:15:23Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-03T05:15:23.621Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "0687997498",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "GAU Stephane",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0687997498",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COM. DE COM. DE LA THIERACHE",
            "CustomerCompanyGroupName": "COMMUNAUTE COMMUNES THIERACHE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "24 01 23 XYLEM",
            "XylemReference": "199298",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité efficacité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63dc986bc33a29e0a646a1a6",
        "extendedResponse": {
            "_id": "63e4a85e956a2e76a213bd80",
            "submittedBody": {
                "id": "63dc986bc33a29e0a646a1a60.604925745754807",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison dans le temps imparti et livreur sympa ",
                "creationDate": "2023-02-03T06:15:23+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0620692270"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6d9ad7a7-efb6-4eb8-a50c-5fb89429aacb",
            "responseId": "63dc986bc33a29e0a646a1a6",
            "receivedResponse": {
                "id": "63dc986bc33a29e0a646a1a60.604925745754807",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison dans le temps imparti et livreur sympa ",
                "creationDate": "2023-02-03T06:15:23Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-03T05:15:23.651Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "0620692270",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "MR ALEX",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0620692270",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PERFECT POMPES MOTEURS",
            "CustomerCompanyGroupName": "PERFECT POMPES MOTEURS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CD0392",
            "XylemReference": "196337",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison dans le temps imparti et livreur sympa ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63dde9ebfc1dd4868d77bda3",
        "extendedResponse": {
            "_id": "63e4a85f956a2e76a213bd81",
            "submittedBody": {
                "id": "63dde9ebfc1dd4868d77bda30.847011491356338",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour \nEn moyenne, délai de livraison 1 semaine pour du matériel en stock !",
                "creationDate": "2023-02-04T06:15:23+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0603603647"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "d7453c07-7903-44ce-8df8-4d2de629c399",
            "responseId": "63dde9ebfc1dd4868d77bda3",
            "receivedResponse": {
                "id": "63dde9ebfc1dd4868d77bda30.847011491356338",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour \nEn moyenne, délai de livraison 1 semaine pour du matériel en stock !",
                "creationDate": "2023-02-04T06:15:23Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.neg",
                                    "label": "Availability / Stock (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "dispo.neg",
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.neg",
                    "label": "Availability / Stock (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-02-04T05:15:23.665Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-01_06.15.12",
        "integration": {
            "ContactKey": "0603603647",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "WOLFF Sylvain",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0603603647",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BOBINAGE DU TRIANGLE",
            "CustomerCompanyGroupName": "BOBINAGE DU TRIANGLE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CD0017",
            "XylemReference": "198909",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour \nEn moyenne, délai de livraison 1 semaine pour du matériel en stock !",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Wolff Sylvain \",\n  \"mail\": \"Bobinagedutriangle@hotmail.fr \"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63dde9ebfc1dd4868d77be8d",
        "extendedResponse": {
            "_id": "63e4a85f956a2e76a213bd82",
            "submittedBody": {
                "id": "63dde9ebfc1dd4868d77be8d0.6905814347556396",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité et le conseil",
                "creationDate": "2023-02-04T06:15:23+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sparis@thel-etb.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "eb3ad58e-d4e5-4cc5-aebf-b6637b592642",
            "responseId": "63dde9ebfc1dd4868d77be8d",
            "receivedResponse": {
                "id": "63dde9ebfc1dd4868d77be8d0.6905814347556396",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité et le conseil",
                "creationDate": "2023-02-04T06:15:23Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-04T05:15:23.809Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-04_06.15.06",
        "integration": {
            "ContactKey": "sparis@thel-etb.fr",
            "CustomerContactEmail": "sparis@thel-etb.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PARIS",
            "CustomerContactFirstName": "SYLVAIN",
            "CustomerContactMobilePhone": "0631115240",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "THEL-ETB",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Remy",
            "XylemContactedPersonName": "BERNETIERE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223009458",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité et le conseil",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de706732a2d1a3209cf3",
        "extendedResponse": {
            "_id": "63e4a860956a2e76a213bd83",
            "submittedBody": {
                "id": "63e1de706732a2d1a3209cf30.9822203607449207",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide ",
                "creationDate": "2023-02-07T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0760696432"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2439fdc2-3d98-40d3-9847-649307538845",
            "responseId": "63e1de706732a2d1a3209cf3",
            "receivedResponse": {
                "id": "63e1de706732a2d1a3209cf30.9822203607449207",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide ",
                "creationDate": "2023-02-07T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:28.664Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "0760696432",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LEBOURSICAUD Denis",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0760696432",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GOLFE MORBIHAN Vannes Agglomération",
            "CustomerCompanyGroupName": "VANNES AGGLOMERATION",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "23000224",
            "XylemReference": "199560",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de70ae1afd05f56e68e4",
        "extendedResponse": {
            "_id": "63e4a860956a2e76a213bd84",
            "submittedBody": {
                "id": "63e1de70ae1afd05f56e68e40.5013004298928556",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réponse.",
                "creationDate": "2023-02-07T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrick.marajo@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2f80e1f5-1f15-450d-a656-5828194e49a4",
            "responseId": "63e1de70ae1afd05f56e68e4",
            "receivedResponse": {
                "id": "63e1de70ae1afd05f56e68e40.5013004298928556",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réponse.",
                "creationDate": "2023-02-07T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:28.742Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "patrick.marajo@suez.com",
            "CustomerContactEmail": "patrick.marajo@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARAJO",
            "CustomerContactFirstName": "Patrick",
            "CustomerContactMobilePhone": "0688630813",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (STEP DE SETE)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009808",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réponse.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de70ae1afd05f56e6912",
        "extendedResponse": {
            "_id": "63e4a861956a2e76a213bd85",
            "submittedBody": {
                "id": "63e1de70ae1afd05f56e69120.2862090106995181",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité du service demandé.",
                "creationDate": "2023-02-07T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "serge.foray@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6c677109-510f-4408-ad31-39d1aa3e6255",
            "responseId": "63e1de70ae1afd05f56e6912",
            "receivedResponse": {
                "id": "63e1de70ae1afd05f56e69120.2862090106995181",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité du service demandé.",
                "creationDate": "2023-02-07T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:28.775Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "serge.foray@suez.com",
            "CustomerContactEmail": "serge.foray@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FORAY",
            "CustomerContactFirstName": "Serge",
            "CustomerContactMobilePhone": "0660261295",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009900",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité du service demandé.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de70ae1afd05f56e6971",
        "extendedResponse": {
            "_id": "63e4a861956a2e76a213bd86",
            "submittedBody": {
                "id": "63e1de70ae1afd05f56e69710.25085360237848753",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le plus la rapidite",
                "creationDate": "2023-02-07T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.gallard@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "957279c7-a5a0-4d62-a1fd-dc7422ed3d0a",
            "responseId": "63e1de70ae1afd05f56e6971",
            "receivedResponse": {
                "id": "63e1de70ae1afd05f56e69710.25085360237848753",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le plus la rapidité",
                "creationDate": "2023-02-07T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:28.796Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "stephane.gallard@veolia.com",
            "CustomerContactEmail": "stephane.gallard@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GALLARD",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "0611112439",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA ECHM",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009959",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le plus la rapidite",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de70ae1afd05f56e6a35",
        "extendedResponse": {
            "_id": "63ef86898196e3c449e109b5",
            "submittedBody": {
                "id": "63e1de70ae1afd05f56e6a350.4349777439945346",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Une demande par téléphone en 3 fois pour finir par un mail ! \nEt un standard non joignable pendant quelques jours !\nAprès  une réponse rapide et efficace qui atténue l'attente de communications téléphoniques trop compliquées. ",
                "creationDate": "2023-02-07T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.day@ca-saintdie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "a2f4c6eb-2cc6-4597-9099-5d1f30e0f32b",
            "responseId": "63e1de70ae1afd05f56e6a35",
            "receivedResponse": {
                "id": "63e1de70ae1afd05f56e6a350.4349777439945346",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Une demande par téléphone en 3 fois pour finir par un mail ! \nEt un standard non joignable pendant quelques jours !\nAprès une réponse rapide et efficace qui atténue l'attente de communications téléphoniques trop compliquées. ",
                "creationDate": "2023-02-07T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.pos",
                                    "label": "Reachability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "joignabilite.pos",
                "efficacite.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "joignabilite.pos",
                    "label": "Reachability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-07T05:15:28.920Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "christophe.day@ca-saintdie.fr",
            "CustomerContactEmail": "christophe.day@ca-saintdie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DAY",
            "CustomerContactFirstName": "CHRISTOPHE",
            "CustomerContactMobilePhone": "0788302193",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Communauté d'Agglomération de Saint-Dié-des-Vosges",
            "CustomerCompanyGroupName": "SIVU D ASSAINISSEMENT DU VAL",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009568",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Une demande par téléphone en 3 fois pour finir par un mail ! \nEt un standard non joignable pendant quelques jours !\nAprès  une réponse rapide et efficace qui atténue l'attente de communications téléphoniques trop compliquées. ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Une plateforme téléphonique plus  efficace !"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de70ae1afd05f56e6aa4",
        "extendedResponse": {
            "_id": "63e4a862956a2e76a213bd87",
            "submittedBody": {
                "id": "63e1de70ae1afd05f56e6aa40.599577017155603",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapide - efficace ",
                "creationDate": "2023-02-07T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9af371e0-5997-40c2-b6a4-ef8022eab946",
            "responseId": "63e1de70ae1afd05f56e6aa4",
            "receivedResponse": {
                "id": "63e1de70ae1afd05f56e6aa40.599577017155603",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapide - efficace ",
                "creationDate": "2023-02-07T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:28.970Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009595",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapide - efficace ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de71ae1afd05f56e6b41",
        "extendedResponse": {
            "_id": "63e4a862956a2e76a213bd88",
            "submittedBody": {
                "id": "63e1de71ae1afd05f56e6b410.967728875096163",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-02-07T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.wattrelot@thermap.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "022072b0-e468-4bf5-a5fd-db8dc8075cd1",
            "responseId": "63e1de71ae1afd05f56e6b41",
            "receivedResponse": {
                "id": "63e1de71ae1afd05f56e6b410.967728875096163",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-02-07T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:29.050Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "emmanuel.wattrelot@thermap.fr",
            "CustomerContactEmail": "emmanuel.wattrelot@thermap.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "WATTRELOT",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0757439229",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "THERMAP",
            "CustomerCompanyGroupName": "THERMAP",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223009669",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de71ae1afd05f56e6b55",
        "extendedResponse": {
            "_id": "63e4a863956a2e76a213bd89",
            "submittedBody": {
                "id": "63e1de71ae1afd05f56e6b550.2399050386932835",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Top",
                "creationDate": "2023-02-07T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denis.franger-riteau@mirey-sa.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0d561e0b-a576-4c89-895c-96133fdbe3ba",
            "responseId": "63e1de71ae1afd05f56e6b55",
            "receivedResponse": {
                "id": "63e1de71ae1afd05f56e6b550.2399050386932835",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Top",
                "creationDate": "2023-02-07T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-07T05:15:29.085Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "denis.franger-riteau@mirey-sa.com",
            "CustomerContactEmail": "denis.franger-riteau@mirey-sa.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FRANGER-RITEAU",
            "CustomerContactFirstName": "Denis",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MIREY",
            "CustomerCompanyGroupName": "MIREY SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009703",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Top",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de71ae1afd05f56e6b78",
        "extendedResponse": {
            "_id": "63e4a863956a2e76a213bd8a",
            "submittedBody": {
                "id": "63e1de71ae1afd05f56e6b780.34359653160739345",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "reactivité",
                "creationDate": "2023-02-07T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vplottier@savoietransmissions.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bf20090f-4bee-431b-bb9d-56762d898173",
            "responseId": "63e1de71ae1afd05f56e6b78",
            "receivedResponse": {
                "id": "63e1de71ae1afd05f56e6b780.34359653160739345",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité",
                "creationDate": "2023-02-07T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:29.118Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "vplottier@savoietransmissions.com",
            "CustomerContactEmail": "vplottier@savoietransmissions.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "PLOTTIER",
            "CustomerContactFirstName": "Valérie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAVOIE TRANSMISSIONS",
            "CustomerCompanyGroupName": "SAVOIE TRANSMISSIONS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009723",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "reactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de71ae1afd05f56e6c66",
        "extendedResponse": {
            "_id": "63e4a863956a2e76a213bd8b",
            "submittedBody": {
                "id": "63e1de71ae1afd05f56e6c660.9236661824012187",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité ",
                "creationDate": "2023-02-07T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gregory.diacre@ortec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "284839ec-b43d-4f17-9ba1-4aa35add52fd",
            "responseId": "63e1de71ae1afd05f56e6c66",
            "receivedResponse": {
                "id": "63e1de71ae1afd05f56e6c660.9236661824012187",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité ",
                "creationDate": "2023-02-07T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:29.219Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "gregory.diacre@ortec.fr",
            "CustomerContactEmail": "gregory.diacre@ortec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DIACRE",
            "CustomerContactFirstName": "Gregory",
            "CustomerContactMobilePhone": "0767693472",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ORTEC",
            "CustomerCompanyGroupName": "ORTEC ENVIRONNEMENT",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009818",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de71ae1afd05f56e6d52",
        "extendedResponse": {
            "_id": "63e4a864956a2e76a213bd8c",
            "submittedBody": {
                "id": "63e1de71ae1afd05f56e6d520.2776789649310003",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité, Réponse correspondant à la demande",
                "creationDate": "2023-02-07T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "carine.dumoulin@aeci01.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "81ef8e6f-bb02-43cd-988a-94c3db9a0e9c",
            "responseId": "63e1de71ae1afd05f56e6d52",
            "receivedResponse": {
                "id": "63e1de71ae1afd05f56e6d520.2776789649310003",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité, Réponse correspondant à la demande",
                "creationDate": "2023-02-07T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:29.327Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "carine.dumoulin@aeci01.fr",
            "CustomerContactEmail": "carine.dumoulin@aeci01.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DUMOULIN",
            "CustomerContactFirstName": "CARINE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AECI",
            "CustomerCompanyGroupName": "A.E.C.I.",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009911",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité, Réponse correspondant à la demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de71ae1afd05f56e6d5b",
        "extendedResponse": {
            "_id": "63e4a864956a2e76a213bd8d",
            "submittedBody": {
                "id": "63e1de71ae1afd05f56e6d5b0.16624635548190492",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "livraison conforme à la commande",
                "creationDate": "2023-02-07T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denis.leiros@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5be635b6-cfed-46ab-bee3-7ea93ce81fe3",
            "responseId": "63e1de71ae1afd05f56e6d5b",
            "receivedResponse": {
                "id": "63e1de71ae1afd05f56e6d5b0.16624635548190492",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "livraison conforme à la commande",
                "creationDate": "2023-02-07T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:29.340Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "denis.leiros@suez.com",
            "CustomerContactEmail": "denis.leiros@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEIROS",
            "CustomerContactFirstName": "DENIS",
            "CustomerContactMobilePhone": "0632311684",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223009934",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "livraison conforme à la commande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e1de71ae1afd05f56e6e26",
        "extendedResponse": {
            "_id": "63e4a865956a2e76a213bd8e",
            "submittedBody": {
                "id": "63e1de71ae1afd05f56e6e260.08489352098202674",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité",
                "creationDate": "2023-02-07T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-yves.renaudeau@saur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "5a114d1f-c841-44bb-8095-5a0416efafe1",
            "responseId": "63e1de71ae1afd05f56e6e26",
            "receivedResponse": {
                "id": "63e1de71ae1afd05f56e6e260.08489352098202674",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité",
                "creationDate": "2023-02-07T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-07T05:15:29.451Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "jean-yves.renaudeau@saur.fr",
            "CustomerContactEmail": "jean-yves.renaudeau@saur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RENAUDEAU",
            "CustomerContactFirstName": "Jean-Yves",
            "CustomerContactMobilePhone": "0661302189",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010033",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "La perfection n'est pas de ce monde  ;-) "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e32feeef0138540be09714",
        "extendedResponse": {
            "_id": "63e4a865956a2e76a213bd8f",
            "submittedBody": {
                "id": "63e32feeef0138540be097140.6120019720851613",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité ",
                "creationDate": "2023-02-08T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yannick.leberre@nicolasbobinage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d1339eab-5d3a-45bb-843b-a946fd43125f",
            "responseId": "63e32feeef0138540be09714",
            "receivedResponse": {
                "id": "63e32feeef0138540be097140.6120019720851613",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité ",
                "creationDate": "2023-02-08T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-08T05:15:26.560Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-08_06.15.08",
        "integration": {
            "ContactKey": "yannick.leberre@nicolasbobinage.com",
            "CustomerContactEmail": "yannick.leberre@nicolasbobinage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE BERRE",
            "CustomerContactFirstName": "Yannick",
            "CustomerContactMobilePhone": "0608802782",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RUBIX FRANCE",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223010050",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e32feeef0138540be09758",
        "extendedResponse": {
            "_id": "63e4a866956a2e76a213bd90",
            "submittedBody": {
                "id": "63e32feeef0138540be097580.09085543376150529",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Service rapide.colis bien emballé ",
                "creationDate": "2023-02-08T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0683705736"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2de2b97e-f5ca-45a3-ad5f-f583ab5af2e1",
            "responseId": "63e32feeef0138540be09758",
            "receivedResponse": {
                "id": "63e32feeef0138540be097580.09085543376150529",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Service rapide.colis bien emballé ",
                "creationDate": "2023-02-08T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-08T05:15:26.627Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "0683705736",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BOSERO Eric",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0683705736",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIREV - TERIDEAL",
            "CustomerCompanyGroupName": "TERIDEAL - SIREV",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF3490000166 / ZW49035",
            "XylemReference": "200044",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Service rapide.colis bien emballé ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e32feeef0138540be09767",
        "extendedResponse": {
            "_id": "63e4a866956a2e76a213bd91",
            "submittedBody": {
                "id": "63e32feeef0138540be097670.19340154269027443",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Votre Sms envoyé à 5h du matin ne me plaît pas",
                "creationDate": "2023-02-08T06:15:26+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0625735292"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "691f9f50-9249-44f3-80cb-6edc6102e9fc",
            "responseId": "63e32feeef0138540be09767",
            "receivedResponse": {
                "id": "63e32feeef0138540be097670.19340154269027443",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Votre Sms envoyé à 5h du matin ne me plaît pas",
                "creationDate": "2023-02-08T06:15:26Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-08T05:15:26.641Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "0625735292",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "DETTORI Christophe",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0625735292",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KYRNOLIA (AGENCE SAGONE)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3673561",
            "XylemReference": "199988",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Votre Sms envoyé à 5h du matin ne me plaît pas",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e32fefef0138540be099ab",
        "extendedResponse": {
            "_id": "63e4a866956a2e76a213bd92",
            "submittedBody": {
                "id": "63e32fefef0138540be099ab0.3963142948710443",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "votre réactivité",
                "creationDate": "2023-02-08T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "martin.dao@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4e89e68e-8ddb-4ae2-9260-8d75226c2141",
            "responseId": "63e32fefef0138540be099ab",
            "receivedResponse": {
                "id": "63e32fefef0138540be099ab0.3963142948710443",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "votre réactivité",
                "creationDate": "2023-02-08T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-08T05:15:27.065Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-08_06.15.08",
        "integration": {
            "ContactKey": "martin.dao@sofinther.fr",
            "CustomerContactEmail": "martin.dao@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DAO NGUYEN",
            "CustomerContactFirstName": "Martin",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010203",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "votre réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e32fefef0138540be099df",
        "extendedResponse": {
            "_id": "63e4a867956a2e76a213bd93",
            "submittedBody": {
                "id": "63e32fefef0138540be099df0.6468378237352286",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "delai de reponse a un devis trop long",
                "creationDate": "2023-02-08T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "g-maubourguet@groupe-villebenoit.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "24bbd6ea-e8bd-4c98-ad7d-8a3b97c83654",
            "responseId": "63e32fefef0138540be099df",
            "receivedResponse": {
                "id": "63e32fefef0138540be099df0.6468378237352286",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délai de réponse a un devis trop long",
                "creationDate": "2023-02-08T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-08T05:15:27.089Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-08_06.15.08",
        "integration": {
            "ContactKey": "g-maubourguet@groupe-villebenoit.fr",
            "CustomerContactEmail": "g-maubourguet@groupe-villebenoit.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MAUBOURGUET",
            "CustomerContactFirstName": "Gilles",
            "CustomerContactMobilePhone": "0386957024",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OREXAD",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010240",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "delai de reponse a un devis trop long",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "plus rapide"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e32fefef0138540be09a24",
        "extendedResponse": {
            "_id": "63e4a867956a2e76a213bd94",
            "submittedBody": {
                "id": "63e32fefef0138540be09a240.5334608892216555",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tout était parfait, j'avais besoin d'une estimation budgétaire ainsi qu'une estimation de puissance pour un projet, j'ai obtenu cela très rapidement grâce à Tony Geneve, toujours très réactif. Merci à lui. Cordialement. Jean-Michel PONCET",
                "creationDate": "2023-02-08T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-michel.poncet@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f8ba966c-cb33-4b28-9e54-19c083ef6f7c",
            "responseId": "63e32fefef0138540be09a24",
            "receivedResponse": {
                "id": "63e32fefef0138540be09a240.5334608892216555",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tout était parfait, j'avais besoin d'une estimation budgétaire ainsi qu'une estimation de puissance pour un projet, j'ai obtenu cela très rapidement grâce à Tony Genève, toujours très réactif. Merci à lui. Cordialement. Jean-Michel PONCET",
                "creationDate": "2023-02-08T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-08T05:15:27.214Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-08_06.15.08",
        "integration": {
            "ContactKey": "jean-michel.poncet@suez.com",
            "CustomerContactEmail": "jean-michel.poncet@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PONCET",
            "CustomerContactFirstName": "Jean Michel",
            "CustomerContactMobilePhone": "0630971383",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDREA",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223010332",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tout était parfait, j'avais besoin d'une estimation budgétaire ainsi qu'une estimation de puissance pour un projet, j'ai obtenu cela très rapidement grâce à Tony Geneve, toujours très réactif. Merci à lui. Cordialement. Jean-Michel PONCET",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48173377f2144dd5467f1",
        "extendedResponse": {
            "_id": "63e4ae53fff82aee4fe8a76f",
            "submittedBody": {
                "id": "63e48173377f2144dd5467f10.09992627725703995",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Prise de contact avant livraison",
                "creationDate": "2023-02-09T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0671194341"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dd905cdd-a32c-4cb3-b1ed-49b0e6ced100",
            "responseId": "63e48173377f2144dd5467f1",
            "receivedResponse": {
                "id": "63e48173377f2144dd5467f10.09992627725703995",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Prise de contact avant livraison",
                "creationDate": "2023-02-09T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-09T05:15:31.942Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "0671194341",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Mr Loic",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0671194341",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "POMPECO",
            "CustomerCompanyGroupName": "S.A.R.L. POMPECO",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 16/01/2023 - Devis 223003780",
            "XylemReference": "197168",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Prise de contact avant livraison",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174377f2144dd546923",
        "extendedResponse": {
            "_id": "63e4a868956a2e76a213bd95",
            "submittedBody": {
                "id": "63e48174377f2144dd5469230.121911906728696",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0632631162"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bb899f1e-ac5d-4307-a1a3-2c35721e094c",
            "responseId": "63e48174377f2144dd546923",
            "receivedResponse": {
                "id": "63e48174377f2144dd5469230.121911906728696",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.004Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "0632631162",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LACAN HERVE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0632631162",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DAFFINI SARL",
            "CustomerCompanyGroupName": "DAFFINI SARL (INNOV'ELEVAGE)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 06/12/2022 - Devis 222080362",
            "XylemReference": "191906",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaae19",
        "extendedResponse": {
            "_id": "63ef86898196e3c449e109b6",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaae190.5329979686681521",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne présentation du matériel et conseil technique de qualité !",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.lache@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5fd680eb-4a69-4c2b-859a-ba390ba80251",
            "responseId": "63e48174ea3c877b0dfaae19",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaae190.5329979686681521",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne présentation du matériel et conseil technique de qualité !",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.043Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "sebastien.lache@dalkia.fr",
            "CustomerContactEmail": "sebastien.lache@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACHE",
            "CustomerContactFirstName": "SEBASTIEN",
            "CustomerContactMobilePhone": "+33614712004",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA France",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Fabien",
            "XylemContactedPersonName": "CANNIZZARO",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne présentation du matériel et conseil technique de qualité !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaae66",
        "extendedResponse": {
            "_id": "63e4a868956a2e76a213bd96",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaae660.8390841788696664",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le temps de réponse pour un simple prix sur une pompe était de... 6 jours !\nBref, j'ai pris une pompe chez un autre fabriquant.",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.meiss@hydraustab.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "048ec9a3-862e-4301-bf21-fe87706919c4",
            "responseId": "63e48174ea3c877b0dfaae66",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaae660.8390841788696664",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le temps de réponse pour un simple prix sur une pompe était de... 6 jours !\nBref, j'ai pris une pompe chez un autre fabriquant.",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.065Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "j.meiss@hydraustab.com",
            "CustomerContactEmail": "j.meiss@hydraustab.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MEISS",
            "CustomerContactFirstName": "Jérémy",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRAUSTAB",
            "CustomerCompanyGroupName": "SARL HYDRAUSTAB",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010727",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le temps de réponse pour un simple prix sur une pompe était de... 6 jours !\nBref, j'ai pris une pompe chez un autre fabriquant.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaae83",
        "extendedResponse": {
            "_id": "63e4a869956a2e76a213bd97",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaae830.5034562689711544",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Offre claire, rapide et précise. la documentation de la pompe fournie avec le devis apporte un plus chez les clientsq.",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "poixs@michaud-chailly.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bcfb41ad-01ce-491d-9f16-1e1f3fa22635",
            "responseId": "63e48174ea3c877b0dfaae83",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaae830.5034562689711544",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Offre claire, rapide et précise. la documentation de la pompe fournie avec le devis apporte un plus chez les clientsq.",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.114Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "poixs@michaud-chailly.fr",
            "CustomerContactEmail": "poixs@michaud-chailly.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POIX",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MICHAUD CHAILLY",
            "CustomerCompanyGroupName": "MICHAUD & CHAILLY",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010795",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Offre claire, rapide et précise. la documentation de la pompe fournie avec le devis apporte un plus chez les clientsq.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaaef0",
        "extendedResponse": {
            "_id": "63e4a869956a2e76a213bd98",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaaef00.6922646644903871",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité professionnalisme",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "r.robin@belle-environnement.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f1e30714-9025-466a-8ff6-75c8ac6b0e1b",
            "responseId": "63e48174ea3c877b0dfaaef0",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaaef00.6922646644903871",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité professionnalisme",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.209Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "r.robin@belle-environnement.fr",
            "CustomerContactEmail": "r.robin@belle-environnement.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "ROBIN",
            "CustomerContactFirstName": "ROXANE",
            "CustomerContactMobilePhone": "0788645594",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BELLE ENVIRONNEMENT",
            "CustomerCompanyGroupName": "BELLE ENVIRONNEMENT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010551",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité professionnalisme",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaaf06",
        "extendedResponse": {
            "_id": "63ef868a8196e3c449e109b7",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaaf060.3262593395447002",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Pour l'instant nous sommes satisfaits de vos services.",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nelson.pangkoui@polynesienne-des-eaux.pf"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "7ab87cc3-775f-416f-829e-89078af2ab12",
            "responseId": "63e48174ea3c877b0dfaaf06",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaaf060.3262593395447002",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Pour l'instant nous sommes satisfaits de vos services.",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.255Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "nelson.pangkoui@polynesienne-des-eaux.pf",
            "CustomerContactEmail": "nelson.pangkoui@polynesienne-des-eaux.pf",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PANG KOUI",
            "CustomerContactFirstName": "Nelson",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "POLYNESIENNE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010581",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Pour l'instant nous sommes satisfaits de vos services.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Meilleurs prix sur tout le matériel \"accords-cadres\"."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaaf46",
        "extendedResponse": {
            "_id": "63e4ae54fff82aee4fe8a770",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaaf460.1473688876906485",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Équipe très professionnelle",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hetzel@water-proved.de"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cc9e4215-9f70-47d5-9f8e-07125e953a28",
            "responseId": "63e48174ea3c877b0dfaaf46",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaaf460.1473688876906485",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Équipe très professionnelle",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.342Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "hetzel@water-proved.de",
            "CustomerContactEmail": "hetzel@water-proved.de",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HETZEL",
            "CustomerContactFirstName": "GEORGES",
            "CustomerContactMobilePhone": "0627737251",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "WATER-PROVED GMBH",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010591",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Équipe très professionnelle",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaaf7f",
        "extendedResponse": {
            "_id": "63e4ae54fff82aee4fe8a771",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaaf7f0.35009818037569396",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité ++",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "marie-anne.saldaqui@agi-infra.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "439718dc-19a5-4a44-bc8c-6c4336505657",
            "responseId": "63e48174ea3c877b0dfaaf7f",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaaf7f0.35009818037569396",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité ++",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.383Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "marie-anne.saldaqui@agi-infra.fr",
            "CustomerContactEmail": "marie-anne.saldaqui@agi-infra.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "SALDAQUI",
            "CustomerContactFirstName": "MARIE ANNE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AGI INFRA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Thierry",
            "XylemContactedPersonName": "BLONDET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010603",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité ++",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfaafdc",
        "extendedResponse": {
            "_id": "63e4a86a956a2e76a213bd99",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfaafdc0.43182592063203007",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "LA REACTIVITE !! AU TOP !!!",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cyrille.diot@feldmann.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8727b246-3f32-404e-b53f-1982e5bb4f28",
            "responseId": "63e48174ea3c877b0dfaafdc",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfaafdc0.43182592063203007",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "LA RÉACTIVITÉ !! AU TOP !!!",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.452Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "cyrille.diot@feldmann.fr",
            "CustomerContactEmail": "cyrille.diot@feldmann.fr",
            "CustomerContactSalutation": "-",
            "CustomerContactName": "DIOT",
            "CustomerContactFirstName": "Cyrille",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FELDMANN SAS - OREXAD",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010699",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "LA REACTIVITE !! AU TOP !!!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab07a",
        "extendedResponse": {
            "_id": "63e4a86a956a2e76a213bd9a",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab07a0.05117879187678698",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactif et répond à l'attente ",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gilles.sebastien@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1e674213-641b-4da4-95b8-4bdda88faf0c",
            "responseId": "63e48174ea3c877b0dfab07a",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab07a0.05117879187678698",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactif et répond à l'attente ",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.579Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "gilles.sebastien@sade-cgth.fr",
            "CustomerContactEmail": "gilles.sebastien@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GILLES",
            "CustomerContactFirstName": "SÉBASTIEN",
            "CustomerContactMobilePhone": "0619813557",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "Reynald",
            "XylemContactedPersonName": "MEGRET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010752",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactif et répond à l'attente ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab116",
        "extendedResponse": {
            "_id": "63ef868a8196e3c449e109b8",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab1160.02476070711042544",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Traitement efficace de ma demande avec production du devis réactualisé.",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "caribe.diving.company@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a9360a91-8482-4034-92ac-e0ba157ee289",
            "responseId": "63e48174ea3c877b0dfab116",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab1160.02476070711042544",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Traitement efficace de ma demande avec production du devis réactualisé.",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.626Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "caribe.diving.company@gmail.com",
            "CustomerContactEmail": "caribe.diving.company@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CLABECQ",
            "CustomerContactFirstName": "RENAUD",
            "CustomerContactMobilePhone": "0696299696",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CARIBE DIVING COMPANY",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010669",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Traitement efficace de ma demande avec production du devis réactualisé.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab159",
        "extendedResponse": {
            "_id": "63e4a9d3f62e46ede388e12b",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab1590.8471985000821298",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réponse, et la pertinence du devis",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bruno.leclercq@ciema.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e882e5f9-57d3-4f72-a060-d038a367483a",
            "responseId": "63e48174ea3c877b0dfab159",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab1590.8471985000821298",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réponse, et la pertinence du devis",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.669Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "bruno.leclercq@ciema.fr",
            "CustomerContactEmail": "bruno.leclercq@ciema.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LECLERCQ",
            "CustomerContactFirstName": "Bruno",
            "CustomerContactMobilePhone": "0642420206",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CIEMA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010832",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réponse, et la pertinence du devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab197",
        "extendedResponse": {
            "_id": "63e4a86b956a2e76a213bd9b",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab1970.7364591206979683",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.chamard@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5f207f19-e58a-4ee2-ac23-39a6620f0eab",
            "responseId": "63e48174ea3c877b0dfab197",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab1970.7364591206979683",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.717Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "xavier.chamard@eiffage.com",
            "CustomerContactEmail": "xavier.chamard@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHAMARD",
            "CustomerContactFirstName": "XAVIER",
            "CustomerContactMobilePhone": "0164812410",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIFFAGE TRAVAUX PUBLICS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010901",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab19e",
        "extendedResponse": {
            "_id": "63e4e2b54e40b02037ea7ab2",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab19e0.8459002803047662",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commercial très réactif et à l'écoute.\nFaible délais de réponse.\nProposition financière et technique de prime abords plutôt intéressante et pertinente",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "d.albin@dealhydraulique.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "12d55ab7-6681-47f1-aabe-3394636d338a",
            "responseId": "63e48174ea3c877b0dfab19e",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab19e0.8459002803047662",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commercial très réactif et à l'écoute.\nFaible délais de réponse.\nProposition financière et technique de prime abords plutôt intéressante et pertinente",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.727Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "d.albin@dealhydraulique.fr",
            "CustomerContactEmail": "d.albin@dealhydraulique.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ALBIN",
            "CustomerContactFirstName": "Dominique",
            "CustomerContactMobilePhone": "0624691456",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DEAL Hydraulique",
            "CustomerCompanyGroupName": "DEAL HYDRAULIQUE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223010910",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commercial très réactif et à l'écoute.\nFaible délais de réponse.\nProposition financière et technique de prime abords plutôt intéressante et pertinente",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab1cd",
        "extendedResponse": {
            "_id": "63e4a86b956a2e76a213bd9c",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab1cd0.0486664464254396",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité ",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "herve.chemama@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d1c6a50a-e488-41d8-a8ed-34d20e3c62d2",
            "responseId": "63e48174ea3c877b0dfab1cd",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab1cd0.0486664464254396",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité ",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.749Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "herve.chemama@suez.com",
            "CustomerContactEmail": "herve.chemama@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHEMAMA",
            "CustomerContactFirstName": "Hervé",
            "CustomerContactMobilePhone": "0680621766",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010924",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab1cf",
        "extendedResponse": {
            "_id": "63e4a86b956a2e76a213bd9d",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab1cf0.9126474081402309",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le réaction de réponse à ma demande\nmerci à l'équipe",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jeanmichelvoisin@mei-services.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ae5361e6-337a-4e32-bb7d-3e32a885305d",
            "responseId": "63e48174ea3c877b0dfab1cf",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab1cf0.9126474081402309",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le réaction de réponse à ma demande\nmerci à l'équipe",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.751Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "jeanmichelvoisin@mei-services.fr",
            "CustomerContactEmail": "jeanmichelvoisin@mei-services.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VOISIN",
            "CustomerContactFirstName": "Jean Michel",
            "CustomerContactMobilePhone": "0620868568",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MEI SERVICES",
            "CustomerCompanyGroupName": "MEI SERVICES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223010923",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le réaction de réponse à ma demande\nmerci à l'équipe",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e48174ea3c877b0dfab226",
        "extendedResponse": {
            "_id": "63e4a86c956a2e76a213bd9e",
            "submittedBody": {
                "id": "63e48174ea3c877b0dfab2260.5113672498821544",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le plus : Rapidité de la réponse du devis\nLe moins : Les prix ",
                "creationDate": "2023-02-09T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "n.gai@sere.soflux.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "f9f4fa02-f157-460c-a1f3-6c2a7f17e084",
            "responseId": "63e48174ea3c877b0dfab226",
            "receivedResponse": {
                "id": "63e48174ea3c877b0dfab2260.5113672498821544",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le plus : Rapidité de la réponse du devis\nLe moins : Les prix ",
                "creationDate": "2023-02-09T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-09T05:15:32.819Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-09_06.15.08",
        "integration": {
            "ContactKey": "n.gai@sere.soflux.fr",
            "CustomerContactEmail": "n.gai@sere.soflux.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GAI",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0636098135",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "BOUZIGON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223010957",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le plus : Rapidité de la réponse du devis\nLe moins : Les prix ",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "De meilleurs prix"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2f9ea3c877b0da1f079",
        "extendedResponse": {
            "_id": "63ef868a8196e3c449e109b9",
            "submittedBody": {
                "id": "63e5d2f9ea3c877b0da1f0790.2884924542331788",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la disponibilité, l’amabilité et la compétence technique sur site.",
                "creationDate": "2023-02-10T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "maxime.didelet@weylchem.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f680da13-6009-4307-a208-80c2a84003ea",
            "responseId": "63e5d2f9ea3c877b0da1f079",
            "receivedResponse": {
                "id": "63e5d2f9ea3c877b0da1f0790.2884924542331788",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la disponibilité, l amabilité et la compétence technique sur site.",
                "creationDate": "2023-02-10T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:37.786Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "maxime.didelet@weylchem.com",
            "CustomerContactEmail": "maxime.didelet@weylchem.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DIDELET",
            "CustomerContactFirstName": "Maxime",
            "CustomerContactMobilePhone": "0344853937",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "WEYLCHEM LAMOTTE",
            "CustomerCompanyGroupName": "WEYLCHEM LAMOTTE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "WATELET",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "WATELET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la disponibilité, l’amabilité et la compétence technique sur site.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2f9ea3c877b0da1f085",
        "extendedResponse": {
            "_id": "63ef868a8196e3c449e109ba",
            "submittedBody": {
                "id": "63e5d2f9ea3c877b0da1f0850.3499388797675669",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponses aux demandes et sérieux du suivis des clients.",
                "creationDate": "2023-02-10T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "carl.helfrich@porteseureliennesidf.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8fbb4e9c-c3b5-451f-8637-13d4e22a8f8c",
            "responseId": "63e5d2f9ea3c877b0da1f085",
            "receivedResponse": {
                "id": "63e5d2f9ea3c877b0da1f0850.3499388797675669",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponses aux demandes et sérieux du suivis des clients.",
                "creationDate": "2023-02-10T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "service.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:37.801Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "carl.helfrich@porteseureliennesidf.fr",
            "CustomerContactEmail": "carl.helfrich@porteseureliennesidf.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HELFRICH",
            "CustomerContactFirstName": "Carl",
            "CustomerContactMobilePhone": "0671445789",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CdC des Portes Eureliennes",
            "CustomerCompanyGroupName": "COMM. DE COMMUNES PORTES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Mehmet Akif",
            "XylemContactedPersonName": "TOKER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponses aux demandes et sérieux du suivis des clients.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2f9ea3c877b0da1f0a1",
        "extendedResponse": {
            "_id": "63ef868b8196e3c449e109bb",
            "submittedBody": {
                "id": "63e5d2f9ea3c877b0da1f0a10.027463923806550605",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commercial très agréable et compétent. Un plaisir de le recevoir.\nRéponses techniques et commerciales précises et pertinentes.\n",
                "creationDate": "2023-02-10T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.rahmani-skalli@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "064fd47e-2397-4379-b64f-34b5f6a3df8f",
            "responseId": "63e5d2f9ea3c877b0da1f0a1",
            "receivedResponse": {
                "id": "63e5d2f9ea3c877b0da1f0a10.027463923806550605",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commercial très agréable et compétent. Un plaisir de le recevoir.\nRéponses techniques et commerciales précises et pertinentes.\n",
                "creationDate": "2023-02-10T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:37.828Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-07_06.15.08",
        "integration": {
            "ContactKey": "sebastien.rahmani-skalli@saur.com",
            "CustomerContactEmail": "sebastien.rahmani-skalli@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RAHMANI SKALLI",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "0669702470",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Mehmet Akif",
            "XylemContactedPersonName": "TOKER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commercial très agréable et compétent. Un plaisir de le recevoir.\nRéponses techniques et commerciales précises et pertinentes.\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2f9cc6564b43defe5ee",
        "extendedResponse": {
            "_id": "63ef868b8196e3c449e109bc",
            "submittedBody": {
                "id": "63e5d2f9cc6564b43defe5ee0.532871346440678",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le service commerciale et le SAV\n\nDommage que les roues en Fonte au Chrome ne se fassent pas sur toutes les tailles, exemple pompe 3085 roue 250 :-(",
                "creationDate": "2023-02-10T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.salaun@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6a9a63ba-bc92-422a-9410-087d6da42e15",
            "responseId": "63e5d2f9cc6564b43defe5ee",
            "receivedResponse": {
                "id": "63e5d2f9cc6564b43defe5ee0.532871346440678",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le service commerciale et le SAV\n\nDommage que les roues en Fonte au Chrome ne se fassent pas sur toutes les tailles, exemple pompe 3085 roue 250 :-(",
                "creationDate": "2023-02-10T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.neg",
                                    "label": "Quality (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.neg",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.neg",
                    "label": "Quality (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-10T05:15:37.900Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "laurent.salaun@saur.com",
            "CustomerContactEmail": "laurent.salaun@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SALAUN",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0665988061",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011177",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le service commerciale et le SAV\n\nDommage que les roues en Fonte au Chrome ne se fassent pas sur toutes les tailles, exemple pompe 3085 roue 250 :-(",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2f9cc6564b43defe62f",
        "extendedResponse": {
            "_id": "63ef868b8196e3c449e109bd",
            "submittedBody": {
                "id": "63e5d2f9cc6564b43defe62f0.09787232726341144",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité et la communication",
                "creationDate": "2023-02-10T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.bernard@altradendel.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b34fbb92-6230-440f-a7a0-68a6ff95ad66",
            "responseId": "63e5d2f9cc6564b43defe62f",
            "receivedResponse": {
                "id": "63e5d2f9cc6564b43defe62f0.09787232726341144",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité et la communication",
                "creationDate": "2023-02-10T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:37.927Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "laurent.bernard@altradendel.com",
            "CustomerContactEmail": "laurent.bernard@altradendel.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BERNARD",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0673712755",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COFELY ENDEL",
            "CustomerCompanyGroupName": "COFELY (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011231",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité et la communication",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe765",
        "extendedResponse": {
            "_id": "63ef868c8196e3c449e109be",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe7650.35897359741205737",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'accueil, la réactivité",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "valerie@electro-tec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f11080ba-7c2d-4a5f-8c32-822cb340ec20",
            "responseId": "63e5d2facc6564b43defe765",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe7650.35897359741205737",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'accueil, la réactivité",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.096Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "valerie@electro-tec.fr",
            "CustomerContactEmail": "valerie@electro-tec.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DECUYPER",
            "CustomerContactFirstName": "Valérie",
            "CustomerContactMobilePhone": "0673883526",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELECTROTEC SARL",
            "CustomerCompanyGroupName": "ELECTROTEC SARL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Rachid",
            "XylemSalespeopleName": "ABDELKRIM",
            "XylemContactedPersonFirstName": "Corinne",
            "XylemContactedPersonName": "LAQUAINE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223011039",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'accueil, la réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe767",
        "extendedResponse": {
            "_id": "63ef868c8196e3c449e109bf",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe7670.6541886206602929",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\nSuite à ma demande pour des devis pompes,je n'ai aucune remarque à vous signaler.\nRetour des devis très rapide,et au moindre petit détail ou renseignement,contact téléphonique sans difficulté.\nService impeccable.\n",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.delpouve@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "86c6751f-2c14-46a8-86d7-cb3115e5e0ab",
            "responseId": "63e5d2facc6564b43defe767",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe7670.6541886206602929",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\nSuite à ma demande pour des devis pompes,je n'ai aucune remarque à vous signaler.\nRetour des devis très rapide,et au moindre petit détail ou renseignement,contact téléphonique sans difficulté.\nService impeccable.\n",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "service.pos",
                "competence.pos",
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.100Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "olivier.delpouve@veolia.com",
            "CustomerContactEmail": "olivier.delpouve@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELPOUVE",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "+33626915683",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE BRUAY)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011046",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\nSuite à ma demande pour des devis pompes,je n'ai aucune remarque à vous signaler.\nRetour des devis très rapide,et au moindre petit détail ou renseignement,contact téléphonique sans difficulté.\nService impeccable.\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe80c",
        "extendedResponse": {
            "_id": "63ef868c8196e3c449e109c0",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe80c0.4130814769934905",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité à mon problème et la rapidité à me faire-part d'une offre. ",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.ladreyt@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "84005042-38ea-45e0-83d1-ae6470d5556f",
            "responseId": "63e5d2facc6564b43defe80c",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe80c0.4130814769934905",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité à mon problème et la rapidité à me faire-part d'une offre. ",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.183Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "philippe.ladreyt@veolia.com",
            "CustomerContactEmail": "philippe.ladreyt@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LADREYT",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0627455467",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223010632",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité à mon problème et la rapidité à me faire-part d'une offre. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe857",
        "extendedResponse": {
            "_id": "63ef868c8196e3c449e109c1",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe8570.7638305487586443",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "merci de me retirer de vos enquetes Satisfaction",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.busson@taeh.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "79bf3726-5719-4128-817f-2036eed7a83c",
            "responseId": "63e5d2facc6564b43defe857",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe8570.7638305487586443",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "merci de me retirer de vos enquêtes Satisfaction",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.211Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "j.busson@taeh.fr",
            "CustomerContactEmail": "j.busson@taeh.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BUSSON",
            "CustomerContactFirstName": "Jérôme",
            "CustomerContactMobilePhone": "0785271153",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TAEH",
            "CustomerCompanyGroupName": "TRAVAUX APPLIC ELECT HELIOT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "DELBOS",
            "XylemContactedPersonFirstName": "Cédric",
            "XylemContactedPersonName": "DELBOS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223011143",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "merci de me retirer de vos enquetes Satisfaction",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe8fc",
        "extendedResponse": {
            "_id": "63ef868c8196e3c449e109c2",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe8fc0.8191110470884617",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'offre détaillée et son courriel d'accompagnement répondant à toutes les demandes.",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sabine.vaillant@vinci-construction.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "61c7f85c-92ec-4df7-b71b-36a296121ee9",
            "responseId": "63e5d2facc6564b43defe8fc",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe8fc0.8191110470884617",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'offre détaillée et son courriel d'accompagnement répondant à toutes les demandes.",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.422Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "sabine.vaillant@vinci-construction.fr",
            "CustomerContactEmail": "sabine.vaillant@vinci-construction.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "VAILLANT",
            "CustomerContactFirstName": "SABINE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEA SUD HYDRAULIQUE - PROCESS",
            "CustomerCompanyGroupName": "VINCI CF (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011300",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'offre détaillée et son courriel d'accompagnement répondant à toutes les demandes.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe90c",
        "extendedResponse": {
            "_id": "63ef868d8196e3c449e109c3",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe90c0.897234197491444",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gregory.lezier@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5961620c-e840-40b7-8fee-91da0be82848",
            "responseId": "63e5d2facc6564b43defe90c",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe90c0.897234197491444",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.446Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "gregory.lezier@veolia.com",
            "CustomerContactEmail": "gregory.lezier@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEZIER",
            "CustomerContactFirstName": "Gregory",
            "CustomerContactMobilePhone": "0614578166",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEAO",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011322",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe922",
        "extendedResponse": {
            "_id": "63ef868d8196e3c449e109c4",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe9220.81487451708363",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité et conseils de la part de votre équipe.",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romain.morancais@brunet-groupe.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e5678d05-0a1a-460e-a4b3-f8869623f20b",
            "responseId": "63e5d2facc6564b43defe922",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe9220.81487451708363",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité et conseils de la part de votre équipe.",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.474Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "romain.morancais@brunet-groupe.fr",
            "CustomerContactEmail": "romain.morancais@brunet-groupe.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORANCAIS",
            "CustomerContactFirstName": "Romain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BRUNET",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223011356",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité et conseils de la part de votre équipe.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe961",
        "extendedResponse": {
            "_id": "63ef868d8196e3c449e109c5",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe9610.130857357910789",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Vous êtes trés réactif et disponibles.\n\nVincent Achard au top et les equipes aussi. ",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.nadal@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4b1a313b-acdf-43f2-a925-aeab1323fe25",
            "responseId": "63e5d2facc6564b43defe961",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe9610.130857357910789",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Vous êtes très réactif et disponibles.\n\nVincent Achard au top et les équipes aussi. ",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.521Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "david.nadal@veolia.com",
            "CustomerContactEmail": "david.nadal@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "NADAL",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "0629915852",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011392",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Vous êtes trés réactif et disponibles.\n\nVincent Achard au top et les equipes aussi. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e5d2facc6564b43defe978",
        "extendedResponse": {
            "_id": "63ef868d8196e3c449e109c6",
            "submittedBody": {
                "id": "63e5d2facc6564b43defe9780.7760368843125991",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour rien a dire sur la qualité de vos produits.\nps: merci de ne plus m'envoyer des SMS a six heure du matin.\ncdlt. ",
                "creationDate": "2023-02-10T06:15:38+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.maubon@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "be86388f-b9c9-48d6-9466-9a85470af3a8",
            "responseId": "63e5d2facc6564b43defe978",
            "receivedResponse": {
                "id": "63e5d2facc6564b43defe9780.7760368843125991",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour rien a dire sur la qualité de vos produits.\nps: merci de ne plus m'envoyer des SMS a six heure du matin.\ncordialement. ",
                "creationDate": "2023-02-10T06:15:38Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-10T05:15:38.561Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-10_06.15.14",
        "integration": {
            "ContactKey": "eric.maubon@suez.com",
            "CustomerContactEmail": "eric.maubon@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MAUBON",
            "CustomerContactFirstName": "ERIC",
            "CustomerContactMobilePhone": "0683480596",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "Joachim",
            "XylemContactedPersonName": "GAUDON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011417",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour rien a dire sur la qualité de vos produits.\nps: merci de ne plus m'envoyer des SMS a six heure du matin.\ncdlt. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b98fae",
        "extendedResponse": {
            "_id": "63ef868d8196e3c449e109c7",
            "submittedBody": {
                "id": "63e72474b328848db8b98fae0.8056221203455023",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse du service commercial rapide, devis clair répondant aux attentes, solution alternative proposée. Parfait",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "antoine.thomas@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0106f458-d076-49bc-8408-216686c8e19d",
            "responseId": "63e72474b328848db8b98fae",
            "receivedResponse": {
                "id": "63e72474b328848db8b98fae0.8056221203455023",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse du service commercial rapide, devis clair répondant aux attentes, solution alternative proposée. Parfait",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.182Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "antoine.thomas@veolia.com",
            "CustomerContactEmail": "antoine.thomas@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Antoine",
            "CustomerContactMobilePhone": "0603500636",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE ETAPLES)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011496",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse du service commercial rapide, devis clair répondant aux attentes, solution alternative proposée. Parfait",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b98fb1",
        "extendedResponse": {
            "_id": "63ef868e8196e3c449e109c8",
            "submittedBody": {
                "id": "63e72474b328848db8b98fb10.8566580805253343",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Plu : Réactivité, très explicite, super :)",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kbidrane@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bea27223-92ec-4843-bb3c-526738654d86",
            "responseId": "63e72474b328848db8b98fb1",
            "receivedResponse": {
                "id": "63e72474b328848db8b98fb10.8566580805253343",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Plu : Réactivité, très explicite, super :)",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.188Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "kbidrane@hydralians.com",
            "CustomerContactEmail": "kbidrane@hydralians.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIDRANE",
            "CustomerContactFirstName": "Kamel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRALIANS",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011501",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Plu : Réactivité, très explicite, super :)",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b98fb5",
        "extendedResponse": {
            "_id": "63ef868e8196e3c449e109c9",
            "submittedBody": {
                "id": "63e72474b328848db8b98fb50.9730188755338007",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse rapide",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@moteur-pinson.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "68545f04-0682-47ca-84ca-e8f1e20fe947",
            "responseId": "63e72474b328848db8b98fb5",
            "receivedResponse": {
                "id": "63e72474b328848db8b98fb50.9730188755338007",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.197Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "contact@moteur-pinson.com",
            "CustomerContactEmail": "contact@moteur-pinson.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "PROVOST",
            "CustomerContactFirstName": "Christine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PINSON SARL",
            "CustomerCompanyGroupName": "ETS PINSON",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011522",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse rapide",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "meilleures remises"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b98ff8",
        "extendedResponse": {
            "_id": "63ef868e8196e3c449e109ca",
            "submittedBody": {
                "id": "63e72474b328848db8b98ff80.5478834724494006",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "devis rapide ",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "guillaume.lor@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "12521860-75a4-42c3-b497-e69c55333575",
            "responseId": "63e72474b328848db8b98ff8",
            "receivedResponse": {
                "id": "63e72474b328848db8b98ff80.5478834724494006",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "devis rapide ",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.237Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "guillaume.lor@veolia.com",
            "CustomerContactEmail": "guillaume.lor@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LOR",
            "CustomerContactFirstName": "Guillaume",
            "CustomerContactMobilePhone": "+33613936756",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE LENS)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011586",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "devis rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b990af",
        "extendedResponse": {
            "_id": "63ef868e8196e3c449e109cb",
            "submittedBody": {
                "id": "63e72474b328848db8b990af0.9224378585077859",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDITE ET PRECISION DU DEVIS \nDISPONIBILITE DES PRODUITS DEMANDES \nLIEN AVEC LE SITE DE COMMANDE  ",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dmerdy@sofintec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "1cfbca54-160d-44c5-9902-db3e58245b85",
            "responseId": "63e72474b328848db8b990af",
            "receivedResponse": {
                "id": "63e72474b328848db8b990af0.9224378585077859",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité ET PRECISION DU DEVIS \nDISPONIBILITE DES PRODUITS DEMANDES \nLIEN AVEC LE SITE DE COMMANDE ",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.302Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "dmerdy@sofintec.fr",
            "CustomerContactEmail": "dmerdy@sofintec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MERDY",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTEC INDUSTRIES",
            "CustomerCompanyGroupName": "SOFINTEC (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011694",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDITE ET PRECISION DU DEVIS \nDISPONIBILITE DES PRODUITS DEMANDES \nLIEN AVEC LE SITE DE COMMANDE  ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "IL Y A TOUJOURS MOYEN DE S AMELIORER !!!\n4.5 AVEC DES PRIX PLUS ABORDABLE \nET 5.0 AVEC UN PORT GRATUIT POUR LE MONTANT DE NOS COMMANDES ANNUELLES \nDEPASSANT LES 450.000€ !"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b9919e",
        "extendedResponse": {
            "_id": "63ef868f8196e3c449e109cc",
            "submittedBody": {
                "id": "63e72474b328848db8b9919e0.19057420673150283",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactif ",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "htriboulet@cabinet-merlin.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1587a248-47f7-44d7-ac42-3e58b347713f",
            "responseId": "63e72474b328848db8b9919e",
            "receivedResponse": {
                "id": "63e72474b328848db8b9919e0.19057420673150283",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactif ",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.439Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "htriboulet@cabinet-merlin.fr",
            "CustomerContactEmail": "htriboulet@cabinet-merlin.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TRIBOULET",
            "CustomerContactFirstName": "Hervé",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CABINET MERLIN",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223011524",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactif ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b991e1",
        "extendedResponse": {
            "_id": "63ef868f8196e3c449e109cd",
            "submittedBody": {
                "id": "63e72474b328848db8b991e10.44124325183046187",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide ",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denis.leiros@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ccc9f6d8-a648-44a6-91ee-8208893d3766",
            "responseId": "63e72474b328848db8b991e1",
            "receivedResponse": {
                "id": "63e72474b328848db8b991e10.44124325183046187",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide ",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.463Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "denis.leiros@suez.com",
            "CustomerContactEmail": "denis.leiros@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEIROS",
            "CustomerContactFirstName": "DENIS",
            "CustomerContactMobilePhone": "0632311684",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011539",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b991fe",
        "extendedResponse": {
            "_id": "63ef868f8196e3c449e109ce",
            "submittedBody": {
                "id": "63e72474b328848db8b991fe0.9754413038218426",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité et conseil",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@first-robinetterie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c194e599-1051-47bb-9412-f84d334bdec5",
            "responseId": "63e72474b328848db8b991fe",
            "receivedResponse": {
                "id": "63e72474b328848db8b991fe0.9754413038218426",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité et conseil",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.502Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "commercial@first-robinetterie.com",
            "CustomerContactEmail": "commercial@first-robinetterie.com",
            "CustomerContactSalutation": "Mademoiselle",
            "CustomerContactName": "TZIKUNIB",
            "CustomerContactFirstName": "Karine",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FIRST ROBINETTERIE MARSEILLE",
            "CustomerCompanyGroupName": "FIRST ROBINETTERIE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011506",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité et conseil",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63e72474b328848db8b993d4",
        "extendedResponse": {
            "_id": "63ef868f8196e3c449e109cf",
            "submittedBody": {
                "id": "63e72474b328848db8b993d40.03326827370589802",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai de réponse rapide\noffre correspondant à la demande\n",
                "creationDate": "2023-02-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "adasilva.abm78@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "afda8b23-824e-4b0e-837d-2a078d2a3611",
            "responseId": "63e72474b328848db8b993d4",
            "receivedResponse": {
                "id": "63e72474b328848db8b993d40.03326827370589802",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai de réponse rapide\noffre correspondant à la demande\n",
                "creationDate": "2023-02-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-11T05:15:32.796Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-11_06.15.08",
        "integration": {
            "ContactKey": "adasilva.abm78@gmail.com",
            "CustomerContactEmail": "adasilva.abm78@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DA SILVA",
            "CustomerContactFirstName": "Antonio",
            "CustomerContactMobilePhone": "0612879361",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ABM",
            "CustomerCompanyGroupName": "ABM   (ATEL. BOBINAGE MUREAUX)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223011786",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai de réponse rapide\noffre correspondant à la demande\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63eb18f4b328848db80d2ced",
        "extendedResponse": {
            "_id": "63ef868f8196e3c449e109d0",
            "submittedBody": {
                "id": "63eb18f4b328848db80d2ced0.21736261555573844",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de livraison ",
                "creationDate": "2023-02-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0608979837"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "2a48f7de-5516-447d-8f2c-e17cb68f8078",
            "responseId": "63eb18f4b328848db80d2ced",
            "receivedResponse": {
                "id": "63eb18f4b328848db80d2ced0.21736261555573844",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de livraison ",
                "creationDate": "2023-02-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-14T05:15:32.075Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "0608979837",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Pascal RIOUL",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0608979837",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR FRANCE EXPLOITATION SEGRE",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000455110",
            "XylemReference": "198935",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de livraison ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63eb18f4b328848db80d3a52",
        "extendedResponse": {
            "_id": "63ef86908196e3c449e109d1",
            "submittedBody": {
                "id": "63eb18f4b328848db80d3a520.6064501574166481",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le dernier devis demandé ne correspond pas à la demande\nLes réponses sont longues à venir (demande de devis, retour d'expertise, retour de FRMA, problèmes divers...)\nIl manquait un accessoire de refoulement sur la pompe de la dernière commande neuve\nLa qualité du suivi des demandes d'expertise/réparations est à un bon niveau mais un peu long",
                "creationDate": "2023-02-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thierry.souchon@arkema.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "1d2854c4-e6de-4ff1-83ad-d530cfc009db",
            "responseId": "63eb18f4b328848db80d3a52",
            "receivedResponse": {
                "id": "63eb18f4b328848db80d3a520.6064501574166481",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le dernier devis demandé ne correspond pas à la demande\nLes réponses sont longues à venir (demande de devis, retour d'expertise, retour de FRMA, problèmes divers...)\nIl manquait un accessoire de refoulement sur la pompe de la dernière commande neuve\nLa qualité du suivi des demandes d'expertise/réparations est à un bon niveau mais un peu long",
                "creationDate": "2023-02-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "abime.neg",
                                    "label": "Damaged product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "manque.produit.neg",
                "abime.neg",
                "fiabilite.pos",
                "qualite.pos",
                "service.pos",
                "competence.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "abime.neg",
                    "label": "Damaged product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-14T05:15:32.372Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "thierry.souchon@arkema.com",
            "CustomerContactEmail": "thierry.souchon@arkema.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SOUCHON",
            "CustomerContactFirstName": "THIERRY",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ARKEMA FRANCE",
            "CustomerCompanyGroupName": "ARKEMA FRANCE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Annabel",
            "XylemContactedPersonName": "VALENTIN",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "",
            "XylemReference": "223011960",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le dernier devis demandé ne correspond pas à la demande\nLes réponses sont longues à venir (demande de devis, retour d'expertise, retour de FRMA, problèmes divers...)\nIl manquait un accessoire de refoulement sur la pompe de la dernière commande neuve\nLa qualité du suivi des demandes d'expertise/réparations est à un bon niveau mais un peu long",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "ameliorer les points du questionnaire n°1"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63eb18f4b328848db80d3af8",
        "extendedResponse": {
            "_id": "63ef86908196e3c449e109d2",
            "submittedBody": {
                "id": "63eb18f4b328848db80d3af80.346283549564969",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La durée d'expertise est beaucoup trop longue et même chose pour les réparations 5pompe concertor)",
                "creationDate": "2023-02-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jerome.poupin@saur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "037dd236-852c-4f3d-b3cc-8d29a7a953c7",
            "responseId": "63eb18f4b328848db80d3af8",
            "receivedResponse": {
                "id": "63eb18f4b328848db80d3af80.346283549564969",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La durée d'expertise est beaucoup trop longue et même chose pour les réparations 5pompe concertor)",
                "creationDate": "2023-02-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "abime.neg",
                                    "label": "Damaged product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "abime.neg",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "abime.neg",
                    "label": "Damaged product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-14T05:15:32.394Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "jerome.poupin@saur.fr",
            "CustomerContactEmail": "jerome.poupin@saur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POUPIN",
            "CustomerContactFirstName": "Jérôme",
            "CustomerContactMobilePhone": "0669582124",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Stéphanie",
            "XylemContactedPersonName": "CLOITRE",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "SAUR-0000459762",
            "XylemReference": "3RF000714",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La durée d'expertise est beaucoup trop longue et même chose pour les réparations 5pompe concertor)",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63eb18f4b328848db80d3b97",
        "extendedResponse": {
            "_id": "63ef86908196e3c449e109d3",
            "submittedBody": {
                "id": "63eb18f4b328848db80d3b970.7538554682879894",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et qualité de réponse ",
                "creationDate": "2023-02-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vvantrimpont@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e6a1c5ae-ddb6-4483-8e34-b50a6502406c",
            "responseId": "63eb18f4b328848db80d3b97",
            "receivedResponse": {
                "id": "63eb18f4b328848db80d3b970.7538554682879894",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et qualité de réponse ",
                "creationDate": "2023-02-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-14T05:15:32.534Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "vvantrimpont@gressier.net",
            "CustomerContactEmail": "vvantrimpont@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANTRIMPONT",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0626329823",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER ET FILS",
            "CustomerCompanyGroupName": "ETS GRESSIER ET FILS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011772",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et qualité de réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63eb18f4b328848db80d3b9a",
        "extendedResponse": {
            "_id": "63ef86908196e3c449e109d4",
            "submittedBody": {
                "id": "63eb18f4b328848db80d3b9a0.7652440498311623",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité et efficacité",
                "creationDate": "2023-02-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "aude.leroy@cedeo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ace858c1-d6e2-4aff-a7ad-e90e8391315d",
            "responseId": "63eb18f4b328848db80d3b9a",
            "receivedResponse": {
                "id": "63eb18f4b328848db80d3b9a0.7652440498311623",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité et efficacité",
                "creationDate": "2023-02-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-14T05:15:32.540Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "aude.leroy@cedeo.fr",
            "CustomerContactEmail": "aude.leroy@cedeo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEROY",
            "CustomerContactFirstName": "Aude",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CEDEO",
            "CustomerCompanyGroupName": "DSC - Distribution Sanitaire Chauffage",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Clement",
            "XylemContactedPersonName": "FAIVRE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223011908",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité et efficacité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63eb18f4b328848db80d3cff",
        "extendedResponse": {
            "_id": "63ef86908196e3c449e109d5",
            "submittedBody": {
                "id": "63eb18f4b328848db80d3cff0.5040428052076751",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je mets la marque XYLEM en avant régulièrement mais je trouve que les temps de réponses sont trop long. A améliorer.\n",
                "creationDate": "2023-02-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.baigne@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "559ff81f-b4da-4550-9a07-7f394dae62d5",
            "responseId": "63eb18f4b328848db80d3cff",
            "receivedResponse": {
                "id": "63eb18f4b328848db80d3cff0.5040428052076751",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je mets la marque XYLEM en avant régulièrement mais je trouve que les temps de réponses sont trop long. A améliorer.\n",
                "creationDate": "2023-02-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-14T05:15:32.801Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "emmanuel.baigne@sofinther.fr",
            "CustomerContactEmail": "emmanuel.baigne@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BAIGNE",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0760803645",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER RENNES",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Simon",
            "XylemContactedPersonName": "CLEMENT",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223012106",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je mets la marque XYLEM en avant régulièrement mais je trouve que les temps de réponses sont trop long. A améliorer.\n",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Être beaucoup pus réactif."
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63eb18f4b328848db80d3d62",
        "extendedResponse": {
            "_id": "63ef86918196e3c449e109d6",
            "submittedBody": {
                "id": "63eb18f4b328848db80d3d620.2224203916836136",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-02-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.pradelles@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "2a9786e9-1072-4c68-9738-bd627de57128",
            "responseId": "63eb18f4b328848db80d3d62",
            "receivedResponse": {
                "id": "63eb18f4b328848db80d3d620.2224203916836136",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-02-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-14T05:15:32.870Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "sebastien.pradelles@saur.com",
            "CustomerContactEmail": "sebastien.pradelles@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PRADELLES",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR (LA FONTAINERIE)",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223011979",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Mail de confirmation ou qui informe que la Demande est prise en compte avec si possible une date d’engagement de traitement de la demande. J’ai dû relancer votre service concernant ma dernière demande de devis. Cela dit là réponse a été rapide "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a78232f35bd0781971b",
        "extendedResponse": {
            "_id": "63ef86918196e3c449e109d7",
            "submittedBody": {
                "id": "63ec6a78232f35bd0781971b0.8640548460145381",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Pompe enlevée par votre service le 20/06/2022.\nDevis reçu le 07/11/2022. (6 mois)\nCommande envoyée le 21/11/2022. (14 jours)\nPompe reçu le 15/02/2023 (3 mois)\nTotal 9 mois pour la réparation d'une pompe 3202 de 30Kw, je vous laisse vous faire votre avis.\n ",
                "creationDate": "2023-02-15T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lmasson@mel-sourceo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "795de33a-e499-42a4-afdd-b659797599ed",
            "responseId": "63ec6a78232f35bd0781971b",
            "receivedResponse": {
                "id": "63ec6a78232f35bd0781971b0.8640548460145381",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Pompe enlevée par votre service le 20/06/2022.\nDevis reçu le 07/11/2022. (6 mois)\nCommande envoyée le 21/11/2022. (14 jours)\nPompe reçu le 15/02/2023 (3 mois)\nTotal 9 mois pour la réparation d'une pompe 3202 de 30Kw, je vous laisse vous faire votre avis.\n ",
                "creationDate": "2023-02-15T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "abime.neg",
                                    "label": "Damaged product (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "abime.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "abime.neg",
                    "label": "Damaged product (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-15T05:15:36.931Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "lmasson@mel-sourceo.fr",
            "CustomerContactEmail": "lmasson@mel-sourceo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MASSON",
            "CustomerContactFirstName": "Ludovic",
            "CustomerContactMobilePhone": "0770024196",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOURCEO (Prod. Eau M.E.L)",
            "CustomerCompanyGroupName": "REGIE DE PRODUCTION D'EAU",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Nathalie",
            "XylemContactedPersonName": "STOESSEL",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "C202213041",
            "XylemReference": "2RF023402",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Pompe enlevée par votre service le 20/06/2022.\nDevis reçu le 07/11/2022. (6 mois)\nCommande envoyée le 21/11/2022. (14 jours)\nPompe reçu le 15/02/2023 (3 mois)\nTotal 9 mois pour la réparation d'une pompe 3202 de 30Kw, je vous laisse vous faire votre avis.\n ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Ludovic MASSON\",\n  \"phone\": \"0770024196\",\n  \"mail\": \"lmasson@mel-sourceo.fr\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a781b14e19a3dfe2d1b",
        "extendedResponse": {
            "_id": "63ef86918196e3c449e109d8",
            "submittedBody": {
                "id": "63ec6a781b14e19a3dfe2d1b0.16124544130647278",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "l’équipe est très réactive et aimable dans ses réponses ",
                "creationDate": "2023-02-15T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "k.kouame@emygaqua.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a64ef549-6a17-4869-b8d7-426060e38f58",
            "responseId": "63ec6a781b14e19a3dfe2d1b",
            "receivedResponse": {
                "id": "63ec6a781b14e19a3dfe2d1b0.16124544130647278",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "l équipe est très réactive et aimable dans ses réponses ",
                "creationDate": "2023-02-15T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:36.997Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "k.kouame@emygaqua.com",
            "CustomerContactEmail": "k.kouame@emygaqua.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "KOUAME",
            "CustomerContactFirstName": "Konan",
            "CustomerContactMobilePhone": "0658543073",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EMYG",
            "CustomerCompanyGroupName": "EMYG",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012310",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "l’équipe est très réactive et aimable dans ses réponses ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe2d1e",
        "extendedResponse": {
            "_id": "63ef86918196e3c449e109d9",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe2d1e0.008298988647372108",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Équipe réactive et compétente ",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "anthony.charton@carct.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e627dfbf-e59b-48d2-a3da-c2a3081d5d9a",
            "responseId": "63ec6a791b14e19a3dfe2d1e",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe2d1e0.008298988647372108",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Équipe réactive et compétente ",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.001Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "anthony.charton@carct.fr",
            "CustomerContactEmail": "anthony.charton@carct.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHARTON",
            "CustomerContactFirstName": "Anthony",
            "CustomerContactMobilePhone": "0785326208",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "C.A.R.C.T.",
            "CustomerCompanyGroupName": "CA REGION DE CHATEAU THIERRY",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012320",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Équipe réactive et compétente ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe2ddf",
        "extendedResponse": {
            "_id": "63ef86928196e3c449e109da",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe2ddf0.01716973597224225",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS concernant la livraison. ",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0680848497"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "192d604b-d5ac-45f4-8d56-c0c50ff3c563",
            "responseId": "63ec6a791b14e19a3dfe2ddf",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe2ddf0.01716973597224225",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS concernant la livraison. ",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.076Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "0680848497",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "FAIVRE Dan",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0680848497",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU INDUSTRIELLE",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "4503940880",
            "XylemReference": "201318",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS concernant la livraison. ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Éviter d'envoyer un SMS à 6h15 du matin pour demander l'avis sur la qualité du service..."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe2ec1",
        "extendedResponse": {
            "_id": "63ef86928196e3c449e109db",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe2ec10.8519894910880708",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité : TOP\nClaire et précis.\n10/10",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kbidrane@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9aa457b4-16d3-4fa2-997f-c77e3d4f103e",
            "responseId": "63ec6a791b14e19a3dfe2ec1",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe2ec10.8519894910880708",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité : TOP\nClaire et précis.\n10/10",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.231Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "kbidrane@hydralians.com",
            "CustomerContactEmail": "kbidrane@hydralians.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIDRANE",
            "CustomerContactFirstName": "Kamel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRALIANS",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012286",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité : TOP\nClaire et précis.\n10/10",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe2ee2",
        "extendedResponse": {
            "_id": "63ef86928196e3c449e109dc",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe2ee20.6544933886949031",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai de réponse long pour une référence simple au catalogue.\nPrix plus cher qu'un revendeur local d’où quel intérêt d'avoir des accords commerciaux direct usine??????????  ",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "brice.lysak@avf-albi.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "95c763b5-591f-46e6-bd87-1aac77fafde9",
            "responseId": "63ec6a791b14e19a3dfe2ee2",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe2ee20.6544933886949031",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai de réponse long pour une référence simple au catalogue.\nPrix plus cher qu'un revendeur local d où quel intérêt d'avoir des accords commerciaux direct usine?????????? ",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.neg",
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.248Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "brice.lysak@avf-albi.com",
            "CustomerContactEmail": "brice.lysak@avf-albi.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LYSAC",
            "CustomerContactFirstName": "Brice",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AVF",
            "CustomerCompanyGroupName": "A.V.F.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012301",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai de réponse long pour une référence simple au catalogue.\nPrix plus cher qu'un revendeur local d’où quel intérêt d'avoir des accords commerciaux direct usine??????????  ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Brice LYSAK -AVF\",\n  \"phone\": \"05.63.46.26.68\",\n  \"mail\": \"brice.lysak@avf-albi.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe2f4f",
        "extendedResponse": {
            "_id": "63ef86928196e3c449e109dd",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe2f4f0.10031883615257708",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bonne réactivité ",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.dougniaux@hemwater.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "9dd57ae2-ee63-41a3-82d0-d690d01eeace",
            "responseId": "63ec6a791b14e19a3dfe2f4f",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe2f4f0.10031883615257708",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bonne réactivité ",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.288Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "xavier.dougniaux@hemwater.com",
            "CustomerContactEmail": "xavier.dougniaux@hemwater.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DOUGNIAUX",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRO ELECTRIQUE MARINE",
            "CustomerCompanyGroupName": "HYDRO ELECTRIQUE MARINE SARL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "David",
            "XylemSalespeopleName": "FOUNAU JACQUARD",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223012340",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bonne réactivité ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe3042",
        "extendedResponse": {
            "_id": "63ef86928196e3c449e109de",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe30420.9125341585478814",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité du retour de mon commercial et sélection du produit ",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.morvant@eimi.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "1386eded-1adb-478d-bbac-3afafe63a0dd",
            "responseId": "63ec6a791b14e19a3dfe3042",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe30420.9125341585478814",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité du retour de mon commercial et sélection du produit ",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.423Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "kevin.morvant@eimi.fr",
            "CustomerContactEmail": "kevin.morvant@eimi.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORVANT",
            "CustomerContactFirstName": "Kevin",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIMI",
            "CustomerCompanyGroupName": "EIMI HOTEL D ENTREPRISE N°7",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222013545",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité du retour de mon commercial et sélection du produit ",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe3065",
        "extendedResponse": {
            "_id": "63ef86938196e3c449e109df",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe30650.22524211258251947",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je reçois une enquête de satisfaction pour connaître mes impressions suite à un devis demandé mais toujours pas reçu...demande du 09/02/2023.\nn'y a t'il pas un pb?",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "h.irigoin@agur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "00b5e7da-8f96-4899-a3bd-384bb26e2135",
            "responseId": "63ec6a791b14e19a3dfe3065",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe30650.22524211258251947",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je reçois une enquête de satisfaction pour connaître mes impressions suite à un devis demandé mais toujours pas reçu...demande du 09/02/2023.\nn'y a t'il pas un problème?",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.488Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "h.irigoin@agur.fr",
            "CustomerContactEmail": "h.irigoin@agur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "IRIGOIN",
            "CustomerContactFirstName": "HERVE",
            "CustomerContactMobilePhone": "0787143666",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AGUR POMPAGE",
            "CustomerCompanyGroupName": "FEDERATION DES DISTRIBUTEURS D'EAU INDEPENDANTS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012493",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je reçois une enquête de satisfaction pour connaître mes impressions suite à un devis demandé mais toujours pas reçu...demande du 09/02/2023.\nn'y a t'il pas un pb?",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"phone\": \"0787143666\",\n  \"name\": \"IRIGOIN HERVE\",\n  \"mail\": \"h.irigoin@agur.fr\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe30d2",
        "extendedResponse": {
            "_id": "63ef86938196e3c449e109e0",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe30d20.49894126482891776",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "j'ai rencontré des difficultés pour identifier le service a contacter et pour avoir un premier contact.\nSuite à l'envoi de ma demande par mail tout s'est très bien passé (efficacité, rapidité)",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sdequaire@g-e-d.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "50b7e03e-a502-48a5-bb11-332b153a1d41",
            "responseId": "63ec6a791b14e19a3dfe30d2",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe30d20.49894126482891776",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "j'ai rencontré des difficultés pour identifier le service a contacter et pour avoir un premier contact.\nSuite à l'envoi de ma demande par mail tout s'est très bien passé (efficacité, rapidité)",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.598Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "sdequaire@g-e-d.fr",
            "CustomerContactEmail": "sdequaire@g-e-d.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DEQUAIRE",
            "CustomerContactFirstName": "Sylvianne",
            "CustomerContactMobilePhone": "0685765540",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GED MANTES ELECTRO FLUIDE",
            "CustomerCompanyGroupName": "GED",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012408",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "j'ai rencontré des difficultés pour identifier le service a contacter et pour avoir un premier contact.\nSuite à l'envoi de ma demande par mail tout s'est très bien passé (efficacité, rapidité)",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ec6a791b14e19a3dfe30e6",
        "extendedResponse": {
            "_id": "63ef86938196e3c449e109e1",
            "submittedBody": {
                "id": "63ec6a791b14e19a3dfe30e60.9178409189413976",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "LA RAPIDITE DE TRAITEMENT",
                "creationDate": "2023-02-15T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thierry.siry@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0ee5738e-c3f3-4ec4-bc11-135e5a942c44",
            "responseId": "63ec6a791b14e19a3dfe30e6",
            "receivedResponse": {
                "id": "63ec6a791b14e19a3dfe30e60.9178409189413976",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "LA rapidité DE TRAITEMENT",
                "creationDate": "2023-02-15T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-15T05:15:37.633Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-15_06.15.14",
        "integration": {
            "ContactKey": "thierry.siry@saur.com",
            "CustomerContactEmail": "thierry.siry@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SIRY",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012602",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "LA RAPIDITE DE TRAITEMENT",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d99ff5f",
        "extendedResponse": {
            "_id": "63ef86938196e3c449e109e2",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d99ff5f0.05608935425442252",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a plu écoute et réactivité",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.cros@eloa-bassin-arcachon.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3491ee53-d2e3-4f05-b3ce-b1a29fde728b",
            "responseId": "63edbbf41b14e19a3d99ff5f",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d99ff5f0.05608935425442252",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a plu écoute et réactivité",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.349Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "eric.cros@eloa-bassin-arcachon.fr",
            "CustomerContactEmail": "eric.cros@eloa-bassin-arcachon.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CROS",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "0603562076",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELOA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012763",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a plu écoute et réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf4232f35bd0702e7cb",
        "extendedResponse": {
            "_id": "63ef86948196e3c449e109e3",
            "submittedBody": {
                "id": "63edbbf4232f35bd0702e7cb0.8133090979412452",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tout c'est très bien passé.\nRAS",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "camille.masternak@master-group.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3c3ae2bb-8bf4-4b0f-b711-d3a88a939da2",
            "responseId": "63edbbf4232f35bd0702e7cb",
            "receivedResponse": {
                "id": "63edbbf4232f35bd0702e7cb0.8133090979412452",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tout c'est très bien passé.\nRAS",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.368Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-13_06.15.04",
        "integration": {
            "ContactKey": "camille.masternak@master-group.fr",
            "CustomerContactEmail": "camille.masternak@master-group.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MASTERNAK",
            "CustomerContactFirstName": "Camille",
            "CustomerContactMobilePhone": "0671097225",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELEC MASTER",
            "CustomerCompanyGroupName": "SNC ELEC MASTER",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Hugo",
            "XylemContactedPersonName": "DEBETTE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tout c'est très bien passé.\nRAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf4232f35bd0702e7cf",
        "extendedResponse": {
            "_id": "63ef86948196e3c449e109e4",
            "submittedBody": {
                "id": "63edbbf4232f35bd0702e7cf0.8755857042430373",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon accueil. Reste à vérifier que les actions seront menées.",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "louis.masternak@master-group.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ae73ed60-a4c2-4e3f-bb50-5d6f37338ad6",
            "responseId": "63edbbf4232f35bd0702e7cf",
            "receivedResponse": {
                "id": "63edbbf4232f35bd0702e7cf0.8755857042430373",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon accueil. Reste à vérifier que les actions seront menées.",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.370Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-13_06.15.04",
        "integration": {
            "ContactKey": "louis.masternak@master-group.fr",
            "CustomerContactEmail": "louis.masternak@master-group.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MASTERNAK",
            "CustomerContactFirstName": "Louis",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELEC MASTER",
            "CustomerCompanyGroupName": "SNC ELEC MASTER",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Hugo",
            "XylemContactedPersonName": "DEBETTE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon accueil. Reste à vérifier que les actions seront menées.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d99ff70",
        "extendedResponse": {
            "_id": "63ef86948196e3c449e109e5",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d99ff700.7445274063104905",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le rendez vous c'est très bien passé\nBon appui technique ",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lionel.morvan@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5c94f5bf-5434-4df2-b8d7-eb0c9a9f3e88",
            "responseId": "63edbbf41b14e19a3d99ff70",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d99ff700.7445274063104905",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le rendez vous c'est très bien passé\nBon appui technique ",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.371Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "lionel.morvan@suez.com",
            "CustomerContactEmail": "lionel.morvan@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORVAN",
            "CustomerContactFirstName": "Lionel",
            "CustomerContactMobilePhone": "0770245249",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ STEP PORTUAIRE",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012825",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le rendez vous c'est très bien passé\nBon appui technique ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf4232f35bd0702e7dc",
        "extendedResponse": {
            "_id": "63ef86948196e3c449e109e6",
            "submittedBody": {
                "id": "63edbbf4232f35bd0702e7dc0.49367202858842285",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Présentation des activités, adaptation à notre besoin.",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.buffo@edf.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "b9bfceff-1204-4444-8bda-c19f77281b0d",
            "responseId": "63edbbf4232f35bd0702e7dc",
            "receivedResponse": {
                "id": "63edbbf4232f35bd0702e7dc0.49367202858842285",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Présentation des activités, adaptation à notre besoin.",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.375Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-13_06.15.04",
        "integration": {
            "ContactKey": "sebastien.buffo@edf.fr",
            "CustomerContactEmail": "sebastien.buffo@edf.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BUFFO",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "0625213046",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EDF - DIPNN - CNEPE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Michael",
            "XylemContactedPersonName": "MOREAU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Présentation des activités, adaptation à notre besoin.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Aspect REX client avec références des produits installées adaptées à notre besoin."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d99ffd9",
        "extendedResponse": {
            "_id": "63ef86948196e3c449e109e7",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d99ffd90.6827840126108944",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité des échanges (devis.doc.renseignement)",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-francois.accola@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8cc2b700-f54d-4f31-b6ff-a21e38f3c024",
            "responseId": "63edbbf41b14e19a3d99ffd9",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d99ffd90.6827840126108944",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité des échanges (devis.doc.renseignement)",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.452Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "jean-francois.accola@veolia.com",
            "CustomerContactEmail": "jean-francois.accola@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ACCOLAS",
            "CustomerContactFirstName": "Jean François",
            "CustomerContactMobilePhone": "0778513997",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013005",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité des échanges (devis.doc.renseignement)",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d9a00fc",
        "extendedResponse": {
            "_id": "63ef86958196e3c449e109e8",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d9a00fc0.8935266975711793",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne écoute, proposition cohérente.\nN'a pas hésiter à appeler pour avoir plus de précision sans passer par 40000 mail. Agréable au téléphone",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabrice.carry@solvay.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f361a295-917f-4848-9da7-123f0ce7f141",
            "responseId": "63edbbf41b14e19a3d9a00fc",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d9a00fc0.8935266975711793",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne écoute, proposition cohérente.\nN'a pas hésiter à appeler pour avoir plus de précision sans passer par 40000 mail. Agréable au téléphone",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.605Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "fabrice.carry@solvay.com",
            "CustomerContactEmail": "fabrice.carry@solvay.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CARRY",
            "CustomerContactFirstName": "FABRICE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOLVAY NOVACARE",
            "CustomerCompanyGroupName": "RHODIA OPERATIONS SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012757",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne écoute, proposition cohérente.\nN'a pas hésiter à appeler pour avoir plus de précision sans passer par 40000 mail. Agréable au téléphone",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d9a01cd",
        "extendedResponse": {
            "_id": "63ef86958196e3c449e109e9",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d9a01cd0.3796264135086451",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse claire et rapide",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "regis.menguy@rs-components.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0bf8cdb4-0388-4428-86ef-afb12c9165f3",
            "responseId": "63edbbf41b14e19a3d9a01cd",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d9a01cd0.3796264135086451",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse claire et rapide",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.709Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "regis.menguy@rs-components.com",
            "CustomerContactEmail": "regis.menguy@rs-components.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MENGUY",
            "CustomerContactFirstName": "REGIS",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RS COMPONENTS SAS",
            "CustomerCompanyGroupName": "RS COMPONENTS SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": ".",
            "XylemSalespeopleName": "TCS ZONE IDF",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012849",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse claire et rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d9a0261",
        "extendedResponse": {
            "_id": "63ef86958196e3c449e109ea",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d9a02610.6781798787640747",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "offre claire, détaillée et bien documentée\nNickel",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denis.beluze@ksb.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cad66af5-f287-4bf4-9cb4-4246ab5307b0",
            "responseId": "63edbbf41b14e19a3d9a0261",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d9a02610.6781798787640747",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "offre claire, détaillée et bien documentée\nNickel",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.796Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "denis.beluze@ksb.com",
            "CustomerContactEmail": "denis.beluze@ksb.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BELUZE",
            "CustomerContactFirstName": "Denis",
            "CustomerContactMobilePhone": "0622892801",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KSB SERVICES CENTRE EST",
            "CustomerCompanyGroupName": "DOIT : KSB S.A.S.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Gauthier",
            "XylemSalespeopleName": "DUFOUR",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012927",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "offre claire, détaillée et bien documentée\nNickel",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d9a02da",
        "extendedResponse": {
            "_id": "63f8b33a108bcd5092d9d19f",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d9a02da0.1698278859553508",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.detet@snef.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f22c0698-4d86-4c94-926d-0da69b3efcfc",
            "responseId": "63edbbf41b14e19a3d9a02da",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d9a02da0.1698278859553508",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.840Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "frederic.detet@snef.fr",
            "CustomerContactEmail": "frederic.detet@snef.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DETET",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0688066723",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SNEF",
            "CustomerCompanyGroupName": "SNEF MMS RHONE ALPES AUVERGNE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Franck",
            "XylemContactedPersonName": "PENVERN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "222080253",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63edbbf41b14e19a3d9a033a",
        "extendedResponse": {
            "_id": "63ef86958196e3c449e109eb",
            "submittedBody": {
                "id": "63edbbf41b14e19a3d9a033a0.887884519990622",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide, par contre les sms à 6h00 un peu moins.",
                "creationDate": "2023-02-16T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexandre.tronel@eauxdemarseille.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "73dcb6bb-8cee-4795-952f-06ee83c77377",
            "responseId": "63edbbf41b14e19a3d9a033a",
            "receivedResponse": {
                "id": "63edbbf41b14e19a3d9a033a0.887884519990622",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide, par contre les sms à 6h00 un peu moins.",
                "creationDate": "2023-02-16T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-16T05:15:32.864Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-16_06.15.10",
        "integration": {
            "ContactKey": "alexandre.tronel@eauxdemarseille.fr",
            "CustomerContactEmail": "alexandre.tronel@eauxdemarseille.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TRONEL",
            "CustomerContactFirstName": "Alexandre",
            "CustomerContactMobilePhone": "0612418073",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAOM",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223012997",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide, par contre les sms à 6h00 un peu moins.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d6fb328848db8cfa157",
        "extendedResponse": {
            "_id": "63ef86968196e3c449e109ec",
            "submittedBody": {
                "id": "63ef0d6fb328848db8cfa1570.24840932398856608",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison à 18h !\nLe livreur m'a appelé à plusieurs reprises car nous étions fermé. \nEn effet,  il s'est présenté en-dehors de nos jours et horaires de réception colis. \nIl m'a dit qu'il ne pourrait jamais venir dans nos créneaux horaires et du coup, je l'ai autorisé d'enjamber le portail pour déposer le colis.\n",
                "creationDate": "2023-02-17T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0658768549"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "ef504d99-e682-41b0-94db-ee0bdf38498a",
            "responseId": "63ef0d6fb328848db8cfa157",
            "receivedResponse": {
                "id": "63ef0d6fb328848db8cfa1570.24840932398856608",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison à 18h !\nLe livreur m'a appelé à plusieurs reprises car nous étions fermé. \nEn effet, il s'est présenté en-dehors de nos jours et horaires de réception colis. \nIl m'a dit qu'il ne pourrait jamais venir dans nos créneaux horaires et du coup, je l'ai autorisé d'enjamber le portail pour déposer le colis.\n",
                "creationDate": "2023-02-17T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-17T05:15:27.148Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "0658768549",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "VINCENT Fabrice",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0658768549",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR (CENTRE COTE D'AZUR - CORSE)",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000464993",
            "XylemReference": "201701",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison à 18h !\nLe livreur m'a appelé à plusieurs reprises car nous étions fermé. \nEn effet,  il s'est présenté en-dehors de nos jours et horaires de réception colis. \nIl m'a dit qu'il ne pourrait jamais venir dans nos créneaux horaires et du coup, je l'ai autorisé d'enjamber le portail pour déposer le colis.\n",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d6fb328848db8cfaa35",
        "extendedResponse": {
            "_id": "63ef86968196e3c449e109ed",
            "submittedBody": {
                "id": "63ef0d6fb328848db8cfaa350.595069201920952",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\n\nLa réunion Teams avec Geoffrey WATELEY et Vladimir a été très constructive.",
                "creationDate": "2023-02-17T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.beirer@johncockerill.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dec9dc3a-6d06-4335-9ea1-721a9a4d2a49",
            "responseId": "63ef0d6fb328848db8cfaa35",
            "receivedResponse": {
                "id": "63ef0d6fb328848db8cfaa350.595069201920952",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\n\nLa réunion Teams avec Geoffrey WATELEY et Vladimir a été très constructive.",
                "creationDate": "2023-02-17T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-17T05:15:27.488Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-13_06.15.04",
        "integration": {
            "ContactKey": "olivier.beirer@johncockerill.com",
            "CustomerContactEmail": "olivier.beirer@johncockerill.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BEIRER",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "0764173360",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CMI Proserpol - John Cockerill Environment",
            "CustomerCompanyGroupName": "CMI PROSERPOL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "WATELET",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "WATELET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\n\nLa réunion Teams avec Geoffrey WATELEY et Vladimir a été très constructive.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d6fb328848db8cfb0b1",
        "extendedResponse": {
            "_id": "63ef86968196e3c449e109ee",
            "submittedBody": {
                "id": "63ef0d6fb328848db8cfb0b10.3841331925203386",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bel échange",
                "creationDate": "2023-02-17T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "adrien.grandcolas@ip-france.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "55db193d-5658-457f-bc79-dc6a66aeb78d",
            "responseId": "63ef0d6fb328848db8cfb0b1",
            "receivedResponse": {
                "id": "63ef0d6fb328848db8cfb0b10.3841331925203386",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bel échange",
                "creationDate": "2023-02-17T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-17T05:15:27.563Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-13_06.15.04",
        "integration": {
            "ContactKey": "adrien.grandcolas@ip-france.net",
            "CustomerContactEmail": "adrien.grandcolas@ip-france.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GRANDCOLAS",
            "CustomerContactFirstName": "Adrien",
            "CustomerContactMobilePhone": "0603410328",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "IP FRANCE",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Hugo",
            "XylemContactedPersonName": "DEBETTE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bel échange",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d6fb328848db8cfb451",
        "extendedResponse": {
            "_id": "63ef86968196e3c449e109ef",
            "submittedBody": {
                "id": "63ef0d6fb328848db8cfb4510.2953112952400365",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très satisfait, réactivité au top, délai de réponse très très court,  échange agréable, ... Super",
                "creationDate": "2023-02-17T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kbidrane@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9e6d364b-362e-4995-a4d6-405c061bb67b",
            "responseId": "63ef0d6fb328848db8cfb451",
            "receivedResponse": {
                "id": "63ef0d6fb328848db8cfb4510.2953112952400365",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très satisfait, réactivité au top, délai de réponse très très court, échange agréable, ... Super",
                "creationDate": "2023-02-17T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-17T05:15:27.989Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-17_06.15.09",
        "integration": {
            "ContactKey": "kbidrane@hydralians.com",
            "CustomerContactEmail": "kbidrane@hydralians.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIDRANE",
            "CustomerContactFirstName": "Kamel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRALIANS",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013156",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très satisfait, réactivité au top, délai de réponse très très court,  échange agréable, ... Super",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d70b328848db8cfb4c0",
        "extendedResponse": {
            "_id": "63ef86968196e3c449e109f0",
            "submittedBody": {
                "id": "63ef0d70b328848db8cfb4c00.4378357351981539",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Echange téléphonique pour compléter une commande sur E.Access PARFAIT",
                "creationDate": "2023-02-17T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "v.cousin@tgfluides.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "3f71d822-3488-4035-b98b-47333a9bde71",
            "responseId": "63ef0d70b328848db8cfb4c0",
            "receivedResponse": {
                "id": "63ef0d70b328848db8cfb4c00.4378357351981539",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Echange téléphonique pour compléter une commande sur E.accès PARFAIT",
                "creationDate": "2023-02-17T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-17T05:15:28.067Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-17_06.15.09",
        "integration": {
            "ContactKey": "v.cousin@tgfluides.com",
            "CustomerContactEmail": "v.cousin@tgfluides.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "COUSIN",
            "CustomerContactFirstName": "Valérie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TGF GENIE CLIMATIQUE",
            "CustomerCompanyGroupName": "TGF SARL",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "BOUZIGON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223013214",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Echange téléphonique pour compléter une commande sur E.Access PARFAIT",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d70b328848db8cfb4dd",
        "extendedResponse": {
            "_id": "63ef86978196e3c449e109f1",
            "submittedBody": {
                "id": "63ef0d70b328848db8cfb4dd0.7174801286953358",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse à nos consultations dans les délais",
                "creationDate": "2023-02-17T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "adel.ghabi.ext@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "31222843-7b6c-43ea-93d1-f4c0b6a06d0b",
            "responseId": "63ef0d70b328848db8cfb4dd",
            "receivedResponse": {
                "id": "63ef0d70b328848db8cfb4dd0.7174801286953358",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse à nos consultations dans les délais",
                "creationDate": "2023-02-17T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-17T05:15:28.170Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-17_06.15.09",
        "integration": {
            "ContactKey": "adel.ghabi.ext@veolia.com",
            "CustomerContactEmail": "adel.ghabi.ext@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GHABI",
            "CustomerContactFirstName": "Adel",
            "CustomerContactMobilePhone": "0647116992",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OTV France Sud SERVICES",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Sebastien",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013251",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse à nos consultations dans les délais",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d70b328848db8cfb5fc",
        "extendedResponse": {
            "_id": "63ef86978196e3c449e109f2",
            "submittedBody": {
                "id": "63ef0d70b328848db8cfb5fc0.1750436925595802",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Dans les PLUS : votre matériel super qualité, fiabilité, efficacité. Le choix technique et accompagnement du technico-commercial super! \nDans les moins : Pas d'accompagnement réel et efficace dans les mises en service formation au matériel etc..... ",
                "creationDate": "2023-02-17T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "luc.billa@grandreims.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "77408c8a-2ae6-459c-8ecd-4a8774b151cd",
            "responseId": "63ef0d70b328848db8cfb5fc",
            "receivedResponse": {
                "id": "63ef0d70b328848db8cfb5fc0.1750436925595802",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Dans les PLUS : votre matériel super qualité, fiabilité, efficacité. Le choix technique et accompagnement du technico-commercial super! \nDans les moins : Pas d'accompagnement réel et efficace dans les mises en service formation au matériel etc..... ",
                "creationDate": "2023-02-17T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.neg",
                                    "label": "Attentiveness and availability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "qualite.pos",
                "joignabilite.neg",
                "ecoute.neg",
                "competence.pos",
                "efficacite.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ecoute.neg",
                    "label": "Attentiveness and availability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-17T05:15:28.324Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-17_06.15.09",
        "integration": {
            "ContactKey": "luc.billa@grandreims.fr",
            "CustomerContactEmail": "luc.billa@grandreims.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BILLA",
            "CustomerContactFirstName": "Luc",
            "CustomerContactMobilePhone": "0678784497",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRANDREIMS",
            "CustomerCompanyGroupName": "COMM URBAINE DU GRAND REIMS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Nicolas",
            "XylemContactedPersonName": "SMEJ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223013352",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Dans les PLUS : votre matériel super qualité, fiabilité, efficacité. Le choix technique et accompagnement du technico-commercial super! \nDans les moins : Pas d'accompagnement réel et efficace dans les mises en service formation au matériel etc..... ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Formation gratuite sur le matériel et surtout les systèmes d'exploitation comme CONCERTOR XPC ou SMART RUN"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63ef0d70b328848db8cfb627",
        "extendedResponse": {
            "_id": "63ef86978196e3c449e109f3",
            "submittedBody": {
                "id": "63ef0d70b328848db8cfb6270.4472671644238817",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité et bonne technicité , bon échange.",
                "creationDate": "2023-02-17T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vincent.dehais@lyonnaise-des-eaux.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e3f38520-eee7-4604-9b6d-da2e8df99e44",
            "responseId": "63ef0d70b328848db8cfb627",
            "receivedResponse": {
                "id": "63ef0d70b328848db8cfb6270.4472671644238817",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité et bonne technicité , bon échange.",
                "creationDate": "2023-02-17T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-17T05:15:28.408Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-17_06.15.09",
        "integration": {
            "ContactKey": "vincent.dehais@lyonnaise-des-eaux.fr",
            "CustomerContactEmail": "vincent.dehais@lyonnaise-des-eaux.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEHAIS",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0674265266",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013415",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité et bonne technicité , bon échange.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef0ab5b785dea32c967",
        "extendedResponse": {
            "_id": "63f8b33a108bcd5092d9d1a0",
            "submittedBody": {
                "id": "63f05ef0ab5b785dea32c9670.6934925630349333",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bon relationnel ",
                "creationDate": "2023-02-18T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-pierre.lafond@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "01cd7eae-5046-4a7e-8ecf-43aee43625d1",
            "responseId": "63f05ef0ab5b785dea32c967",
            "receivedResponse": {
                "id": "63f05ef0ab5b785dea32c9670.6934925630349333",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bon relationnel ",
                "creationDate": "2023-02-18T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:28.766Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "jean-pierre.lafond@dalkia.fr",
            "CustomerContactEmail": "jean-pierre.lafond@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAFOND",
            "CustomerContactFirstName": "Jean Pierre",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bon relationnel ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef0277c48375e656ad0",
        "extendedResponse": {
            "_id": "63f8b33b108bcd5092d9d1a1",
            "submittedBody": {
                "id": "63f05ef0277c48375e656ad00.30837271548923684",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité et la disponibilité du produit ",
                "creationDate": "2023-02-18T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0616365195"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cf5c0843-264f-410e-8e6b-dbcc69f0b546",
            "responseId": "63f05ef0277c48375e656ad0",
            "receivedResponse": {
                "id": "63f05ef0277c48375e656ad00.30837271548923684",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité et la disponibilité du produit ",
                "creationDate": "2023-02-18T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:28.850Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "0616365195",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "DIALLO Mamoudou",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0616365195",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VALOMSY",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3689011",
            "XylemReference": "201901",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité et la disponibilité du produit ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef0277c48375e656ad5",
        "extendedResponse": {
            "_id": "63f8b33b108bcd5092d9d1a2",
            "submittedBody": {
                "id": "63f05ef0277c48375e656ad50.7607840587078909",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide et efficace ",
                "creationDate": "2023-02-18T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0612231940"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "893ca9d5-a42e-43f4-b0fc-69ed7f607592",
            "responseId": "63f05ef0277c48375e656ad5",
            "receivedResponse": {
                "id": "63f05ef0277c48375e656ad50.7607840587078909",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide et efficace ",
                "creationDate": "2023-02-18T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:28.859Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-14_06.15.09",
        "integration": {
            "ContactKey": "0612231940",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BENJAMIN DEMARS",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0612231940",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CLAISSE ENVIRONNEMENT",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CDF S0230 2022 009331",
            "XylemReference": "202285",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide et efficace ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef0ab5b785dea32c990",
        "extendedResponse": {
            "_id": "63f8b33b108bcd5092d9d1a3",
            "submittedBody": {
                "id": "63f05ef0ab5b785dea32c9900.03747993971628416",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rencontré fructueuse",
                "creationDate": "2023-02-18T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nordine.himi@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "64e50156-3566-4958-9852-1b7d9be1f8ea",
            "responseId": "63f05ef0ab5b785dea32c990",
            "receivedResponse": {
                "id": "63f05ef0ab5b785dea32c9900.03747993971628416",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rencontré fructueuse",
                "creationDate": "2023-02-18T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-18T05:15:28.876Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "nordine.himi@dalkia.fr",
            "CustomerContactEmail": "nordine.himi@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HIMI",
            "CustomerContactFirstName": "Nordine",
            "CustomerContactMobilePhone": "0619456162",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA CENTRE OPERATIONEL RESEAUX NORD-OUEST",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rencontré fructueuse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef0ab5b785dea32c99b",
        "extendedResponse": {
            "_id": "63f8b33c108bcd5092d9d1a4",
            "submittedBody": {
                "id": "63f05ef0ab5b785dea32c99b0.813341511147349",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "j'ai bien aimer la réactivité  de vos service (prise de rdv /intervention) ",
                "creationDate": "2023-02-18T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "giovanni.cardon@perfhome.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "01c52c1a-5345-413b-b515-e7c4dd30f648",
            "responseId": "63f05ef0ab5b785dea32c99b",
            "receivedResponse": {
                "id": "63f05ef0ab5b785dea32c99b0.813341511147349",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "j'ai bien aimer la réactivité de vos service (prise de rendez-vous /intervention) ",
                "creationDate": "2023-02-18T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:28.907Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "giovanni.cardon@perfhome.com",
            "CustomerContactEmail": "giovanni.cardon@perfhome.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CARDON",
            "CustomerContactFirstName": "Giovanni",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PERFHOME",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "j'ai bien aimer la réactivité  de vos service (prise de rdv /intervention) ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "revoir le  support à remplir pour la programmations de mise en service , nous devons transmettre  les coordonnés de mon client (promoteur ) qui me semble pas nécessaire pour une mise en service"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1ab5b785dea32c9cc",
        "extendedResponse": {
            "_id": "63f8b33c108bcd5092d9d1a5",
            "submittedBody": {
                "id": "63f05ef1ab5b785dea32c9cc0.6000265182016544",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité, conseil et réactivité",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe@sarldelage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "52f5c8a5-cf00-4e4c-a46a-dfa020af9a00",
            "responseId": "63f05ef1ab5b785dea32c9cc",
            "receivedResponse": {
                "id": "63f05ef1ab5b785dea32c9cc0.6000265182016544",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité, conseil et réactivité",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.038Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "philippe@sarldelage.com",
            "CustomerContactEmail": "philippe@sarldelage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GREBERT",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0677427417",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DELAGE",
            "CustomerCompanyGroupName": "SARL DELAGE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité, conseil et réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e6578a0",
        "extendedResponse": {
            "_id": "63f8b33c108bcd5092d9d1a6",
            "submittedBody": {
                "id": "63f05ef1277c48375e6578a00.02706614847121247",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité et réponse à la question excellentes",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-luc.maymard@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4e09093d-d7b7-4fd8-aa6f-bd1cabb9161d",
            "responseId": "63f05ef1277c48375e6578a0",
            "receivedResponse": {
                "id": "63f05ef1277c48375e6578a00.02706614847121247",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité et réponse à la question excellentes",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.360Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "jean-luc.maymard@veolia.com",
            "CustomerContactEmail": "jean-luc.maymard@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MAYMARD",
            "CustomerContactFirstName": "Jean Luc",
            "CustomerContactMobilePhone": "0616396665",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CGE - VÉOLIA - CEO",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Sebastien",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013462",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité et réponse à la question excellentes",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e6578d0",
        "extendedResponse": {
            "_id": "63f8b33d108bcd5092d9d1a7",
            "submittedBody": {
                "id": "63f05ef1277c48375e6578d00.8786359427479009",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité ",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christelle.meyer@cedeo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "f715ee41-6695-478c-a8d4-62aa6decd878",
            "responseId": "63f05ef1277c48375e6578d0",
            "receivedResponse": {
                "id": "63f05ef1277c48375e6578d00.8786359427479009",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité ",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.413Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "christelle.meyer@cedeo.fr",
            "CustomerContactEmail": "christelle.meyer@cedeo.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MEYER",
            "CustomerContactFirstName": "CHRISTELLE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CEDEO",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013507",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e6579c7",
        "extendedResponse": {
            "_id": "63f8b33d108bcd5092d9d1a8",
            "submittedBody": {
                "id": "63f05ef1277c48375e6579c70.0393819716247954",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité ",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrice.lafilolie@tereva.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "131c63d0-8e31-4007-94c0-5a966ada2d4d",
            "responseId": "63f05ef1277c48375e6579c7",
            "receivedResponse": {
                "id": "63f05ef1277c48375e6579c70.0393819716247954",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité ",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.513Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "patrice.lafilolie@tereva.fr",
            "CustomerContactEmail": "patrice.lafilolie@tereva.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAFILOLIE",
            "CustomerContactFirstName": "Patrice",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREVA BRIVE",
            "CustomerCompanyGroupName": "MBE (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223013568",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e657a55",
        "extendedResponse": {
            "_id": "63f8b33d108bcd5092d9d1a9",
            "submittedBody": {
                "id": "63f05ef1277c48375e657a550.7843263308519546",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Renseignement clair et bien présenté",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-luc.hilmoine@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "58b142ab-b1f7-4a21-9482-183bce85ba0a",
            "responseId": "63f05ef1277c48375e657a55",
            "receivedResponse": {
                "id": "63f05ef1277c48375e657a550.7843263308519546",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Renseignement clair et bien présenté",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.550Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "jean-luc.hilmoine@veolia.com",
            "CustomerContactEmail": "jean-luc.hilmoine@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HILMOINE",
            "CustomerContactFirstName": "Jean Luc",
            "CustomerContactMobilePhone": "0622265460",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "USINE EAU POTABLE AIRE (VEOLIA)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013593",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Renseignement clair et bien présenté",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e657a85",
        "extendedResponse": {
            "_id": "63f8b33e108bcd5092d9d1aa",
            "submittedBody": {
                "id": "63f05ef1277c48375e657a850.21464573786595875",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité, les devis sont fait rapidement.\n\nLe seul point négatif, la société que nous demandons de faire apparaitre sur les devis n'est presque jamais respecté.",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julien.tagliarino@eauxdemarseille.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "6f5733cd-86e4-4fe7-bdb4-763455885d37",
            "responseId": "63f05ef1277c48375e657a85",
            "receivedResponse": {
                "id": "63f05ef1277c48375e657a850.21464573786595875",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité, les devis sont fait rapidement.\n\nLe seul point négatif, la société que nous demandons de faire apparaître sur les devis n'est presque jamais respecté.",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.neg",
                                    "label": "Welcome / Kindness (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "accueil.neg",
                "efficacite.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.neg",
                    "label": "Welcome / Kindness (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.582Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "julien.tagliarino@eauxdemarseille.fr",
            "CustomerContactEmail": "julien.tagliarino@eauxdemarseille.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TAGLIARINO",
            "CustomerContactFirstName": "Julien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE MARSEILLE (SOCIETE DES)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013600",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité, les devis sont fait rapidement.\n\nLe seul point négatif, la société que nous demandons de faire apparaitre sur les devis n'est presque jamais respecté.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Mettre la bonne société sur les devis."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e657adc",
        "extendedResponse": {
            "_id": "63f8b33e108bcd5092d9d1ab",
            "submittedBody": {
                "id": "63f05ef1277c48375e657adc0.9784727116490806",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les + : rapidités des retours ; précisions des devis et conseils.\nLes  - : R.A.S",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denny.eyssartier@cde.nc"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "021e5961-46f8-4542-a207-29ca8308c1bc",
            "responseId": "63f05ef1277c48375e657adc",
            "receivedResponse": {
                "id": "63f05ef1277c48375e657adc0.9784727116490806",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les + : rapidités des retours ; précisions des devis et conseils.\nLes - : R.A.S",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.600Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "denny.eyssartier@cde.nc",
            "CustomerContactEmail": "denny.eyssartier@cde.nc",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "EYSSARTIER",
            "CustomerContactFirstName": "DENNY",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CALEDONIENNE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013612",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les + : rapidités des retours ; précisions des devis et conseils.\nLes  - : R.A.S",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e657ba2",
        "extendedResponse": {
            "_id": "63f8b33e108bcd5092d9d1ac",
            "submittedBody": {
                "id": "63f05ef1277c48375e657ba20.8847476462551764",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "offre incompléte \ncommerciale injoignable ",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "couchet@wapsas.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "61d60efc-af0b-46d8-ba34-d85a4e6422e5",
            "responseId": "63f05ef1277c48375e657ba2",
            "receivedResponse": {
                "id": "63f05ef1277c48375e657ba20.8847476462551764",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "offre incomplète \ncommerciale injoignable ",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "joignabilite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.690Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "couchet@wapsas.com",
            "CustomerContactEmail": "couchet@wapsas.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COUCHET",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0609625474",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Water African Project (WAP)",
            "CustomerCompanyGroupName": "WATER AFRICAN PROJECT SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "Joachim",
            "XylemContactedPersonName": "GAUDON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013490",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "offre incompléte \ncommerciale injoignable ",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f05ef1277c48375e657d05",
        "extendedResponse": {
            "_id": "63f8b33f108bcd5092d9d1ad",
            "submittedBody": {
                "id": "63f05ef1277c48375e657d050.2007479869786708",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonne reaction de la part de vos equipes",
                "creationDate": "2023-02-18T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.fiorentino@seram-metropole.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "571e2605-a95b-4640-a9e3-a327cc20dcd2",
            "responseId": "63f05ef1277c48375e657d05",
            "receivedResponse": {
                "id": "63f05ef1277c48375e657d050.2007479869786708",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonne réaction de la part de vos équipes",
                "creationDate": "2023-02-18T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-18T05:15:29.996Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "frederic.fiorentino@seram-metropole.fr",
            "CustomerContactEmail": "frederic.fiorentino@seram-metropole.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FIORENTINO",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0622581640",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERAMM",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013753",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonne reaction de la part de vos equipes",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f45372c15ff4bb0b831c1d",
        "extendedResponse": {
            "_id": "63f8b33f108bcd5092d9d1ae",
            "submittedBody": {
                "id": "63f45372c15ff4bb0b831c1d0.7220373646377023",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Personne très à l écoute de mes problèmes technique ",
                "creationDate": "2023-02-21T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.brasseur@mci.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "80bba53f-8ca2-4dff-8700-4d8c69f4b497",
            "responseId": "63f45372c15ff4bb0b831c1d",
            "receivedResponse": {
                "id": "63f45372c15ff4bb0b831c1d0.7220373646377023",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Personne très à l écoute de mes problèmes technique ",
                "creationDate": "2023-02-21T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-21T05:15:30.547Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "david.brasseur@mci.fr",
            "CustomerContactEmail": "david.brasseur@mci.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BRASSEUR",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MCI",
            "CustomerCompanyGroupName": "MCI SAS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Personne très à l écoute de mes problèmes technique ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f453726f6b3ab012144067",
        "extendedResponse": {
            "_id": "63f8b33f108bcd5092d9d1af",
            "submittedBody": {
                "id": "63f453726f6b3ab0121440670.3239571868286273",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J'ai reçu à 6h15 votre demande sur ma messagerie du téléphone ce qui est fort désagréable, à l'avenir je souhaiterai ne pas être réveille pour ce genre de demande",
                "creationDate": "2023-02-21T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.cros@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "9b7f9a10-e8cc-4f19-8da6-88b310d19c4d",
            "responseId": "63f453726f6b3ab012144067",
            "receivedResponse": {
                "id": "63f453726f6b3ab0121440670.3239571868286273",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J'ai reçu à 6h15 votre demande sur ma messagerie du téléphone ce qui est fort désagréable, à l'avenir je souhaiterai ne pas être réveille pour ce genre de demande",
                "creationDate": "2023-02-21T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-21T05:15:30.638Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "eric.cros@veolia.com",
            "CustomerContactEmail": "eric.cros@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CROS",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "0603562076",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013755",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J'ai reçu à 6h15 votre demande sur ma messagerie du téléphone ce qui est fort désagréable, à l'avenir je souhaiterai ne pas être réveille pour ce genre de demande",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f453726f6b3ab0121441f7",
        "extendedResponse": {
            "_id": "63f8b340108bcd5092d9d1b0",
            "submittedBody": {
                "id": "63f453726f6b3ab0121441f70.029445401248080483",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Pb rdv Schoenberg par  SMS + rdv téléphonique pour livraison lundi matin et finalement le chauffeur est venu l'après-midi ! ",
                "creationDate": "2023-02-21T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0635193561"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "f11465c0-9178-4cfc-8615-973dee0d920b",
            "responseId": "63f453726f6b3ab0121441f7",
            "receivedResponse": {
                "id": "63f453726f6b3ab0121441f70.029445401248080483",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "problème rendez-vous Schoenberg par SMS + rendez-vous téléphonique pour livraison lundi matin et finalement le chauffeur est venu l'après-midi ! ",
                "creationDate": "2023-02-21T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-21T05:15:30.819Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "0635193561",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "VILLEMAGNE Laurent",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0635193561",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "AYEL Stéphane",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3658449",
            "XylemReference": "196541",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Pb rdv Schoenberg par  SMS + rdv téléphonique pour livraison lundi matin et finalement le chauffeur est venu l'après-midi ! ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f453736f6b3ab0121448a6",
        "extendedResponse": {
            "_id": "63f8b340108bcd5092d9d1b1",
            "submittedBody": {
                "id": "63f453736f6b3ab0121448a60.3113970881403454",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonne réactivité de réponse ",
                "creationDate": "2023-02-21T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "malika.giesner@actemium.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bde52f7f-11cb-4815-809a-5e1907c633ec",
            "responseId": "63f453736f6b3ab0121448a6",
            "receivedResponse": {
                "id": "63f453736f6b3ab0121448a60.3113970881403454",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonne réactivité de réponse ",
                "creationDate": "2023-02-21T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-21T05:15:31.040Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "malika.giesner@actemium.com",
            "CustomerContactEmail": "malika.giesner@actemium.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "GIESNER",
            "CustomerContactFirstName": "Malika",
            "CustomerContactMobilePhone": "0633652581",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ACTEMIUM PMD",
            "CustomerCompanyGroupName": "MONNIER SARL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223013928",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonne réactivité de réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f453736f6b3ab0121448a7",
        "extendedResponse": {
            "_id": "63f8b340108bcd5092d9d1b2",
            "submittedBody": {
                "id": "63f453736f6b3ab0121448a70.8263167867978098",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponible et compétent ",
                "creationDate": "2023-02-21T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "g.chabory@sioule-morge.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d8366723-5d6f-40d7-b6dd-c534a9d23c84",
            "responseId": "63f453736f6b3ab0121448a7",
            "receivedResponse": {
                "id": "63f453736f6b3ab0121448a70.8263167867978098",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponible et compétent ",
                "creationDate": "2023-02-21T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-21T05:15:31.043Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "g.chabory@sioule-morge.fr",
            "CustomerContactEmail": "g.chabory@sioule-morge.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHABORY",
            "CustomerContactFirstName": "GUILLAUME",
            "CustomerContactMobilePhone": "0616675940",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIVOM SIOULE ET MORGE",
            "CustomerCompanyGroupName": "SYNDICAT MIXTE SIOULE ET MORGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN",
            "XylemContactedPersonFirstName": "Joachim",
            "XylemContactedPersonName": "GAUDON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223013922",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponible et compétent ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f453736f6b3ab012144a82",
        "extendedResponse": {
            "_id": "63f8b340108bcd5092d9d1b3",
            "submittedBody": {
                "id": "63f453736f6b3ab012144a820.7948192764448514",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "pas de réponse ",
                "creationDate": "2023-02-21T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ffricaud@prolians.eu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "d827864a-8a54-4b0c-91ce-db646a953f42",
            "responseId": "63f453736f6b3ab012144a82",
            "receivedResponse": {
                "id": "63f453736f6b3ab012144a820.7948192764448514",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "pas de réponse ",
                "creationDate": "2023-02-21T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "joignabilite.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-02-21T05:15:31.278Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "ffricaud@prolians.eu",
            "CustomerContactEmail": "ffricaud@prolians.eu",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "FRICAUD",
            "CustomerContactFirstName": "Fatima",
            "CustomerContactMobilePhone": "0670275565",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CACC ROYAN",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014078",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "pas de réponse ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f44e210621089ec3c2",
        "extendedResponse": {
            "_id": "63f8b341108bcd5092d9d1b4",
            "submittedBody": {
                "id": "63f5a4f44e210621089ec3c20.3374518521942156",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ras",
                "creationDate": "2023-02-22T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0603500636"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e75e0eb5-9512-4102-9e20-49f480b0cca2",
            "responseId": "63f5a4f44e210621089ec3c2",
            "receivedResponse": {
                "id": "63f5a4f44e210621089ec3c20.3374518521942156",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ras",
                "creationDate": "2023-02-22T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-22T05:15:32.926Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "0603500636",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "THOMAS Antoine",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0603500636",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE ETAPLES)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3690979",
            "XylemReference": "202354",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ras",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f44e210621089ec3ca",
        "extendedResponse": {
            "_id": "63f8b341108bcd5092d9d1b5",
            "submittedBody": {
                "id": "63f5a4f44e210621089ec3ca0.1286315610482931",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité ",
                "creationDate": "2023-02-22T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0658291741"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dfa170c8-8913-48da-8b6e-062f30dedb70",
            "responseId": "63f5a4f44e210621089ec3ca",
            "receivedResponse": {
                "id": "63f5a4f44e210621089ec3ca0.1286315610482931",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité ",
                "creationDate": "2023-02-22T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-22T05:15:32.944Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "0658291741",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "LEMAIRE Laurent",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0658291741",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOC RHONE ALPES et GRANDS PROJETS",
            "CustomerCompanyGroupName": "SOC",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "68NS03-068 A10791923",
            "XylemReference": "202577",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f5da4f9b269082db54",
        "extendedResponse": {
            "_id": "63f8b341108bcd5092d9d1b6",
            "submittedBody": {
                "id": "63f5a4f5da4f9b269082db540.6694515784908437",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon échange, constructif et informatif",
                "creationDate": "2023-02-22T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c.moneron@heca.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "25d4dae9-cae6-49d5-b9f3-ca1f42a642bd",
            "responseId": "63f5a4f5da4f9b269082db54",
            "receivedResponse": {
                "id": "63f5a4f5da4f9b269082db540.6694515784908437",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon échange, constructif et informatif",
                "creationDate": "2023-02-22T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-22T05:15:33.282Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-18_06.15.09",
        "integration": {
            "ContactKey": "c.moneron@heca.fr",
            "CustomerContactEmail": "c.moneron@heca.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONERON",
            "CustomerContactFirstName": "Cyril",
            "CustomerContactMobilePhone": "0623802139",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRAULIQUE ENVIRONNEMENT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Cédric",
            "XylemContactedPersonName": "ROYER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon échange, constructif et informatif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f5da4f9b269082dbcb",
        "extendedResponse": {
            "_id": "63f8b342108bcd5092d9d1b7",
            "submittedBody": {
                "id": "63f5a4f5da4f9b269082dbcb0.2005371716130664",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Point positif : le service est toujours bien présent\nPoint négatif : Les tarifs sont horriblement élevés au point que je me pose la question de savoir si proposer une autre marque ne serait pas plus approprié, plutôt que de me retrouver en face de distributeurs qui ont de bien meilleurs remises que nous.",
                "creationDate": "2023-02-22T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ludovic.vilpou@rubix.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "a6d3f760-d42d-4290-b59a-c5635b271c3a",
            "responseId": "63f5a4f5da4f9b269082dbcb",
            "receivedResponse": {
                "id": "63f5a4f5da4f9b269082dbcb0.2005371716130664",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Point positif : le service est toujours bien présent\nPoint négatif : Les tarifs sont horriblement élevés au point que je me pose la question de savoir si proposer une autre marque ne serait pas plus approprié, plutôt que de me retrouver en face de distributeurs qui ont de bien meilleurs remises que nous.",
                "creationDate": "2023-02-22T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.neg",
                                    "label": "Price (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "prix.neg",
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "prix.neg",
                    "label": "Price (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-22T05:15:33.387Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "ludovic.vilpou@rubix.com",
            "CustomerContactEmail": "ludovic.vilpou@rubix.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VILPOU",
            "CustomerContactFirstName": "Ludovic",
            "CustomerContactMobilePhone": "0680758245",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RUBIX FRANCE",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014400",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Point positif : le service est toujours bien présent\nPoint négatif : Les tarifs sont horriblement élevés au point que je me pose la question de savoir si proposer une autre marque ne serait pas plus approprié, plutôt que de me retrouver en face de distributeurs qui ont de bien meilleurs remises que nous.",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Ludovic Vilpou\",\n  \"phone\": \"06 80 75 82 45\",\n  \"mail\": \"ludovic.vilpou@rubix.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f5da4f9b269082dcba",
        "extendedResponse": {
            "_id": "63f8b342108bcd5092d9d1b8",
            "submittedBody": {
                "id": "63f5a4f5da4f9b269082dcba0.5059524894188379",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse",
                "creationDate": "2023-02-22T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nsore@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0d3b1b64-aaf4-437c-89e7-1c877f5d253d",
            "responseId": "63f5a4f5da4f9b269082dcba",
            "receivedResponse": {
                "id": "63f5a4f5da4f9b269082dcba0.5059524894188379",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse",
                "creationDate": "2023-02-22T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-22T05:15:33.525Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "nsore@hydralians.com",
            "CustomerContactEmail": "nsore@hydralians.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "SORE",
            "CustomerContactFirstName": "Nathalie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRALIANS",
            "CustomerCompanyGroupName": "PROSJET IRRIGARONNE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223014175",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f5da4f9b269082dd21",
        "extendedResponse": {
            "_id": "63f8b342108bcd5092d9d1b9",
            "submittedBody": {
                "id": "63f5a4f5da4f9b269082dd210.10002191890667222",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité sur le retour de ma demande de prix",
                "creationDate": "2023-02-22T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pascal.couronne@vinci-construction.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d7a944be-51b6-4a45-b330-38884016cb45",
            "responseId": "63f5a4f5da4f9b269082dd21",
            "receivedResponse": {
                "id": "63f5a4f5da4f9b269082dd210.10002191890667222",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité sur le retour de ma demande de prix",
                "creationDate": "2023-02-22T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-22T05:15:33.630Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "pascal.couronne@vinci-construction.fr",
            "CustomerContactEmail": "pascal.couronne@vinci-construction.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COURONNE",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "0625026205",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOGEA ENVIRONNEMENT 44",
            "CustomerCompanyGroupName": "VINCI CF (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Pierrick",
            "XylemContactedPersonName": "FRAPPAS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223014247",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité sur le retour de ma demande de prix",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f5da4f9b269082dd22",
        "extendedResponse": {
            "_id": "63f8b343108bcd5092d9d1ba",
            "submittedBody": {
                "id": "63f5a4f5da4f9b269082dd220.7714550930018889",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide et claire ! Parfait !",
                "creationDate": "2023-02-22T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "saet.distribution2@luvica.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ca2ee3a3-3d02-4b25-aac4-68340d1178e8",
            "responseId": "63f5a4f5da4f9b269082dd22",
            "receivedResponse": {
                "id": "63f5a4f5da4f9b269082dd220.7714550930018889",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide et claire ! Parfait !",
                "creationDate": "2023-02-22T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-22T05:15:33.632Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "saet.distribution2@luvica.fr",
            "CustomerContactEmail": "saet.distribution2@luvica.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONNOURY",
            "CustomerContactFirstName": "Denis",
            "CustomerContactMobilePhone": "0970823903",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAET",
            "CustomerCompanyGroupName": "Groupe LUVICA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014194",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide et claire ! Parfait !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f5da4f9b269082dee5",
        "extendedResponse": {
            "_id": "63f8b343108bcd5092d9d1bb",
            "submittedBody": {
                "id": "63f5a4f5da4f9b269082dee50.9517438631738737",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réponse",
                "creationDate": "2023-02-22T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bcadoret@armorinox.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2cd8d52d-e1e6-4d83-8f14-0e3a0e43af6d",
            "responseId": "63f5a4f5da4f9b269082dee5",
            "receivedResponse": {
                "id": "63f5a4f5da4f9b269082dee50.9517438631738737",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réponse",
                "creationDate": "2023-02-22T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-22T05:15:33.928Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "bcadoret@armorinox.com",
            "CustomerContactEmail": "bcadoret@armorinox.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CADORET",
            "CustomerContactFirstName": "Bernard",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ARMOR INOX",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014431",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f5da4f9b269082def0",
        "extendedResponse": {
            "_id": "63f8b343108bcd5092d9d1bc",
            "submittedBody": {
                "id": "63f5a4f5da4f9b269082def00.5942300179813664",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Proposition technique + délai de réponse ",
                "creationDate": "2023-02-22T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thierrybuffard@cgr-robinetterie.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "92623357-5bd1-407f-8f70-f927200db59f",
            "responseId": "63f5a4f5da4f9b269082def0",
            "receivedResponse": {
                "id": "63f5a4f5da4f9b269082def00.5942300179813664",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Proposition technique + délai de réponse ",
                "creationDate": "2023-02-22T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-22T05:15:33.943Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "thierrybuffard@cgr-robinetterie.fr",
            "CustomerContactEmail": "thierrybuffard@cgr-robinetterie.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BUFFARD",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CGR Sud-Ouest",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014461",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Proposition technique + délai de réponse ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f6da4f9b269082df7d",
        "extendedResponse": {
            "_id": "63f8b344108bcd5092d9d1bd",
            "submittedBody": {
                "id": "63f5a4f6da4f9b269082df7d0.7989562336339993",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité, expertise",
                "creationDate": "2023-02-22T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.ledain@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "26ac0b6f-4e97-4959-ae42-c5c5152897ee",
            "responseId": "63f5a4f6da4f9b269082df7d",
            "receivedResponse": {
                "id": "63f5a4f6da4f9b269082df7d0.7989562336339993",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité, expertise",
                "creationDate": "2023-02-22T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-22T05:15:34.012Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "olivier.ledain@eiffage.com",
            "CustomerContactEmail": "olivier.ledain@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE DAIN",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "0693930493",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROTECH SARL",
            "CustomerCompanyGroupName": "HYDROTECH",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN DT",
            "XylemContactedPersonFirstName": "Reynald",
            "XylemContactedPersonName": "MEGRET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014509",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité, expertise",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f5a4f6da4f9b269082dfe7",
        "extendedResponse": {
            "_id": "63f8b344108bcd5092d9d1be",
            "submittedBody": {
                "id": "63f5a4f6da4f9b269082dfe70.9409095813903798",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse et délai de réponse\n",
                "creationDate": "2023-02-22T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "h.lafond@01pompage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d2fbc6f0-5e27-4886-9651-d243537ad9d0",
            "responseId": "63f5a4f6da4f9b269082dfe7",
            "receivedResponse": {
                "id": "63f5a4f6da4f9b269082dfe70.9409095813903798",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse et délai de réponse\n",
                "creationDate": "2023-02-22T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-22T05:15:34.101Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-22_06.15.09",
        "integration": {
            "ContactKey": "h.lafond@01pompage.fr",
            "CustomerContactEmail": "h.lafond@01pompage.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAFOND",
            "CustomerContactFirstName": "Herve",
            "CustomerContactMobilePhone": "0667438523",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "01 POMPAGE",
            "CustomerCompanyGroupName": "APAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223014556",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse et délai de réponse\n",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Merci d'arrêter de harceler avec des enquêtes satisfactions ou autres."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6701b0703e463834ec7",
        "extendedResponse": {
            "_id": "63f8b344108bcd5092d9d1bf",
            "submittedBody": {
                "id": "63f6f6701b0703e463834ec70.38416211216732177",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "R A S",
                "creationDate": "2023-02-23T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0621112624"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "e2c33995-ac25-41fb-968e-54103f86ef77",
            "responseId": "63f6f6701b0703e463834ec7",
            "receivedResponse": {
                "id": "63f6f6701b0703e463834ec70.38416211216732177",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "R A S",
                "creationDate": "2023-02-23T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-23T05:15:28.861Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "0621112624",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Stephane LAUNAY",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0621112624",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RABA SARP SUD OUEST",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "C33300798/FZ01870",
            "XylemReference": "202822",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "R A S",
            "pageNo": "0",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Plus de réactivité "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6708043932b209007cd",
        "extendedResponse": {
            "_id": "63f8b344108bcd5092d9d1c0",
            "submittedBody": {
                "id": "63f6f6708043932b209007cd0.8684100270462962",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Pas de soucis avec vos équipes de villeneuve d'ascq  (59). efficaces et rapides...nous avons de très bonne relations.\n\nMerci de ne pas modifier cette équipe !!",
                "creationDate": "2023-02-23T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "baudoin.langlet@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "90738d48-5254-4554-a362-26f5da3a01a7",
            "responseId": "63f6f6708043932b209007cd",
            "receivedResponse": {
                "id": "63f6f6708043932b209007cd0.8684100270462962",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Pas de soucis avec vos équipes de Villeneuve d'ascq (59). efficaces et rapides...nous avons de très bonne relations.\n\nMerci de ne pas modifier cette équipe !!",
                "creationDate": "2023-02-23T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "accueil.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:28.865Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "baudoin.langlet@veolia.com",
            "CustomerContactEmail": "baudoin.langlet@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LANGLET",
            "CustomerContactFirstName": "Baudoin",
            "CustomerContactMobilePhone": "0646525945",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (STEP SELIANE)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014604",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Pas de soucis avec vos équipes de villeneuve d'ascq  (59). efficaces et rapides...nous avons de très bonne relations.\n\nMerci de ne pas modifier cette équipe !!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6705339fc5c90267848",
        "extendedResponse": {
            "_id": "63f8b345108bcd5092d9d1c1",
            "submittedBody": {
                "id": "63f6f6705339fc5c902678480.8301253805605793",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité, rapidité de réception des offres, niveau technique, rapport humain excellant.",
                "creationDate": "2023-02-23T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "n.rousseau@advinsi.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7b715b0c-15fd-4573-b2cf-b2fb88e596df",
            "responseId": "63f6f6705339fc5c90267848",
            "receivedResponse": {
                "id": "63f6f6705339fc5c902678480.8301253805605793",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité, rapidité de réception des offres, niveau technique, rapport humain excellent.",
                "creationDate": "2023-02-23T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:28.866Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "n.rousseau@advinsi.com",
            "CustomerContactEmail": "n.rousseau@advinsi.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROUSSEAU",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0660408462",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ADVINSI",
            "CustomerCompanyGroupName": "SAS CASLA ADVINSI",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Claude",
            "XylemContactedPersonName": "GUILLERMET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité, rapidité de réception des offres, niveau technique, rapport humain excellant.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6701b0703e463834ecf",
        "extendedResponse": {
            "_id": "63f8b345108bcd5092d9d1c2",
            "submittedBody": {
                "id": "63f6f6701b0703e463834ecf0.9624763711905964",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de la livraison",
                "creationDate": "2023-02-23T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0620262324"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b1d4d46d-6821-492e-b05f-e624902d1be9",
            "responseId": "63f6f6701b0703e463834ecf",
            "receivedResponse": {
                "id": "63f6f6701b0703e463834ecf0.9624763711905964",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de la livraison",
                "creationDate": "2023-02-23T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:28.880Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "0620262324",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "PERTUISOT Laurent",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0620262324",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CAPLATUB BLOIS",
            "CustomerCompanyGroupName": "HURSIN (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Romain LEPRINCE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "10807612",
            "XylemReference": "202894",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de la livraison",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6701b0703e463834efc",
        "extendedResponse": {
            "_id": "63f8b345108bcd5092d9d1c3",
            "submittedBody": {
                "id": "63f6f6701b0703e463834efc0.35265177145028703",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide",
                "creationDate": "2023-02-23T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0660638258"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f8b07c00-18c6-4a82-b48f-6207f8d62c16",
            "responseId": "63f6f6701b0703e463834efc",
            "receivedResponse": {
                "id": "63f6f6701b0703e463834efc0.35265177145028703",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide",
                "creationDate": "2023-02-23T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:28.957Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-02-21_06.15.07",
        "integration": {
            "ContactKey": "0660638258",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Pascal GORIN",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0660638258",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ERIDIS",
            "CustomerCompanyGroupName": "ERIDIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Michel",
            "XylemSalespeopleName": "ALBRECHT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CFE230319-CE230212",
            "XylemReference": "202749",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6718043932b20900d76",
        "extendedResponse": {
            "_id": "63f8b346108bcd5092d9d1c4",
            "submittedBody": {
                "id": "63f6f6718043932b20900d760.8153864725587137",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a plu c'est la rapidité de réponse.",
                "creationDate": "2023-02-23T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrick.marajo@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "afb5bb3c-411a-43b0-a08f-758d2314d606",
            "responseId": "63f6f6718043932b20900d76",
            "receivedResponse": {
                "id": "63f6f6718043932b20900d760.8153864725587137",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a plu c'est la rapidité de réponse.",
                "creationDate": "2023-02-23T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:29.046Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "patrick.marajo@suez.com",
            "CustomerContactEmail": "patrick.marajo@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARAJO",
            "CustomerContactFirstName": "Patrick",
            "CustomerContactMobilePhone": "0688630813",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (STEP DE SETE)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014628",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a plu c'est la rapidité de réponse.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6718043932b20900d95",
        "extendedResponse": {
            "_id": "63f8b346108bcd5092d9d1c5",
            "submittedBody": {
                "id": "63f6f6718043932b20900d950.12496263768025817",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité",
                "creationDate": "2023-02-23T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "station.filtres@villefranchederouergue.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5f7c7cea-b7f1-4ab0-9be8-41610a0aa0ab",
            "responseId": "63f6f6718043932b20900d95",
            "receivedResponse": {
                "id": "63f6f6718043932b20900d950.12496263768025817",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité",
                "creationDate": "2023-02-23T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:29.095Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "station.filtres@villefranchederouergue.fr",
            "CustomerContactEmail": "station.filtres@villefranchederouergue.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "OULES",
            "CustomerContactFirstName": "JEROME",
            "CustomerContactMobilePhone": "0612720030",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MAIRIE VILLEFRANCHE DE ROUERGUE",
            "CustomerCompanyGroupName": "HOTEL DE VILLE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "RIGAL",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "223014588",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6718043932b20900df8",
        "extendedResponse": {
            "_id": "63f8b346108bcd5092d9d1c6",
            "submittedBody": {
                "id": "63f6f6718043932b20900df80.4867426994531674",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "OK pour réactivité et explication de l'offre ",
                "creationDate": "2023-02-23T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.dupradeau@groupe-fb.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0a4eab6d-d05f-4cf0-9351-ccfe3100e644",
            "responseId": "63f6f6718043932b20900df8",
            "receivedResponse": {
                "id": "63f6f6718043932b20900df80.4867426994531674",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "OK pour réactivité et explication de l'offre ",
                "creationDate": "2023-02-23T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:29.180Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "stephane.dupradeau@groupe-fb.com",
            "CustomerContactEmail": "stephane.dupradeau@groupe-fb.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUPRADEAU",
            "CustomerContactFirstName": "STÉPHANE",
            "CustomerContactMobilePhone": "0608735166",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANS BONHOMME (234)",
            "CustomerCompanyGroupName": "FRANS BONHOMME (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Clément",
            "XylemContactedPersonName": "RAGUET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014736",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "OK pour réactivité et explication de l'offre ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6718043932b20900e42",
        "extendedResponse": {
            "_id": "63f8b347108bcd5092d9d1c7",
            "submittedBody": {
                "id": "63f6f6718043932b20900e420.6383458078129705",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Reactivite et clarte",
                "creationDate": "2023-02-23T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-luc.hilmoine@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "895ffead-1410-415c-96f4-83c47014c0e4",
            "responseId": "63f6f6718043932b20900e42",
            "receivedResponse": {
                "id": "63f6f6718043932b20900e420.6383458078129705",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et clarté",
                "creationDate": "2023-02-23T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:29.254Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "jean-luc.hilmoine@veolia.com",
            "CustomerContactEmail": "jean-luc.hilmoine@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HILMOINE",
            "CustomerContactFirstName": "Jean Luc",
            "CustomerContactMobilePhone": "0622265460",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "USINE EAU POTABLE AIRE (VEOLIA)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014779",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Reactivite et clarte",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6718043932b20900ec4",
        "extendedResponse": {
            "_id": "63f8b347108bcd5092d9d1c8",
            "submittedBody": {
                "id": "63f6f6718043932b20900ec40.0846158016220544",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponses pertinentes et adaptées à ma demande \nProfessionnalisme et accueil téléphonique des équipes Merci ",
                "creationDate": "2023-02-23T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dragache@frvnet.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9ec4a426-c3c8-4477-8a6b-b7d538d065c8",
            "responseId": "63f6f6718043932b20900ec4",
            "receivedResponse": {
                "id": "63f6f6718043932b20900ec40.0846158016220544",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponses pertinentes et adaptées à ma demande \nProfessionnalisme et accueil téléphonique des équipes Merci ",
                "creationDate": "2023-02-23T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:29.307Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "dragache@frvnet.com",
            "CustomerContactEmail": "dragache@frvnet.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RAGACHE",
            "CustomerContactFirstName": "Denis",
            "CustomerContactMobilePhone": "0777389194",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FRANCE ROBINETTERIE VALVES",
            "CustomerCompanyGroupName": "FRANCE ROBINETTERIE VALVES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014825",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponses pertinentes et adaptées à ma demande \nProfessionnalisme et accueil téléphonique des équipes Merci ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6718043932b20900ec9",
        "extendedResponse": {
            "_id": "63f8b347108bcd5092d9d1c9",
            "submittedBody": {
                "id": "63f6f6718043932b20900ec90.46537551371377317",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité pour avoir le devis",
                "creationDate": "2023-02-23T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.menegatti@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c57476aa-fd9f-4b1f-92ba-7712bffd3ba9",
            "responseId": "63f6f6718043932b20900ec9",
            "receivedResponse": {
                "id": "63f6f6718043932b20900ec90.46537551371377317",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité pour avoir le devis",
                "creationDate": "2023-02-23T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:29.327Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "emmanuel.menegatti@suez.com",
            "CustomerContactEmail": "emmanuel.menegatti@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MENEGATTI",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "+33670795385",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (Agence ANZIN)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223014837",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité pour avoir le devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f6f6718043932b20900f41",
        "extendedResponse": {
            "_id": "63f8b348108bcd5092d9d1ca",
            "submittedBody": {
                "id": "63f6f6718043932b20900f410.6036152802645256",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La proximité de l'atelier sur Toulouse et le relationnel avec votre technico-commerciale Emilie Rigal est un point positif.\n",
                "creationDate": "2023-02-23T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romain.didier@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a4e4cd8a-87d4-4aa2-978f-3ee6a14ed11e",
            "responseId": "63f6f6718043932b20900f41",
            "receivedResponse": {
                "id": "63f6f6718043932b20900f410.6036152802645256",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La proximité de l'atelier sur Toulouse et le relationnel avec votre technico-commerciale Emilie Rigal est un point positif.\n",
                "creationDate": "2023-02-23T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.pos",
                                    "label": "Reachability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "joignabilite.pos",
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "joignabilite.pos",
                    "label": "Reachability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-23T05:15:29.425Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "romain.didier@saur.com",
            "CustomerContactEmail": "romain.didier@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DIDIER",
            "CustomerContactFirstName": "ROMAIN",
            "CustomerContactMobilePhone": "0684504032",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR 82",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "RIGAL",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "223014896",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La proximité de l'atelier sur Toulouse et le relationnel avec votre technico-commerciale Emilie Rigal est un point positif.\n",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f847f3133ec71795a12f2b",
        "extendedResponse": {
            "_id": "63f8b348108bcd5092d9d1cb",
            "submittedBody": {
                "id": "63f847f3133ec71795a12f2b0.10511147570785373",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Votre commercial a parfaitement répondu à mes attentes.",
                "creationDate": "2023-02-24T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.piquenot@totalenergies.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "595ac168-8aec-49ad-aecd-6dcc7d168e96",
            "responseId": "63f847f3133ec71795a12f2b",
            "receivedResponse": {
                "id": "63f847f3133ec71795a12f2b0.10511147570785373",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Votre commercial a parfaitement répondu à mes attentes.",
                "creationDate": "2023-02-24T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T05:15:31.780Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "emmanuel.piquenot@totalenergies.com",
            "CustomerContactEmail": "emmanuel.piquenot@totalenergies.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PIQUENOT",
            "CustomerContactFirstName": "EMMANUEL",
            "CustomerContactMobilePhone": "0627997245",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TOTAL ENERGIES SE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "DE CARVALHO FERREIRA",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "WATELET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Votre commercial a parfaitement répondu à mes attentes.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f847f3e63a4039e7d54c77",
        "extendedResponse": {
            "_id": "63f8b348108bcd5092d9d1cc",
            "submittedBody": {
                "id": "63f847f3e63a4039e7d54c770.7511859238801055",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je n'ai pas de remarque particulière à vous faire,bien au contraire je suis très  satisfait de vos services.",
                "creationDate": "2023-02-24T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.delpouve@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2aacda69-fbb9-447d-a8f9-bb4feb30af55",
            "responseId": "63f847f3e63a4039e7d54c77",
            "receivedResponse": {
                "id": "63f847f3e63a4039e7d54c770.7511859238801055",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je n'ai pas de remarque particulière à vous faire,bien au contraire je suis très satisfait de vos services.",
                "creationDate": "2023-02-24T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-24T05:15:31.937Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "olivier.delpouve@veolia.com",
            "CustomerContactEmail": "olivier.delpouve@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELPOUVE",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "+33626915683",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE BRUAY)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015200",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je n'ai pas de remarque particulière à vous faire,bien au contraire je suis très  satisfait de vos services.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f847f4e63a4039e7d54d82",
        "extendedResponse": {
            "_id": "63f8b349108bcd5092d9d1cd",
            "submittedBody": {
                "id": "63f847f4e63a4039e7d54d820.228498682779245",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2023-02-24T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "g.delia@ppi-pompes.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d93baf10-2b9a-4585-9076-b54275bbf7f1",
            "responseId": "63f847f4e63a4039e7d54d82",
            "receivedResponse": {
                "id": "63f847f4e63a4039e7d54d820.228498682779245",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2023-02-24T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T05:15:32.077Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "g.delia@ppi-pompes.fr",
            "CustomerContactEmail": "g.delia@ppi-pompes.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELIA",
            "CustomerContactFirstName": "Giovanni",
            "CustomerContactMobilePhone": "0672206391",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PPI",
            "CustomerCompanyGroupName": "POMPES ET PROCESS INDUSTRIE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223014984",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f847f4e63a4039e7d54e49",
        "extendedResponse": {
            "_id": "63f8b349108bcd5092d9d1ce",
            "submittedBody": {
                "id": "63f847f4e63a4039e7d54e490.5139297813361781",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui a plu : réponse rapide, devis clair (toutes les infos + fiche technique avec courbes de performances facilement lisibles + dessin 2D)",
                "creationDate": "2023-02-24T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "romaingueguen@pignat.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a850ed84-5401-4326-860b-e0302aff3952",
            "responseId": "63f847f4e63a4039e7d54e49",
            "receivedResponse": {
                "id": "63f847f4e63a4039e7d54e490.5139297813361781",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui a plu : réponse rapide, devis clair (toutes les informations + fiche technique avec courbes de performances facilement lisibles + dessin 2D)",
                "creationDate": "2023-02-24T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T05:15:32.241Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "romaingueguen@pignat.com",
            "CustomerContactEmail": "romaingueguen@pignat.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUEGUEN",
            "CustomerContactFirstName": "ROMAIN",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PIGNAT",
            "CustomerCompanyGroupName": "PIGNAT",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Jorge",
            "XylemContactedPersonName": "DE OLIVEIRA",
            "XylemContactedPersonRole": "Ingénieur produits solutions",
            "CustomerReference": "",
            "XylemReference": "223015075",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui a plu : réponse rapide, devis clair (toutes les infos + fiche technique avec courbes de performances facilement lisibles + dessin 2D)",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Il faut toujours laisser une place à l'amélioration"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f847f4e63a4039e7d54fe0",
        "extendedResponse": {
            "_id": "63f8b349108bcd5092d9d1cf",
            "submittedBody": {
                "id": "63f847f4e63a4039e7d54fe00.2166614004404932",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commercial disponible, réactif",
                "creationDate": "2023-02-24T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.detet@snef.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6a035c91-7e55-4549-a106-9d71f50d6e7a",
            "responseId": "63f847f4e63a4039e7d54fe0",
            "receivedResponse": {
                "id": "63f847f4e63a4039e7d54fe00.2166614004404932",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commercial disponible, réactif",
                "creationDate": "2023-02-24T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T05:15:32.502Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "frederic.detet@snef.fr",
            "CustomerContactEmail": "frederic.detet@snef.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DETET",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0688066723",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SNEF",
            "CustomerCompanyGroupName": "SNEF MMS RHONE ALPES AUVERGNE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Franck",
            "XylemContactedPersonName": "PENVERN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223015189",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commercial disponible, réactif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f847f4e63a4039e7d55079",
        "extendedResponse": {
            "_id": "63f8b34a108bcd5092d9d1d0",
            "submittedBody": {
                "id": "63f847f4e63a4039e7d550790.6755505912428932",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité efficacité ",
                "creationDate": "2023-02-24T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.couty@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "be4b59b2-f696-4bbd-bf3e-641b5336da90",
            "responseId": "63f847f4e63a4039e7d55079",
            "receivedResponse": {
                "id": "63f847f4e63a4039e7d550790.6755505912428932",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité efficacité ",
                "creationDate": "2023-02-24T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T05:15:32.572Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "frederic.couty@suez.com",
            "CustomerContactEmail": "frederic.couty@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COUTY",
            "CustomerContactFirstName": "FREDERIC",
            "CustomerContactMobilePhone": "0688275517",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ 81",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015258",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité efficacité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f847f4e63a4039e7d5507b",
        "extendedResponse": {
            "_id": "63f8b34a108bcd5092d9d1d1",
            "submittedBody": {
                "id": "63f847f4e63a4039e7d5507b0.7865807203832729",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour, j'ai reçu un mail de réponse à ma demande de devis, mais sans la pièce jointe",
                "creationDate": "2023-02-24T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-louis.euvrard@saur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "a4a449dd-921e-4b14-9f29-f227a8f25a0f",
            "responseId": "63f847f4e63a4039e7d5507b",
            "receivedResponse": {
                "id": "63f847f4e63a4039e7d5507b0.7865807203832729",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour, j'ai reçu un mail de réponse à ma demande de devis, mais sans la pièce jointe",
                "creationDate": "2023-02-24T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-02-24T05:15:32.576Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "jean-louis.euvrard@saur.fr",
            "CustomerContactEmail": "jean-louis.euvrard@saur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "EUVRARD",
            "CustomerContactFirstName": "JEAN LOUIS",
            "CustomerContactMobilePhone": "0670310042",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015240",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour, j'ai reçu un mail de réponse à ma demande de devis, mais sans la pièce jointe",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f8ccaae63a4039e7161c83",
        "extendedResponse": {
            "_id": "6405aa7636e8ad318e69a4e0",
            "submittedBody": {
                "id": "63f8ccaae63a4039e7161c830.8469649962794525",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDE EFFICACE",
                "creationDate": "2023-02-24T15:41:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vjulien@vareo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b4f4a803-30b9-4f42-a436-f2b271dddfc5",
            "responseId": "63f8ccaae63a4039e7161c83",
            "receivedResponse": {
                "id": "63f8ccaae63a4039e7161c830.8469649962794525",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAPIDE EFFICACE",
                "creationDate": "2023-02-24T15:41:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T14:41:46.042Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "vjulien@vareo.fr",
            "CustomerContactEmail": "vjulien@vareo.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "JULIEN",
            "CustomerContactFirstName": "Valérie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VAREO",
            "CustomerCompanyGroupName": "VAREO",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF9932",
            "XylemReference": "767701",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDE EFFICACE",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f8ccaae63a4039e7161c9e",
        "extendedResponse": {
            "_id": "63fcddd1506f9662e2c7f579",
            "submittedBody": {
                "id": "63f8ccaae63a4039e7161c9e0.07867983096933195",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "C'est parfait.",
                "creationDate": "2023-02-24T15:41:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.volkaert@luvica.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "30decb65-bae6-49f6-8aee-bf0b970a3d73",
            "responseId": "63f8ccaae63a4039e7161c9e",
            "receivedResponse": {
                "id": "63f8ccaae63a4039e7161c9e0.07867983096933195",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "C'est parfait.",
                "creationDate": "2023-02-24T15:41:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T14:41:46.094Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "david.volkaert@luvica.fr",
            "CustomerContactEmail": "david.volkaert@luvica.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VOLKAERT",
            "CustomerContactFirstName": "DAVID",
            "CustomerContactMobilePhone": "0608106550",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEEM  DISTRIBUTION",
            "CustomerCompanyGroupName": "Groupe LUVICA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AA230196",
            "XylemReference": "770561",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "C'est parfait.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f8ccaae63a4039e7161cee",
        "extendedResponse": {
            "_id": "63fcddd1506f9662e2c7f57a",
            "submittedBody": {
                "id": "63f8ccaae63a4039e7161cee0.9265606696178008",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le délai respecté et la réactivité de vos services du devis à la commande.",
                "creationDate": "2023-02-24T15:41:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jaguin.asso@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "65c54226-2cb3-47c6-904d-55e4eac01c71",
            "responseId": "63f8ccaae63a4039e7161cee",
            "receivedResponse": {
                "id": "63f8ccaae63a4039e7161cee0.9265606696178008",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le délai respecté et la réactivité de vos services du devis à la commande.",
                "creationDate": "2023-02-24T15:41:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T14:41:46.260Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "jaguin.asso@gmail.com",
            "CustomerContactEmail": "jaguin.asso@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JAGUIN",
            "CustomerContactFirstName": "Gérard",
            "CustomerContactMobilePhone": "0661201803",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ASS'O",
            "CustomerCompanyGroupName": "SAS ASS'O",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "220223 XYLEM",
            "XylemReference": "770614",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le délai respecté et la réactivité de vos services du devis à la commande.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f8ccaae63a4039e7161d42",
        "extendedResponse": {
            "_id": "63fcddd1506f9662e2c7f57b",
            "submittedBody": {
                "id": "63f8ccaae63a4039e7161d420.4491539653044281",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Personnes réactives.\nLes échanges par mail ou tél aboutissent à des résultats",
                "creationDate": "2023-02-24T15:41:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mchristiaens@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "c6ac6590-2d77-4bea-b924-63a0ba6feba0",
            "responseId": "63f8ccaae63a4039e7161d42",
            "receivedResponse": {
                "id": "63f8ccaae63a4039e7161d420.4491539653044281",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Personnes réactives.\nLes échanges par mail ou tél aboutissent à des résultats",
                "creationDate": "2023-02-24T15:41:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-24T14:41:46.407Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "mchristiaens@lri-sodime.com",
            "CustomerContactEmail": "mchristiaens@lri-sodime.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "CHRISTIAENS",
            "CustomerContactFirstName": "Muriel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LRI - LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "504 330",
            "XylemReference": "770383",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Personnes réactives.\nLes échanges par mail ou tél aboutissent à des résultats",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f8ccaae63a4039e7161d4e",
        "extendedResponse": {
            "_id": "63fcddd2506f9662e2c7f57c",
            "submittedBody": {
                "id": "63f8ccaae63a4039e7161d4e0.13294860615941606",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "une confirmation commande rapide et détaillée",
                "creationDate": "2023-02-24T15:41:46+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pcry@viessmann.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1c9287d9-3995-4adc-91be-09118ceee67b",
            "responseId": "63f8ccaae63a4039e7161d4e",
            "receivedResponse": {
                "id": "63f8ccaae63a4039e7161d4e0.13294860615941606",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "une confirmation commande rapide et détaillée",
                "creationDate": "2023-02-24T15:41:46Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T14:41:46.431Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "pcry@viessmann.com",
            "CustomerContactEmail": "pcry@viessmann.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "POCHARD",
            "CustomerContactFirstName": "yulia",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VIESSMANN",
            "CustomerCompanyGroupName": "VIESSMANN FRANCE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "651/4507629289",
            "XylemReference": "770124",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "une confirmation commande rapide et détaillée",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f8ce30133ec71795ec434e",
        "extendedResponse": {
            "_id": "63fcddd2506f9662e2c7f57d",
            "submittedBody": {
                "id": "63f8ce30133ec71795ec434e0.09808901414975346",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de la demande de prix à la livraison ",
                "creationDate": "2023-02-24T15:48:16+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrice-jammes@ccl.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "671c7fde-3e6d-48d6-8bda-3dddd407eded",
            "responseId": "63f8ce30133ec71795ec434e",
            "receivedResponse": {
                "id": "63f8ce30133ec71795ec434e0.09808901414975346",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de la demande de prix à la livraison ",
                "creationDate": "2023-02-24T15:48:16Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T14:48:16.178Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "patrice-jammes@ccl.fr",
            "CustomerContactEmail": "patrice-jammes@ccl.fr",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CCL",
            "CustomerCompanyGroupName": "C.C.L.",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "06 - 9230206641",
            "XylemReference": "770388",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de la demande de prix à la livraison ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f8ce30133ec71795ec43f3",
        "extendedResponse": {
            "_id": "63fcddd3506f9662e2c7f57e",
            "submittedBody": {
                "id": "63f8ce30133ec71795ec43f30.9783529943706375",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité",
                "creationDate": "2023-02-24T15:48:16+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nsore@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "968679b4-8b01-4d41-82f9-563dbe57699d",
            "responseId": "63f8ce30133ec71795ec43f3",
            "receivedResponse": {
                "id": "63f8ce30133ec71795ec43f30.9783529943706375",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité",
                "creationDate": "2023-02-24T15:48:16Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date "
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-24T14:48:16.335Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "nsore@hydralians.com",
            "CustomerContactEmail": "nsore@hydralians.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "SORE",
            "CustomerContactFirstName": "Nathalie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRALIANS",
            "CustomerCompanyGroupName": "PROSJET IRRIGARONNE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "RE A 152 67127",
            "XylemReference": "770550",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f999724646c8f1a9fb5a2a",
        "extendedResponse": {
            "_id": "63fcddd3506f9662e2c7f57f",
            "submittedBody": {
                "id": "63f999724646c8f1a9fb5a2a0.02132545696246746",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Pas vu",
                "creationDate": "2023-02-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cduchamp@alpespompeschauffage.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "7da2e331-7372-4fa5-bb1e-ca49a76132ff",
            "responseId": "63f999724646c8f1a9fb5a2a",
            "receivedResponse": {
                "id": "63f999724646c8f1a9fb5a2a0.02132545696246746",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Pas vu",
                "creationDate": "2023-02-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-25T05:15:30.178Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "cduchamp@alpespompeschauffage.fr",
            "CustomerContactEmail": "cduchamp@alpespompeschauffage.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DUCHAMP",
            "CustomerContactFirstName": "Caroline",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ALPES POMPES CHAUFFAGE",
            "CustomerCompanyGroupName": "ALPES POMPES CHAUFFAGE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Christian",
            "XylemContactedPersonName": "DODDI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Pas vu",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f999724646c8f1a9fb5a4d",
        "extendedResponse": {
            "_id": "63fcddd3506f9662e2c7f580",
            "submittedBody": {
                "id": "63f999724646c8f1a9fb5a4d0.8898724451684354",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien a redire pour le moment je suis satisfait des réponses et de la réactivité de Mr moreau",
                "creationDate": "2023-02-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "damien.bazin@etsmeunier.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "5ce71dec-fc6b-4e1c-b6c4-e4635a717d09",
            "responseId": "63f999724646c8f1a9fb5a4d",
            "receivedResponse": {
                "id": "63f999724646c8f1a9fb5a4d0.8898724451684354",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien a redire pour le moment je suis satisfait des réponses et de la réactivité de Mr moreau",
                "creationDate": "2023-02-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-02-25T05:15:30.204Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "damien.bazin@etsmeunier.fr",
            "CustomerContactEmail": "damien.bazin@etsmeunier.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BAZIN",
            "CustomerContactFirstName": "Damien",
            "CustomerContactMobilePhone": "0680739740",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MEUNIER",
            "CustomerCompanyGroupName": "ETS MEUNIER SA",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Michael",
            "XylemContactedPersonName": "MOREAU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien a redire pour le moment je suis satisfait des réponses et de la réactivité de Mr moreau",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Quelque réponses tardive du a mon impatience"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f999724646c8f1a9fb5a86",
        "extendedResponse": {
            "_id": "63fcddd4506f9662e2c7f581",
            "submittedBody": {
                "id": "63f999724646c8f1a9fb5a860.014286386349435132",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité des échanges par mail.",
                "creationDate": "2023-02-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ajaguin.asso@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "52d783af-08e3-4320-93aa-530da3362ad6",
            "responseId": "63f999724646c8f1a9fb5a86",
            "receivedResponse": {
                "id": "63f999724646c8f1a9fb5a860.014286386349435132",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité des échanges par mail.",
                "creationDate": "2023-02-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-25T05:15:30.261Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "ajaguin.asso@gmail.com",
            "CustomerContactEmail": "ajaguin.asso@gmail.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "JAGUIN",
            "CustomerContactFirstName": "Agnès",
            "CustomerContactMobilePhone": "0603513936",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ASS'O",
            "CustomerCompanyGroupName": "SAS ASS'O",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Mehmet Akif",
            "XylemContactedPersonName": "TOKER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité des échanges par mail.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f999724646c8f1a9fb5a8b",
        "extendedResponse": {
            "_id": "63fcddd4506f9662e2c7f582",
            "submittedBody": {
                "id": "63f999724646c8f1a9fb5a8b0.03581987754641647",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Echanges conviviaux ",
                "creationDate": "2023-02-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jaguin.asso@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ff9a54f6-7f70-4111-b19f-752ecac63fef",
            "responseId": "63f999724646c8f1a9fb5a8b",
            "receivedResponse": {
                "id": "63f999724646c8f1a9fb5a8b0.03581987754641647",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Echanges conviviaux ",
                "creationDate": "2023-02-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-25T05:15:30.266Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "jaguin.asso@gmail.com",
            "CustomerContactEmail": "jaguin.asso@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JAGUIN",
            "CustomerContactFirstName": "Gérard",
            "CustomerContactMobilePhone": "0661201803",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ASS'O",
            "CustomerCompanyGroupName": "SAS ASS'O",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Mehmet Akif",
            "XylemContactedPersonName": "TOKER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Echanges conviviaux ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f999724646c8f1a9fb5a93",
        "extendedResponse": {
            "_id": "63fcddd4506f9662e2c7f583",
            "submittedBody": {
                "id": "63f999724646c8f1a9fb5a930.6992787986543061",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\n\nle professionnalisme et le sens de l'écoute sont un vrai plus.",
                "creationDate": "2023-02-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.ginja@ciec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "89527c5d-1c6f-482f-8f60-afaf185220aa",
            "responseId": "63f999724646c8f1a9fb5a93",
            "receivedResponse": {
                "id": "63f999724646c8f1a9fb5a930.6992787986543061",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\n\nle professionnalisme et le sens de l'écoute sont un vrai plus.",
                "creationDate": "2023-02-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-25T05:15:30.274Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "sebastien.ginja@ciec.fr",
            "CustomerContactEmail": "sebastien.ginja@ciec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GINJA",
            "CustomerContactFirstName": "SEBASTIEN",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CIEC",
            "CustomerCompanyGroupName": "COFELY (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Sarah",
            "XylemContactedPersonName": "NERON",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\n\nle professionnalisme et le sens de l'écoute sont un vrai plus.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f999724646c8f1a9fb5ab6",
        "extendedResponse": {
            "_id": "63fcddd5506f9662e2c7f584",
            "submittedBody": {
                "id": "63f999724646c8f1a9fb5ab60.7318515784326081",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Clement est très compétent et professionnel.",
                "creationDate": "2023-02-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bernard.dejoybert@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1765203c-2f4b-4a85-b975-3913a473e2d5",
            "responseId": "63f999724646c8f1a9fb5ab6",
            "receivedResponse": {
                "id": "63f999724646c8f1a9fb5ab60.7318515784326081",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Clement est très compétent et professionnel.",
                "creationDate": "2023-02-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-25T05:15:30.305Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-23_06.15.08",
        "integration": {
            "ContactKey": "bernard.dejoybert@eiffage.com",
            "CustomerContactEmail": "bernard.dejoybert@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DE JOYBERT",
            "CustomerContactFirstName": "Bernard",
            "CustomerContactMobilePhone": "0698647575",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIFFAGE ENERGIE  TH MAINTENANCE",
            "CustomerCompanyGroupName": "EIFFAGE GENIE CLIMATIQUE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Clement",
            "XylemContactedPersonName": "FAIVRE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Clement est très compétent et professionnel.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63f999744646c8f1a9fb7429",
        "extendedResponse": {
            "_id": "63fcddd5506f9662e2c7f585",
            "submittedBody": {
                "id": "63f999744646c8f1a9fb74290.1041525690542735",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "retour rapide de la sélection de pompe par notre commercial",
                "creationDate": "2023-02-25T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.morvant@eimi.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cb14bd96-18e0-49a7-bb26-92e108b0b4d0",
            "responseId": "63f999744646c8f1a9fb7429",
            "receivedResponse": {
                "id": "63f999744646c8f1a9fb74290.1041525690542735",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "retour rapide de la sélection de pompe par notre commercial",
                "creationDate": "2023-02-25T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-25T05:15:32.022Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-25_06.15.12",
        "integration": {
            "ContactKey": "kevin.morvant@eimi.fr",
            "CustomerContactEmail": "kevin.morvant@eimi.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORVANT",
            "CustomerContactFirstName": "Kevin",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIMI",
            "CustomerCompanyGroupName": "EIMI HOTEL D ENTREPRISE N°7",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223015294",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "retour rapide de la sélection de pompe par notre commercial",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df3255cbcabbc51a2e5",
        "extendedResponse": {
            "_id": "6405aa7636e8ad318e69a4e1",
            "submittedBody": {
                "id": "63fd8df3255cbcabbc51a2e50.44654783753276805",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "C'est bien de nous informer des dates de report de livraison, mais quand c'est pour un produit censé être en stock, c'est beaucoup moins bien. La commande initiale date du mois de septembre 2022. Avec le commercial, on a trouvé une solution de remplacement et, ça recommence avec des reports de date de livraison successifs. Résultat, j'ai annulé la commande pour commander chez un concurrent une pompe équivalente et stockée.",
                "creationDate": "2023-02-28T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pcharpentier@prolians.eu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "8a644de5-bbbb-444a-9c4b-50f6117dc1bd",
            "responseId": "63fd8df3255cbcabbc51a2e5",
            "receivedResponse": {
                "id": "63fd8df3255cbcabbc51a2e50.44654783753276805",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "C'est bien de nous informer des dates de report de livraison, mais quand c'est pour un produit censé être en stock, c'est beaucoup moins bien. La commande initiale date du mois de septembre 2022. Avec le commercial, on a trouvé une solution de remplacement et, ça recommence avec des reports de date de livraison successifs. Résultat, j'ai annulé la commande pour commander chez un concurrent une pompe équivalente et stockée.",
                "creationDate": "2023-02-28T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "delai.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:31.309Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-02-24_06.15.09",
        "integration": {
            "ContactKey": "pcharpentier@prolians.eu",
            "CustomerContactEmail": "pcharpentier@prolians.eu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHARPENTIER",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0632555164",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CMB PROLIANS",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "PR D CSP 43488",
            "XylemReference": "746249",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "C'est bien de nous informer des dates de report de livraison, mais quand c'est pour un produit censé être en stock, c'est beaucoup moins bien. La commande initiale date du mois de septembre 2022. Avec le commercial, on a trouvé une solution de remplacement et, ça recommence avec des reports de date de livraison successifs. Résultat, j'ai annulé la commande pour commander chez un concurrent une pompe équivalente et stockée.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df37b3a242b0e7e99a9",
        "extendedResponse": {
            "_id": "6405aa7636e8ad318e69a4e2",
            "submittedBody": {
                "id": "63fd8df37b3a242b0e7e99a90.8243532746760316",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Je n’ai que des bons retours d’Emilie Noirot, super réactive quand je l’appelle elle me répond. Il essaye du mieux qu’elle peut pour me trouver la solution et elle va toujours avec moi jusqu’au fond du problème. Je vous remercie. Je vous souhaite une bonne soirée.",
                "creationDate": "2023-02-28T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fernando-vaz@delacommune-dumont.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "4a504458-8cf4-4d2a-aac2-61e9e3ec776d",
            "responseId": "63fd8df37b3a242b0e7e99a9",
            "receivedResponse": {
                "id": "63fd8df37b3a242b0e7e99a90.8243532746760316",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Je n ai que des bons retours d Emilie Noirot, super réactive quand je l appelle elle me répond. Il essaye du mieux qu elle peut pour me trouver la solution et elle va toujours avec moi jusqu au fond du problème. Je vous remercie. Je vous souhaite une bonne soirée.",
                "creationDate": "2023-02-28T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:31.375Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "fernando-vaz@delacommune-dumont.fr",
            "CustomerContactEmail": "fernando-vaz@delacommune-dumont.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DE VAZ",
            "CustomerContactFirstName": "Fernando",
            "CustomerContactMobilePhone": "0629461949",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DELACOMMUNE ET DUMONT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "NOIROT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Je n’ai que des bons retours d’Emilie Noirot, super réactive quand je l’appelle elle me répond. Il essaye du mieux qu’elle peut pour me trouver la solution et elle va toujours avec moi jusqu’au fond du problème. Je vous remercie. Je vous souhaite une bonne soirée.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Pas grand-chose Avec Emilie Noirot ça se passe toujours bien jusqu’à maintenant"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df37b3a242b0e7e99b7",
        "extendedResponse": {
            "_id": "6405aa7736e8ad318e69a4e3",
            "submittedBody": {
                "id": "63fd8df37b3a242b0e7e99b70.7631811360036178",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Retrouver un ancien collègue. \nfuture mise en place d'un plan partenaire. ",
                "creationDate": "2023-02-28T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "cgillant1@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "7d82b12c-551e-4b3e-ac4e-0becd5f6b55a",
            "responseId": "63fd8df37b3a242b0e7e99b7",
            "receivedResponse": {
                "id": "63fd8df37b3a242b0e7e99b70.7631811360036178",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Retrouver un ancien collègue. \nfuture mise en place d'un plan partenaire. ",
                "creationDate": "2023-02-28T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-02-28T05:15:31.412Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "cgillant1@gressier.net",
            "CustomerContactEmail": "cgillant1@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GILLANT",
            "CustomerContactFirstName": "Cyprien",
            "CustomerContactMobilePhone": "0613691301",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER / FILS",
            "CustomerCompanyGroupName": "ETS GRESSIER & FILS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Claude",
            "XylemContactedPersonName": "GUILLERMET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Retrouver un ancien collègue. \nfuture mise en place d'un plan partenaire. ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df37b3a242b0e7e99bc",
        "extendedResponse": {
            "_id": "6405aa7736e8ad318e69a4e4",
            "submittedBody": {
                "id": "63fd8df37b3a242b0e7e99bc0.2039164474205135",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bon relationnel avec M.BOUZIGON. Il se tient au courant de nos projets en amont de leur exécution. \nDe bons conseils techniques ! ",
                "creationDate": "2023-02-28T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "d.fernandes@etchart-energies.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1eda1b37-fb8b-431b-a7fb-7f74c5c334f7",
            "responseId": "63fd8df37b3a242b0e7e99bc",
            "receivedResponse": {
                "id": "63fd8df37b3a242b0e7e99bc0.2039164474205135",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bon relationnel avec M.BOUZIGON. Il se tient au courant de nos projets en amont de leur exécution. \nDe bons conseils techniques ! ",
                "creationDate": "2023-02-28T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:31.425Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "d.fernandes@etchart-energies.fr",
            "CustomerContactEmail": "d.fernandes@etchart-energies.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FERNANDES",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "0624684088",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ETCHART ENERGIES",
            "CustomerCompanyGroupName": "ETCHART ENERGIES",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "BOUZIGON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bon relationnel avec M.BOUZIGON. Il se tient au courant de nos projets en amont de leur exécution. \nDe bons conseils techniques ! ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df4255cbcabbc51b175",
        "extendedResponse": {
            "_id": "6405aa7736e8ad318e69a4e5",
            "submittedBody": {
                "id": "63fd8df4255cbcabbc51b1750.81638278419636",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et qualité du matériel.",
                "creationDate": "2023-02-28T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vvantrimpont@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e0b51406-f5a2-41e2-a4ef-1f5d3afa6041",
            "responseId": "63fd8df4255cbcabbc51b175",
            "receivedResponse": {
                "id": "63fd8df4255cbcabbc51b1750.81638278419636",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et qualité du matériel.",
                "creationDate": "2023-02-28T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:32.560Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "vvantrimpont@gressier.net",
            "CustomerContactEmail": "vvantrimpont@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANTRIMPONT",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0626329823",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER ET FILS",
            "CustomerCompanyGroupName": "ETS GRESSIER ET FILS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Mickael",
            "XylemContactedPersonName": "AUBRY",
            "XylemContactedPersonRole": "TCS Location",
            "CustomerReference": "",
            "XylemReference": "766667",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et qualité du matériel.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df4e63a4039e7f1622f",
        "extendedResponse": {
            "_id": "6405aa7736e8ad318e69a4e6",
            "submittedBody": {
                "id": "63fd8df4e63a4039e7f1622f0.5133297926009746",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité",
                "creationDate": "2023-02-28T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "f.haubert@tdm-pompes.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "169ecc6f-6d86-4df0-85bf-f5d2c557d249",
            "responseId": "63fd8df4e63a4039e7f1622f",
            "receivedResponse": {
                "id": "63fd8df4e63a4039e7f1622f0.5133297926009746",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité",
                "creationDate": "2023-02-28T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:32.668Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "f.haubert@tdm-pompes.com",
            "CustomerContactEmail": "f.haubert@tdm-pompes.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HAUBERT",
            "CustomerContactFirstName": "Franck",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TDM",
            "CustomerCompanyGroupName": "T.D.M.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015768",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df4e63a4039e7f16315",
        "extendedResponse": {
            "_id": "6405aa7836e8ad318e69a4e7",
            "submittedBody": {
                "id": "63fd8df4e63a4039e7f163150.1951013069590577",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité / conseils ont été parfait ",
                "creationDate": "2023-02-28T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "n.bocquet@tdm-pompes.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "74bc1dea-dff0-482c-8bb5-bf0987e118a0",
            "responseId": "63fd8df4e63a4039e7f16315",
            "receivedResponse": {
                "id": "63fd8df4e63a4039e7f163150.1951013069590577",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité / conseils ont été parfait ",
                "creationDate": "2023-02-28T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:32.798Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "n.bocquet@tdm-pompes.com",
            "CustomerContactEmail": "n.bocquet@tdm-pompes.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOCQUET",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0610907485",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TDM",
            "CustomerCompanyGroupName": "T.D.M.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015973",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité / conseils ont été parfait ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df4e63a4039e7f1633f",
        "extendedResponse": {
            "_id": "6405aa7836e8ad318e69a4e8",
            "submittedBody": {
                "id": "63fd8df4e63a4039e7f1633f0.25208854026864924",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Besoin devis en urgence, réponse reçue sous 24h.\nOffre complète et détaillée y compris délais avec fiches techniques.",
                "creationDate": "2023-02-28T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.fortier@mrpompes.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "92a92eb5-9b51-4569-87ba-90edcc32a3b8",
            "responseId": "63fd8df4e63a4039e7f1633f",
            "receivedResponse": {
                "id": "63fd8df4e63a4039e7f1633f0.25208854026864924",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Besoin devis en urgence, réponse reçue sous 24h.\nOffre complète et détaillée y compris délais avec fiches techniques.",
                "creationDate": "2023-02-28T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "choix.pos",
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-02-28T05:15:32.882Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "thomas.fortier@mrpompes.com",
            "CustomerContactEmail": "thomas.fortier@mrpompes.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FORTIER",
            "CustomerContactFirstName": "THOMAS",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MR POMPES",
            "CustomerCompanyGroupName": "MR POMPES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Antoine",
            "XylemContactedPersonName": "BRULFER",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015645",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Besoin devis en urgence, réponse reçue sous 24h.\nOffre complète et détaillée y compris délais avec fiches techniques.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df4e63a4039e7f163a4",
        "extendedResponse": {
            "_id": "6405aa7836e8ad318e69a4e9",
            "submittedBody": {
                "id": "63fd8df4e63a4039e7f163a40.48530853413081987",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse",
                "creationDate": "2023-02-28T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "b.moreau@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "64b3342c-65c6-47eb-b575-32c38926ed60",
            "responseId": "63fd8df4e63a4039e7f163a4",
            "receivedResponse": {
                "id": "63fd8df4e63a4039e7f163a40.48530853413081987",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse",
                "creationDate": "2023-02-28T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:32.979Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "b.moreau@suez.com",
            "CustomerContactEmail": "b.moreau@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MOREAU",
            "CustomerContactFirstName": "Benoit",
            "CustomerContactMobilePhone": "0631943468",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Laurence",
            "XylemContactedPersonName": "GUILLEMINE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015710",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df5e63a4039e7f163d6",
        "extendedResponse": {
            "_id": "6405aa7836e8ad318e69a4ea",
            "submittedBody": {
                "id": "63fd8df5e63a4039e7f163d60.9613036860865816",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité et efficacité",
                "creationDate": "2023-02-28T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pro.eurobobinage@orange.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "28fe4207-3e12-432b-91f2-7396acdbed07",
            "responseId": "63fd8df5e63a4039e7f163d6",
            "receivedResponse": {
                "id": "63fd8df5e63a4039e7f163d60.9613036860865816",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité et efficacité",
                "creationDate": "2023-02-28T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:33.089Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "pro.eurobobinage@orange.fr",
            "CustomerContactEmail": "pro.eurobobinage@orange.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ORSAT",
            "CustomerContactFirstName": "William",
            "CustomerContactMobilePhone": "0630529419",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EUROBOBINAGE",
            "CustomerCompanyGroupName": "EUROBOBINAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223015775",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité et efficacité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df5e63a4039e7f16438",
        "extendedResponse": {
            "_id": "6405aa7936e8ad318e69a4eb",
            "submittedBody": {
                "id": "63fd8df5e63a4039e7f164380.11298293935925252",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité +++\nInterlocuteur identifié qui se déplace chez ses clients et partenaires +++",
                "creationDate": "2023-02-28T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jonathan.perrin@grandbourg.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4af380af-ce89-4286-95b2-4eca642b8022",
            "responseId": "63fd8df5e63a4039e7f16438",
            "receivedResponse": {
                "id": "63fd8df5e63a4039e7f164380.11298293935925252",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité +++\nInterlocuteur identifié qui se déplace chez ses clients et partenaires +++",
                "creationDate": "2023-02-28T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:33.157Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "jonathan.perrin@grandbourg.fr",
            "CustomerContactEmail": "jonathan.perrin@grandbourg.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PERRIN",
            "CustomerContactFirstName": "Jonathan",
            "CustomerContactMobilePhone": "0617114743",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CA3B",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223015702",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité +++\nInterlocuteur identifié qui se déplace chez ses clients et partenaires +++",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df5e63a4039e7f1644c",
        "extendedResponse": {
            "_id": "6405aa7936e8ad318e69a4ec",
            "submittedBody": {
                "id": "63fd8df5e63a4039e7f1644c0.6666288675401777",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Conforme à nos demandes !",
                "creationDate": "2023-02-28T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.lache@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e59acb0a-9bbc-40a0-948e-6ed564883131",
            "responseId": "63fd8df5e63a4039e7f1644c",
            "receivedResponse": {
                "id": "63fd8df5e63a4039e7f1644c0.6666288675401777",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Conforme à nos demandes !",
                "creationDate": "2023-02-28T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:33.197Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "sebastien.lache@dalkia.fr",
            "CustomerContactEmail": "sebastien.lache@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACHE",
            "CustomerContactFirstName": "SEBASTIEN",
            "CustomerContactMobilePhone": "+33614712004",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA France",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015081",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Conforme à nos demandes !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df5e63a4039e7f16455",
        "extendedResponse": {
            "_id": "6405aa7936e8ad318e69a4ed",
            "submittedBody": {
                "id": "63fd8df5e63a4039e7f164550.22302395820589793",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité",
                "creationDate": "2023-02-28T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c.thomas@cub-architecture.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cc59c254-9bdb-47eb-b28c-14a7750bbf04",
            "responseId": "63fd8df5e63a4039e7f16455",
            "receivedResponse": {
                "id": "63fd8df5e63a4039e7f164550.22302395820589793",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité",
                "creationDate": "2023-02-28T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:33.217Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "c.thomas@cub-architecture.fr",
            "CustomerContactEmail": "c.thomas@cub-architecture.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Claire",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CUB ARCHITECTURE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Thierry",
            "XylemContactedPersonName": "BLONDET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223015859",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fd8df5e63a4039e7f165a3",
        "extendedResponse": {
            "_id": "6405aa7a36e8ad318e69a4ee",
            "submittedBody": {
                "id": "63fd8df5e63a4039e7f165a30.3421588857451763",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse et la qualité des explications.",
                "creationDate": "2023-02-28T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "renaldo.gianesini@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1bca227e-e185-4ee5-8105-682020dbb999",
            "responseId": "63fd8df5e63a4039e7f165a3",
            "receivedResponse": {
                "id": "63fd8df5e63a4039e7f165a30.3421588857451763",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse et la qualité des explications.",
                "creationDate": "2023-02-28T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-02-28T05:15:33.560Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-02-28_06.15.09",
        "integration": {
            "ContactKey": "renaldo.gianesini@dalkia.fr",
            "CustomerContactEmail": "renaldo.gianesini@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GIANESINI",
            "CustomerContactFirstName": "Rénaldo",
            "CustomerContactMobilePhone": "0611458363",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA EST",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "EHRENFELD",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "EHRENFELD",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "223016029",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse et la qualité des explications.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf767b3a242b0ee90fa8",
        "extendedResponse": {
            "_id": "6405aa7a36e8ad318e69a4ef",
            "submittedBody": {
                "id": "63fedf767b3a242b0ee90fa80.698624236666763",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": " Réactivité - clarté du devis - offre trés bien documentée",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "michel.frerejean@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "863ba951-5bd8-465e-b5de-a076364771bf",
            "responseId": "63fedf767b3a242b0ee90fa8",
            "receivedResponse": {
                "id": "63fedf767b3a242b0ee90fa80.698624236666763",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": " Réactivité - clarté du devis - offre très bien documentée",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.551Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "michel.frerejean@veolia.com",
            "CustomerContactEmail": "michel.frerejean@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FREREJEAN",
            "CustomerContactFirstName": "Michel",
            "CustomerContactMobilePhone": "0646647321",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016227",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": " Réactivité - clarté du devis - offre trés bien documentée",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf767b3a242b0ee90fd6",
        "extendedResponse": {
            "_id": "6405aa7a36e8ad318e69a4f0",
            "submittedBody": {
                "id": "63fedf767b3a242b0ee90fd60.6084830158129666",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide et précise ",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "maxime.langlois@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2574818f-feb6-4ed1-a05d-a6cb2c3abc56",
            "responseId": "63fedf767b3a242b0ee90fd6",
            "receivedResponse": {
                "id": "63fedf767b3a242b0ee90fd60.6084830158129666",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide et précise ",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.601Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "maxime.langlois@veolia.com",
            "CustomerContactEmail": "maxime.langlois@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LANGLOIS",
            "CustomerContactFirstName": "Maxime",
            "CustomerContactMobilePhone": "0616547052",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "C.G.E VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016306",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide et précise ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf767b3a242b0ee90fe0",
        "extendedResponse": {
            "_id": "6405aa7a36e8ad318e69a4f1",
            "submittedBody": {
                "id": "63fedf767b3a242b0ee90fe00.8017814030701809",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L’heure de réception du sms! \n6h15",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0671992051"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "698e6eb1-53f1-4bcc-bf16-ed9670dd2397",
            "responseId": "63fedf767b3a242b0ee90fe0",
            "receivedResponse": {
                "id": "63fedf767b3a242b0ee90fe00.8017814030701809",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L heure de réception du sms! \n6h15",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.612Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "0671992051",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Jérémy BAYE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0671992051",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TGF GENIE CLIMATIQUE",
            "CustomerCompanyGroupName": "TGF SARL",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CD230111-CDF230109 - CYMEA",
            "XylemReference": "201242",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L’heure de réception du sms! \n6h15",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf767b3a242b0ee9101a",
        "extendedResponse": {
            "_id": "6405aa7a36e8ad318e69a4f2",
            "submittedBody": {
                "id": "63fedf767b3a242b0ee9101a0.3927363390365892",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité et bonne référence de matériel",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabien.fontana@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7035f036-42c8-4e45-b1aa-f22ba7224c06",
            "responseId": "63fedf767b3a242b0ee9101a",
            "receivedResponse": {
                "id": "63fedf767b3a242b0ee9101a0.3927363390365892",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité et bonne référence de matériel",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.696Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "fabien.fontana@veolia.com",
            "CustomerContactEmail": "fabien.fontana@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FONTANA",
            "CustomerContactFirstName": "Fabien",
            "CustomerContactMobilePhone": "0617033981",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CEO - VEOLIA - CGE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016427",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité et bonne référence de matériel",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf76255cbcabbc8d71a3",
        "extendedResponse": {
            "_id": "6405aa7b36e8ad318e69a4f3",
            "submittedBody": {
                "id": "63fedf76255cbcabbc8d71a30.9280237277734489",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le délai initialement annoncé n'a largement pas été respecté et est sans cesse repoussé...",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mathieu.layrac@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "5122c3c8-f504-4312-96b0-d277a93ed232",
            "responseId": "63fedf76255cbcabbc8d71a3",
            "receivedResponse": {
                "id": "63fedf76255cbcabbc8d71a30.9280237277734489",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le délai initialement annoncé n'a largement pas été respecté et est sans cesse repoussé...",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.neg",
                                    "label": "Welcome / Kindness (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "accueil.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "accueil.neg",
                    "label": "Welcome / Kindness (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.697Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "mathieu.layrac@suez.com",
            "CustomerContactEmail": "mathieu.layrac@suez.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ DEGREMONT SERVICE",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "86TPLA123001946/0",
            "XylemReference": "768243",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le délai initialement annoncé n'a largement pas été respecté et est sans cesse repoussé...",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf76255cbcabbc8d71a7",
        "extendedResponse": {
            "_id": "6405aa7b36e8ad318e69a4f4",
            "submittedBody": {
                "id": "63fedf76255cbcabbc8d71a70.2807450065266419",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité du commercial",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lalemaire@soc.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "32443f67-2bde-41dc-b8ca-b2634c7e1392",
            "responseId": "63fedf76255cbcabbc8d71a7",
            "receivedResponse": {
                "id": "63fedf76255cbcabbc8d71a70.2807450065266419",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité du commercial",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.706Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "lalemaire@soc.fr",
            "CustomerContactEmail": "lalemaire@soc.fr",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOC RHONE ALPES et GRANDS PROJETS",
            "CustomerCompanyGroupName": "SOC",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "68NS03-068 A10791923",
            "XylemReference": "769540",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité du commercial",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "L'agence Sud Est soit plus réactive"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf767b3a242b0ee910d4",
        "extendedResponse": {
            "_id": "6405aa7b36e8ad318e69a4f5",
            "submittedBody": {
                "id": "63fedf767b3a242b0ee910d40.5660465036710911",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "impeccable par telephone, et la reponse j+1 par mail",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ivladu@prolians.eu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "afbcb563-0e36-4fd1-b24b-71f6b96e8f28",
            "responseId": "63fedf767b3a242b0ee910d4",
            "receivedResponse": {
                "id": "63fedf767b3a242b0ee910d40.5660465036710911",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "impeccable par téléphone, et la réponse j+1 par mail",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.878Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "ivladu@prolians.eu",
            "CustomerContactEmail": "ivladu@prolians.eu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VLADU",
            "CustomerContactFirstName": "Ionel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LECOUFLE",
            "CustomerCompanyGroupName": "DESCOURS & CABAUD (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016150",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "impeccable par telephone, et la reponse j+1 par mail",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf767b3a242b0ee9114f",
        "extendedResponse": {
            "_id": "6405aa7b36e8ad318e69a4f6",
            "submittedBody": {
                "id": "63fedf767b3a242b0ee9114f0.010289887702555012",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité",
                "creationDate": "2023-03-01T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bryan.morieux@equans.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "367ca7ea-46d6-4eb1-8ac0-52850714c658",
            "responseId": "63fedf767b3a242b0ee9114f",
            "receivedResponse": {
                "id": "63fedf767b3a242b0ee9114f0.010289887702555012",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité",
                "creationDate": "2023-03-01T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-01T05:15:34.952Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "bryan.morieux@equans.com",
            "CustomerContactEmail": "bryan.morieux@equans.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORIEUX",
            "CustomerContactFirstName": "Bryan",
            "CustomerContactMobilePhone": "0783682208",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EQUANS AXIMA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016183",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf777b3a242b0ee9126e",
        "extendedResponse": {
            "_id": "6405aa7c36e8ad318e69a4f7",
            "submittedBody": {
                "id": "63fedf777b3a242b0ee9126e0.9935317542738611",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "TOUT",
                "creationDate": "2023-03-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bbigot@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "eec6defb-7800-4141-8e35-250c644cf4b2",
            "responseId": "63fedf777b3a242b0ee9126e",
            "receivedResponse": {
                "id": "63fedf777b3a242b0ee9126e0.9935317542738611",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "TOUT",
                "creationDate": "2023-03-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-01T05:15:35.019Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "bbigot@lri-sodime.com",
            "CustomerContactEmail": "bbigot@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIGOT",
            "CustomerContactFirstName": "Bruno",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016212",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "TOUT",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "RIEN"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "63fedf77255cbcabbc8d754e",
        "extendedResponse": {
            "_id": "6405aa7c36e8ad318e69a4f8",
            "submittedBody": {
                "id": "63fedf77255cbcabbc8d754e0.21345422154776816",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très réactif",
                "creationDate": "2023-03-01T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mjacquot@ccpm-maiche.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f39dffbe-ca16-46d6-a612-32e4458d5583",
            "responseId": "63fedf77255cbcabbc8d754e",
            "receivedResponse": {
                "id": "63fedf77255cbcabbc8d754e0.21345422154776816",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très réactif",
                "creationDate": "2023-03-01T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-01T05:15:35.071Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "mjacquot@ccpm-maiche.com",
            "CustomerContactEmail": "mjacquot@ccpm-maiche.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JACQUOT",
            "CustomerContactFirstName": "Marc",
            "CustomerContactMobilePhone": "0614283499",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Communauté de Communes du Pays de Maîche",
            "CustomerCompanyGroupName": "COMM DE COMMUNES DU MAICHE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "2023-AS021-XYLE",
            "XylemReference": "771328",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très réactif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3255cbcabbc1dad9c",
        "extendedResponse": {
            "_id": "6405aa7c36e8ad318e69a4f9",
            "submittedBody": {
                "id": "640030f3255cbcabbc1dad9c0.008902882567601411",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le délai de réponse est très bon",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "seelec.etude@wanadoo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ce067a80-7e59-42c0-9175-8479654b5b0a",
            "responseId": "640030f3255cbcabbc1dad9c",
            "receivedResponse": {
                "id": "640030f3255cbcabbc1dad9c0.008902882567601411",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le délai de réponse est très bon",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.183Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "seelec.etude@wanadoo.fr",
            "CustomerContactEmail": "seelec.etude@wanadoo.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "FOUILLOUSE",
            "CustomerContactFirstName": "ANNIE",
            "CustomerContactMobilePhone": "0555621637",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEELEC",
            "CustomerCompanyGroupName": "SEELEC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "030378",
            "XylemReference": "771476",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le délai de réponse est très bon",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3501c04d391daa78d",
        "extendedResponse": {
            "_id": "6405aa7c36e8ad318e69a4fa",
            "submittedBody": {
                "id": "640030f3501c04d391daa78d0.2542080236312827",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "GEOFFREY est professionnel et très avenant.\nJe le remercie pour sa présentation devant ma forcé de vente.",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yannick.besse@tereva.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5ef7ceb2-5a63-47f7-ae32-52b40b8a7a5a",
            "responseId": "640030f3501c04d391daa78d",
            "receivedResponse": {
                "id": "640030f3501c04d391daa78d0.2542080236312827",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "GEOFFREY est professionnel et très avenant.\nJe le remercie pour sa présentation devant ma forcé de vente.",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.426Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "yannick.besse@tereva.fr",
            "CustomerContactEmail": "yannick.besse@tereva.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BESSE",
            "CustomerContactFirstName": "Yannick",
            "CustomerContactMobilePhone": "0788526494",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TEREVA BRIVE",
            "CustomerCompanyGroupName": "MBE (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "GEOFFREY est professionnel et très avenant.\nJe le remercie pour sa présentation devant ma forcé de vente.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3501c04d391daa7a0",
        "extendedResponse": {
            "_id": "6405aa7d36e8ad318e69a4fb",
            "submittedBody": {
                "id": "640030f3501c04d391daa7a00.7667118939928754",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour, livraison dans les délais et conforme Cordialement, M.Bonizec.",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0658479132"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "55cc55ac-5148-458b-abc2-1c6c74251161",
            "responseId": "640030f3501c04d391daa7a0",
            "receivedResponse": {
                "id": "640030f3501c04d391daa7a00.7667118939928754",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour, livraison dans les délais et conforme Cordialement, M.Bonizec.",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.468Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "0658479132",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BONIZEC Jean-Yves",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0658479132",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RENNES METROPOLE USINE",
            "CustomerCompanyGroupName": "RENNES METROPOLE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "E177863",
            "XylemReference": "192157",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour, livraison dans les délais et conforme Cordialement, M.Bonizec.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3501c04d391daa7a3",
        "extendedResponse": {
            "_id": "6405aa7d36e8ad318e69a4fc",
            "submittedBody": {
                "id": "640030f3501c04d391daa7a30.9497781351384984",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les délais de livraison ne sont pas respecté, les livreur n'appellent pas j'ai du me déplacer pour récupérer la livraison et dernier point la commande globale a été fractionné en 10 livraison. ",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0678223863"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "8b219320-2cd1-4b5e-aadc-6434eb9a3dba",
            "responseId": "640030f3501c04d391daa7a3",
            "receivedResponse": {
                "id": "640030f3501c04d391daa7a30.9497781351384984",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les délais de livraison ne sont pas respecté, les livreur n'appellent pas j'ai du me déplacer pour récupérer la livraison et dernier point la commande globale a été fractionné en 10 livraison. ",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.476Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "0678223863",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "GUILLAUME DAVID",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0678223863",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AXIMA-CONCEPT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "22185962",
            "XylemReference": "202557",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les délais de livraison ne sont pas respecté, les livreur n'appellent pas j'ai du me déplacer pour récupérer la livraison et dernier point la commande globale a été fractionné en 10 livraison. ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3501c04d391daa7c9",
        "extendedResponse": {
            "_id": "6405aa7d36e8ad318e69a4fd",
            "submittedBody": {
                "id": "640030f3501c04d391daa7c90.3571582343669255",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Parfait ",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0672950884"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fb46a98c-43d8-4f96-bed8-190f14d2e1ed",
            "responseId": "640030f3501c04d391daa7c9",
            "receivedResponse": {
                "id": "640030f3501c04d391daa7c90.3571582343669255",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Parfait ",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.507Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "0672950884",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "OLIVIER PILLON",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0672950884",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CAC LE COTENTIN",
            "CustomerCompanyGroupName": "LE COTENTIN COM D'AGGLO.",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BC2023/231702050100072",
            "XylemReference": "203771",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Parfait ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3255cbcabbc1db7ad",
        "extendedResponse": {
            "_id": "6405aa7e36e8ad318e69a4fe",
            "submittedBody": {
                "id": "640030f3255cbcabbc1db7ad0.33170640311608657",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité apporté à ma demande",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sylvie.ramard@rubix.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "df123d16-7b44-47c0-ae14-58ce4c4d2c03",
            "responseId": "640030f3255cbcabbc1db7ad",
            "receivedResponse": {
                "id": "640030f3255cbcabbc1db7ad0.33170640311608657",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité apporté à ma demande",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.568Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "sylvie.ramard@rubix.com",
            "CustomerContactEmail": "sylvie.ramard@rubix.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "RAMARD",
            "CustomerContactFirstName": "SYLVIE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OREXAD",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": ".",
            "XylemSalespeopleName": "TCS ZONE IDF",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016716",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité apporté à ma demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3255cbcabbc1db899",
        "extendedResponse": {
            "_id": "6405aa7e36e8ad318e69a4ff",
            "submittedBody": {
                "id": "640030f3255cbcabbc1db8990.2632084250902438",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et professionalisme",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "laurent.buchenaud@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "5d3c3696-0937-4903-81bf-12ae70850ca2",
            "responseId": "640030f3255cbcabbc1db899",
            "receivedResponse": {
                "id": "640030f3255cbcabbc1db8990.2632084250902438",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et professionnalisme",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.692Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "laurent.buchenaud@veolia.com",
            "CustomerContactEmail": "laurent.buchenaud@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BUCHENAUD",
            "CustomerContactFirstName": "LAURENT",
            "CustomerContactMobilePhone": "0637648686",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SPEN VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Francois",
            "XylemContactedPersonName": "LANTIGNY",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016499",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et professionalisme",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3255cbcabbc1db94f",
        "extendedResponse": {
            "_id": "6405aa7e36e8ad318e69a500",
            "submittedBody": {
                "id": "640030f3255cbcabbc1db94f0.2177636773344811",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide + commentaires sur les solutions proposées dans le corps du mail",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "marie.thomas@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "4d1d9c88-5add-4c49-976f-69d32992c722",
            "responseId": "640030f3255cbcabbc1db94f",
            "receivedResponse": {
                "id": "640030f3255cbcabbc1db94f0.2177636773344811",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide + commentaires sur les solutions proposées dans le corps du mail",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.817Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "marie.thomas@saur.com",
            "CustomerContactEmail": "marie.thomas@saur.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Marie",
            "CustomerContactMobilePhone": "0699924713",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR 31",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016566",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide + commentaires sur les solutions proposées dans le corps du mail",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Compléter la fiche de spécifications techniques jointe à la demande de consultation"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3255cbcabbc1dba61",
        "extendedResponse": {
            "_id": "6405aa7e36e8ad318e69a501",
            "submittedBody": {
                "id": "640030f3255cbcabbc1dba610.7721202007804624",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité et efficacité ...",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tony.dossantos@eau-vapeur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7282698a-ce65-4b94-bd9f-66366b5edb59",
            "responseId": "640030f3255cbcabbc1dba61",
            "receivedResponse": {
                "id": "640030f3255cbcabbc1dba610.7721202007804624",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité et efficacité ...",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.958Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "tony.dossantos@eau-vapeur.fr",
            "CustomerContactEmail": "tony.dossantos@eau-vapeur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DOS SANTOS",
            "CustomerContactFirstName": "TONY",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAU ET VAPEUR",
            "CustomerCompanyGroupName": "EAU ET VAPEUR",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Gaetan",
            "XylemContactedPersonName": "BRETEAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016659",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité et efficacité ...",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f3255cbcabbc1dbaa6",
        "extendedResponse": {
            "_id": "6405aa7e36e8ad318e69a502",
            "submittedBody": {
                "id": "640030f3255cbcabbc1dbaa60.793709900765154",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "4 mois pour avoir un devis , c'est dommage  au vue de la qualité des produits!",
                "creationDate": "2023-03-02T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "justin.malterre@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "5b672769-f0f9-4cff-823c-695982d3ce0f",
            "responseId": "640030f3255cbcabbc1dbaa6",
            "receivedResponse": {
                "id": "640030f3255cbcabbc1dbaa60.793709900765154",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "4 mois pour avoir un devis , c'est dommage au vue de la qualité des produits!",
                "creationDate": "2023-03-02T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:31.984Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "justin.malterre@veolia.com",
            "CustomerContactEmail": "justin.malterre@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MALTERRE",
            "CustomerContactFirstName": "Justin",
            "CustomerContactMobilePhone": "0603654102",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SARP CENTRE EST",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016672",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "4 mois pour avoir un devis , c'est dommage  au vue de la qualité des produits!",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f4255cbcabbc1dbb0b",
        "extendedResponse": {
            "_id": "6405aa7f36e8ad318e69a503",
            "submittedBody": {
                "id": "640030f4255cbcabbc1dbb0b0.09924734753707476",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2023-03-02T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.hauth@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2e4e59fc-7c3b-4958-a9bc-1c9fb2c98668",
            "responseId": "640030f4255cbcabbc1dbb0b",
            "receivedResponse": {
                "id": "640030f4255cbcabbc1dbb0b0.09924734753707476",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2023-03-02T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:32.042Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "sebastien.hauth@dalkia.fr",
            "CustomerContactEmail": "sebastien.hauth@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HAUTH",
            "CustomerContactFirstName": "SEBASTIEN",
            "CustomerContactMobilePhone": "0620821687",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA France",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Fabien",
            "XylemSalespeopleName": "CANNIZZARO",
            "XylemContactedPersonFirstName": "Fabien",
            "XylemContactedPersonName": "CANNIZZARO",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223016708",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f4255cbcabbc1dbc4e",
        "extendedResponse": {
            "_id": "6405aa7f36e8ad318e69a504",
            "submittedBody": {
                "id": "640030f4255cbcabbc1dbc4e0.9209869176219243",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité serieux",
                "creationDate": "2023-03-02T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "herve.chemama@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c2f75a69-6f28-4ee7-9ddd-0cac164775c3",
            "responseId": "640030f4255cbcabbc1dbc4e",
            "receivedResponse": {
                "id": "640030f4255cbcabbc1dbc4e0.9209869176219243",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité sérieux",
                "creationDate": "2023-03-02T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:32.206Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "herve.chemama@suez.com",
            "CustomerContactEmail": "herve.chemama@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHEMAMA",
            "CustomerContactFirstName": "Hervé",
            "CustomerContactMobilePhone": "0680621766",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016811",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité serieux",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640030f4255cbcabbc1dbc99",
        "extendedResponse": {
            "_id": "6405aa7f36e8ad318e69a505",
            "submittedBody": {
                "id": "640030f4255cbcabbc1dbc990.0826539879294399",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité ",
                "creationDate": "2023-03-02T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabrice.lenne@herve-thermique.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ffcf7741-7f2c-4eb7-85fe-0f72bc9ab55d",
            "responseId": "640030f4255cbcabbc1dbc99",
            "receivedResponse": {
                "id": "640030f4255cbcabbc1dbc990.0826539879294399",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité ",
                "creationDate": "2023-03-02T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-02T05:15:32.247Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-02_06.15.10",
        "integration": {
            "ContactKey": "fabrice.lenne@herve-thermique.com",
            "CustomerContactEmail": "fabrice.lenne@herve-thermique.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LENNE",
            "CustomerContactFirstName": "FABRICE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HERVE THERMIQUE",
            "CustomerCompanyGroupName": "HERVE THERMIQUE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "BOURDIEU",
            "XylemContactedPersonFirstName": "Geoffrey",
            "XylemContactedPersonName": "BOURDIEU",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223016826",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Frait de port important"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182717b3a242b0ef7a96e",
        "extendedResponse": {
            "_id": "6405aa7f36e8ad318e69a506",
            "submittedBody": {
                "id": "640182717b3a242b0ef7a96e0.37798090194365797",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Echange de qualité. A l'écoute de nos besoins",
                "creationDate": "2023-03-03T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-christophe.ournac@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "1d16f27e-da37-4c85-841f-3d41e0f63757",
            "responseId": "640182717b3a242b0ef7a96e",
            "receivedResponse": {
                "id": "640182717b3a242b0ef7a96e0.37798090194365797",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Echange de qualité. A l'écoute de nos besoins",
                "creationDate": "2023-03-03T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:29.908Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "jean-christophe.ournac@veolia.com",
            "CustomerContactEmail": "jean-christophe.ournac@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "OURNAC",
            "CustomerContactFirstName": "JEAN CHRISTOPHE",
            "CustomerContactMobilePhone": "0620344134",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - MONTPELLIER",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Sonia",
            "XylemContactedPersonName": "HUBERT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Echange de qualité. A l'écoute de nos besoins",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "RAS"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182727b3a242b0ef7c3b0",
        "extendedResponse": {
            "_id": "6405aa8036e8ad318e69a507",
            "submittedBody": {
                "id": "640182727b3a242b0ef7c3b00.599279036981839",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-michel.deffaix@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9593bccd-0530-4bb5-b95e-005eb54adb7e",
            "responseId": "640182727b3a242b0ef7c3b0",
            "receivedResponse": {
                "id": "640182727b3a242b0ef7c3b00.599279036981839",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.690Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "jean-michel.deffaix@saur.com",
            "CustomerContactEmail": "jean-michel.deffaix@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEFFAIX",
            "CustomerContactFirstName": "Jean Michel",
            "CustomerContactMobilePhone": "0762531623",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR / UTEP",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016995",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182727b3a242b0ef7c3b7",
        "extendedResponse": {
            "_id": "6405aa8036e8ad318e69a508",
            "submittedBody": {
                "id": "640182727b3a242b0ef7c3b70.5105991936868497",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "TRES REACTIF \nMERCI ",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ludovic.barre@soazig.eu"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6e608bed-98f2-4b14-a16c-e156121b945d",
            "responseId": "640182727b3a242b0ef7c3b7",
            "receivedResponse": {
                "id": "640182727b3a242b0ef7c3b70.5105991936868497",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très REACTIF \nMERCI ",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.704Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "ludovic.barre@soazig.eu",
            "CustomerContactEmail": "ludovic.barre@soazig.eu",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BARRE",
            "CustomerContactFirstName": "Ludovic",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ROULIN",
            "CustomerCompanyGroupName": "ETS ROULIN PERE ET FILS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017046",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "TRES REACTIF \nMERCI ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64018272ff70890b5b88e44a",
        "extendedResponse": {
            "_id": "6405aa8036e8ad318e69a509",
            "submittedBody": {
                "id": "64018272ff70890b5b88e44a0.789557554752756",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité du retour et disponibilité matériel",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.canevari@eridis.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e837ddd7-1dfb-4dca-a861-ee3c1296bea8",
            "responseId": "64018272ff70890b5b88e44a",
            "receivedResponse": {
                "id": "64018272ff70890b5b88e44a0.789557554752756",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité du retour et disponibilité matériel",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.744Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "philippe.canevari@eridis.fr",
            "CustomerContactEmail": "philippe.canevari@eridis.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CANEVARI",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0684830448",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ERIDIS",
            "CustomerCompanyGroupName": "ERIDIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Michel",
            "XylemSalespeopleName": "ALBRECHT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CE230249 - CFE230375",
            "XylemReference": "771627",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité du retour et disponibilité matériel",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182727b3a242b0ef7c3d2",
        "extendedResponse": {
            "_id": "6405aa8036e8ad318e69a50a",
            "submittedBody": {
                "id": "640182727b3a242b0ef7c3d20.5781161614752264",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+Réponse très rapide\n+Offre complète",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hilaire.lecornec@roger-marteau.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fab0b166-839b-4732-9be2-13f7e99e009c",
            "responseId": "640182727b3a242b0ef7c3d2",
            "receivedResponse": {
                "id": "640182727b3a242b0ef7c3d20.5781161614752264",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+Réponse très rapide\n+Offre complète",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "choix.pos",
                                    "label": "Choice (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "choix.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "choix.pos",
                    "label": "Choice (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.748Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactEmail": "hilaire.lecornec@roger-marteau.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE CORNEC",
            "CustomerContactFirstName": "Hilaire",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MARTEAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017144",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+Réponse très rapide\n+Offre complète",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182727b3a242b0ef7c3d9",
        "extendedResponse": {
            "_id": "6405aa8036e8ad318e69a50b",
            "submittedBody": {
                "id": "640182727b3a242b0ef7c3d90.657226079086749",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonjour \nje suis très satisfait de notre collaboration je remercie pascal tisseyre et vincent idris pour leurs disponibilités et leurs professionnalisme, technique, rapidité et compétences sont a notre service. Nous sommes tous très sollicités dans l'urgence au quotidien et je les remercie pour leurs patience.\n\nNicolas viguier",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolas-viguier@ccl.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dc964699-ec8d-4d95-a53b-56ffd9b0b843",
            "responseId": "640182727b3a242b0ef7c3d9",
            "receivedResponse": {
                "id": "640182727b3a242b0ef7c3d90.657226079086749",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonjour \nje suis très satisfait de notre collaboration je remercie pascal tisseyre et vincent idris pour leurs disponibilités et leurs professionnalisme, technique, rapidité et compétences sont a notre service. Nous sommes tous très sollicités dans l'urgence au quotidien et je les remercie pour leurs patience.\n\nNicolas viguier",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "delai.pos",
                "ecoute.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.756Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "nicolas-viguier@ccl.fr",
            "CustomerContactEmail": "nicolas-viguier@ccl.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VIGUIER",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0623462454",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CCL",
            "CustomerCompanyGroupName": "C.C.L.",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016997",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonjour \nje suis très satisfait de notre collaboration je remercie pascal tisseyre et vincent idris pour leurs disponibilités et leurs professionnalisme, technique, rapidité et compétences sont a notre service. Nous sommes tous très sollicités dans l'urgence au quotidien et je les remercie pour leurs patience.\n\nNicolas viguier",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182727b3a242b0ef7c482",
        "extendedResponse": {
            "_id": "6405aa8136e8ad318e69a50c",
            "submittedBody": {
                "id": "640182727b3a242b0ef7c4820.6966518804388679",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\n\nLa rapidité de réponse sur les demandes de chiffrage.",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "joel.gagneux@siaal.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b5e3af9a-7969-4b53-8617-a4896a0853f1",
            "responseId": "640182727b3a242b0ef7c482",
            "receivedResponse": {
                "id": "640182727b3a242b0ef7c4820.6966518804388679",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\n\nLa rapidité de réponse sur les demandes de chiffrage.",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.889Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "joel.gagneux@siaal.fr",
            "CustomerContactEmail": "joel.gagneux@siaal.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GAGNEUX",
            "CustomerContactFirstName": "JOEL",
            "CustomerContactMobilePhone": "0785567800",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ECLA ESPACE COMMUNAUTAIRE LONS AGGLO EX SIAAL",
            "CustomerCompanyGroupName": "ESPACE COMMUNAUTAIR LONS AGGLO",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Serge",
            "XylemContactedPersonName": "POLI",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223016941",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\n\nLa rapidité de réponse sur les demandes de chiffrage.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182727b3a242b0ef7c4aa",
        "extendedResponse": {
            "_id": "6405aa8136e8ad318e69a50d",
            "submittedBody": {
                "id": "640182727b3a242b0ef7c4aa0.2933307073535991",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité ",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "p.leandro@cosmac.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "03b87d74-4c05-42c1-aaed-f8bd36f9c873",
            "responseId": "640182727b3a242b0ef7c4aa",
            "receivedResponse": {
                "id": "640182727b3a242b0ef7c4aa0.2933307073535991",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité ",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.916Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "p.leandro@cosmac.fr",
            "CustomerContactEmail": "p.leandro@cosmac.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEANDRO",
            "CustomerContactFirstName": "PATRICK",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ESNAY - COSMAC",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "ARNAUD",
            "XylemContactedPersonName": "JAMELIN",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223016951",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64018272ff70890b5b88e581",
        "extendedResponse": {
            "_id": "6405aa8136e8ad318e69a50e",
            "submittedBody": {
                "id": "64018272ff70890b5b88e5810.4498934198276463",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la rapidité pour avoir le devis\n",
                "creationDate": "2023-03-03T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.menegatti@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d61de8be-9374-4c25-bc5c-dbf7ff8be071",
            "responseId": "64018272ff70890b5b88e581",
            "receivedResponse": {
                "id": "64018272ff70890b5b88e5810.4498934198276463",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la rapidité pour avoir le devis\n",
                "creationDate": "2023-03-03T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:30.935Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "emmanuel.menegatti@suez.com",
            "CustomerContactEmail": "emmanuel.menegatti@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MENEGATTI",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "+33670795385",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (Agence ANZIN)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "30TPLA123002850/0",
            "XylemReference": "771763",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la rapidité pour avoir le devis\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182737b3a242b0ef7c593",
        "extendedResponse": {
            "_id": "6405aa8136e8ad318e69a50f",
            "submittedBody": {
                "id": "640182737b3a242b0ef7c5930.8475500199758303",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidite de l'offre retransmise",
                "creationDate": "2023-03-03T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.leroux@matal.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "1fe3bbd9-8f10-42df-b472-d95d6bf1a289",
            "responseId": "640182737b3a242b0ef7c593",
            "receivedResponse": {
                "id": "640182737b3a242b0ef7c5930.8475500199758303",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de l'offre retransmise",
                "creationDate": "2023-03-03T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:31.028Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "david.leroux@matal.fr",
            "CustomerContactEmail": "david.leroux@matal.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE ROUX",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "0619784515",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MATAL",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017016",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidite de l'offre retransmise",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182737b3a242b0ef7c5f6",
        "extendedResponse": {
            "_id": "64088b5d33d6a99b21e1f535",
            "submittedBody": {
                "id": "640182737b3a242b0ef7c5f60.8722712541925954",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide et précise, documentations et notes techniques fournies.",
                "creationDate": "2023-03-03T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.dussart@lhotellier.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b1390748-e7e3-4661-bb6b-b42204ad9068",
            "responseId": "640182737b3a242b0ef7c5f6",
            "receivedResponse": {
                "id": "640182737b3a242b0ef7c5f60.8722712541925954",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide et précise, documentations et notes techniques fournies.",
                "creationDate": "2023-03-03T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-03T05:15:31.094Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "olivier.dussart@lhotellier.fr",
            "CustomerContactEmail": "olivier.dussart@lhotellier.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUSSART",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRA",
            "CustomerCompanyGroupName": "LHOTELLIER EAU",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Christophe",
            "XylemSalespeopleName": "DOUAY",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017089",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide et précise, documentations et notes techniques fournies.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640182737b3a242b0ef7c60b",
        "extendedResponse": {
            "_id": "6405aa8236e8ad318e69a510",
            "submittedBody": {
                "id": "640182737b3a242b0ef7c60b0.7236884918944295",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bien",
                "creationDate": "2023-03-03T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mickael.ferrand@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "48466a24-df98-4b7c-83e0-3236a7f343f5",
            "responseId": "640182737b3a242b0ef7c60b",
            "receivedResponse": {
                "id": "640182737b3a242b0ef7c60b0.7236884918944295",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bien",
                "creationDate": "2023-03-03T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-03T05:15:31.125Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-03_06.15.09",
        "integration": {
            "ContactKey": "mickael.ferrand@veolia.com",
            "CustomerContactEmail": "mickael.ferrand@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FERRAND",
            "CustomerContactFirstName": "Mickael",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Rémi",
            "XylemContactedPersonName": "BOUQUIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223017128",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bien",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f1ff70890b5bfbc37f",
        "extendedResponse": {
            "_id": "64088b5d33d6a99b21e1f536",
            "submittedBody": {
                "id": "6402d3f1ff70890b5bfbc37f0.6372299928216374",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis correspondant à la demande ",
                "creationDate": "2023-03-04T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.samson@mail.nidec.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "a24f6368-0c04-4821-bfcc-3117c75e31bd",
            "responseId": "6402d3f1ff70890b5bfbc37f",
            "receivedResponse": {
                "id": "6402d3f1ff70890b5bfbc37f0.6372299928216374",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis correspondant à la demande ",
                "creationDate": "2023-03-04T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "fiabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-04T05:15:29.862Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-04_06.15.09",
        "integration": {
            "ContactKey": "christophe.samson@mail.nidec.com",
            "CustomerContactEmail": "christophe.samson@mail.nidec.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SAMSON",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0622856235",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HOUSSIN ESO IDF NORMANDIE",
            "CustomerCompanyGroupName": "ESO (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017294",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis correspondant à la demande ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Peut Être être plus rapide ."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f1ff70890b5bfbc38c",
        "extendedResponse": {
            "_id": "6405aa8236e8ad318e69a511",
            "submittedBody": {
                "id": "6402d3f1ff70890b5bfbc38c0.8277583952829448",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Super commercial, avenant,sérieux et très réactif. Que du positif rien de négatif.",
                "creationDate": "2023-03-04T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "paul.rocha@proservice-sema.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8c4d5fec-e9d3-4fe2-a22e-2336b9330c6f",
            "responseId": "6402d3f1ff70890b5bfbc38c",
            "receivedResponse": {
                "id": "6402d3f1ff70890b5bfbc38c0.8277583952829448",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Super commercial, avenant,sérieux et très réactif. Que du positif rien de négatif.",
                "creationDate": "2023-03-04T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-04T05:15:29.880Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "paul.rocha@proservice-sema.fr",
            "CustomerContactEmail": "paul.rocha@proservice-sema.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROCHA",
            "CustomerContactFirstName": "Paul",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PROSERVICE",
            "CustomerCompanyGroupName": "PROSERVICE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Super commercial, avenant,sérieux et très réactif. Que du positif rien de négatif.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f1ff70890b5bfbc392",
        "extendedResponse": {
            "_id": "64088b5e33d6a99b21e1f537",
            "submittedBody": {
                "id": "6402d3f1ff70890b5bfbc3920.9435598318845493",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Votre courtoisie et votre réactivité",
                "creationDate": "2023-03-04T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c-reau@eauxdevienne.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c004f7ac-db2f-4b86-ada9-40142503a278",
            "responseId": "6402d3f1ff70890b5bfbc392",
            "receivedResponse": {
                "id": "6402d3f1ff70890b5bfbc3920.9435598318845493",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Votre courtoisie et votre réactivité",
                "creationDate": "2023-03-04T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-04T05:15:29.887Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-04_06.15.09",
        "integration": {
            "ContactKey": "c-reau@eauxdevienne.fr",
            "CustomerContactEmail": "c-reau@eauxdevienne.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REAU",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE VIENNE - SIVEER",
            "CustomerCompanyGroupName": "EAUX DE VIENNE - S.I.V.E.E.R.",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223017330",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Votre courtoisie et votre réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f1255cbcabbc405bc8",
        "extendedResponse": {
            "_id": "6405aa8236e8ad318e69a512",
            "submittedBody": {
                "id": "6402d3f1255cbcabbc405bc80.6711191839606103",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour\nJe suis extrêment déçu. Delai de livraison annoncé au telephone : 3 jours Délai notifié sur l'accusé de réception : 1 mois. Commercial contacté sans réponse ni rappel\nJ'attends des explications \nJean Daniel Laborde - 0617693829",
                "creationDate": "2023-03-04T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-daniel.laborde@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "87ca2018-6813-4d49-a881-d5a7d399d784",
            "responseId": "6402d3f1255cbcabbc405bc8",
            "receivedResponse": {
                "id": "6402d3f1255cbcabbc405bc80.6711191839606103",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour\nJe suis extrêmement déçu. délai de livraison annoncé au téléphone : 3 jours Délai notifié sur l'accusé de réception : 1 mois. Commercial contacté sans réponse ni rappel\nJ'attends des explications \nJean Daniel Laborde - 0617693829",
                "creationDate": "2023-03-04T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "joignabilite.neg",
                "efficacite.neg",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-04T05:15:29.916Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-01_06.15.11",
        "integration": {
            "ContactKey": "jean-daniel.laborde@suez.com",
            "CustomerContactEmail": "jean-daniel.laborde@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LABORDE",
            "CustomerContactFirstName": "JEAN DANIEL",
            "CustomerContactMobilePhone": "0617693829",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ EAU FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "97TPLA123003135/0",
            "XylemReference": "771598",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour\nJe suis extrêment déçu. Delai de livraison annoncé au telephone : 3 jours Délai notifié sur l'accusé de réception : 1 mois. Commercial contacté sans réponse ni rappel\nJ'attends des explications \nJean Daniel Laborde - 0617693829",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"LABORDE Jean Daniel\",\n  \"phone\": \"0617693829\",\n  \"mail\": \"jean-daniel.laborde@suez.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f2ff70890b5bfbc56d",
        "extendedResponse": {
            "_id": "6405aa8236e8ad318e69a513",
            "submittedBody": {
                "id": "6402d3f2ff70890b5bfbc56d0.562125321388089",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qu il me plaît, c'est votre rapidité de réponse. \nCe qu il me déplaît, c'est que vous n avez aucun suivi commercial sur notre région. \n",
                "creationDate": "2023-03-04T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "guillaume.arvis@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "ab4c75c1-cd43-410e-9d72-69413792af13",
            "responseId": "6402d3f2ff70890b5bfbc56d",
            "receivedResponse": {
                "id": "6402d3f2ff70890b5bfbc56d0.562125321388089",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qu il me plaît, c'est votre rapidité de réponse. \nCe qu il me déplaît, c'est que vous n avez aucun suivi commercial sur notre région. \n",
                "creationDate": "2023-03-04T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.neg",
                                    "label": "Quality of service / Follow-up (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "service.neg",
                "ressenti.pos",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "service.neg",
                    "label": "Quality of service / Follow-up (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-04T05:15:30.193Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-04_06.15.09",
        "integration": {
            "ContactKey": "guillaume.arvis@saur.com",
            "CustomerContactEmail": "guillaume.arvis@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ARVIS",
            "CustomerContactFirstName": "GUILLAUME",
            "CustomerContactMobilePhone": "0660247804",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017332",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qu il me plaît, c'est votre rapidité de réponse. \nCe qu il me déplaît, c'est que vous n avez aucun suivi commercial sur notre région. \n",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f2ff70890b5bfbc571",
        "extendedResponse": {
            "_id": "6405aa8336e8ad318e69a514",
            "submittedBody": {
                "id": "6402d3f2ff70890b5bfbc5710.10033336166525064",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité et rapidité ",
                "creationDate": "2023-03-04T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "atomc@cabinet-merlin.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d03816d6-0467-48b3-a10a-58afb24674fb",
            "responseId": "6402d3f2ff70890b5bfbc571",
            "receivedResponse": {
                "id": "6402d3f2ff70890b5bfbc5710.10033336166525064",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité et rapidité ",
                "creationDate": "2023-03-04T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-04T05:15:30.202Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-04_06.15.09",
        "integration": {
            "ContactKey": "atomc@cabinet-merlin.fr",
            "CustomerContactEmail": "atomc@cabinet-merlin.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TOMC",
            "CustomerContactFirstName": "Arnaud",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CABINET MERLIN",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Tony",
            "XylemContactedPersonName": "GENEVE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223017336",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité et rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f2ff70890b5bfbc8ed",
        "extendedResponse": {
            "_id": "64088b5e33d6a99b21e1f538",
            "submittedBody": {
                "id": "6402d3f2ff70890b5bfbc8ed0.397015239603669",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide ",
                "creationDate": "2023-03-04T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "erwan.assainissement@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5b9aeec8-a30c-43f7-b3d4-59a024fc9122",
            "responseId": "6402d3f2ff70890b5bfbc8ed",
            "receivedResponse": {
                "id": "6402d3f2ff70890b5bfbc8ed0.397015239603669",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide ",
                "creationDate": "2023-03-04T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-04T05:15:30.524Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-04_06.15.09",
        "integration": {
            "ContactKey": "erwan.assainissement@gmail.com",
            "CustomerContactEmail": "erwan.assainissement@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LION",
            "CustomerContactFirstName": "ERWAN",
            "CustomerContactMobilePhone": "0631262489",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ERWAN ASSAINISSEMENT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017490",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f2ff70890b5bfbc995",
        "extendedResponse": {
            "_id": "6405aa8336e8ad318e69a515",
            "submittedBody": {
                "id": "6402d3f2ff70890b5bfbc9950.7274464575778474",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDITÉ DE LA RÉPONSE - DEVIS CLAIR ET CONFORME A LA DEMANDE ",
                "creationDate": "2023-03-04T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.martin@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c3f66086-2e1b-42ec-813e-fcd7469b9944",
            "responseId": "6402d3f2ff70890b5bfbc995",
            "receivedResponse": {
                "id": "6402d3f2ff70890b5bfbc9950.7274464575778474",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAPIDITÉ DE LA RÉPONSE - DEVIS CLAIR ET CONFORME A LA DEMANDE ",
                "creationDate": "2023-03-04T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-04T05:15:30.557Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-04_06.15.09",
        "integration": {
            "ContactKey": "eric.martin@veolia.com",
            "CustomerContactEmail": "eric.martin@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARTIN",
            "CustomerContactFirstName": "ERIC",
            "CustomerContactMobilePhone": "0612486220",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA 24 BERGERAC",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Cédric",
            "XylemContactedPersonName": "ROYER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223017510",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDITÉ DE LA RÉPONSE - DEVIS CLAIR ET CONFORME A LA DEMANDE ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6402d3f2ff70890b5bfbc99a",
        "extendedResponse": {
            "_id": "6405aa8336e8ad318e69a516",
            "submittedBody": {
                "id": "6402d3f2ff70890b5bfbc99a0.00973136812362796",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le temps d'attente pour certains retours de devis",
                "creationDate": "2023-03-04T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thierry.dagon@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "35a4d9af-44c8-4f24-8eb5-ff51d92b504d",
            "responseId": "6402d3f2ff70890b5bfbc99a",
            "receivedResponse": {
                "id": "6402d3f2ff70890b5bfbc99a0.00973136812362796",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le temps d'attente pour certains retours de devis",
                "creationDate": "2023-03-04T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-04T05:15:30.560Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-04_06.15.09",
        "integration": {
            "ContactKey": "thierry.dagon@veolia.com",
            "CustomerContactEmail": "thierry.dagon@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DAGON",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RABA SARP SUD OUEST",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "DENOYELLE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223017520",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le temps d'attente pour certains retours de devis",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "traiter les demandes urgentes en priorité"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6406c86f804ee5f1461429a5",
        "extendedResponse": {
            "_id": "64088b5e33d6a99b21e1f539",
            "submittedBody": {
                "id": "6406c86f804ee5f1461429a50.9375392665045985",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'écoute , la disponibilité et la montée d'information des contraintes du terrain .",
                "creationDate": "2023-03-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ahmido.boutaqmant@semopsival.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "dc7374e0-fe2f-4aee-908c-f28163849328",
            "responseId": "6406c86f804ee5f1461429a5",
            "receivedResponse": {
                "id": "6406c86f804ee5f1461429a50.9375392665045985",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'écoute , la disponibilité et la montée d'information des contraintes du terrain .",
                "creationDate": "2023-03-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-07T05:15:27.246Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "ahmido.boutaqmant@semopsival.fr",
            "CustomerContactEmail": "ahmido.boutaqmant@semopsival.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUTAQMANT",
            "CustomerContactFirstName": "Ahmido",
            "CustomerContactMobilePhone": "0788756336",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIVAL USINE EPURATION SEINE AMONT",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE",
            "XylemContactedPersonFirstName": "Flavio",
            "XylemContactedPersonName": "MEDARDONI",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'écoute , la disponibilité et la montée d'information des contraintes du terrain .",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6406c86fd24d12c228bd19f0",
        "extendedResponse": {
            "_id": "64088b5f33d6a99b21e1f53a",
            "submittedBody": {
                "id": "6406c86fd24d12c228bd19f00.61751378127781",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité. ",
                "creationDate": "2023-03-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.paillard@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2744dda4-ebb4-482b-9651-eee38edf4e66",
            "responseId": "6406c86fd24d12c228bd19f0",
            "receivedResponse": {
                "id": "6406c86fd24d12c228bd19f00.61751378127781",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité. ",
                "creationDate": "2023-03-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-07T05:15:27.361Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "eric.paillard@veolia.com",
            "CustomerContactEmail": "eric.paillard@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PAILLARD",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "0613160776",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - CFSP-",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017957",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité. ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6406c86fa06b87736da22e03",
        "extendedResponse": {
            "_id": "64088b5f33d6a99b21e1f53b",
            "submittedBody": {
                "id": "6406c86fa06b87736da22e030.16800824044424778",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "plus : règlage de la vitesse de de la puissance et qualité",
                "creationDate": "2023-03-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "emmanuel.beneteau@agglo-larochelle.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "acdcb56e-ac14-429e-8fcd-c630f8c9695d",
            "responseId": "6406c86fa06b87736da22e03",
            "receivedResponse": {
                "id": "6406c86fa06b87736da22e030.16800824044424778",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "plus : réglage de la vitesse de de la puissance et qualité",
                "creationDate": "2023-03-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-07T05:15:27.442Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "emmanuel.beneteau@agglo-larochelle.fr",
            "CustomerContactEmail": "emmanuel.beneteau@agglo-larochelle.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BENETEAU",
            "CustomerContactFirstName": "Emmanuel",
            "CustomerContactMobilePhone": "0622976622",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COM D'AGGLO LA ROCHELLE",
            "CustomerCompanyGroupName": "COMMUNAUTE AGGLO. LA ROCHELLE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AS230377",
            "XylemReference": "772102",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "plus : règlage de la vitesse de de la puissance et qualité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6406c86fd24d12c228bd1b17",
        "extendedResponse": {
            "_id": "64088b6033d6a99b21e1f53c",
            "submittedBody": {
                "id": "6406c86fd24d12c228bd1b170.7941796175891005",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapide et correct",
                "creationDate": "2023-03-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7f2c5db6-fe00-464f-ae8a-209ff012c3f2",
            "responseId": "6406c86fd24d12c228bd1b17",
            "receivedResponse": {
                "id": "6406c86fd24d12c228bd1b170.7941796175891005",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapide et correct",
                "creationDate": "2023-03-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-07T05:15:27.475Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017656",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapide et correct",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6406c86fd24d12c228bd1b81",
        "extendedResponse": {
            "_id": "64088b6033d6a99b21e1f53d",
            "submittedBody": {
                "id": "6406c86fd24d12c228bd1b810.8782526257429479",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la qualité de l 'offre",
                "creationDate": "2023-03-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pierre.ruch@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "ef897ffe-8842-4936-b53d-a2fb03d320e5",
            "responseId": "6406c86fd24d12c228bd1b81",
            "receivedResponse": {
                "id": "6406c86fd24d12c228bd1b810.8782526257429479",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la qualité de l 'offre",
                "creationDate": "2023-03-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-07T05:15:27.551Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "pierre.ruch@veolia.com",
            "CustomerContactEmail": "pierre.ruch@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RUCH",
            "CustomerContactFirstName": "Pierre",
            "CustomerContactMobilePhone": "0620305228",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU METZ",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017724",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la qualité de l 'offre",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6406c86fd24d12c228bd1c40",
        "extendedResponse": {
            "_id": "64088b6033d6a99b21e1f53e",
            "submittedBody": {
                "id": "6406c86fd24d12c228bd1c400.3693142482389211",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse rapide a la demande \nproposition d'une offre mieux adaptée a la demande initiale ",
                "creationDate": "2023-03-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vincent.leitao@industrelec.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c734f0a3-8007-4cdd-bf90-4842d9ecaea2",
            "responseId": "6406c86fd24d12c228bd1c40",
            "receivedResponse": {
                "id": "6406c86fd24d12c228bd1c400.3693142482389211",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide a la demande \nproposition d'une offre mieux adaptée a la demande initiale ",
                "creationDate": "2023-03-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-07T05:15:27.665Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "vincent.leitao@industrelec.com",
            "CustomerContactEmail": "vincent.leitao@industrelec.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEITAO",
            "CustomerContactFirstName": "VINCENT",
            "CustomerContactMobilePhone": "0607797178",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "INDUSTRELEC",
            "CustomerCompanyGroupName": "INDUSTRELEC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017708",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse rapide a la demande \nproposition d'une offre mieux adaptée a la demande initiale ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6406c86fd24d12c228bd1c53",
        "extendedResponse": {
            "_id": "64088b6133d6a99b21e1f53f",
            "submittedBody": {
                "id": "6406c86fd24d12c228bd1c530.9326495307542149",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réponse à nos demandes très bonne.",
                "creationDate": "2023-03-07T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francisco.romero@collardettrolart.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f6136e04-e8bb-447d-b6d1-d39a38eb8d72",
            "responseId": "6406c86fd24d12c228bd1c53",
            "receivedResponse": {
                "id": "6406c86fd24d12c228bd1c530.9326495307542149",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réponse à nos demandes très bonne.",
                "creationDate": "2023-03-07T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-07T05:15:27.697Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "francisco.romero@collardettrolart.com",
            "CustomerContactEmail": "francisco.romero@collardettrolart.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROMERO",
            "CustomerContactFirstName": "Francisco",
            "CustomerContactMobilePhone": "0689742408",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COLLARD ET TROLARD THERMIQUE",
            "CustomerCompanyGroupName": "COLLARD ET TROLART THERMIQUE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Remy",
            "XylemSalespeopleName": "BERNETIERE",
            "XylemContactedPersonFirstName": "Bénédicte",
            "XylemContactedPersonName": "LERAY",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017844",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réponse à nos demandes très bonne.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f0a06b87736d40c604",
        "extendedResponse": {
            "_id": "64088b6133d6a99b21e1f540",
            "submittedBody": {
                "id": "640819f0a06b87736d40c6040.7476118873643733",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon conseil ",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.bricout@anvolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e02e306c-7058-4340-a81c-776a62c6df99",
            "responseId": "640819f0a06b87736d40c604",
            "receivedResponse": {
                "id": "640819f0a06b87736d40c6040.7476118873643733",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon conseil ",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.508Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "s.bricout@anvolia.com",
            "CustomerContactEmail": "s.bricout@anvolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BRICOUT",
            "CustomerContactFirstName": "sylvain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ANVOLIA",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon conseil ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f0a06b87736d40c608",
        "extendedResponse": {
            "_id": "64088b6133d6a99b21e1f541",
            "submittedBody": {
                "id": "640819f0a06b87736d40c6080.05542211214456194",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commercial très à l'écoute et connait bien nos problématiques terrain.\nTrès professionnel, j'ai grand plaisir à travaillé avec lui.",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alexis.roblique@engie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7c404a7b-fa7d-4252-bb09-1d0351a8cfa1",
            "responseId": "640819f0a06b87736d40c608",
            "receivedResponse": {
                "id": "640819f0a06b87736d40c6080.05542211214456194",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commercial très à l'écoute et connaît bien nos problématiques terrain.\nTrès professionnel, j'ai grand plaisir à travaillé avec lui.",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.518Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "alexis.roblique@engie.com",
            "CustomerContactEmail": "alexis.roblique@engie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROBLIQUE",
            "CustomerContactFirstName": "ALEXIS",
            "CustomerContactMobilePhone": "0670649102",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COFELY",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Romain",
            "XylemSalespeopleName": "CANAPA",
            "XylemContactedPersonFirstName": "Romain",
            "XylemContactedPersonName": "CANAPA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commercial très à l'écoute et connait bien nos problématiques terrain.\nTrès professionnel, j'ai grand plaisir à travaillé avec lui.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f0804ee5f146f8aa6a",
        "extendedResponse": {
            "_id": "64088b6233d6a99b21e1f542",
            "submittedBody": {
                "id": "640819f0804ee5f146f8aa6a0.7924795726084068",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bon accueil ",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pascal.beaufils@motralec.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "45c7f347-a291-43f0-8a70-cb7053c22218",
            "responseId": "640819f0804ee5f146f8aa6a",
            "receivedResponse": {
                "id": "640819f0804ee5f146f8aa6a0.7924795726084068",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bon accueil ",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "accueil.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.529Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "pascal.beaufils@motralec.com",
            "CustomerContactEmail": "pascal.beaufils@motralec.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BEAUFILS",
            "CustomerContactFirstName": "PASCAL",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MOTRALEC",
            "CustomerCompanyGroupName": "MOTRALEC (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018178",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bon accueil ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Meilleure communication interne "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f0804ee5f146f8aaab",
        "extendedResponse": {
            "_id": "64088b6233d6a99b21e1f543",
            "submittedBody": {
                "id": "640819f0804ee5f146f8aaab0.3999799054775173",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour,\nl'étude de la pompe est complète.\nmanque les fixations et raccordements possible \nje vais voir avec M. Kerouanton.\ncordialement\n",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nleborgne@agglo-lorient.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "570b94da-0d62-4e99-8bec-ccf88a5a5ab3",
            "responseId": "640819f0804ee5f146f8aaab",
            "receivedResponse": {
                "id": "640819f0804ee5f146f8aaab0.3999799054775173",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour,\nl'étude de la pompe est complète.\nmanque les fixations et raccordements possible \nje vais voir avec M. Kerouanton.\ncordialement\n",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.571Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "nleborgne@agglo-lorient.fr",
            "CustomerContactEmail": "nleborgne@agglo-lorient.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE BORGNE",
            "CustomerContactFirstName": ".",
            "CustomerContactMobilePhone": "0616284773",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LORIENT AGGLOMERATION",
            "CustomerCompanyGroupName": "LORIENT AGGLOMERATION",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223017870",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour,\nl'étude de la pompe est complète.\nmanque les fixations et raccordements possible \nje vais voir avec M. Kerouanton.\ncordialement\n",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f0804ee5f146f8aadc",
        "extendedResponse": {
            "_id": "64146ac4b47a8ed707235b1d",
            "submittedBody": {
                "id": "640819f0804ee5f146f8aadc0.9419520446467049",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le devis correspond à la demande.",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-christophe.moisan@nantesmetropole.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "510c41ea-aaee-4d11-b365-586df6cf79d2",
            "responseId": "640819f0804ee5f146f8aadc",
            "receivedResponse": {
                "id": "640819f0804ee5f146f8aadc0.9419520446467049",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le devis correspond à la demande.",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "fiabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.603Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "jean-christophe.moisan@nantesmetropole.fr",
            "CustomerContactEmail": "jean-christophe.moisan@nantesmetropole.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MOISAN",
            "CustomerContactFirstName": "Jean-Christophe",
            "CustomerContactMobilePhone": "0676857627",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NANTES METROPOLE (USINE DE LA ROCHE)",
            "CustomerCompanyGroupName": "NANTES METROPOLE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223017384",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le devis correspond à la demande.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f03a4d77d291536cda",
        "extendedResponse": {
            "_id": "64088b6233d6a99b21e1f544",
            "submittedBody": {
                "id": "640819f03a4d77d291536cda0.0004652350413512085",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "C'est qui m'a plus c'est de recevoir la date de livraison rapidement par rapport à l'envoi de ma commande.\nC'est qui me plairait plus c'est de recevoir un seul mail et non deux pour avec AR et date de livraison.",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "paula.kern@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e82bd78c-80c2-46d0-b71c-28f90e3e3843",
            "responseId": "640819f03a4d77d291536cda",
            "receivedResponse": {
                "id": "640819f03a4d77d291536cda0.0004652350413512085",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "C'est qui m'a plus c'est de recevoir la date de livraison rapidement par rapport à l'envoi de ma commande.\nC'est qui me plairait plus c'est de recevoir un seul mail et non deux pour avec AR et date de livraison.",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.647Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "paula.kern@suez.com",
            "CustomerContactEmail": "paula.kern@suez.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "KERN",
            "CustomerContactFirstName": "Paula",
            "CustomerContactMobilePhone": "0686077211",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "140020234500498175",
            "XylemReference": "772065",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "C'est qui m'a plus c'est de recevoir la date de livraison rapidement par rapport à l'envoi de ma commande.\nC'est qui me plairait plus c'est de recevoir un seul mail et non deux pour avec AR et date de livraison.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f03a4d77d291536e22",
        "extendedResponse": {
            "_id": "64088b6333d6a99b21e1f545",
            "submittedBody": {
                "id": "640819f03a4d77d291536e220.9303846994133251",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité pour donner la date de livraison",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yannick.dubouillon@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8fc5b9a0-8c9a-471d-8f1b-100fc26d9392",
            "responseId": "640819f03a4d77d291536e22",
            "receivedResponse": {
                "id": "640819f03a4d77d291536e220.9303846994133251",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité pour donner la date de livraison",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.738Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "yannick.dubouillon@suez.com",
            "CustomerContactEmail": "yannick.dubouillon@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUBOUILLON",
            "CustomerContactFirstName": "Yannick",
            "CustomerContactMobilePhone": "0789827490",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEVESC",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "92TPLA123000621/0",
            "XylemReference": "771882",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité pour donner la date de livraison",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f03a4d77d291536e9a",
        "extendedResponse": {
            "_id": "64088b6333d6a99b21e1f546",
            "submittedBody": {
                "id": "640819f03a4d77d291536e9a0.4525978108554818",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité et réactivité ( ce qui m'a plu).\nJe ne sais pas pourquoi, mais j'ai eu du mal à voir la date de livraison. Surement par manque d'habitude. Peut-être qu'une zone fluotée (couleur vive) permettrai de visualiser plus rapidement ces dates, mais c'est vraiment pour pinailler....\nBonne journée.",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c-reau@eauxdevienne.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "83b38850-4cd7-4e69-bdc9-4468187c6eec",
            "responseId": "640819f03a4d77d291536e9a",
            "receivedResponse": {
                "id": "640819f03a4d77d291536e9a0.4525978108554818",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité et réactivité ( ce qui m'a plu).\nJe ne sais pas pourquoi, mais j'ai eu du mal à voir la date de livraison. Surement par manque d'habitude. Peut-être qu'une zone fluotée (couleur vive) permettrai de visualiser plus rapidement ces dates, mais c'est vraiment pour pinailler....\nBonne journée.",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.789Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "c-reau@eauxdevienne.fr",
            "CustomerContactEmail": "c-reau@eauxdevienne.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REAU",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE VIENNE - SIVEER",
            "CustomerCompanyGroupName": "EAUX DE VIENNE - S.I.V.E.E.R.",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Romain LEPRINCE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "2023050948",
            "XylemReference": "772375",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité et réactivité ( ce qui m'a plu).\nJe ne sais pas pourquoi, mais j'ai eu du mal à voir la date de livraison. Surement par manque d'habitude. Peut-être qu'une zone fluotée (couleur vive) permettrai de visualiser plus rapidement ces dates, mais c'est vraiment pour pinailler....\nBonne journée.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f0804ee5f146f8addc",
        "extendedResponse": {
            "_id": "64088b6333d6a99b21e1f547",
            "submittedBody": {
                "id": "640819f0804ee5f146f8addc0.004275514236268929",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Arrêtez de m'envoyer ce type de message à 6h15",
                "creationDate": "2023-03-08T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.cros@eloa-bassin-arcachon.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "d10d91ac-ed6e-420f-ae23-07d085b4143a",
            "responseId": "640819f0804ee5f146f8addc",
            "receivedResponse": {
                "id": "640819f0804ee5f146f8addc0.004275514236268929",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Arrêtez de m'envoyer ce type de message à 6h15",
                "creationDate": "2023-03-08T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-08T05:15:28.937Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "eric.cros@eloa-bassin-arcachon.fr",
            "CustomerContactEmail": "eric.cros@eloa-bassin-arcachon.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CROS",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "0603562076",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELOA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018117",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Arrêtez de m'envoyer ce type de message à 6h15",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f1804ee5f146f8aedd",
        "extendedResponse": {
            "_id": "64146ac5b47a8ed707235b1e",
            "submittedBody": {
                "id": "640819f1804ee5f146f8aedd0.017130355398129682",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour depuis la Polynésie Française, Tahiti.\nJ'ai apprécié la réponse rapide , merci pour la réactivité.\nMerci aussi pour la retransmission des demandes venant  de notre zone.\nBonne continuation.",
                "creationDate": "2023-03-08T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ramon.ly@sopom.pf"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8fcbed1d-2e1d-4c50-81d8-287231095278",
            "responseId": "640819f1804ee5f146f8aedd",
            "receivedResponse": {
                "id": "640819f1804ee5f146f8aedd0.017130355398129682",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour depuis la Polynésie Française, Tahiti.\nJ'ai apprécié la réponse rapide , merci pour la réactivité.\nMerci aussi pour la retransmission des demandes venant de notre zone.\nBonne continuation.",
                "creationDate": "2023-03-08T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:29.098Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "ramon.ly@sopom.pf",
            "CustomerContactEmail": "ramon.ly@sopom.pf",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LY",
            "CustomerContactFirstName": "Ramon",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOPOM",
            "CustomerCompanyGroupName": "SIN TUNG HING MARINE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018161",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour depuis la Polynésie Française, Tahiti.\nJ'ai apprécié la réponse rapide , merci pour la réactivité.\nMerci aussi pour la retransmission des demandes venant  de notre zone.\nBonne continuation.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f1804ee5f146f8af7c",
        "extendedResponse": {
            "_id": "64088b6433d6a99b21e1f548",
            "submittedBody": {
                "id": "640819f1804ee5f146f8af7c0.2376490002860263",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "J'ai tout ce que j'ai besoin chez XYLEM : L'écoute de mes besoins, le service, les prix très compétitif, et la qualité du matériel.",
                "creationDate": "2023-03-08T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrice.perrenoud@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "93d4dc7b-4f0b-4fe5-a875-b02ba5b4a55a",
            "responseId": "640819f1804ee5f146f8af7c",
            "receivedResponse": {
                "id": "640819f1804ee5f146f8af7c0.2376490002860263",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "J'ai tout ce que j'ai besoin chez XYLEM : L'écoute de mes besoins, le service, les prix très compétitif, et la qualité du matériel.",
                "creationDate": "2023-03-08T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.pos",
                                    "label": "Price (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.pos",
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.pos",
                    "label": "Price (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:29.215Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "patrice.perrenoud@dalkia.fr",
            "CustomerContactEmail": "patrice.perrenoud@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PERRENOUD",
            "CustomerContactFirstName": "PATRICE",
            "CustomerContactMobilePhone": "0677165806",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA EST",
            "CustomerCompanyGroupName": "DALKIA groupe VEOLIA (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "EHRENFELD",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "EHRENFELD",
            "XylemContactedPersonRole": "Responsable régional ventes génie climatique",
            "CustomerReference": "",
            "XylemReference": "223018288",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "J'ai tout ce que j'ai besoin chez XYLEM : L'écoute de mes besoins, le service, les prix très compétitif, et la qualité du matériel.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f1804ee5f146f8af83",
        "extendedResponse": {
            "_id": "64088b6433d6a99b21e1f549",
            "submittedBody": {
                "id": "640819f1804ee5f146f8af830.1409784675808472",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très réactif et professionnel",
                "creationDate": "2023-03-08T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "m.beller@evac-eau.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d6e444fd-8afd-4e5f-a3ce-34a6f87e1efa",
            "responseId": "640819f1804ee5f146f8af83",
            "receivedResponse": {
                "id": "640819f1804ee5f146f8af830.1409784675808472",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très réactif et professionnel",
                "creationDate": "2023-03-08T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:29.235Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "m.beller@evac-eau.com",
            "CustomerContactEmail": "m.beller@evac-eau.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BELLER",
            "CustomerContactFirstName": "Martin",
            "CustomerContactMobilePhone": "0686410356",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EVAC EAU",
            "CustomerCompanyGroupName": "EVAC EAU",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Benoit",
            "XylemContactedPersonName": "BOLTZ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223018301",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très réactif et professionnel",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f1804ee5f146f8b008",
        "extendedResponse": {
            "_id": "64088b6433d6a99b21e1f54a",
            "submittedBody": {
                "id": "640819f1804ee5f146f8b0080.31607988857133984",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse",
                "creationDate": "2023-03-08T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rl.augee@hotmail.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "88ad31f1-c38d-4c09-a5fa-0d2d27229eb5",
            "responseId": "640819f1804ee5f146f8b008",
            "receivedResponse": {
                "id": "640819f1804ee5f146f8b0080.31607988857133984",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse",
                "creationDate": "2023-03-08T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-08T05:15:29.389Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "rl.augee@hotmail.fr",
            "CustomerContactEmail": "rl.augee@hotmail.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "AUGEE",
            "CustomerContactFirstName": "Rene-Louis",
            "CustomerContactMobilePhone": "0631119552",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NORIASYS POMPAGE",
            "CustomerCompanyGroupName": "NORIASYS POMPAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Benoit",
            "XylemContactedPersonName": "BOLTZ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223018403",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640819f1804ee5f146f8b009",
        "extendedResponse": {
            "_id": "64088b6533d6a99b21e1f54b",
            "submittedBody": {
                "id": "640819f1804ee5f146f8b0090.4310655717997418",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2023-03-08T06:15:29+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arnaud.silhouette@herve-thermique.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "883ca995-d4bd-4de3-8ca5-766d668e67d0",
            "responseId": "640819f1804ee5f146f8b009",
            "receivedResponse": {
                "id": "640819f1804ee5f146f8b0090.4310655717997418",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2023-03-08T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-08T05:15:29.392Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-08_06.15.09",
        "integration": {
            "ContactKey": "arnaud.silhouette@herve-thermique.com",
            "CustomerContactEmail": "arnaud.silhouette@herve-thermique.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SILHOUETTE",
            "CustomerContactFirstName": "Arnaud",
            "CustomerContactMobilePhone": "0682805745",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HERVE THERMIQUE",
            "CustomerCompanyGroupName": "HERVE THERMIQUE BOURGES",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "BOUZIGON",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223018405",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b724654cbe955061cbb",
        "extendedResponse": {
            "_id": "64146ac5b47a8ed707235b1f",
            "submittedBody": {
                "id": "64096b724654cbe955061cbb0.49023181583979847",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "3ème changement de date de livraison..... comment expliquer cela à mon client...",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "mhamon@ovive.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "4c143401-c676-4e9c-be6c-473ca0cebb57",
            "responseId": "64096b724654cbe955061cbb",
            "receivedResponse": {
                "id": "64096b724654cbe955061cbb0.49023181583979847",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "3ème changement de date de livraison..... comment expliquer cela à mon client...",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.371Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "mhamon@ovive.fr",
            "CustomerContactEmail": "mhamon@ovive.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HAMON",
            "CustomerContactFirstName": "MAXIME",
            "CustomerContactMobilePhone": "0788837894",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OVIVE",
            "CustomerCompanyGroupName": "OVIVE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "MT-LATRINI-070223-MHAM",
            "XylemReference": "768141",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "3ème changement de date de livraison..... comment expliquer cela à mon client...",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f163",
        "extendedResponse": {
            "_id": "64146ac5b47a8ed707235b20",
            "submittedBody": {
                "id": "64096b72f97abcf24334f1630.5198310595277165",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la reactivité",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lalemaire@soc.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "f39e4d4b-6a63-42c4-81f3-1bb25051fb7e",
            "responseId": "64096b72f97abcf24334f163",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f1630.5198310595277165",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.407Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "lalemaire@soc.fr",
            "CustomerContactEmail": "lalemaire@soc.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEMAIRE",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0658291741",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOC RHONE ALPES / SUD-EST (HYDROLIA)",
            "CustomerCompanyGroupName": "SOC",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018612",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la reactivité",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "4\n"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b724654cbe955061d26",
        "extendedResponse": {
            "_id": "64146ac6b47a8ed707235b21",
            "submittedBody": {
                "id": "64096b724654cbe955061d260.6759510437972005",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les message à 06H15 du matin m'oblige à ne plus commander du xylem",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0682594894"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "6a6d1f57-f0bb-4370-bf0f-e72f1014ec55",
            "responseId": "64096b724654cbe955061d26",
            "receivedResponse": {
                "id": "64096b724654cbe955061d260.6759510437972005",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les message à 06H15 du matin m'oblige à ne plus commander du xylem",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.426Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "0682594894",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BARIOULET JOFFREY",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0682594894",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "97TPLA123002499/0",
            "XylemReference": "202565",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les message à 06H15 du matin m'oblige à ne plus commander du xylem",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"phone\": \"0682594894\",\n  \"name\": \"Barioulet \",\n  \"mail\": \"Joffrey.barioulet@suez.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f18d",
        "extendedResponse": {
            "_id": "64146ac6b47a8ed707235b22",
            "submittedBody": {
                "id": "64096b72f97abcf24334f18d0.8878049605404987",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "les retour de demandes d'offre sont rapide ",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.gerberon@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "30ff05d9-3ff5-4ed1-83eb-41140d82fdad",
            "responseId": "64096b72f97abcf24334f18d",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f18d0.8878049605404987",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "les retour de demandes d'offre sont rapide ",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.485Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "philippe.gerberon@veolia.com",
            "CustomerContactEmail": "philippe.gerberon@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GERBERON",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0612853814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Corinne",
            "XylemContactedPersonName": "LAQUAINE",
            "XylemContactedPersonRole": "Technico-commerciale pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223018415",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "les retour de demandes d'offre sont rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b724654cbe955061d7b",
        "extendedResponse": {
            "_id": "64146ac7b47a8ed707235b23",
            "submittedBody": {
                "id": "64096b724654cbe955061d7b0.6705222008376943",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "les délais trop long, pas de solution apporté a notre problème de réacteur UV . les pieces ne ce fabriquent plus. quand j appel personne ne sais.",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.rault@emeriadinard.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "dab1b498-cd96-4cae-a459-26edcee1f21e",
            "responseId": "64096b724654cbe955061d7b",
            "receivedResponse": {
                "id": "64096b724654cbe955061d7b0.6705222008376943",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "les délais trop long, pas de solution apporté a notre problème de réacteur UV . les pièces ne ce fabriquent plus. quand j appel personne ne sais.",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.510Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "s.rault@emeriadinard.com",
            "CustomerContactEmail": "s.rault@emeriadinard.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "THALASSA DINARD",
            "CustomerCompanyGroupName": "NOVOTEL THALASSA DINARD",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Simon",
            "XylemSalespeopleName": "CLEMENT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 17/02/2023 - Devis 223013252",
            "XylemReference": "769710",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "les délais trop long, pas de solution apporté a notre problème de réacteur UV . les pieces ne ce fabriquent plus. quand j appel personne ne sais.",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"stephane RAULT\",\n  \"phone\": \"02 99 16 78 17\",\n  \"mail\": \"direction.technique@emeriadinard.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b724654cbe955061d81",
        "extendedResponse": {
            "_id": "64146ac7b47a8ed707235b24",
            "submittedBody": {
                "id": "64096b724654cbe955061d810.27836550010154393",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapide de la demande de devis à la commande",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.couty@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "37d3d21d-4028-4ca0-9859-da93266253fd",
            "responseId": "64096b724654cbe955061d81",
            "receivedResponse": {
                "id": "64096b724654cbe955061d810.27836550010154393",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapide de la demande de devis à la commande",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.517Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "frederic.couty@suez.com",
            "CustomerContactEmail": "frederic.couty@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COUTY",
            "CustomerContactFirstName": "FREDERIC",
            "CustomerContactMobilePhone": "0688275517",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ 81",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "86TPLA123003474/0",
            "XylemReference": "772464",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapide de la demande de devis à la commande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f1a2",
        "extendedResponse": {
            "_id": "64146ac7b47a8ed707235b25",
            "submittedBody": {
                "id": "64096b72f97abcf24334f1a20.355849351728742",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les +\nLa rapidité dans le retour du devis.\nLa simplicité des echanges\nLes -\nLe coût",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "v.houzan@groupe-seche.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "404a6b08-6bf2-43ac-a6b4-e023cc369871",
            "responseId": "64096b72f97abcf24334f1a2",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f1a20.355849351728742",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les +\nLa rapidité dans le retour du devis.\nLa simplicité des échanges\nLes -\nLe coût",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.527Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "v.houzan@groupe-seche.com",
            "CustomerContactEmail": "v.houzan@groupe-seche.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HOUZAN",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0635562512",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SECHE ECO SERVICES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018434",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les +\nLa rapidité dans le retour du devis.\nLa simplicité des echanges\nLes -\nLe coût",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b724654cbe955061dde",
        "extendedResponse": {
            "_id": "64146ac8b47a8ed707235b26",
            "submittedBody": {
                "id": "64096b724654cbe955061dde0.628777114990045",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Toujours un interlocuteur disponible",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.blanco@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "620b2577-d831-4368-a069-52bfc3d3def8",
            "responseId": "64096b724654cbe955061dde",
            "receivedResponse": {
                "id": "64096b724654cbe955061dde0.628777114990045",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Toujours un interlocuteur disponible",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.605Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "olivier.blanco@veolia.com",
            "CustomerContactEmail": "olivier.blanco@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BLANCO",
            "CustomerContactFirstName": "OLIVIER",
            "CustomerContactMobilePhone": "0626962234",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA  EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3710103",
            "XylemReference": "772499",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Toujours un interlocuteur disponible",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b724654cbe955061e15",
        "extendedResponse": {
            "_id": "64146ac8b47a8ed707235b27",
            "submittedBody": {
                "id": "64096b724654cbe955061e150.8672924218297275",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Est-il nécessaire de m'envoyer un SMS à 6h00 du matin pour votre enquête ????",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0660084696"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "d818e085-e948-4d34-a614-cc85058890cb",
            "responseId": "64096b724654cbe955061e15",
            "receivedResponse": {
                "id": "64096b724654cbe955061e150.8672924218297275",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Est-il nécessaire de m'envoyer un SMS à 6h00 du matin pour votre enquête ????",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.654Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "0660084696",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Yann LAPRIE",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0660084696",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR 47",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000476697",
            "XylemReference": "204192",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Est-il nécessaire de m'envoyer un SMS à 6h00 du matin pour votre enquête ????",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f20a",
        "extendedResponse": {
            "_id": "64146ac9b47a8ed707235b28",
            "submittedBody": {
                "id": "64096b72f97abcf24334f20a0.15871338095194298",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de reponse",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.dubourg@veoliaeau.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9b3b3b7a-1b3d-47ea-aa1e-b9f3110ebea8",
            "responseId": "64096b72f97abcf24334f20a",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f20a0.15871338095194298",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de réponse",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.718Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "david.dubourg@veoliaeau.fr",
            "CustomerContactEmail": "david.dubourg@veoliaeau.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUBOURG",
            "CustomerContactFirstName": "DAVID",
            "CustomerContactMobilePhone": "0628671730",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU MARMANDE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018593",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de reponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f20c",
        "extendedResponse": {
            "_id": "64146ac9b47a8ed707235b29",
            "submittedBody": {
                "id": "64096b72f97abcf24334f20c0.37154634749869975",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien à dire!",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bobinage.duclos@orange.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "566792fe-f32c-4414-aab4-051ae97f0506",
            "responseId": "64096b72f97abcf24334f20c",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f20c0.37154634749869975",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien à dire!",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.722Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "bobinage.duclos@orange.fr",
            "CustomerContactEmail": "bobinage.duclos@orange.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DUCLOS",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "0670586219",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SA DUCLOS BOBINAGE",
            "CustomerCompanyGroupName": "SAS BOBINAGE DUCLOS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018592",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien à dire!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f218",
        "extendedResponse": {
            "_id": "64146ac9b47a8ed707235b2a",
            "submittedBody": {
                "id": "64096b72f97abcf24334f2180.16994850162402408",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "OK",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.canevari@eridis.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "fe8e04d3-20c7-4e98-8a4e-178f398dd07d",
            "responseId": "64096b72f97abcf24334f218",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f2180.16994850162402408",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "OK",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.741Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "philippe.canevari@eridis.fr",
            "CustomerContactEmail": "philippe.canevari@eridis.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CANEVARI",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0684830448",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ERIDIS",
            "CustomerCompanyGroupName": "ERIDIS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Michel",
            "XylemSalespeopleName": "ALBRECHT",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018613",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "OK",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f220",
        "extendedResponse": {
            "_id": "64146acab47a8ed707235b2b",
            "submittedBody": {
                "id": "64096b72f97abcf24334f2200.5237760481141029",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité rapidité  ",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "b.garcin@lestechniciensdeleau.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ec0b3caf-55d1-4c63-a6aa-ef77e0e0a3a7",
            "responseId": "64096b72f97abcf24334f220",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f2200.5237760481141029",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité rapidité ",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.755Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "b.garcin@lestechniciensdeleau.com",
            "CustomerContactEmail": "b.garcin@lestechniciensdeleau.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GARCIN",
            "CustomerContactFirstName": "Benoît",
            "CustomerContactMobilePhone": "0649166409",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LES TECHNICIENS DE L'EAU",
            "CustomerCompanyGroupName": "LES TECHNICIENS DE L’EAU",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Joachim",
            "XylemSalespeopleName": "GAUDON",
            "XylemContactedPersonFirstName": "Joachim",
            "XylemContactedPersonName": "GAUDON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018622",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité rapidité  ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f23a",
        "extendedResponse": {
            "_id": "64146acab47a8ed707235b2c",
            "submittedBody": {
                "id": "64096b72f97abcf24334f23a0.043536263843570344",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour\nréponse rapide a ma demande par mail( bien!) \ndélais noté sur devis \"dispo ce jour\" et \"Transport : Livraison Métropole le lendemain pour commande validée avant 13h\".\nune fois commandé, délais passe à 7 jours en confirmation ; (moins bien)\ndu coup, port express qui ne sert a rien puisque venant d'Italie, et j'ai dû en acheter une autre  d'une autre marque pour dépanner rapidement le client. Du coup , je vais avoir cette pompe probablement en stock pour rien si mon client refuse que j'y revienne une deuxième fois\nPhilippe TRUXA",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sav@brouillon-process.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "8dd8f9d7-699d-417e-b917-998f75bb8593",
            "responseId": "64096b72f97abcf24334f23a",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f23a0.043536263843570344",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour\nréponse rapide a ma demande par mail( bien!) \ndélais noté sur devis \"dispo ce jour\" et \"Transport : Livraison Métropole le lendemain pour commande validée avant 13h\".\nune fois commandé, délais passe à 7 jours en confirmation ; (moins bien)\ndu coup, port express qui ne sert a rien puisque venant d'Italie, et j'ai dû en acheter une autre d'une autre marque pour dépanner rapidement le client. Du coup , je vais avoir cette pompe probablement en stock pour rien si mon client refuse que j'y revienne une deuxième fois\nPhilippe TRUXA",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "refus.all",
                                    "label": "Refusal (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "dispo.pos",
                "delai.pos",
                "refus.all"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "refus.all",
                    "label": "Refusal (neg)",
                    "suffix": "all"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.800Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "sav@brouillon-process.fr",
            "CustomerContactEmail": "sav@brouillon-process.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TRUXA",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BROUILLON PROCESS SA",
            "CustomerCompanyGroupName": "BROUILLON PROCESS SA",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Jorge",
            "XylemContactedPersonName": "DE OLIVEIRA",
            "XylemContactedPersonRole": "Ingénieur produits solutions",
            "CustomerReference": "",
            "XylemReference": "223018653",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour\nréponse rapide a ma demande par mail( bien!) \ndélais noté sur devis \"dispo ce jour\" et \"Transport : Livraison Métropole le lendemain pour commande validée avant 13h\".\nune fois commandé, délais passe à 7 jours en confirmation ; (moins bien)\ndu coup, port express qui ne sert a rien puisque venant d'Italie, et j'ai dû en acheter une autre  d'une autre marque pour dépanner rapidement le client. Du coup , je vais avoir cette pompe probablement en stock pour rien si mon client refuse que j'y revienne une deuxième fois\nPhilippe TRUXA",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"mail\": \"sav@brouillon-process.fr\",\n  \"name\": \"Philippe Truxa\",\n  \"phone\": \"055320989183\"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f28d",
        "extendedResponse": {
            "_id": "64146acbb47a8ed707235b2d",
            "submittedBody": {
                "id": "64096b72f97abcf24334f28d0.7293705006802493",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité de mon interlocuteur lors d'une consultation de dernière minute \nLa description technique de l'offre ",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c.defert@tdefrance.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "3f39e058-31a6-4ebb-a350-1c19053bbe03",
            "responseId": "64096b72f97abcf24334f28d",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f28d0.7293705006802493",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité de mon interlocuteur lors d'une consultation de dernière minute \nLa description technique de l'offre ",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.893Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "c.defert@tdefrance.fr",
            "CustomerContactEmail": "c.defert@tdefrance.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEFERT",
            "CustomerContactFirstName": "Charlie",
            "CustomerContactMobilePhone": "0676757556",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "PREST'ASSAINISSEMENT",
            "CustomerCompanyGroupName": "TECHNICIENS DES EAUX",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Roberto",
            "XylemContactedPersonName": "FERNANDES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018741",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité de mon interlocuteur lors d'une consultation de dernière minute \nLa description technique de l'offre ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Maintenir cette dynamique dans la durée !"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f2ac",
        "extendedResponse": {
            "_id": "64146acbb47a8ed707235b2e",
            "submittedBody": {
                "id": "64096b72f97abcf24334f2ac0.15711922647751164",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "+ Réactivité et conseils.",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c-reau@eauxdevienne.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9c0a80ee-968c-4119-9403-68e4782bec32",
            "responseId": "64096b72f97abcf24334f2ac",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f2ac0.15711922647751164",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "+ Réactivité et conseils.",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.897Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "c-reau@eauxdevienne.fr",
            "CustomerContactEmail": "c-reau@eauxdevienne.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "REAU",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAUX DE VIENNE - SIVEER",
            "CustomerCompanyGroupName": "EAUX DE VIENNE - S.I.V.E.E.R.",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Rémi",
            "XylemContactedPersonName": "BOUQUIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223018744",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "+ Réactivité et conseils.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b72f97abcf24334f2f1",
        "extendedResponse": {
            "_id": "64146acbb47a8ed707235b2f",
            "submittedBody": {
                "id": "64096b72f97abcf24334f2f10.49153744398402766",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Retour rapide et réponse en adéquation ave la demande. Parfait",
                "creationDate": "2023-03-09T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fdesjardins@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "882d97b8-34ab-4b04-8984-d8ea9f331fdc",
            "responseId": "64096b72f97abcf24334f2f1",
            "receivedResponse": {
                "id": "64096b72f97abcf24334f2f10.49153744398402766",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Retour rapide et réponse en adéquation avez la demande. Parfait",
                "creationDate": "2023-03-09T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:30.920Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-09_06.15.08",
        "integration": {
            "ContactKey": "fdesjardins@lri-sodime.com",
            "CustomerContactEmail": "fdesjardins@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DESJARDINS",
            "CustomerContactFirstName": "Franck",
            "CustomerContactMobilePhone": "0684808073",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE - LRI SODIME",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018767",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Retour rapide et réponse en adéquation ave la demande. Parfait",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64096b734654cbe955062e51",
        "extendedResponse": {
            "_id": "64146accb47a8ed707235b30",
            "submittedBody": {
                "id": "64096b734654cbe955062e510.1244364130373623",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et professionnalisme comme toujours ",
                "creationDate": "2023-03-09T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vvantrimpont@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8c3fba10-7486-452a-a89e-8651e4bb8d0e",
            "responseId": "64096b734654cbe955062e51",
            "receivedResponse": {
                "id": "64096b734654cbe955062e510.1244364130373623",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et professionnalisme comme toujours ",
                "creationDate": "2023-03-09T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-09T05:15:31.382Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "vvantrimpont@gressier.net",
            "CustomerContactEmail": "vvantrimpont@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VANTRIMPONT",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "0626329823",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER ET FILS",
            "CustomerCompanyGroupName": "ETS GRESSIER ET FILS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Mickael",
            "XylemContactedPersonName": "AUBRY",
            "XylemContactedPersonRole": "TCS Location",
            "CustomerReference": "CF038766",
            "XylemReference": "771659",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et professionnalisme comme toujours ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf54654cbe955f4d356",
        "extendedResponse": {
            "_id": "64146acdb47a8ed707235b31",
            "submittedBody": {
                "id": "640abcf54654cbe955f4d3560.6259842823761075",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Professionnalisme, sympathie, à l'écoute, réactivité",
                "creationDate": "2023-03-10T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "j.mantovanelli@syndicat-crw.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e90f91a4-d81c-4643-8fa6-6271dca4a2b5",
            "responseId": "640abcf54654cbe955f4d356",
            "receivedResponse": {
                "id": "640abcf54654cbe955f4d3560.6259842823761075",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Professionnalisme, sympathie, à l'écoute, réactivité",
                "creationDate": "2023-03-10T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:33.321Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "j.mantovanelli@syndicat-crw.fr",
            "CustomerContactEmail": "j.mantovanelli@syndicat-crw.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MANTOVANELLI",
            "CustomerContactFirstName": "JONATHAN",
            "CustomerContactMobilePhone": "0672716946",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Syndicat Contrat Rivière Woigot (CRW) - BRIEY",
            "CustomerCompanyGroupName": "CONTRAT RIVIERE WOIGOT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Hugo",
            "XylemContactedPersonName": "DEBETTE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Professionnalisme, sympathie, à l'écoute, réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf558764372e2d48f03",
        "extendedResponse": {
            "_id": "64146acdb47a8ed707235b32",
            "submittedBody": {
                "id": "640abcf558764372e2d48f030.8240403446534412",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les délais de réponses des devis ainsi que les délais de livraisons.",
                "creationDate": "2023-03-10T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.franques@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "dcaa951a-0260-446e-acbd-070329eb77ba",
            "responseId": "640abcf558764372e2d48f03",
            "receivedResponse": {
                "id": "640abcf558764372e2d48f030.8240403446534412",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les délais de réponses des devis ainsi que les délais de livraisons.",
                "creationDate": "2023-03-10T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-03-10T05:15:33.367Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "christophe.franques@suez.com",
            "CustomerContactEmail": "christophe.franques@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "FRANQUES",
            "CustomerContactFirstName": "CHRISTOPHE",
            "CustomerContactMobilePhone": "0633031908",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ DEGREMONT SERVICE",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "140020234500496297",
            "XylemReference": "768676",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les délais de réponses des devis ainsi que les délais de livraisons.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf558764372e2d48f8d",
        "extendedResponse": {
            "_id": "64146aceb47a8ed707235b33",
            "submittedBody": {
                "id": "640abcf558764372e2d48f8d0.4038232666571282",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour.\nLe nouveau délai passant de début février à fin septembre me laisse perplex sur la meilleur formule pour vous répondre.\nTout s'explique mais juste un mail sans aucune explication, alors que l'engagement était prit.\nJe doute sur le changement suite à ce questionnaire...\nBonne journée.",
                "creationDate": "2023-03-10T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francois.guiho@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "494e03da-7c90-4671-b40d-9d099a7abeb6",
            "responseId": "640abcf558764372e2d48f8d",
            "receivedResponse": {
                "id": "640abcf558764372e2d48f8d0.4038232666571282",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour.\nLe nouveau délai passant de début février à fin septembre me laisse perplexe sur la meilleur formule pour vous répondre.\nTout s'explique mais juste un mail sans aucune explication, alors que l'engagement était prit.\nJe doute sur le changement suite à ce questionnaire...\nBonne journée.",
                "creationDate": "2023-03-10T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.neg",
                                    "label": "Attentiveness and availability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre",
                "joignabilite.neg",
                "ecoute.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ecoute.neg",
                    "label": "Attentiveness and availability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-10T05:15:33.484Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "francois.guiho@suez.com",
            "CustomerContactEmail": "francois.guiho@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUIHO",
            "CustomerContactFirstName": "François",
            "CustomerContactMobilePhone": "0620873818",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EPuRéo STEP TOUGAS",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "2RTPLA123000218/0",
            "XylemReference": "770282",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour.\nLe nouveau délai passant de début février à fin septembre me laisse perplex sur la meilleur formule pour vous répondre.\nTout s'explique mais juste un mail sans aucune explication, alors que l'engagement était prit.\nJe doute sur le changement suite à ce questionnaire...\nBonne journée.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"mail\": \"francois.guiho@suez.com\",\n  \"phone\": \"0000000000\",\n  \"name\": \"François Guiho\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf558764372e2d49090",
        "extendedResponse": {
            "_id": "64146aceb47a8ed707235b34",
            "submittedBody": {
                "id": "640abcf558764372e2d490900.5312340494312355",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai de prise en charge commande trop long par rapport à E-access",
                "creationDate": "2023-03-10T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.glatigny@aseos.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "2587d3e1-70eb-4bae-8cdf-bbe6c52d5488",
            "responseId": "640abcf558764372e2d49090",
            "receivedResponse": {
                "id": "640abcf558764372e2d490900.5312340494312355",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai de prise en charge commande trop long par rapport à E-access",
                "creationDate": "2023-03-10T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-10T05:15:33.602Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "thomas.glatigny@aseos.fr",
            "CustomerContactEmail": "thomas.glatigny@aseos.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GLATIGNY",
            "CustomerContactFirstName": "THOMAS",
            "CustomerContactMobilePhone": "0608529498",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ASEOS",
            "CustomerCompanyGroupName": "ASEOS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "1802",
            "XylemReference": "772693",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai de prise en charge commande trop long par rapport à E-access",
            "generalRating": "3",
            "improvementSuggestionsVerbatim": "Prendre en charge la commande le jour même ou le lendemain maximum "
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf558764372e2d491d3",
        "extendedResponse": {
            "_id": "64146acfb47a8ed707235b35",
            "submittedBody": {
                "id": "640abcf558764372e2d491d30.6867248189287289",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tout d'abord, quelle commande ?\nSi c'est pour  la commande qui date de fin octobre 2022 pour une livraison mars 2023, il n'y a rien de plaisant !",
                "creationDate": "2023-03-10T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bernadette.marle@otech40.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "8f665151-932b-450b-8fd4-d16b9ab75193",
            "responseId": "640abcf558764372e2d491d3",
            "receivedResponse": {
                "id": "640abcf558764372e2d491d30.6867248189287289",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tout d'abord, quelle commande ?\nSi c'est pour la commande qui date de fin octobre 2022 pour une livraison mars 2023, il n'y a rien de plaisant !",
                "creationDate": "2023-03-10T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-10T05:15:33.731Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "bernadette.marle@otech40.fr",
            "CustomerContactEmail": "bernadette.marle@otech40.fr",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OTECH 40",
            "CustomerCompanyGroupName": "OTECH 40",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF2300786",
            "XylemReference": "772998",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tout d'abord, quelle commande ?\nSi c'est pour  la commande qui date de fin octobre 2022 pour une livraison mars 2023, il n'y a rien de plaisant !",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf558764372e2d491de",
        "extendedResponse": {
            "_id": "64146acfb47a8ed707235b36",
            "submittedBody": {
                "id": "640abcf558764372e2d491de0.07993033354707313",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "délais sur l'offre non respecté.....d'ou complication commercial avec le client final",
                "creationDate": "2023-03-10T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gguillou@lefeuvre.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "50391028-7466-4040-b1f2-e12403143601",
            "responseId": "640abcf558764372e2d491de",
            "receivedResponse": {
                "id": "640abcf558764372e2d491de0.07993033354707313",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délais sur l'offre non respecté.....d'ou complication commercial avec le client final",
                "creationDate": "2023-03-10T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.neg",
                                    "label": "Welcome / Kindness (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg",
                "accueil.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "accueil.neg",
                    "label": "Welcome / Kindness (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-10T05:15:33.743Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "gguillou@lefeuvre.fr",
            "CustomerContactEmail": "gguillou@lefeuvre.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUILLOU",
            "CustomerContactFirstName": "Ghislain",
            "CustomerContactMobilePhone": "0623175331",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAS C LEFEUVRE / LE CAIGNARD",
            "CustomerCompanyGroupName": "LE CAIGNARD SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 08/03/2023 - Devis 223018285",
            "XylemReference": "772729",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "délais sur l'offre non respecté.....d'ou complication commercial avec le client final",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"guillou ghislain\",\n  \"phone\": \"0623175331\",\n  \"mail\": \"gguillou@lefeuvre.fr\"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf64654cbe955f4e387",
        "extendedResponse": {
            "_id": "64146acfb47a8ed707235b37",
            "submittedBody": {
                "id": "640abcf64654cbe955f4e3870.9430308690376112",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité et professionnalisme des interlocuteurs.\nPar contre pas cool le SMS de relance pour l'enquête le matin à 6h15.",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0637648686"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "8ec60df6-e1fb-4850-9033-f74fcd7e847c",
            "responseId": "640abcf64654cbe955f4e387",
            "receivedResponse": {
                "id": "640abcf64654cbe955f4e3870.9430308690376112",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité et professionnalisme des interlocuteurs.\nPar contre pas cool le SMS de relance pour l'enquête le matin à 6h15.",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.391Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-07_06.15.08",
        "integration": {
            "ContactKey": "0637648686",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "BUCHENAUD LAURENT",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0637648686",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SPEN VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "4108566618",
            "XylemReference": "204690",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité et professionnalisme des interlocuteurs.\nPar contre pas cool le SMS de relance pour l'enquête le matin à 6h15.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Pas le SMS à 6h15"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf658764372e2d4a116",
        "extendedResponse": {
            "_id": "64146ad0b47a8ed707235b38",
            "submittedBody": {
                "id": "640abcf658764372e2d4a1160.1478466693172169",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité ",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "julien.boschage@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "4ea2b7e2-c0f7-4f7e-b1c5-d4d47f6b72c7",
            "responseId": "640abcf658764372e2d4a116",
            "receivedResponse": {
                "id": "640abcf658764372e2d4a1160.1478466693172169",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité ",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.422Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-10_06.15.08",
        "integration": {
            "ContactKey": "julien.boschage@suez.com",
            "CustomerContactEmail": "julien.boschage@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOSCHAGE",
            "CustomerContactFirstName": "JULIEN",
            "CustomerContactMobilePhone": "0673303902",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Sylvie",
            "XylemContactedPersonName": "CORNE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018836",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité ",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Rencontrer de temps en temps un commercial XYLEM"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf658764372e2d4a156",
        "extendedResponse": {
            "_id": "64146ad0b47a8ed707235b39",
            "submittedBody": {
                "id": "640abcf658764372e2d4a1560.6668352475515158",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la reactivité m'a plu",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tjolivel@sofintec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "90411c9f-9256-4b8f-8a9b-e79f7e3cf6ab",
            "responseId": "640abcf658764372e2d4a156",
            "receivedResponse": {
                "id": "640abcf658764372e2d4a1560.6668352475515158",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité m'a plu",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.438Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-10_06.15.08",
        "integration": {
            "ContactKey": "tjolivel@sofintec.fr",
            "CustomerContactEmail": "tjolivel@sofintec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JOLIVEL",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTEC INDUSTRIES",
            "CustomerCompanyGroupName": "SOFINTEC (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223018854",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la reactivité m'a plu",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf658764372e2d4a1ad",
        "extendedResponse": {
            "_id": "64146ad0b47a8ed707235b3a",
            "submittedBody": {
                "id": "640abcf658764372e2d4a1ad0.5517314683292005",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "XYLEM na jamais perdu son efficacités",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "raymond.toupouti@sgde.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "214d857b-2708-4cb5-9e45-50f1b21d8fb1",
            "responseId": "640abcf658764372e2d4a1ad",
            "receivedResponse": {
                "id": "640abcf658764372e2d4a1ad0.5517314683292005",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "XYLEM na jamais perdu son efficacités",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.528Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-10_06.15.08",
        "integration": {
            "ContactKey": "raymond.toupouti@sgde.fr",
            "CustomerContactEmail": "raymond.toupouti@sgde.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TOUPOUTI",
            "CustomerContactFirstName": "RAYMOND",
            "CustomerContactMobilePhone": "0694264712",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GUYANAISE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018983",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "XYLEM na jamais perdu son efficacités",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf658764372e2d4a1bf",
        "extendedResponse": {
            "_id": "64146ad1b47a8ed707235b3b",
            "submittedBody": {
                "id": "640abcf658764372e2d4a1bf0.6429976835217708",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Aucun problème à signaler avec Xylem.  Quelques relances, un service globalement très satisfaisant. \nJ'aimerais qu'au retour de mail, on me rappelle la référence systématiquement de la demande.",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "patrice.richardeau@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a043f54d-fe17-4f8e-9d77-38d5e343d20a",
            "responseId": "640abcf658764372e2d4a1bf",
            "receivedResponse": {
                "id": "640abcf658764372e2d4a1bf0.6429976835217708",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Aucun problème à signaler avec Xylem. Quelques relances, un service globalement très satisfaisant. \nJ'aimerais qu'au retour de mail, on me rappelle la référence systématiquement de la demande.",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "efficacite.neg",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.564Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-10_06.15.08",
        "integration": {
            "ContactKey": "patrice.richardeau@veolia.com",
            "CustomerContactEmail": "patrice.richardeau@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RICHARDEAU",
            "CustomerContactFirstName": "Patrice",
            "CustomerContactMobilePhone": "0621400700",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "BONVALOT",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019046",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Aucun problème à signaler avec Xylem.  Quelques relances, un service globalement très satisfaisant. \nJ'aimerais qu'au retour de mail, on me rappelle la référence systématiquement de la demande.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf658764372e2d4a30d",
        "extendedResponse": {
            "_id": "64146ad1b47a8ed707235b3c",
            "submittedBody": {
                "id": "640abcf658764372e2d4a30d0.48906807070077685",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidite de reponse (trés bien)",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pierre.lequeux@avf-albi.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "12e42dac-79c5-4e4f-8d98-9c9627299181",
            "responseId": "640abcf658764372e2d4a30d",
            "receivedResponse": {
                "id": "640abcf658764372e2d4a30d0.48906807070077685",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de réponse (très bien)",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.783Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-10_06.15.08",
        "integration": {
            "ContactKey": "pierre.lequeux@avf-albi.com",
            "CustomerContactEmail": "pierre.lequeux@avf-albi.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEQUEUX",
            "CustomerContactFirstName": "Pierre",
            "CustomerContactMobilePhone": "0683889956",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AVF",
            "CustomerCompanyGroupName": "A.V.F.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Sebastien",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018907",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidite de reponse (trés bien)",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf658764372e2d4a30e",
        "extendedResponse": {
            "_id": "64146ad1b47a8ed707235b3d",
            "submittedBody": {
                "id": "640abcf658764372e2d4a30e0.26976068162101074",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDITE .\nMERCI ",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "annicet.pedro-lembe@eau-vapeur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e61982c2-1e59-4141-94ce-c6bc5d8c16e2",
            "responseId": "640abcf658764372e2d4a30e",
            "receivedResponse": {
                "id": "640abcf658764372e2d4a30e0.26976068162101074",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité .\nMERCI ",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.786Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-10_06.15.08",
        "integration": {
            "ContactKey": "annicet.pedro-lembe@eau-vapeur.fr",
            "CustomerContactEmail": "annicet.pedro-lembe@eau-vapeur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PEDRO-LEMBE",
            "CustomerContactFirstName": "Annicet",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAU ET VAPEUR",
            "CustomerCompanyGroupName": "EAU ET VAPEUR",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "ARNAUD",
            "XylemContactedPersonName": "JAMELIN",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018908",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDITE .\nMERCI ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640abcf658764372e2d4a36a",
        "extendedResponse": {
            "_id": "64146ad1b47a8ed707235b3e",
            "submittedBody": {
                "id": "640abcf658764372e2d4a36a0.058081890869189134",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bon accompagnement technique",
                "creationDate": "2023-03-10T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-claude.prost@sources.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "175f0341-844a-491f-a45a-1f15d457592f",
            "responseId": "640abcf658764372e2d4a36a",
            "receivedResponse": {
                "id": "640abcf658764372e2d4a36a0.058081890869189134",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bon accompagnement technique",
                "creationDate": "2023-03-10T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-10T05:15:34.815Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-10_06.15.08",
        "integration": {
            "ContactKey": "jean-claude.prost@sources.fr",
            "CustomerContactEmail": "jean-claude.prost@sources.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PROST",
            "CustomerContactFirstName": "Jean-Claude",
            "CustomerContactMobilePhone": "0613516382",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOURCES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Audrey",
            "XylemSalespeopleName": "CHAUVET",
            "XylemContactedPersonFirstName": "Audrey",
            "XylemContactedPersonName": "CHAUVET",
            "XylemContactedPersonRole": "Ingénieur Grands Projets",
            "CustomerReference": "",
            "XylemReference": "223018585",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bon accompagnement technique",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640c0e74b81e13a5efab020c",
        "extendedResponse": {
            "_id": "64146ad2b47a8ed707235b3f",
            "submittedBody": {
                "id": "640c0e74b81e13a5efab020c0.4651986643550199",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne qualité ",
                "creationDate": "2023-03-11T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "clement.perez@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a22c2c69-270c-4684-928f-f68a0faaa080",
            "responseId": "640c0e74b81e13a5efab020c",
            "receivedResponse": {
                "id": "640c0e74b81e13a5efab020c0.4651986643550199",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne qualité ",
                "creationDate": "2023-03-11T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-11T05:15:32.316Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-11_06.15.08",
        "integration": {
            "ContactKey": "clement.perez@veolia.com",
            "CustomerContactEmail": "clement.perez@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PEREZ",
            "CustomerContactFirstName": "CLEMENT",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019378",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne qualité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640c0e76b81e13a5efab0554",
        "extendedResponse": {
            "_id": "64146ad2b47a8ed707235b40",
            "submittedBody": {
                "id": "640c0e76b81e13a5efab05540.07676236817445825",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Envoi d'un devis sous délai court",
                "creationDate": "2023-03-11T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "b.daret@faure-technologies.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2ea56741-593f-46a6-a4e7-f9c240f4d0b6",
            "responseId": "640c0e76b81e13a5efab0554",
            "receivedResponse": {
                "id": "640c0e76b81e13a5efab05540.07676236817445825",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Envoi d'un devis sous délai court",
                "creationDate": "2023-03-11T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-11T05:15:34.317Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-11_06.15.08",
        "integration": {
            "ContactKey": "b.daret@faure-technologies.com",
            "CustomerContactEmail": "b.daret@faure-technologies.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DARET",
            "CustomerContactFirstName": "BRICE",
            "CustomerContactMobilePhone": "0616191678",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "FAURE TECHNOLOGIES",
            "CustomerCompanyGroupName": "FAURE TECHNOLOGIES",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Patrice",
            "XylemSalespeopleName": "PAPONE",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019404",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Envoi d'un devis sous délai court",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640c0e76b81e13a5efab05b3",
        "extendedResponse": {
            "_id": "64146ad2b47a8ed707235b41",
            "submittedBody": {
                "id": "640c0e76b81e13a5efab05b30.3975569016611824",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "commercial attentif a notre besoin et force de proposition",
                "creationDate": "2023-03-11T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "arlette.monny@groupe-coriance.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c5080598-dbc4-4c50-9229-cf77df6a9727",
            "responseId": "640c0e76b81e13a5efab05b3",
            "receivedResponse": {
                "id": "640c0e76b81e13a5efab05b30.3975569016611824",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "commercial attentif a notre besoin et force de proposition",
                "creationDate": "2023-03-11T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-11T05:15:34.626Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-11_06.15.08",
        "integration": {
            "ContactKey": "arlette.monny@groupe-coriance.fr",
            "CustomerContactEmail": "arlette.monny@groupe-coriance.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MONNY",
            "CustomerContactFirstName": "Arlette",
            "CustomerContactMobilePhone": "0750690303",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CORIANCE",
            "CustomerCompanyGroupName": "ENERGIE VERTE DE VAL D EUROPE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Gaetan",
            "XylemContactedPersonName": "BRETEAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223018758",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "commercial attentif a notre besoin et force de proposition",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640c0e78b81e13a5efab0726",
        "extendedResponse": {
            "_id": "64146ad3b47a8ed707235b42",
            "submittedBody": {
                "id": "640c0e78b81e13a5efab07260.6598933779423977",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de retour pour le devis",
                "creationDate": "2023-03-11T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hmijat@roannaise-de-leau.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d0ba0004-5078-4bab-ba2c-a632613f2c65",
            "responseId": "640c0e78b81e13a5efab0726",
            "receivedResponse": {
                "id": "640c0e78b81e13a5efab07260.6598933779423977",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de retour pour le devis",
                "creationDate": "2023-03-11T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-11T05:15:36.318Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-11_06.15.08",
        "integration": {
            "ContactKey": "hmijat@roannaise-de-leau.fr",
            "CustomerContactEmail": "hmijat@roannaise-de-leau.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MIJAT",
            "CustomerContactFirstName": "Hervé",
            "CustomerContactMobilePhone": "0643599920",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ROANNAISE DE L'EAU",
            "CustomerCompanyGroupName": "ROANNAIS AGGLOMERATION",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN",
            "XylemContactedPersonFirstName": "Nicolas",
            "XylemContactedPersonName": "BRUN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223019607",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de retour pour le devis",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "personne n'est parfait ;-)"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "640eb163304769802563fe06",
        "extendedResponse": {
            "_id": "64146ad3b47a8ed707235b43",
            "submittedBody": {
                "id": "640eb163304769802563fe060.47422787002522004",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Nous avons toujours un très bon échanges avec le commercial, il est à l écoute et surtout de très bons conseils ",
                "creationDate": "2023-03-13T06:15:15+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "contact@mobitechniques.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "808d48aa-8f93-4005-9cab-e27f1e47621e",
            "responseId": "640eb163304769802563fe06",
            "receivedResponse": {
                "id": "640eb163304769802563fe060.47422787002522004",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Nous avons toujours un très bon échanges avec le commercial, il est à l écoute et surtout de très bons conseils ",
                "creationDate": "2023-03-13T06:15:15Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-13T05:15:15.115Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-13_06.15.04",
        "integration": {
            "ContactKey": "contact@mobitechniques.fr",
            "CustomerContactEmail": "contact@mobitechniques.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DEGIORGI",
            "CustomerContactFirstName": "Vincent",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MOBITEC",
            "CustomerCompanyGroupName": "SARL MOBITEC",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Claude",
            "XylemContactedPersonName": "GUILLERMET",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Nous avons toujours un très bon échanges avec le commercial, il est à l écoute et surtout de très bons conseils ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f4ce66f81088d2e99b",
        "extendedResponse": {
            "_id": "64146ad3b47a8ed707235b44",
            "submittedBody": {
                "id": "641002f4ce66f81088d2e99b0.7945078736748614",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Nicolas est un Top Man :\nà l’écoute - professionnel  avec  la compétence  pour analyser et proposer la solution technique ",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "raymond.eberhard@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2e291a05-f9ee-4be6-a6f3-573b2fe4e5b4",
            "responseId": "641002f4ce66f81088d2e99b",
            "receivedResponse": {
                "id": "641002f4ce66f81088d2e99b0.7945078736748614",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Nicolas est un Top Man :\nà l écoute - professionnel avec la compétence pour analyser et proposer la solution technique ",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.055Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-13_06.15.04",
        "integration": {
            "ContactKey": "raymond.eberhard@veolia.com",
            "CustomerContactEmail": "raymond.eberhard@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "EBERHARD",
            "CustomerContactFirstName": "Raymond",
            "CustomerContactMobilePhone": "0612564639",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "THEBA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Nicolas",
            "XylemContactedPersonName": "SMEJ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Nicolas est un Top Man :\nà l’écoute - professionnel  avec  la compétence  pour analyser et proposer la solution technique ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f4ce66f81088d2e9a4",
        "extendedResponse": {
            "_id": "64146ad3b47a8ed707235b45",
            "submittedBody": {
                "id": "641002f4ce66f81088d2e9a40.6076299145640449",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "merci pour la rapidité ",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "annicet.pedro-lembe@eau-vapeur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e13a412d-efca-4aa9-98f9-e96567dc3e51",
            "responseId": "641002f4ce66f81088d2e9a4",
            "receivedResponse": {
                "id": "641002f4ce66f81088d2e9a40.6076299145640449",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "merci pour la rapidité ",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.070Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-13_06.15.04",
        "integration": {
            "ContactKey": "annicet.pedro-lembe@eau-vapeur.fr",
            "CustomerContactEmail": "annicet.pedro-lembe@eau-vapeur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PEDRO-LEMBE",
            "CustomerContactFirstName": "Annicet",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAU ET VAPEUR",
            "CustomerCompanyGroupName": "EAU ET VAPEUR",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "merci pour la rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f45a14491c0e53af15",
        "extendedResponse": {
            "_id": "64146ad4b47a8ed707235b46",
            "submittedBody": {
                "id": "641002f45a14491c0e53af150.9909627052382988",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Efficacité dans la mise en service du matériel en toute sécurité",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "regis.rohou@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2804f141-be12-472f-8ffb-719895db17ca",
            "responseId": "641002f45a14491c0e53af15",
            "receivedResponse": {
                "id": "641002f45a14491c0e53af150.9909627052382988",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Efficacité dans la mise en service du matériel en toute sécurité",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.077Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "regis.rohou@veolia.com",
            "CustomerContactEmail": "regis.rohou@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROHOU",
            "CustomerContactFirstName": "REGIS",
            "CustomerContactMobilePhone": "0627077045",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA  AGENCE PAYS DE RENNES",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Pierre-François",
            "XylemContactedPersonName": "CLEMENT",
            "XylemContactedPersonRole": "Charge d'affaires Service & Location",
            "CustomerReference": "CZZ3658628",
            "XylemReference": "763888",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Efficacité dans la mise en service du matériel en toute sécurité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f4ce66f81088d2e9bc",
        "extendedResponse": {
            "_id": "64146ad4b47a8ed707235b47",
            "submittedBody": {
                "id": "641002f4ce66f81088d2e9bc0.5788670713419304",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Expertise, réactivité",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.ledain@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c42d4763-56f5-445f-b200-10a22c457b6b",
            "responseId": "641002f4ce66f81088d2e9bc",
            "receivedResponse": {
                "id": "641002f4ce66f81088d2e9bc0.5788670713419304",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Expertise, réactivité",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.117Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-13_06.15.04",
        "integration": {
            "ContactKey": "olivier.ledain@eiffage.com",
            "CustomerContactEmail": "olivier.ledain@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE DAIN",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "0693930493",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDROTECH SARL",
            "CustomerCompanyGroupName": "HYDROTECH",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN DT",
            "XylemContactedPersonFirstName": "Erwan",
            "XylemContactedPersonName": "CRAQUELIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Expertise, réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f4ce66f81088d2e9fc",
        "extendedResponse": {
            "_id": "64146ad5b47a8ed707235b48",
            "submittedBody": {
                "id": "641002f4ce66f81088d2e9fc0.5521247260169673",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bon échange m'a permis d'être un peut plus autonome sur vos produits",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bbigot@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "c34235e7-d0e9-43d4-946a-0a4f6dfb9a28",
            "responseId": "641002f4ce66f81088d2e9fc",
            "receivedResponse": {
                "id": "641002f4ce66f81088d2e9fc0.5521247260169673",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bon échange m'a permis d'être un peut plus autonome sur vos produits",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.191Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-13_06.15.04",
        "integration": {
            "ContactKey": "bbigot@lri-sodime.com",
            "CustomerContactEmail": "bbigot@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BIGOT",
            "CustomerContactFirstName": "Bruno",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Thed",
            "XylemContactedPersonName": "SITA",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bon échange m'a permis d'être un peut plus autonome sur vos produits",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "rien 5 c'est de l'exception... "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f458764372e257efa1",
        "extendedResponse": {
            "_id": "64146ad5b47a8ed707235b49",
            "submittedBody": {
                "id": "641002f458764372e257efa10.5092339046341403",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité et traitement de ma demande dans les plus bref délai, très bonne appréciation.\nmerci.",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jl.petit@cacg.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "0bf38c12-aecd-4147-89a2-4961a8304137",
            "responseId": "641002f458764372e257efa1",
            "receivedResponse": {
                "id": "641002f458764372e257efa10.5092339046341403",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité et traitement de ma demande dans les plus bref délai, très bonne appréciation.\nmerci.",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.252Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "jl.petit@cacg.fr",
            "CustomerContactEmail": "jl.petit@cacg.fr",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CACG",
            "CustomerCompanyGroupName": "CIE D'AMENAGEM. DES COTEAUX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Paul-Justin",
            "XylemContactedPersonName": "SAINT ELOI",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019922",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité et traitement de ma demande dans les plus bref délai, très bonne appréciation.\nmerci.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f45a14491c0e53b04a",
        "extendedResponse": {
            "_id": "64146ad5b47a8ed707235b4a",
            "submittedBody": {
                "id": "641002f45a14491c0e53b04a0.4017637268931902",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la disponibilité et la  réactivité  ",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "abc.atelier@luvica.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "e4ce009b-f825-4648-887a-c0cd8420cb77",
            "responseId": "641002f45a14491c0e53b04a",
            "receivedResponse": {
                "id": "641002f45a14491c0e53b04a0.4017637268931902",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la disponibilité et la réactivité ",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.268Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "abc.atelier@luvica.fr",
            "CustomerContactEmail": "abc.atelier@luvica.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PERON",
            "CustomerContactFirstName": "Aurélien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ABC DISTRIBUTION",
            "CustomerCompanyGroupName": "Groupe LUVICA",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "BRUN",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "XCA23-0178",
            "XylemReference": "773264",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la disponibilité et la  réactivité  ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f45a14491c0e53b05f",
        "extendedResponse": {
            "_id": "64146ad6b47a8ed707235b4b",
            "submittedBody": {
                "id": "641002f45a14491c0e53b05f0.19499490663206176",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "A",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0672528415"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0e61feb3-3e97-4611-b4bc-ba7a16659822",
            "responseId": "641002f45a14491c0e53b05f",
            "receivedResponse": {
                "id": "641002f45a14491c0e53b05f0.19499490663206176",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "A",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.292Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "0672528415",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "OLIVIER Sebastien",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0672528415",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ORPEO Environnement",
            "CustomerCompanyGroupName": "ORPEO ENVIRONNEMENT",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "80323/2",
            "XylemReference": "205898",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "A",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f458764372e257effb",
        "extendedResponse": {
            "_id": "64146ad6b47a8ed707235b4c",
            "submittedBody": {
                "id": "641002f458764372e257effb0.6052927737010765",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de traitement",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "m.praderie@o-pure.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b0a9bc6b-4f84-47a0-8431-3847b22e7f2c",
            "responseId": "641002f458764372e257effb",
            "receivedResponse": {
                "id": "641002f458764372e257effb0.6052927737010765",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de traitement",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.366Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "m.praderie@o-pure.fr",
            "CustomerContactEmail": "m.praderie@o-pure.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PRADERIE",
            "CustomerContactFirstName": "Mathieu",
            "CustomerContactMobilePhone": "0607233453",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OPURE",
            "CustomerCompanyGroupName": "OPURE - AGENCE SUD",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Cédric",
            "XylemSalespeopleName": "ROYER",
            "XylemContactedPersonFirstName": "Jean-Michel",
            "XylemContactedPersonName": "ROUILLE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019651",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de traitement",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f45a14491c0e53b0bc",
        "extendedResponse": {
            "_id": "64146ad7b47a8ed707235b4d",
            "submittedBody": {
                "id": "641002f45a14491c0e53b0bc0.6334598107303118",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "ras",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.gerberon@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "eb831dfa-d875-4bbe-82ce-72fccf741da8",
            "responseId": "641002f45a14491c0e53b0bc",
            "receivedResponse": {
                "id": "641002f45a14491c0e53b0bc0.6334598107303118",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "ras",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.428Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "philippe.gerberon@veolia.com",
            "CustomerContactEmail": "philippe.gerberon@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GERBERON",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0612853814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3714516",
            "XylemReference": "773273",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "ras",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f45a14491c0e53b42e",
        "extendedResponse": {
            "_id": "64146ad7b47a8ed707235b4e",
            "submittedBody": {
                "id": "641002f45a14491c0e53b42e0.6793951490886974",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Non respect des délais annoncés ",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "t.trajkovski@arep86.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "e03006bf-3f72-4bda-be2e-49b0f1530629",
            "responseId": "641002f45a14491c0e53b42e",
            "receivedResponse": {
                "id": "641002f45a14491c0e53b42e0.6793951490886974",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Non respect des délais annoncés ",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.676Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "t.trajkovski@arep86.com",
            "CustomerContactEmail": "t.trajkovski@arep86.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TRAJKOVSKI",
            "CustomerContactFirstName": "TONY",
            "CustomerContactMobilePhone": "0627465151",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "AREP SARL",
            "CustomerCompanyGroupName": "AREP SARL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Michael",
            "XylemSalespeopleName": "MOREAU",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Romain LEPRINCE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF00001990",
            "XylemReference": "773501",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Non respect des délais annoncés ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f458764372e257f346",
        "extendedResponse": {
            "_id": "64146ad7b47a8ed707235b4f",
            "submittedBody": {
                "id": "641002f458764372e257f3460.7156397965492793",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonne réactivité et technicité.\nForce de proposition\nMerci",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tech-nord@epureau.nc"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "18541572-2cea-400d-824b-30de43bc36c7",
            "responseId": "641002f458764372e257f346",
            "receivedResponse": {
                "id": "641002f458764372e257f3460.7156397965492793",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonne réactivité et technicité.\nForce de proposition\nMerci",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.759Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "tech-nord@epureau.nc",
            "CustomerContactEmail": "tech-nord@epureau.nc",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ROSTAN",
            "CustomerContactFirstName": "Florent",
            "CustomerContactMobilePhone": "0687781193",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EPUREAU",
            "CustomerCompanyGroupName": "EPUREAU",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019790",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonne réactivité et technicité.\nForce de proposition\nMerci",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f458764372e257f3dc",
        "extendedResponse": {
            "_id": "64146ad8b47a8ed707235b50",
            "submittedBody": {
                "id": "641002f458764372e257f3dc0.8305543429592355",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "excellent ",
                "creationDate": "2023-03-14T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "annicet.pedro-lembe@eau-vapeur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b2e9d67c-42dd-4ead-bdb8-3333c2348b02",
            "responseId": "641002f458764372e257f3dc",
            "receivedResponse": {
                "id": "641002f458764372e257f3dc0.8305543429592355",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "excellent ",
                "creationDate": "2023-03-14T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-14T05:15:32.835Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "annicet.pedro-lembe@eau-vapeur.fr",
            "CustomerContactEmail": "annicet.pedro-lembe@eau-vapeur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PEDRO-LEMBE",
            "CustomerContactFirstName": "Annicet",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAU ET VAPEUR",
            "CustomerCompanyGroupName": "EAU ET VAPEUR",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "ARNAUD",
            "XylemContactedPersonName": "JAMELIN",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019834",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "excellent ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f558764372e257f5e3",
        "extendedResponse": {
            "_id": "64146ad8b47a8ed707235b51",
            "submittedBody": {
                "id": "641002f558764372e257f5e30.5172401553772286",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour ,\nCe qui  me plaie, le temps de retour des demandes  aussi bien pour les devis , que pour les questions techniques.\n",
                "creationDate": "2023-03-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.chatry@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "022ea361-10d9-47ed-8bda-0bc8842211c0",
            "responseId": "641002f558764372e257f5e3",
            "receivedResponse": {
                "id": "641002f558764372e257f5e30.5172401553772286",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour ,\nCe qui me plaie, le temps de retour des demandes aussi bien pour les devis , que pour les questions techniques.\n",
                "creationDate": "2023-03-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-03-14T05:15:33.152Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "stephane.chatry@saur.com",
            "CustomerContactEmail": "stephane.chatry@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CHATRY",
            "CustomerContactFirstName": "Stéphane",
            "CustomerContactMobilePhone": "0662135081",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR SAUMUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "THEVENET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223019989",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour ,\nCe qui  me plaie, le temps de retour des demandes  aussi bien pour les devis , que pour les questions techniques.\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641002f558764372e257f5f4",
        "extendedResponse": {
            "_id": "64146ad9b47a8ed707235b52",
            "submittedBody": {
                "id": "641002f558764372e257f5f40.7676934695069457",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rien a redire réponse en moins de 24h.  ",
                "creationDate": "2023-03-14T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "g.guilbon@roclim.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2aa2ffc9-15bf-4f47-afbe-1ea691cbd7d5",
            "responseId": "641002f558764372e257f5f4",
            "receivedResponse": {
                "id": "641002f558764372e257f5f40.7676934695069457",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rien a redire réponse en moins de 24h. ",
                "creationDate": "2023-03-14T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-14T05:15:33.197Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "g.guilbon@roclim.com",
            "CustomerContactEmail": "g.guilbon@roclim.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUILBON",
            "CustomerContactFirstName": "Grégory",
            "CustomerContactMobilePhone": "0612812191",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ROCLIM",
            "CustomerCompanyGroupName": "ROCLIM",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Al'Moudjib",
            "XylemContactedPersonName": "MBAE",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020013",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rien a redire réponse en moins de 24h.  ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641154765a14491c0ec476a7",
        "extendedResponse": {
            "_id": "64146ad9b47a8ed707235b53",
            "submittedBody": {
                "id": "641154765a14491c0ec476a70.23633307052321828",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-03-15T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0674981719"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ff604bed-6ab3-450f-8e35-57d5132e2df8",
            "responseId": "641154765a14491c0ec476a7",
            "receivedResponse": {
                "id": "641154765a14491c0ec476a70.23633307052321828",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-03-15T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-15T05:15:34.348Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "0674981719",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "MME PIC",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0674981719",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SABOM LOUIS FARGUE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "VANNEREAU Anne",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3711527",
            "XylemReference": "206004",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64115476ce66f81088516ad6",
        "extendedResponse": {
            "_id": "64146ad9b47a8ed707235b54",
            "submittedBody": {
                "id": "64115476ce66f81088516ad60.11012958186405841",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide à ma demande de prix",
                "creationDate": "2023-03-15T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.litt@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "2bcf85e8-0546-41e9-aefc-95f123a079dc",
            "responseId": "64115476ce66f81088516ad6",
            "receivedResponse": {
                "id": "64115476ce66f81088516ad60.11012958186405841",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide à ma demande de prix",
                "creationDate": "2023-03-15T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-15T05:15:34.829Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-15_06.15.11",
        "integration": {
            "ContactKey": "christophe.litt@veolia.com",
            "CustomerContactEmail": "christophe.litt@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LITT",
            "CustomerContactFirstName": "CHRISTOPHE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ARIANEO",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020222",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide à ma demande de prix",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64115476ce66f81088516b84",
        "extendedResponse": {
            "_id": "64146adab47a8ed707235b55",
            "submittedBody": {
                "id": "64115476ce66f81088516b840.4121810538736381",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Bonjour, je reçois des devis de votre part toutes les semaines, je ne sais pas lequel vous voulez parler mais je suis globalement satisfait.\nbonne journée\nsébastien",
                "creationDate": "2023-03-15T06:15:34+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "s.parinet@poulain51.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a330b22f-8586-410e-85cc-36f1e7b850fd",
            "responseId": "64115476ce66f81088516b84",
            "receivedResponse": {
                "id": "64115476ce66f81088516b840.4121810538736381",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Bonjour, je reçois des devis de votre part toutes les semaines, je ne sais pas lequel vous voulez parler mais je suis globalement satisfait.\nbonne journée\nsébastien",
                "creationDate": "2023-03-15T06:15:34Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-15T05:15:34.864Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-15_06.15.11",
        "integration": {
            "ContactKey": "s.parinet@poulain51.fr",
            "CustomerContactEmail": "s.parinet@poulain51.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PARINET",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "0776848326",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "POULAIN BOBINAGE",
            "CustomerCompanyGroupName": "SARL POULAIN BOBINAGE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Nicolas",
            "XylemContactedPersonName": "SMEJ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223020117",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Bonjour, je reçois des devis de votre part toutes les semaines, je ne sais pas lequel vous voulez parler mais je suis globalement satisfait.\nbonne journée\nsébastien",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64115477ce66f81088516fd7",
        "extendedResponse": {
            "_id": "64146adab47a8ed707235b56",
            "submittedBody": {
                "id": "64115477ce66f81088516fd70.21241536914954717",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAPIDITE DE REPONSE AU TOP",
                "creationDate": "2023-03-15T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "veronique.albinet@brammer.biz"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c2c416e0-46f5-408c-8c87-9afb629adcab",
            "responseId": "64115477ce66f81088516fd7",
            "receivedResponse": {
                "id": "64115477ce66f81088516fd70.21241536914954717",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité DE RÉPONSE AU TOP",
                "creationDate": "2023-03-15T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-15T05:15:35.348Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-15_06.15.11",
        "integration": {
            "ContactKey": "veronique.albinet@brammer.biz",
            "CustomerContactEmail": "veronique.albinet@brammer.biz",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "ALBINET",
            "CustomerContactFirstName": "VERONIQUE",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BRAMMER",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Lea",
            "XylemSalespeopleName": "FATOWIEZ",
            "XylemContactedPersonFirstName": "Lea",
            "XylemContactedPersonName": "FATOWIEZ",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020342",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAPIDITE DE REPONSE AU TOP",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff5a14491c0e9cbe74",
        "extendedResponse": {
            "_id": "64146adbb47a8ed707235b57",
            "submittedBody": {
                "id": "6412a5ff5a14491c0e9cbe740.24139814179218289",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "plu= il y a un suivi bien fait et tout est bien expliqué concernant le devis\n\n",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "elevage-service@maisadour.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "49a53f46-8fab-4832-8bc5-cea1de34936d",
            "responseId": "6412a5ff5a14491c0e9cbe74",
            "receivedResponse": {
                "id": "6412a5ff5a14491c0e9cbe740.24139814179218289",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "plu= il y a un suivi bien fait et tout est bien expliqué concernant le devis\n\n",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Repairing"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "service.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.273Z",
        "touchpoint": "Repairing",
        "filename": "XylemFrance_Repairing_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "elevage-service@maisadour.com",
            "CustomerContactEmail": "elevage-service@maisadour.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DUBOIS",
            "CustomerContactFirstName": "Véronique",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ELEVAGE SERVICE",
            "CustomerCompanyGroupName": "ELEVAGE SERVICE SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "Anne",
            "XylemContactedPersonName": "VANNEREAU",
            "XylemContactedPersonRole": "Back Office Service",
            "CustomerReference": "BON ACCORD 06/03/23",
            "XylemReference": "3RF003007",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "plu= il y a un suivi bien fait et tout est bien expliqué concernant le devis\n\n",
            "pageNo": "0",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff5a14491c0e9cbedc",
        "extendedResponse": {
            "_id": "64146adbb47a8ed707235b58",
            "submittedBody": {
                "id": "6412a5ff5a14491c0e9cbedc0.7952513397777643",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Livraison rapide, excellent.",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.delteil@engie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "060b2a89-cddd-4f45-8c34-a5e51b8e7fa2",
            "responseId": "6412a5ff5a14491c0e9cbedc",
            "receivedResponse": {
                "id": "6412a5ff5a14491c0e9cbedc0.7952513397777643",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Livraison rapide, excellent.",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.296Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "xavier.delteil@engie.com",
            "CustomerContactEmail": "xavier.delteil@engie.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ENGIE COFELY BUSINESS LINE INDUSTRIE SANTE RECHERCHE DPT TRAVAUX",
            "CustomerCompanyGroupName": "COFELY (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "0012876694",
            "XylemReference": "773664",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Livraison rapide, excellent.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Penser à votre client quand la livraison se fait et joindre avec les pompes et les cales la distribution des cales métalliques, car sinon c'est un jeu de essaye avec celle là, met plus tôt l'autre...."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff5a14491c0e9cbf67",
        "extendedResponse": {
            "_id": "64146adbb47a8ed707235b59",
            "submittedBody": {
                "id": "6412a5ff5a14491c0e9cbf670.48223513977867505",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Date de livraison qui n'a rien avoir avec la date annoncé avant la commande.",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tony.privat@eau-vapeur.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "1eaacbba-4d40-4f02-a409-bff4625ae334",
            "responseId": "6412a5ff5a14491c0e9cbf67",
            "receivedResponse": {
                "id": "6412a5ff5a14491c0e9cbf670.48223513977867505",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Date de livraison qui n'a rien avoir avec la date annoncé avant la commande.",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.460Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "tony.privat@eau-vapeur.fr",
            "CustomerContactEmail": "tony.privat@eau-vapeur.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PRIVAT",
            "CustomerContactFirstName": "Tony",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EAU ET VAPEUR",
            "CustomerCompanyGroupName": "EAU ET VAPEUR",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "183725",
            "XylemReference": "773212",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Date de livraison qui n'a rien avoir avec la date annoncé avant la commande.",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff58764372e2327539",
        "extendedResponse": {
            "_id": "64146adcb47a8ed707235b5a",
            "submittedBody": {
                "id": "6412a5ff58764372e23275390.6574314947563575",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité ",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "n.bocquet@tdm-pompes.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "beadd223-614f-42cb-81b9-cb62326f8e7a",
            "responseId": "6412a5ff58764372e2327539",
            "receivedResponse": {
                "id": "6412a5ff58764372e23275390.6574314947563575",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité ",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.535Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "n.bocquet@tdm-pompes.com",
            "CustomerContactEmail": "n.bocquet@tdm-pompes.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOCQUET",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0610907485",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "TDM",
            "CustomerCompanyGroupName": "T.D.M.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020821",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff58764372e232753a",
        "extendedResponse": {
            "_id": "64146adcb47a8ed707235b5b",
            "submittedBody": {
                "id": "6412a5ff58764372e232753a0.4453777825592897",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité ",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pompes.services@wanadoo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "944a8a49-6b30-440f-aa91-5865ac856f17",
            "responseId": "6412a5ff58764372e232753a",
            "receivedResponse": {
                "id": "6412a5ff58764372e232753a0.4453777825592897",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité ",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.538Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "pompes.services@wanadoo.fr",
            "CustomerContactEmail": "pompes.services@wanadoo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MORLAIN",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "POMPES SERVICES 59",
            "CustomerCompanyGroupName": "POMPES SERVICES 59",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020830",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff58764372e232755b",
        "extendedResponse": {
            "_id": "64146addb47a8ed707235b5c",
            "submittedBody": {
                "id": "6412a5ff58764372e232755b0.15911892281299922",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "LA réactivité et le servise",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.guerra@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d517749b-4e23-4170-ab9d-fa2b255e1a6e",
            "responseId": "6412a5ff58764372e232755b",
            "receivedResponse": {
                "id": "6412a5ff58764372e232755b0.15911892281299922",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "LA réactivité et le service",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.633Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "philippe.guerra@veolia.com",
            "CustomerContactEmail": "philippe.guerra@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GUERRA",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0614633121",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "Gilbert",
            "XylemContactedPersonName": "JORGE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223020503",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "LA réactivité et le servise",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff58764372e2327574",
        "extendedResponse": {
            "_id": "64146addb47a8ed707235b5d",
            "submittedBody": {
                "id": "6412a5ff58764372e23275740.05924584106217878",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "a.montanes@serclim.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7ef92005-78ed-4bb9-8f4b-e27677fef9e7",
            "responseId": "6412a5ff58764372e2327574",
            "receivedResponse": {
                "id": "6412a5ff58764372e23275740.05924584106217878",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.704Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "a.montanes@serclim.fr",
            "CustomerContactEmail": "a.montanes@serclim.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MONTANES",
            "CustomerContactFirstName": "ALBERT",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SERCLIM",
            "CustomerCompanyGroupName": "SAS SERCLIM",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Pascal",
            "XylemSalespeopleName": "TISSEYRE",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "GUILLON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020357",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff58764372e232757e",
        "extendedResponse": {
            "_id": "64146adeb47a8ed707235b5e",
            "submittedBody": {
                "id": "6412a5ff58764372e232757e0.5893274179116075",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité d'exécution du devis.\nGentillesse .\nEcoute.\nQualité du devis.\n",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "beauvironnois.herve@sade-cgth.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cad8fa54-7427-477e-ab3b-a824f66a056e",
            "responseId": "6412a5ff58764372e232757e",
            "receivedResponse": {
                "id": "6412a5ff58764372e232757e0.5893274179116075",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité d'exécution du devis.\nGentillesse .\nEcoute.\nQualité du devis.\n",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.730Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "beauvironnois.herve@sade-cgth.fr",
            "CustomerContactEmail": "beauvironnois.herve@sade-cgth.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BEAUVIRONNOIS",
            "CustomerContactFirstName": "Herve",
            "CustomerContactMobilePhone": "0609615856",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SADE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020551",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité d'exécution du devis.\nGentillesse .\nEcoute.\nQualité du devis.\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff58764372e232759e",
        "extendedResponse": {
            "_id": "64146adeb47a8ed707235b5f",
            "submittedBody": {
                "id": "6412a5ff58764372e232759e0.8227233045784559",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Trés bon conseil de mon commercial bonne réactivité",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "michel.arnal@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "de14c461-be32-4c83-a5bc-51c08df505b8",
            "responseId": "6412a5ff58764372e232759e",
            "receivedResponse": {
                "id": "6412a5ff58764372e232759e0.8227233045784559",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bon conseil de mon commercial bonne réactivité",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.818Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "michel.arnal@veolia.com",
            "CustomerContactEmail": "michel.arnal@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ARNAL",
            "CustomerContactFirstName": "MICHEL",
            "CustomerContactMobilePhone": "0614424087",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU 12 RODEZ",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sebastien",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020332",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Trés bon conseil de mon commercial bonne réactivité",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "la pompe devait arrivé le vendredi elle est arrivé le lundi"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a5ff58764372e23275a1",
        "extendedResponse": {
            "_id": "64146adeb47a8ed707235b60",
            "submittedBody": {
                "id": "6412a5ff58764372e23275a10.9781202963311173",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "très bien ",
                "creationDate": "2023-03-16T06:15:43+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "alain.mutschler@siehr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "cc5f9b00-c740-4fc5-a432-fa91138d8bf7",
            "responseId": "6412a5ff58764372e23275a1",
            "receivedResponse": {
                "id": "6412a5ff58764372e23275a10.9781202963311173",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "très bien ",
                "creationDate": "2023-03-16T06:15:43Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-16T05:15:43.824Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "alain.mutschler@siehr.fr",
            "CustomerContactEmail": "alain.mutschler@siehr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MUTSCHLER",
            "CustomerContactFirstName": "Alain",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SIEHR TPE",
            "CustomerCompanyGroupName": "POMPAC DEVELOPPEMENT (NATIONAL/ ex-COMAFRANC)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020591",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "très bien ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a60058764372e232761d",
        "extendedResponse": {
            "_id": "64146adfb47a8ed707235b61",
            "submittedBody": {
                "id": "6412a60058764372e232761d0.3031206603094274",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les meilleures pompes du marché !! \nCommunauté de Communes du Sartenais Valinco Taravo",
                "creationDate": "2023-03-16T06:15:44+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "ingenierie@ccsvt.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2c09e9d9-8152-4f2a-adb0-48e4029f5a14",
            "responseId": "6412a60058764372e232761d",
            "receivedResponse": {
                "id": "6412a60058764372e232761d0.3031206603094274",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les meilleures pompes du marché !! \nCommunauté de Communes du Sartenais Valinco Taravo",
                "creationDate": "2023-03-16T06:15:44Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-16T05:15:44.152Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "ingenierie@ccsvt.fr",
            "CustomerContactEmail": "ingenierie@ccsvt.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TRAMONI",
            "CustomerContactFirstName": "Félicien",
            "CustomerContactMobilePhone": "0615649360",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Communauté de Communes du Sartenais Valinco Taravo",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223020028",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les meilleures pompes du marché !! \nCommunauté de Communes du Sartenais Valinco Taravo",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6412a60058764372e2327644",
        "extendedResponse": {
            "_id": "64146adfb47a8ed707235b62",
            "submittedBody": {
                "id": "6412a60058764372e23276440.14666266108670434",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de réponse",
                "creationDate": "2023-03-16T06:15:44+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lvilla@grandlyon.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "310e0249-3109-4c05-8c23-d13404d0c48b",
            "responseId": "6412a60058764372e2327644",
            "receivedResponse": {
                "id": "6412a60058764372e23276440.14666266108670434",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de réponse",
                "creationDate": "2023-03-16T06:15:44Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-16T05:15:44.246Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-16_06.15.10",
        "integration": {
            "ContactKey": "lvilla@grandlyon.com",
            "CustomerContactEmail": "lvilla@grandlyon.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VILLA",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRAND LYON METROPOLE",
            "CustomerCompanyGroupName": "LA METROPOLE DE LYON",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "VARENNES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020831",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f77881317bf7d27fe5d0",
        "extendedResponse": {
            "_id": "64146adfb47a8ed707235b63",
            "submittedBody": {
                "id": "6413f77881317bf7d27fe5d00.6522195110538891",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le colis est arrive tres abîmé ",
                "creationDate": "2023-03-17T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0683865201"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 3,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "messageId": "40dcc463-e2d3-4a18-a9d5-766b02455d2c",
            "responseId": "6413f77881317bf7d27fe5d0",
            "receivedResponse": {
                "id": "6413f77881317bf7d27fe5d00.6522195110538891",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le colis est arrive très abîmé ",
                "creationDate": "2023-03-17T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "3"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-17T05:15:36.039Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "0683865201",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "DALLA VALLE Guido",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0683865201",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GDV",
            "CustomerCompanyGroupName": "SARL GDV",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Pierre",
            "XylemSalespeopleName": "RICHARD",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 10/03/2023 - Devis 222085551",
            "XylemReference": "206537",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le colis est arrive tres abîmé ",
            "generalRating": "3"
        },
        "answersCalculated": {
            "generalRating": 3
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f77881317bf7d27feb4a",
        "extendedResponse": {
            "_id": "64146ae0b47a8ed707235b64",
            "submittedBody": {
                "id": "6413f77881317bf7d27feb4a0.9836601580847446",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Respect du délai ",
                "creationDate": "2023-03-17T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "commercial@gressier.net"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a285bb55-d49e-4e58-8e17-8ea3737253d7",
            "responseId": "6413f77881317bf7d27feb4a",
            "receivedResponse": {
                "id": "6413f77881317bf7d27feb4a0.9836601580847446",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Respect du délai ",
                "creationDate": "2023-03-17T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:36.581Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "commercial@gressier.net",
            "CustomerContactEmail": "commercial@gressier.net",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DESEIGNE",
            "CustomerContactFirstName": "TANGUY",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRESSIER ET FILS",
            "CustomerCompanyGroupName": "ETS GRESSIER ET FILS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CF038832",
            "XylemReference": "773957",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Respect du délai ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f77881317bf7d27feb73",
        "extendedResponse": {
            "_id": "64146ae0b47a8ed707235b65",
            "submittedBody": {
                "id": "6413f77881317bf7d27feb730.1450160109545151",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "respect des délais annoncés . ",
                "creationDate": "2023-03-17T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.thibault@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "993c763d-97df-4d45-9ee0-e3d708d696e4",
            "responseId": "6413f77881317bf7d27feb73",
            "receivedResponse": {
                "id": "6413f77881317bf7d27feb730.1450160109545151",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "respect des délais annoncés . ",
                "creationDate": "2023-03-17T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:36.611Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "philippe.thibault@saur.com",
            "CustomerContactEmail": "philippe.thibault@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THIBAULT",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0669582123",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "SAUR-0000487263",
            "XylemReference": "773831",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "respect des délais annoncés . ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f77881317bf7d27febe7",
        "extendedResponse": {
            "_id": "64146ae1b47a8ed707235b66",
            "submittedBody": {
                "id": "6413f77881317bf7d27febe70.7036768695882623",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité",
                "creationDate": "2023-03-17T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "icousseau@s-rb.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5dabbb9d-1468-4b73-80ce-c77ee49a3801",
            "responseId": "6413f77881317bf7d27febe7",
            "receivedResponse": {
                "id": "6413f77881317bf7d27febe70.7036768695882623",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité",
                "creationDate": "2023-03-17T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-17T05:15:36.677Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "icousseau@s-rb.fr",
            "CustomerContactEmail": "icousseau@s-rb.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "COUSSEAU",
            "CustomerContactFirstName": "Ingrid",
            "CustomerContactMobilePhone": "0674102039",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SYND EAUX ROCAILLES BELLECOMBE STATION D'EPURATION",
            "CustomerCompanyGroupName": "SYND EAUX ROCAILLES BELLECOMBE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Gilbert",
            "XylemSalespeopleName": "JORGE",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "ASSTBEL-2023- 9",
            "XylemReference": "773849",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d09ee",
        "extendedResponse": {
            "_id": "64146ae1b47a8ed707235b67",
            "submittedBody": {
                "id": "6413f779011638d5953d09ee0.6122697654756204",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Malik Très réactif et professionnel.\n",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francis.alam@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f5af88f1-1180-4e28-97f1-e7c32224ee19",
            "responseId": "6413f779011638d5953d09ee",
            "receivedResponse": {
                "id": "6413f779011638d5953d09ee0.6122697654756204",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Malik Très réactif et professionnel.\n",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.093Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-03-14_06.15.07",
        "integration": {
            "ContactKey": "francis.alam@veolia.com",
            "CustomerContactEmail": "francis.alam@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "EL ALAM",
            "CustomerContactFirstName": "FRANCIS",
            "CustomerContactMobilePhone": "0628119606",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - C G E",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Malik",
            "XylemContactedPersonName": "RIFFI",
            "XylemContactedPersonRole": "Charge d'affaires Service & Location",
            "CustomerReference": "AZZ3718216",
            "XylemReference": "773441",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Malik Très réactif et professionnel.\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d1343",
        "extendedResponse": {
            "_id": "64146ae2b47a8ed707235b68",
            "submittedBody": {
                "id": "6413f779011638d5953d13430.15522576083714568",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "> Echange technique pertinent pour répondre exactement au besoin\n> Réponse dans le délai demandé\n> Offre détaillée avec variante",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jeanmichel_carpentier@crgl-thirdparty.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "e3fa8596-9808-4e2d-8cc8-483fb4f61cbd",
            "responseId": "6413f779011638d5953d1343",
            "receivedResponse": {
                "id": "6413f779011638d5953d13430.15522576083714568",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "> Echange technique pertinent pour répondre exactement au besoin\n> Réponse dans le délai demandé\n> Offre détaillée avec variante",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.autre",
                                    "label": "Lead time (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.autre",
                    "label": "Lead time (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.281Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "jeanmichel_carpentier@crgl-thirdparty.com",
            "CustomerContactEmail": "jeanmichel_carpentier@crgl-thirdparty.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CARPENTIER",
            "CustomerContactFirstName": "Jean-Michel",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CARGILL HAUBOURDIN SAS",
            "CustomerCompanyGroupName": "CARGILL HAUBOURDIN SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Daniel",
            "XylemSalespeopleName": "RICHEZ",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020842",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "> Echange technique pertinent pour répondre exactement au besoin\n> Réponse dans le délai demandé\n> Offre détaillée avec variante",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "La note 5 sera évaluée lors de la phase de fourniture du matériel (qualité, délai, documentation technique, ...)"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d1351",
        "extendedResponse": {
            "_id": "64146ae2b47a8ed707235b69",
            "submittedBody": {
                "id": "6413f779011638d5953d13510.890480798188088",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse.",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "l.baltrons@decazeville-communaute.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d082db95-02f2-420f-a76e-c8bb31db9aa9",
            "responseId": "6413f779011638d5953d1351",
            "receivedResponse": {
                "id": "6413f779011638d5953d13510.890480798188088",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse.",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.305Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "l.baltrons@decazeville-communaute.fr",
            "CustomerContactEmail": "l.baltrons@decazeville-communaute.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BALTRONS",
            "CustomerContactFirstName": "LAURENT",
            "CustomerContactMobilePhone": "0646755796",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COMMUNAUTE DES COMMUNES DU BAS",
            "CustomerCompanyGroupName": "CC DECAZEVILLE COMMUNAUTE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Frédéric",
            "XylemContactedPersonName": "LOZACH'MEUR",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021002",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d1359",
        "extendedResponse": {
            "_id": "64146ae2b47a8ed707235b6a",
            "submittedBody": {
                "id": "6413f779011638d5953d13590.8701493863628862",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Devis reçu rapidement et correspondant à la demande. \nParfait ",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "antoine.thomas@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "43ab9708-877a-487e-9925-8d9f1f157b7b",
            "responseId": "6413f779011638d5953d1359",
            "receivedResponse": {
                "id": "6413f779011638d5953d13590.8701493863628862",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Devis reçu rapidement et correspondant à la demande. \nParfait ",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "fiabilite.pos",
                "delai.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.320Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "antoine.thomas@veolia.com",
            "CustomerContactEmail": "antoine.thomas@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Antoine",
            "CustomerContactMobilePhone": "0603500636",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA TRAVAUX NEUFS (SAINT OMER)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021048",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Devis reçu rapidement et correspondant à la demande. \nParfait ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d136b",
        "extendedResponse": {
            "_id": "64146ae3b47a8ed707235b6b",
            "submittedBody": {
                "id": "6413f779011638d5953d136b0.08863558411460182",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité expertise ",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lilian.nicolas@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "8aa740ce-a9e2-4dd9-a897-c2dd7926a05b",
            "responseId": "6413f779011638d5953d136b",
            "receivedResponse": {
                "id": "6413f779011638d5953d136b0.08863558411460182",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité expertise ",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.355Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "lilian.nicolas@veolia.com",
            "CustomerContactEmail": "lilian.nicolas@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "NICOLAS",
            "CustomerContactFirstName": "LILIAN",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021114",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité expertise ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d1374",
        "extendedResponse": {
            "_id": "64146ae3b47a8ed707235b6c",
            "submittedBody": {
                "id": "6413f779011638d5953d13740.38631685571274255",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse rapide et précise, ",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "o.courant@syntea.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6b0f2525-f19b-4ebe-ba26-a7cb92c180e9",
            "responseId": "6413f779011638d5953d1374",
            "receivedResponse": {
                "id": "6413f779011638d5953d13740.38631685571274255",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse rapide et précise, ",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.372Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "o.courant@syntea.fr",
            "CustomerContactEmail": "o.courant@syntea.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COURANT",
            "CustomerContactFirstName": "OLIVIER",
            "CustomerContactMobilePhone": "0607832153",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SYNTEA",
            "CustomerCompanyGroupName": "SYNTEA (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021119",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse rapide et précise, ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d14d2",
        "extendedResponse": {
            "_id": "64146ae4b47a8ed707235b6d",
            "submittedBody": {
                "id": "6413f779011638d5953d14d20.7659668551116732",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS. Toujours réactifs aux études !!",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christiane.merdinger@strelec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "724ce4ad-323e-4aef-a3a9-8e7531361ecd",
            "responseId": "6413f779011638d5953d14d2",
            "receivedResponse": {
                "id": "6413f779011638d5953d14d20.7659668551116732",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS. Toujours réactifs aux études !!",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.539Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "christiane.merdinger@strelec.fr",
            "CustomerContactEmail": "christiane.merdinger@strelec.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "MERDINGER",
            "CustomerContactFirstName": "Christiane",
            "CustomerContactMobilePhone": "0674521625",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "STRELEC",
            "CustomerCompanyGroupName": "STE STRELEC SARL",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020978",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS. Toujours réactifs aux études !!",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d14f8",
        "extendedResponse": {
            "_id": "64146ae4b47a8ed707235b6e",
            "submittedBody": {
                "id": "6413f779011638d5953d14f80.4359843476835785",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Merci pour la rapidité de votre réponse \nCordialement ",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "l.gretau@groupe-climater.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "7893d1f8-c5ce-4560-b48a-e10852c54271",
            "responseId": "6413f779011638d5953d14f8",
            "receivedResponse": {
                "id": "6413f779011638d5953d14f80.4359843476835785",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Merci pour la rapidité de votre réponse \nCordialement ",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.613Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "l.gretau@groupe-climater.com",
            "CustomerContactEmail": "l.gretau@groupe-climater.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GRETAU",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CLIMATER MAINTENANCE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Léa",
            "XylemContactedPersonName": "TANNOUS",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021029",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Merci pour la rapidité de votre réponse \nCordialement ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d14ff",
        "extendedResponse": {
            "_id": "64240fd088abc1f06bc7ea99",
            "submittedBody": {
                "id": "6413f779011638d5953d14ff0.11157385985790835",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Gentillesse, compétences et réactivité",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "francois.baliros@sasdr.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6700bc34-1ae5-413c-8be0-552a8c5b8d49",
            "responseId": "6413f779011638d5953d14ff",
            "receivedResponse": {
                "id": "6413f779011638d5953d14ff0.11157385985790835",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Gentillesse, compétences et réactivité",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.624Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "francois.baliros@sasdr.fr",
            "CustomerContactEmail": "francois.baliros@sasdr.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BALIROS",
            "CustomerContactFirstName": "FRANCOIS",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAS DR",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Christophe",
            "XylemSalespeopleName": "DOUAY",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021038",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Gentillesse, compétences et réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d15aa",
        "extendedResponse": {
            "_id": "64146ae4b47a8ed707235b6f",
            "submittedBody": {
                "id": "6413f779011638d5953d15aa0.2702498355166578",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les prix et la réactivité",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jeanmichel.leveque@sofinther.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "30b1506c-6529-4df4-ae6d-4557eb90f730",
            "responseId": "6413f779011638d5953d15aa",
            "receivedResponse": {
                "id": "6413f779011638d5953d15aa0.2702498355166578",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les prix et la réactivité",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.700Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "jeanmichel.leveque@sofinther.fr",
            "CustomerContactEmail": "jeanmichel.leveque@sofinther.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEVEQUE",
            "CustomerContactFirstName": "JEAN MICHEL",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOFINTHER",
            "CustomerCompanyGroupName": "SOFINTHER (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "ARNAUD",
            "XylemContactedPersonName": "JAMELIN",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021102",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les prix et la réactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d15c2",
        "extendedResponse": {
            "_id": "64146ae5b47a8ed707235b70",
            "submittedBody": {
                "id": "6413f779011638d5953d15c20.22909844670649537",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse rapide\nconnaissance précise du produit",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jonathan.jba@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "72acc484-b5e6-49dc-b71b-ac46fd81ac6e",
            "responseId": "6413f779011638d5953d15c2",
            "receivedResponse": {
                "id": "6413f779011638d5953d15c20.22909844670649537",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide\nconnaissance précise du produit",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.740Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "jonathan.jba@veolia.com",
            "CustomerContactEmail": "jonathan.jba@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JEAN-BAPTISTE-ADOLPHE",
            "CustomerContactFirstName": "Jonathan",
            "CustomerContactMobilePhone": "0610829206",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "OTV FRANCE OUEST",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Matthieu",
            "XylemSalespeopleName": "BOUCHARD",
            "XylemContactedPersonFirstName": "Olivier",
            "XylemContactedPersonName": "MAIRE",
            "XylemContactedPersonRole": "Ingénieur Produits Projets",
            "CustomerReference": "",
            "XylemReference": "223021069",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse rapide\nconnaissance précise du produit",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "la perfection n'est pas de ce monde..."
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d15d0",
        "extendedResponse": {
            "_id": "64240fd088abc1f06bc7ea9a",
            "submittedBody": {
                "id": "6413f779011638d5953d15d00.7029014120557162",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponible, rapidité, efficace ",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jf.viallard@lagesetfils.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "9d4a0adf-901d-476f-a5cf-469838036b14",
            "responseId": "6413f779011638d5953d15d0",
            "receivedResponse": {
                "id": "6413f779011638d5953d15d00.7029014120557162",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponible, rapidité, efficace ",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.764Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "jf.viallard@lagesetfils.com",
            "CustomerContactEmail": "jf.viallard@lagesetfils.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VIALLARD",
            "CustomerContactFirstName": "JEAN FRANCOIS",
            "CustomerContactMobilePhone": "0788703186",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LAGES ET FILS",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "RIGAL",
            "XylemContactedPersonFirstName": "Sebastien",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021174",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponible, rapidité, efficace ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d1687",
        "extendedResponse": {
            "_id": "64146ae5b47a8ed707235b71",
            "submittedBody": {
                "id": "6413f779011638d5953d16870.27323177199945015",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le devis correspond bien à ma demande.\nPar contre il manque vos référence pour chaque pièces.",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.vinay@cros.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "9f2aa3ac-fecd-4eb8-bda0-d05876509e28",
            "responseId": "6413f779011638d5953d1687",
            "receivedResponse": {
                "id": "6413f779011638d5953d16870.27323177199945015",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le devis correspond bien à ma demande.\nPar contre il manque vos référence pour chaque pièces.",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "manque.produit.neg",
                                    "label": "Missing / Lost product (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "manque.produit.neg",
                "fiabilite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "manque.produit.neg",
                    "label": "Missing / Lost product (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.890Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "sebastien.vinay@cros.com",
            "CustomerContactEmail": "sebastien.vinay@cros.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VINAY",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CROS SAS à Mission",
            "CustomerCompanyGroupName": "CROS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021263",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le devis correspond bien à ma demande.\nPar contre il manque vos référence pour chaque pièces.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Mettre les références sur le devis"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6413f779011638d5953d16c4",
        "extendedResponse": {
            "_id": "64146ae6b47a8ed707235b72",
            "submittedBody": {
                "id": "6413f779011638d5953d16c40.5045647614164044",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse très rapide",
                "creationDate": "2023-03-17T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "denis.leiros@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2dd69af1-ed0f-4b5e-8526-fba8e344f890",
            "responseId": "6413f779011638d5953d16c4",
            "receivedResponse": {
                "id": "6413f779011638d5953d16c40.5045647614164044",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse très rapide",
                "creationDate": "2023-03-17T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-17T05:15:37.915Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-17_06.15.12",
        "integration": {
            "ContactKey": "denis.leiros@suez.com",
            "CustomerContactEmail": "denis.leiros@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEIROS",
            "CustomerContactFirstName": "DENIS",
            "CustomerContactMobilePhone": "0632311684",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (HYDREA)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021281",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse très rapide",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641548f7d871b3e6615335e4",
        "extendedResponse": {
            "_id": "64240fd088abc1f06bc7ea9b",
            "submittedBody": {
                "id": "641548f7d871b3e6615335e40.36510061569821106",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rencontre avec les sédentaires ",
                "creationDate": "2023-03-18T06:15:35+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "xavier.abramatic@eiffage.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ac881dc3-6349-458d-9a74-a159c34b699c",
            "responseId": "641548f7d871b3e6615335e4",
            "receivedResponse": {
                "id": "641548f7d871b3e6615335e40.36510061569821106",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rencontre avec les sédentaires ",
                "creationDate": "2023-03-18T06:15:35Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-18T05:15:35.831Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-18_06.15.10",
        "integration": {
            "ContactKey": "xavier.abramatic@eiffage.com",
            "CustomerContactEmail": "xavier.abramatic@eiffage.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ABRAMATIC",
            "CustomerContactFirstName": "Xavier",
            "CustomerContactMobilePhone": "0646319218",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EIFFAGE GENIE CIVIL HYDRAULIQUE",
            "CustomerCompanyGroupName": "EGC HYDRAULIQUES & RESERVOIRS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Pierrick",
            "XylemContactedPersonName": "FRAPPAS",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rencontre avec les sédentaires ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641548f8d871b3e661534396",
        "extendedResponse": {
            "_id": "64240fd188abc1f06bc7ea9c",
            "submittedBody": {
                "id": "641548f8d871b3e6615343960.23044131159286807",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Notre commercial est parti de chez xylem.vous n'avez pas force de nous prévenir inadmissible ",
                "creationDate": "2023-03-18T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "p.sabat@cls-water.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "2069d007-8457-4423-bc86-cc1310022fb6",
            "responseId": "641548f8d871b3e661534396",
            "receivedResponse": {
                "id": "641548f8d871b3e6615343960.23044131159286807",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Notre commercial est parti de chez xylem.vous n'avez pas force de nous prévenir inadmissible ",
                "creationDate": "2023-03-18T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-18T05:15:36.419Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-18_06.15.10",
        "integration": {
            "ContactKey": "p.sabat@cls-water.com",
            "CustomerContactEmail": "p.sabat@cls-water.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SABAT",
            "CustomerContactFirstName": "Patrick",
            "CustomerContactMobilePhone": "0695723550",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CLS WATER",
            "CustomerCompanyGroupName": "CLS WATER SAS",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Samuel",
            "XylemSalespeopleName": "HARZAU",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223021312",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Notre commercial est parti de chez xylem.vous n'avez pas force de nous prévenir inadmissible ",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"name\": \"Sabat Patrick \",\n  \"mail\": \"p.sabat@cls-water.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641548f8d871b3e6615343bb",
        "extendedResponse": {
            "_id": "64240fd188abc1f06bc7ea9d",
            "submittedBody": {
                "id": "641548f8d871b3e6615343bb0.6808598981113936",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "reactif",
                "creationDate": "2023-03-18T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "vjulien@vareo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c123d2dd-0de4-4452-b3d0-f1a86d31d706",
            "responseId": "641548f8d871b3e6615343bb",
            "receivedResponse": {
                "id": "641548f8d871b3e6615343bb0.6808598981113936",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactif",
                "creationDate": "2023-03-18T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-18T05:15:36.456Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-18_06.15.10",
        "integration": {
            "ContactKey": "vjulien@vareo.fr",
            "CustomerContactEmail": "vjulien@vareo.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "JULIEN",
            "CustomerContactFirstName": "Valérie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VAREO",
            "CustomerCompanyGroupName": "VAREO",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021371",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "reactif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641548f8d871b3e661534494",
        "extendedResponse": {
            "_id": "64240fd288abc1f06bc7ea9e",
            "submittedBody": {
                "id": "641548f8d871b3e6615344940.19118769665329438",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Impeccable",
                "creationDate": "2023-03-18T06:15:36+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sebastien.vinay@cros.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "4829794d-3879-46ef-9956-78e14422cc62",
            "responseId": "641548f8d871b3e661534494",
            "receivedResponse": {
                "id": "641548f8d871b3e6615344940.19118769665329438",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Impeccable",
                "creationDate": "2023-03-18T06:15:36Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-18T05:15:36.544Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-18_06.15.10",
        "integration": {
            "ContactKey": "sebastien.vinay@cros.com",
            "CustomerContactEmail": "sebastien.vinay@cros.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VINAY",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CROS SAS à Mission",
            "CustomerCompanyGroupName": "CROS",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Elie",
            "XylemContactedPersonName": "BAYET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021263",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Impeccable",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641548f9d871b3e661534940",
        "extendedResponse": {
            "_id": "64240fd288abc1f06bc7ea9f",
            "submittedBody": {
                "id": "641548f9d871b3e6615349400.19349380545822337",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité et pertinence de l'offre",
                "creationDate": "2023-03-18T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "martial.marlin@spie.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ce2c0f38-26f6-4947-b047-8052f9a45a18",
            "responseId": "641548f9d871b3e661534940",
            "receivedResponse": {
                "id": "641548f9d871b3e6615349400.19349380545822337",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité et pertinence de l'offre",
                "creationDate": "2023-03-18T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-18T05:15:37.182Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-18_06.15.10",
        "integration": {
            "ContactKey": "martial.marlin@spie.com",
            "CustomerContactEmail": "martial.marlin@spie.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARLIN",
            "CustomerContactFirstName": "MARTIAL",
            "CustomerContactMobilePhone": "0643741957",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SPIE EST OPERATION GENIE CLIMATIQUE",
            "CustomerCompanyGroupName": "SPIE BUILDING SOLUTIONS",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Guillaume",
            "XylemSalespeopleName": "GEYER",
            "XylemContactedPersonFirstName": "Nizar",
            "XylemContactedPersonName": "TRIMECH",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021570",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité et pertinence de l'offre",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641548f9d871b3e661534979",
        "extendedResponse": {
            "_id": "64240fd288abc1f06bc7eaa0",
            "submittedBody": {
                "id": "641548f9d871b3e6615349790.5436770368087998",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité \n",
                "creationDate": "2023-03-18T06:15:37+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "fabien.marechal@spiebatignolles.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bca13e18-8e16-4f5b-a6ac-680ad09818fa",
            "responseId": "641548f9d871b3e661534979",
            "receivedResponse": {
                "id": "641548f9d871b3e6615349790.5436770368087998",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité \n",
                "creationDate": "2023-03-18T06:15:37Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-18T05:15:37.310Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-18_06.15.10",
        "integration": {
            "ContactKey": "fabien.marechal@spiebatignolles.fr",
            "CustomerContactEmail": "fabien.marechal@spiebatignolles.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MARECHAL",
            "CustomerContactFirstName": "Fabien",
            "CustomerContactMobilePhone": "0673889887",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SPIE BATIGNOLLES ENERGIE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Franck",
            "XylemSalespeopleName": "PENVERN",
            "XylemContactedPersonFirstName": "Franck",
            "XylemContactedPersonName": "PENVERN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223021656",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité \n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6417ebecd47fa1256953a92b",
        "extendedResponse": {
            "_id": "64240fd388abc1f06bc7eaa1",
            "submittedBody": {
                "id": "6417ebecd47fa1256953a92b0.2915236875554885",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilités tarif ",
                "creationDate": "2023-03-20T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0627212203"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "16eb0a71-f36d-4eab-b7c2-19a8addccb3e",
            "responseId": "6417ebecd47fa1256953a92b",
            "receivedResponse": {
                "id": "6417ebecd47fa1256953a92b0.2915236875554885",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilités tarif ",
                "creationDate": "2023-03-20T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                }
            ]
        },
        "date": "2023-03-20T05:15:24.043Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-20_06.15.06",
        "integration": {
            "ContactKey": "0627212203",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "NICOLAS LILIAN",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0627212203",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - STEP THIONVILLE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3711830",
            "XylemReference": "205931",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilités tarif ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6417ebecd47fa1256953a950",
        "extendedResponse": {
            "_id": "64240fd388abc1f06bc7eaa2",
            "submittedBody": {
                "id": "6417ebecd47fa1256953a9500.9318100432944509",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ras",
                "creationDate": "2023-03-20T06:15:24+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0612853814"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "6fb2a909-8fb9-4aee-a547-5e7311829041",
            "responseId": "6417ebecd47fa1256953a950",
            "receivedResponse": {
                "id": "6417ebecd47fa1256953a9500.9318100432944509",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ras",
                "creationDate": "2023-03-20T06:15:24Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-20T05:15:24.141Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-20_06.15.06",
        "integration": {
            "ContactKey": "0612853814",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "GERBERON Philippe",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0612853814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3711712",
            "XylemReference": "205915",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ras",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bd37a",
        "extendedResponse": {
            "_id": "64240fd388abc1f06bc7eaa3",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bd37a0.5602596007651055",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "DÉLAI DE RÉPONSE CORRECT",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "eric.balan@rubix.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "33f0e210-a22a-45f2-be3f-90f55ab078e6",
            "responseId": "64193d6fa96cf5d08d7bd37a",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bd37a0.5602596007651055",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "DÉLAI DE RÉPONSE CORRECT",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.476Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "eric.balan@rubix.com",
            "CustomerContactEmail": "eric.balan@rubix.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BALAN",
            "CustomerContactFirstName": "Eric",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RUBIX",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Elodie",
            "XylemContactedPersonName": "DURAND",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021819",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "DÉLAI DE RÉPONSE CORRECT",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bd3a5",
        "extendedResponse": {
            "_id": "64240fd388abc1f06bc7eaa4",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bd3a50.12398725671600719",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité de la réponse à notre demande",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jaguin.asso@gmail.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "167258f6-d9b2-4b83-9af5-4ebeae7d452d",
            "responseId": "64193d6fa96cf5d08d7bd3a5",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bd3a50.12398725671600719",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité de la réponse à notre demande",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.594Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "jaguin.asso@gmail.com",
            "CustomerContactEmail": "jaguin.asso@gmail.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "JAGUIN",
            "CustomerContactFirstName": "Gérard",
            "CustomerContactMobilePhone": "0661201803",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ASS'O",
            "CustomerCompanyGroupName": "SAS ASS'O",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "Mehmet Akif",
            "XylemContactedPersonName": "TOKER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223021959",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité de la réponse à notre demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bd3a9",
        "extendedResponse": {
            "_id": "64240fd488abc1f06bc7eaa5",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bd3a90.7270970544555877",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réponse rapide ",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "yann.clouet@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a6e84eea-f259-4681-99db-c2d57c27df84",
            "responseId": "64193d6fa96cf5d08d7bd3a9",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bd3a90.7270970544555877",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réponse rapide ",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.603Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "yann.clouet@suez.com",
            "CustomerContactEmail": "yann.clouet@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CLOUET",
            "CustomerContactFirstName": "Yann",
            "CustomerContactMobilePhone": "0689953024",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP",
            "XylemContactedPersonFirstName": "Pierre",
            "XylemContactedPersonName": "FANEL",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021967",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réponse rapide ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bd3d2",
        "extendedResponse": {
            "_id": "64240fd488abc1f06bc7eaa6",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bd3d20.29067062585017966",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "écoute, conseils & rapidité de la réponse",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rl.augee@hotmail.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a7e14115-71ed-4bee-a126-1f0a42c9952f",
            "responseId": "64193d6fa96cf5d08d7bd3d2",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bd3d20.29067062585017966",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "écoute, conseils & rapidité de la réponse",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.712Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "rl.augee@hotmail.fr",
            "CustomerContactEmail": "rl.augee@hotmail.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "AUGEE",
            "CustomerContactFirstName": "Rene-Louis",
            "CustomerContactMobilePhone": "0631119552",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NORIASYS POMPAGE",
            "CustomerCompanyGroupName": "NORIASYS POMPAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "Benoit",
            "XylemContactedPersonName": "BOLTZ",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223021711",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "écoute, conseils & rapidité de la réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bd3d6",
        "extendedResponse": {
            "_id": "64240fd488abc1f06bc7eaa7",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bd3d60.1389163526948074",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lionel.lejeune@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "55d0762b-d250-4146-9264-866429dfbc43",
            "responseId": "64193d6fa96cf5d08d7bd3d6",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bd3d60.1389163526948074",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.721Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "lionel.lejeune@veolia.com",
            "CustomerContactEmail": "lionel.lejeune@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEJEUNE",
            "CustomerContactFirstName": "Lionel",
            "CustomerContactMobilePhone": "+33672470320",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CLAISSE ENVIRONNEMENT",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Jean-Philippe",
            "XylemContactedPersonName": "VALTER",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223021729",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "4/5"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bd563",
        "extendedResponse": {
            "_id": "64240fd588abc1f06bc7eaa8",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bd5630.19507972525374995",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les précisions techniques ",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.lion@dalkia.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b6567802-d661-4c72-bd63-4d8c79c30622",
            "responseId": "64193d6fa96cf5d08d7bd563",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bd5630.19507972525374995",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les précisions techniques ",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.821Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "olivier.lion@dalkia.fr",
            "CustomerContactEmail": "olivier.lion@dalkia.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LION",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DALKIA DIEPPE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Clement",
            "XylemSalespeopleName": "FAIVRE",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "GALLO",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223021754",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les précisions techniques ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bde83",
        "extendedResponse": {
            "_id": "64240fd588abc1f06bc7eaa9",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bde830.5621999043740336",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité pour l'envoi et le retour du matériel. Merci",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sgrujard@cc-mosellemadon.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b3db9dcc-46fe-431f-b895-997654373a78",
            "responseId": "64193d6fa96cf5d08d7bde83",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bde830.5621999043740336",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité pour l'envoi et le retour du matériel. Merci",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.902Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "sgrujard@cc-mosellemadon.fr",
            "CustomerContactEmail": "sgrujard@cc-mosellemadon.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GRUJARD",
            "CustomerContactFirstName": "Sébastien",
            "CustomerContactMobilePhone": "0752678573",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COMMUNAUTE DE COMMUNE",
            "CustomerCompanyGroupName": "COMMUNAUTE DES COMMUNES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Hugo",
            "XylemSalespeopleName": "DEBETTE",
            "XylemContactedPersonFirstName": "Ludovic",
            "XylemContactedPersonName": "DUVEREL",
            "XylemContactedPersonRole": "Charge d'affaires Service & Location",
            "CustomerReference": "EA230089",
            "XylemReference": "760611",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité pour l'envoi et le retour du matériel. Merci",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d6fa96cf5d08d7bdf30",
        "extendedResponse": {
            "_id": "64240fd588abc1f06bc7eaaa",
            "submittedBody": {
                "id": "64193d6fa96cf5d08d7bdf300.9916665869557741",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité du commercial, les conseils et la qualité des produits.",
                "creationDate": "2023-03-21T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "kevin.mallard@rivagroup.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "104fe4b5-85ba-4e0d-b062-0abfff982e32",
            "responseId": "64193d6fa96cf5d08d7bdf30",
            "receivedResponse": {
                "id": "64193d6fa96cf5d08d7bdf300.9916665869557741",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité du commercial, les conseils et la qualité des produits.",
                "creationDate": "2023-03-21T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "qualite.pos",
                                    "label": "Quality (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "qualite.pos",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "qualite.pos",
                    "label": "Quality (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:27.905Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "kevin.mallard@rivagroup.com",
            "CustomerContactEmail": "kevin.mallard@rivagroup.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MALLARD",
            "CustomerContactFirstName": "KEVIN",
            "CustomerContactMobilePhone": "0629736126",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ITON-SEINE",
            "CustomerCompanyGroupName": "ITON-SEINE SA",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Geoffrey",
            "XylemSalespeopleName": "WATELET",
            "XylemContactedPersonFirstName": "Fabrice",
            "XylemContactedPersonName": "BLOYARD",
            "XylemContactedPersonRole": "Charge d'affaires Service & Location",
            "CustomerReference": "332-DP/CV",
            "XylemReference": "767726",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité du commercial, les conseils et la qualité des produits.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d70d47fa125696b5b61",
        "extendedResponse": {
            "_id": "64240fd588abc1f06bc7eaab",
            "submittedBody": {
                "id": "64193d70d47fa125696b5b610.03732889692732333",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "L'efficacité; la rapidité",
                "creationDate": "2023-03-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "marielle.gara@dimatech.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d7ec6cf4-34d5-4b1e-8a6e-bf3d56052a72",
            "responseId": "64193d70d47fa125696b5b61",
            "receivedResponse": {
                "id": "64193d70d47fa125696b5b610.03732889692732333",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "L'efficacité; la rapidité",
                "creationDate": "2023-03-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:28.047Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "marielle.gara@dimatech.fr",
            "CustomerContactEmail": "marielle.gara@dimatech.fr",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "DIMATECH",
            "CustomerCompanyGroupName": "DIMATECHNIQUES SARL",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN DT",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Patricia PROUST",
            "XylemContactedPersonRole": "",
            "CustomerReference": "DICA2300060",
            "XylemReference": "774448",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "L'efficacité; la rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d70a96cf5d08d7be55a",
        "extendedResponse": {
            "_id": "64240fd688abc1f06bc7eaac",
            "submittedBody": {
                "id": "64193d70a96cf5d08d7be55a0.7488310690398576",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Communication, impeccable ",
                "creationDate": "2023-03-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0616991562"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "619ee526-20ea-435c-a8d6-fc78b192861f",
            "responseId": "64193d70a96cf5d08d7be55a",
            "receivedResponse": {
                "id": "64193d70a96cf5d08d7be55a0.7488310690398576",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Communication, impeccable ",
                "creationDate": "2023-03-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:28.109Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-20_06.15.06",
        "integration": {
            "ContactKey": "0616991562",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "CEDRIC DELPUECH",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0616991562",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAS DELPUECH BOBINAGE",
            "CustomerCompanyGroupName": "SAS DELPUECH BOBINAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Philippe",
            "XylemSalespeopleName": "CARRARA",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Carole BERTRAND",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 10/03/2023 - Devis 223014733",
            "XylemReference": "207079",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Communication, impeccable ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d70a96cf5d08d7be57a",
        "extendedResponse": {
            "_id": "64240fd688abc1f06bc7eaad",
            "submittedBody": {
                "id": "64193d70a96cf5d08d7be57a0.22007126287606327",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Delai de livraison très rapide \n",
                "creationDate": "2023-03-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0617944196"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "f78bbc90-7b70-4206-b4f5-20e33633f1df",
            "responseId": "64193d70a96cf5d08d7be57a",
            "receivedResponse": {
                "id": "64193d70a96cf5d08d7be57a0.22007126287606327",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "délai de livraison très rapide \n",
                "creationDate": "2023-03-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:28.154Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-20_06.15.06",
        "integration": {
            "ContactKey": "0617944196",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "M. Yvon KERMOAL",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0617944196",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU - MAYENNE",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Mehmet Akif",
            "XylemSalespeopleName": "TOKER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3716739",
            "XylemReference": "206706",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Delai de livraison très rapide \n",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Retour de l accuse de réception de commande "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d70d47fa125696b5bbd",
        "extendedResponse": {
            "_id": "64240fd688abc1f06bc7eaae",
            "submittedBody": {
                "id": "64193d70d47fa125696b5bbd0.46251427452606864",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ayant un devis ( N°223008325) avec un délai annoncé d'1 mois pour les pompes et le reste du matériel disponible, au passage de commande, sur les 7 lignes je n'est que 2 lignes renseignées avec une date de livraison.\nPour les 5 autres lignes : \"Attente info usine\"\nJ'ai crainte que le délai annoncé ne soit pas respecté et génère des pénalités de chantier.\nCordialement\nD.MASIERO",
                "creationDate": "2023-03-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dimitri.masiero@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "b1f8b713-5353-4adb-9ad1-74aa0a915566",
            "responseId": "64193d70d47fa125696b5bbd",
            "receivedResponse": {
                "id": "64193d70d47fa125696b5bbd0.46251427452606864",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ayant un devis ( N°223008325) avec un délai annoncé d'1 mois pour les pompes et le reste du matériel disponible, au passage de commande, sur les 7 lignes je n'est que 2 lignes renseignées avec une date de livraison.\nPour les 5 autres lignes : \"Attente information usine\"\nJ'ai crainte que le délai annoncé ne soit pas respecté et génère des pénalités de chantier.\nCordialement\nD.MASIERO",
                "creationDate": "2023-03-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.neg",
                                    "label": "Welcome / Kindness (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.neg",
                "joignabilite.neg",
                "accueil.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "accueil.neg",
                    "label": "Welcome / Kindness (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-21T05:15:28.185Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "dimitri.masiero@veolia.com",
            "CustomerContactEmail": "dimitri.masiero@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MASIERO",
            "CustomerContactFirstName": "Dimitri",
            "CustomerContactMobilePhone": "+33614721226",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE TILLOY)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3717029",
            "XylemReference": "773980",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ayant un devis ( N°223008325) avec un délai annoncé d'1 mois pour les pompes et le reste du matériel disponible, au passage de commande, sur les 7 lignes je n'est que 2 lignes renseignées avec une date de livraison.\nPour les 5 autres lignes : \"Attente info usine\"\nJ'ai crainte que le délai annoncé ne soit pas respecté et génère des pénalités de chantier.\nCordialement\nD.MASIERO",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "yes",
            "coordinates": "{\n  \"phone\": \"0614721226\",\n  \"name\": \"Dimitri MASIERO\",\n  \"mail\": \"dimitri.masiero@veolia.com\"\n}"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "64193d70d47fa125696b5c31",
        "extendedResponse": {
            "_id": "64240fd788abc1f06bc7eaaf",
            "submittedBody": {
                "id": "64193d70d47fa125696b5c310.3380297537532597",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "M. Boltz toujours de bon conseil",
                "creationDate": "2023-03-21T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "claude-obliger@orange.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "38fd8d6e-2f79-4f78-a7fa-b0c0501b49cc",
            "responseId": "64193d70d47fa125696b5c31",
            "receivedResponse": {
                "id": "64193d70d47fa125696b5c310.3380297537532597",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "M. Boltz toujours de bon conseil",
                "creationDate": "2023-03-21T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-21T05:15:28.443Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "claude-obliger@orange.fr",
            "CustomerContactEmail": "claude-obliger@orange.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "OBLIGER",
            "CustomerContactFirstName": "Claude",
            "CustomerContactMobilePhone": "0632655211",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "MAIRIE DE STE-MARIE-AUX-MINES",
            "CustomerCompanyGroupName": "MAIRIE DE STE MARIE AUX MINES",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BPA du 14/03/2023 - devis 223020061",
            "XylemReference": "774215",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "M. Boltz toujours de bon conseil",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641a8eefec6d95c337e24bb6",
        "extendedResponse": {
            "_id": "64240fd788abc1f06bc7eab0",
            "submittedBody": {
                "id": "641a8eefec6d95c337e24bb60.16985762658727044",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité",
                "creationDate": "2023-03-22T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rl.augee@noriasys.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "5f8e570b-9eee-48f6-a0ba-b7e8b723fe40",
            "responseId": "641a8eefec6d95c337e24bb6",
            "receivedResponse": {
                "id": "641a8eefec6d95c337e24bb60.16985762658727044",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité",
                "creationDate": "2023-03-22T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-22T05:15:27.507Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "rl.augee@noriasys.fr",
            "CustomerContactEmail": "rl.augee@noriasys.fr",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "NORIASYS POMPAGE",
            "CustomerCompanyGroupName": "NORIASYS POMPAGE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Benoit",
            "XylemSalespeopleName": "BOLTZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "0813-1961-2303RL",
            "XylemReference": "774682",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641a8eefa96cf5d08deb61ae",
        "extendedResponse": {
            "_id": "64240fd788abc1f06bc7eab1",
            "submittedBody": {
                "id": "641a8eefa96cf5d08deb61ae0.24361112885462655",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Tout est bien ",
                "creationDate": "2023-03-22T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "gauderon.frederic@wanadoo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1264f153-ff5e-45eb-ad9b-4c2e29f4b2f6",
            "responseId": "641a8eefa96cf5d08deb61ae",
            "receivedResponse": {
                "id": "641a8eefa96cf5d08deb61ae0.24361112885462655",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Tout est bien ",
                "creationDate": "2023-03-22T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-22T05:15:27.525Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-22_06.15.07",
        "integration": {
            "ContactKey": "gauderon.frederic@wanadoo.fr",
            "CustomerContactEmail": "gauderon.frederic@wanadoo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GAUDERON",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0618160032",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "M2G",
            "CustomerCompanyGroupName": "M2G",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Claude",
            "XylemSalespeopleName": "GUILLERMET",
            "XylemContactedPersonFirstName": "Frédérique",
            "XylemContactedPersonName": "SCHMITTER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022055",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Tout est bien ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641a8eefa96cf5d08deb61d4",
        "extendedResponse": {
            "_id": "64240fd788abc1f06bc7eab2",
            "submittedBody": {
                "id": "641a8eefa96cf5d08deb61d40.7074251921137409",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "le moins: Trop d'erreur même avec une demande claire obliger de relire en détail les devis .\n\nle plus : très réactif pour rectifier les erreurs.\n\n",
                "creationDate": "2023-03-22T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jonathan.coolen@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "b7fa69c5-c4ed-430d-96d0-bf7e71c0eb31",
            "responseId": "641a8eefa96cf5d08deb61d4",
            "receivedResponse": {
                "id": "641a8eefa96cf5d08deb61d40.7074251921137409",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "le moins: Trop d'erreur même avec une demande claire obliger de relire en détail les devis .\n\nle plus : très réactif pour rectifier les erreurs.\n\n",
                "creationDate": "2023-03-22T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-22T05:15:27.581Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-22_06.15.07",
        "integration": {
            "ContactKey": "jonathan.coolen@veolia.com",
            "CustomerContactEmail": "jonathan.coolen@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "COOLEN",
            "CustomerContactFirstName": "Jonathan",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "RABA SARP SUD OUEST",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022114",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "le moins: Trop d'erreur même avec une demande claire obliger de relire en détail les devis .\n\nle plus : très réactif pour rectifier les erreurs.\n\n",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641a8eefa96cf5d08deb6277",
        "extendedResponse": {
            "_id": "64240fd888abc1f06bc7eab3",
            "submittedBody": {
                "id": "641a8eefa96cf5d08deb62770.20716544906476875",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Clarté\n",
                "creationDate": "2023-03-22T06:15:27+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-claude.prost@sources.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "f840287e-6b20-4295-be85-be8fa7681d1b",
            "responseId": "641a8eefa96cf5d08deb6277",
            "receivedResponse": {
                "id": "641a8eefa96cf5d08deb62770.20716544906476875",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Clarté\n",
                "creationDate": "2023-03-22T06:15:27Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-22T05:15:27.925Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-22_06.15.07",
        "integration": {
            "ContactKey": "jean-claude.prost@sources.fr",
            "CustomerContactEmail": "jean-claude.prost@sources.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PROST",
            "CustomerContactFirstName": "Jean-Claude",
            "CustomerContactMobilePhone": "0613516382",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOURCES",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Audrey",
            "XylemSalespeopleName": "CHAUVET",
            "XylemContactedPersonFirstName": "Audrey",
            "XylemContactedPersonName": "CHAUVET",
            "XylemContactedPersonRole": "Ingénieur Grands Projets",
            "CustomerReference": "",
            "XylemReference": "223021696",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Clarté\n",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641a8ef0a96cf5d08deb62be",
        "extendedResponse": {
            "_id": "64240fd888abc1f06bc7eab4",
            "submittedBody": {
                "id": "641a8ef0a96cf5d08deb62be0.21213170437676832",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le retour rapide et par téléphone",
                "creationDate": "2023-03-22T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "tristan.le-callonec@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "12ef5956-27f3-4235-8437-069ec86a758f",
            "responseId": "641a8ef0a96cf5d08deb62be",
            "receivedResponse": {
                "id": "641a8ef0a96cf5d08deb62be0.21213170437676832",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le retour rapide et par téléphone",
                "creationDate": "2023-03-22T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-22T05:15:28.105Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-22_06.15.07",
        "integration": {
            "ContactKey": "tristan.le-callonec@saur.com",
            "CustomerContactEmail": "tristan.le-callonec@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LE CALLONEC",
            "CustomerContactFirstName": "Tristan",
            "CustomerContactMobilePhone": "0665149123",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Sebastien",
            "XylemContactedPersonName": "PICHEREAU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022239",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le retour rapide et par téléphone",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641a8ef0a96cf5d08deb62c6",
        "extendedResponse": {
            "_id": "64240fd888abc1f06bc7eab5",
            "submittedBody": {
                "id": "641a8ef0a96cf5d08deb62c60.0167944464086367",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rapidité de réponse",
                "creationDate": "2023-03-22T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lvilla@grandlyon.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "45f0d6dc-0a00-4a82-8f6c-33f6314b9723",
            "responseId": "641a8ef0a96cf5d08deb62c6",
            "receivedResponse": {
                "id": "641a8ef0a96cf5d08deb62c60.0167944464086367",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rapidité de réponse",
                "creationDate": "2023-03-22T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-22T05:15:28.123Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-22_06.15.07",
        "integration": {
            "ContactKey": "lvilla@grandlyon.com",
            "CustomerContactEmail": "lvilla@grandlyon.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VILLA",
            "CustomerContactFirstName": "Laurent",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "GRAND LYON METROPOLE",
            "CustomerCompanyGroupName": "LA METROPOLE DE LYON",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Alexis",
            "XylemContactedPersonName": "MACHON",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022249",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641a8ef0a96cf5d08deb6326",
        "extendedResponse": {
            "_id": "64240fd988abc1f06bc7eab6",
            "submittedBody": {
                "id": "641a8ef0a96cf5d08deb63260.4136928747852324",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "rapidité de réponse avec éléments en appuis",
                "creationDate": "2023-03-22T06:15:28+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "jean-pierre.canizares@lochessudtouraine.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "73d416bb-af16-4ed8-a2dd-8e8ffb8e37a1",
            "responseId": "641a8ef0a96cf5d08deb6326",
            "receivedResponse": {
                "id": "641a8ef0a96cf5d08deb63260.4136928747852324",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "rapidité de réponse avec éléments en appuis",
                "creationDate": "2023-03-22T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-22T05:15:28.276Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-22_06.15.07",
        "integration": {
            "ContactKey": "jean-pierre.canizares@lochessudtouraine.com",
            "CustomerContactEmail": "jean-pierre.canizares@lochessudtouraine.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CANIZARES",
            "CustomerContactFirstName": "jean pierre",
            "CustomerContactMobilePhone": "0608890531",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "COM.COM LOCHES SUD TOURAINE",
            "CustomerCompanyGroupName": "CC LOCHES SUD TOURAINE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Rémi",
            "XylemContactedPersonName": "BOUQUIN",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223022340",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "rapidité de réponse avec éléments en appuis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0740f7d98b103a4fa62",
        "extendedResponse": {
            "_id": "64240fd988abc1f06bc7eab7",
            "submittedBody": {
                "id": "641be0740f7d98b103a4fa620.3070318862791068",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse très rapide et conforme à ma demande.\nPar contre j'ai demandé les modalités pour effectuer un retour sous garantie d'une pompe et je n'ai pas eu de réponse.",
                "creationDate": "2023-03-23T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "stephane.matte@i-procent-littoral.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "b9fba63d-8e37-4b63-a20c-5db91dc7fafb",
            "responseId": "641be0740f7d98b103a4fa62",
            "receivedResponse": {
                "id": "641be0740f7d98b103a4fa620.3070318862791068",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse très rapide et conforme à ma demande.\nPar contre j'ai demandé les modalités pour effectuer un retour sous garantie d'une pompe et je n'ai pas eu de réponse.",
                "creationDate": "2023-03-23T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "fiabilite.pos",
                                    "label": "Reliability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "joignabilite.neg",
                                    "label": "Reachability (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.neg",
                                    "label": "Efficiency / Reactivity / Reminder (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "mitigated",
                                    "label": "Mitigée",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "fiabilite.pos",
                "delai.pos",
                "joignabilite.neg",
                "efficacite.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "fiabilite.pos",
                    "label": "Reliability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "joignabilite.neg",
                    "label": "Reachability (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "efficacite.neg",
                    "label": "Efficiency / Reactivity / Reminder (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-23T05:15:32.936Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "stephane.matte@i-procent-littoral.fr",
            "CustomerContactEmail": "stephane.matte@i-procent-littoral.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "MATTE",
            "CustomerContactFirstName": "STEPHANE",
            "CustomerContactMobilePhone": "0668284526",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "I-PROCENT LITTORAL",
            "CustomerCompanyGroupName": "I PROCENT LITTORAL",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Maryse",
            "XylemSalespeopleName": "VANOVERBERGHE",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022581",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse très rapide et conforme à ma demande.\nPar contre j'ai demandé les modalités pour effectuer un retour sous garantie d'une pompe et je n'ai pas eu de réponse.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Me donner une réponse sur les modalités pour effectuer un retour sous-garantie"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0746600361b9f98287f",
        "extendedResponse": {
            "_id": "64240fd988abc1f06bc7eab8",
            "submittedBody": {
                "id": "641be0746600361b9f98287f0.09802785441237294",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "ras",
                "creationDate": "2023-03-23T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.gerberon@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "09997107-72f6-4079-9726-aaaa3280ce65",
            "responseId": "641be0746600361b9f98287f",
            "receivedResponse": {
                "id": "641be0746600361b9f98287f0.09802785441237294",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "ras",
                "creationDate": "2023-03-23T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-23T05:15:32.949Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "philippe.gerberon@veolia.com",
            "CustomerContactEmail": "philippe.gerberon@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GERBERON",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0612853814",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "SMEJ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "CZZ3723382",
            "XylemReference": "774744",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "ras",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0740f7d98b103a4fa6c",
        "extendedResponse": {
            "_id": "64240fda88abc1f06bc7eab9",
            "submittedBody": {
                "id": "641be0740f7d98b103a4fa6c0.024154343495292663",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la rapidité de la réponse, me donner la bonne adresse mail pour éviter tout problème de correspondance.",
                "creationDate": "2023-03-23T06:15:32+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "andre.delrue@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1cc66348-e027-409d-953e-e85cd1e826b9",
            "responseId": "641be0740f7d98b103a4fa6c",
            "receivedResponse": {
                "id": "641be0740f7d98b103a4fa6c0.024154343495292663",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la rapidité de la réponse, me donner la bonne adresse mail pour éviter tout problème de correspondance.",
                "creationDate": "2023-03-23T06:15:32Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-23T05:15:32.964Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "andre.delrue@suez.com",
            "CustomerContactEmail": "andre.delrue@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELRUE",
            "CustomerContactFirstName": "André",
            "CustomerContactMobilePhone": "0676764697",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (EAUX DU NORD) (AGENCE DOUAI)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Sandrine",
            "XylemContactedPersonName": "BUCHET",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022601",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la rapidité de la réponse, me donner la bonne adresse mail pour éviter tout problème de correspondance.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0750f7d98b103a4fa7f",
        "extendedResponse": {
            "_id": "64240fda88abc1f06bc7eaba",
            "submittedBody": {
                "id": "641be0750f7d98b103a4fa7f0.5047286292597908",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Plu la rapidité du retour de mail merci ",
                "creationDate": "2023-03-23T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "david.tanguy@saur.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1103e141-934d-47f9-ba23-c350290e2e78",
            "responseId": "641be0750f7d98b103a4fa7f",
            "receivedResponse": {
                "id": "641be0750f7d98b103a4fa7f0.5047286292597908",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Plu la rapidité du retour de mail merci ",
                "creationDate": "2023-03-23T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-23T05:15:33.015Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "david.tanguy@saur.com",
            "CustomerContactEmail": "david.tanguy@saur.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TANGUY",
            "CustomerContactFirstName": "David",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAUR SARZEAU",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "DESJONQUERES",
            "XylemContactedPersonRole": "Technico-commercial pièces de rechange",
            "CustomerReference": "",
            "XylemReference": "223022704",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Plu la rapidité du retour de mail merci ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0750f7d98b103a4fa86",
        "extendedResponse": {
            "_id": "64240fda88abc1f06bc7eabb",
            "submittedBody": {
                "id": "641be0750f7d98b103a4fa860.038975305333374566",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "réactivité - précision\n",
                "creationDate": "2023-03-23T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christophe.andre@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "df7a8f32-fbab-44b1-a1c1-b1a44d510240",
            "responseId": "641be0750f7d98b103a4fa86",
            "receivedResponse": {
                "id": "641be0750f7d98b103a4fa860.038975305333374566",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "réactivité - précision\n",
                "creationDate": "2023-03-23T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-23T05:15:33.034Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "christophe.andre@suez.com",
            "CustomerContactEmail": "christophe.andre@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "ANDRE",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "+33672980260",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (DEGREMONT) (STEP HOUPLIN)",
            "CustomerCompanyGroupName": "DEGREMONT (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022456",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "réactivité - précision\n",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "manque les délais d'appro que j'avais demandé"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0756600361b9f982927",
        "extendedResponse": {
            "_id": "64240fda88abc1f06bc7eabc",
            "submittedBody": {
                "id": "641be0756600361b9f9829270.10128302179924287",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité",
                "creationDate": "2023-03-23T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "benoit.swaenepoel@groupe-sarp.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ce68c960-c894-4867-83d2-5e363f3810d0",
            "responseId": "641be0756600361b9f982927",
            "receivedResponse": {
                "id": "641be0756600361b9f9829270.10128302179924287",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité",
                "creationDate": "2023-03-23T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-23T05:15:33.120Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "benoit.swaenepoel@groupe-sarp.com",
            "CustomerContactEmail": "benoit.swaenepoel@groupe-sarp.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SWAENEPOEL",
            "CustomerContactFirstName": "BENOIT",
            "CustomerContactMobilePhone": "0619820507",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BACHELET-BONNEFOND SAS",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rachid",
            "XylemSalespeopleName": "ABDELKRIM",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "C30101321",
            "XylemReference": "774890",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0756600361b9f98295e",
        "extendedResponse": {
            "_id": "64240fdb88abc1f06bc7eabd",
            "submittedBody": {
                "id": "641be0756600361b9f98295e0.620445556360971",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Fournisseur très réactif",
                "creationDate": "2023-03-23T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "veronique.albinet@rubix.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "093a890d-d815-4d4d-9ddc-59070e8b500b",
            "responseId": "641be0756600361b9f98295e",
            "receivedResponse": {
                "id": "641be0756600361b9f98295e0.620445556360971",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Fournisseur très réactif",
                "creationDate": "2023-03-23T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-23T05:15:33.173Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "veronique.albinet@rubix.com",
            "CustomerContactEmail": "veronique.albinet@rubix.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BRAMMER",
            "CustomerCompanyGroupName": "RUBIX",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Lea",
            "XylemSalespeopleName": "FATOWIEZ",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Sarah OLIVEIRA",
            "XylemContactedPersonRole": "",
            "CustomerReference": "301-23*1117*AEJ796",
            "XylemReference": "774905",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Fournisseur très réactif",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0750f7d98b103a4fca6",
        "extendedResponse": {
            "_id": "64240fdb88abc1f06bc7eabe",
            "submittedBody": {
                "id": "641be0750f7d98b103a4fca60.7196934516646107",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien à signaler de négatif, au contraire, les équipes sont réactives et efficaces  ",
                "creationDate": "2023-03-23T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thibault.leroy@svph.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "db04b4ce-5d7b-47c1-b6f3-8cc441ed4e1a",
            "responseId": "641be0750f7d98b103a4fca6",
            "receivedResponse": {
                "id": "641be0750f7d98b103a4fca60.7196934516646107",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien à signaler de négatif, au contraire, les équipes sont réactives et efficaces ",
                "creationDate": "2023-03-23T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos",
                "ressenti.neg",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-23T05:15:33.499Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "thibault.leroy@svph.fr",
            "CustomerContactEmail": "thibault.leroy@svph.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LEROY",
            "CustomerContactFirstName": "THIBAULT",
            "CustomerContactMobilePhone": "0696304008",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SVPH MARTINIQUE",
            "CustomerCompanyGroupName": "SVPH",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Ludovic",
            "XylemSalespeopleName": "TIERRIE DT",
            "XylemContactedPersonFirstName": "Reynald",
            "XylemContactedPersonName": "MEGRET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022635",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien à signaler de négatif, au contraire, les équipes sont réactives et efficaces  ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0750f7d98b103a4fcd8",
        "extendedResponse": {
            "_id": "64240fdb88abc1f06bc7eabf",
            "submittedBody": {
                "id": "641be0750f7d98b103a4fcd80.13676262177365706",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité !",
                "creationDate": "2023-03-23T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.lavalle@sesem.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "14c5ba59-d919-49ed-84f9-3e0342faecaf",
            "responseId": "641be0750f7d98b103a4fcd8",
            "receivedResponse": {
                "id": "641be0750f7d98b103a4fcd80.13676262177365706",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité !",
                "creationDate": "2023-03-23T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-23T05:15:33.521Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "olivier.lavalle@sesem.fr",
            "CustomerContactEmail": "olivier.lavalle@sesem.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAVALLE",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SESEM SA",
            "CustomerCompanyGroupName": "SESEM",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Léa",
            "XylemContactedPersonName": "TANNOUS",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022636",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité !",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641be0750f7d98b103a4fd17",
        "extendedResponse": {
            "_id": "64240fdc88abc1f06bc7eac0",
            "submittedBody": {
                "id": "641be0750f7d98b103a4fd170.8535577364923155",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les +: Réponses rapide, Toujours à l'écoute,  Très bon accueil que ce soit du commercial ou de la sédentaire, réponse toujours en phase avec  ce que demande le client...\nLes - : RAS",
                "creationDate": "2023-03-23T06:15:33+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "herve.breant@satrouen.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "b2d54833-5977-4f31-886c-3165e32c3eb4",
            "responseId": "641be0750f7d98b103a4fd17",
            "receivedResponse": {
                "id": "641be0750f7d98b103a4fd170.8535577364923155",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les +: Réponses rapide, Toujours à l'écoute, Très bon accueil que ce soit du commercial ou de la sédentaire, réponse toujours en phase avec ce que demande le client...\nLes - : RAS",
                "creationDate": "2023-03-23T06:15:33Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "accueil.pos",
                "ecoute.pos",
                "ressenti.pos",
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-23T05:15:33.566Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "herve.breant@satrouen.fr",
            "CustomerContactEmail": "herve.breant@satrouen.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BREANT",
            "CustomerContactFirstName": "HERVe",
            "CustomerContactMobilePhone": "0627681437",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAT",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Christophe",
            "XylemSalespeopleName": "DOUAY",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022671",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les +: Réponses rapide, Toujours à l'écoute,  Très bon accueil que ce soit du commercial ou de la sédentaire, réponse toujours en phase avec  ce que demande le client...\nLes - : RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f2496a85ded61da946",
        "extendedResponse": {
            "_id": "64240fdc88abc1f06bc7eac1",
            "submittedBody": {
                "id": "641d31f2496a85ded61da9460.5127907371026945",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le service commercial la réactivité  et la disponibilité du matériel  ",
                "creationDate": "2023-03-24T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "c.lafon@sobebo.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "2a4520d9-1cc0-4afd-8cd2-968f622d0bc1",
            "responseId": "641d31f2496a85ded61da946",
            "receivedResponse": {
                "id": "641d31f2496a85ded61da9460.5127907371026945",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le service commercial la réactivité et la disponibilité du matériel ",
                "creationDate": "2023-03-24T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Rental"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-24T05:15:30.354Z",
        "touchpoint": "Rental",
        "filename": "XylemFrance_Rental_2023-03-21_06.15.06",
        "integration": {
            "ContactKey": "c.lafon@sobebo.com",
            "CustomerContactEmail": "c.lafon@sobebo.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LAFON",
            "CustomerContactFirstName": "Christophe",
            "CustomerContactMobilePhone": "0608523301",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOBEBO",
            "CustomerCompanyGroupName": "SOBEBO",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "Eric",
            "XylemContactedPersonName": "VERGNES",
            "XylemContactedPersonRole": "Resp. regional Service & Location",
            "CustomerReference": "220789",
            "XylemReference": "773545",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le service commercial la réactivité  et la disponibilité du matériel  ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f29a0c8a5ace1629ee",
        "extendedResponse": {
            "_id": "64240fdc88abc1f06bc7eac2",
            "submittedBody": {
                "id": "641d31f29a0c8a5ace1629ee0.4675129868160248",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "RAS",
                "creationDate": "2023-03-24T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "axel.corneroute@sapian.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "168895d1-3f05-49d9-b3eb-38ca61cea1e7",
            "responseId": "641d31f29a0c8a5ace1629ee",
            "receivedResponse": {
                "id": "641d31f29a0c8a5ace1629ee0.4675129868160248",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "RAS",
                "creationDate": "2023-03-24T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.ras",
                                    "label": "No comment (neu)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ressenti.ras"
            ],
            "listOfTagObjects": [
                {
                    "name": "ressenti.ras",
                    "label": "No comment (neu)",
                    "suffix": "ras"
                }
            ]
        },
        "date": "2023-03-24T05:15:30.450Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-23_06.15.09",
        "integration": {
            "ContactKey": "axel.corneroute@sapian.fr",
            "CustomerContactEmail": "axel.corneroute@sapian.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CORNEROUTE",
            "CustomerContactFirstName": "Axel",
            "CustomerContactMobilePhone": "0684551324",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAPIAN",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Sonia",
            "XylemSalespeopleName": "HUBERT",
            "XylemContactedPersonFirstName": "Sonia",
            "XylemContactedPersonName": "HUBERT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "RAS",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f2496a85ded61db5b0",
        "extendedResponse": {
            "_id": "64240fdd88abc1f06bc7eac3",
            "submittedBody": {
                "id": "641d31f2496a85ded61db5b00.35891195341801274",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La recherche d'informations complémentaire et la rapidité de réponse",
                "creationDate": "2023-03-24T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "pascal.baudry@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "d29f3e2b-6780-4dac-90de-d7f71ad875ac",
            "responseId": "641d31f2496a85ded61db5b0",
            "receivedResponse": {
                "id": "641d31f2496a85ded61db5b00.35891195341801274",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La recherche d'informations complémentaire et la rapidité de réponse",
                "creationDate": "2023-03-24T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-24T05:15:30.944Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-24_06.15.06",
        "integration": {
            "ContactKey": "pascal.baudry@suez.com",
            "CustomerContactEmail": "pascal.baudry@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BAUDRY",
            "CustomerContactFirstName": "Pascal",
            "CustomerContactMobilePhone": "+33680340627",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUEZ (EAU ET FORCE) (STEP WAVRECHAIN)",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Maryse",
            "XylemSalespeopleName": "VANOVERBERGHE",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023011",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La recherche d'informations complémentaire et la rapidité de réponse",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f2496a85ded61db5b4",
        "extendedResponse": {
            "_id": "64240fdd88abc1f06bc7eac4",
            "submittedBody": {
                "id": "641d31f2496a85ded61db5b40.03666807952012241",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La personne qui s'occupe des devis est très aimable et à l'écoute.\nElle s'exprime correctement et compréhensible.",
                "creationDate": "2023-03-24T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "hichem.ben-saada@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "da572a3b-707a-4fc2-9466-dcb8eb8ad986",
            "responseId": "641d31f2496a85ded61db5b4",
            "receivedResponse": {
                "id": "641d31f2496a85ded61db5b40.03666807952012241",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La personne qui s'occupe des devis est très aimable et à l'écoute.\nElle s'exprime correctement et compréhensible.",
                "creationDate": "2023-03-24T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "accueil.pos",
                                    "label": "Welcome / Kindness (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "accueil.pos",
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "accueil.pos",
                    "label": "Welcome / Kindness (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-24T05:15:30.955Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-24_06.15.06",
        "integration": {
            "ContactKey": "hichem.ben-saada@suez.com",
            "CustomerContactEmail": "hichem.ben-saada@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BEN SAADA",
            "CustomerContactFirstName": "HICHEM",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SEVESC",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023022",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La personne qui s'occupe des devis est très aimable et à l'écoute.\nElle s'exprime correctement et compréhensible.",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Rien mon expérience avec Xylem est assez reconnue pour ne rien en reprocher. "
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f3496a85ded61db9ff",
        "extendedResponse": {
            "_id": "64240fdd88abc1f06bc7eac5",
            "submittedBody": {
                "id": "641d31f3496a85ded61db9ff0.6483510692938788",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": " + : l'échange et l'intérêt porté au sujet pour y répondre le plus justement. L'écoute de mon interlocutrice et son professionnalisme.\n\n- : la difficulté de trouver le contact commercial local. Un carto en ligne avec les adresses mails par secteur et par branche serait un vrai plus.",
                "creationDate": "2023-03-24T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "d.lietaert@bouygues-construction.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0b3ba83e-2bcc-4c87-8a2e-8862f8fcc40e",
            "responseId": "641d31f3496a85ded61db9ff",
            "receivedResponse": {
                "id": "641d31f3496a85ded61db9ff0.6483510692938788",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": " + : l'échange et l'intérêt porté au sujet pour y répondre le plus justement. L'écoute de mon interlocutrice et son professionnalisme.\n\n- : la difficulté de trouver le contact commercial local. Un carto en ligne avec les adresses mails par secteur et par branche serait un vrai plus.",
                "creationDate": "2023-03-24T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-24T05:15:31.391Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-24_06.15.06",
        "integration": {
            "ContactKey": "d.lietaert@bouygues-construction.com",
            "CustomerContactEmail": "d.lietaert@bouygues-construction.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LIETAERT",
            "CustomerContactFirstName": "DAMIEN",
            "CustomerContactMobilePhone": "0660492002",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "BOUYGUES CONSTRUCTION",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Flavio",
            "XylemSalespeopleName": "MEDARDONI",
            "XylemContactedPersonFirstName": "Annie",
            "XylemContactedPersonName": "GRENU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022964",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": " + : l'échange et l'intérêt porté au sujet pour y répondre le plus justement. L'écoute de mon interlocutrice et son professionnalisme.\n\n- : la difficulté de trouver le contact commercial local. Un carto en ligne avec les adresses mails par secteur et par branche serait un vrai plus.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f3496a85ded61dba6a",
        "extendedResponse": {
            "_id": "64240fde88abc1f06bc7eac6",
            "submittedBody": {
                "id": "641d31f3496a85ded61dba6a0.34918462763970237",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "D'être rappelé pour valider les solutions ",
                "creationDate": "2023-03-24T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "christian.gameiro@ciabrinibtp.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a3b8888c-4c55-4aac-b333-5867627d6c24",
            "responseId": "641d31f3496a85ded61dba6a",
            "receivedResponse": {
                "id": "641d31f3496a85ded61dba6a0.34918462763970237",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "D'être rappelé pour valider les solutions ",
                "creationDate": "2023-03-24T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-24T05:15:31.491Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-24_06.15.06",
        "integration": {
            "ContactKey": "christian.gameiro@ciabrinibtp.com",
            "CustomerContactEmail": "christian.gameiro@ciabrinibtp.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "GAMEIRO",
            "CustomerContactFirstName": "Christian",
            "CustomerContactMobilePhone": "0609094009",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CIABRINI GUILLAUME BTP",
            "CustomerCompanyGroupName": "CIABRINI GUILLAUME BTP",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Julien",
            "XylemContactedPersonName": "VOIDIE",
            "XylemContactedPersonRole": "Technico-commercial itinérant",
            "CustomerReference": "",
            "XylemReference": "223022942",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "D'être rappelé pour valider les solutions ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f3496a85ded61dbb04",
        "extendedResponse": {
            "_id": "64240fde88abc1f06bc7eac7",
            "submittedBody": {
                "id": "641d31f3496a85ded61dbb040.06232654462408305",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La rapidité pour le traitement de ma demande",
                "creationDate": "2023-03-24T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "frederic.tardieu@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "0786e0b5-1ca4-4f9a-9ea8-cba723892d61",
            "responseId": "641d31f3496a85ded61dbb04",
            "receivedResponse": {
                "id": "641d31f3496a85ded61dbb040.06232654462408305",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La rapidité pour le traitement de ma demande",
                "creationDate": "2023-03-24T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-24T05:15:31.559Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-24_06.15.06",
        "integration": {
            "ContactKey": "frederic.tardieu@veolia.com",
            "CustomerContactEmail": "frederic.tardieu@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TARDIEU",
            "CustomerContactFirstName": "Frédéric",
            "CustomerContactMobilePhone": "0620888588",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "KYRNOLIA",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "VOIDIE",
            "XylemContactedPersonFirstName": "Dimitri",
            "XylemContactedPersonName": "JEHU",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023063",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La rapidité pour le traitement de ma demande",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641d31f3496a85ded61dbb09",
        "extendedResponse": {
            "_id": "64240fde88abc1f06bc7eac8",
            "submittedBody": {
                "id": "641d31f3496a85ded61dbb090.9525525147525402",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Rien de particulier à vous signaler,satisfait de vos services.",
                "creationDate": "2023-03-24T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "olivier.delpouve@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "ea13031b-f893-42d9-a0a8-7682ae38dff7",
            "responseId": "641d31f3496a85ded61dbb09",
            "receivedResponse": {
                "id": "641d31f3496a85ded61dbb090.9525525147525402",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Rien de particulier à vous signaler,satisfait de vos services.",
                "creationDate": "2023-03-24T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-24T05:15:31.570Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-24_06.15.06",
        "integration": {
            "ContactKey": "olivier.delpouve@veolia.com",
            "CustomerContactEmail": "olivier.delpouve@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DELPOUVE",
            "CustomerContactFirstName": "Olivier",
            "CustomerContactMobilePhone": "+33626915683",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA EAU (AGENCE BRUAY)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023066",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Rien de particulier à vous signaler,satisfait de vos services.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e8372496a85ded6bcafbf",
        "extendedResponse": {
            "_id": "64240fde88abc1f06bc7eac9",
            "submittedBody": {
                "id": "641e8372496a85ded6bcafbf0.1746970194332471",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Erreur d’adresse de livraison\nPièces livrées avec 15 jours de retard",
                "creationDate": "2023-03-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0677340334"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "ad580f86-06e1-4733-b1ee-d5c8e9248f76",
            "responseId": "641e8372496a85ded6bcafbf",
            "receivedResponse": {
                "id": "641e8372496a85ded6bcafbf0.1746970194332471",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Erreur d adresse de livraison\nPièces livrées avec 15 jours de retard",
                "creationDate": "2023-03-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-25T05:15:30.572Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "0677340334",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "SAUTONIE Frederic",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0677340334",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HAINAUT MAINTENANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Jean-Philippe",
            "XylemSalespeopleName": "VALTER",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "22427",
            "XylemReference": "206005",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Erreur d’adresse de livraison\nPièces livrées avec 15 jours de retard",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e83729a0c8a5ace97d0ba",
        "extendedResponse": {
            "_id": "64240fdf88abc1f06bc7eaca",
            "submittedBody": {
                "id": "641e83729a0c8a5ace97d0ba0.767034662535532",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réponse Rapide",
                "creationDate": "2023-03-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "f.desfontaine@groupe-seche.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "e5d37050-fd65-4fcf-9d4b-ab7d9fa4195d",
            "responseId": "641e83729a0c8a5ace97d0ba",
            "receivedResponse": {
                "id": "641e83729a0c8a5ace97d0ba0.767034662535532",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réponse Rapide",
                "creationDate": "2023-03-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-25T05:15:30.647Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "f.desfontaine@groupe-seche.com",
            "CustomerContactEmail": "f.desfontaine@groupe-seche.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "DESFONTAINE",
            "CustomerContactFirstName": "FLORIAN",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SECHE ECO SERVICES",
            "CustomerCompanyGroupName": "SECHE TRAITEMENT EAUX INDUSTR.",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Thibault",
            "XylemSalespeopleName": "LE DEAN",
            "XylemContactedPersonFirstName": "Christelle",
            "XylemContactedPersonName": "CHOTEAU",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023006",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réponse Rapide",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Le délai légèrement long"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e83729a0c8a5ace97d1b9",
        "extendedResponse": {
            "_id": "64240fdf88abc1f06bc7eacb",
            "submittedBody": {
                "id": "641e83729a0c8a5ace97d1b90.5297134441783202",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Délai correct pour recevoir le devis",
                "creationDate": "2023-03-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "lhubeau@emma40.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "83d30a60-8e49-4573-b819-ddf73c9ea99f",
            "responseId": "641e83729a0c8a5ace97d1b9",
            "receivedResponse": {
                "id": "641e83729a0c8a5ace97d1b90.5297134441783202",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Délai correct pour recevoir le devis",
                "creationDate": "2023-03-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-25T05:15:30.887Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "lhubeau@emma40.fr",
            "CustomerContactEmail": "lhubeau@emma40.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "HUBEAU",
            "CustomerContactFirstName": "LAURENT",
            "CustomerContactMobilePhone": "0679998034",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EMMA",
            "CustomerCompanyGroupName": "EMMA EAUX MARENSIN MARENNE",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Vincent",
            "XylemSalespeopleName": "ACHARD",
            "XylemContactedPersonFirstName": "Younes",
            "XylemContactedPersonName": "LAABID",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023475",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Délai correct pour recevoir le devis",
            "generalRating": "4",
            "improvementSuggestionsVerbatim": "Être moins cher ( hausse de 3600€ en 3 ans sur la même reference prix initale de 6100€)"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e83729a0c8a5ace97d210",
        "extendedResponse": {
            "_id": "64240fdf88abc1f06bc7eacc",
            "submittedBody": {
                "id": "641e83729a0c8a5ace97d2100.44366075952131667",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "bonne reactivité",
                "creationDate": "2023-03-25T06:15:30+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thierry.perraudin@climelec.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "93da6adb-68b3-4f00-bee4-78f79cc7cbe8",
            "responseId": "641e83729a0c8a5ace97d210",
            "receivedResponse": {
                "id": "641e83729a0c8a5ace97d2100.44366075952131667",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "bonne réactivité",
                "creationDate": "2023-03-25T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-25T05:15:30.964Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "thierry.perraudin@climelec.fr",
            "CustomerContactEmail": "thierry.perraudin@climelec.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "PERRAUDIN",
            "CustomerContactFirstName": "Thierry",
            "CustomerContactMobilePhone": "0682587309",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CLIMELEC Entreprise",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Christian",
            "XylemSalespeopleName": "DODDI",
            "XylemContactedPersonFirstName": "ARNAUD",
            "XylemContactedPersonName": "JAMELIN",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023001",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "bonne reactivité",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e83739a0c8a5ace97d242",
        "extendedResponse": {
            "_id": "64240fe088abc1f06bc7eacd",
            "submittedBody": {
                "id": "641e83739a0c8a5ace97d2420.2894861381850322",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Disponibilité et écoute ",
                "creationDate": "2023-03-25T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "franck.kowalewski@suez.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "2b4c6c9c-73cf-48c7-bb57-37ce0652210d",
            "responseId": "641e83739a0c8a5ace97d242",
            "receivedResponse": {
                "id": "641e83739a0c8a5ace97d2420.2894861381850322",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Disponibilité et écoute ",
                "creationDate": "2023-03-25T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-25T05:15:31.034Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "franck.kowalewski@suez.com",
            "CustomerContactEmail": "franck.kowalewski@suez.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "KOWALEWSKI",
            "CustomerContactFirstName": "franck",
            "CustomerContactMobilePhone": "0678700561",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LYONNAISE DES EAUX FRANCE",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023222",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Disponibilité et écoute ",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e83739a0c8a5ace97d2cd",
        "extendedResponse": {
            "_id": "64240fe088abc1f06bc7eace",
            "submittedBody": {
                "id": "641e83739a0c8a5ace97d2cd0.8900098616135315",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité, l'écoute par rapport à nos besoins",
                "creationDate": "2023-03-25T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "l.kalid-torres@aquadep.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "1fd152dc-232c-4d1b-a9ab-028d674d19d7",
            "responseId": "641e83739a0c8a5ace97d2cd",
            "receivedResponse": {
                "id": "641e83739a0c8a5ace97d2cd0.8900098616135315",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité, l'écoute par rapport à nos besoins",
                "creationDate": "2023-03-25T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-25T05:15:31.129Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "l.kalid-torres@aquadep.com",
            "CustomerContactEmail": "l.kalid-torres@aquadep.com",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "KALID TORRES",
            "CustomerContactFirstName": "Leyla",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SOCIETE NOUVELLE NANTAISE DES EAUX",
            "CustomerCompanyGroupName": "STE NOUVELLE NANTAISE DES EAUX",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Pierrick",
            "XylemSalespeopleName": "FRAPPAS",
            "XylemContactedPersonFirstName": "Rachid",
            "XylemContactedPersonName": "ABDELKRIM",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023267",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité, l'écoute par rapport à nos besoins",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e83739a0c8a5ace97d321",
        "extendedResponse": {
            "_id": "64240fe088abc1f06bc7eacf",
            "submittedBody": {
                "id": "641e83739a0c8a5ace97d3210.27944265970601934",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Très bonne réactivité ",
                "creationDate": "2023-03-25T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "rcavaille@lri-sodime.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "dcc5b40c-87fe-4386-a1e9-a9f4087a8993",
            "responseId": "641e83739a0c8a5ace97d321",
            "receivedResponse": {
                "id": "641e83739a0c8a5ace97d3210.27944265970601934",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Très bonne réactivité ",
                "creationDate": "2023-03-25T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-25T05:15:31.182Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "rcavaille@lri-sodime.com",
            "CustomerContactEmail": "rcavaille@lri-sodime.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CAVAILLE",
            "CustomerContactFirstName": "ROMAIN",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "LA ROBINETTERIE INDUSTRIELLE - LRI SODIME",
            "CustomerCompanyGroupName": "LRI (NATIONAL)",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Frédéric",
            "XylemSalespeopleName": "BOUZIGON",
            "XylemContactedPersonFirstName": "Vincent",
            "XylemContactedPersonName": "IDRIS",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023303",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Très bonne réactivité ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "641e83739a0c8a5ace97d328",
        "extendedResponse": {
            "_id": "64240fe188abc1f06bc7ead0",
            "submittedBody": {
                "id": "641e83739a0c8a5ace97d3280.33216583046056547",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité m'a plu",
                "creationDate": "2023-03-25T06:15:31+01:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "maxime-nagone@samse.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "02191aca-fbed-467f-9350-4a1687b4e33a",
            "responseId": "641e83739a0c8a5ace97d328",
            "receivedResponse": {
                "id": "641e83739a0c8a5ace97d3280.33216583046056547",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité m'a plu",
                "creationDate": "2023-03-25T06:15:31Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-25T05:15:31.189Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "maxime-nagone@samse.fr",
            "CustomerContactEmail": "maxime-nagone@samse.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "NAGONE",
            "CustomerContactFirstName": "MAXIME",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CELESTIN MATERIAUX",
            "CustomerCompanyGroupName": "SAMSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Tony",
            "XylemSalespeopleName": "GENEVE",
            "XylemContactedPersonFirstName": "Esther",
            "XylemContactedPersonName": "SCHNEIDER",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023305",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité m'a plu",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e0ef0656196abbdc9e",
        "extendedResponse": {
            "_id": "64240fe188abc1f06bc7ead1",
            "submittedBody": {
                "id": "642269e0ef0656196abbdc9e0.9912949722580939",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Retard de livraison qui décale notre chantier d'une semaine",
                "creationDate": "2023-03-28T06:15:28+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "melina.sohier.ext@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "ce251750-36bc-4ea7-bbe1-def7b5aff0ff",
            "responseId": "642269e0ef0656196abbdc9e",
            "receivedResponse": {
                "id": "642269e0ef0656196abbdc9e0.9912949722580939",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Retard de livraison qui décale notre chantier d'une semaine",
                "creationDate": "2023-03-28T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-28T04:15:28.492Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "melina.sohier.ext@veolia.com",
            "CustomerContactEmail": "melina.sohier.ext@veolia.com",
            "CustomerContactSalutation": "",
            "CustomerContactName": "Cher(e) client(e)",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "Société Méridionale d'Environnement_SME",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAURE-PATUREL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "C31500555 Duplicata",
            "XylemReference": "773084",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Retard de livraison qui décale notre chantier d'une semaine",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e0f74d882f19265ec5",
        "extendedResponse": {
            "_id": "64240fe188abc1f06bc7ead2",
            "submittedBody": {
                "id": "642269e0f74d882f19265ec50.7489523996889338",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Commerciale à l'écoute, connaissant ses produits.",
                "creationDate": "2023-03-28T06:15:28+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "molie.darcelin@saga-tertiaire.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "74a33a2b-afb4-4903-8383-87b3616e202a",
            "responseId": "642269e0f74d882f19265ec5",
            "receivedResponse": {
                "id": "642269e0f74d882f19265ec50.7489523996889338",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Commerciale à l'écoute, connaissant ses produits.",
                "creationDate": "2023-03-28T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Customer Visit"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:28.876Z",
        "touchpoint": "Customer Visit",
        "filename": "XylemFrance_CustomerVisit_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "molie.darcelin@saga-tertiaire.fr",
            "CustomerContactEmail": "molie.darcelin@saga-tertiaire.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "DARCELIN",
            "CustomerContactFirstName": "Molie",
            "CustomerContactMobilePhone": "0614736843",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SAGA TERTIAIRE BOIS COLOMBES",
            "CustomerCompanyGroupName": "SAGA TERTIAIRE",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Emilie",
            "XylemSalespeopleName": "NOIROT",
            "XylemContactedPersonFirstName": "Emilie",
            "XylemContactedPersonName": "NOIROT",
            "XylemContactedPersonRole": "Technico-commerciale Itinérante",
            "CustomerReference": "",
            "XylemReference": "",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Commerciale à l'écoute, connaissant ses produits.",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e0ef0656196abbe803",
        "extendedResponse": {
            "_id": "64240fe188abc1f06bc7ead3",
            "submittedBody": {
                "id": "642269e0ef0656196abbe8030.8169424027980465",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la réactivité ",
                "creationDate": "2023-03-28T06:15:28+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "nicolas.ribler@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 4,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "messageId": "d6f934ee-abb4-4531-83b3-70ac3e5d7f3c",
            "responseId": "642269e0ef0656196abbe803",
            "receivedResponse": {
                "id": "642269e0ef0656196abbe8030.8169424027980465",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la réactivité ",
                "creationDate": "2023-03-28T06:15:28Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "4"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:28.947Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "nicolas.ribler@veolia.com",
            "CustomerContactEmail": "nicolas.ribler@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "RIBLER",
            "CustomerContactFirstName": "Nicolas",
            "CustomerContactMobilePhone": "0623591634",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA STATION EPURATION",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3725591",
            "XylemReference": "775523",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la réactivité ",
            "generalRating": "4"
        },
        "answersCalculated": {
            "generalRating": 4
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1df6864a747c6c3a2",
        "extendedResponse": {
            "_id": "64240fe288abc1f06bc7ead4",
            "submittedBody": {
                "id": "642269e1df6864a747c6c3a20.6461708303309015",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Les délais de livraison est long…\nCommandé vendredi 17 et pompe reçu lundi 27",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "0613843525"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "49d06a7a-6580-4e35-bf31-b163ca68cab3",
            "responseId": "642269e1df6864a747c6c3a2",
            "receivedResponse": {
                "id": "642269e1df6864a747c6c3a20.6461708303309015",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Les délais de livraison est long \nCommandé vendredi 17 et pompe reçu lundi 27",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.neg",
                                    "label": "Lead time (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "delai.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.neg",
                    "label": "Lead time (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.020Z",
        "touchpoint": "Delivery",
        "filename": "XylemFrance_Delivery_2023-03-25_06.15.08",
        "integration": {
            "ContactKey": "0613843525",
            "CustomerContactEmail": "",
            "CustomerContactSalutation": "",
            "CustomerContactName": "ACCUEIL",
            "CustomerContactFirstName": "",
            "CustomerContactMobilePhone": "0613843525",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "EVEL UP",
            "CustomerCompanyGroupName": "SCA EVEL'UP",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Dominique",
            "XylemSalespeopleName": "KEROUANTON",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Isabelle EDELINE",
            "XylemContactedPersonRole": "",
            "CustomerReference": "BC154746",
            "XylemReference": "208312",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Les délais de livraison est long…\nCommandé vendredi 17 et pompe reçu lundi 27",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbe863",
        "extendedResponse": {
            "_id": "64240fe288abc1f06bc7ead5",
            "submittedBody": {
                "id": "642269e1ef0656196abbe8630.522210114591682",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Réactivité du personnel et disponibilité des pièces.",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "sconde@hydralians.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "115cd052-fe8f-400b-b678-fe6cbe1980dc",
            "responseId": "642269e1ef0656196abbe863",
            "receivedResponse": {
                "id": "642269e1ef0656196abbe8630.522210114591682",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Réactivité du personnel et disponibilité des pièces.",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "dispo.pos",
                                    "label": "Availabity / Stock (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "dispo.pos",
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "dispo.pos",
                    "label": "Availabity / Stock (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.082Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "sconde@hydralians.com",
            "CustomerContactEmail": "sconde@hydralians.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "CONDE",
            "CustomerContactFirstName": "STEPHANE",
            "CustomerContactMobilePhone": "0682312489",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "HYDRALIANS - SOMAIR GERVAT",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Sarah",
            "XylemSalespeopleName": "NERON",
            "XylemContactedPersonFirstName": "Maryse",
            "XylemContactedPersonName": "VANOVERBERGHE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023954",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Réactivité du personnel et disponibilité des pièces.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbe889",
        "extendedResponse": {
            "_id": "64240fe288abc1f06bc7ead6",
            "submittedBody": {
                "id": "642269e1ef0656196abbe8890.49991671705793217",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Professionnalisme",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.terriot@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "c60dba9b-f2b7-4f9b-8903-fd0676b334ac",
            "responseId": "642269e1ef0656196abbe889",
            "receivedResponse": {
                "id": "642269e1ef0656196abbe8890.49991671705793217",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Professionnalisme",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.pos",
                                    "label": "Skills (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "competence.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "competence.pos",
                    "label": "Skills (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.132Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "philippe.terriot@veolia.com",
            "CustomerContactEmail": "philippe.terriot@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "TERRIOT",
            "CustomerContactFirstName": "Philippe",
            "CustomerContactMobilePhone": "0603346900",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "M.S.E / OTV",
            "CustomerCompanyGroupName": "VWS - OTV - MSE (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Rémi",
            "XylemSalespeopleName": "BOUQUIN",
            "XylemContactedPersonFirstName": "Laurence",
            "XylemContactedPersonName": "GUILLEMINE",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023588",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Professionnalisme",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbe8ee",
        "extendedResponse": {
            "_id": "64240fe388abc1f06bc7ead7",
            "submittedBody": {
                "id": "642269e1ef0656196abbe8ee0.5649862787532294",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Date de livraison rapide et conforme au devis",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "antoine.thomas@veolia.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "867d1cf7-9e07-4eb4-96c7-7a5ca406f1cb",
            "responseId": "642269e1ef0656196abbe8ee",
            "receivedResponse": {
                "id": "642269e1ef0656196abbe8ee0.5649862787532294",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Date de livraison rapide et conforme au devis",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "prix.autre",
                                    "label": "Price (neu)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "prix.autre",
                "delai.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "prix.autre",
                    "label": "Price (neu)",
                    "suffix": "autre"
                },
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.265Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "antoine.thomas@veolia.com",
            "CustomerContactEmail": "antoine.thomas@veolia.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "THOMAS",
            "CustomerContactFirstName": "Antoine",
            "CustomerContactMobilePhone": "0603500636",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "VEOLIA TRAVAUX NEUFS (SAINT OMER)",
            "CustomerCompanyGroupName": "VEOLIA EAU (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Eric",
            "XylemSalespeopleName": "MAECKEREEL",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Celine BOUYER",
            "XylemContactedPersonRole": "",
            "CustomerReference": "AZZ3727198",
            "XylemReference": "775637",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Date de livraison rapide et conforme au devis",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbe92e",
        "extendedResponse": {
            "_id": "64240fe388abc1f06bc7ead8",
            "submittedBody": {
                "id": "642269e1ef0656196abbe92e0.12222878931842884",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui m'a plu est le suivi et la réactivité des équipes XYLEM TOULOUSE sur ce dossier",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "bobinage-gonzalez@wanadoo.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "16907782-e272-45b8-bc32-78da6d4960bf",
            "responseId": "642269e1ef0656196abbe92e",
            "receivedResponse": {
                "id": "642269e1ef0656196abbe92e0.12222878931842884",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui m'a plu est le suivi et la réactivité des équipes XYLEM TOULOUSE sur ce dossier",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "service.pos",
                                    "label": "Quality of service / Follow-up (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.pos",
                                    "label": "Feeling (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Delivery date"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "service.pos",
                "efficacite.pos",
                "ressenti.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "service.pos",
                    "label": "Quality of service / Follow-up (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ressenti.pos",
                    "label": "Feeling (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.346Z",
        "touchpoint": "Delivery date",
        "filename": "XylemFrance_Deliverydate_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "bobinage-gonzalez@wanadoo.fr",
            "CustomerContactEmail": "bobinage-gonzalez@wanadoo.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "AGUILERA",
            "CustomerContactFirstName": "Fernand",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "ABE GONZALEZ",
            "CustomerCompanyGroupName": "ABE GONZALEZ",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Régis",
            "XylemSalespeopleName": "PARRENS",
            "XylemContactedPersonFirstName": "",
            "XylemContactedPersonName": "Khadra RAHAOUI",
            "XylemContactedPersonRole": "",
            "CustomerReference": "2021069",
            "XylemReference": "775669",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui m'a plu est le suivi et la réactivité des équipes XYLEM TOULOUSE sur ce dossier",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbe97c",
        "extendedResponse": {
            "_id": "64240fe388abc1f06bc7ead9",
            "submittedBody": {
                "id": "642269e1ef0656196abbe97c0.756020940627659",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Il faut vraiment aller à la pêche aux informations lorsque l'on échange avec les technico-commerciaux. Et lorsque l'on reçoit une réponse, elles sont vagues ou incorrectes. Le strict minimum, c'est fastidieux.",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "valery.bador@innovaquaculture.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 2,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "messageId": "647f67ef-2d88-449b-bddb-3cb5f6aad55c",
            "responseId": "642269e1ef0656196abbe97c",
            "receivedResponse": {
                "id": "642269e1ef0656196abbe97c0.756020940627659",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Il faut vraiment aller à la pêche aux informations lorsque l'on échange avec les technico-commerciaux. Et lorsque l'on reçoit une réponse, elles sont vagues ou incorrectes. Le strict minimum, c'est fastidieux.",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "competence.neg",
                                    "label": "Skills (neg)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "2"
                    }
                ]
            },
            "listOfTags": [
                "ecoute.pos",
                "competence.neg",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "competence.neg",
                    "label": "Skills (neg)",
                    "suffix": "neg"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.450Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "valery.bador@innovaquaculture.com",
            "CustomerContactEmail": "valery.bador@innovaquaculture.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BADOR",
            "CustomerContactFirstName": "VALERY",
            "CustomerContactMobilePhone": "0687517171",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "INNOV AQUACULTURE",
            "CustomerCompanyGroupName": "INNOV' AQUACULTURE",
            "XylemMarket": "IND",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Véronique",
            "XylemContactedPersonName": "TREHARD",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223022458",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Il faut vraiment aller à la pêche aux informations lorsque l'on échange avec les technico-commerciaux. Et lorsque l'on reçoit une réponse, elles sont vagues ou incorrectes. Le strict minimum, c'est fastidieux.",
            "generalRating": "2",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 2
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbe9a4",
        "extendedResponse": {
            "_id": "64240fe488abc1f06bc7eada",
            "submittedBody": {
                "id": "642269e1ef0656196abbe9a40.9440710765452376",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Ce qui ne m'a pas plus : Recevoir votre offre après la date limite de remise de l'appel d'offre (alors que vous aviez déjà préparé une offre pour ce même dossier pour un concurrent), 2 semaines auparavant,\n",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "thomas.bouhier@cisetp.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "462449e4-3b70-4acf-ac44-37ea4a06b776",
            "responseId": "642269e1ef0656196abbe9a4",
            "receivedResponse": {
                "id": "642269e1ef0656196abbe9a40.9440710765452376",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Ce qui ne m'a pas plus : Recevoir votre offre après la date limite de remise de l'appel d'offre (alors que vous aviez déjà préparé une offre pour ce même dossier pour un concurrent), 2 semaines auparavant,\n",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "promo.all",
                                    "label": "Promotions",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ressenti.neg",
                                    "label": "Feeling (neg)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "promo.all",
                "ressenti.neg"
            ],
            "listOfTagObjects": [
                {
                    "name": "promo.all",
                    "label": "Promotions",
                    "suffix": "all"
                },
                {
                    "name": "ressenti.neg",
                    "label": "Feeling (neg)",
                    "suffix": "neg"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.502Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "thomas.bouhier@cisetp.com",
            "CustomerContactEmail": "thomas.bouhier@cisetp.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "BOUHIER",
            "CustomerContactFirstName": "Thomas",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "CISE TP NORD OUEST",
            "CustomerCompanyGroupName": "SAUR (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Erwan",
            "XylemSalespeopleName": "CRAQUELIN",
            "XylemContactedPersonFirstName": "Roberto",
            "XylemContactedPersonName": "FERNANDES",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023699",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Ce qui ne m'a pas plus : Recevoir votre offre après la date limite de remise de l'appel d'offre (alors que vous aviez déjà préparé une offre pour ce même dossier pour un concurrent), 2 semaines auparavant,\n",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbf049",
        "extendedResponse": {
            "_id": "64240fe488abc1f06bc7eadb",
            "submittedBody": {
                "id": "642269e1ef0656196abbf0490.9964425336564875",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "la communication rapide,  le service commercial m'a contacté dans la journée de ma demande pour un complément d'information afin de m'établir une proposition tarifaire au plus juste.",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "philippe.lacombe@sreme.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "a3ee63ad-7653-4528-bec7-a18f398223a1",
            "responseId": "642269e1ef0656196abbf049",
            "receivedResponse": {
                "id": "642269e1ef0656196abbf0490.9964425336564875",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "la communication rapide, le service commercial m'a contacté dans la journée de ma demande pour un complément d'information afin de m'établir une proposition tarifaire au plus juste.",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "delai.pos",
                                    "label": "Lead time (pos)",
                                    "nodes": []
                                },
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "ecoute.pos",
                                    "label": "Attentiveness and availability (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "delai.pos",
                "ecoute.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "delai.pos",
                    "label": "Lead time (pos)",
                    "suffix": "pos"
                },
                {
                    "name": "ecoute.pos",
                    "label": "Attentiveness and availability (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.642Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "philippe.lacombe@sreme.fr",
            "CustomerContactEmail": "philippe.lacombe@sreme.fr",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "LACOMBE",
            "CustomerContactFirstName": "PHILIPPE",
            "CustomerContactMobilePhone": "0670749476",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SREME",
            "CustomerCompanyGroupName": "SREME",
            "XylemMarket": "GCL",
            "XylemSalespeopleFirstName": "Thed",
            "XylemSalespeopleName": "SITA",
            "XylemContactedPersonFirstName": "Léa",
            "XylemContactedPersonName": "TANNOUS",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023815",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "la communication rapide,  le service commercial m'a contacté dans la journée de ma demande pour un complément d'information afin de m'établir une proposition tarifaire au plus juste.",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbf21a",
        "extendedResponse": {
            "_id": "64240fe488abc1f06bc7eadc",
            "submittedBody": {
                "id": "642269e1ef0656196abbf21a0.5958712800411201",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "La réactivité m'a plu",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "valerie.amet@queguiner.fr"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "bc0b4daf-a64f-4b18-b0ba-7fd7339c0214",
            "responseId": "642269e1ef0656196abbf21a",
            "receivedResponse": {
                "id": "642269e1ef0656196abbf21a0.5958712800411201",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "La réactivité m'a plu",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "efficacite.pos",
                                    "label": "Efficiency / Reactivity (pos)",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "efficacite.pos"
            ],
            "listOfTagObjects": [
                {
                    "name": "efficacite.pos",
                    "label": "Efficiency / Reactivity (pos)",
                    "suffix": "pos"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.894Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "valerie.amet@queguiner.fr",
            "CustomerContactEmail": "valerie.amet@queguiner.fr",
            "CustomerContactSalutation": "Madame",
            "CustomerContactName": "AMET",
            "CustomerContactFirstName": "Valérie",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "QUEGUINER",
            "CustomerCompanyGroupName": "SAS QUEGUINER MATERIAUX",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Fabrice",
            "XylemSalespeopleName": "ROBIN",
            "XylemContactedPersonFirstName": "Michel",
            "XylemContactedPersonName": "ALBRECHT",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223023955",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "La réactivité m'a plu",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "642269e1ef0656196abbf23a",
        "extendedResponse": {
            "_id": "64240fe588abc1f06bc7eadd",
            "submittedBody": {
                "id": "642269e1ef0656196abbf23a0.7408041778121153",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "Le temps de réponse est bien trop loin, et quand on est pas oublié",
                "creationDate": "2023-03-28T06:15:29+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "dominique.vergne@polynesienne-des-eaux.pf"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 1,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "messageId": "d3dd5cd1-5015-4ac0-806e-266b882a6394",
            "responseId": "642269e1ef0656196abbf23a",
            "receivedResponse": {
                "id": "642269e1ef0656196abbf23a0.7408041778121153",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "Le temps de réponse est bien trop loin, et quand on est pas oublié",
                "creationDate": "2023-03-28T06:15:29Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "negative",
                                    "label": "Négative",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "1"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-28T04:15:29.917Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-28_06.15.08",
        "integration": {
            "ContactKey": "dominique.vergne@polynesienne-des-eaux.pf",
            "CustomerContactEmail": "dominique.vergne@polynesienne-des-eaux.pf",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "VERGNE",
            "CustomerContactFirstName": "Dominique",
            "CustomerContactMobilePhone": "0689724504",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "POLYNESIENNE DES EAUX",
            "CustomerCompanyGroupName": "SUEZ - LYONNAISE DES EAUX (NATIONAL)",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Julien",
            "XylemSalespeopleName": "TRAPP DT",
            "XylemContactedPersonFirstName": "Aurélie",
            "XylemContactedPersonName": "CHERON",
            "XylemContactedPersonRole": "Technico-commerciale sédentaire",
            "CustomerReference": "",
            "XylemReference": "223020528",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "Le temps de réponse est bien trop loin, et quand on est pas oublié",
            "generalRating": "1",
            "shouldContactBecauseOfInsatisfaction": "no"
        },
        "answersCalculated": {
            "generalRating": 1
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    },
    {
        "_id": "6423bb620d447d9c188c71be",
        "extendedResponse": {
            "_id": "64240fe588abc1f06bc7eade",
            "submittedBody": {
                "id": "6423bb620d447d9c188c71be0.9584830777919642",
                "source": "oppy.survey",
                "lang": "fr",
                "title": "",
                "body": "tout",
                "creationDate": "2023-03-29T06:15:30+02:00",
                "organizationId": "xylem.com",
                "statusInConv": "INIT",
                "fromUser": {
                    "userId": "etude@sudvrd.com"
                },
                "toUsers": [
                    {
                        "userId": "1234"
                    }
                ],
                "rating": {
                    "value": 5,
                    "min": 1,
                    "max": 5
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "messageId": "15c2dbf7-2730-4154-b462-dcd600cf7c51",
            "responseId": "6423bb620d447d9c188c71be",
            "receivedResponse": {
                "id": "6423bb620d447d9c188c71be0.9584830777919642",
                "source": "oppy.survey",
                "lang": "fr",
                "titleProcessed": "",
                "bodyProcessed": "tout",
                "creationDate": "2023-03-29T06:15:30Z",
                "organizationId": "xylem.com",
                "classificationResults": {
                    "classificationTrees": [
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "topic",
                            "label": "Motif",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "topic.empty",
                                    "label": "N/A",
                                    "nodes": []
                                }
                            ]
                        },
                        {
                            "nodeType": "CLASSIFICATION",
                            "name": "sentiment",
                            "label": "Satisfaction générale",
                            "nodes": [
                                {
                                    "nodeType": "CLASSIFICATION",
                                    "name": "positive",
                                    "label": "Positive",
                                    "nodes": []
                                }
                            ]
                        }
                    ],
                    "metaTrees": []
                },
                "customDatas": [
                    {
                        "key": "touchpoint",
                        "value": "Quotation"
                    },
                    {
                        "key": "country",
                        "value": "FR"
                    },
                    {
                        "key": "nps",
                        "value": "5"
                    }
                ]
            },
            "listOfTags": [
                "topic.empty"
            ],
            "listOfTagObjects": [
                {
                    "name": "topic.empty",
                    "label": "N/A",
                    "suffix": "empty"
                }
            ]
        },
        "date": "2023-03-29T04:15:30.107Z",
        "touchpoint": "Quotation",
        "filename": "XylemFrance_Quotation_2023-03-29_06.15.06",
        "integration": {
            "ContactKey": "etude@sudvrd.com",
            "CustomerContactEmail": "etude@sudvrd.com",
            "CustomerContactSalutation": "Monsieur",
            "CustomerContactName": "SIGAUT",
            "CustomerContactFirstName": "Walter",
            "CustomerContactMobilePhone": "",
            "CustomerContactLandlinePhone": "",
            "CustomerContactRole": "",
            "CustomerCompanyName": "SUD VRD INGENIERIE",
            "CustomerCompanyGroupName": "",
            "XylemMarket": "MUN",
            "XylemSalespeopleFirstName": "Nicolas",
            "XylemSalespeopleName": "CHARIN",
            "XylemContactedPersonFirstName": "Reynald",
            "XylemContactedPersonName": "MEGRET",
            "XylemContactedPersonRole": "Technico-commercial sédentaire",
            "CustomerReference": "",
            "XylemReference": "223024280",
            "XylemCountry": "FR"
        },
        "answers": {
            "initialVerbatim": "tout",
            "generalRating": "5"
        },
        "answersCalculated": {
            "generalRating": 5
        },
        "integrationCalculated": {
            "subRegion": "South Europe",
            "region": "Europe"
        }
    }
]