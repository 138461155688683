import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColumns,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  flatMap,
  groupBy,
  intersection,
  map,
  mapValues,
  orderBy,
} from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DataContext } from '../DataContext';
import { MultipleSelectChip } from '../GenericChipSelect';
import {
  DashboardFilter,
  DashboardFilters,
} from '../components/DashboardFilter';
import { PerformanceHeader } from '../components/PerformanceHeader';
import { allTouchpoints, iconsMapping, XYLEM_BLUE } from '../constants';
import { usePersistColumnSettings } from '../hooks/usePersistColumnSettings';
import { fromTagPrefixToLightLabel, mapping } from '../viavooMappings';
import { DetailPanelContent } from './MyFollowUp';

const CustomResponsiveContainer = (props: any) => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <ResponsiveContainer {...props} />
      </div>
    </div>
  );
};
const columns: GridColumns = [
  {
    valueGetter: ({ row }) => {
      return row.answers.initialVerbatim;
    },
    field: 'verbatim',
    headerName: 'Verbatim',
    width: 700,
  },
  {
    valueGetter: ({ row }) => {
      return row.answersCalculated.generalRating;
    },
    field: 'rating',
    headerName: 'Rating',
    width: 100,
  },
  {
    valueGetter: ({ row }) => {
      return (row.extendedResponse?.listOfTagObjects || [])
        .map((t: any) => t.label)
        .join(', ');
    },
    field: 'tags',
    headerName: 'Tags',
    width: 400,
  },
  {
    valueGetter: ({ row }) => {
      return row.parsedDate;
    },
    field: 'date',
    headerName: 'Date',
    width: 200,
  },
];

export const DashboardByMood = () => {
  let { xylemResponses: allRowsAll } = useContext(DataContext);
  const [filterState, setFilterState] = useState<DashboardFilter>({
    regions: [] as string[],
    subRegions: [] as string[],
    countries: [] as string[],
    markets: [] as string[],
    companyNames: [] as string[],
    companyGroupNames: [] as string[],
    range: [null, null],
  });
  function onFilterChange(state: DashboardFilter) {
    setFilterState(state);
  }

  const [touchpoints, setTouchpoints] = useState<string[]>([]);
  const [moodCategories, setMoodCategories] = useState<string[]>([]);

  const allRows = useMemo(() => {
    let startDate = (filterState.range && filterState.range[0]) || null;
    let endDate = (filterState.range && filterState.range[1]) || null;
    return allRowsAll.filter(
      (r) =>
        (!touchpoints.length || touchpoints.includes(r.touchpoint)) &&
        (!filterState.regions.length ||
          filterState.regions.includes(r.integrationCalculated.region)) &&
        (!filterState.subRegions.length ||
          filterState.subRegions.includes(r.integrationCalculated.subRegion)) &&
        (!filterState.countries.length ||
          filterState.countries.includes(r.integration.XylemCountry)) &&
        (!filterState.markets.length ||
          filterState.markets.includes(r.integration.XylemMarket)) &&
        (!filterState.companyGroupNames.length ||
          filterState.companyGroupNames.includes(
            r.integration.CustomerCompanyGroupName
          )) &&
        (!filterState.companyNames.length ||
          filterState.companyNames.includes(
            r.integration.CustomerCompanyName
          )) &&
        (!moodCategories.length ||
          intersection(
            moodCategories,
            r.extendedResponse?.listOfTagObjects?.map((x) => x.label) || []
          ).length > 0) &&
        (!startDate || new Date(r.parsedDate) >= startDate) &&
        (!endDate || new Date(r.parsedDate) <= endDate)
    );
  }, [
    allRowsAll,
    filterState.regions,
    filterState.subRegions,
    filterState.countries,
    filterState.markets,
    filterState.companyGroupNames,
    filterState.companyNames,
    filterState.range,
    touchpoints,
    moodCategories,
  ]);

  const allTags = flatMap(
    allRows,
    (r) => r.extendedResponse?.listOfTags || []
  ).filter((t) => t !== 'topic.empty');
  const allResults = allTags
    .map((t) => t.split('.'))
    .map((anArray) => {
      const prefix = anArray.slice(0, anArray.length - 1).join('.');
      const suffix = anArray[anArray.length - 1];
      return {
        suffix,
        prefix,
      };
    })
    .filter((x) => ['neg', 'pos'].includes(x.suffix));

  const allPositives = allResults.filter((x) => x.suffix === 'pos');
  const allNegatives = allResults.filter((x) => x.suffix === 'neg');

  const positivesGroupBy = orderBy(
    map(
      mapValues(
        groupBy(allPositives, (x) => x.prefix),
        (x) => x.length
      ),
      (nb, category) => ({
        nb,
        category,
        SentimentIcon:
          iconsMapping[(category + '.pos') as keyof typeof iconsMapping] ||
          iconsMapping[category as keyof typeof iconsMapping],
      })
    ),
    'nb',
    'desc'
  ).slice(0, 3);

  const negativesGroupBy = orderBy(
    map(
      mapValues(
        groupBy(allNegatives, (x) => x.prefix),
        (x) => x.length
      ),
      (nb, category) => ({
        nb,
        category,
        SentimentIcon:
          iconsMapping[(category + '.neg') as keyof typeof iconsMapping] ||
          iconsMapping[category as keyof typeof iconsMapping],
      })
    ),
    'nb',
    'desc'
  ).slice(0, 3);

  const categoryWeight = orderBy(
    map(
      mapValues(
        groupBy(allResults, (x) => x.prefix),
        (x) => x.length
      ),
      (nb, category) => ({ nb, category })
    ),
    'nb',
    'desc'
  ).slice(0, 10);

  const getNumberOfPositiveForGivenCategory = (cat: string) => {
    return allPositives.filter((x) => x.prefix === cat).length;
  };

  const categoryWeightPercentVersion = categoryWeight.map((x) => {
    const percent = (100 * x.nb) / allResults.length;
    const nbPositive = getNumberOfPositiveForGivenCategory(x.category);
    const nbNegative = x.nb - nbPositive;
    return {
      category: fromTagPrefixToLightLabel(x.category),
      categoryKey: x.category,
      nb: x.nb,
      percent,
      nbPositive,
      nbNegative,
      positivePercent: (100 * nbPositive) / x.nb,
      negativePercent: (100 * nbNegative) / x.nb,
    };
  });

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} noEdit />, []);

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridProProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const apiRef = useGridApiRef();
  usePersistColumnSettings(apiRef, 'mood-grid');

  function applyLabelFilter(label: string) {
    const categories = Object.values(mapping).filter((text) => {
      const suffix = text.replaceAll(/\(.+?\)/g, '').trim();
      return suffix === label;
    });

    setMoodCategories(categories);
  }
  const successData = positivesGroupBy.map(
    ({ nb, category, SentimentIcon }) => {
      return {
        tooltip: fromTagPrefixToLightLabel(category),
        label: `${nb}/${allPositives.length}`,
        Icon: SentimentIcon,
      };
    }
  );
  const errorData = negativesGroupBy.map(({ nb, category, SentimentIcon }) => {
    return {
      tooltip: fromTagPrefixToLightLabel(category),
      label: `${nb}/${allNegatives.length}`,
      Icon: SentimentIcon,
    };
  });
  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: 4,
        height: 'calc(100vh - 62px)',
        paddingBottom: 0,
        paddingTop: 2.5,
      }}
      alignItems={'stretch'}
    >
      <Grid
        item
        container
        xs={8}
        sm={9}
        md={10}
        sx={{ px: 2 }}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'stretch'}
      >
        <Grid
          container
          mb={4}
          pl={0}
          mt={0}
          spacing={4}
          flexDirection='row'
          justifyContent={'stretch'}
          alignItems={'center'}
        >
          {successData.length > 0 && (
            <PerformanceHeader type='success' data={successData} />
          )}
          {errorData.length > 0 && (
            <PerformanceHeader type='error' data={errorData} />
          )}
        </Grid>
        <Grid
          item
          height={'30vh'}
          minHeight={270}
          sx={{ position: 'relative', pl: '0!important' }}
        >
          <CustomResponsiveContainer width={'100%'} minHeight={270}>
            <BarChart
              data={categoryWeightPercentVersion}
              layout='vertical'
              margin={{ top: 5, right: 30, left: 10, bottom: 5 }}
              maxBarSize={30}
            >
              <CartesianGrid strokeDasharray='3 3' />

              <YAxis
                dataKey='category'
                type='category'
                width={200}
                style={{ fontSize: '0.8rem' }}
                onClick={(event: any) => {
                  if (event.value) {
                    applyLabelFilter(event.value);
                  }
                }}
              />
              <XAxis type='number' />
              <Tooltip
                formatter={(_, name, item) => {
                  if (item.dataKey === 'nbPositive') {
                    return `${Math.floor(item.payload.positivePercent)}% (${
                      item.payload.nbPositive
                    } occurences)`;
                  }
                  return `${Math.floor(item.payload.negativePercent)}% (${
                    item.payload.nbNegative
                  } occurences)`;
                }}
              />
              <Legend />
              <Bar
                name='Nb of positive'
                dataKey='nbPositive'
                stackId='a'
                fill='#1EA67D'
                onClick={(a, b, c) => {
                  setMoodCategories([mapping[a.categoryKey + '.pos']]);
                }}
              />
              <Bar
                name='Nb of negative'
                dataKey='nbNegative'
                stackId='a'
                fill='#F27350'
                onClick={(a, b, c) => {
                  setMoodCategories([mapping[a.categoryKey + '.neg']]);
                }}
              />
            </BarChart>
          </CustomResponsiveContainer>
        </Grid>

        <Grid
          item
          flexGrow={1}
          pt={3}
          container
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          spacing={4}
          sx={{ minHeight: 300 }}
        >
          <Typography
            display={'block'}
            variant='h6'
            component='div'
            align='center'
            ml={4}
            sx={{
              padding: '6px',
            }}
          >
            Corresponding messages
          </Typography>
          <Grid
            item
            sx={{ flexGrow: 1, minHeight: 280, paddingTop: '0 !important' }}
          >
            <DataGridPro
              headerHeight={30}
              apiRef={apiRef}
              columns={columns}
              rows={allRows}
              rowHeight={30}
              rowThreshold={0}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              sx={{
                '& .MuiDataGrid-footerContainer': {
                  minHeight: 24,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={3} md={2} borderLeft={'1px solid #bbb'}>
        <Paper sx={{ position: 'sticky', top: '40px' }}>
          <Box sx={{ p: 3 }}>
            <Typography variant='subtitle2'>Filters</Typography>
            <Stack spacing={1}>
              <DashboardFilters onChange={onFilterChange} />
              <MultipleSelectChip
                values={touchpoints}
                onNewValues={(values: any) => setTouchpoints(values)}
                options={allTouchpoints}
                id='mood-touchpoints'
                label='Touchpoint'
                placeholder='Touchpoint'
              />
              <MultipleSelectChip
                values={moodCategories}
                onNewValues={(values: any) => setMoodCategories(values)}
                options={Object.values(mapping)}
                id='mood-categories'
                label='Mood categories'
                placeholder='Mood categories'
              />
            </Stack>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
